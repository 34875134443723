// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function detail(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/detail', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function visitClick(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/visitClick', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function save(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/save', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}