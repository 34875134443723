import { request } from "umi";

export async function save(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/dealer/visit/save', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function checkInDetails(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/checkInDetails', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function saveCall(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/company/relation/save', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function orglist(options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/partner/org/list', {
    method: 'POST',
    ...(options || {}),
  });
}
export async function getAddress(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/qy/getAddress', {
    data:data, 
    method: 'POST',
    ...(options || {}),
  });
}

export async function saveComany(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/info/save', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}

export async function visitDetail(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/detail', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function infoSave(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/info/save', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
export async function planCompanyDate(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/planCompanyDate', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
export async function firstSignIn(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/dealer/visit/firstSignIn', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
