// @ts-ignore
import Navbar from '../utils/Navbar';
import styles from './index.less';
import 矩749 from './img/矩形 749.png';
import 矩形1222 from './img/矩形 1222.png';
import 新增 from './img/新增.png';
import 组29 from './img/组 29.png';
import Card from 'antd-mobile/es/components/card';
import { Helmet, history, useLocation } from 'umi';
import TreeSelect, { TreeSelectOption } from 'antd-mobile/es/components/tree-select';
import { FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Popup from 'antd-mobile/es/components/popup';
import { getUserInfo, ListMore, Lists } from './service';
import { Button, CheckList, DatePicker, DotLoading, Form, Image, InfiniteScroll, Input, List, Modal, PullToRefresh, SearchBar, Slider, Space, Stepper, Tabs, Toast } from 'antd-mobile/es'
import { dateFormat } from '../utils/util';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { getWxAccessToken } from '../login/service';
import backUmirc from 'back.umirc';
import dayjs from 'dayjs';

export default function Merchant() {
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const location = useLocation();
  const [BDVisible, setBDVisible] = useState<boolean>(false)
  const [MarketVisible, setMarketVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [visible2, setVisible2] = useState<boolean>(false)
  const [startTimeVisible, setstartTimeVisible] = useState<boolean>(false)
  const [endTimeVisible, setendTimeVisible] = useState<boolean>(false)
  const [value, setValue] = useState<any>([])
  const [treeFlag, setTreeFlag] = useState<any>("A")
  const [treeChildFlag, setTreeChildFlag] = useState<any>("")
  const [current, setCurrent] = useState<any>([])
  const [userIds, setUserIds] = useState<any>([])
  const [userId, setUserId] = useState<any>([])
  const [data, setData] = useState<any[]>([])
  const [belongMarketIds, setbelongMarketId] = useState<any[]>([])
  const [belongMarketName, setbelongMarketName] = useState<any[]>([])
  const [phoneAndName, setphoneAndName] = useState<string>('')
  const [pageNo, setpageNo] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [hasMore, setHasMore] = useState(true)
  const [stockpile, setStockpile] = useState<any>()
  const [collectionTime, setCollectionTime] = useState<any>()
  const token = localStorage.getItem("token")
  const infoRef = useRef();
  const dataFlag:any = location.state;
  console.log(data);
  const optionsBD = [
    {
      label: '苏皖大区',
      value: 'A',
      children: [
        {
          label: '张三',
          value: 'A1',
        },
        {
          label: '李四',
          value: 'A2',
        },
        {
          label: '王二',
          value: 'A3',
        },
      ],
    },
    {
      label: '苏皖大区',
      value: 'b',
      children: [
        {
          label: '张三',
          value: 'b1',
        },
        {
          label: '李四',
          value: 'b2',
        },
        {
          label: '王二',
          value: 'b3',
        },
      ],
    },
    {
      label: '苏皖大区',
      value: 'c',
      children: [
        {
          label: '张',
          value: 'c1',
        },
        {
          label: '李',
          value: 'c2',
        },
        {
          label: '王',
          value: 'c3',
        },
      ],
    },
  ]
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年'
      case 'month':
        return data + '月'
      case 'day':
        return data + '日'
      case 'hour':
        return data + '时'
      case 'minute':
        return data + '分'
      case 'second':
        return data + '秒'
      default:
        return data
    }
  }, [])
  const loadMore = async(params:any) =>{
    if(params.pageNo === -1){
      setData([])
      params.pageNo = 1
    }
    params.phoneAndName = infoRef.current
    if(userId.length>0){
      params.userIds = userId
    }
    if(belongMarketIds.length>0){
      params.belongMarketIds = belongMarketIds
    }
    if(params.pageNo === -2){
      setData([])
      form.setFieldValue("usedNum",0)
      form.setFieldValue("notUsedNum",0)
      forms.setFieldValue("startTime",null)
      forms.setFieldValue("endTime",null)
      delete params.phoneAndName
      delete params.userIds
      delete params.belongMarketIds
      delete params.usedNum
      delete params.notUsedNum
      params.pageNo = 1
    }
    // if(stockpileFlag){
    const values = form.getFieldsValue();
    const valueTime = forms.getFieldsValue();
    params.notUsedNum = values.notUsedNum
    params.usedNum = values.usedNum
    params.startTime = valueTime.startTime
    params.endTime = valueTime.endTime
    // }
    let num =  params.pageNo + 1
    setpageNo(num)
    const append:any = await Lists(params)
    console.log(append);
    if(append.success){
      setTotalCount(append.total)
    }
    setData(val=>[...val,...append.data])
    setHasMore(append.data.length > 0)
  }
  const pullListMore = async()=>{
    const msg = await ListMore({})
    setData(msg.data.datas)
  }
  useEffect(() => {
    // @ts-ignore
    infoRef.current = phoneAndName;
    console.log(infoRef.current);
  }, [phoneAndName]);
  const callOnBD = async(list:any)=>{
    const msg = await getUserInfo({
      userIds:list
    })
    const params: any = {
      pageNo: -1,
      pageSize: 20,
      userIds: list,
    };
    console.log(msg.data);
    let lists:any = []
    msg.data.map((item:any)=>{
      lists.push(item.name)
    })
    // const arr = new Set(lists)
    // console.log([arr]);
    let arr: any = []
    for (let index = 0; index < lists.length; index++) {
      if(arr.indexOf(lists[index]) === -1) {
        arr.push(lists[index])
      }
    }
    console.log(arr);
    
    setUserIds(arr)
    // setData(msg.data.datas)
    loadMore(params)
  }
  useEffect(() => {
    if(localStorage.getItem("userId")){
      setCurrent(localStorage.getItem("userId"))
    }
  }, [localStorage.getItem("userId")]);
  const marks = {
    0: 0,
    20: 1000,
    40: 2000,
    60: 5000,
    80: "1万",
    100: "不限",
  }
  
  const toastValue = (value: number | number[]) => {
    let text = ''
    if (typeof value === 'number') {
      text = `${value}`
    } else {
      text = `[${value.join(',')}]`
    }
    Toast.show(`当前选中值为：${text}`)
    console.log(value)
  }
  const onSearch = async (value: string) => {
    const params: any = {
      pageNo: -1,
      pageSize: 20,
      phoneAndName: value,
    };
    console.log(value);
    
    // @ts-ignore
    infoRef.current = value
    loadMore(params)
    // const msg = await ListMore(params);
    // if (msg.retCode === 0) {
    //   setData(msg.data.datas);
    //   console.log(msg.data.datas);
      
    // }
  };
  const onClear = async () => {
    setUserIds([])
    setUserId([])
    setbelongMarketName([])
    setbelongMarketId([])
    setStockpile(undefined)
    setCollectionTime(undefined)
    loadMore({
      pageNo: -2,
      pageSize: 20,
    })
  }
  useEffect(()=>{
    // wxShareConfig()
    // callOnBD()
    if(dataFlag.value){
      setValue(dataFlag.value)
    }
    console.log(dataFlag);
    if(dataFlag?.market){
      let list: any = []
      let listName: any = []
      dataFlag?.value.map((item:any)=>{
        list.push(item.id)
        listName.push(item.marketName)
      })
      setbelongMarketId(list)
      setbelongMarketName(listName)
      const params: any = {
        pageNo: -1,
        pageSize: 20,
        belongMarketIds: list,
      };
      loadMore(params)
    }
    
  },[])
  
  const onChange = (v: any)=>{
    console.log(v);
    setValue(v)
    
  }
  const wxShareConfig = async() => {
    // setUserId(["ChenHong"])
    // callOnBD(["ChenHong"])
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/",
    })
    console.log(wx);
    if(msg.retCode === 0){
      await wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ["onMenuShareTimeline"] 
      });
      await wx.agentConfig({
        corpid: "ww4168efadaace928a",
        agentid: "1000013",
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ["selectEnterpriseContact"],
        success: function(res) {
          // 回调
        },
        fail: function(res) {
          if(res.errMsg.indexOf('function not exist') > -1){
              alert('版本过低请升级')
          }
        }
      })
    
    }
    // WWOpenData.bind(document.querySelector('ww-open-data'))
    // WWOpenData.on(event: string, callback: Function)
    // WWOpenData.on("", )
    // 
    // @ts-ignore
    wx.invoke("selectEnterpriseContact", {
      "fromDepartmentId": 0,// 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
      "mode": "multi",// 必填，选择模式，single表示单选，multi表示多选
      "type": ["department", "user"],// 必填，选择限制类型，指定department、user中的一个或者多个
      },
      function(res){
        setCurrent(res)
        console.log(res);
        if (res.err_msg == "selectEnterpriseContact:ok"){
          if(typeof res.result == 'string'){
            res.result = JSON.parse(res.result) //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          var selectedDepartmentList = res.result.departmentList;// 已选的部门列表
          for (var i = 0; i < selectedDepartmentList.length; i++){
            var department = selectedDepartmentList[i];
            var departmentId = department.id;// 已选的单个部门ID
            var departemntName = department.name;// 已选的单个部门名称
            Toast.show({
              icon: 'success',
              content: departmentId,
            })
          }
          var selectedUserList = res.result.userList; // 已选的成员列表
          let list = []
          for (var i = 0; i < selectedUserList.length; i++){
            var user = selectedUserList[i];
            var userId = user.id; // 已选的单个成员ID
            var userName = user.name;// 已选的单个成员名称
            var userAvatar= user.avatar;// 已选的单个成员头像
            // setCurrent(userName)
            list.push(userId)
          }
          setUserId(list)
          callOnBD(list)
          // Toast.show({
          //   icon:"success",
          //   content:userId
          // })
        }
      }
    );
  }
  return(
    <div className={styles.page}>
      <div className={styles.checkMerchant}>
        <Navbar name= {"选择商户"}></Navbar>
        <div style={{height:"20px",width:"100%",background:"#ffffff"}}>
        </div>
        <div className={styles.search}>
          <SearchBar
            onSearch={onSearch}
            placeholder="商户名称或者手机号查询"
          />
        </div>
        <div className={styles.menu}>
          <div className={styles.menu_screening}>
            <div className={styles.menu_screening_0}
              onClick={() => {
                // setBDVisible(true)
                wxShareConfig()
              }}
            >
              <div>BD</div>
              <img src={矩749}/>
            </div>
            <div className={styles.menu_screening_0}
              onClick={() => {
                // setMarketVisible(true)
                // let data = {cheyuan: true}
                history.replace("./market",data)
              }}
            >
              <div>市场</div>
              <img src={矩749}/>
            </div>
            <div className={styles.menu_screening_0} onClick={()=>{
              setVisible(true)
            }}>
              <div>库存</div>
              <img src={矩749}/>
            </div>
            <div className={styles.menu_screening_0} onClick={()=>{
              setVisible2(true)
            }}>
              <div>集采时间</div>
              <img src={矩749}/>
            </div>
          </div>
        </div>
        <div style={{display:"flex"}}>
          {totalCount !=0 && (
          <div className={styles.Tags}>
            <div onClick={ async()=>{
              // await onClear()
              console.log(totalCount);
            }}>商户总数 ： {totalCount}个</div>
          </div>
          )}
          {((belongMarketName.length>0) || (userIds.length>0) ||( stockpile) || collectionTime) && (
            <>
              <div className={styles.Tags}>
                <div onClick={ async()=>{
                  await onClear()
                }}>重置</div>
              </div>
              <div className={styles.Tags}>
                {userIds &&userIds.length>0 && userIds.map((item:any)=>{
                  return (
                    <div>{item}</div>
                  )
                })}
              </div>
              <div className={styles.Tags}>
                {belongMarketName &&belongMarketName.length>0 && belongMarketName.map((item:any)=>{
                  return (
                    <div>{item}</div>
                  )
                })}
              </div>
              <div className={styles.Tags}>
                {stockpile && (
                  <div>{stockpile.notUsedNum}~{stockpile.usedNum}</div>
                )}
              </div>
              <div className={styles.Tags}>
                {collectionTime && (
                  <div>{collectionTime.startTime}~{collectionTime.endTime}</div>
                )}
              </div>
            </>
          )}
        </div>
        
        <div className={styles.content}>
          <PullToRefresh
            onRefresh={async () => {
              setpageNo(1)
              loadMore({
                pageNo: -1,
                pageSize: 20,
              })
            }}
          >
            <CheckList multiple onChange={onChange} defaultValue={dataFlag.value}>
              {data&& data.length >0 && data.map((item:any)=>{
                return(
                <CheckList.Item value={`${item.id}-${item.companyName}`} className={styles.customBody}>
                  <div >
                    <div className={styles.customBody_title}>
                      <div className={styles.customBody_title_t}>
                        {item.doorHeadPhoto && (<Image src={item.doorHeadPhoto} width={45} height={45} fit='fill' />)}
                      </div>
                      <div className={styles.customBody_title_text}>
                        <div className={styles.customBody_title_text_name}>{item.companyName} <span></span></div>
                        <div className={styles.customBody_title_text_tab}>
                          <span>车位数 {item.parkingSpacePricePoint ? item.parkingSpacePricePoint : "0"} · 价位 {item.price ? item.price : "0"}</span>
                          <span>零售</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.customBody_content}>
                      <div className={styles.customBody_content_0}>
                        <div>联系人</div>
                        <span>{item.contactName}</span>
                      </div>
                      <div className={styles.customBody_content_0}>
                        <div>联系方式</div>
                        <span>{item.contactMobileNo}</span>
                        <a href={`tel:${item.contactMobileNo}`}>拨号</a>
                      </div>
                      <div className={styles.customBody_content_0}>
                        <div>库存数量</div>
                        <span>{item.notUsedNum ? item.notUsedNum : "0"}</span>
                      </div>
                      <div className={styles.customBody_content_0}>
                        <div>集采时间</div>
                        <span>{item.createTime ? dateFormat(item.createTime,'yyyy-MM-dd hh:mm:ss') : "-"}</span>
                      </div>
                    </div>
                    <div className={styles.customBody_footer}>
                      <div>BD：{item.marketSalesManName ? item.marketSalesManName : "-"}</div>
                    </div>
                  </div>
                  </CheckList.Item>
                )
              })}
            </CheckList>
          <InfiniteScroll loadMore={()=>{
            return loadMore({
              pageNo: pageNo,
              pageSize: 20,
            })
          }} hasMore={hasMore} />
          </PullToRefresh>
        </div>
      
      <div className={styles.footer}>
        <div>
          已选：{value.length ? value.length : 0} 个
        </div>
        <div className={styles.button} >
          <Button color='primary' fill='outline' onClick={()=>{
            history.replace("./programme",dataFlag)
          }}>
            取消
          </Button>
          <Button size='middle' color='primary' onClick={()=>{
            const data:any = dataFlag
            data.value = value
            data.checkMerchant = true
            history.replace("./programme",data)
          }}>
            确定
          </Button>
        </div>
      </div>
      </div>
    </div>
  )
}