import Button from 'antd-mobile/es/components/button';
import Card from 'antd-mobile/es/components/card';
import Form from 'antd-mobile/es/components/form';
import Input from 'antd-mobile/es/components/input';
import List from 'antd-mobile/es/components/list';
import { useEffect, useState } from 'react';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import { history, useLocation } from 'umi';
import { save } from './service';
import { Picker, Popup, Radio, Toast, TreeSelect } from 'antd-mobile';
import data from '../utils/省市区线数据.json';
import { getWxAccessToken } from '../login/service';
import Merchant from '../merchant';
import { degreeIntentList } from '../utils/signIn';
import Market from '../comments/Market';
export default function Businesses() {
  const [belongMarketId, setbelongMarketId] = useState<any>([]);
  const [options, setoptions] = useState<any>([]);
  const [cityCode, setCityCode] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [marketVisible, setMarketVisible] = useState<boolean>(false);
  const [degreeIntentVisible, setdegreeIntentVisible] =
    useState<boolean>(false);
  const [degreeIntent, setdegreeIntent] = useState<number>();
  const [form] = Form.useForm();
  const location = useLocation();
  const dataFlag = location.state;
  const submit = async () => {
    const values = form.getFieldsValue();
    values.belongMarketId = belongMarketId;
    values.contactProvinceCode = cityCode[0].value;
    values.contactProvinceName = cityCode[0].label;
    values.contactCityCode = cityCode[1].value;
    values.contactCityName = cityCode[1].label;
    values.contactRegionCode = cityCode[2].value;
    values.contactRegionName = cityCode[2].label;
    values.degreeIntent = degreeIntent;
    values.contactProvinceName = values.contactDetailAddress;
    const msg = await save(values);
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: "已创建",
      });
      history.goBack()
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };
  const handelUserMarket = async (value: any) => {
    if(value && value.length>0){
      value.map((item: any) => {
        form.setFieldsValue({belongMarketName: item.marketName})
        setbelongMarketId(item.id)
      });
    }
    setMarketVisible(false)
  };
  const handleUserMarketCancel = async () => {
    setMarketVisible(false)
  };
  const onFinish = async () => {
    form.validateFields().then(() => {
      submit();
    });
  };
  const initValue = (dataFlag: any) => {
    console.log(dataFlag);
    if (dataFlag.value) {
      form.setFieldValue('belongMarketName', dataFlag.value[0].marketName);
      setbelongMarketId(dataFlag.value[0].id);
    }
    if (dataFlag.degreeIntentName) {
      form.setFieldValue('degreeIntentName', dataFlag.degreeIntentName);
      setdegreeIntent(dataFlag.degreeIntent);
    }
    let list: any[] = [];
    let list2: any[] = [];
    let list3: any[] = [];
    data.RECORDS.map((item: any) => {
      if (item.deep === 0) {
        list.push(item);
      }
      if (item.deep === 1) {
        list2.push(item);
      }
      if (item.deep === 2) {
        list3.push(item);
      }
    });
    console.log(list, list2, list3);
  };
  const init = () => {
    let list: any[] = [];
    let list2: any[] = [];
    let list3: any[] = [];
    data.RECORDS.map((item: any) => {
      if (item.deep === 0) {
        list.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 1) {
        list2.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 2) {
        list3.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
    });
    let city: any[] = [];
    let city2: any[] = [];
    list2.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list3.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city.push(data);
    });
    list.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list2.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city2.push(data);
    });
    setoptions(city2);
    console.log(city2);
  };

  useEffect(() => {
    init();
    if (dataFlag) {
      initValue(dataFlag);
    }
  }, []);
  return (
    <div className={`${styles.businesses_form} ${styles.page}`}>
      <Navbar name="新建商户"></Navbar>
      <Card>
        <Form
          onFinish={onFinish}
          form={form}
          footer={
            <Button
              block
              className={styles.submit}
              type="submit"
              color="primary"
            >
              新增车商
            </Button>
          }
        >
          <List>
            <List.Item prefix="车商名称" title={'*'}>
              <Form.Item
                name="companyName"
                rules={[{ required: true, message: '请输入车商名称' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入车商名称" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="联系人" title={'*'}>
              <Form.Item
                name="contactName"
                rules={[{ required: true, message: '请输入联系人' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入联系人" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="联系电话" title={'*'}>
              <Form.Item
                name="contactMobileNo"
                rules={[{ required: true, message: '请输入联系电话' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入联系电话" />
              </Form.Item>
            </List.Item>
            <List.Item
              prefix="车商地址"
              title={'*'}
              onClick={() => {
                setVisible(true);
              }}
            >
              <Form.Item
                name="contactDetailAddress"
                rules={[{ required: true, message: '请选择车商地址' }]}
                className={styles.form_item}
              >
                <Input placeholder="请选择车商地址" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item
              prefix="归属市场"
              onClick={() => {
                // const values = form.getFieldsValue();
                // values.businesses = true;
                // values.degreeIntent = degreeIntent;
                // history.replace('./market', values);
                setMarketVisible(true)
              }}
              title={'*'}
            >
              <Form.Item
                name="belongMarketName"
                rules={[{ required: true, message: '选择市场' }]}
                className={styles.form_item}
              >
                <Input placeholder="选择市场" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item prefix="归属区域">
              <Form.Item
                name="belongingRegion"
                // rules={[{ required: true, message: '请输入归属区域' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入归属区域" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="楼层">
              <Form.Item
                name="floor"
                // rules={[{ required: true, message: '请输入楼层' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入楼层" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="门牌号">
              <Form.Item
                name="doorplate"
                // rules={[{ required: true, message: '请输入门牌号' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入门牌号" />
              </Form.Item>
            </List.Item>
            {/* <List.Item prefix="商户类型"
              title={"*"}>
                <Form.Item
                  name="companyType"
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={0}>合作商户</Radio>
                    <Radio value={1}>待激活商户</Radio>
                    <Radio value={2}>注册商户</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item> */}
            {/* <List.Item
              prefix="意向度"
              title={'*'}
              onClick={() => {
                setdegreeIntentVisible(true);
              }}
            >
              <Form.Item
                name="degreeIntentName"
                rules={[{ required: true, message: '请选择意向度' }]}
                className={styles.form_item}
              >
                <Input placeholder="请选择意向度" readOnly />
              </Form.Item>
            </List.Item> */}
          </List>
        </Form>
        <div className={styles.footer}>
          新建车商默认启用状态，负责BD为创建人本人。
        </div>
      </Card>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        position="right"
        bodyStyle={{ width: '100%' }}
      >
        <div style={{ width: 'inherit' }}>
          <TreeSelect
            options={options}
            onChange={(value, nodes) => {
              console.log(value, nodes);
              setCityCode(nodes.options);
            }}
          />
          <div className={styles.time_button}>
            <Button
              color="primary"
              fill="outline"
              onClick={() => {
                setVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="middle"
              color="primary"
              onClick={() => {
                console.log(cityCode);
                if (cityCode.length === 1) {
                  Toast.show({
                    content: '请选择城市',
                  });
                  return;
                }
                if (cityCode.length === 2) {
                  Toast.show({
                    content: '请选择区/县',
                  });
                  return;
                }
                form.setFieldValue(
                  'contactDetailAddress',
                  `${cityCode[0].label}${cityCode[1].label}${cityCode[2].label}`,
                );
                setVisible(false);
              }}
            >
              确定
            </Button>
          </div>
        </div>
      </Popup>
      <Market
        nameVisible={marketVisible}
        onSubmit={handelUserMarket}
        onCancel={handleUserMarketCancel}
        value={false}
      />

      <Picker
        columns={degreeIntentList}
        visible={degreeIntentVisible}
        onCancel={() => {
          setdegreeIntentVisible(false);
        }}
        onConfirm={(value: any, extend) => {
          setdegreeIntent(value[0]);
          form.setFieldValue('degreeIntentName', extend.items[0]?.label);
          setdegreeIntentVisible(false);
        }}
      />
    </div>
  );
}
