// @ts-ignore
/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'umi';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import 矩749 from './img/矩形 749.png';
import {
  Button,
  Calendar,
  Card,
  CheckList,
  IndexBar,
  IndexBarRef,
  InfiniteScroll,
  List,
  Popup,
  SearchBar,
  Tag,
  Tabs,
  Picker,
} from 'antd-mobile';
import { getVisitTemplate, Lists, noauth } from './service';
import { dateFormat } from '../utils/util';
import proxy from '../../../config/proxy';
import Merchant from '../comments/Merchant';
import { judgment } from '../utils/signIn';
import Login from '../login';
export default function report() {
  const location = useLocation();
  const [checkoutFlag, setCheckoutFlag] = useState<string>('1');
  const [data, setData] = useState<any[]>([]);
  const [detectData, setdetectData] = useState<any[]>([]);
  const [vin, setVin] = useState<string>('');
  const [pageNo, setpageNo] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [detectHasMore, setdetectHasMore] = useState<boolean>(true);
  const [visibleTime, setVisibleTime] = useState<boolean>(false);
  const [marketVisible, setMarketVisible] = useState<boolean>(false);
  const [judgmentVisible, setJudgmentVisible] = useState<boolean>(false);
  const [timeMore, setTimeMore] = useState<[Date, Date] | null>(null);
  const [times, setTimes] = useState<any>([]);
  const [checkList, setCheckList] = useState<any>([]);
  const [checkValue, setCheckValue] = useState<any>([]);
  const [marketName, setMarketName] = useState<any>([]);
  const [checkType, setCheckType] = useState<string>('-1');
  const [noauthData, setNoauthData] = useState<any>([]);
  const [carModelVisible, setCarModelVisible] = useState(false);
  const [seriesVisible, setSeriesVisible] = useState(false);
  const [modelNameVisible, setModelNameVisible] = useState(false);
  const [nameVisible, setNameVisible] = useState(false);
  const [seriesData, setSeriesData] = useState<any>([]);
  const [companyName, setCompanyName] = useState<string>('');
  const [determineType, setDetermineType] = useState<any>(-1);
  const [modelNameList, setModelNameList] = useState<any>([]);
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    flag: false,
  });
  const [brandName, setBrandName] = useState<string>('');
  const [topTemplateId, settopTemplateId] = useState<number>(0);
  const dataFlag = location.state;
  const indexBarRef = useRef<IndexBarRef>(null);
  const infoRef: any = useRef([]);
  // @ts-ignore
  const url = 'https://app-report-image-data.cn-sh2.ufileos.com/';
  useEffect(() => {
    if (!params.flag) {
      setLoading(true)
      loadMore({ flag: false });
    }
  }, [params]);
  const loadMore = async (pm: any = {}) => {
    if (params.pageNo === -1) {
      setData([]);
      setParams({ ...params, pageNo: 1 });
      setHasMore(false);
      return;
    }
    if (params.carModel === '品牌车系') {
      console.log(1111);

      delete params.carModel;
    }
    if (params.determineType === 4) {
      console.log(2222);

      delete params.determineType;
    }
    if (pm.flag) {
      console.log(3333);
      setParams({ ...params, ...pm });
    }
    await loadData({ ...params, ...pm });
  };
  const loadData = async (pm: any = {}) => {
    let temp = data;
    const datas = await Lists({ ...params, ...pm });
    setLoading(false)
    setData([...temp, ...(datas || [])]);
    setHasMore(datas.length > 0);
  };
  const viewH5 = (record: any) => {
    if (record.reportUrl) {
      window.open(record.reportUrl, '_blank');
    } else {
      if (proxy['/api'].target.indexOf('bdtest') > -1) {
        const h5Url = `https://testapi.wzyanche.com/report/h5/${record.code}`;
        window.open(h5Url, '_blank');
      } else {
        const h5Url = `https://api.wzyanche.com/report/h5/${record.code}`;
        window.open(h5Url, '_blank');
      }
    }
  };
  const onCheckChange = (v: any) => {
    console.log(v);

    setCheckValue(v);
  };
  const onSearch = async (value: string) => {
    setParams({ ...params,pageNo:-1, vin: value, flag: false });
  };
  const putAway = () => {
    setCarModelVisible(false);
  };
  const brand = (child: any) => {
    setSeriesData(child.series);
    setSeriesVisible(true);
    // setCarModelVisible(false);
  };
  const series = async (item: any) => {
    setBrandName(item.seriesName);
    setParams({
      ...params,
      pageNo: -1,
      carModel: item.seriesName,
      flag: false,
    });
    // setSeriesId(item.id);
    setCarModelVisible(false);
    setSeriesVisible(false);
    // const msg = await openCarModel({
    //   brandCode:item.brandId,
    //   seriesCode:item.id,
    // })
    // if(msg.retCode === 0){
    //   setModelNameVisible(true)
    //   setModelNameList(msg.data)
    //   console.log(msg.data);
    // }
  };
  const onCheckSearch = async (value: string) => {
    setpageNo(1);
    setMarketName(value);
    // const msg = await ListMore(params);
    // if (msg.retCode === 0) {
    //   setData(msg.data.datas);
    //   console.log(msg.data.datas);

    // }
  };
  const onChange = async (v: any) => {
    setCheckType(v);
  };
  const initValue = async () => {
    const append = await getVisitTemplate({});
    setCheckList((val: any) => [...val, ...append]);
    setHasMore(append.length > 0);
    const msg = await noauth({});
    if (msg.retCode === 0) {
      setNoauthData(msg.data);
      console.log(msg.data);
    }
  };
  const handelUserName = async (value: any) => {
    console.log(value);
    if (value.length > 0) {
      value.map((item: any) => {
        let arr = item.split('===');
        setCompanyName(arr[0]);
        setParams({ ...params, pageNo: -1, companyId: arr[1], flag: false });
      });
    }
    setNameVisible(false);
  };
  const handleUserNameCancel = async () => {
    setNameVisible(false);
  };
  useEffect(() => {
    localStorage.removeItem('conmanyVisitSignType');
    initValue();
  }, []);
  const onClear = () => {
    setParams({
      pageNo: -1,
      pageSize: 20,
      flag: false,
    })
    setHasMore(false)
    setBrandName("")
    setCompanyName("")
    setDetermineType(4)
  }
  return (
    <div className={styles.report}>
      <Navbar name="报告中心"></Navbar>
      {loading && <Login></Login>}
      <div className={styles.search}>
        <SearchBar
          onSearch={onSearch}
          // onClear={getReport}
          style={{ '--background': '#E9ECF0' }}
          placeholder="车架号搜索"
        />
      </div>
      <div className={styles.menu}>
        <div className={styles.menu_screening}>
          <div
            className={styles.menu_screening_0}
            onClick={() => {
              setCarModelVisible(true);
            }}
          >
            <div>品牌车系</div>
            <img src={矩749} />
          </div>
          <div
            className={styles.menu_screening_0}
            onClick={() => {
              setNameVisible(true);
            }}
          >
            <div>商户</div>
            <img src={矩749} />
          </div>
          <div
            className={styles.menu_screening_0}
            onClick={() => {
              setMarketVisible(true);
            }}
          >
            <div>检测产品</div>
            <img src={矩749} />
          </div>
          <div className={styles.menu_screening_0}>
            <div
              onClick={() => {
                setVisibleTime(true);
              }}
            >
              下单时间
            </div>
            <img src={矩749} />
          </div>
          <div className={styles.menu_screening_0}>
            <div
              onClick={() => {
                setJudgmentVisible(true);
              }}
            >
              判定
            </div>
            <img src={矩749} />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', overflow: 'scroll', marginRight: '10px' }}>
        <div className={styles.Tags}>
          <div
            onClick={async () => {
              await onClear();
            }}
          >
            重置
          </div>
          {params.start && params.end && (
            <div>
              下单时间：
              {params.start}~{params.end}
            </div>
          )}
          {checkList &&
            checkList.length > 0 &&
            params.topTemplateId &&
            checkList.map((item: any) => {
              if (item.topTemplateId === params.topTemplateId) {
                return (
                  <div>
                    检测产品：
                    {item.templateName}
                  </div>
                );
              }
            })}
          {brandName && brandName != '' && (
            <div>
              品牌车系：
              {brandName}
            </div>
          )}
          {companyName && companyName != '' && (
            <div>
              商户：
              {companyName}
            </div>
          )}
          {determineType &&
            judgment &&
            judgment[0] &&
            judgment[0].length > 0 &&
            judgment[0].map((item: any) => {
              if (item.value === params.determineType) {
                return (
                  <div>
                    判定：
                    {item.label}
                  </div>
                );
              }
            })}
        </div>
      </div>
      {/* {checkoutFlag === '1' && ( */}
      <div className={styles.text}>
        {data &&
          data.map((item: any, index: number) => {
            return (
              <Card
                style={{ borderRadius: '8px' }}
                onClick={() => {
                  viewH5(item);
                }}
              >
                <div className={styles.content}>
                  <div className={styles.content_img}>
                    <img src={`${url}${item.carMainImage}`} alt="" />
                  </div>
                  <div className={styles.content_text}>
                    <div className={styles.content_car}>
                      {item.carModel} <span>{item.templateName}</span>
                    </div>
                    <div className={styles.content_0}>VIN：{item.vin}</div>
                    {item.mCompanyInfo && item.mCompanyInfo.companyName && (
                      <div className={styles.content_0}>
                        商户：{item.mCompanyInfo.companyName}
                      </div>
                    )}
                    <div className={styles.content_0}>
                      检测时间：
                      {item.createTime
                        ? dateFormat(item.createTime, 'yyyy-MM-dd hh:mm:ss')
                        : '-'}
                    </div>
                    {/* <div className={styles.content_1}>
                        
                      </div> */}
                    <div className={styles.content_2}>
                      {item.accidentContent && (
                        <Tag
                          color={
                            item.accidentContent.indexOf('排除') > -1 ||
                            item.accidentContent === '未见明显异常'
                              ? 'rgba(21, 209, 65, 1)'
                              : 'danger'
                          }
                        >
                          {item.accidentContent}
                        </Tag>
                      )}
                      {item.fireContent && (
                        <Tag
                          color={
                            item.fireContent.indexOf('排除') > -1 ||
                            item.fireContent === '未见明显异常'
                              ? 'rgba(21, 209, 65, 1)'
                              : 'danger'
                          }
                        >
                          {item.fireContent}
                        </Tag>
                      )}
                      {item.waterContent && (
                        <Tag
                          color={
                            item.waterContent.indexOf('排除') > -1 ||
                            item.waterContent === '未见明显异常'
                              ? 'rgba(21, 209, 65, 1)'
                              : 'danger'
                          }
                        >
                          {item.waterContent}
                        </Tag>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        <InfiniteScroll
          loadMore={async () => {
            await loadMore({ pageNo: params.pageNo + 1, flag: true });
          }}
          hasMore={hasMore}
        />
      </div>
      {/* )} */}
      <Popup
        visible={visibleTime}
        showCloseButton
        onClose={() => {
          setVisibleTime(false);
        }}
        position="right"
        bodyStyle={{ width: '100vw', display: 'block' }}
      >
        {/* {mockContent} */}
        <div className={styles.mores}>选择时间段</div>
        <Calendar
          // defaultValue={defaultRange}
          className={styles.calendar_custom}
          selectionMode="range"
          onChange={(val) => {
            console.log(val);
            setTimeMore(val);
          }}
          value={timeMore}
        />
        <div className={styles.time_button}>
          <Button
            color="primary"
            fill="outline"
            onClick={() => {
              setTimeMore(null);
            }}
          >
            重置
          </Button>
          <Button
            size="middle"
            color="primary"
            onClick={() => {
              if (timeMore != null) {
                // console.log(dateFormat(timeMore[0],"Y-MM-dd hh:mm:ss"));
                let startTime = dateFormat(timeMore[0], 'Y-MM-dd hh:mm:ss');
                let endTime = dateFormat(timeMore[1], 'Y-MM-dd hh:mm:ss');
                setVisibleTime(false);
                setParams({
                  pageNo: -1,
                  pageSize: 20,
                  start: startTime,
                  end: endTime,
                  flag: false,
                });
              } else {
                setParams({
                  pageNo: -1,
                  pageSize: 20,
                  flag: false,
                });
              }
            }}
          >
            确定
          </Button>
        </div>
      </Popup>
      <div>
        <Popup
          position="right"
          visible={marketVisible}
          bodyClassName={styles.bodyClass}
          style={{ zIndex: '60' }}
        >
          <div className={styles.market}>
            <div className={styles.search}>
              <SearchBar onSearch={onCheckSearch} placeholder="请输入产品名" />
            </div>
            <div className={styles.container}>
              {checkList.length > 0 && (
                <div className={styles.checkList}>
                  <Tabs
                    style={{
                      '--title-font-size': '14px',
                    }}
                    onChange={onChange}
                  >
                    <Tabs.Tab title={'全部'} key={'-1'} />
                    <Tabs.Tab title={'新产品'} key={'0'} />
                    <Tabs.Tab title={'老产品'} key={'1'} />
                  </Tabs>
                  <CheckList
                    multiple={false}
                    onChange={onCheckChange}
                    defaultValue={checkValue}
                  >
                    {checkList.length > 0 &&
                      checkList.map((item: any) => {
                        if (
                          item.templateName &&
                          item.templateName.indexOf(marketName) > -1
                        ) {
                          if (
                            checkType &&
                            (checkType === '-1' || checkType === `${item.type}`)
                          ) {
                            return (
                              <CheckList.Item value={item.topTemplateId}>
                                {item.templateName}
                              </CheckList.Item>
                            );
                          }
                        }
                      })}
                  </CheckList>
                </div>
              )}
            </div>
            <div className={styles.footer_check}>
              <div>已选：{checkValue.length} 个</div>
              <div className={styles.button}>
                <Button
                  color="primary"
                  fill="outline"
                  onClick={() => {
                    // onCancel()
                    setMarketVisible(false);
                  }}
                >
                  取消
                </Button>
                <Button
                  size="middle"
                  color="primary"
                  onClick={() => {
                    setParams({
                      ...params,
                      pageNo: -1,
                      topTemplateId: checkValue[0],
                      flag: false,
                    });
                    setMarketVisible(false);
                  }}
                >
                  确定
                </Button>
              </div>
            </div>
          </div>
        </Popup>
        <Merchant
          visit={true}
          cityFlag={false}
          checkFlag={false}
          ButtonFlag={false}
          nameVisible={nameVisible}
          onSubmit={handelUserName}
          onCancel={handleUserNameCancel}
        />
      </div>

      <Picker
        columns={judgment}
        visible={judgmentVisible}
        onCancel={() => {
          setJudgmentVisible(false);
        }}
        onConfirm={(value, extend) => {
          setParams({
            ...params,
            pageNo: -1,
            determineType: value[0],
            flag: false,
          });
          setJudgmentVisible(false);
        }}
      />
      <Popup
        visible={carModelVisible}
        onMaskClick={() => {
          putAway();
        }}
        position="right"
        bodyStyle={{ minHeight: '40vh', width: '100vw', zIndex: 9999 }}
      >
        <div
          style={{ height: window.innerHeight }}
          className={styles.popupPosition}
        >
          <div className={styles.popupClose} onClick={putAway}>
            收起
          </div>
          <IndexBar ref={indexBarRef}>
            {noauthData.length > 0 &&
              noauthData.map((item: any, index: number) => {
                return (
                  <IndexBar.Panel
                    index={item.letter}
                    title={item.letter}
                    key={item.letter}
                  >
                    {item.brands &&
                      item.brands.length > 0 &&
                      item.brands.map((child: any, childIndex: number) => {
                        return (
                          <List.Item
                            onClick={() => brand(child)}
                            key={child.id}
                            arrow={false}
                          >
                            <img
                              src={child.url}
                              width="18"
                              className={styles.carLogo}
                            />
                            {child.brandName}
                          </List.Item>
                        );
                      })}
                  </IndexBar.Panel>
                );
              })}
          </IndexBar>
        </div>
        {/* 内层容器 */}
        <Popup
          visible={seriesVisible}
          onMaskClick={() => {
            setSeriesVisible(false);
          }}
          position="right"
          bodyStyle={{ minHeight: '40vh', width: '70vw', zIndex: 99999 }}
        >
          <IndexBar ref={indexBarRef}>
            <IndexBar.Panel index={''} title={'车系'} key={'车系'}>
              {seriesData.length > 0 &&
                seriesData.map((item: any, index: number) => {
                  return (
                    <List.Item
                      onClick={() => series(item)}
                      key={item.id}
                      arrow={false}
                    >
                      {item.seriesName}
                    </List.Item>
                  );
                })}
            </IndexBar.Panel>
          </IndexBar>
        </Popup>
        <Popup
          visible={modelNameVisible}
          onMaskClick={() => {
            setModelNameVisible(false);
          }}
          position="right"
          bodyStyle={{ minHeight: '40vh', width: '40vw', zIndex: 999999 }}
        >
          <IndexBar ref={indexBarRef}>
            <IndexBar.Panel index={''} title={'年款'} key={'年款'}>
              {modelNameList.length > 0 &&
                modelNameList.map((item: any, index: number) => {
                  return (
                    <List.Item key={item.id} arrow={false}>
                      {item.modelName}
                    </List.Item>
                  );
                })}
            </IndexBar.Panel>
          </IndexBar>
        </Popup>
      </Popup>
    </div>
  );
}
