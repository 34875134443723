// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
import { sleep } from 'antd-mobile/es/utils/sleep';

let count = 1;

export async function ListMore(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/car/info/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function Lists(data: any, options?: { [key: string]: any }) {
  const params = data;
  params.pageNo = count;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/car/info/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  count++;
  await sleep(2000);
  if (msg.retCode === 0) {
    return msg.data.datas;
  } else {
    return [];
  }
}

export async function save(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/save', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getNewRepTotal(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/new/rep/total/getNewRepTotal', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function labellist(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/label/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function typelist(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/type/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function saves(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/info/save', {
    data: data,
    method: 'POST',
    ...(options || {}),
  });
}
export async function remarkLists(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/remark/record/list', {
    data: data,
    method: 'POST',
    ...(options || {}),
  });
}

export async function visitSave(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/dealer/visit/save', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}