// @ts-ignore
/* eslint-disable */
import styles from './index.less';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../utils/Navbar';
import {
  Badge,
  Button,
  Calendar,
  Card,
  DatePicker,
  Empty,
  Form,
  ImageViewer,
  InfiniteScroll,
  Input,
  List,
  Modal,
  Picker,
  Popup,
  PullToRefresh,
  Radio,
  SearchBar,
  Switch,
  Tabs,
  TextArea,
  Toast,
} from 'antd-mobile/es';
import AddSquareOutline from 'antd-mobile-icons/es/AddSquareOutline';
import FilterOutline from 'antd-mobile-icons/es/FilterOutline';
import 成功 from './img/成功.png';
import 拜访成交 from './img/拜访成交.png';
import 注册上线 from './img/注册上线.png';
import 失败 from './img/失败.png';
import 待在拜访 from './img/待在拜访.png';
import 切换 from './img/切换.png';
import 上一条 from './img/上一条.png';
import 下一条 from './img/下一条.png';
import { history, useLocation } from 'umi';
import {
  amendment,
  breakthroughLogicInTheRegione,
  cancel,
  getRemarkRecord,
  listAnnotate,
  Lists,
  recordSave,
  redisCount,
  saveScreenshot,
  visitRemarks,
} from './service';
import { dateFormat } from '../utils/util';
import dayjs from 'dayjs';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
  visitResultList,
  visitResultLists,
  visitSignTypeList,
} from '../utils/signIn';
import { getWxAccessToken } from '../login/service';
import { getUserInfo } from '../merchant/service';
import Login from '../login';
import { typelist } from '../programme/service';
import NameList from '../comments/NameList';
import Item from 'antd-mobile/es/components/dropdown/item';
import { AddOutline } from 'antd-mobile-icons';
import { getQyWxImg } from '../checkIn/service';

export const parseResultName = (appraiseType: string) => {
  let result;
  if (appraiseType === '1') {
    result = '极好';
  }
  if (appraiseType === '2') {
    result = '好';
  }
  if (appraiseType === '3') {
    result = '中';
  }
  if (appraiseType === '4') {
    result = '差';
  }
  return result;
};
const testContent = (text: number) => {
  let content: any;
  if (text > 0) {
    if (Number.isInteger(text)) {
      content = text;
    } else {
      content = text.toFixed(1);
    }
  }
  return content;
};
export default function PayAVisit() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [forms] = Form.useForm();
  const [formMore] = Form.useForm();
  const [remarkform] = Form.useForm();
  const [remarkforms] = Form.useForm();
  const pageSize = 15;
  const [checkState, setCheckState] = useState({ check: false, index: 0 });
  const [appraiseCheckState, setAppraiseCheckState] = useState(0);
  const [checkStateRemark, setCheckStateRemark] = useState({ check: false });
  const tabIndex = localStorage.getItem('tabIndex') || '0';
  const userIdSocre = localStorage.getItem('name') || '0';
  const token = localStorage.getItem('token') || '';
  const [newRemark, setNewRemark] = useState<any>([]);
  const [newRemarkList, setNewRemarkList] = useState<any>([]);
  const [mediaImage, setMediaImage] = useState<any>([]);
  const [remark, setRemark] = useState<any>([]);
  const [remarks, setRemarks] = useState<any>([]);
  const [modifyAnnotations, setModifyAnnotations] = useState<any>([]);
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize,
    status: tabIndex,
    teamFlag: false,
    flag: false,
  });
  const [countMap, setCountMap] = useState<any>({
    totalPage: 0,
    totalCount: 0,
  });
  const [data, setData] = useState<any[]>([]);
  const [remarkValue, setRemarkValue] = useState<any>([]);
  const [visitPlanId, setVisitPlanId] = useState<number>();
  const [hasMore, setHasMore] = useState(false);
  const [timeVisible, setTimeVisible] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleTime, setVisibleTime] = useState<boolean>(false);
  const [timeId, setTimeId] = useState<number>(-1);
  const [visitResultVisible, setvisitResultVisible] = useState<boolean>(false);
  const [visitResult, setvisitResult] = useState<any>(-1);
  const [marketNameId, setmarketNameId] = useState<number>(-1);
  const [times, setTimes] = useState<any>([]);
  const [timeMore, setTimeMore] = useState<[Date, Date] | null>(null);
  const [visitSignTypeVisible, setvisitSignTypeVisible] =
    useState<boolean>(false);
  const [visitSignType, setvisitSignType] = useState<any>(-1);
  const [userId, setUserId] = useState<any>([]);
  const [visible4, setVisible4] = useState<boolean>(false);
  const [visible5, setVisible5] = useState<boolean>(false);
  const [visible6, setVisible6] = useState<boolean>(false);
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [remarkRecordId, setremarkRecordId] = useState<any>([]);
  const [mCarDealerVisitId, setmCarDealerVisitId] = useState<any>([]);
  const [moreMap, setMoreMap] = useState<any>({});
  const [typeList, setTypeList] = useState<any>([]);
  const [nameVisible, setNameVisible] = useState<boolean>(false);
  const [iPhone, setiPhone] = useState<boolean>(true);
  const [allImage, setAllImage] = useState<any>([]);
  const [appraiseList, setAppraiseList] = useState<any>([]);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [annotations, setAnnotations] = useState<any>([]);
  const [marginalValue, setMarginalValue] = useState<any>([]);
  const [marginalList, setMarginalList] = useState<any>([]);
  const [record, setRecord] = useState<any>([]);
  const [redisList, setRedisList] = useState<any>([]);
  const location = useLocation();
  const dataFlag = location.state;
  const infoRef = useRef([]);
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年';
      case 'month':
        return data + '月';
      case 'day':
        return data + '日';
      case 'hour':
        return data + '时';
      case 'minute':
        return data + '分';
      case 'second':
        return data + '秒';
      default:
        return data;
    }
  }, []);
  const onChange = (v: any) => {
    console.log(v);
    if (v != 4) {
      setCheckState({ check: false, index: 0 });
    }
    setModifyAnnotations([]);
    setLoading(true);
    localStorage.setItem('tabIndex', v);
    reload({ status: v, teamFlag: params.teamFlag });
  };
  const onAppraiseChange = (
    v: any,
    companyId: any,
    visitId: any,
    status: number,
  ) => {
    setAppraiseCheckState(v);
    visitRemarks({
      appraiseType: v,
      companyId: companyId,
      id: visitId,
      annotationFlag: params.status === '4' ? false : true,
    }).then((res: any) => {
      if (res.retCode === 0) {
        if (res.data && res.data.length > 0) {
          appraiseList[visitId] = res.data;
          setAppraiseList([...appraiseList]);
        } else {
          appraiseList[visitId] = [];
          setAppraiseList([...appraiseList]);
        }
      }
    });
  };
  const loadMore = async (pm: any = {}) => {
    if (times.length > 0) {
      params.startTime = times[0] + ' 00:00:01';
      params.endTime = times[1] + ' 23:59:59';
    }
    if (visitResult != -1) {
      params.visitResults = visitResult;
    }
    // @ts-ignore
    if (infoRef.current.check) {
      // @ts-ignore
      params.type = infoRef.current.check;
    }
    if (marketNameId != -1) {
      params.belongMarketIds = [marketNameId];
    }
    if (userId.length > 0) {
      params.userIds = userId;
    }
    if (visitSignType != -1) {
      params.visitSignType = visitSignType;
    }
    if (params.status === '3' || params.status === 3 || !params.status) {
      params.statusIn = [6, 3];
      delete params.status;
    } else {
      delete params.statusIn;
    }
    if (pm.flag) {
      setParams({ ...params, ...pm });
    }
    if (params.status === '5' || params.status === 5) {
      params.annotationFlag = 1;
      await loadDataTow({ ...params, ...pm });
    } else {
      delete params.annotationFlag;
      await loadData({ ...params, ...pm });
    }
  };

  useEffect(() => {
    if (!params.flag) {
      loadMore({ flag: false });
    }
  }, [params]);
  useEffect(() => {
    if (loading && params.pageNo <= 1) {
      redisInit(params.teamFlag);
    }
  }, [loading]);
  const reload = (pm: any = {}) => {
    setParams({
      ...params,
      pageNo: -1,
      pageSize,
      status: params.status,
      ...pm,
      flag: false,
    });
  };
  const reset = (pm: any = {}) => {
    setParams({
      pageNo: -1,
      pageSize,
      status: params.status,
      flag: false,
      teamFlag: params.teamFlag,
    });
  };
  const loadData = async (pm: any = {}) => {
    let temp = data;
    if (params.pageNo == -1) {
      setData([]);
      setParams({ ...params, pageNo: 1 });
      return;
    }
    setLoading(true);
    const { datas, totalPage, totalCount } = await Lists({ ...params, ...pm });
    if (totalCount) {
      countMap.totalCount = totalCount;
    }
    if (totalPage) {
      countMap.totalPage = totalPage;
    }
    setCountMap({ ...countMap });
    setData([...temp, ...(datas || [])]);
    setLoading(false);
    setHasMore(totalPage > params.pageNo);
  };
  const loadDataTow = async (pm: any = {}) => {
    let temp = data;
    if (params.pageNo == -1) {
      setData([]);
      setParams({ ...params, pageNo: 1 });
      return;
    }
    setLoading(true);
    const { datas, totalPage, totalCount } = await listAnnotate({
      ...params,
      ...pm,
    });
    if (totalCount) {
      countMap.totalCount = totalCount;
    }
    if (totalPage) {
      countMap.totalPage = totalPage;
    }
    setCountMap({ ...countMap });
    setData([...temp, ...(datas || [])]);
    setLoading(false);
    setHasMore(totalPage > params.pageNo);
  };
  // useEffect(() => {
  //   loadData();
  // }, [params]);
  const onAmendment = async () => {
    const values = forms.getFieldsValue();

    const msg = await amendment({
      visitingTime: values.forms,
      id: values.id,
      amendmentRemark: values.remarkValue,
      focusCompanyVisit: values.focusCompanyVisit,
    });
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '成功改约计划',
      });
      forms.resetFields();
      loadMore({});
      reset();
      // pullListMore(checkoutFlag)
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };
  const remarkRecord = (id: number) => {
    if (newRemark[id]) {
      newRemark[id] = false;
      setNewRemark([...newRemark]);
    } else {
      newRemark[id] = true;
      setNewRemark([...newRemark]);
    }
    getRemarkRecord({ id: id }).then((msg) => {
      if (msg.retCode === 0) {
        // Toast.show({
        //   icon: 'success',
        //   content: msg.retMsg,
        // });
        // loadMore({});
        setRemarks([]);
        setNewRemarkList(msg.data);
        // pullListMore(checkoutFlag)
      } else {
        Toast.show({
          icon: 'fail',
          content: msg.retMsg,
        });
      }
    });
  };
  const onDelete = async (id: any) => {
    const values = form.getFieldsValue();
    console.log(values);

    const msg = await cancel({ id: id, cancelRemark: values.forms, status: 3 });
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '成功取消计划',
      });
      setParams({
        pageNo: -1,
        pageSize: 20,
        status: 0,
        flag: true,
      });
      form.resetFields();
      reset();
      // pullListMore(checkoutFlag)
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };
  const onDeletes = async (id: any) => {
    const values = form.getFieldsValue();
    console.log(values);

    const msg = await cancel({ id: id, cancelRemark: values.forms, status: 6 });
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '成功驳回计划',
      });
      setParams({
        pageNo: -1,
        pageSize: 20,
        status: 0,
        flag: true,
      });
      form.resetFields();
      reset();
      // pullListMore(checkoutFlag)
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };

  const onSearch = async (value: string) => {
    setParams({ ...params, flag: false, pageNo: -1, comPonyPhoneName: value });
  };
  const team = async () => {
    setParams({ ...params, teamFlag: !params.teamFlag, flag: false });
  };
  const onFinishRemark = () => {
    remarkform
      .validateFields()
      .then(() => {
        onRemarkFinish();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onRemarkFinish = async () => {
    const values = remarkform.getFieldsValue();
    if (remarkValue === '') {
      setLoading(false);
      Toast.show({
        icon: 'fail',
        content: '请填写批注！',
        // duration: 0,
      });
      return;
    }
    // values.createTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    values.remarkContent = remarkValue;
    values.labelId = remarkChild.labelId;
    values.labelName = remarkChild.labelName;
    values.parentId = remarkChild.id;
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = localStorage.getItem('avatar');
    values.url = localStorage.getItem('avatar');
    values.visitPlanId = mCarDealerVisitId;
    values.remarkRecordId = remarkRecordId;
    let list = remark;
    recordSave(values)
      .then((msg) => {
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '批注成功',
          });
          setVisible4(false);
          // setParams({ ...params, pageNo: -1, flag: false });
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        if (msg.data) {
          list.push(msg.data);
        }
        let arr = list.reverse();
        console.log(arr, list);
        setRemarks(arr);
        setRemark(arr);
        setLoading(false);
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: error,
        });
        setLoading(false);
      });
  };
  const onFinishAnnotations = () => {
    remarkform
      .validateFields()
      .then(() => {
        onAnnotationsFinish();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onAnnotationsFinish = async () => {
    const values = remarkform.getFieldsValue();
    // values.createTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    values.remarkContent = remarkValue;
    values.labelId = remarkChild.labelId;
    values.labelName = remarkChild.labelName;
    values.parentId = remarkChild.id;
    values.id = annotations.id;
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = localStorage.getItem('avatar');
    values.visitPlanId = mCarDealerVisitId;
    values.remarkRecordId = remarkRecordId;
    let list: any = [];
    recordSave(values)
      .then((msg) => {
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '修改成功',
          });
          if (msg.data && msg.data) {
            list[msg.data.id] = msg.data;
          }
          setVisible5(false);
          setModifyAnnotations(list);
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: error,
        });
        setLoading(false);
      });
  };
  const onFinishMarginal = async (values: any) => {
    breakthroughLogicInTheRegione({
      id: values.id,
      regionBreakThroughLogic: marginalValue,
    })
      .then((msg) => {
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '批注成功',
          });
          // setVisible5(false);
          // setParams({ ...params, pageNo: -1, flag: false });
          // history.go(0)
          marginalList[values.id] = marginalValue;
          setMarginalList([...marginalList]);
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        setLoading(false);
        setVisible6(false);
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: error,
        });
        setLoading(false);
      });
  };

  const wxShareConfig = async () => {
    // callOnBD(["ZhuGe"])
    const msg = await getWxAccessToken({
      url: window.location.href,
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareTimeline'],
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['selectEnterpriseContact'],
        // success: function (res) {
        //   // 回调
        // },
        // fail: function (res) {
        //   if (res.errMsg.indexOf('function not exist') > -1) {
        //     alert('版本过低请升级')
        //   }
        // }
      });
    }
    // @ts-ignore
    wx.invoke(
      'selectEnterpriseContact',
      {
        fromDepartmentId: 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        mode: 'multi', // 必填，选择模式，single表示单选，multi表示多选
        type: ['department', 'user'], // 必填，选择限制类型，指定department、user中的一个或者多个
      },
      function (res: any) {
        if (res.err_msg == 'selectEnterpriseContact:ok') {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result); //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          var selectedDepartmentList = res.result.departmentList; // 已选的部门列表
          for (let i = 0; i < selectedDepartmentList.length; i++) {
            let department = selectedDepartmentList[i];
            let departmentId = department.id; // 已选的单个部门ID
            Toast.show({
              icon: 'success',
              content: departmentId,
            });
          }
          var selectedUserList = res.result.userList; // 已选的成员列表
          let list = [];
          for (let i = 0; i < selectedUserList.length; i++) {
            let user = selectedUserList[i];
            let userId = user.id; // 已选的单个成员ID
            list.push(userId);
          }
          setUserId(list);
          callOnBD(list);
        }
      },
    );
  };
  const wxShareConfigImg = async (rowId:number) => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
        success: function (res) {
          // 回调
        },
      });
      window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function (res) {
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'normal', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds;
          localIds.map(async (item: any, index: number) => {
            await window.wx.uploadImage({
              localId: item.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
              isShowProgressTips: 1, // 默认为1，显示进度提示
              success: function (res: { serverId: any }) {
                let serverId = res.serverId; // 返回图片的服务器端ID
                getQyWxImg({mediaId:serverId})
                .then((ret:any)=>{
                  saveScreenshot({
                    id:rowId,
                    appointmentVisitScreenshot:ret
                  }).then((imgs:any)=>{
                    if(imgs.retCode == 0){
                      mediaImage[rowId] = ret
                      setMediaImage([...mediaImage])
                      Toast.show({
                        icon: 'success',
                        content: '已上传',
                      });
                    }
                  })
                })
              },
              errMsg: function () {
                Toast.show({
                  icon: 'fail',
                  content: '上传失败',
                });
                setLoading(false);
                return;
              },
            });
          });
          // onValuesChange();
        },
      });
    }
  };
  const callOnBD = async (list: any) => {
    const msg = await getUserInfo({
      userIds: list,
    });
    console.log(msg.data);
    let lists: any = [];
    msg.data.map((item: any, key: number) => {
      lists.push(item.name);
    });
    formMore?.setFieldsValue({ visitContent: lists.join('、') });
    // const arr = new Set(lists)
    // console.log([arr]);
  };
  const redisInit = async (teamFlag:boolean) => {
    const msg = await redisCount({teamFlag:teamFlag});
    // const arr = new Set(lists)
    // console.log([arr]);
    if (msg.retCode === 0) {
      setRedisList(msg.data);
    }
  };
  const dataFlagValue = (list: any) => {
    formMore?.setFieldsValue(list);
    if (list.payAVisit) {
      setVisible(true);
      setvisitResult(list.visitResult);
      setvisitSignType(list.visitSignType);
      setUserId(list.userId);
      setTimeMore(list.timeMore);
      setTimes(list.times);
      if (list.value && list.value.length > 0 && list.value[0]) {
        setmarketNameId(list.value[0].id);
        formMore.setFieldValue('belongMarketName', list.value[0].marketName);
      }
    }
  };
  useEffect(() => {
    if (dataFlag) {
      dataFlagValue(dataFlag);
    }

    let userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        userAgent,
      )
    ) {
      console.log('前端是移动端');
      setiPhone(true);
    } else {
      console.log('前端是pc端');
      setiPhone(false);
    }
  }, []);

  const handelUserName = async (value: any) => {
    console.log(value);
    callOnBD(value);
    setNameVisible(false);
    setParams({ ...params, pageNo: -1, userIds: value });
  };
  const handleUserNameCancel = async () => {
    setNameVisible(false);
  };
  const displayMap: any = {
    0: '未知',
    1: '独立展厅',
    2: '露天市场',
    3: '大棚市场',
    4: '室内市场',
  };
  const degreeIntentMap: any = {
    1: '30天以上',
    2: '14天-30天',
    3: '7-14天',
    4: '3天内',
    5: '1天内',
    6: '3天-七天',
  };
  const OnVisitSign = () => {
    let list: any = [];
    typelist({})
      .then((any) => {
        if (any.retCode === 0 && any.data.length > 0) {
          any.data.map((item: any, index: number) => {
            list.push({
              label: item.name,
              value: item.type,
            });
          });
          console.log(list);
          setTypeList([list]);
          setvisitSignTypeVisible(true);
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.show({
          icon: 'fail',
          content: '拜访类型获取失败',
        });
      });
  };
  const stateEnum = [
    { name: '待拜访', value: 0, redis: 'toBeVisited' },
    { name: '已计划', value: 6, redis: 'plannedQuantity' },
    { name: '已备注', value: 4, redis: 'quantityNoted' },
    { name: '已批注', value: 5, redis: 'annotatedQuantity' },
    { name: '已改约', value: 1, redis: 'quantityChanged' },
    {
      name: '已取消',
      value: 3,
      redis: 'canceledQuantity',
    },
  ];
  const appraiseTypeEnum = [
    { name: '全部', value: 0 },
    { name: '极好', value: 1 },
    {
      name: '好',
      value: 2,
    },
    { name: '中', value: 3 },
    {
      name: '差',
      value: 4,
    },
    { name: '待沟通', value: 5 },
  ];
  const stateMap: any = {
    0: '待拜访',
    1: '已改约',
    3: '已取消',
    6: '已驳回',
    4: '已备注',
  };
  return (
    <div className={styles.page}>
      {loading && (
        <Login
          className={styles.loading}
          style={{ height: '90%', bottom: '0' }}
        ></Login>
      )}
      <div className={styles.all}>
        <Navbar name={'拜访中心'}></Navbar>
        <Form layout="horizontal">
          <Form.Item
            label={
              <div
                className={styles.team}
                onClick={() => {
                  team();
                }}
              >
                <span>{params.teamFlag ? '团队' : '个人'}</span>
                {/* <span className={styles.team_triangles}></span> */}
                <img
                  style={{ marginTop: '3px' }}
                  src={切换}
                  width={10}
                  alt=""
                />
              </div>
            }
            extra={
              <div
                className={styles.extraPart}
                onClick={() => {
                  history.push('./programme');
                }}
              >
                <AddSquareOutline />
                <span>新建计划</span>
              </div>
            }
          >
            <SearchBar
              onSearch={onSearch}
              placeholder="输入手机号商户或BD查询"
            />
          </Form.Item>
        </Form>
        <div className={styles.tabs}>
          <Tabs
            style={{
              '--title-font-size': '14px',
            }}
            defaultActiveKey={tabIndex}
            onChange={onChange}
          >
            {stateEnum.map((item, index) => (
              <Tabs.Tab
                title={<Badge content={redisList[item.redis] || "0"}>{item.name}</Badge>}
                key={item.value}
              />
            ))}
          </Tabs>
          <div
            className={styles.extraPart}
            style={{ marginRight: '5px',minWidth:"20%" }}
            onClick={() => {
              setVisible(true);
            }}
          >
            <span>更多筛选</span>
            <FilterOutline />
          </div>
        </div>
        <div
          className={styles.content}
          style={{ overflow: `${loading ? '' : ''}` }}
        >
          <>
            <PullToRefresh
              onRefresh={async () => {
                await sleep(1000);
                // setCheckState({ index: 0, check: checkState.check });
                // reload();
                setLoading(true);
                history.go(0);
              }}
            >
              <>
                {data &&
                  data.map((item: any, index: number) => {
                    return (
                      <Card
                        key={`data-${index}`}
                        title={
                          <div>
                            <span
                              style={{ fontWeight: '400', fontSize: '14px' }}
                            >
                              {item.visitSignTypeName}
                            </span>
                            <span
                              style={{
                                marginLeft: '53px',
                                fontWeight: '400',
                                fontSize: '14px',
                              }}
                            >
                              拜访
                              {`${item.numberOfVisits}` != ''
                                ? item.numberOfVisits + 1
                                : 0}
                              次
                            </span>
                            {item.focusCompanyVisit && (
                              <span
                                style={{
                                  marginLeft: '15px',
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  color: 'red',
                                }}
                              >
                                重点商户
                              </span>
                            )}
                          </div>
                        }
                        extra={
                          <div
                            style={{
                              color: '#FF5705',
                              fontWeight: '400',
                              fontSize: '12px',
                            }}
                          >
                            {item.status === 4 &&
                              item.visitSignType != 12 &&
                              item.visitSignType != 13 && (
                                <a
                                  style={{ marginRight: '5px' }}
                                  onClick={() => {
                                    // wxOnMenuShareConfig();
                                    history.push(
                                      `/remarkDetail/${item.id}`,
                                      item,
                                    );
                                  }}
                                >
                                  查看批注详情
                                </a>
                              )}
                            {stateMap[item.status]}
                          </div>
                        }
                        style={{
                          borderRadius: '8px',
                          display:
                            !checkState.check ||
                            checkState.index == index ||
                            item.status != 4
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <div className={styles.content}>
                          <div className={styles.content_0}>
                            <div>计划ID</div>
                            <span>{item.id || 0}</span>
                          </div>
                          {item.focusCompanyVisit && (
                          <div className={styles.content_img}>
                            <div>预约拜访截图</div>
                            {(mediaImage[item.id] || item.appointmentVisitScreenshot) && (
                              <img onClick={()=>{
                                setAllImage(mediaImage[item.id] || item.appointmentVisitScreenshot)
                                setImageVisible(true);
                              }} src={mediaImage[item.id] || item.appointmentVisitScreenshot} alt="" />
                            )}
                            <div
                              className={styles.increaseIcon}
                              onClick={() => {
                                wxShareConfigImg(item.id);
                              }}
                            >
                              <AddOutline />
                            </div>
                          </div>
                          )}
                          {item.visitSignType !== 13 ? (
                            <>
                              {item.visitSignType !== 12 ? (
                                <div className={styles.content_0}>
                                  <div>拜访车商</div>
                                  <span>
                                    {item.companyName ? item.companyName : '-'}
                                  </span>
                                </div>
                              ) : (
                                <div
                                  className={styles.content}
                                  onClick={() => {
                                    if (item.visitSignType === 12) {
                                      history.push('./callOnSb', item);
                                      return;
                                    }
                                    const data = item;
                                    if (item.mCarDealerVisit) {
                                      data.visitContent =
                                        item.mCarDealerVisit.visitContent;
                                    }
                                    // data.type = 0;
                                    // if (item.status === 4) {
                                    //   data.edit = true;
                                    //   history.push('./see', data);
                                    // } else {
                                    //   // history.push('./awaitingDetail', data);
                                    // }
                                  }}
                                >
                                  <div className={styles.com}>
                                    {item.visitCompanyRelations &&
                                      item.visitCompanyRelations.map(
                                        (child: any, childIndex: number) => {
                                          return (
                                            <div
                                              className={styles.completed}
                                              key={`${index}-${childIndex}-wait`}
                                              style={{
                                                display:
                                                  moreMap[`${index}`] ||
                                                  childIndex < 5
                                                    ? 'flex'
                                                    : 'none',
                                              }}
                                            >
                                              <div
                                                className={styles.completed_0}
                                                style={{ opacity: '0' }}
                                              >
                                                拜访车商(
                                                {
                                                  item.visitCompanyRelations
                                                    .length
                                                }
                                                )
                                              </div>
                                              <div
                                                className={styles.completed_1}
                                              >
                                                <div
                                                  className={styles.completed_2}
                                                >
                                                  <div
                                                    style={{ fontSize: '14px' }}
                                                  >
                                                    {child.companyName}
                                                  </div>
                                                  {!child.completedFlag ? (
                                                    <span
                                                      style={{
                                                        color:
                                                          'rgba(255, 87, 5, 1)',
                                                      }}
                                                    >
                                                      待拜访
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color:
                                                          'rgba(41, 165, 31, 1)',
                                                      }}
                                                    >
                                                      已拜访
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  className={styles.completed_2}
                                                >
                                                  <div>车位数</div>
                                                  <span>
                                                    {testContent(
                                                      child.parkingNum,
                                                    ) || 0}
                                                  </span>
                                                </div>
                                                <div
                                                  className={styles.completed_2}
                                                >
                                                  <div>检测渗透率</div>
                                                  <span>
                                                    {testContent(
                                                      child.detectPermeability *
                                                        100,
                                                    ) || 0}
                                                    %
                                                  </span>
                                                </div>
                                                <div
                                                  className={styles.completed_2}
                                                >
                                                  <div>库存</div>
                                                  <span>
                                                    {child.usedNum || 0}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        },
                                      )}
                                    {item.visitCompanyRelations &&
                                      item.visitCompanyRelations.length > 4 && (
                                        <div
                                          style={{
                                            textAlign: 'right',
                                            color: 'blue',
                                          }}
                                          onClick={(e) => {
                                            moreMap[`${index}`] =
                                              !moreMap[`${index}`];
                                            setMoreMap({ ...moreMap });
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }}
                                        >
                                          {moreMap[`${index}`]
                                            ? '折叠-'
                                            : '展开+'}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div className={styles.content_0}>
                                <div>推荐车商</div>
                                <span>
                                  {item.companyName ? item.companyName : '-'}
                                </span>
                              </div>
                              {item.visitCompanyRelations &&
                                item.visitCompanyRelations.map(
                                  (visitCompany: any) => {
                                    return (
                                      <div className={styles.content_0}>
                                        <div>被推荐车商</div>
                                        <span>
                                          {visitCompany.companyName
                                            ? visitCompany.companyName
                                            : '-'}
                                        </span>
                                      </div>
                                    );
                                  },
                                )}
                            </>
                          )}
                          {item.mCompanyInfo && (
                            <>
                              <div className={styles.content_0}>
                                <div>商户库存</div>
                                <span>
                                  {item.mCompanyInfo.notUsedNum
                                    ? item.mCompanyInfo.notUsedNum
                                    : '0'}
                                </span>
                              </div>
                              <div className={styles.content_0}>
                                <div>城市</div>
                                <span>
                                  {item.mCompanyInfo.contactCityName
                                    ? item.mCompanyInfo.contactCityName
                                    : '-'}
                                </span>
                              </div>
                              <div className={styles.content_0}>
                                <div>车位数</div>
                                <span>
                                  {item.mCompanyInfo.parkingNum
                                    ? item.mCompanyInfo.parkingNum
                                    : '0'}
                                </span>
                              </div>
                              <div className={styles.content_0}>
                                <div>市场</div>
                                <span>
                                  {item.mCompanyInfo.belongMarketName
                                    ? item.mCompanyInfo.belongMarketName
                                    : '-'}
                                </span>
                              </div>
                              <div className={styles.content_0}>
                                <div>展厅</div>
                                <span>
                                  {item.mCompanyInfo.displayType
                                    ? displayMap[item.mCompanyInfo.displayType]
                                    : '-'}
                                </span>
                              </div>
                              <div className={styles.content_0}>
                                <div>集采次数</div>
                                <span>
                                  {item.mCompanyInfo.collectiveProcurementNum ||
                                    '0'}
                                </span>
                              </div>
                              <div className={styles.content_0}>
                                <div>意向度</div>
                                <span>
                                  {degreeIntentMap[
                                    item.mCompanyInfo.degreeIntent
                                  ] || '-'}
                                </span>
                              </div>
                            </>
                          )}
                          {item.carDealerMedias &&
                            item.carDealerMedias.length > 0 && (
                              <>
                                <div className={styles.content_0}>
                                  <div>自媒体信息</div>
                                  <div>
                                    {item.carDealerMedias &&
                                    item.carDealerMedias.length > 0
                                      ? item.carDealerMedias.length
                                      : '无'}
                                  </div>
                                </div>
                                {item.carDealerMedias.map((itemMedias: any) => {
                                  return (
                                    <div className={styles.tabs_content_1}>
                                      <div className={styles.tabs_content_1_0}>
                                        <div>自媒体</div>
                                        <div>
                                          {itemMedias.mediaName
                                            ? itemMedias.mediaName
                                            : '无'}
                                        </div>
                                      </div>
                                      <div className={styles.tabs_content_1_0}>
                                        <div>账号</div>
                                        <div>
                                          {itemMedias.account
                                            ? itemMedias.account
                                            : '无'}
                                        </div>
                                      </div>
                                      <div className={styles.tabs_content_1_0}>
                                        <div>粉丝量</div>
                                        <div>
                                          {itemMedias.fansNum
                                            ? itemMedias.fansNum
                                            : '0'}
                                          万
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          {item.carDealerCompetitors &&
                            item.carDealerCompetitors.length > 0 && (
                              <>
                                <div className={styles.content_0}>
                                  <div>竞队信息</div>
                                  <div>
                                    {item.carDealerCompetitors &&
                                    item.carDealerCompetitors.length > 0
                                      ? item.carDealerCompetitors.length
                                      : '无'}
                                  </div>
                                </div>
                                {item.carDealerCompetitors.map(
                                  (itemPartners: any) => {
                                    return (
                                      <div className={styles.tabs_content_1}>
                                        <div
                                          className={styles.tabs_content_1_0}
                                        >
                                          <div>检测机构</div>
                                          <div>
                                            {itemPartners.partnerName
                                              ? itemPartners.partnerName
                                              : '无'}
                                          </div>
                                        </div>
                                        <div
                                          className={styles.tabs_content_1_0}
                                        >
                                          <div>合作时间</div>
                                          <div>
                                            {itemPartners.cooperationTime
                                              ? dateFormat(
                                                  itemPartners.cooperationTime,
                                                  'yyyy-MM-dd hh:mm:ss',
                                                )
                                              : '-'}
                                          </div>
                                        </div>
                                        <div
                                          className={styles.tabs_content_1_0}
                                        >
                                          <div>集采余额</div>
                                          <div>
                                            {itemPartners.purchaseBalance
                                              ? itemPartners.purchaseBalance
                                              : '0'}
                                            元
                                          </div>
                                        </div>
                                        <div
                                          className={styles.tabs_content_1_0}
                                        >
                                          <div>硬广覆盖</div>
                                          <div>
                                            {itemPartners.hardCover === 1
                                              ? '是'
                                              : '否'}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </>
                            )}
                          <div className={styles.content_0}>
                            <div>姓名</div>
                            <span>{item.userName ? item.userName : '-'}</span>
                          </div>
                          {item.checkInPictures &&
                            item.checkInPictures.map(
                              (img: any, key: number) => {
                                return (
                                  <div key={key} className={styles.content_img}>
                                    <div>{img.urlName}</div>
                                    <span>
                                      <img
                                        src={img.url}
                                        onClick={() => {
                                          setImageVisible(true);
                                          setAllImage(img.url);
                                        }}
                                      />
                                    </span>
                                  </div>
                                );
                              },
                            )}
                          {/* {item.visitSignType !== 12 ? ( */}
                          <div className={styles.content_0}>
                            <div>实际拜访时间</div>
                            <span>
                              {item.actualVisitTime
                                ? dayjs(item.actualVisitTime).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                  )
                                : '-'}
                            </span>
                          </div>
                          <div className={styles.content_0}>
                            <div>拜访时间</div>
                            <span>
                              {item.visitingTime
                                ? dayjs(item.visitingTime).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                  )
                                : '-'}
                            </span>
                          </div>
                          {item.nextVisitTime && (
                          <div className={styles.content_0}>
                            <div>下次拜访时间</div>
                            <span>
                              {item.nextVisitTime
                                ? dayjs(item.nextVisitTime).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                  )
                                : '-'}
                            </span>
                          </div>)}
                          {/* ) : (
                            <></>
                          )} */}
                          {item.visitSignType != 12 &&
                            item.visitSignType != 13 && (
                              <div className={styles.content_0}>
                                <div>目标金额</div>
                                <span>
                                  {item.targetAmount ? item.targetAmount : 0}元
                                </span>
                              </div>
                            )}
                          {item.visitSignType != 12 &&
                            item.visitSignType != 13 && (
                              <div className={styles.content_0}>
                                <div>目标方案</div>
                                <span>
                                  {item.targetPlanType === 2 && '集采城市限价' }
                                  {item.targetPlanType === 1 && '形象店' }
                                  {item.targetPlanType === 0 && '产品买赠方案'}
                                </span>
                              </div>
                            )}
                          {item.productName && (
                            <div className={styles.content_0}>
                              <div>检测产品</div>
                              <span>
                                {/* {item.visitGoodsVos.map((child:any)=>{
                                  return <>{child.goodsOriginName}、</>
                                })} */}
                                {item.productName}
                              </span>
                            </div>
                          )}
                          {item.visitSignType !== 12 &&
                            item.visitSignType != 13 && item.status != 5 && (
                              <>
                                {item.mCarDealerVisit ? (
                                  <div className={styles.content_0}>
                                    <div>商户建议</div>
                                    <span>
                                      {item.mCarDealerVisit.visitContent || '-'}
                                    </span>
                                    {item.status >= 4 && item.status != 6 ? (
                                      item && item.visitResults === false ? (
                                        <img
                                          src={
                                            item.visitSignType === 1 ||
                                            item.visitSignType === 5 ||
                                            item.visitSignType === 2 ||
                                            item.visitSignType === 3 ||
                                            item.visitSignType === 4 ||
                                            item.visitSignType === 11
                                              ? 待在拜访
                                              : 失败
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={
                                            item.visitSignType === 2 ||
                                            item.visitSignType === 3 ||
                                            item.visitSignType === 4 ||
                                            item.visitSignType === 11
                                              ? `${
                                                  item.visitSignType === 1 ||
                                                  item.visitSignType === 5
                                                    ? 注册上线
                                                    : 拜访成交
                                                }`
                                              : 成功
                                          }
                                          alt=""
                                        />
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <div className={styles.content_0}>
                                    <div>商户建议</div>
                                    <span>{item.visitContent || '-'}</span>
                                    {item.status >= 4 && item.status != 6 ? (
                                      item && item.visitResults === false ? (
                                        <img src={失败} alt="" />
                                      ) : (
                                        <img src={成功} alt="" />
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          {item.visitSummary && (
                            <div className={styles.content_0}>
                              <div>拜访总结</div>
                              <span>{item.visitSummary || ''}</span>
                            </div>
                          )}
                          {item.breakThroughLogic && (
                            <div className={styles.content_0}>
                              <div>突破逻辑</div>
                              <span>{item.breakThroughLogic || ''}</span>
                              <div
                                className={styles.remark_content_header_0}
                                style={{ width: 'auto', lineHeight: '1.5' }}
                                onClick={() => {
                                  setVisible6(true);
                                  setMarginalValue('');
                                  setRecord(item);
                                }}
                              >
                                批注
                              </div>
                            </div>
                          )}
                          {marginalList && marginalList[item.id] ? (
                            <div className={styles.content_0}>
                              <div>突破逻辑（批注）</div>
                              <span>{marginalList[item.id]}</span>
                              <div
                                className={styles.remark_content_header_0}
                                style={{ width: 'auto', lineHeight: '1.5' }}
                                onClick={() => {
                                  setVisible6(true);
                                  setMarginalValue(marginalList[item.id]);
                                  setRecord(item);
                                }}
                              >
                                修改
                              </div>
                            </div>
                          ) : (
                            <>
                              {item.regionBreakThroughLogic && (
                                <div className={styles.content_0}>
                                  <div>突破逻辑（批注）</div>
                                  <span>
                                    {item.regionBreakThroughLogic || ''}
                                  </span>
                                  <div
                                    className={styles.remark_content_header_0}
                                    style={{ width: 'auto', lineHeight: '1.5' }}
                                    onClick={() => {
                                      setVisible6(true);
                                      setMarginalValue(
                                        item.regionBreakThroughLogic,
                                      );
                                      setRecord(item);
                                    }}
                                  >
                                    修改
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {item.status === 1 && (
                            <div className={styles.content_0}>
                              <div>改约原因</div>
                              <span>
                                {item.amendmentRemark
                                  ? item.amendmentRemark
                                  : '-'}
                              </span>
                            </div>
                          )}
                          {item.status === 4 &&
                            item.mCarDealerVisit &&
                            item.mCarDealerVisit.reasonForFailure && (
                              <div className={styles.content_0}>
                                <div>商户托词</div>
                                <span>
                                  {item.mCarDealerVisit.reasonForFailure
                                    ? item.mCarDealerVisit.reasonForFailure
                                    : '-'}
                                </span>
                              </div>
                            )}
                          {item.status === 3 && (
                            <div className={styles.content_0}>
                              <div>取消原因</div>
                              <span>
                                {item.cancelRemark ? item.cancelRemark : '-'}
                              </span>
                            </div>
                          )}
                          {item.status === 6 && (
                            <div className={styles.content_0}>
                              <div>驳回原因</div>
                              <span>
                                {item.cancelRemark ? item.cancelRemark : '-'}
                              </span>
                            </div>
                          )}
                          {/* <div className={styles.content_0}>
                              <div>重点商户</div>
                              <span>{item.focusCompanyVisit ? "是" : '否'}</span>
                            </div> */}
                        </div>
                        {item.status >= 4 &&
                        item.visitSignType != 12 &&
                        item.visitSignType != 13 &&
                        item.status != 6 &&
                        item.status != 5 ? (
                          <div className={styles.footer_1}>
                            <div className={styles.footer_b}>
                              <div>批注信息</div>
                              <div
                                className={styles.footer_button_2}
                                onClick={() => {
                                  remarkRecord(item.id);
                                }}
                              >
                                {newRemark[item.id]
                                  ? '查看全部批注'
                                  : '查看最新批注'}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {item.status >= 4 &&
                          item.visitSignType != 12 &&
                          item.visitSignType != 13 &&
                          item.status != 6  &&
                          item.status != 5 && (
                            <>
                              {newRemark[item.id] ? (
                                <>
                                  {newRemarkList &&
                                    newRemarkList.length > 0 &&
                                    newRemarkList.map(
                                      (child: any, mCarIndex: number) => {
                                        return (
                                          <div
                                            key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                                          >
                                            <div
                                              className={styles.parent_label}
                                              // key={`mCarDealerVisitVo-${mCarIndex}-${index}`}
                                              style={{ fontSize: '16px' }}
                                            >{`${child.labelName}`}</div>
                                            {child.mRemarkRecordDetails.length >
                                              0 &&
                                              child.mRemarkRecordDetails.map(
                                                (
                                                  pchildren: any,
                                                  pcIndex: number,
                                                ) => {
                                                  return (
                                                    <div>
                                                      <div
                                                        className={
                                                          styles.footer
                                                        }
                                                      >
                                                        {/* <div
                                                          className={
                                                            styles.footer_2
                                                          }
                                                          key={`${pcIndex}-wait`}
                                                          style={{
                                                            fontSize: '12px',
                                                            paddingBottom: 0,
                                                            fontWeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          <div
                                                            className={`${styles.footer_2_title} ${styles.status}`}
                                                          >
                                                            <span
                                                              className={
                                                                styles.label_name
                                                              }
                                                            >{`${
                                                              mCarIndex + 1
                                                            }.${pcIndex + 1} ${
                                                              pchildren.labelName
                                                            }`}</span>
                                                          </div>
                                                        </div> */}
                                                      </div>
                                                      {pchildren
                                                        .mRemarkRecordDetails
                                                        .length > 0 &&
                                                        pchildren.mRemarkRecordDetails.map(
                                                          (
                                                            children: any,
                                                            rIndex: number,
                                                          ) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  styles.footer
                                                                }
                                                                key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                                                style={{
                                                                  display:
                                                                    moreMap[
                                                                      `${pcIndex}`
                                                                    ] ||
                                                                    rIndex < 3
                                                                      ? 'block'
                                                                      : 'none',
                                                                }}
                                                              >
                                                                <div
                                                                  className={
                                                                    styles.footer_2
                                                                  }
                                                                  style={{
                                                                    fontSize:
                                                                      '12px',
                                                                    paddingBottom: 0,
                                                                    fontWeight:
                                                                      'normal',
                                                                  }}
                                                                >
                                                                  <div
                                                                    className={`${styles.footer_2_title} ${styles.status}`}
                                                                  >
                                                                    <span
                                                                      className={
                                                                        styles.label_name
                                                                      }
                                                                    >
                                                                      {` ${children.labelName}`}
                                                                      1
                                                                    </span>
                                                                    <span
                                                                      className={
                                                                        styles.status
                                                                      }
                                                                    >
                                                                      已完成
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      styles.footer_2_extra
                                                                    }
                                                                  >
                                                                    这里是价值点的文案说明
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    styles.remark
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_header
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                        style={{
                                                                          position:
                                                                            'relative',
                                                                        }}
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            children.url
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'static',
                                                                          }}
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff_1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            {children.remarkLabelUserName
                                                                              ? children.remarkLabelUserName
                                                                              : '-'}{' '}
                                                                            <span>
                                                                              {parseResultName(
                                                                                `${children.appraiseType}`,
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_time
                                                                            }
                                                                          >
                                                                            {children.createTime
                                                                              ? dateFormat(
                                                                                  children.createTime,
                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                )
                                                                              : '-'}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_0
                                                                        }
                                                                        onClick={() => {
                                                                          console.log(
                                                                            remark,
                                                                          );
                                                                          remarkform.resetFields();
                                                                          setVisible4(
                                                                            true,
                                                                          );
                                                                          setRemarkChild(
                                                                            children,
                                                                          );
                                                                          setmCarDealerVisitId(
                                                                            item.id,
                                                                          );
                                                                          setRemarkValue(
                                                                            '',
                                                                          );
                                                                          setremarkRecordId(
                                                                            child.id,
                                                                          );
                                                                          setVisitPlanId(
                                                                            item.id,
                                                                          );
                                                                        }}
                                                                      >
                                                                        批注
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_foot
                                                                      }
                                                                    >
                                                                      {
                                                                        children.remarkContent
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                  {remarks &&
                                                                    remarks.length >
                                                                      0 &&
                                                                    remarks.map(
                                                                      (
                                                                        remarkItem: any,
                                                                      ) => {
                                                                        if (
                                                                          remarkItem.visitPlanId ===
                                                                            item.id &&
                                                                          remarkItem.parentId ===
                                                                            children.id
                                                                        ) {
                                                                          return (
                                                                            <div
                                                                              style={{
                                                                                background:
                                                                                  '#EFF3F9',
                                                                                marginLeft:
                                                                                  '50px',
                                                                                marginBottom:
                                                                                  '10px',
                                                                                borderRadius:
                                                                                  '8px',
                                                                              }}
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_p
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header_staff
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        className={
                                                                                          styles.remark_content_header_staff_0
                                                                                        }
                                                                                        src={
                                                                                          remarkItem.url
                                                                                        }
                                                                                        style={{
                                                                                          position:
                                                                                            'static',
                                                                                        }}
                                                                                        alt=""
                                                                                      />
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1_name
                                                                                          }
                                                                                        >
                                                                                          {
                                                                                            remarkItem.remarkLabelUserName
                                                                                          }{' '}
                                                                                          <span>
                                                                                            {' '}
                                                                                            {parseResultName(
                                                                                              `${remarkItem.appraiseType}`,
                                                                                            )}{' '}
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1_time
                                                                                          }
                                                                                        >
                                                                                          {remarkItem.createTime
                                                                                            ? dateFormat(
                                                                                                remarkItem.createTime,
                                                                                                'yyyy-MM-dd hh:mm:ss',
                                                                                              )
                                                                                            : '-'}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_child
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      remarkItem.remarkContent
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          );
                                                                        }
                                                                      },
                                                                    )}
                                                                  {children.mRemarkRecordDetails &&
                                                                    children
                                                                      .mRemarkRecordDetails
                                                                      .length >
                                                                      0 &&
                                                                    children.mRemarkRecordDetails.map(
                                                                      (
                                                                        remarkChild: any,
                                                                        key: number,
                                                                      ) => {
                                                                        console.log(
                                                                          children.mRemarkRecordDetails,
                                                                        );

                                                                        return (
                                                                          <div
                                                                            key={
                                                                              key
                                                                            }
                                                                            style={{
                                                                              background:
                                                                                '#EFF3F9',
                                                                              marginLeft:
                                                                                '50px',
                                                                              marginBottom:
                                                                                '10px',
                                                                              borderRadius:
                                                                                '8px',
                                                                            }}
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_p
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      className={
                                                                                        styles.remark_content_header_staff_0
                                                                                      }
                                                                                      src={
                                                                                        remarkChild.url
                                                                                      }
                                                                                      style={{
                                                                                        position:
                                                                                          'static',
                                                                                      }}
                                                                                      alt=""
                                                                                    />
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header_staff_1
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1_name
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          remarkChild.remarkLabelUserName
                                                                                        }{' '}
                                                                                        <span>
                                                                                          {' '}
                                                                                          {parseResultName(
                                                                                            `${remarkChild.appraiseType}`,
                                                                                          )}{' '}
                                                                                        </span>
                                                                                        {remarkChild.remarkLabelUserName ===
                                                                                          children.remarkLabelUserName && (
                                                                                          <span>
                                                                                            自评
                                                                                          </span>
                                                                                        )}
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1_time
                                                                                        }
                                                                                      >
                                                                                        {remarkChild.createTime
                                                                                          ? dateFormat(
                                                                                              remarkChild.createTime,
                                                                                              'yyyy-MM-dd hh:mm:ss',
                                                                                            )
                                                                                          : '-'}
                                                                                      </div>
                                                                                    </div>
                                                                                    {userIdSocre ===
                                                                                      remarkChild.remarkLabelUserName && (
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_0
                                                                                        }
                                                                                        onClick={() => {
                                                                                          console.log(
                                                                                            remark,
                                                                                          );

                                                                                          remarkform.resetFields();
                                                                                          setVisible5(
                                                                                            true,
                                                                                          );
                                                                                          setRemarkChild(
                                                                                            children,
                                                                                          );
                                                                                          setmCarDealerVisitId(
                                                                                            item.id,
                                                                                          );
                                                                                          setRemarkValue(
                                                                                            remarkChild.remarkContent
                                                                                          );
                                                                                          setremarkRecordId(
                                                                                            child.id,
                                                                                          );
                                                                                          setVisitPlanId(
                                                                                            item.id,
                                                                                          );
                                                                                          console.log(remarkChild.appraiseType);
                                                                                          
                                                                                          remarkform?.setFieldsValue({appraiseType:remarkChild.appraiseType})
                                                                                          setAnnotations(
                                                                                            remarkChild,
                                                                                          );
                                                                                        }}
                                                                                      >
                                                                                        修改
                                                                                      </div>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_child
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    remarkChild.remarkContent
                                                                                  }
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        );
                                                                      },
                                                                    )}
                                                                </div>
                                                              </div>
                                                            );
                                                          },
                                                        )}
                                                      {pchildren.mRemarkRecordDetails &&
                                                        pchildren
                                                          .mRemarkRecordDetails
                                                          .length > 3 && (
                                                          <div
                                                            style={{
                                                              textAlign:
                                                                'right',
                                                              color: 'blue',
                                                            }}
                                                            onClick={(e) => {
                                                              moreMap[
                                                                `${pcIndex}`
                                                              ] =
                                                                !moreMap[
                                                                  `${pcIndex}`
                                                                ];
                                                              setMoreMap({
                                                                ...moreMap,
                                                              });
                                                              e.stopPropagation();
                                                              e.preventDefault();
                                                            }}
                                                          >
                                                            {moreMap[
                                                              `${pcIndex}`
                                                            ]
                                                              ? '折叠-'
                                                              : '展开+'}
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                },
                                              )}
                                          </div>
                                        );
                                      },
                                    )}
                                </>
                              ) : (
                                <>
                                  <Tabs
                                    style={{
                                      '--title-font-size': '14px',
                                    }}
                                    className={styles.tab_appraise}
                                    onChange={(v) => {
                                      onAppraiseChange(
                                        v,
                                        item.companyId,
                                        item.id,
                                        item.status,
                                      );
                                    }}
                                  >
                                    {appraiseTypeEnum.map((item, index) => (
                                      <Tabs.Tab
                                        title={item.name}
                                        key={item.value}
                                      />
                                    ))}
                                  </Tabs>
                                  {appraiseList &&
                                  appraiseCheckState != 0 &&
                                  appraiseList[item.id] ? (
                                    appraiseList[item.id].length > 0 ? (
                                      appraiseList[item.id].map(
                                        (child: any, mCarIndex: number) => {
                                          return (
                                            <div
                                              key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                                            >
                                              <div
                                                className={styles.parent_label}
                                                style={{ fontSize: '16px' }}
                                              >{`${child.labelName}`}</div>
                                              {child.mRemarkRecordDetails
                                                .length > 0 &&
                                                child.mRemarkRecordDetails.map(
                                                  (
                                                    pchildren: any,
                                                    pcIndex: number,
                                                  ) => {
                                                    return (
                                                      <div>
                                                        <div
                                                          className={
                                                            styles.footer
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              styles.footer_2
                                                            }
                                                            // key={`${pcIndex}-wait`}
                                                            style={{
                                                              fontSize: '12px',
                                                              paddingBottom: 0,
                                                              fontWeight:
                                                                'normal',
                                                              // display:
                                                              //   pcIndex < 3
                                                              //     ? 'block'
                                                              //     : 'none',
                                                            }}
                                                          >
                                                            <div
                                                              className={`${styles.footer_2_title} ${styles.status}`}
                                                            >
                                                              <span
                                                                className={
                                                                  styles.label_name
                                                                }
                                                              >{`${pchildren.labelName}`}</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {pchildren.mRemarkRecordDetails &&
                                                          pchildren
                                                            .mRemarkRecordDetails
                                                            .length > 0 &&
                                                          pchildren.mRemarkRecordDetails.map(
                                                            (
                                                              children: any,
                                                              rIndex: number,
                                                            ) => {
                                                              // pchildren.mRemarkRecordDetails.sort(
                                                              //   (
                                                              //     a: any,
                                                              //     b: any,
                                                              //   ) => {
                                                              //     if (
                                                              //       a.appraiseType <
                                                              //       3
                                                              //     ) {
                                                              //       return 1;
                                                              //     } else {
                                                              //       return -1;
                                                              //     }
                                                              //   },
                                                              // );
                                                              return (
                                                                <div
                                                                  className={
                                                                    styles.footer
                                                                  }
                                                                  key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                                                  style={{
                                                                    display:
                                                                      moreMap[
                                                                        `${pcIndex}`
                                                                      ] ||
                                                                      rIndex < 3
                                                                        ? 'block'
                                                                        : 'none',
                                                                  }}
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'relative',
                                                                          }}
                                                                        >
                                                                          <img
                                                                            className={
                                                                              styles.remark_content_header_staff_0
                                                                            }
                                                                            src={
                                                                              children.url
                                                                            }
                                                                            style={{
                                                                              position:
                                                                                'static',
                                                                            }}
                                                                            alt=""
                                                                          />
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff_1_name
                                                                              }
                                                                            >
                                                                              {children.remarkLabelUserName
                                                                                ? children.remarkLabelUserName
                                                                                : '-'}{' '}
                                                                              <span>
                                                                                {parseResultName(
                                                                                  `${children.appraiseType}`,
                                                                                )}
                                                                              </span>
                                                                            </div>
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff_1_time
                                                                              }
                                                                            >
                                                                              {children.createTime
                                                                                ? dateFormat(
                                                                                    children.createTime,
                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                  )
                                                                                : '-'}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_0
                                                                          }
                                                                          onClick={() => {
                                                                            console.log(
                                                                              remark,
                                                                            );

                                                                            remarkform.resetFields();
                                                                            setVisible4(
                                                                              true,
                                                                            );
                                                                            setRemarkChild(
                                                                              children,
                                                                            );
                                                                            setmCarDealerVisitId(
                                                                              item.id,
                                                                            );
                                                                            setRemarkValue(
                                                                              '',
                                                                            );
                                                                            setremarkRecordId(
                                                                              child.id,
                                                                            );
                                                                            setVisitPlanId(
                                                                              item.id,
                                                                            );
                                                                          }}
                                                                        >
                                                                          批注
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_foot
                                                                        }
                                                                      >
                                                                        {
                                                                          children.remarkContent
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                    {remark &&
                                                                      remark.length >
                                                                        0 &&
                                                                      remark.map(
                                                                        (
                                                                          remarkItem: any,
                                                                          key: number,
                                                                        ) => {
                                                                          if (
                                                                            remarkItem.visitPlanId ===
                                                                              item.id &&
                                                                            remarkItem.parentId ===
                                                                              children.id
                                                                          ) {
                                                                            return (
                                                                              <div
                                                                                key={
                                                                                  key
                                                                                }
                                                                                style={{
                                                                                  background:
                                                                                    '#EFF3F9',
                                                                                  marginLeft:
                                                                                    '50px',
                                                                                  marginBottom:
                                                                                    '10px',
                                                                                  borderRadius:
                                                                                    '8px',
                                                                                }}
                                                                              >
                                                                                {modifyAnnotations &&
                                                                                modifyAnnotations[
                                                                                  remarkItem
                                                                                    .id
                                                                                ] ? (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_p
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff
                                                                                          }
                                                                                        >
                                                                                          <img
                                                                                            className={
                                                                                              styles.remark_content_header_staff_0
                                                                                            }
                                                                                            src={
                                                                                              modifyAnnotations[
                                                                                                remarkItem
                                                                                                  .id
                                                                                              ]
                                                                                                .url
                                                                                            }
                                                                                            style={{
                                                                                              position:
                                                                                                'static',
                                                                                            }}
                                                                                            alt=""
                                                                                          />
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1
                                                                                            }
                                                                                          >
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_name
                                                                                              }
                                                                                            >
                                                                                              {
                                                                                                modifyAnnotations[
                                                                                                  remarkItem
                                                                                                    .id
                                                                                                ]
                                                                                                  .remarkLabelUserName
                                                                                              }{' '}
                                                                                              <span>
                                                                                                {' '}
                                                                                                {parseResultName(
                                                                                                  `${
                                                                                                    modifyAnnotations[
                                                                                                      remarkItem
                                                                                                        .id
                                                                                                    ]
                                                                                                      .appraiseType
                                                                                                  }`,
                                                                                                )}{' '}
                                                                                              </span>
                                                                                              {modifyAnnotations[
                                                                                                remarkItem
                                                                                                  .id
                                                                                              ]
                                                                                                .remarkLabelUserName ===
                                                                                                children.remarkLabelUserName && (
                                                                                                <span>
                                                                                                  自评
                                                                                                </span>
                                                                                              )}
                                                                                            </div>
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_time
                                                                                              }
                                                                                            >
                                                                                              {modifyAnnotations[
                                                                                                remarkItem
                                                                                                  .id
                                                                                              ]
                                                                                                .createTime
                                                                                                ? dateFormat(
                                                                                                    modifyAnnotations[
                                                                                                      remarkItem
                                                                                                        .id
                                                                                                    ]
                                                                                                      .createTime,
                                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                                  )
                                                                                                : '-'}
                                                                                            </div>
                                                                                          </div>
                                                                                          {
                                                                                            modifyAnnotations[remarkItem.id].remarkLabelUserName === userIdSocre && (
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_0
                                                                                            }
                                                                                            onClick={() => {
                                                                                              console.log(
                                                                                                remark,
                                                                                              );
                                                                                              remarkform.resetFields();
                                                                                              setVisible5(
                                                                                                true,
                                                                                              );
                                                                                              setRemarkChild(
                                                                                                children,
                                                                                              );
                                                                                              setmCarDealerVisitId(
                                                                                                item.id,
                                                                                              );
                                                                                              setRemarkValue(
                                                                                                modifyAnnotations[remarkItem.id].remarkContent
                                                                                              );
                                                                                              setremarkRecordId(
                                                                                                child.id,
                                                                                              );
                                                                                              setVisitPlanId(
                                                                                                item.id,
                                                                                              );
                                                                                              console.log(modifyAnnotations[remarkItem.id].appraiseType);
                                                                                              remarkform?.setFieldsValue({appraiseType:modifyAnnotations[remarkItem.id].appraiseType})
                                                                                              setAnnotations(
                                                                                                modifyAnnotations[
                                                                                                  remarkItem
                                                                                                    .id
                                                                                                ],
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            修改
                                                                                          </div>)
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_child
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          modifyAnnotations[
                                                                                            remarkItem
                                                                                              .id
                                                                                          ]
                                                                                            .remarkContent
                                                                                        }
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_p
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff
                                                                                          }
                                                                                        >
                                                                                          <img
                                                                                            className={
                                                                                              styles.remark_content_header_staff_0
                                                                                            }
                                                                                            src={
                                                                                              remarkItem.url
                                                                                            }
                                                                                            style={{
                                                                                              position:
                                                                                                'static',
                                                                                            }}
                                                                                            alt=""
                                                                                          />
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1
                                                                                            }
                                                                                          >
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_name
                                                                                              }
                                                                                            >
                                                                                              {
                                                                                                remarkItem.remarkLabelUserName
                                                                                              }{' '}
                                                                                              <span>
                                                                                                {' '}
                                                                                                {parseResultName(
                                                                                                  `${remarkItem.appraiseType}`,
                                                                                                )}{' '}
                                                                                              </span>
                                                                                              {remarkItem.remarkLabelUserName ===
                                                                                                children.remarkLabelUserName && (
                                                                                                <span>
                                                                                                  自评
                                                                                                </span>
                                                                                              )}
                                                                                            </div>
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_time
                                                                                              }
                                                                                            >
                                                                                              {remarkItem.createTime
                                                                                                ? dateFormat(
                                                                                                    remarkItem.createTime,
                                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                                  )
                                                                                                : '-'}
                                                                                            </div>
                                                                                          </div>
                                                                                          {remarkItem.remarkLabelUserName === userIdSocre && (
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_0
                                                                                            }
                                                                                            onClick={() => {
                                                                                              console.log(
                                                                                                remark,
                                                                                              );
                                                                                              remarkform.resetFields();
                                                                                              setVisible5(
                                                                                                true,
                                                                                              );
                                                                                              setRemarkChild(
                                                                                                children,
                                                                                              );
                                                                                              setmCarDealerVisitId(
                                                                                                item.id,
                                                                                              );
                                                                                              setRemarkValue(
                                                                                                remarkItem.remarkContent
                                                                                              );
                                                                                              setremarkRecordId(
                                                                                                child.id,
                                                                                              );
                                                                                              setVisitPlanId(
                                                                                                item.id,
                                                                                              );
                                                                                              console.log(remarkItem.appraiseType);
                                                                                              remarkform?.setFieldsValue({appraiseType:remarkItem.appraiseType})
                                                                                              setAnnotations(
                                                                                                remarkItem,
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            修改
                                                                                          </div>
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_child
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          remarkItem.remarkContent
                                                                                        }
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            );
                                                                          }
                                                                        },
                                                                      )}
                                                                    {children.mRemarkRecordDetails &&
                                                                      children
                                                                        .mRemarkRecordDetails
                                                                        .length >
                                                                        0 &&
                                                                      children.mRemarkRecordDetails.map(
                                                                        (
                                                                          remarkChild: any,
                                                                          key: number,
                                                                        ) => {
                                                                          // children.mRemarkRecordDetails.sort(
                                                                          //   (
                                                                          //     a: any,
                                                                          //     b: any,
                                                                          //   ) => {
                                                                          //     if (
                                                                          //       a.appraiseType <=
                                                                          //       3
                                                                          //     ) {
                                                                          //       return 1;
                                                                          //     } else {
                                                                          //       return -1;
                                                                          //     }
                                                                          //   },
                                                                          // );
                                                                          return (
                                                                            <div
                                                                              key={
                                                                                key
                                                                              }
                                                                              style={{
                                                                                background:
                                                                                  '#EFF3F9',
                                                                                marginLeft:
                                                                                  '50px',
                                                                                marginBottom:
                                                                                  '10px',
                                                                                borderRadius:
                                                                                  '8px',
                                                                              }}
                                                                            >
                                                                              {modifyAnnotations &&
                                                                              modifyAnnotations[
                                                                                remarkChild
                                                                                  .id
                                                                              ] ? (
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_p
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff
                                                                                        }
                                                                                      >
                                                                                        <img
                                                                                          className={
                                                                                            styles.remark_content_header_staff_0
                                                                                          }
                                                                                          src={
                                                                                            modifyAnnotations[
                                                                                              remarkChild
                                                                                                .id
                                                                                            ]
                                                                                              .url
                                                                                          }
                                                                                          style={{
                                                                                            position:
                                                                                              'static',
                                                                                          }}
                                                                                          alt=""
                                                                                        />
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1
                                                                                          }
                                                                                        >
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1_name
                                                                                            }
                                                                                          >
                                                                                            {
                                                                                              modifyAnnotations[
                                                                                                remarkChild
                                                                                                  .id
                                                                                              ]
                                                                                                .remarkLabelUserName
                                                                                            }{' '}
                                                                                            <span>
                                                                                              {' '}
                                                                                              {parseResultName(
                                                                                                `${
                                                                                                  modifyAnnotations[
                                                                                                    remarkChild
                                                                                                      .id
                                                                                                  ]
                                                                                                    .appraiseType
                                                                                                }`,
                                                                                              )}{' '}
                                                                                            </span>
                                                                                            {modifyAnnotations[
                                                                                              remarkChild
                                                                                                .id
                                                                                            ]
                                                                                              .remarkLabelUserName ===
                                                                                              children.remarkLabelUserName && (
                                                                                              <span>
                                                                                                自评
                                                                                              </span>
                                                                                            )}
                                                                                          </div>
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1_time
                                                                                            }
                                                                                          >
                                                                                            {modifyAnnotations[
                                                                                              remarkChild
                                                                                                .id
                                                                                            ]
                                                                                              .createTime
                                                                                              ? dateFormat(
                                                                                                  modifyAnnotations[
                                                                                                    remarkChild
                                                                                                      .id
                                                                                                  ]
                                                                                                    .createTime,
                                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                                )
                                                                                              : '-'}
                                                                                          </div>
                                                                                        </div>
                                                                                        {modifyAnnotations[
                                                                                              remarkChild
                                                                                                .id
                                                                                            ]
                                                                                              .remarkLabelUserName === userIdSocre && (
                                                                                                <div
                                                                                                  className={
                                                                                                    styles.remark_content_header_0
                                                                                                  }
                                                                                                  onClick={() => {
                                                                                                    console.log(
                                                                                                      remark,
                                                                                                    );
                                                                                                    remarkform.resetFields();
                                                                                                    setVisible5(
                                                                                                      true,
                                                                                                    );
                                                                                                    setRemarkChild(
                                                                                                      children,
                                                                                                    );
                                                                                                    setmCarDealerVisitId(
                                                                                                      item.id,
                                                                                                    );
                                                                                                    setRemarkValue(
                                                                                                      modifyAnnotations[remarkChild.id].remarkContent
                                                                                                    );
                                                                                                    setremarkRecordId(
                                                                                                      child.id,
                                                                                                    );
                                                                                                    setVisitPlanId(
                                                                                                      item.id,
                                                                                                    );
                                                                                                    console.log(modifyAnnotations[remarkChild.id].appraiseType);
                                                                                                    remarkform?.setFieldsValue({appraiseType:modifyAnnotations[remarkChild.id].appraiseType})
                                                                                                    setAnnotations(
                                                                                                      modifyAnnotations[
                                                                                                        remarkChild
                                                                                                          .id
                                                                                                      ],
                                                                                                    );
                                                                                                  }}
                                                                                                >
                                                                                                  修改
                                                                                                </div>
                                                                                              )}
                                                                                      </div>
                                                                                    </div>
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_child
                                                                                      }
                                                                                    >
                                                                                      {
                                                                                        modifyAnnotations[
                                                                                          remarkChild
                                                                                            .id
                                                                                        ]
                                                                                          .remarkContent
                                                                                      }
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              ) : (
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_p
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff
                                                                                        }
                                                                                      >
                                                                                        <img
                                                                                          className={
                                                                                            styles.remark_content_header_staff_0
                                                                                          }
                                                                                          src={
                                                                                            remarkChild.url
                                                                                          }
                                                                                          style={{
                                                                                            position:
                                                                                              'static',
                                                                                          }}
                                                                                          alt=""
                                                                                        />
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1
                                                                                          }
                                                                                        >
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1_name
                                                                                            }
                                                                                          >
                                                                                            {
                                                                                              remarkChild.remarkLabelUserName
                                                                                            }{' '}
                                                                                            <span>
                                                                                              {' '}
                                                                                              {parseResultName(
                                                                                                `${remarkChild.appraiseType}`,
                                                                                              )}{' '}
                                                                                            </span>
                                                                                            {remarkChild.remarkLabelUserName ===
                                                                                              children.remarkLabelUserName && (
                                                                                              <span>
                                                                                                自评
                                                                                              </span>
                                                                                            )}
                                                                                          </div>
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1_time
                                                                                            }
                                                                                          >
                                                                                            {remarkChild.createTime
                                                                                              ? dateFormat(
                                                                                                  remarkChild.createTime,
                                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                                )
                                                                                              : '-'}
                                                                                          </div>
                                                                                        </div>
                                                                                        {remarkChild.remarkLabelUserName === userIdSocre && (
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_0
                                                                                          }
                                                                                          onClick={() => {
                                                                                            console.log(
                                                                                              remark,
                                                                                            );
                                                                                            remarkform.resetFields();
                                                                                            setVisible5(
                                                                                              true,
                                                                                            );
                                                                                            setRemarkChild(
                                                                                              children,
                                                                                            );
                                                                                            setmCarDealerVisitId(
                                                                                              item.id,
                                                                                            );
                                                                                            setRemarkValue(
                                                                                              remarkChild.remarkContent
                                                                                            );
                                                                                            setremarkRecordId(
                                                                                              child.id,
                                                                                            );
                                                                                            setVisitPlanId(
                                                                                              item.id,
                                                                                            );
                                                                                            console.log(remarkChild.appraiseType);
                                                                                            remarkform?.setFieldsValue({appraiseType:remarkChild.appraiseType})
                                                                                            setAnnotations(
                                                                                              remarkChild,
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          修改
                                                                                        </div>
                                                                                        )}
                                                                                      </div>
                                                                                    </div>
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_child
                                                                                      }
                                                                                    >
                                                                                      {
                                                                                        remarkChild.remarkContent
                                                                                      }
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            </div>
                                                                          );
                                                                        },
                                                                      )}
                                                                  </div>
                                                                </div>
                                                              );
                                                            },
                                                          )}
                                                        {pchildren.mRemarkRecordDetails &&
                                                          pchildren
                                                            .mRemarkRecordDetails
                                                            .length > 3 && (
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  'right',
                                                                color: 'blue',
                                                              }}
                                                              onClick={(e) => {
                                                                moreMap[
                                                                  `${pcIndex}`
                                                                ] =
                                                                  !moreMap[
                                                                    `${pcIndex}`
                                                                  ];
                                                                setMoreMap({
                                                                  ...moreMap,
                                                                });
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                              }}
                                                            >
                                                              {moreMap[
                                                                `${pcIndex}`
                                                              ]
                                                                ? '折叠-'
                                                                : '展开+'}
                                                            </div>
                                                          )}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                            </div>
                                          );
                                        },
                                      )
                                    ) : (
                                      <Empty description="暂无价值点" />
                                    )
                                  ) : (
                                    <>
                                      {item.mRemarkRecordDetails &&
                                        item.mRemarkRecordDetails.map(
                                          (child: any, mCarIndex: number) => {
                                            return (
                                              <div
                                                key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                                              >
                                                <div
                                                  className={
                                                    styles.parent_label
                                                  }
                                                  style={{ fontSize: '16px' }}
                                                >{`${child.labelName}`}</div>
                                                {child.mRemarkRecordDetails
                                                  .length > 0 &&
                                                  child.mRemarkRecordDetails.map(
                                                    (
                                                      pchildren: any,
                                                      pcIndex: number,
                                                    ) => {
                                                      return (
                                                        <div>
                                                          <div
                                                            className={
                                                              styles.footer
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles.footer_2
                                                              }
                                                              // key={`${pcIndex}-wait`}
                                                              style={{
                                                                fontSize:
                                                                  '12px',
                                                                paddingBottom: 0,
                                                                fontWeight:
                                                                  'normal',
                                                                // display:
                                                                //   pcIndex < 3
                                                                //     ? 'block'
                                                                //     : 'none',
                                                              }}
                                                            >
                                                              <div
                                                                className={`${styles.footer_2_title} ${styles.status}`}
                                                              >
                                                                <span
                                                                  className={
                                                                    styles.label_name
                                                                  }
                                                                >{`${pchildren.labelName}`}</span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {pchildren
                                                            .mRemarkRecordDetails
                                                            .length > 0 &&
                                                            pchildren.mRemarkRecordDetails.map(
                                                              (
                                                                children: any,
                                                                rIndex: number,
                                                              ) => {
                                                                // pchildren.mRemarkRecordDetails.sort(
                                                                //   (
                                                                //     a: any,
                                                                //     b: any,
                                                                //   ) => {
                                                                //     if (
                                                                //       a.appraiseType <
                                                                //       3
                                                                //     ) {
                                                                //       return 1;
                                                                //     } else {
                                                                //       return -1;
                                                                //     }
                                                                //   },
                                                                // );
                                                                return (
                                                                  <div
                                                                    className={
                                                                      styles.footer
                                                                    }
                                                                    key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                                                    style={{
                                                                      display:
                                                                        moreMap[
                                                                          `${pcIndex}`
                                                                        ] ||
                                                                        rIndex <
                                                                          3
                                                                          ? 'block'
                                                                          : 'none',
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff
                                                                            }
                                                                            style={{
                                                                              position:
                                                                                'relative',
                                                                            }}
                                                                          >
                                                                            <img
                                                                              className={
                                                                                styles.remark_content_header_staff_0
                                                                              }
                                                                              src={
                                                                                children.url
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  'static',
                                                                              }}
                                                                              alt=""
                                                                            />
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff_1
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1_name
                                                                                }
                                                                              >
                                                                                {children.remarkLabelUserName
                                                                                  ? children.remarkLabelUserName
                                                                                  : '-'}{' '}
                                                                                <span>
                                                                                  {parseResultName(
                                                                                    `${children.appraiseType}`,
                                                                                  )}
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1_time
                                                                                }
                                                                              >
                                                                                {children.createTime
                                                                                  ? dateFormat(
                                                                                      children.createTime,
                                                                                      'yyyy-MM-dd hh:mm:ss',
                                                                                    )
                                                                                  : '-'}
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_0
                                                                            }
                                                                            onClick={() => {
                                                                              console.log(
                                                                                remark,
                                                                              );

                                                                              remarkform.resetFields();
                                                                              setVisible4(
                                                                                true,
                                                                              );
                                                                              setRemarkChild(
                                                                                children,
                                                                              );
                                                                              setmCarDealerVisitId(
                                                                                item.id,
                                                                              );
                                                                              setRemarkValue(
                                                                                '',
                                                                              );
                                                                              setremarkRecordId(
                                                                                child.id,
                                                                              );
                                                                              setVisitPlanId(
                                                                                item.id,
                                                                              );
                                                                            }}
                                                                          >
                                                                            批注
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_foot
                                                                          }
                                                                        >
                                                                          {
                                                                            children.remarkContent
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                      {remark &&
                                                                        remark.length >
                                                                          0 &&
                                                                        remark.map(
                                                                          (
                                                                            remarkItem: any,
                                                                            key: number,
                                                                          ) => {
                                                                            if (
                                                                              remarkItem.visitPlanId ===
                                                                                item.id &&
                                                                              remarkItem.parentId ===
                                                                                children.id
                                                                            ) {
                                                                              return (
                                                                                <div
                                                                                  key={
                                                                                    key
                                                                                  }
                                                                                  style={{
                                                                                    background:
                                                                                      '#EFF3F9',
                                                                                    marginLeft:
                                                                                      '50px',
                                                                                    marginBottom:
                                                                                      '10px',
                                                                                    borderRadius:
                                                                                      '8px',
                                                                                  }}
                                                                                >
                                                                                  {modifyAnnotations &&
                                                                                  modifyAnnotations[
                                                                                    remarkItem
                                                                                      .id
                                                                                  ] ? (
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_p
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header
                                                                                          }
                                                                                        >
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff
                                                                                            }
                                                                                          >
                                                                                            <img
                                                                                              className={
                                                                                                styles.remark_content_header_staff_0
                                                                                              }
                                                                                              src={
                                                                                                modifyAnnotations[
                                                                                                  remarkItem
                                                                                                    .id
                                                                                                ]
                                                                                                  .url
                                                                                              }
                                                                                              style={{
                                                                                                position:
                                                                                                  'static',
                                                                                              }}
                                                                                              alt=""
                                                                                            />
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1
                                                                                              }
                                                                                            >
                                                                                              <div
                                                                                                className={
                                                                                                  styles.remark_content_header_staff_1_name
                                                                                                }
                                                                                              >
                                                                                                {
                                                                                                  modifyAnnotations[
                                                                                                    remarkItem
                                                                                                      .id
                                                                                                  ]
                                                                                                    .remarkLabelUserName
                                                                                                }{' '}
                                                                                                <span>
                                                                                                  {' '}
                                                                                                  {parseResultName(
                                                                                                    `${
                                                                                                      modifyAnnotations[
                                                                                                        remarkItem
                                                                                                          .id
                                                                                                      ]
                                                                                                        .appraiseType
                                                                                                    }`,
                                                                                                  )}{' '}
                                                                                                </span>
                                                                                                {modifyAnnotations[
                                                                                                  remarkItem
                                                                                                    .id
                                                                                                ]
                                                                                                  .remarkLabelUserName ===
                                                                                                  children.remarkLabelUserName && (
                                                                                                  <span>
                                                                                                    自评
                                                                                                  </span>
                                                                                                )}
                                                                                              </div>
                                                                                              <div
                                                                                                className={
                                                                                                  styles.remark_content_header_staff_1_time
                                                                                                }
                                                                                              >
                                                                                                {modifyAnnotations[
                                                                                                  remarkItem
                                                                                                    .id
                                                                                                ]
                                                                                                  .createTime
                                                                                                  ? dateFormat(
                                                                                                      modifyAnnotations[
                                                                                                        remarkItem
                                                                                                          .id
                                                                                                      ]
                                                                                                        .createTime,
                                                                                                      'yyyy-MM-dd hh:mm:ss',
                                                                                                    )
                                                                                                  : '-'}
                                                                                              </div>
                                                                                            </div>
                                                                                            {modifyAnnotations[
                                                                                                  remarkItem
                                                                                                    .id
                                                                                                ].remarkLabelUserName === userIdSocre && (
                                                                                                  <div
                                                                                                    className={
                                                                                                      styles.remark_content_header_0
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                      console.log(
                                                                                                        remark,
                                                                                                      );
                                                                                                      remarkform.resetFields();
                                                                                                      setVisible5(
                                                                                                        true,
                                                                                                      );
                                                                                                      setRemarkChild(
                                                                                                        children,
                                                                                                      );
                                                                                                      setmCarDealerVisitId(
                                                                                                        item.id,
                                                                                                      );
                                                                                                      setRemarkValue(
                                                                                                        modifyAnnotations[remarkItem.id].remarkContent
                                                                                                      );
                                                                                                      setremarkRecordId(
                                                                                                        child.id,
                                                                                                      );
                                                                                                      setVisitPlanId(
                                                                                                        item.id,
                                                                                                      );
                                                                                                      console.log(modifyAnnotations[remarkItem.id].appraiseType);
                                                                                                      remarkform?.setFieldsValue({appraiseType:modifyAnnotations[remarkItem.id].appraiseType})
                                                                                                      setAnnotations(
                                                                                                        modifyAnnotations[
                                                                                                          remarkItem
                                                                                                            .id
                                                                                                        ],
                                                                                                      );
                                                                                                    }}
                                                                                                  >
                                                                                                    修改
                                                                                                  </div>

                                                                                                )}
                                                                                          </div>
                                                                                        </div>
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_child
                                                                                          }
                                                                                        >
                                                                                          {
                                                                                            modifyAnnotations[
                                                                                              remarkItem
                                                                                                .id
                                                                                            ]
                                                                                              .remarkContent
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_p
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header
                                                                                          }
                                                                                        >
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff
                                                                                            }
                                                                                          >
                                                                                            <img
                                                                                              className={
                                                                                                styles.remark_content_header_staff_0
                                                                                              }
                                                                                              src={
                                                                                                remarkItem.url
                                                                                              }
                                                                                              style={{
                                                                                                position:
                                                                                                  'static',
                                                                                              }}
                                                                                              alt=""
                                                                                            />
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1
                                                                                              }
                                                                                            >
                                                                                              <div
                                                                                                className={
                                                                                                  styles.remark_content_header_staff_1_name
                                                                                                }
                                                                                              >
                                                                                                {
                                                                                                  remarkItem.remarkLabelUserName
                                                                                                }{' '}
                                                                                                <span>
                                                                                                  {' '}
                                                                                                  {parseResultName(
                                                                                                    `${remarkItem.appraiseType}`,
                                                                                                  )}{' '}
                                                                                                </span>
                                                                                                {remarkItem.remarkLabelUserName ===
                                                                                                  children.remarkLabelUserName && (
                                                                                                  <span>
                                                                                                    自评
                                                                                                  </span>
                                                                                                )}
                                                                                              </div>
                                                                                              <div
                                                                                                className={
                                                                                                  styles.remark_content_header_staff_1_time
                                                                                                }
                                                                                              >
                                                                                                {remarkItem.createTime
                                                                                                  ? dateFormat(
                                                                                                      remarkItem.createTime,
                                                                                                      'yyyy-MM-dd hh:mm:ss',
                                                                                                    )
                                                                                                  : '-'}
                                                                                              </div>
                                                                                            </div>
                                                                                            {remarkItem.remarkLabelUserName === userIdSocre && (
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_0
                                                                                              }
                                                                                              onClick={() => {
                                                                                                console.log(
                                                                                                  remark,
                                                                                                );
                                                                                                remarkform.resetFields();
                                                                                                setVisible5(
                                                                                                  true,
                                                                                                );
                                                                                                setRemarkChild(
                                                                                                  children,
                                                                                                );
                                                                                                setmCarDealerVisitId(
                                                                                                  item.id,
                                                                                                );
                                                                                                setRemarkValue(
                                                                                                  remarkItem.remarkContent
                                                                                                );
                                                                                                setremarkRecordId(
                                                                                                  child.id,
                                                                                                );
                                                                                                setVisitPlanId(
                                                                                                  item.id,
                                                                                                );
                                                                                                console.log(remarkItem.appraiseType);
                                                                                                remarkform?.setFieldsValue({appraiseType:remarkItem.appraiseType})
                                                                                                setAnnotations(
                                                                                                  remarkItem,
                                                                                                );
                                                                                              }}
                                                                                            >
                                                                                              修改
                                                                                            </div>

                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_child
                                                                                          }
                                                                                        >
                                                                                          {
                                                                                            remarkItem.remarkContent
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  )}
                                                                                </div>
                                                                              );
                                                                            }
                                                                          },
                                                                        )}
                                                                      {children.mRemarkRecordDetails &&
                                                                        children
                                                                          .mRemarkRecordDetails
                                                                          .length >
                                                                          0 &&
                                                                        children.mRemarkRecordDetails.map(
                                                                          (
                                                                            remarkChild: any,
                                                                            key: number,
                                                                          ) => {
                                                                            // children.mRemarkRecordDetails.sort(
                                                                            //   (
                                                                            //     a: any,
                                                                            //     b: any,
                                                                            //   ) => {
                                                                            //     if (
                                                                            //       a.appraiseType <=
                                                                            //       3
                                                                            //     ) {
                                                                            //       return 1;
                                                                            //     } else {
                                                                            //       return -1;
                                                                            //     }
                                                                            //   },
                                                                            // );
                                                                            return (
                                                                              <div
                                                                                key={
                                                                                  key
                                                                                }
                                                                                style={{
                                                                                  background:
                                                                                    '#EFF3F9',
                                                                                  marginLeft:
                                                                                    '50px',
                                                                                  marginBottom:
                                                                                    '10px',
                                                                                  borderRadius:
                                                                                    '8px',
                                                                                }}
                                                                              >
                                                                                {modifyAnnotations &&
                                                                                modifyAnnotations[
                                                                                  remarkChild
                                                                                    .id
                                                                                ] ? (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_p
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff
                                                                                          }
                                                                                        >
                                                                                          <img
                                                                                            className={
                                                                                              styles.remark_content_header_staff_0
                                                                                            }
                                                                                            src={
                                                                                              modifyAnnotations[
                                                                                                remarkChild
                                                                                                  .id
                                                                                              ]
                                                                                                .url
                                                                                            }
                                                                                            style={{
                                                                                              position:
                                                                                                'static',
                                                                                            }}
                                                                                            alt=""
                                                                                          />
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1
                                                                                            }
                                                                                          >
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_name
                                                                                              }
                                                                                            >
                                                                                              {
                                                                                                modifyAnnotations[
                                                                                                  remarkChild
                                                                                                    .id
                                                                                                ]
                                                                                                  .remarkLabelUserName
                                                                                              }{' '}
                                                                                              <span>
                                                                                                {' '}
                                                                                                {parseResultName(
                                                                                                  `${
                                                                                                    modifyAnnotations[
                                                                                                      remarkChild
                                                                                                        .id
                                                                                                    ]
                                                                                                      .appraiseType
                                                                                                  }`,
                                                                                                )}{' '}
                                                                                              </span>
                                                                                              {modifyAnnotations[
                                                                                                remarkChild
                                                                                                  .id
                                                                                              ]
                                                                                                .remarkLabelUserName ===
                                                                                                children.remarkLabelUserName && (
                                                                                                <span>
                                                                                                  自评
                                                                                                </span>
                                                                                              )}
                                                                                            </div>
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_time
                                                                                              }
                                                                                            >
                                                                                              {modifyAnnotations[
                                                                                                remarkChild
                                                                                                  .id
                                                                                              ]
                                                                                                .createTime
                                                                                                ? dateFormat(
                                                                                                    modifyAnnotations[
                                                                                                      remarkChild
                                                                                                        .id
                                                                                                    ]
                                                                                                      .createTime,
                                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                                  )
                                                                                                : '-'}
                                                                                            </div>
                                                                                          </div>
                                                                                          {modifyAnnotations[
                                                                                                remarkChild
                                                                                                  .id
                                                                                              ]
                                                                                                .remarkLabelUserName === userIdSocre && (
                                                                                                  <div
                                                                                                    className={
                                                                                                      styles.remark_content_header_0
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                      console.log(
                                                                                                        remark,
                                                                                                      );
                                                                                                      remarkform.resetFields();
                                                                                                      setVisible5(
                                                                                                        true,
                                                                                                      );
                                                                                                      setRemarkChild(
                                                                                                        children,
                                                                                                      );
                                                                                                      setmCarDealerVisitId(
                                                                                                        item.id,
                                                                                                      );
                                                                                                      setRemarkValue(
                                                                                                        modifyAnnotations[remarkChild.id].remarkContent
                                                                                                      );
                                                                                                      setremarkRecordId(
                                                                                                        child.id,
                                                                                                      );
                                                                                                      setVisitPlanId(
                                                                                                        item.id,
                                                                                                      );
                                                                                                      console.log(modifyAnnotations[remarkChild.id].appraiseType);
                                                                                                      remarkform?.setFieldsValue({appraiseType:modifyAnnotations[remarkChild.id].appraiseType})
                                                                                                      setAnnotations(
                                                                                                        modifyAnnotations[
                                                                                                          remarkChild
                                                                                                            .id
                                                                                                        ],
                                                                                                      );
                                                                                                    }}
                                                                                                  >
                                                                                                    修改
                                                                                                  </div>
                                                                                                )}
                                                                                        </div>
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_child
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          modifyAnnotations[
                                                                                            remarkChild
                                                                                              .id
                                                                                          ]
                                                                                            .remarkContent
                                                                                        }
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_p
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff
                                                                                          }
                                                                                        >
                                                                                          <img
                                                                                            className={
                                                                                              styles.remark_content_header_staff_0
                                                                                            }
                                                                                            src={
                                                                                              remarkChild.url
                                                                                            }
                                                                                            style={{
                                                                                              position:
                                                                                                'static',
                                                                                            }}
                                                                                            alt=""
                                                                                          />
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1
                                                                                            }
                                                                                          >
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_name
                                                                                              }
                                                                                            >
                                                                                              {
                                                                                                remarkChild.remarkLabelUserName
                                                                                              }{' '}
                                                                                              <span>
                                                                                                {' '}
                                                                                                {parseResultName(
                                                                                                  `${remarkChild.appraiseType}`,
                                                                                                )}{' '}
                                                                                              </span>
                                                                                              {remarkChild.remarkLabelUserName ===
                                                                                                children.remarkLabelUserName && (
                                                                                                <span>
                                                                                                  自评
                                                                                                </span>
                                                                                              )}
                                                                                            </div>
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_time
                                                                                              }
                                                                                            >
                                                                                              {remarkChild.createTime
                                                                                                ? dateFormat(
                                                                                                    remarkChild.createTime,
                                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                                  )
                                                                                                : '-'}
                                                                                            </div>
                                                                                          </div>
                                                                                          {remarkChild.remarkLabelUserName === userIdSocre && (
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_0
                                                                                            }
                                                                                            onClick={() => {
                                                                                              console.log(
                                                                                                remark,
                                                                                              );
                                                                                              remarkform.resetFields();
                                                                                              setVisible5(
                                                                                                true,
                                                                                              );
                                                                                              setRemarkChild(
                                                                                                children,
                                                                                              );
                                                                                              setmCarDealerVisitId(
                                                                                                item.id,
                                                                                              );
                                                                                              setRemarkValue(
                                                                                                remarkChild.remarkContent
                                                                                              );
                                                                                              setremarkRecordId(
                                                                                                child.id,
                                                                                              );
                                                                                              setVisitPlanId(
                                                                                                item.id,
                                                                                              );
                                                                                              console.log(remarkChild.appraiseType);
                                                                                              remarkform?.setFieldsValue({appraiseType:remarkChild.appraiseType})
                                                                                              setAnnotations(
                                                                                                remarkChild,
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            修改
                                                                                          </div>
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_child
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          remarkChild.remarkContent
                                                                                        }
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            );
                                                                          },
                                                                        )}
                                                                    </div>
                                                                  </div>
                                                                );
                                                              },
                                                            )}
                                                          {pchildren.mRemarkRecordDetails &&
                                                            pchildren
                                                              .mRemarkRecordDetails
                                                              .length > 3 && (
                                                              <div
                                                                style={{
                                                                  textAlign:
                                                                    'right',
                                                                  color: 'blue',
                                                                }}
                                                                onClick={(
                                                                  e,
                                                                ) => {
                                                                  moreMap[
                                                                    `${pcIndex}`
                                                                  ] =
                                                                    !moreMap[
                                                                      `${pcIndex}`
                                                                    ];
                                                                  setMoreMap({
                                                                    ...moreMap,
                                                                  });
                                                                  e.stopPropagation();
                                                                  e.preventDefault();
                                                                }}
                                                              >
                                                                {moreMap[
                                                                  `${pcIndex}`
                                                                ]
                                                                  ? '折叠-'
                                                                  : '展开+'}
                                                              </div>
                                                            )}
                                                        </div>
                                                      );
                                                    },
                                                  )}
                                              </div>
                                            );
                                          },
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        <div className={styles.footer}>
                          <div className={styles.content_0}>
                            <div>创建人</div>
                            <span>
                              {item.createUserName ? item.createUserName : '-'}{' '}
                              {item.createTime
                                ? dateFormat(
                                    item.createTime,
                                    'yyyy-MM-dd hh:mm:ss',
                                  )
                                : '-'}
                            </span>
                          </div>
                          <div className={styles.footer_0}>
                            {item.status === 4 && item.focusCompanyVisit && (
                              <div
                                className={styles.footer_button}
                                onClick={() => {
                                  const data = item.mCompanyInfo;
                                  data.companyId = item.companyId;
                                  data.companyName = item.companyName;
                                  data.visitSignTypeName =
                                    item.visitSignTypeName;
                                  data.visitSignType = item.visitSignType;
                                  data.parentId = item.id;
                                  history.push('./programme', data);
                                }}
                              >
                                跟进
                              </div>
                            )}
                            {item.visitSignType != 12 && (
                              <div
                                className={styles.footer_button}
                                onClick={() => {
                                  let data: any = item.mCompanyInfo;
                                  data.companyId = item.companyId;
                                  data.collectKey = 1;
                                  history.push('./collect', data);
                                }}
                              >
                                下单
                              </div>
                            )}
                            {(item.status === 3 || item.status === 4) && (
                              <div
                                className={styles.footer_button}
                                onClick={() => {
                                  const data = item.mCompanyInfo;
                                  data.companyId = item.companyId;
                                  data.companyName = item.companyName;
                                  data.visitSignTypeName =
                                    item.visitSignTypeName;
                                  data.visitSignType = item.visitSignType;
                                  history.push('./programme', data);
                                }}
                              >
                                新建计划
                              </div>
                            )}
                            {item.status !== 3 && item.status !== 4 && (
                              <>
                                <div
                                  className={styles.footer_button}
                                  onClick={() => {
                                    Modal.confirm({
                                      title: '取消原因',
                                      content: (
                                        <div
                                          style={{ width: '100%' }}
                                          className={styles.forms}
                                        >
                                          <Form form={form}>
                                            <Form.Item
                                              name="forms"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: '请输入取消原因',
                                                },
                                              ]}
                                              className={styles.form_item}
                                            >
                                              <TextArea
                                                placeholder="请输入取消原因"
                                                maxLength={100}
                                                rows={5}
                                                showCount
                                                onChange={(v) => {
                                                  console.log(v);
                                                }}
                                              />
                                            </Form.Item>
                                          </Form>
                                        </div>
                                      ),
                                      onConfirm: () => {
                                        onDelete(item.id);
                                      },
                                    });
                                  }}
                                >
                                  取消
                                </div>
                                {item.status !== 1 && item.status != 5 && (
                                  <div
                                    className={styles.footer_button}
                                    onClick={() => {
                                      setTimeId(item.id);
                                      if (item.focusCompanyVisit) {
                                        forms?.setFieldsValue({
                                          focusCompanyVisit: 1,
                                        });
                                      } else {
                                        forms?.setFieldsValue({
                                          focusCompanyVisit: 0,
                                        });
                                      }
                                      Modal.confirm({
                                        title: '改约',
                                        content: (
                                          <div style={{ width: '100%' }}>
                                            <Form form={forms}>
                                              <List>
                                                <List.Item
                                                  prefix="改约时间"
                                                  onClick={() => {
                                                    setTimeVisible(true);
                                                  }}
                                                >
                                                  <Form.Item
                                                    name="forms"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          '请选择改约时间',
                                                      },
                                                    ]}
                                                    initialValue={
                                                      item.visitingTime
                                                    }
                                                    className={styles.form_item}
                                                  >
                                                    <Input
                                                      placeholder="请选择改约时间"
                                                      readOnly
                                                    />
                                                  </Form.Item>
                                                </List.Item>
                                                <List.Item prefix="重点商户">
                                                  <Form.Item
                                                    name="focusCompanyVisit"
                                                    className={styles.form_item}
                                                  >
                                                    <Radio.Group>
                                                      <Radio value={0}>
                                                        否
                                                      </Radio>
                                                      <Radio value={1}>
                                                        是
                                                      </Radio>
                                                    </Radio.Group>
                                                  </Form.Item>
                                                </List.Item>
                                                <Form.Item name="remarkValue">
                                                  <TextArea
                                                    placeholder="请输入改约原因"
                                                    maxLength={100}
                                                    rows={5}
                                                    showCount
                                                    onChange={(v) => {
                                                      setRemarkValue(v);
                                                    }}
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  style={{ display: 'none' }}
                                                  name="id"
                                                  initialValue={item.id}
                                                >
                                                  <Input placeholder="请选择改约时间" />
                                                </Form.Item>
                                              </List>
                                            </Form>
                                          </div>
                                        ),
                                        onConfirm: () => {
                                          // setTimeVisible(true)
                                          onAmendment();
                                        },
                                      });
                                    }}
                                  >
                                    改约
                                  </div>
                                )}
                              </>
                            )}
                            {item.visitSignType != 13 &&
                              item.userName ===
                                localStorage.getItem('name')&& (
                                <div
                                  className={styles.footer_button}
                                  onClick={() => {
                                    if (item.visitSignType === 12) {
                                      history.push('./callOnSb', item);
                                      return;
                                    }
                                    const data = item;
                                    if (item.status === 4) {
                                      data.entranceType = 3;
                                    } else {
                                      data.entranceType = 1;
                                    }
                                    if (
                                      localStorage.getItem('visitId') != item.id
                                    ) {
                                      localStorage.removeItem('values');
                                      localStorage.removeItem('visitContent');
                                      localStorage.removeItem('companyId');
                                      localStorage.removeItem('belongMarketId');
                                      localStorage.removeItem('localIdsImg');
                                      localStorage.removeItem('pointImg');
                                      localStorage.removeItem('advertiseImg');
                                      localStorage.removeItem('powerModelImg');
                                      localStorage.removeItem('premiumImg');
                                      localStorage.removeItem('doorway');
                                      localStorage.removeItem('point');
                                      localStorage.removeItem('advertise');
                                      localStorage.removeItem('powerModel');
                                      localStorage.removeItem('premium');
                                      localStorage.removeItem('visitResult');
                                    }
                                    // data.id = item.id;
                                    localStorage.setItem('visitId', item.id);
                                    // if(item.status === 5 || item.serveType != 1 ){
                                      history.push('./see', data);
                                    // }else{
                                    //   history.push('./signIn', data)
                                    // }
                                  }}
                                >
                                {/* {(item.status === 5 || item.serveType != 1) && item.status != 4  ? "备注" : "签到"} */}
                                签到
                                </div>
                              )}
                            {item.visitSignType != 13 ? (
                              <Badge
                                content={
                                  !item.clickFlag &&
                                  (item.status === 0 || item.status === 1) &&
                                  localStorage.getItem('isLeaderInDept') === '1'
                                    ? '未查看'
                                    : ''
                                }
                                style={{ '--right': '80%' }}
                              >
                                <div
                                  className={styles.footer_button}
                                  onClick={() => {
                                    if (item.visitSignType === 12) {
                                      history.push('./callOnSb', item);
                                      return;
                                    }
                                    const data = item;
                                    data.type = 0;
                                    if (item.mCarDealerVisit) {
                                      data.visitContent =
                                        item.mCarDealerVisit.visitContent;
                                    }
                                    if (item.status === 4) {
                                      data.status = 4;
                                      data.edit = true;
                                      if (
                                        localStorage.getItem('visitId') !=
                                        item.id
                                      ) {
                                        localStorage.removeItem('values');
                                        localStorage.removeItem('visitContent');
                                        localStorage.removeItem('visitResult');
                                        localStorage.removeItem('companyId');
                                        localStorage.removeItem(
                                          'belongMarketId',
                                        );
                                        localStorage.removeItem('localIdsImg');
                                        localStorage.removeItem('pointImg');
                                        localStorage.removeItem('advertiseImg');
                                        localStorage.removeItem(
                                          'powerModelImg',
                                        );
                                        localStorage.removeItem('premiumImg');
                                        localStorage.removeItem('doorway');
                                        localStorage.removeItem('point');
                                        localStorage.removeItem('advertise');
                                        localStorage.removeItem('powerModel');
                                        localStorage.removeItem('premium');
                                        localStorage.removeItem('visitResult');
                                      }
                                      history.push('./see', data);
                                    } else {
                                      history.push('./awaitingDetail', data);
                                    }
                                  }}
                                >
                                  详情
                                </div>
                              </Badge>
                            ) : (
                              <div>
                                {localStorage.getItem('isLeaderInDept') ===
                                  '1' &&
                                  (item.status === 0 || item.status === 1) && (
                                    <div
                                      className={styles.footer_button}
                                      onClick={() => {
                                        Modal.confirm({
                                          title: '驳回原因',
                                          content: (
                                            <div className={styles.forms}>
                                              <Form form={form}>
                                                <Form.Item
                                                  name="forms"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: '请输入驳回原因',
                                                    },
                                                  ]}
                                                  className={styles.form_item}
                                                >
                                                  <TextArea
                                                    placeholder="请输入驳回原因"
                                                    maxLength={100}
                                                    rows={5}
                                                    showCount
                                                    onChange={(v) => {
                                                      console.log(v);
                                                    }}
                                                  />
                                                </Form.Item>
                                              </Form>
                                            </div>
                                          ),
                                          onConfirm: () => {
                                            onDeletes(item.id);
                                          },
                                        });
                                      }}
                                    >
                                      驳回
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </Card>
                    );
                  })}
              </>
              {data.length > 0 && !checkState.check ? (
                <InfiniteScroll
                  loadMore={async () => {
                    if (checkState.check) {
                      return;
                    }
                    await loadMore({ pageNo: params.pageNo + 1, flag: true });
                  }}
                  hasMore={hasMore}
                />
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    color: '#9c9797',
                    marginTop: '10px',
                  }}
                >
                  暂无数据
                </div>
              )}
            </PullToRefresh>
          </>
        </div>
        <DatePicker
          visible={timeVisible}
          onClose={() => {
            setTimeVisible(false);
          }}
          title={'选择改约时间'}
          precision="minute"
          min={new Date()}
          renderLabel={labelRenderer}
          onConfirm={(val) => {
            const year = dayjs(val).year();
            const month = dayjs(val).month() + 1;
            const date = dayjs(val).date();
            const hour = dayjs(val).hour();
            const minute = dayjs(val).minute();
            let mon;
            let day;
            let hou;
            let min;
            if (month < 10) {
              mon = `0${month}`;
            } else {
              mon = month;
            }
            if (date < 10) {
              day = `0${date}`;
            } else {
              day = date;
            }
            if (hour < 10) {
              hou = `0${hour}`;
            } else {
              hou = hour;
            }
            if (minute < 10) {
              min = `0${minute}`;
            } else {
              min = minute;
            }
            const msg = `${year}-${mon}-${day} ${hou}:${min}`;
            forms?.setFieldsValue({ forms: msg });
          }}
        />
        <Popup
          visible={visible}
          showCloseButton
          onClose={() => {
            setVisible(false);
          }}
          bodyStyle={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            minHeight: '50vh',
          }}
        >
          <div>
            <div className={styles.more}>更多筛选</div>
            <Form form={formMore}>
              <List>
                <List.Item
                  prefix="拜访结果"
                  onClick={() => {
                    setvisitResultVisible(true);
                  }}
                >
                  <Form.Item
                    name="visitResultName"
                    className={styles.form_item}
                  >
                    <Input placeholder="选择拜访结果" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="拜访时间段"
                  onClick={() => {
                    setVisibleTime(true);
                  }}
                >
                  <Form.Item name="time" className={styles.form_item}>
                    <Input placeholder="选择拜访时间段" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="拜访BD"
                  onClick={() => {
                    // wxShareConfig();
                    // setNameVisible(true)
                    if (iPhone) {
                      wxShareConfig();
                    } else {
                      setNameVisible(true);
                    }
                  }}
                >
                  <Form.Item name="visitContent" className={styles.form_item}>
                    <Input placeholder="选择拜访BD" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="选择市场"
                  onClick={() => {
                    const values = formMore.getFieldsValue();
                    values.payAVisit = true;
                    values.visitResult = visitResult;
                    values.userId = userId;
                    values.timeMore = timeMore;
                    values.times = times;
                    values.visitSignType = visitSignType;
                    history.replace('./market', values);
                  }}
                >
                  <Form.Item
                    name="belongMarketName"
                    className={styles.form_item}
                  >
                    <Input placeholder="选择市场" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="拜访类型"
                  onClick={() => {
                    OnVisitSign();
                  }}
                >
                  <Form.Item
                    name="visitSignTypeName"
                    className={styles.form_item}
                  >
                    <Input placeholder="选择拜访类型" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="重点商户">
                  <Form.Item
                    name="focusCompanyVisit"
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={0}>否</Radio>
                      <Radio value={1}>是</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
              </List>
            </Form>
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  formMore.resetFields();
                  setTimeMore(null);
                  setTimes([]);
                  setvisitResult(-1);
                  setUserId([]);
                  setmarketNameId(-1);
                  setvisitSignType(-1);
                  reset();
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  setVisible(false);
                  const values = formMore.getFieldsValue();
                  if (times.length > 0) {
                    params.startTime = times[0];
                    params.endTime = times[1];
                  }
                  if (visitResult != -1) {
                    params.visitResults = visitResult;
                  }
                  if (marketNameId != -1) {
                    params.belongMarketIds = [marketNameId];
                  }
                  if (userId.length > 0) {
                    params.userIds = userId;
                  }
                  if (visitSignType != -1) {
                    params.visitSignType = visitSignType;
                  }
                  if (values.focusCompanyVisit === 1) {
                    params.focusCompanyVisit = 1;
                  }
                  if (values.focusCompanyVisit === 0) {
                    params.focusCompanyVisit = 0;
                  }
                  // setParams({ ...params, ...pm });
                  setParams({ ...params, pageNo: -1, flag: false });
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
        <Picker
          columns={visitResultLists}
          visible={visitResultVisible}
          onCancel={() => {
            setvisitResultVisible(false);
          }}
          onConfirm={(value, extend) => {
            setvisitResult(value[0]);
            formMore.setFieldValue('visitResultName', extend.items[0]?.label);
            setvisitResultVisible(false);
          }}
        />
        <Popup
          visible={visibleTime}
          showCloseButton
          onClose={() => {
            setVisibleTime(false);
          }}
          position="right"
          bodyStyle={{ width: '100vw' }}
        >
          {/* {mockContent} */}
          <div>
            <div className={styles.mores}>选择时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setTimeMore(val);
              }}
              value={timeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (timeMore != null) {
                    // console.log(dateFormat(timeMore[0],"Y-MM-dd hh:mm:ss"));
                    let startTime = dateFormat(timeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(timeMore[1], 'Y-MM-dd');
                    const times = [startTime, endTime];
                    setTimes(times);
                    formMore.setFieldValue('time', `${startTime}~${endTime}`);
                    setVisibleTime(false);
                  }
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
        <Picker
          columns={typeList}
          visible={visitSignTypeVisible}
          onCancel={() => {
            setvisitSignTypeVisible(false);
          }}
          onConfirm={(value, extend) => {
            setvisitSignType(value[0]);
            formMore.setFieldValue('visitSignTypeName', extend.items[0]?.label);
            setvisitSignTypeVisible(false);
          }}
        />
        <div className={styles.visible4}>
          <Popup
            visible={visible4}
            showCloseButton
            onClose={() => {
              setVisible4(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '40vh',
              paddingBottom: '20px',
            }}
          >
            {/* {console.log(remarkChild)} */}

            <Form form={remarkform}>
              <List>
                <div className={styles.formList}>
                  <div className={styles.remark_p}>
                    <div className={styles.remark_content}>
                      <div className={styles.remark_content_header}>
                        <div className={styles.remark_content_header_staff}>
                          <img
                            className={styles.remark_content_header_staff_0}
                            src={remarkChild.url}
                            alt=""
                          />
                          <div className={styles.remark_content_header_staff_1}>
                            <div
                              className={
                                styles.remark_content_header_staff_1_name
                              }
                            >
                              {remarkChild.remarkLabelUserName
                                ? remarkChild.remarkLabelUserName
                                : '-'}
                              <span>
                                {parseResultName(`${remarkChild.appraiseType}`)}
                              </span>
                            </div>
                            <div
                              className={
                                styles.remark_content_header_staff_1_time
                              }
                            >
                              {remarkChild.createTime
                                ? dateFormat(
                                    remarkChild.createTime,
                                    'yyyy-MM-dd hh:mm:ss',
                                  )
                                : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.remark_child}>
                        {remarkChild.remarkContent}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <List.Item prefix="是否已完成">
                <Form.Item
                  name="finishType"
                  rules={[{ required: true, message: '选择是否已完成' }]}
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={'1'}>已完成</Radio>
                    <Radio value={'2'}>未完成</Radio>
                    <Radio value={'3'}>未选择</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item> */}
                <List.Item prefix="评分">
                  <Form.Item
                    name="appraiseType"
                    rules={[{ required: true, message: '选择评分' }]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      {/* <Radio value={1}>极好</Radio> */}
                      <Radio value={2}>好</Radio>
                      <Radio value={3}>中</Radio>
                      <Radio value={4}>差</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <TextArea
                  placeholder="请输入批注"
                  maxLength={100}
                  rows={3}
                  showCount
                  value={remarkValue}
                  onChange={(v) => {
                    setRemarkValue(v);
                  }}
                />
              </List>
            </Form>
            <div className={styles.button_4}>
              <Button
                block
                type="submit"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  onFinishRemark();
                }}
              >
                提交
              </Button>
            </div>
          </Popup>
          <Popup
            visible={visible5}
            showCloseButton
            onClose={() => {
              setVisible5(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '40vh',
              paddingBottom: '20px',
            }}
          >
            <Form form={remarkform}>
              <List>
                <div className={styles.formList}>
                  <div className={styles.remark_p}>
                    <div className={styles.remark_content}>
                      <div className={styles.remark_content_header}>
                        <div className={styles.remark_content_header_staff}>
                          <img
                            className={styles.remark_content_header_staff_0}
                            src={remarkChild.url}
                            alt=""
                          />
                          <div className={styles.remark_content_header_staff_1}>
                            <div
                              className={
                                styles.remark_content_header_staff_1_name
                              }
                            >
                              {remarkChild.remarkLabelUserName
                                ? remarkChild.remarkLabelUserName
                                : '-'}
                              <span>
                                {parseResultName(`${remarkChild.appraiseType}`)}
                              </span>
                            </div>
                            <div
                              className={
                                styles.remark_content_header_staff_1_time
                              }
                            >
                              {remarkChild.createTime
                                ? dateFormat(
                                    remarkChild.createTime,
                                    'yyyy-MM-dd hh:mm:ss',
                                  )
                                : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.remark_child}>
                        {remarkChild.remarkContent}
                      </div>
                    </div>
                  </div>
                </div>
                <List.Item prefix="评分">
                  <Form.Item
                    name="appraiseType"
                    rules={[{ required: true, message: '选择评分' }]}
                    className={styles.form_item}
                    // initialValue={annotations.appraiseType}
                  >
                    <Radio.Group>
                      {/* <Radio value={1}>极好</Radio> */}
                      <Radio value={2}>好</Radio>
                      <Radio value={3}>中</Radio>
                      <Radio value={4}>差</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <TextArea
                  placeholder="请输入批注"
                  maxLength={100}
                  rows={3}
                  showCount
                  value={remarkValue}
                  // defaultValue={annotations.remarkContent}
                  onChange={(v) => {
                    setRemarkValue(v);
                  }}
                />
              </List>
            </Form>
            <div className={styles.button_4}>
              <Button
                block
                type="submit"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  onFinishAnnotations();
                }}
              >
                提交
              </Button>
            </div>
          </Popup>
          <Popup
            visible={visible6}
            showCloseButton
            onClose={() => {
              setVisible6(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '30vh',
              padding: '20px',
            }}
          >
            <Form form={remarkform}>
              {record && <div> 突破逻辑：{record.breakThroughLogic || ''}</div>}
              <List>
                <TextArea
                  placeholder="请输入批注"
                  maxLength={100}
                  rows={5}
                  showCount
                  value={marginalValue}
                  onChange={(v) => {
                    setMarginalValue(v);
                  }}
                />
              </List>
            </Form>
            <div className={styles.button_4}>
              <Button
                block
                type="submit"
                color="primary"
                onClick={() => {
                  if(marginalValue != ""){
                    setLoading(true);
                    onFinishMarginal(record);
                  }else{
                    Toast.show({
                      icon: 'fail',
                      content: '批注不可为空',
                    });
                  }
                }}
              >
                提交
              </Button>
            </div>
          </Popup>
        </div>
      </div>
      {params.status == 4 ? (
        <div className={styles.menu_next}>
          <Switch
            onChange={(val) => {
              setCheckState({ check: val, index: 0 });
            }}
            checked={checkState.check}
            uncheckedText="单条"
            checkedText="列表"
          />
          {/* <Switch
            style={{marginTop:"10px"}}
            onChange={(val) => {
              setCheckStateRemark({ check: val});
              // @ts-ignore
              infoRef.current.check = val
              // setParams({ ...params, pageNo: -1, remark: val });
              reset()
            }}
            checked={checkStateRemark.check}
            uncheckedText="最新"
            checkedText="全部"
          /> */}
          {checkState.check ? (
            <img
              src={上一条}
              alt=""
              onClick={() => {
                if (checkState.index <= 0) {
                  return;
                }
                setCheckState({ ...checkState, index: checkState.index - 1 });
              }}
            />
          ) : (
            <></>
          )}
          {checkState.check ? (
            <img
              src={下一条}
              alt=""
              onClick={async () => {
                if (data.length < checkState.index + 1 + 3 && hasMore) {
                  setParams({
                    ...params,
                    pageNo: params.pageNo + 1,
                    flag: false,
                  });
                }
                if (checkState.index + 1 <= data.length - 1) {
                  setCheckState({ ...checkState, index: checkState.index + 1 });
                }
              }}
            />
          ) : (
            <></>
          )}
          {checkState.check ? (
            <span
              style={{
                background: 'rgba(0,0,0,.3)',
                color: 'white',
                padding: '5px',
                borderRadius: '2px',
              }}
            >
              {checkState.index + 1}/{countMap.totalCount}
            </span>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <NameList
        value={[]}
        nameVisible={nameVisible}
        onSubmit={handelUserName}
        onCancel={handleUserNameCancel}
        bd={false}
      />
      <ImageViewer
        image={allImage}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />
    </div>
  );
}
