import Button from 'antd-mobile/es/components/button';
import Card from 'antd-mobile/es/components/card';
import Form from 'antd-mobile/es/components/form';
import Input from 'antd-mobile/es/components/input';
import List from 'antd-mobile/es/components/list';
import {
  Checkbox,
  Tabs,
  ImageViewer,
  Loading,
  Modal,
  Picker,
  Radio,
  Image,
  TextArea,
  Empty,
  Badge,
} from 'antd-mobile/es';
import initMerchant from './img/initMerchant.png';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import { history, useLocation } from 'umi';
import {
  getQyWxImg,
  parseThePicture,
  save,
  vehicleSourceNotes,
  verification,
  visitImgDelete,
} from './service';
import { Popup, Toast, TreeSelect } from 'antd-mobile';
import data from '../utils/省市区线数据.json';
import { getWxAccessToken } from '../login/service';
import Merchant from '../merchant';
import { orglist, saveCall } from '../see/service';
import { isIOS } from '../see';
import { labellist } from '../programme/service';
import { AddOutline } from 'antd-mobile-icons';
import { dateFormat } from '../utils/util';
import dayjs from 'dayjs';
import { flow } from '@ant-design/plots';
import Item from 'antd-mobile/es/components/dropdown/item';
import { testJianCe } from '../utils/signIn';
import { relationList } from '../callOnSb/service';
export default function Businesses() {
  const position = localStorage.getItem('position');
  const [remarkform] = Form.useForm();
  const [forms] = Form.useForm();
  const [belongMarketId, setbelongMarketId] = useState<any>([]);
  const [options, setoptions] = useState<any>([]);
  const [cityCode, setCityCode] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [vinHistoryFlag, setVinHistoryFlag] = useState<boolean>(false);
  const [detectOrgNameVisible, setdetectOrgNameVisible] =
    useState<boolean>(false);
  const [mediaIds, setmediaIds] = useState<any[]>([]);
  const [companyId, setCompanyId] = useState<any>([]);
  const [visitSignType, setvisitSignType] = useState<any>([]);
  const [radioFlag, setRadioFlag] = useState<any>(0);
  const [callImg, setCallImg] = useState<any>([]);
  const [mediaImage, setMediaImage] = useState<any[]>([]);
  const [mediaGroupImage, setMediaGroupImage] = useState<any>([]);
  const [visitContent, setvisitContent] = useState<any>('');
  const [ids, setIds] = useState<number>();
  const [meritList, setMeritList] = useState<any>([]);
  const [initcarLabelIds, setinitcarLabelIds] = useState<any>([]);
  const [valuez, setValues] = useState<any>([]);
  const [isView, setIsView] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [moreMap, setMoreMap] = useState<any>({});
  const location = useLocation();
  const dataFlag: any = location.state;
  const [visitSignTypeVisible, setvisitSignTypeVisible] =
    useState<boolean>(false);
  const [visible4, setVisible4] = useState<boolean>(false);
  const avatar = localStorage.getItem('avatar');
  const [remarkValue, setRemarkValue] = useState<string>('');
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [remark, setRemark] = useState<any>([]);
  const [imgAllVin, setImgAllVin] = useState<any>([]);
  const [imgNum, setImgNum] = useState<number>(0);
  const [mediaIdNum, setMediaIdNum] = useState<number>(0);
  const [mediaImgVisible, setMediaImgVisible] = useState<boolean>(false);
  const [mediaStatusVisible, setMediaStatusVisible] = useState<boolean>(false);
  const [historyMediaStatusVisible, setHistoryMediaStatusVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [orgPicket, setorgPicket] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [allImage, setAllImage] = useState<any>([]);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [checkState, setCheckState] = useState<string>('1');
  // let mediaIdList:any = []
  const infoRef: any = useRef([]);
  // let imgNum = 0
  const onFinishCall = async () => {
    if (mediaImage.length === 0) {
      Toast.show({
        icon: 'fail',
        content: `请上传图片！！`,
      });
      return;
    }
    // if (visitContent === '') {
    //   Toast.show({
    //     icon: 'fail',
    //     content: '请填写备注信息',
    //   });
    //   return;
    // }
    // let flag = false
    // let type = false
    // let index = 0
    // let index2 = 0
    // if(mediaImage != [] && mediaImage.length > 0){
    //   mediaImage.map((item:any,childIndex:any)=>{
    //     if(!item.vin || item.vin === ""){
    //       flag = true
    //       index = childIndex + 1
    //     }
    //   })
    // }
    // if(mediaImage != [] && mediaImage.length > 0){
    //   mediaImage.map((item:any,childIndex:any)=>{
    //     if(!item.status && `${item.status}` != '0'){
    //       type = true
    //       index2 = childIndex + 1
    //     }
    //   })
    // }
    // if(flag){
    //   Toast.show({
    //     icon: 'fail',
    //     content: `请输入第${index}张车源照片车架号`,
    //   });
    //   return
    // }
    // if(type){
    //   Toast.show({
    //     icon: 'fail',
    //     content: `请选择第${index2}张车源照片车源状态`,
    //   });
    //   return
    // }
    form.validateFields().then(() => {
      submit();
    });
  };
  const submit = async () => {
    const values = form.getFieldsValue();
    values.mediaIds = mediaIds;
    values.companyId = companyId;
    values.rowId = ids;
    values.visitId = dataFlag.id;
    values.groupPhotoPeopleCar = mediaGroupImage.url;
    let list: any[] = [];
    if (mediaImage && mediaImage.length > 0) {
      mediaImage.map((item: any) => {
        if (item) {
          list.push(item);
        }
      });
    }
    values.visitImgVinList = list;
    if (remark.length > 0) {
      const remarks = remark.filter((item: any) => item);
      values.remarkInputDTO = {
        labelList: remarks,
        companyId: companyId,
        bdMobile: localStorage.getItem('phone'),
      };
    }
    const msg = await saveCall(values);
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '车源签到成功',
      });
      history.goBack();
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };
  const initValue = async (dataFlag: any) => {
    console.log(dataFlag);
    
    const msg = await orglist();
    if (msg.retCode === 0) {
      console.log(msg.data);
      let list: any = [];
      msg.data.map((item: any) => {
        list.push({
          label: item.partnerName,
          value: item.id,
        });
      });
      setorgPicket([list]);
    }
    const res = await vehicleSourceNotes({});
    if (res.retCode === 0) {
      setMeritList(res.data);
    }
    form?.setFieldsValue(dataFlag);
    setMediaGroupImage(dataFlag.groupPhotoPeopleCar)
    form?.setFieldsValue({ companyName: dataFlag.companyName });
    // form?.setFieldsValue({address: dataFlag.contactDetailAddress });
    if (dataFlag.rowId) {
      setIds(dataFlag.rowId);
    }
    setvisitSignType(dataFlag.visitSignType);
    setCompanyId(dataFlag.companyId);

    const record = await relationList({
      visitId: dataFlag.id,
    });
    if (record.retCode === 0) {
      if (record.data && record.data.visitCompanyRelations) {
        record.data.visitCompanyRelations.map((item: any) => {
          if (item.companyId && item.companyId === dataFlag.companyId) {
            setData(item.visitImgVinHistory);
          }
        });
      }
    }
  };
  const wxShareConfigCall = async () => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
        success: function (res: any) {
          // 回调
        },
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function (res: any) {
          // 回调
        },
        fail: function (res: { errMsg: string | string[] }) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      await window.wx.chooseImage({
        count: 5, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'batch', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          console.log(1);
          let localIds = res.localIds;
          if (localIds) {
            let list:any = [];
            localIds.map(async (item: any, index: number) => {
              await window.wx.uploadImage({
                localId: item.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: function (res: { serverId: any }) {
                  let serverId = res.serverId; // 返回图片的服务器端ID
                  getQyWxImg({mediaId:serverId})
                  .then((ret:any)=>{
                    console.log(ret);
                    
                    mediaImage[index + mediaImage.length] = {
                      mediaId:serverId,
                      url: ret
                    };
                    setMediaImage([...mediaImage]);
                  })
                },
                errMsg: function () {
                  Toast.show({
                    icon: 'fail',
                    content: '上传失败',
                  });
                  setLoading(false);
                  return;
                },
              });
            });
          } else {
            setLoading(false);
          }
          
          // setTimeout(() => {
          //   mediaIdImg(list);
          // }, 1500);
        },
      });
    }
  };
  const wxGroupPhotoPeopleCarCall = async () => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
        success: function (res: any) {
          // 回调
        },
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function (res: any) {
          // 回调
        },
        fail: function (res: { errMsg: string | string[] }) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      await window.wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'batch', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          let localIds = res.localIds;
          if (localIds) {
            let list:any = [];
            localIds.map(async (item: any, index: number) => {
              await window.wx.uploadImage({
                localId: item.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: function (res: { serverId: any }) {
                  let serverId = res.serverId; // 返回图片的服务器端ID
                  getQyWxImg({mediaId:serverId})
                  .then((ret:any)=>{
                    setMediaGroupImage({
                      mediaId:serverId,
                      url: ret
                    });
                  })
                },
                errMsg: function () {
                  Toast.show({
                    icon: 'fail',
                    content: '上传失败',
                  });
                  setLoading(false);
                  return;
                },
              });
            });
          } else {
            setLoading(false);
          }
          
          // setTimeout(() => {
          //   mediaIdImg(list);
          // }, 1500);
        },
      });
    }
  };
  const mediaIdImg = (value: any,index:number) => {
    setLoading(true);
    parseThePicture({ mediaIds: [value.mediaId] })
      .then((msg) => {
        if (msg.retCode === 0 && msg.data && msg.data.length > 0) {
          msg.data.map((item: any) => {
            mediaImage[index] = item;
          });
          setMediaImage([...mediaImage]);
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: '车架号查询识别失败',
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    if (dataFlag) {
      initValue(dataFlag);
    }
  }, []);
  const onRemarkFinish = () => {
    const values = remarkform.getFieldsValue();
    if (remarkValue === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写备注！',
        // duration: 0,
      });
      return;
    }
    values.remarkContent = remarkValue;
    values.labelId = remarkChild.id;
    values.labelName = remarkChild.name;
    // values.remarkLabelUserId = localStorage.getItem("userId")
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = avatar;
    values.createTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    remark[remarkChild.id] = values;
    setRemark([...remark]);
    setVisible4(false);
    console.log(remark);
  };
  const loginStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: '10000',
    background: 'rgba(233, 236, 240, 0.8)',
  };
  const getSubmit = (list: any) => {
    forms
      .validateFields()
      .then(() => {
        submitVer(list);
      })
      .catch(() => {
        setHistoryMediaStatusVisible(true);
      });
  };
  const submitVer = (list: any) => {
    const values = forms.getFieldsValue();
    list.vin = values.vin;
    list.reserveFlag = values.reserveFlag;
    list.status = values.status;
    list.notReserveReason = values.notReserveReason;
    list.detectOrgName = values.detectOrgName;
    list.id = imgAllVin.item.id;
    verification(list)
      .then((res) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '核销成功',
          });
          initValue(dataFlag);
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '核销失败',
        });
      });
  };
  const onDelete = (item: any) => {
    visitImgDelete({id:item.id})
      .then((res) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '已删除',
          });
          initValue(dataFlag);
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '删除失败',
        });
      });
  };
  const stateEnum = [
    { name: '在库', value: 1 },
    { name: '未检测', value: 2 },
    { name: '已检测', value: 4 },
    { name: '已售出', value: 5 },
    {
      name: '未下单',
      value: 3,
    },
  ];
  const onChange = (v: any) => {
    setCheckState(v);
  };
  return (
    <div
      className={`${styles.businesses_form} ${styles.page}`}
      style={{ overflow: `${loading ? 'hidden' : 'scroll'}`, height: '100%' }}
    >
      <Navbar name="车源签到"></Navbar>
      {loading && (
        <div style={loginStyle}>
          <Loading color="primary" />
          <span style={{ color: 'rgba(27, 91, 219, 1)' }}>正在识别车架号</span>
        </div>
      )}
      <Card>
      <div className={styles.callOnSb}>
        <div className={styles.callOnSb_0}>
          <div className={styles.callOnSb_img}>
              {dataFlag.doorHeadPhoto ? (
                <Image
                  src={`${dataFlag.doorHeadPhoto}?x-image-process=image/resize,l_100`}
                  width={45}
                  height={45}
                  fit="fill"
                />
              ) : 
                <Image
                  src={initMerchant}
                  width={45}
                  height={45}
                  fit="fill"
                />
              }
          </div>
          <div>{dataFlag.companyName}</div>
        </div>
        {dataFlag.completedFlag ? <div style={{color:"rgba(139, 140, 142, 1)",fontSize:"12px",padding:"0 15px"}}>已拜访</div> : <div  style={{color:"#FF6920",fontSize:"12px",padding:"0 15px"}}>待拜访</div>}
      </div>
        <Form
          // onFinish={onFinish}
          form={form}
          footer={
            <div className={styles.submit}>
              {!dataFlag.completedFlag && (
                <Button
                  block
                  type="submit"
                  color="primary"
                  onClick={() => {
                    onFinishCall();
                  }}
                >
                  保存
                </Button>
              )}
            </div>
          }
        >
          <List>
            <List.Item
              prefix="拜访类型"
              onClick={() => {
                setvisitSignTypeVisible(true);
              }}
              title={'*'}
            >
              <Form.Item
                name="visitSignTypeName"
                rules={[{ required: true, message: '请选择拜访类型' }]}
                className={styles.form_item}
              >
                <Input placeholder="请选择拜访类型" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item prefix="人车合影" title={'*'}>
              <Form.Item name="groupPhotoPeopleCar" className={styles.form_item}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {mediaGroupImage && mediaGroupImage.url ? (
                  <div className={styles.batchBuyNo_img_0}>
                    <Badge color='#ACACAC' content={<span onClick={()=>{
                      setMediaGroupImage([])
                    }}>x</span>}>
                      <img width={40} height={40} src={mediaGroupImage.url} alt="" />
                    </Badge>
                  </div>
                  ) : 
                  <div
                    className={styles.increaseIcon}
                    onClick={() => {
                      wxGroupPhotoPeopleCarCall();
                    }}
                  >
                    <AddOutline />
                  </div>
                  }
                </div>
              </Form.Item>
            </List.Item>
            {/* <List.Item prefix="是否预约验车" title={'*'}>
              <Form.Item
                name="bookingFlag"
                rules={[{ required: true, message: '是否预约验车' }]}
                className={styles.form_item}
              >
                <Radio.Group onChange={(val)=>{
                  console.log(val);
                  
                  setRadioFlag(val)
                }}>
                  <Radio value={1}>是</Radio>
                  <Radio value={0}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </List.Item> */}
            {/* <List.Item prefix="愿意异地推荐" title={'*'}>
              <Form.Item
                name="recommendCompanyFlag"
                rules={[{ required: true, message: '愿意异地推荐' }]}
                className={styles.form_item}
              >
                <Radio.Group>
                  <Radio value={1}>是</Radio>
                  <Radio value={0}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </List.Item>
            <List.Item prefix="备注" title={'*'}>
              <Form.Item
                name="remark"
                rules={[{ required: true, message: '输入备注信息' }]}
                className={styles.form_item}
              >
                <TextArea
                  onChange={(v) => {
                    console.log(v);
                    setvisitContent(v);
                  }}
                  placeholder="输入备注信息"
                />
              </Form.Item>
            </List.Item> */}
            <List.Item prefix="车位数" extra="个" title={'*'}>
              <Form.Item
                name="parkingSpacesNum"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        Toast.show({
                          icon: 'fail',
                          content: '输入车位数',
                        });
                        return Promise.reject(new Error('输入车位数'));
                      }
                      if (Number.isFinite(Number(value)) === false) {
                        return Promise.reject(
                          new Error('不可输入文字！！'),
                        );
                      }
                      return Promise.resolve();
                    },
                    required: true,
                  }),
                ]}
                className={styles.form_item}
              >
                <Input placeholder="输入车位数" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="车源数" extra="个" title={'*'}>
              <Form.Item
                name="carSourceNum"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        Toast.show({
                          icon: 'fail',
                          content: '输入车源数',
                        });
                        return Promise.reject(new Error('输入车源数'));
                      }
                      if (Number.isFinite(Number(value)) === false) {
                        return Promise.reject(
                          new Error('不可输入文字！！'),
                        );
                      }
                      return Promise.resolve();
                    },
                    required: true,
                  }),
                ]}
                className={styles.form_item}
              >
                <Input placeholder="输入车源数" />
              </Form.Item>
            </List.Item>
            {/* {position && (position.indexOf('检测') > -1 || position.indexOf('城市主管') > -1 || position.indexOf("大区副总监")>-1) && ( */}
              <>
                <List.Item prefix="车源照片" title={'*'}>
                  <Form.Item name="mediaIds" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfigCall();
                          // mediaIdImg()
                        }}
                      >
                        <AddOutline />
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {mediaImage &&
                  mediaImage.length > 0 &&
                  mediaImage.map((item: any, index: any) => {
                    console.log(mediaImage);
                    if (item) {
                      return (
                        <div className={styles.media_img}>
                          <div className={styles.media_img_1}>
                            {/* <img
                              style={{ borderRadius: '10%' }}
                              src={item.url}
                              onClick={() => {
                                setImageVisible(true);
                                setAllImage(item.url);
                              }}
                            /> */}
                            <Image
                              src={item.url}
                              width={40}
                              height={40}
                              fit="fill"
                              onClick={() => {
                                setImageVisible(true);
                                setAllImage(item.url);
                              }}
                            />
                          </div>
                          <div className={styles.media_text}>
                            <div className={styles.media_text_0}>
                              车架号：{item.vin ? item.vin : '车架号识别失败'}
                              <>
                                {
                                  <a
                                    style={{
                                      color: `${
                                        item.reserveFlag
                                          ? 'rgba(39, 171, 17, 1)'
                                          : 'rgba(255, 105, 32, 1)'
                                      }`,
                                    }}
                                  >
                                    {
                                      item.status === 0 &&
                                      '已检测'}
                                    {
                                      item.status === 1 &&
                                      '未检测'}
                                    {
                                      item.status === 2 &&
                                      '已售出'}
                                    {/* {!item.reserveFlag && '未下单'} */}
                                  </a>
                                }
                              </>
                            </div>
                            <div className={styles.media_text_1}>
                              {item.detectOrgName && (
                                <>检测机构：{item.detectOrgName}</>
                              )}
                            </div>
                            <div className={styles.media_text_1}>
                              {item.notReserveReason &&
                                item.reserveFlag === 0 && (
                                  <>不可预约理由：{item.notReserveReason}</>
                                )}
                            </div>

                            <div className={styles.footer_button}>
                              <span
                                onClick={() => {
                                  mediaIdImg(item,index)
                                }}
                              >
                                识别
                              </span>
                              <span
                                onClick={() => {
                                  forms.resetFields();
                                  setRadioFlag(0);
                                  forms.setFieldsValue({ vin: item.vin });
                                  forms.setFieldsValue({
                                    reserveFlag: item.reserveFlag,
                                  });
                                  setRadioFlag(item.reserveFlag);
                                  forms.setFieldsValue({ status: item.status });
                                  forms.setFieldsValue({
                                    notReserveReason: item.notReserveReason,
                                  });
                                  forms.setFieldsValue({
                                    detectOrgName: item.detectOrgName,
                                  });
                                  setImgAllVin({ item, index });
                                  setMediaImgVisible(true);
                                }}
                              >
                                编辑
                              </span>
                              <span
                                onClick={() => {
                                  let list = mediaImage.filter(
                                    (child: any, i: any) => index != i,
                                  );
                                  // let lists = callImg.filter(
                                  //   (child: any, i: any) => index != i,
                                  // );
                                  setMediaImage(list);
                                  // setmediaIds(lists);
                                }}
                              >
                                删除
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </>
            {/* )} */}
          </List>
          {/* <div className={styles.merit}>
          <div
            className={styles.merit_title}
            style={{ fontWeight: '600' }}
          >
            选择本次拜访价值点{' '}
          </div>
          {meritList &&
            meritList.map((item: any, index: any) => {
              if (
                item.mCarDealerLabelVos &&
                item.mCarDealerLabelVos.length > 0
              ) {
                return (
                  <div>
                    <div className={styles.merit_title}>
                      <div
                        onClick={() => {
                          rowClick(index);
                        }}
                      >
                        {item.name}{' '}
                        <span
                          style={{
                            transform: `${
                              !isView[index]
                                ? 'rotate(-135deg)'
                                : 'rotate(-45deg)'
                            }`,
                          }}
                        ></span>
                      </div>
                      <Checkbox
                        // defaultChecked={valuez}
                        onChange={(checked) => {
                          let list: any[] = [];
                          if (checked) {
                            item.mCarDealerLabelVos.map(
                              (check: any) => {
                                valuez[check.id] = check.id;
                              },
                            );
                            valuez[item.id] = item.id;
                            setValues([...valuez]);
                          } else {
                            item.mCarDealerLabelVos.map(
                              (check: any) => {
                                valuez[check.id] = undefined;
                              },
                            );
                            valuez[item.id] = undefined;
                            setValues([...valuez]);
                          }
                        }}
                        value={item.id}
                      />
                    </div>
                    {isView[index] && (
                      <div className={styles.merit_check}>
                        {item.mCarDealerLabelVos.length > 0 &&
                          item.mCarDealerLabelVos.map(
                            (ChildItem: any) => {
                              let list: any[] = [];
                              return (
                                <Checkbox.Group value={valuez}>
                                  <div
                                    className={styles.merit_check_0}
                                  >
                                    <div>{ChildItem.name}</div>
                                    <Checkbox
                                      onChange={(checked) => {
                                        if (checked) {
                                          console.log(valuez);
                                          valuez[ChildItem.id] =
                                            ChildItem.id;
                                          setValues([...valuez]);
                                        } else {
                                          valuez[ChildItem.id] =
                                            undefined;
                                          setValues([...valuez]);
                                        }
                                      }}
                                      value={ChildItem.id}
                                    />
                                  </div>
                                  <div></div>
                                </Checkbox.Group>
                              );
                            },
                          )}
                      </div>
                    )}
                  </div>
                );
              }
            })}
        </div> */}
          {/* <div className={styles.jiazhi}>
            <div className={styles.footer}>
              <div className={styles.footer_1}>
                <div className={styles.footer_b}>
                  <div>备注价值点</div>
                </div>
              </div>
              {meritList &&
                meritList.map((item: any, mIndex: number) => {
                  if (item.mCarDealerLabelVos.length > 0) {
                    return (
                      <div>
                        <div
                          className={styles.footer_2}
                          key={`MeritList-${mIndex}`}
                        >
                          <div
                            className={styles.footer_2_title}
                          >{`${mIndex + 1} ${item.name}`}</div>
                          <div className={styles.footer_2_extra}>
                            这里是价值点的文案说明
                          </div>
                        </div>
                        {item.mCarDealerLabelVos &&
                          item.mCarDealerLabelVos.length > 0 &&
                          item.mCarDealerLabelVos.map(
                            (Child: any, cIndex: number) => {
                              let childFlag = false;
                              if (initcarLabelIds.length > 0) {
                                initcarLabelIds.map((i: any) => {
                                  if (Child.id === i) {
                                    childFlag = true;
                                  }
                                });
                              }
                              return (
                                <div
                                  style={{ paddingTop: '5px' }}
                                  key={`MeritList-${mIndex}-${cIndex}`}
                                >
                                  <div className={styles.remark_content_header}>
                                    <div
                                      style={{
                                        color: `${childFlag && 'red'}`,
                                        width: '80%',
                                        textAlign:'start'
                                      }}
                                    >{`${mIndex + 1}.${cIndex + 1} ${
                                      Child.name
                                    }`}</div>
                                    <div
                                      className={styles.remark_content_header_0}
                                      onClick={() => {
                                        remarkform.resetFields();
                                        setRemarkValue('');
                                        setVisible4(true);
                                        let list = Child;
                                        list.index = `${mIndex + 1}.${
                                          cIndex + 1
                                        }`;
                                        list.childFlag = childFlag;
                                        setRemarkChild(Child);
                                      }}
                                    >
                                      备注
                                    </div>
                                  </div>
                                  {remark &&
                                    remark.map((res: any, resIndex: number) => {
                                      if (res && res.labelId === Child.id) {
                                        return (
                                          <div className={styles.remark} key={resIndex}>
                                            <div
                                              className={styles.remark_content}
                                            >
                                              <div
                                                className={
                                                  styles.remark_content_header
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.remark_content_header_staff
                                                  }
                                                >
                                                  {avatar != null ? (
                                                    <img
                                                      src={avatar}
                                                      alt=""
                                                      className={
                                                        styles.remark_content_header_staff_0
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_0
                                                      }
                                                    ></div>
                                                  )}
                                                  <div
                                                    className={
                                                      styles.remark_content_header_staff_1
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_1_name
                                                      }
                                                    >
                                                      {res.remarkLabelUserName}
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_1_time
                                                      }
                                                    >
                                                      {res.createTime
                                                        ? dateFormat(
                                                            res.createTime,
                                                            'yyyy-MM-dd hh:mm:ss',
                                                          )
                                                        : '-'}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  styles.remark_content_foot
                                                }
                                              >
                                                {res.remarkContent}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  {Child.remarkRecordDetails &&
                                    Child.remarkRecordDetails.map(
                                      (res: any, resIndex: number) => {
                                        return (
                                          <div
                                            className={styles.remark}
                                            key={`${cIndex}-${resIndex}-wait`}
                                            style={{
                                              display:
                                                moreMap[`${cIndex}`] ||
                                                resIndex < 4
                                                  ? 'flex'
                                                  : 'none',
                                            }}
                                          >
                                            <div
                                              className={styles.remark_content}
                                            >
                                              <div
                                                className={
                                                  styles.remark_content_header
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.remark_content_header_staff
                                                  }
                                                >
                                                  {res.url &&
                                                  res.url != 'null' ? (
                                                    <img
                                                      src={res.url}
                                                      alt=""
                                                      className={
                                                        styles.remark_content_header_staff_0
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_0
                                                      }
                                                    ></div>
                                                  )}
                                                  <div
                                                    className={
                                                      styles.remark_content_header_staff_1
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_1_name
                                                      }
                                                    >
                                                      {res.remarkLabelUserName}
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_1_time
                                                      }
                                                    >
                                                      {res.createTime
                                                        ? dateFormat(
                                                            res.createTime,
                                                            'yyyy-MM-dd hh:mm:ss',
                                                          )
                                                        : '-'}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  styles.remark_content_foot
                                                }
                                              >
                                                {res.remarkContent}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}
                                  {Child.remarkRecordDetails &&
                                    Child.remarkRecordDetails.length > 3 && (
                                      <div
                                        style={{
                                          textAlign: 'right',
                                          color: 'blue',
                                          marginBottom: '10px',
                                        }}
                                        onClick={(e) => {
                                          moreMap[`${cIndex}`] =
                                            !moreMap[`${cIndex}`];
                                          setMoreMap({ ...moreMap });
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                      >
                                        {moreMap[`${cIndex}`]
                                          ? '折叠-'
                                          : '展开+'}
                                      </div>
                                    )}
                                </div>
                              );
                            },
                          )}
                      </div>
                    );
                  }
                })}
            </div>
          </div> */}
        </Form>
      </Card>

      <Card title={'在库车源信息'} style={{ marginBottom: '40px' }}>
        <Tabs
          style={{
            '--title-font-size': '14px',
          }}
          defaultActiveKey={'1'}
          className={styles.tab_appraise}
          onChange={onChange}
        >
          {stateEnum.map((item, index) => (
            <Tabs.Tab title={item.name} key={item.value} />
          ))}
        </Tabs>
        {data && data.length > 0 ? (
          data.map((item: any, index: any) => {
            if (
              item.url &&
              (checkState === '1' ||
                (checkState === '2'  && item.status === 1) ||
                (checkState === '3' && !item.reserveFlag) ||
                (checkState === '4' &&
                  `${item.status}` === '0') ||
                (checkState === '5' &&
                  `${item.status}` === '2'))
            ) {
              console.log(data);

              return (
                <div className={styles.media_img}>
                  <div className={styles.media_img_1}>
                    <img
                      style={{ borderRadius: '10%' }}
                      src={item.url}
                      onClick={() => {
                        setImageVisible(true);
                        setAllImage(item.url);
                      }}
                    />
                  </div>
                  <div className={styles.media_text}>
                    <div className={styles.media_text_0}>
                      车架号：{item.vin ? item.vin : '车架号识别失败'}
                      <>
                        {
                          <a
                            style={{
                              color: `${
                                item.reserveFlag
                                  ? 'rgba(39, 171, 17, 1)'
                                  : 'rgba(255, 105, 32, 1)'
                              }`,
                            }}
                          >
                            {item.status === 0 && '已检测'}
                            {item.status === 1 && '未检测'}
                            {item.status === 2 && '已售出'}
                            {/* {!item.reserveFlag && '未下单'} */}
                          </a>
                        }
                      </>
                    </div>
                    <div className={styles.media_text_1}>
                      {item.detectOrgName && (
                        <>检测机构：{item.detectOrgName}</>
                      )}
                    </div>
                    <div className={styles.media_text_1}>
                      {item.notReserveReason && !item.reserveFlag && (
                        <>不可预约理由：{item.notReserveReason}</>
                      )}
                    </div>
                    {`${item.reserveFlag}`!= "" &&
                      <div className={styles.media_text_1}>
                        <>是否下单：{item.reserveFlag ? "是" : "否"}</>
                      </div>
                    }

                    <div className={styles.footer_button}>
                      {/* <span
                        className={styles.imgdelete}
                        onClick={() => {
                          Modal.confirm({
                            content: '确认删除车源？',
                            bodyClassName: styles.imgdeleteModel ,
                            onConfirm: async () => {
                              onDelete(item)
                            },
                          })
                        }}
                      >
                        删除
                      </span> */}
                      <span
                        onClick={() => {
                          forms.resetFields();
                          setRadioFlag(0);
                          forms.setFieldsValue({ vin: item.vin });
                          if (item.reserveFlag) {
                            forms.setFieldsValue({ reserveFlag: 1 });
                            setRadioFlag(1);
                          } else {
                            forms.setFieldsValue({ reserveFlag: 0 });
                            setRadioFlag(0);
                          }
                          forms.setFieldsValue({ status: item.status });
                          forms.setFieldsValue({
                            notReserveReason: item.notReserveReason,
                          });
                          forms.setFieldsValue({
                            detectOrgName: item.detectOrgName,
                          });
                          setImgAllVin({ item, index });
                          setHistoryMediaStatusVisible(true);
                        }}
                      >
                        核销
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <Empty description="暂无车源信息" />
        )}
      </Card>
      <Popup
        visible={visible4}
        showCloseButton
        onClose={() => {
          setVisible4(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '50vh',
          maxHeight: '100vh',
        }}
      >
        <div className={styles.remarkName}>
          {remarkChild.index} {remarkChild.name}
        </div>
        <Form form={remarkform}>
          <List>
            <List.Item prefix="评分">
              <Form.Item name="appraiseType" className={styles.form_item}>
                <Radio.Group>
                  {/* <Radio value={'1'}>极好</Radio> */}
                  <Radio value={'2'}>好</Radio>
                  <Radio value={'3'}>中</Radio>
                  <Radio value={'4'}>差</Radio>
                </Radio.Group>
              </Form.Item>
            </List.Item>
            <TextArea
              placeholder="请输入备注"
              maxLength={100}
              rows={5}
              showCount
              value={remarkValue}
              onChange={(v) => {
                setRemarkValue(v);
              }}
            />
          </List>
        </Form>
        <div style={{ width: '95%', paddingBottom: '10px' }}>
          <Button
            block
            type="button"
            color="primary"
            onClick={() => {
              onRemarkFinish();
            }}
          >
            提交
          </Button>
        </div>
      </Popup>
      <Modal
        visible={mediaImgVisible}
        closeOnAction
        title={'车源信息'}
        content={
          <Form form={forms}>
            <List>
              <List.Item prefix="车架号">
                <Form.Item name="vin" className={styles.form_item}>
                  <Input placeholder="输入车架号" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="是否预约下单">
                <Form.Item name="reserveFlag" className={styles.form_item}>
                  <Radio.Group
                    onChange={(val) => {
                      console.log(val);

                      setRadioFlag(val);
                    }}
                  >
                    <Radio value={1}>已下单 </Radio>
                    <Radio value={0}>未下单</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item prefix="车源状态" title={'*'}>
                <Form.Item
                  name="status"
                  rules={[{ required: true, message: '请选择车源状态' }]}
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={0}>已检测 </Radio>
                    <Radio value={1}>未检测 </Radio>
                    <Radio value={2}>已售出</Radio>
                  </Radio.Group>
                </Form.Item>
                </List.Item>
              {radioFlag != 1 && (
                <List.Item prefix="不预约理由">
                  <Form.Item
                    name="notReserveReason"
                    rules={[
                      { required: true, message: '输入不预约验车的理由' },
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入不预约验车的理由" />
                  </Form.Item>
                </List.Item>
              )}
              <List.Item
                prefix="检测机构"
                onClick={() => {
                  setdetectOrgNameVisible(true);
                }}
              >
                <Form.Item
                  name="detectOrgName"
                  rules={[{ required: true, message: '选择检测机构' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测机构" readOnly />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setMediaImgVisible(false);
        }}
        showCloseButton
        onAction={() => {
          const values = forms.getFieldsValue();
          let list = imgAllVin.item;
          list.vin = values.vin;
          list.reserveFlag = values.reserveFlag;
          list.status = values.status;
          list.notReserveReason = values.notReserveReason;
          list.detectOrgName = values.detectOrgName;
          mediaImage[imgAllVin.index] = list;
          setMediaImage(mediaImage);
        }}
      ></Modal>
      <Modal
        visible={mediaStatusVisible}
        closeOnAction
        title={'车源状态'}
        content={
          <Form form={forms}>
            <List>
              {imgAllVin.item && imgAllVin.item.historyFlag ? (
                <List.Item prefix="车源状态">
                  <Form.Item name="status" className={styles.form_item}>
                    <Radio.Group>
                      <Radio value={0}>已检测 </Radio>
                      <Radio value={1}>未检测</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
              ) : (
                <List.Item prefix="车源状态">
                  <Form.Item name="status" className={styles.form_item}>
                    <Radio.Group>
                      <Radio value={0}>已检测 </Radio>
                      <Radio value={2}>已售出</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
              )}
              <List.Item
                prefix="检测机构"
                onClick={() => {
                  setdetectOrgNameVisible(true);
                }}
              >
                <Form.Item
                  name="detectOrgName"
                  rules={[{ required: true, message: '选择检测机构' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测机构" readOnly />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setMediaStatusVisible(false);
        }}
        showCloseButton
        onAction={() => {
          let list = imgAllVin.item;
          list.status = forms.getFieldsValue().status;
          list.detectOrgName = forms.getFieldsValue().detectOrgName;
          mediaImage[imgAllVin.index] = list;
          setMediaImage(mediaImage);
        }}
      ></Modal>
      <Modal
        visible={historyMediaStatusVisible}
        closeOnAction
        title={'历史车源状态'}
        content={
          <Form form={forms}>
            <List>
              <List.Item prefix="车架号">
                <Form.Item name="vin" className={styles.form_item}>
                  <Input placeholder="输入车架号" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="是否预约下单">
                <Form.Item name="reserveFlag" className={styles.form_item}>
                  <Radio.Group
                    onChange={(val) => {
                      console.log(val);

                      setRadioFlag(val);
                    }}
                  >
                    <Radio value={1}>已下单 </Radio>
                    <Radio value={0}>未下单</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
                <List.Item prefix="车源状态" title={'*'}>
                  <Form.Item
                    name="status"
                    rules={[{ required: true, message: '请选择车源状态' }]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={0}>已检测 </Radio>
                      <Radio value={1}>未检测 </Radio>
                      <Radio value={2}>已售出</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
              {radioFlag != 1 && (
                <List.Item prefix="不预约理由" title={'*'}>
                  <Form.Item
                    name="notReserveReason"
                    rules={[
                      { required: true, message: '输入不预约验车的理由' },
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入不预约验车的理由" />
                  </Form.Item>
                </List.Item>
              )}
              <List.Item
                prefix="检测机构"
                onClick={() => {
                  setdetectOrgNameVisible(true);
                }}
              >
                <Form.Item
                  name="detectOrgName"
                  rules={[{ required: true, message: '选择检测机构' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测机构" readOnly />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setHistoryMediaStatusVisible(false);
        }}
        showCloseButton
        onAction={async () => {
          let list = imgAllVin.item;
          await getSubmit(list);
        }}
      ></Modal>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        position="right"
        bodyStyle={{ width: '100%' }}
      >
        <TreeSelect
          options={options}
          onChange={(value, nodes) => {
            console.log(value, nodes);
            setCityCode(nodes.options);
          }}
        />
        <div className={styles.time_button}>
          <Button
            color="primary"
            fill="outline"
            onClick={() => {
              setVisible(false);
            }}
          >
            取消
          </Button>
          <Button
            size="middle"
            color="primary"
            onClick={() => {
              console.log(cityCode);
              if (cityCode.length === 1) {
                Toast.show({
                  content: '请选择城市',
                });
                return;
              }
              if (cityCode.length === 2) {
                Toast.show({
                  content: '请选择区/县',
                });
                return;
              }
              form.setFieldValue(
                'contactDetailAddress',
                `${cityCode[0].label}${cityCode[1].label}${cityCode[2].label}`,
              );
              setVisible(false);
            }}
          >
            确定
          </Button>
        </div>
      </Popup>
      <Picker
        columns={testJianCe}
        visible={detectOrgNameVisible}
        onCancel={() => {
          setdetectOrgNameVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // settestOrgId(value[0]);
          forms.setFieldValue('detectOrgName', extend.items[0]?.label);
          setdetectOrgNameVisible(false);
        }}
      />
      <ImageViewer
        image={allImage}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />
    </div>
  );
}
