// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function getSummaryNumber(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/statistics/getSummaryNumber', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function companyRemarkNumber(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/statistics/companyRemarkNumber', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function memberKanban(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/kanban/memberKanban', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}