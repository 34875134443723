// @ts-ignore
import Navbar from '../utils/Navbar';
import styles from './index.less';
import 矩749 from './img/矩形 749.png';
import 矩形1222 from './img/矩形 1222.png';
import 新增 from './img/新增.png';
import initMerchant from './img/initMerchant.png';
import 组29 from './img/组 29.png';
import Card from 'antd-mobile/es/components/card';
import { Helmet, history, useLocation } from 'umi';
import TreeSelect, {
  TreeSelectOption,
} from 'antd-mobile/es/components/tree-select';
import {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Popup from 'antd-mobile/es/components/popup';
import {
  allotInspector,
  allotUser,
  distribution,
  extension,
  fatherSonCompany,
  getUserInfo,
  ListMore,
  ListNew,
  Lists,
  transferStart,
  userList,
  usersync,
} from './service';
import datas from '../utils/省市区线数据.json';
import {
  Button,
  Calendar,
  Checkbox,
  CheckList,
  DatePicker,
  DotLoading,
  Dropdown,
  DropdownRef,
  Form,
  Image,
  InfiniteScroll,
  Input,
  List,
  Modal,
  Picker,
  Popover,
  PullToRefresh,
  Radio,
  SearchBar,
  Selector,
  Slider,
  Space,
  Stepper,
  Tabs,
  Toast,
} from 'antd-mobile/es';
import { dateFormat } from '../utils/util';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { getWxAccessToken } from '../login/service';
import backUmirc from 'back.umirc';
import dayjs from 'dayjs';
import NameList from '../comments/NameList';
import Merchants from '../comments/Merchant';
import {
  Annotationscore,
  Annotationscoring,
  degreeIntentList,
  detectingPermeability,
  Detectionforthemonth,
  Detectionlastmonth,
  detectionRatio,
  ExhibitionHallType,
  imageStoreList,
  intentionality,
  isOrIsnt,
  MerchantsToBeActivated,
  MerchantType,
  merchantType,
  ValuePointScreening,
  valuePointsToBeAddressed,
  WhethertiedtoSalesOrTesting,
} from '../utils/signIn';
import { createOrder, saveCompanyBelongMarket } from '../collect/service';
import Pie from './common/pie';
import Market from '../comments/Market';
import MarketMultiple from '../comments/MarketMultiple';
import { FilterOutline } from 'antd-mobile-icons';
import { type } from '../awaitingDetail';
import { getMCarDealerVisitVo } from '../details/service';
export default function Merchant() {
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [form_supply] = Form.useForm();
  const [form_parking] = Form.useForm();
  const [formFloor] = Form.useForm();
  const [formDoorplate] = Form.useForm();
  const [formType] = Form.useForm();
  const location = useLocation();
  const [BDVisible, setBDVisible] = useState<boolean>(false);
  const [nameVisible, setNameVisible] = useState<boolean>(false);
  const [surveyorFlag, setSurveyorFlag] = useState<boolean>(false);
  const [companyVisible, setCompanyVisible] = useState<boolean>(false);
  const [stockFlag, setStockFlag] = useState<boolean>(false);
  const [isLeaderInDept, setisLeaderInDept] = useState<boolean>(false);
  const [marketVisible, setMarketVisible] = useState<boolean>(false);
  const [selectMarketVisible, setSelectMarketVisible] =
    useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleTime, setVisibleTime] = useState<boolean>(false);
  const [visitVisibleTime, setVisitVisibleTime] = useState<boolean>(false);
  const [lastSalesCallTimeVisibleTime, setlastSalesCallTimeVisibleTime] = useState<boolean>(false);
  const [lastCarSourceVisitTime, setlastCarSourceVisitTime] = useState<boolean>(false);
  const [notLastSalesCallTimeVisible, setnotLastSalesCallTimeVisible] = useState<boolean>(false);
  const [notLastCarSourceVisitTimeVisible, setnotLastCarSourceVisitTimeVisible] = useState<boolean>(false);
  const [moreFilteringVisible, setMoreFilteringVisible] =
    useState<boolean>(false);
  const [options, setoptions] = useState<any>([]);
  const [stockTransfer, setStockTransfer] = useState<any>([]);
  const [inspectorsList, setInspectorsList] = useState<any>({});
  const [distributionList, setdistributionList] = useState<any>({});
  const [annotatorList, setannotatorList] = useState<any>({});
  const [trainerList, settrainerList] = useState<any>({});
  const [timeMore, setTimeMore] = useState<any>([
    dayjs(new Date()).format('YYYY-MM-DD'),
    dayjs(new Date()).format('YYYY-MM-DD'),
  ]);
  const [visitTimeMore, setVisitTimeMore] = useState<any>([
    dayjs(new Date()).format('YYYY-MM-DD'),
    dayjs(new Date()).format('YYYY-MM-DD'),
  ]);
  const [lastSalesCallTimeMore, setlastSalesCallTimeMore] = useState<any>([
    dayjs(new Date()).format('YYYY-MM-DD'),
    dayjs(new Date()).format('YYYY-MM-DD'),
  ]);
  const [lastCarSourceVisitTimeMore, setlastCarSourceVisitTimeMore] = useState<any>([
    dayjs(new Date()).format('YYYY-MM-DD'),
    dayjs(new Date()).format('YYYY-MM-DD'),
  ]);
  const [notLastSalesCallTimeMore, setnotLastSalesCallTimeMore] = useState<any>([
    dayjs(new Date()).format('YYYY-MM-DD'),
    dayjs(new Date()).format('YYYY-MM-DD'),
  ]);
  const [notLastCarSourceVisitTimeMore, setnotLastCarSourceVisitTimeMore] = useState<any>([
    dayjs(new Date()).format('YYYY-MM-DD'),
    dayjs(new Date()).format('YYYY-MM-DD'),
  ]);
  const [cityCode, setCityCode] = useState<any>([]);
  const [visitCound, setVisitCound] = useState<any>({
    visitCoundStart: 0,
    visitCoundEnd: 100,
  });
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    flag: false,
    valuePoint: [],
    score: [],
  });
  const [presentParams, setPresentParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    flag: false,
  });
  const [socreVisible, setSocreVisible] = useState<boolean>(false);
  const [degreeIntent, setdegreeIntent] = useState<number>();
  const [degreeIntentName, setdegreeIntentName] = useState<string>('意向度');
  const [companyType, setCompanyType] = useState<string>('商户类型');
  const [toBeAddressed, setToBeAddressed] = useState<string>('待解决价值点');
  const [permeability, setpermeability] = useState<string>('检测渗透率');
  const [imageStore, setimageStore] = useState<string>('是否形象店');
  const [poolingRatio, setpoolingRatio] = useState<string>('集采/检测比例');
  const [startTimeVisible, setstartTimeVisible] = useState<boolean>(false);
  const [endTimeVisible, setendTimeVisible] = useState<boolean>(false);
  const [value, setValue] = useState<any>([]);
  const [treeFlag, setTreeFlag] = useState<any>('A');
  const [treeChildFlag, setTreeChildFlag] = useState<any>('');
  const [current, setCurrent] = useState<any>([]);
  const [actions, setActions] = useState<any>([
    { key: 'offerings', text: '产品下单', value: 1},
    { key: 'coupon', text: '体验劵', value: 2 },
    { key: 'annotator', text: '绑定批注人', value: 3 },
    { key: 'trainer', text: '绑定带教人', value: 4 },
  ]);
  const [userIds, setUserIds] = useState<any>([]);
  const [userId, setUserId] = useState<any>([]);
  const [data, setData] = useState<any[]>([]);
  const [belongMarketIds, setbelongMarketId] = useState<any[]>([]);
  const [belongMarketName, setbelongMarketName] = useState<any[]>([]);
  const [userIdList, setUserIdList] = useState<any>([]);
  const [pageNo, setpageNo] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [companyId, setCompanyId] = useState<any>({
    companyId: -1,
    flag: false,
    bd: false,
  });
  const [hasMore, setHasMore] = useState(false);
  const [stockpile, setStockpile] = useState<any>();
  const [visirtNum, setVisirtNum] = useState<any>();
  const [collectionTime, setCollectionTime] = useState<any>();
  const [iPhone, setiPhone] = useState<boolean>(true);
  const [checkMerchantList, setCheckMerchantList] = useState<any>([]);
  const [ButtonFlag, setButtonFlag] = useState<boolean>(false);
  const [countMap, setCountMap] = useState<any>({
    totalPage: 0,
    totalCount: 0,
  });
  const token = localStorage.getItem('token');
  const infoRef: any = useRef([]);
  const ref = useRef<DropdownRef>(null);
  const dataFlag: any = location.state;
  const optionsBD = [
    {
      label: '苏皖大区',
      value: 'A',
      children: [
        {
          label: '张三',
          value: 'A1',
        },
        {
          label: '李四',
          value: 'A2',
        },
        {
          label: '王二',
          value: 'A3',
        },
      ],
    },
    {
      label: '苏皖大区',
      value: 'b',
      children: [
        {
          label: '张三',
          value: 'b1',
        },
        {
          label: '李四',
          value: 'b2',
        },
        {
          label: '王二',
          value: 'b3',
        },
      ],
    },
    {
      label: '苏皖大区',
      value: 'c',
      children: [
        {
          label: '张',
          value: 'c1',
        },
        {
          label: '李',
          value: 'c2',
        },
        {
          label: '王',
          value: 'c3',
        },
      ],
    },
  ];
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年';
      case 'month':
        return data + '月';
      case 'day':
        return data + '日';
      case 'hour':
        return data + '时';
      case 'minute':
        return data + '分';
      case 'second':
        return data + '秒';
      default:
        return data;
    }
  }, []);
  const loadMore = async (pm: any = {}) => {
    if (pm.flag) {
      setParams({ ...params, ...pm });
    }
    await loadData({ ...params, ...pm });
  };
  const loadData = async (pm: any = {}) => {
    let temp = data;
    if (params.pageNo === -1 || params.pageNo === 0) {
      setParams({ ...params, pageNo: 1 });
      return;
    }
    const { datas, totalPage, totalCount } = await ListNew({
      ...params,
      ...pm,
    });
    countMap.totalCount = totalCount;
    setCountMap({ ...countMap });
  };
  const loadPresentMore = async (pm: any = {}) => {
    // if (presentParams.pageNo === -1 || presentParams.pageNo === 0 ) {
    //   setData([]);
    //   setPresentParams({ ...presentParams, pageNo: 1 });
    //   return
    // }
    if (pm.flag) {
      setPresentParams({ ...presentParams, ...pm });
    }
    if (presentParams.pageNo === -1 || presentParams.pageNo === 0) {
      setPresentParams({ ...presentParams, pageNo: 1 });
      return;
    }
    await loadPresentData({ ...presentParams, ...pm });
  };
  const loadPresentData = async (pm: any = {}) => {
    let temp = data;
    const { datas, totalPage, totalCount } = await ListNew({
      ...presentParams,
      ...pm,
    });
    countMap.totalPage = totalPage;
    setCountMap({ ...countMap });
    setPresentParams({ ...presentParams, ...pm });
    if (pm.pageNo == 1) {
      setData(datas);
    } else {
      setData([...temp, ...(datas || [])]);
    }
    setHasMore(totalPage > presentParams.pageNo);
  };
  const reload = (pm: any = {}) => {
    setParams({
      pageNo: -1,
      pageSize: 20,
      flag: false,
      score: [],
      monthProjectNum: [],
      monthUsageCount: [],
      notUsedNum: [],
      notDetect: [],
      ratioToBeInspected: [] ,
      parkingNum: [],
    });
    form.resetFields();
    forms.resetFields();
    form_parking.resetFields();
    form_supply.resetFields();
    forms.setFieldsValue(formInitValue)
    setUserIdList([]);
    loadPresentMore({
      pageNo: 1,
      pageSize: 20,
      flag: false,
      userIds: [],
      score: [],
      startTime: [],
      endTime: [],
      visitCountEnd: [],
      visitCountStart: [],
      monthUsageCount: [],
      monthProjectNum: [],
      notDetect: [],
      notUsedNum: [],
      lastSalesCallTime: [],
      ratioToBeInspectedStart: [],
      preDetectPermeability: [],
      preDetectPermeabilityList: [],
      detectPermeability: [],
      detectPermeabilityList: [],
      exhibitionHallType: [],
      exhibitionHallTypeList: [],
      bindingType: [],
      bindingTypeList: [],
      merchantType: [],
      merchantTypeList: [],
      imageShopType: [],
      imageShopTypeList: [],
      belongMarketIds: [],
      specializedFlag: [],
      detectFlag: [],
      dataPersonaFlag: [],
      quoteFlag: [],
      bookingFlag: [],
      degreeIntent: [],
      degreeIntentList: [],
      companyTypes: [],
      companyTypesList: [],
    });

    setVisitCound([0, 100]);
  };
  useEffect(() => {
    if (!params.flag) {
      loadMore({ flag: false });
    }
  }, [params]);
  useEffect(() => {
    if (!presentParams.flag) {
      loadPresentMore({ flag: false });
    }
  }, []);
  const pullListMore = async () => {
    const msg = await ListMore({});
    setData(msg.data.datas);
  };
  const callOnBD = async (list: any) => {
    const msg = await getUserInfo({
      userIds: list,
    });
    // @ts-ignore
    let lists: any = [];
    msg.data.map((item: any) => {
      lists.push(item.name);
    });
    // const arr = new Set(lists)
    // console.log([arr]);
    let arr: any = [];
    let str: string = '';
    for (let index = 0; index < lists.length; index++) {
      if (arr.indexOf(lists[index]) === -1) {
        arr.push(lists[index]);
      }
    }
    arr.map((item: any, index: number) => {
      str = str + item + `${index + 1 != arr.length ? '、' : ''}`;
    });
    form.setFieldsValue({ userIds: str });
    // setPresentParams({
    //   ...presentParams,
    //   pageNo:-1,
    //   flag:false,
    // })
    await loadPresentData({ ...params, pageNo: 1, userIds: list });
    setParams({
      ...params,
      flag: false,
      pageNo: 1,
      userIds: list,
    });
    console.log(params);
    ref.current?.close();
  };
  // const callOnBDValue = (list: any) => {
  //   let arr: any = [];
  //   getUserInfo({
  //     userIds: list,
  //   }).then((msg)=>{
  //     let lists: any = [];
  //     msg.data.map((item: any) => {
  //       lists.push(item.name);
  //     });
  //     // const arr = new Set(lists)
  //     // console.log([arr]);
  //     for (let index = 0; index < lists.length; index++) {
  //       if (arr.indexOf(lists[index]) === -1) {
  //         arr.push(lists[index]);
  //       }
  //     }
  //   })
  //   return {arr}
  // };
  const init = () => {
    let list: any[] = [];
    let list2: any[] = [];
    let list3: any[] = [];
    datas.RECORDS.map((item: any) => {
      if (item.deep === 0) {
        list.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 1) {
        list2.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 2) {
        list3.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
    });
    let city: any[] = [];
    let city2: any[] = [];
    list2.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list3.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city.push(data);
    });
    list.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list2.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city2.push(data);
    });
    setoptions(city2);
    console.log(city2);
  };

  useEffect(() => {
    init();
    if (localStorage.getItem('userId')) {
      setCurrent(localStorage.getItem('userId'));
    }
    let userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        userAgent,
      )
    ) {
      console.log('前端是移动端');
      setiPhone(true);
    } else {
      console.log('前端是pc端');
      setiPhone(false);
    }
    const name = localStorage.getItem("name")
    if((name === "朱葛") || (name === "温发宝") || (name === "徐涛") ){
      setActions([
        { key: 'offerings', text: '产品下单', value: 1},
        { key: 'coupon', text: '体验劵', value: 2 },
        { key: 'annotator', text: '绑定批注人', value: 3 },
        { key: 'trainer', text: '绑定带教人', value: 4 },
        { key: 'transfer', text: '库存转移', value: 5 },
        { key: 'extension', text: '库存延期', value: 6 },
        { key: 'usersync', text: '同步库存', value: 7 },
      ])
    }
  }, [localStorage.getItem('userId')]);
  const marks = {
    0: 0,
    9: 1,
    18: 2,
    27: 3,
    36: 4,
    45: 5,
    54: 6,
    63: 7,
    72: 8,
    81: 9,
    90: 10,
    100: '不限',
  };

  const toastValue = (value: any) => {
    if (value[1] < 100) {
      let visitCountStart = value[0] / 9;
      let visitCountEnd = value[1] / 9;
      setParams({
        ...params,
        flag: false,
        visitCountStart: visitCountStart,
        visitCountEnd: visitCountEnd,
      });
    }
    if (value[1] === 100) {
      let visitCountStart = value[0] / 9;
      setParams({
        ...params,
        visitCountStart: visitCountStart,
        visitCountEnd: 10000,
      });
    }
    setVisitCound({ visitCountStart: value[0], visitCountEnd: value[1] });
  };

  const wxShareConfig = async () => {
    // setUserId(["ChenHong"])
    // callOnBD(["ChenHong"])
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/",
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareTimeline'],
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['selectEnterpriseContact'],
        success: function (res: any) {
          // 回调
        },
        fail: function (res: any) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
    }
    // WWOpenData.bind(document.querySelector('ww-open-data'))
    // WWOpenData.on(event: string, callback: Function)
    // WWOpenData.on("", )
    //
    // @ts-ignore
    window.wx.invoke(
      'selectEnterpriseContact',
      {
        fromDepartmentId: 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        mode: 'multi', // 必填，选择模式，single表示单选，multi表示多选
        type: ['department', 'user'], // 必填，选择限制类型，指定department、user中的一个或者多个
      },
      function (res: any) {
        setCurrent(res);
        console.log(res);
        if (res.err_msg == 'selectEnterpriseContact:ok') {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result); //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          var selectedDepartmentList = res.result.departmentList; // 已选的部门列表
          for (var i = 0; i < selectedDepartmentList.length; i++) {
            var department = selectedDepartmentList[i];
            var departmentId = department.id; // 已选的单个部门ID
            var departemntName = department.name; // 已选的单个部门名称
            Toast.show({
              icon: 'success',
              content: departmentId,
            });
          }
          var selectedUserList = res.result.userList; // 已选的成员列表
          let list = [];
          for (var i = 0; i < selectedUserList.length; i++) {
            var user = selectedUserList[i];
            var userId = user.id; // 已选的单个成员ID
            var userName = user.name; // 已选的单个成员名称
            var userAvatar = user.avatar; // 已选的单个成员头像
            // setCurrent(userName)
            list.push(userId);
          }
          callOnBD(list);
          // Toast.show({
          //   icon:"success",
          //   content:userId
          // })
        }
      },
    );
  };
  const wxShareConfiGabroad = async (companyId: number, type: number) => {
    // setUserId(["ChenHong"])
    // callOnBD(["ChenHong"])
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/",
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareTimeline'],
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['selectEnterpriseContact'],
        success: function (res: any) {
          // 回调
        },
        fail: function (res: any) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
    }
    // WWOpenData.bind(document.querySelector('ww-open-data'))
    // WWOpenData.on(event: string, callback: Function)
    // WWOpenData.on("", )
    //
    // @ts-ignore
    window.wx.invoke(
      'selectEnterpriseContact',
      {
        fromDepartmentId: 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        mode: 'single', // 必填，选择模式，single表示单选，multi表示多选
        type: ['department', 'user'], // 必填，选择限制类型，指定department、user中的一个或者多个
      },
      function (res: any) {
        setCurrent(res);
        console.log(res);
        if (res.err_msg == 'selectEnterpriseContact:ok') {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result); //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          var selectedDepartmentList = res.result.departmentList; // 已选的部门列表
          for (var i = 0; i < selectedDepartmentList.length; i++) {
            var department = selectedDepartmentList[i];
            var departmentId = department.id; // 已选的单个部门ID
            var departemntName = department.name; // 已选的单个部门名称
            Toast.show({
              icon: 'success',
              content: departmentId,
            });
          }
          var selectedUserList = res.result.userList; // 已选的成员列表
          let list = [];
          for (var i = 0; i < selectedUserList.length; i++) {
            var user = selectedUserList[i];
            var userId = user.id; // 已选的单个成员ID
            var userName = user.name; // 已选的单个成员名称
            var userAvatar = user.avatar; // 已选的单个成员头像
            if (type > 0) {
              bindingAnnotator(companyId, userId, type);
            } else {
              bindingSales(companyId, userId);
            }
          }
          // Toast.show({
          //   icon:"success",
          //   content:userId
          // })
        }
      },
    );
  };
  const bindingSales = (companyId: number, userId: any) => {
    distribution({
      id: companyId,
      userId: userId,
    })
      .then((res) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '分配成功',
          });
          getUserInfo({
            userIds: [userId],
          }).then((res) => {
            distributionList[companyId] = res.data[0];
            setdistributionList({ ...distributionList });
          });
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch((err) => {
        Toast.show({
          icon: 'fail',
          content: '分配失败',
        });
      });
  };
  const bindingAnnotator = (companyId: number, userId: any, type: number) => {
    allotUser({
      companyId: companyId,
      userId: userId,
      type: type,
    })
      .then((res) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '分配成功',
          });
          getUserInfo({
            userIds: [userId],
          }).then((res) => {
            if (type === 1) {
              annotatorList[companyId] = res.data[0];
              setannotatorList({ ...annotatorList });
            } else {
              trainerList[companyId] = res.data[0];
              settrainerList({ ...trainerList });
            }
          });
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch((err) => {
        Toast.show({
          icon: 'fail',
          content: '分配失败',
        });
      });
  };
  const onSearch = async (value: string) => {
    setParams({
      ...params,
      phoneAndName: value,
    });
    loadPresentData({ ...presentParams, pageNo: 1, phoneAndName: value });
  };
  const handelCompanyName = async (value: any) => {
    if (value && value.length > 0) {
      let arr = value[0].split('===');
      const msg = await getMCarDealerVisitVo({
        companyId: arr[1],
      });
      transferStart({
        phone: stockTransfer.contactMobileNo,
        stockPhone: msg.data.mCompanyInfo.contactMobileNo,
      })
        .then((res: any) => {
          if (res.retCode === 0) {
            Toast.show({
              icon: 'success',
              content: '转移成功',
            });
            setCompanyVisible(false);
            reload();
          } else {
            Toast.show({
              icon: 'fail',
              content: res.retMsg,
            });
          }
        })
        .catch((err) => {
          Toast.show({
            icon: 'fail',
            content: '转移失败',
          });
        });
    }
  };
  const inventoryExtension = async (value: any) => {
    extension({
      phone:value.contactMobileNo
    })
    .then((res: any) => {
      if (res.retCode === 0) {
        Toast.show({
          icon: 'success',
          content: '延期成功',
        });
        reload();
      } else {
        Toast.show({
          icon: 'fail',
          content: res.retMsg,
        });
      }
    })
    .catch((err) => {
      Toast.show({
        icon: 'fail',
        content: '延期失败',
      });
    });
  }
  const handleCompanyNameCancel = async () => {
    setCompanyVisible(false);
  };

  const onChange = (e: boolean, value: any) => {
    let list = checkMerchantList;
    if (e) {
      list.push(value);
      // const Lists = list.filter((item: any) => item);
      let Lists: any = [];
      list.forEach((key: any, index: any) => {
        if (Lists.indexOf(key.id) === -1) {
          Lists.push(key);
        }
      });
      setCheckMerchantList(Lists);
      console.log(e, Lists);
    } else {
      console.log(e);
      const list = checkMerchantList.filter((item: any) => item.id != value.id);
      console.log(e, list);
      setCheckMerchantList(list);
    }
  };
  const handelUserName = async (value: any) => {
    console.log(value);
    if (surveyorFlag && value.length > 0) {
      let arr: any[] = [];
      getUserInfo({
        userIds: value,
      }).then((msg) => {
        let lists: any = [];
        msg.data.map((item: any) => {
          lists.push({ id: item.userId, name: item.name });
        });
        // const arr = new Set(lists)
        // console.log([arr]);
        for (let index = 0; index < lists.length; index++) {
          if (arr.indexOf(lists[index]) === -1) {
            arr.push(lists[index]);
          }
        }
        Modal.confirm({
          title: <div style={{ textAlign: 'center' }}>是否可以集采</div>,
          content: (
            <div className={styles.checkMerchant}>
              {arr &&
                arr.length > 0 &&
                arr.map((item: any, index: number) => {
                  console.log(item);
                  return (
                    <div className={styles.checkMerchant_text} key={item.id}>
                      <div>{item.name}</div>
                      <Checkbox
                        defaultChecked={false}
                        value={item.id}
                        onChange={(checked) => {
                          onChange(checked, item);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          ),
          onConfirm: () => {
            DistributionTester(value);
          },
          bodyClassName: styles.bodyClassName,
        });
      });
    } else {
      if (companyId.flag) {
        if (companyId.type > 0) {
          bindingAnnotator(companyId.companyId, value[0], companyId.type);
        } else {
          bindingSales(companyId.companyId, value[0]);
        }
      } else {
        callOnBD(value);
        setUserIdList(value);
      }
    }
    setNameVisible(false);
    setSurveyorFlag(false);
    setisLeaderInDept(false);
  };
  const DistributionTester = (value: any) => {
    let list: { inspectorId: any; batchBuyFlag: boolean }[] = [];
    value.map((item: any) => {
      let flag = false;
      checkMerchantList.map((child: any) => {
        if (item === child.id) {
          flag = true;
        }
      });
      list.push({
        inspectorId: item,
        batchBuyFlag: flag,
      });
    });
    allotInspector({
      companyId: companyId.companyId,
      inspectors: list,
    })
      .then((res) => {
        if (res.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '分配成功',
          });
          let list3: any = [];
          list.map((item: any) => {
            list3.push(item.inspectorId);
          });
          getUserInfo({
            userIds: list3,
          }).then((res) => {
            let arr: any = [];
            if (res.data) {
              res.data.map((item: any) => {
                arr.push(item.name);
                console.log(item.name);
              });
            }
            inspectorsList[companyId.companyId] = arr;
            setInspectorsList({ ...inspectorsList });
          });
        } else {
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          });
        }
      })
      .catch((err) => {
        Toast.show({
          icon: 'fail',
          content: '分配失败',
        });
      });
  };
  const formInitValue = {
    monthUsageCountEnd: null,
    monthUsageCountStart: null,
    monthProjectNumEnd: null,
    monthProjectNumStart: null,
    notUsedNumEnd: null,
    notUsedNumStart:null,
  }
  const handelUserMarket = async (value: any) => {
    console.log(value);
    // localStorage.setItem('belongMarketId', value[0].id);
    // formComany.setFieldValue('belongMarketName', value[0].marketName);
    if (value && value[0]) {
      saveCompanyBelongMarket({ companyId: companyId, marketId: value[0].id })
        .then((res: any) => {
          if (res.retCode === 0) {
            createOrder({ companyId: companyId, tasteFlag: true })
              .then((res) => {
                if (res.retCode === 0) {
                  Toast.show({
                    icon: 'success',
                    content: '申请成功',
                  });
                  reload();
                } else {
                  Toast.show({
                    icon: 'fail',
                    content: res.retMsg,
                  });
                  if (res.retCode === 1000005) {
                    setMarketVisible(true);
                  }
                }
              })
              .catch((err) => {
                Toast.show({
                  icon: 'fail',
                  content: '申请失败',
                });
              });
          } else {
            Toast.show({
              icon: 'fail',
              content: res.retMsg,
            });
          }
          setMarketVisible(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '发放失败',
          });
        });
    }
  };
  const handelSelectMarket = async (value: any, nameList: any) => {
    console.log(value);
    if (value && value.length > 0) {
      let list: any = [];
      let list2: any = [];
      let NameStr = '';
      nameList.map((item: any) => {
        if (value.indexOf(item.id) > -1) {
          list2.push(item);
        }
      });
      list2.map((item: any, index: number) => {
        list.push(item.id);
        NameStr =
          NameStr +
          item.marketName +
          `${index + 1 != value.length ? '、' : ''}`;
      });
      setParams({ ...params, belongMarketIds: list });
      form.setFieldsValue({ belongMarketName: NameStr });
    } else {
      form.setFieldsValue({ belongMarketName: '' });
    }
    setSelectMarketVisible(false);
  };
  const handleUserMarketCancel = async () => {
    setMarketVisible(false);
    setSelectMarketVisible(false);
  };
  const handleUserNameCancel = async () => {
    setNameVisible(false);
    setSurveyorFlag(false);
    setisLeaderInDept(false);
    ref.current?.close();
  };
  const onClear = async () => {
    infoRef.current.degreeIntent = null;
    infoRef.current.repurchaseFocusFlag = null;
    infoRef.current.newPurchasesFocusFlag = null;
    infoRef.current.activationRepurchaseFlag = null;
    infoRef.current.vehicleSourceVisitsFlag = null;
    infoRef.current.notResolvedIssueNum = null;
    infoRef.current.detectPermeability = null;
    infoRef.current.imageStore = null;
    infoRef.current.poolingRatio = null;
    setdegreeIntentName('意向度');
    setCompanyType('商户类型');
    setToBeAddressed('待解决价值点');
    setpermeability('检测渗透率');
    setimageStore('是否形象店');
    setpoolingRatio('集采/检测比例');
    form.resetFields();
    formFloor.resetFields();
    formDoorplate.resetFields();
    formType.resetFields();
    setCityCode([]);
    setUserIds([]);
    setUserId([]);
    setUserIdList([]);
    setbelongMarketName([]);
    setbelongMarketId([]);
    setStockpile(undefined);
    setVisirtNum(undefined);
    setCollectionTime(undefined);

    // data.value = []
    reload();
  };
  const keyList = [
    'monthProjectNum',
    'monthUsageCount',
    'notUsedNum',
    'notDetect',
    'ratioToBeInspected',
    'parkingNum',
  ];
  const onValuesChange = () => {
    const stocksValues = forms.getFieldsValue();
    const supplyValues = form_supply.getFieldsValue();
    const parkingNumValues = form_parking.getFieldsValue();
    console.log(stocksValues);
    let list = { ...stocksValues, ...supplyValues, ...parkingNumValues };
    console.log(list);

    const temp: any = {};
    keyList.map((item: any) => {
      if (
        (`${list[`${item}Start`]}` != '' && list[`${item}Start`] != null) ||
        (`${list[`${item}End`]}` != '' && list[`${item}End`] != null)
      ) {
        if (
          `${list[`${item}Start`]}` === '0' &&
          `${list[`${item}End`]}` === '0'
        ) {
          temp[item] = '0_0';
          temp[`${item}Text`] = '0-0';
        } else {
          if (`${list[`${item}End`]}` === '' || list[`${item}End`] === null) {
            temp[item] = `${list[`${item}Start`] || 0}_`;
            temp[`${item}Text`] = `${list[`${item}Start`] || 0}以上`;
          } else if (
            `${list[`${item}Start`]}` === '' ||
            list[`${item}Start`] === null
          ) {
            temp[item] = `_${list[`${item}End`] || 0}`;
            temp[`${item}Text`] = `${list[`${item}End`] || 0}以下`;
          } else {
            temp[item] = `${list[`${item}Start`] || 0}_${
              list[`${item}End`] || 0
            }`;
            temp[`${item}Text`] = `${list[`${item}Start`] || 0}-${
              list[`${item}End`] || 0
            }`;
          }
        }
      } else {
        temp[item] = [];
        temp[`${item}Text`] = [];
      }
    });
    setParams({
      ...params,
      ...temp,
    });
  };
  const divValue = (key: number) => {
    return (
      <div className={styles.Dropdown_footer}>
        <div
          onClick={() => {
            reload();
          }}
          className={styles.Dropdown_footer_0}
        >
          重置
        </div>
        <div
          onClick={async () => {
            await loadPresentData({ ...params, pageNo: 1 });
            setMoreFilteringVisible(false);
            ref.current?.close();
          }}
          className={styles.Dropdown_footer_1}
        >
          查看{countMap.totalCount || 0}个商户
        </div>
      </div>
    );
  };
  // 价值点
  const divValuePoint = () => {
    return (
      <div className={styles.Dropdown}>
        <div className={styles.Dropdown_content}>
          <div className={styles.Dropdown_content_0}>价值点</div>
          {/* <Selector
            options={ValuePointScreening}
            value={params.valuePoint || []}
            multiple
            onChange={(arr, extend) => {
              // setSocreVisible(true)
              setParams({ ...params, valuePoint: arr, flag: false });
            }}
          /> */}
          <div className={styles.slector}>
            {ValuePointScreening.map((item: any) => {
              return (
                <div className={styles.slectorDiv}>
                  <div>{item.label}</div>
                  <Selector
                    // @ts-ignore
                    options={Annotationscoring}
                    multiple
                    value={(params.score && params.score[item.value]) || []}
                    onChange={(arr, extend) => {
                      let list = params.score;
                      if (arr.length != 2) {
                        params[item.key] = arr[0];
                      } else {
                        delete params[item.key];
                      }
                      list[item.value] = arr;
                      list[item.value].label = item.label;
                      console.log(list, params, arr);
                      setParams({ ...params, score: list, flag: false });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className={styles.Dropdown_content}>
          <div className={styles.Dropdown_content_0}>批注评分</div>
        </div> */}
      </div>
    );
  };
  // 库存
  const divPropertyOrCashHeldInReserve = (type: number) => {
    return (
      <List style={{ paddingTop: '8px' }}>
        <div className={styles.Dropdown_content_0}>
          <span>库存</span>
          {type === 1 && (
            <span
              onClick={() => {
                forms.resetFields();
                // onValuesChange()
                setParams({
                  ...params,
                  monthProjectNum: [],
                  monthUsageCount: [],
                  notUsedNum: [],
                });
                forms.setFieldsValue(formInitValue)
              }}
              className={styles.Dropdown_content_0_reprovision}
            >
              重置
            </span>
          )}
        </div>
        <Form
          form={forms}
          className={styles.form_modal}
          onValuesChange={onValuesChange}
        >
          <List.Item prefix={'月集采'}>
            <Form.Item
              name="monthProjectNumStart"
              className={styles.form_item_0}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
          <span style={{ fontSize: '16px', margin: '5px' }}>-</span>
          <List.Item>
            <Form.Item
              name="monthProjectNumEnd"
              className={styles.form_item}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
        </Form>
        <Form
          form={forms}
          className={styles.form_modal}
          onValuesChange={onValuesChange}
        >
          <List.Item prefix={'月使用'}>
            <Form.Item
              name="monthUsageCountStart"
              className={styles.form_item_0}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
          <span style={{ fontSize: '16px', margin: '5px' }}>-</span>
          <List.Item>
            <Form.Item
              name="monthUsageCountEnd"
              className={styles.form_item}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
        </Form>
        <Form
          form={forms}
          className={styles.form_modal}
          onValuesChange={onValuesChange}
        >
          <List.Item prefix={'库存'}>
            <Form.Item
              name="notUsedNumStart"
              className={styles.form_item_0}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
          <span style={{ fontSize: '16px', margin: '5px' }}>-</span>
          <List.Item>
            <Form.Item
              name="notUsedNumEnd"
              className={styles.form_item}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
        </Form>
      </List>
    );
  };
  // 车位数
  const divParkingNum = () => {
    return (
      <List style={{ paddingTop: '8px' }}>
        <div className={styles.Dropdown_content_0}>
          <span>车位数</span>
          <span
            onClick={() => {
              form_parking.resetFields();
              // onValuesChange()
              setParams({
                ...params,
                parkingNum: [],
              });
            }}
            className={styles.Dropdown_content_0_reprovision}
          >
            重置
          </span>
        </div>
        <Form
          form={form_parking}
          className={styles.form_modal}
          onValuesChange={onValuesChange}
        >
          <List.Item prefix={'车位数'}>
            <Form.Item
              name="parkingNumStart"
              className={styles.form_item_0}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
          <span style={{ fontSize: '16px', margin: '5px' }}>-</span>
          <List.Item>
            <Form.Item
              name="parkingNumEnd"
              className={styles.form_item}
              initialValue={null}
            >
              <Stepper allowEmpty min={0} />
            </Form.Item>
          </List.Item>
        </Form>
      </List>
    );
  };
  // 检测渗透率
  const divDetectingPermeability = () => {
    return (
      <div className={styles.Dropdown}>
        <div className={styles.Dropdown_content}>
          <div className={styles.Dropdown_content_0}>检测渗透率（本月）</div>
          <Selector
            options={Detectionforthemonth}
            value={params.detectPermeability || []}
            multiple
            onChange={(arr, extend) =>
              setParams({
                ...params,
                detectPermeability: arr,
                detectPermeabilityList: extend,
              })
            }
          />
        </div>
        <div className={styles.Dropdown_content}>
          <div className={styles.Dropdown_content_0}>检测渗透率（上月）</div>
          <Selector
            options={Detectionlastmonth}
            value={params.preDetectPermeability || []}
            multiple
            onChange={(arr, extend) =>
              setParams({
                ...params,
                preDetectPermeability: arr,
                preDetectPermeabilityList: extend,
              })
            }
          />
        </div>
      </div>
    );
  };
  const timeKeyList = [
    {label:"上次集采时间",key:"lastCollectionTime"},
    {label:"未集采时间",key:"notLastCollectionTime"},
    {label:"上次拜访时间（销售）",key:"lastSalesCallTime"},
    {label:"上次拜访时间（车源）",key:"lastCarSourceVisitTime"},
    {label:"未拜访时间（销售）",key:"notLastSalesCallTime"},
    {label:"未拜访时间（车源）",key:"notLastCarSourceVisitTime"},
  ]
  // 检测渗透率
  const divMoreElse = () => {
    return (
      <>
        <List style={{ paddingTop: '8px', borderBottom: '1px solid #E6E6E6' }}>
          <Form form={form} className={styles.form_modal_0}>
            <List.Item
              prefix={'岗位'}
              onClick={() => {
                if (iPhone) {
                  wxShareConfig();
                } else {
                  setNameVisible(true);
                  setCompanyId({ companyId: -1, flag: false, bd: true });
                }
              }}
            >
              <Form.Item
                name="userIds"
                rules={[{ required: true, message: '请选择岗位' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择岗位" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item
              prefix={'市场'}
              onClick={() => {
                console.log(111);
                setSelectMarketVisible(true);
              }}
            >
              <Form.Item
                name="belongMarketName"
                rules={[{ required: true, message: '请选择市场' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择市场" readOnly />
              </Form.Item>
            </List.Item>
          </Form>
        </List>
        <div className={styles.Dropdown}>
          <div className={styles.Dropdown_content}>
            <div className={styles.Dropdown_content_0}>展厅类型</div>
            <Selector
              options={ExhibitionHallType}
              value={params.exhibitionHallType || []}
              multiple
              onChange={(arr, extend) => {
                setParams({
                  ...params,
                  exhibitionHallType: arr,
                  exhibitionHallTypeList: extend,
                });
              }}
            />
          </div>
          <div className={styles.Dropdown_content}>
            <div className={styles.Dropdown_content_0}>是否绑定销售或检测</div>
            <Selector
              options={WhethertiedtoSalesOrTesting}
              value={params.bindingType || []}
              multiple
              onChange={(arr, extend) => {
                setParams({
                  ...params,
                  bindingType: arr,
                  bindingTypeList: extend,
                });
              }}
            />
          </div>
          <div className={styles.Dropdown_content}>
            <div className={styles.Dropdown_content_0}>商户类型</div>
            <Selector
              options={MerchantType}
              multiple
              value={params.merchantType || []}
              onChange={(arr, extend) => {
                setParams({
                  ...params,
                  merchantType: arr,
                  merchantTypeList: extend,
                });
              }}
            />
          </div>
          <div className={styles.Dropdown_content}>
            <div className={styles.Dropdown_content_0}>是否待激活商户</div>
            <Selector
              options={MerchantsToBeActivated}
              multiple
              value={params && (params.companyTypes || [])}
              onChange={(arr, extend) => {
                setParams({
                  ...params,
                  companyTypes: arr,
                  companyTypesList: extend,
                });
              }}
            />
          </div>
          <div className={styles.Dropdown_content}>
            <div className={styles.Dropdown_content_0}>意向度</div>
            <Selector
              options={intentionality}
              value={params.degreeIntent || []}
              multiple
              onChange={(arr, extend) =>
                setParams({
                  ...params,
                  degreeIntent: arr,
                  degreeIntentList: extend,
                })
              }
            />
          </div>
        </div>
        <List style={{ paddingTop: '8px', borderBottom: '1px solid #E6E6E6' }}>
          <Form form={form} className={styles.form_modal_0}>
            {/* {timeKeyList.map((item:any)=>{
              return (
                <List.Item
                  prefix={item.label}
                  onClick={() => {
                    setVisible(true);
                    setTimeList({
                      visible
                    })
                  }}
                >
                  <Form.Item
                    name={item.key}
                    rules={[{ required: true, message: '请选择时间段' }]}
                    className={styles.form_item_0}
                  >
                    <Input placeholder="请选择时间段" readOnly />
                  </Form.Item>
                </List.Item>
              )
            })} */}
            <List.Item
              prefix={'上次集采时间'}
              onClick={() => {
                console.log(111);
                setVisibleTime(true);
              }}
            >
              <Form.Item
                name="lastCollectionTime"
                rules={[{ required: true, message: '请选择时间段' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择时间段" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item
              prefix={'未集采时间'}
              onClick={() => {
                console.log(111);
                setVisitVisibleTime(true);
              }}
            >
              <Form.Item
                name="notLastCollectionTime"
                rules={[{ required: true, message: '请选择时间段' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择时间段" readOnly />
              </Form.Item>
            </List.Item>
          </Form>
        </List>
        <List style={{ paddingTop: '8px', borderBottom: '1px solid #E6E6E6' }}>
          <Form form={form} className={styles.form_modal_0}>
            <List.Item
              prefix={'上次拜访时间（销售）'}
              onClick={() => {
                console.log(111);
                setlastSalesCallTimeVisibleTime(true);
              }}
            >
              <Form.Item
                name="lastSalesCallTime"
                rules={[{ required: true, message: '请选择时间段' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择时间段" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item
              prefix={'上次拜访时间（车源）'}
              onClick={() => {
                console.log(111);
                setlastCarSourceVisitTime(true);
              }}
            >
              <Form.Item
                name="lastCarSourceVisitTime"
                rules={[{ required: true, message: '请选择时间段' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择时间段" readOnly />
              </Form.Item>
            </List.Item>
          </Form>
        </List>
        <List style={{ paddingTop: '8px', borderBottom: '1px solid #E6E6E6' }}>
          <Form form={form} className={styles.form_modal_0}>
            <List.Item
              prefix={'未拜访时间（销售）'}
              onClick={() => {
                console.log(111);
                setnotLastSalesCallTimeVisible(true);
              }}
            >
              <Form.Item
                name="notLastSalesCallTime"
                rules={[{ required: true, message: '请选择时间段' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择时间段" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item
              prefix={'未拜访时间（车源）'}
              onClick={() => {
                console.log(111);
                setnotLastCarSourceVisitTimeVisible(true);
              }}
            >
              <Form.Item
                name="notLastCarSourceVisitTime"
                rules={[{ required: true, message: '请选择时间段' }]}
                className={styles.form_item_0}
              >
                <Input placeholder="请选择时间段" readOnly />
              </Form.Item>
            </List.Item>
          </Form>
        </List>
        <div className={styles.Dropdown}>
          <div className={styles.Dropdown_content}>
            <div className={styles.Dropdown_content_0}>
              <span>拜访次数</span>
              {
                <span>
                  {visitCound.visitCountStart / 9 || 0} ~{' '}
                  {!visitCound.visitCountEnd || visitCound.visitCountEnd === 100
                    ? '不限'
                    : visitCound.visitCountEnd / 9}
                </span>
              }
            </div>
            <Slider
              marks={marks}
              ticks
              range
              value={[
                visitCound.visitCountStart || 0,
                visitCound.visitCountEnd || 100,
              ]}
              onAfterChange={toastValue}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </div>
          <div className={styles.Dropdown_content}>
            <div className={styles.Dropdown_content_0}>形象店</div>
            <Selector
              options={imageStoreList}
              value={params.imageShopType || []}
              multiple
              onChange={(arr, extend) => {
                setParams({
                  ...params,
                  imageShopType: arr,
                  imageShopTypeList: extend,
                });
              }}
            />
          </div>
        </div>
        <List style={{ paddingTop: '8px', borderBottom: '1px solid #E6E6E6' }}>
          <div className={styles.Dropdown_content_0}>
            <span>车源量</span>
            <span
              onClick={() => {
                form_supply.resetFields();
                // onValuesChange()
                setParams({ ...params, notDetect: [], ratioToBeInspected: [] });
              }}
              className={styles.Dropdown_content_0_reprovision}
            >
              重置
            </span>
          </div>
          <Form
            form={form_supply}
            className={styles.form_modal}
            onValuesChange={onValuesChange}
          >
            <List.Item prefix={'待检量'}>
              <Form.Item
                name="notDetectStart"
                initialValue={null}
                className={styles.form_item_0}
              >
                <Stepper allowEmpty min={0} />
              </Form.Item>
            </List.Item>
            <span style={{ fontSize: '16px', margin: '5px' }}>-</span>
            <List.Item>
              <Form.Item
                name="notDetectEnd"
                initialValue={null}
                className={styles.form_item}
              >
                <Stepper allowEmpty min={0} />
              </Form.Item>
            </List.Item>
          </Form>
          <Form
            form={form_supply}
            className={styles.form_modal}
            onValuesChange={onValuesChange}
          >
            <List.Item prefix={'待检比例(%)'}>
              <Form.Item
                name="ratioToBeInspectedStart"
                className={styles.form_item_0}
                initialValue={null}
              >
                <Stepper allowEmpty min={0} />
              </Form.Item>
            </List.Item>
            <span style={{ fontSize: '16px', margin: '5px' }}>-</span>
            <List.Item>
              <Form.Item
                name="ratioToBeInspectedEnd"
                className={styles.form_item}
                initialValue={null}
              >
                <Stepper allowEmpty min={0} />
              </Form.Item>
            </List.Item>
          </Form>
          <div style={{ fontSize: '12px' }}>例：大于50%，在左侧输入50即可</div>
        </List>
      </>
    );
  };
  return (
    <div className={styles.page}>
      <div className={styles.merchant}>
        <Navbar name={'商户中心'}></Navbar>
        <div
          style={{ height: '20px', width: '100%', background: '#ffffff' }}
        ></div>
        <div className={styles.search}>
          <div style={{ width: '75%' }}>
            <SearchBar
              onSearch={onSearch}
              // style={{ '--background': '#E9ECF0'}}
              placeholder="商户名称或者手机号查询"
            />
          </div>
          <div
            className={styles.menu_new}
            onClick={() => {
              history.push('./businesses');
            }}
          >
            <div>
              <img src={新增} />
              <span>新增商户</span>
            </div>
          </div>
        </div>
        <div className={styles.menu}>
          <div className={styles.menu_screening}>
            <Dropdown ref={ref}>
              <Dropdown.Item
                key="sorter"
                title={
                  <span
                    className={`${
                      params.score &&
                      params.score.length > 0 &&
                      styles.selectColor
                    }`}
                  >
                    价值点
                  </span>
                }
              >
                {divValuePoint()}
                <div className={styles.Dropdown_footer}>
                  <div
                    onClick={() => {
                      reload();
                      setParams({
                        ...params,
                        score: [],
                        specializedFlag: [],
                        detectFlag: [],
                        dataPersonaFlag: [],
                        quoteFlag: [],
                        bookingFlag: [],
                      });
                    }}
                    className={styles.Dropdown_footer_0}
                  >
                    重置
                  </div>
                  <div
                    onClick={async () => {
                      await loadPresentData({ ...params, pageNo: 1 });
                      setMoreFilteringVisible(false);
                      ref.current?.close();
                    }}
                    className={styles.Dropdown_footer_1}
                  >
                    查看{countMap.totalCount || 0}个商户
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                key="bizop"
                title={
                  <span
                    className={`${
                      ( (params.monthUsageCount && params.monthUsageCount != "") ||
                        (params.monthProjectNum && params.monthProjectNum != "") ||
                        (params.notUsedNum && params.notUsedNum != "")) &&
                      styles.selectColor
                    }`}
                  >
                    库存
                  </span>
                }
              >
                {divPropertyOrCashHeldInReserve(2)}
                <div className={styles.Dropdown_footer}>
                  <div
                    onClick={() => {
                      forms.resetFields();
                      setParams({
                        ...params,
                        monthProjectNum: [],
                        monthUsageCount: [],
                        notUsedNum: [],
                      });
                      forms.setFieldsValue(formInitValue)
                    }}
                    className={styles.Dropdown_footer_0}
                  >
                    重置
                  </div>
                  <div
                    onClick={async () => {
                      await loadPresentData({ ...params, pageNo: 1 });
                      setMoreFilteringVisible(false);
                      ref.current?.close();
                    }}
                    className={styles.Dropdown_footer_1}
                  >
                    查看{countMap.totalCount || 0}个商户
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                key="month"
                title={
                  <span
                    className={`${
                      (params.detectPermeability ||
                        params.preDetectPermeability) &&
                      styles.selectColor
                    }`}
                  >
                    检测渗透率
                  </span>
                }
              >
                {divDetectingPermeability()}
                <div className={styles.Dropdown_footer}>
                  <div
                    onClick={() => {
                      forms.resetFields();
                      setParams({
                        ...params,
                        detectPermeability: [],
                        detectPermeabilityList: [],
                        preDetectPermeability: [],
                        preDetectPermeabilityList: [],
                      });
                    }}
                    className={styles.Dropdown_footer_0}
                  >
                    重置
                  </div>
                  <div
                    onClick={async () => {
                      await loadPresentData({ ...params, pageNo: 1 });
                      setMoreFilteringVisible(false);
                      ref.current?.close();
                    }}
                    className={styles.Dropdown_footer_1}
                  >
                    查看{countMap.totalCount || 0}个商户
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                key="more"
                title={
                  <div
                    className={`${
                      params.userIds &&
                      params.userIds.length > 0 &&
                      styles.selectColor
                    }`}
                    onClick={() => {
                      if (iPhone) {
                        wxShareConfig();
                      } else {
                        setNameVisible(true);
                        setCompanyId({ companyId: -1, flag: false, bd: true });
                      }
                    }}
                  >
                    岗位
                  </div>
                }
              ></Dropdown.Item>
            </Dropdown>
          </div>
          <div
            className={styles.menu_new}
            onClick={() => {
              ref.current?.close();
              setMoreFilteringVisible(true);
            }}
          >
            <img src={矩形1222} />
            <div>
              <FilterOutline fontSize={16} style={{ margin: '0 7px' }} />
              <span
                className={`${
                  ((params.userIds && params.userIds.length > 0) ||
                    (params.score && params.score.length > 0) ||
                    params.startTime ||
                    params.endTime ||
                    params.visitCountEnd ||
                    params.visitCountStart ||
                    (params.monthUsageCount && params.monthUsageCount != "") ||
                    (params.monthProjectNum && params.monthProjectNum != "") ||
                    (params.notUsedNum && params.notUsedNum != "") ||
                    params.notDetect ||
                    params.lastSalesCallTime ||
                    params.ratioToBeInspectedStart ||
                    (params.preDetectPermeability &&
                      params.preDetectPermeability.length > 0) ||
                    (params.detectPermeability &&
                      params.detectPermeability.length > 0) ||
                    (params.exhibitionHallType &&
                      params.exhibitionHallType.length > 0) ||
                    (params.bindingType && params.bindingType.length > 0) ||
                    (params.imageShopType && params.imageShopType.length > 0) ||
                    (params.belongMarketIds &&
                      params.belongMarketIds.length > 0) ||
                    (params.degreeIntent && params.degreeIntent.length > 0) ||
                    params.companyTypes) &&
                  styles.selectColor
                }`}
              >
                更多筛选
              </span>
            </div>
          </div>
        </div>
        <div
          style={{ display: 'flex', overflow: 'scroll', marginRight: '10px' }}
        >
          {(belongMarketName.length > 0 ||
            userIds.length > 0 ||
            stockpile ||
            visirtNum ||
            degreeIntentName != '意向度' ||
            companyType != '商户类型' ||
            toBeAddressed != '待解决价值点' ||
            permeability != '检测渗透率' ||
            imageStore != '是否形象店' ||
            poolingRatio != '集采/检测比例' ||
            collectionTime ||
            formFloor.getFieldsValue().floor ||
            formDoorplate.getFieldsValue().floor ||
            formDoorplate.getFieldsValue().belongingRegion ||
            formDoorplate.getFieldsValue().doorplate ||
            formDoorplate.getFieldsValue().contactDetailAddress ||
            totalCount != 0) && (
            <>
              <div className={styles.Tags}>
                <div
                  onClick={async () => {
                    // await onClear()
                    console.log(totalCount);
                  }}
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  商户总数 ： {totalCount}个
                </div>
              </div>
              <div className={styles.Tags}>
                <div
                  onClick={async () => {
                    await onClear();
                  }}
                >
                  重置
                </div>
              </div>
              <div className={styles.Tags}>
                {userIds.length > 0 && (
                  <div>
                    BD:
                    {userIds && userIds.join('、')}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {visirtNum && (
                  <div>
                    拜访次数:
                    {visirtNum.visitCountStart || 0}~
                    {visirtNum.visitCountEnd || 0}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {formDoorplate.getFieldsValue().doorplate && (
                  <div>
                    门牌号:
                    {formDoorplate.getFieldsValue().doorplate}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {formDoorplate.getFieldsValue().floor && (
                  <div>
                    楼层:
                    {formDoorplate.getFieldsValue().floor}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {formDoorplate.getFieldsValue().belongingRegion && (
                  <div>
                    区域:
                    {formDoorplate.getFieldsValue().belongingRegion}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {formDoorplate.getFieldsValue().contactDetailAddress && (
                  <div>
                    地址:
                    {formDoorplate.getFieldsValue().contactDetailAddress}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {degreeIntentName != '意向度' && (
                  <div>
                    意向度:
                    {degreeIntentName}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {companyType != '商户类型' && (
                  <div>
                    商户类型:
                    {companyType}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {toBeAddressed != '待解决价值点' && (
                  <div>
                    待解决价值点:
                    {toBeAddressed}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {permeability != '检测渗透率' && (
                  <div>
                    检测渗透率:
                    {permeability}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {poolingRatio != '集采/检测比例' && (
                  <div>
                    集采/检测比例:
                    {poolingRatio}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {imageStore != '是否形象店' && (
                  <div>
                    是否形象店:
                    {imageStore}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {belongMarketName && belongMarketName.length > 0 && (
                  <div>
                    市场：
                    {belongMarketName && belongMarketName.join('、')}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {stockpile && (
                  <div>
                    库存：
                    {stockpile.startNum || 0}~{stockpile.endNum || 0}
                  </div>
                )}
              </div>
              <div className={styles.Tags}>
                {collectionTime && (
                  <div>
                    集采时间：
                    {collectionTime.startTime}~{collectionTime.endTime}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className={styles.content}>
          <PullToRefresh
            onRefresh={async () => {
              await sleep(1000);
              setpageNo(1);
              reload();
            }}
          >
            {data &&
              data.length > 0 &&
              data.map((item: any) => {
                let content = '';
                if (
                  inspectorsList &&
                  inspectorsList[item.id] &&
                  inspectorsList[item.id].length > 0
                ) {
                  inspectorsList[item.id].map((child: any, index: number) => {
                    content =
                      content +
                      child +
                      `${
                        index + 1 != inspectorsList[item.id].length ? ',' : ''
                      }`;
                  });
                } else if (item.companyInspectorRelations) {
                  item.companyInspectorRelations.map(
                    (child: any, index: number) => {
                      if (child.allUser && child.allUser.name) {
                        content =
                          content +
                          child.allUser.name +
                          `${
                            index + 1 != item.companyInspectorRelations.length
                              ? ','
                              : ''
                          }`;
                      }
                    },
                  );
                }
                return (
                  <div className={styles.customBody} key={item.id}>
                    <div
                      className={styles.customBody_title}
                    >
                      <div className={styles.customBody_title_father}>
                        {/* {item.fatherSonFlag && <div>主商户</div>}
                        {!item.fatherSonFlag && item.fatherSonFlag != null && (
                          <div>子商户</div>
                        )} */}
                        <div className={styles.customBody_title_t}>
                          {item.doorHeadPhoto ? (
                            <Image
                              src={`${item.doorHeadPhoto}?x-image-process=image/resize,l_100`}
                              width={45}
                              height={45}
                              fit="fill"
                            />
                          ) : (
                            <Image
                              src={initMerchant}
                              width={45}
                              height={45}
                              fit="fill"
                            />
                          )}
                        </div>
                      </div>
                      <div className={styles.customBody_title_text}>
                        <div className={styles.customBody_title_text_name}>
                          {/* <div>{item.companyName}</div> <span></span> {item.remoteRecommendFlag && <a>推荐</a>} */}
                          <div>{item.companyName}</div>{' '}
                          <span>{item.contactName}</span>
                          <a className={styles.contactMobileNo} href={`tel:${item.contactMobileNo}`}>{item.contactMobileNo || "-"}</a>
                        </div>
                        <div className={styles.customBody_title_text_tab} 
                          onClick={() => {
                            if (dataFlag && !dataFlag.merchant) {
                              console.log(dataFlag);
                            } else {
                              history.push(`./details?rowId=${item.id}`);
                            }
                          }}
                        >
                          {item.fatherSonFlag && <span>主商户</span>}
                          {!item.fatherSonFlag &&
                            item.fatherSonFlag != null && <span>子商户</span>}
                          {item.imageStore === 1 && <span>形象店</span>}
                          {/* {item.repurchaseFocusFlag && (
                            <span>合作商户复购重点</span>
                          )}
                          {item.newPurchasesFocusFlag && (
                            <span>注册商户新购重点</span>
                          )}
                          {item.activationRepurchaseFlag && (
                            <span>待激活复购</span>
                          )}
                          {item.vehicleSourceVisitsFlag && (
                            <span>合作商户车源拜访重点</span>
                          )} */}
                          <span>
                            车位数 {item.parkingNum || 0} · 价位{' '}
                            {item.price || 0}
                          </span>
                          {item.sellWay &&
                            `${item.sellWay}` != '' &&
                            `${item.sellWay}` != '0' && (
                              <span>
                                {item.sellWay === 1 && '零售'}
                                {item.sellWay === 2 && '批发'}
                                {item.sellWay === 3 && '零售&批发'}
                              </span>
                            )}
                          {Number.isFinite(Number(item.merchantType))&&`${item.merchantType}` != '' && (
                            <span>
                              {`${item.merchantType}` === '0' && '合作复购'}
                              {item.merchantType === 1 && '合作新购'}
                              {item.merchantType === 2 && '已认证待合作'}
                            </span>
                          )}
                          {item.degreeIntent &&
                            `${item.degreeIntent}` != '' &&
                            `${item.degreeIntent}` != '0' && (
                              <span>
                                {item.degreeIntent === 1 && '30天以上'}
                                {item.degreeIntent === 2 && '14天-30天'}
                                {item.degreeIntent === 3 && '7天-14天'}
                                {item.degreeIntent === 4 && '3天内'}
                                {item.degreeIntent === 5 && '1天内'}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className={styles.customBody_title_f}>
                        拜访{item.visitCount ? item.visitCount : '0'}次
                      </div>
                    </div>
                    <div style={{ width: '100%', overflow: 'scroll' }}>
                      <div
                        className={styles.customBody_content}
                        onClick={() => {
                          if (dataFlag && !dataFlag.merchant) {
                            console.log(dataFlag);
                          } else {
                            history.push(`./details?rowId=${item.id}`);
                          }
                        }}
                      >
                        <div style={{ width: '300px', marginRight: '3%' }}>
                          <div className={styles.customBody_content_1}>
                            <div className={styles.customBody_content_child}>
                              产品数量
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>{item.monthProjectNum || 0}</div>
                              <div>本月集采</div>
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>{item.monthUsageCount || 0}</div>
                              <div>本月使用</div>
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>{item.notUsedNum || 0}</div>
                              <div>实时库存</div>
                            </div>
                          </div>
                          <div className={styles.customBody_content_1}>
                            <div className={styles.customBody_content_child}>
                              检测渗透率
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>
                                {((item.detectPermeability || 0) * 100).toFixed(
                                  1,
                                )}
                                %
                              </div>
                              <div>本月</div>
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>
                                {(
                                  (item.preDetectPermeability || 0) * 100
                                ).toFixed(1)}
                                %
                              </div>
                              <div>上月</div>
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>
                                {((item.ratioToBeInspected || 0) * 100).toFixed(
                                  1,
                                )}
                                %
                              </div>
                              <div>待检比例</div>
                            </div>
                          </div>
                          <div className={styles.customBody_content_1}>
                            <div className={styles.customBody_content_child}>
                              车源量
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>{item.notDetect || 0}</div>
                              <div>当前待检量</div>
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>{item.alliesDetect || 0}</div>
                              <div>友商检测</div>
                            </div>
                            <div className={styles.customBody_content_child_0}>
                              <div>{item.wzDetect || 0}</div>
                              <div>维真检测</div>
                            </div>
                          </div>
                          <div className={styles.customBody_content_1}>
                            <div
                              className={styles.customBody_content_child}
                              style={{ marginRight: '5px' }}
                            >
                              上次集采
                            </div>
                            <div className={styles.customBody_content_child_2}>
                              <div>
                                {item.lastCollectionTime
                                  ? dateFormat(
                                      item.lastCollectionTime,
                                      'yyyy-MM-dd',
                                    )
                                  : '-'}
                              </div>
                              <div>日期</div>
                            </div>
                            <div className={styles.customBody_content_child_2}>
                              <div>{item.lastCollectionDay || 0}</div>
                              <div>天数</div>
                            </div>
                          </div>
                          <div className={styles.customBody_content_1}>
                            <div
                              className={styles.customBody_content_child}
                              style={{ marginRight: '5px' }}
                            >
                              上次拜访
                            </div>
                            <div className={styles.customBody_content_child_2}>
                              <div>
                                {item.lastSalesCallTime
                                  ? dateFormat(
                                      item.lastSalesCallTime,
                                      'yyyy-MM-dd',
                                    )
                                  : '-'}
                              </div>
                              <div>销售拜访</div>
                            </div>
                            <div className={styles.customBody_content_child_2}>
                              <div>
                                {item.lastCarSourceVisitTime
                                  ? dateFormat(
                                      item.lastCarSourceVisitTime,
                                      'yyyy-MM-dd',
                                    )
                                  : '-'}
                              </div>
                              <div>车源拜访</div>
                            </div>
                          </div>
                        </div>
                        <div style={{ width: '40%' }}>
                          {item.companyLabelRelations &&
                            item.companyLabelRelations.length > 0 &&
                            item.companyLabelRelations.map((pointItem: any) => {
                              let aggregate =
                                (pointItem.superb || 0) +
                                (pointItem.good || 0) +
                                (pointItem.medium || 0) +
                                (pointItem.difference || 0) +
                                (pointItem.notCommented || 0);
                              return (
                                <div>
                                  <div className={styles.pieDiv}>
                                    <div
                                      style={{
                                        background: '#15D141',
                                        width: `${
                                          ((pointItem.superb || 0) /
                                            aggregate) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        background: '#0C7DFD',
                                        width: `${
                                          ((pointItem.good || 0) / aggregate) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        background: '#FF8E20',
                                        width: `${
                                          ((pointItem.medium || 0) /
                                            aggregate) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        background: '#FF2F20',
                                        width: `${
                                          ((pointItem.difference || 0) /
                                            aggregate) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        background: '#D5D5D5',
                                        width: `${
                                          ((pointItem.notCommented || 0) /
                                            aggregate) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className={styles.corlorDiv}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span style={{ color: 'black' }}>
                                      {pointItem.labelName}
                                    </span>
                                    <span style={{ background: '#15D141' }}>
                                      {pointItem.superb || 0}
                                    </span>
                                    <span style={{ background: '#0C7DFD' }}>
                                      {pointItem.good || 0}
                                    </span>
                                    <span style={{ background: '#FF8E20' }}>
                                      {pointItem.medium || 0}
                                    </span>
                                    <span style={{ background: '#FF2F20' }}>
                                      {pointItem.difference || 0}
                                    </span>
                                    <span style={{ background: '#D5D5D5' }}>
                                      {pointItem.notCommented || 0}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className={styles.marketSalesManName}>
                        <div
                          style={{ fontWeight: 'bold', marginRight: '10px' }}
                        >
                          商户绑定
                        </div>
                        <div>
                          <div>
                            {distributionList[item.id] ? (
                              distributionList[item.id].name
                            ) : (
                              <>
                                {item.marketSalesManName
                                  ? item.marketSalesManName
                                  : '-'}
                              </>
                            )}
                          </div>
                          <div className={styles.nameDivColor}>销售</div>
                        </div>
                        <div>
                          <div>
                            {annotatorList[item.id] ? (
                              annotatorList[item.id].name
                            ) : (
                              <>
                                {item.annotator &&
                                item.annotator.allUser &&
                                item.annotator.allUser.name
                                  ? item.annotator.allUser.name
                                  : '-'}
                              </>
                            )}
                          </div>
                          <div className={styles.nameDivColor}>批注人</div>
                        </div>
                        <div>
                          <div>
                            {trainerList[item.id] ? (
                              trainerList[item.id].name
                            ) : (
                              <>
                                {item.teachPeople &&
                                item.teachPeople.allUser &&
                                item.teachPeople.allUser.name
                                  ? item.teachPeople.allUser.name
                                  : '-'}
                              </>
                            )}
                          </div>
                          <div className={styles.nameDivColor}>带教人</div>
                        </div>
                        {inspectorsList &&
                        inspectorsList[item.id] &&
                        inspectorsList[item.id].length > 0
                          ? inspectorsList[item.id].map((child: any) => {
                              return (
                                <div>
                                  <div>{child}</div>
                                  <div className={styles.nameDivColor}>
                                    检测
                                  </div>
                                </div>
                              );
                            })
                          : item.companyInspectorRelations &&
                            item.companyInspectorRelations.map((child: any) => {
                              return (
                                <div>
                                  <div>{child.allUser.name}</div>
                                  <div className={styles.nameDivColor}>
                                    检测
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <div className={styles.customBody_footer}>
                      <Popover.Menu
                        actions={actions.map((action:any) => ({
                          ...action,
                        }))}
                        onAction={async (node: any) => {
                          if (node.value == 1) {
                            let data: any = item;
                            data.companyId = item.id;
                            data.collectKey = 1;
                            history.push('./collect', data);
                          }
                          if (node.value == 2) {
                            if (item.experienceVoucherFlag) {
                              const result = await Modal.confirm({
                                content: '确定发放体验劵？',
                                bodyClassName: styles.bodyClassName,
                              });
                              if (result) {
                                createOrder({
                                  companyId: item.id,
                                  tasteFlag: true,
                                })
                                  .then((res) => {
                                    if (res.retCode === 0) {
                                      Toast.show({
                                        icon: 'success',
                                        content: '申请成功',
                                      });
                                      reload();
                                    } else {
                                      Toast.show({
                                        icon: 'fail',
                                        content: res.retMsg,
                                      });
                                    }
                                  })
                                  .catch((err) => {
                                    Toast.show({
                                      icon: 'fail',
                                      content: '申请失败',
                                    });
                                  });
                              }
                            } else {
                              Toast.show({
                                icon: 'fail',
                                content: '商户不符合',
                              });
                            }
                          }
                          if (node.value == 3) {
                            if (iPhone) {
                              wxShareConfiGabroad(item.id, 1);
                            } else {
                              setisLeaderInDept(true);
                              setNameVisible(true);
                              setCompanyId({
                                companyId: item.id,
                                flag: true,
                                bd: false,
                                type: 1,
                              });
                            }
                          }
                          if (node.value == 4) {
                            if (iPhone) {
                              wxShareConfiGabroad(item.id, 2);
                            } else {
                              setisLeaderInDept(true);
                              setNameVisible(true);
                              setCompanyId({
                                companyId: item.id,
                                flag: true,
                                bd: false,
                                type: 2,
                              });
                            }
                          }
                          if (node.value == 5) {
                            setStockTransfer(item)
                            setStockFlag(true)
                            setCompanyVisible(true)
                          }
                          if (node.value == 6) {
                            inventoryExtension(item)
                          }
                          if (node.value == 7) {
                            usersync({
                              phones:[item.contactMobileNo]
                            })
                            .then((res: any) => {
                              if (res.retCode === 0) {
                                Toast.show({
                                  icon: 'success',
                                  content: '同步成功',
                                });
                                reload();
                              } else {
                                Toast.show({
                                  icon: 'fail',
                                  content: res.retMsg,
                                });
                              }
                            })
                            .catch((err) => {
                              Toast.show({
                                icon: 'fail',
                                content: '同步失败',
                              });
                            });
                          }
                        }}
                        placement="bottom-start"
                        trigger="click"
                      >
                        <div className={styles.footer_button_4}>更多</div>
                      </Popover.Menu>
                      {dataFlag && !dataFlag.merchant ? (
                        <></>
                      ) : (
                        <div className={styles.footer_0}>
                          {/* {localStorage.getItem('isLeaderInDept') === '1' && ( */}
                          <div
                            className={styles.footer_button_0}
                            onClick={() => {
                              if (iPhone) {
                                wxShareConfiGabroad(item.id, 0);
                              } else {
                                setisLeaderInDept(true);
                                setNameVisible(true);
                                setCompanyId({
                                  companyId: item.id,
                                  flag: true,
                                  bd: false,
                                  type: 0,
                                });
                              }
                            }}
                          >
                            绑定销售
                          </div>
                          {/* )} */}
                          {(localStorage.getItem('isLeaderInDept') === '1' ||
                            localStorage.getItem('allotInspectorFlag') ===
                              '1') && (
                            <div
                              className={styles.footer_button_0}
                              onClick={() => {
                                setNameVisible(true);
                                setCompanyId({
                                  companyId: item.id,
                                  flag: false,
                                  bd: false,
                                });
                                setSurveyorFlag(true);
                              }}
                            >
                              绑定检测
                            </div>
                          )}
                          {/* <div
                            className={styles.footer_button}
                            onClick={() => {
                            }}
                          >
                            产品下单
                          </div> */}
                          <div
                            className={styles.footer_button}
                            onClick={() => {
                              // history.push("./programme",item))
                              const data = item;
                              data.companyId = item.id;
                              if (item.remoteRecommendFlag) {
                                data.remoteRecommendFlag = true;
                              }
                              history.push('./programme', data);
                            }}
                          >
                            拜访计划
                          </div>
                          {/* {item.experienceVoucherFlag && (
                            <div
                              className={styles.footer_button}
                              onClick={async () => {
                              }}
                            >
                              体验劵
                            </div>
                          )} */}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </PullToRefresh>
          <InfiniteScroll
            loadMore={async () => {
              await loadPresentMore({
                pageNo: presentParams.pageNo + 1,
                flag: true,
              });
            }}
            hasMore={hasMore}
          />
        </div>
        <div className={styles.shanghu}>
          <Popup position="right" visible={BDVisible}>
            <div className={styles.home}>
              <div
                className={styles.home_0}
                onClick={() => {
                  setBDVisible(false);
                }}
              ></div>
              <div className={styles.home_page}>BD</div>
              <div className={styles.home_x}>×</div>
            </div>
            <div className={styles.tree}>
              <div className={styles.tree_header}>
                {optionsBD.map((item: any) => {
                  return (
                    <div
                      className={styles.tree_content}
                      style={{
                        background: `${
                          treeFlag === item.value ? 'white' : '#F5F7FA'
                        }`,
                      }}
                    >
                      <div
                        className={styles.tree_content_left}
                        onClick={() => {
                          setTreeFlag(item.value);
                        }}
                      >
                        {item.label}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.tree_child}>
                {optionsBD.map((item: any) => {
                  if (treeFlag === item.value) {
                    return (
                      <div className={styles.tree_content_right}>
                        {item.children &&
                          item.children.map((child: any) => {
                            return (
                              <div
                                onClick={() => {
                                  setValue({ BD: child.label });
                                  setTreeChildFlag(child.value);
                                  setBDVisible(false);
                                }}
                                style={{
                                  background: `${
                                    treeChildFlag === child.value
                                      ? '#E7F0FC'
                                      : 'white'
                                  }`,
                                }}
                              >
                                {child.label}
                              </div>
                            );
                          })}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Popup>
          <Popup
            bodyStyle={{ width: '90vw', flexDirection: 'column' }}
            onMaskClick={() => {
              setMoreFilteringVisible(false);
            }}
            position="right"
            visible={moreFilteringVisible}
          >
            <div
              className={styles.home}
              onClick={() => {
                setMoreFilteringVisible(false);
              }}
            >
              <div className={styles.home_0}></div>
              <div className={styles.home_page}>更多筛选</div>
              <div className={styles.home_x}>×</div>
            </div>
            <div className={styles.text}>
              {params.score &&
                params.score.length > 0 &&
                params.score.map((item: any) => {
                  if (item && item.length > 0) {
                    return (
                      <span>
                        {item.label}：
                        {item.map((child: any) => {
                          return (
                            <span>
                              {' '}
                              {child
                                ? '好以上（含好）'
                                : `${child}` === 'false'
                                ? '好以下'
                                : ''}
                            </span>
                          );
                        })}
                        /
                      </span>
                    );
                  }
                })}
              {params.monthProjectNum && params.monthProjectNum != '' && (
                <span>月集采：{params.monthProjectNumText}/</span>
              )}
              {params.parkingNum && params.parkingNum != '' && (
                <span>车位数：{params.parkingNumText}/</span>
              )}
              {params.monthUsageCount && params.monthUsageCount != '' && (
                <span>月使用：{params.monthUsageCountText}/</span>
              )}
              {params.notUsedNum && params.notUsedNum != '' && (
                <span>库存：{params.notUsedNumText}/</span>
              )}
              {params.detectPermeabilityList &&
                params.detectPermeabilityList.items &&
                params.detectPermeabilityList.items.length > 0 && (
                  <span>
                    检测渗透率（本月）：
                    {params.detectPermeabilityList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 !=
                            params.detectPermeabilityList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {params.preDetectPermeabilityList &&
                params.preDetectPermeabilityList.items &&
                params.preDetectPermeabilityList.items.length > 0 && (
                  <span>
                    检测渗透率（上月）：
                    {params.preDetectPermeabilityList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 !=
                            params.preDetectPermeabilityList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {form.getFieldsValue().userIds && (
                <span>岗位：{form.getFieldsValue().userIds}/</span>
              )}
              {form.getFieldsValue().belongMarketName && (
                <span>市场：{form.getFieldsValue().belongMarketName}/</span>
              )}
              {params.exhibitionHallTypeList &&
                params.exhibitionHallTypeList.items &&
                params.exhibitionHallTypeList.items.length > 0 && (
                  <span>
                    展厅类型：
                    {params.exhibitionHallTypeList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 !=
                            params.exhibitionHallTypeList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {params.bindingTypeList &&
                params.bindingTypeList.items &&
                params.bindingTypeList.items.length > 0 && (
                  <span>
                    是否绑定销售或检测：
                    {params.bindingTypeList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 != params.bindingTypeList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {params.merchantTypeList &&
                params.merchantTypeList.items &&
                params.merchantTypeList.items.length > 0 && (
                  <span>
                    商户类型：
                    {params.merchantTypeList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 != params.merchantTypeList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {params.companyTypesList &&
                params.companyTypesList.items &&
                params.companyTypesList.items.length > 0 && (
                  <span>
                    待激活商户：
                    {params.companyTypesList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 != params.companyTypesList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {params.degreeIntentList &&
                params.degreeIntentList.items &&
                params.degreeIntentList.items.length > 0 && (
                  <span>
                    意向度：
                    {params.degreeIntentList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 != params.degreeIntentList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {form.getFieldsValue().lastCollectionTime && (
                <span>
                  上次集采时间：{form.getFieldsValue().lastCollectionTime}/
                </span>
              )}
              {form.getFieldsValue().notLastCollectionTime && (
                <span>未集采时间：{form.getFieldsValue().notLastCollectionTime}/</span>
              )}
              {form.getFieldsValue().lastSalesCallTime && (
                <span>上次拜访时间（销售）：{form.getFieldsValue().lastSalesCallTime}/</span>
              )}
              {form.getFieldsValue().lastCarSourceVisitTime && (
                <span>上次拜访时间（车源）：{form.getFieldsValue().lastCarSourceVisitTime}/</span>
              )}
              {form.getFieldsValue().notLastSalesCallTime && (
                <span>未拜访时间（销售）：{form.getFieldsValue().notLastSalesCallTime}/</span>
              )}
              {form.getFieldsValue().notLastCarSourceVisitTime && (
                <span>未拜访时间（车源）：{form.getFieldsValue().notLastCarSourceVisitTime}/</span>
              )}
              {`${params.visitCountStart}` != '' && (
                <span>
                  {(params.visitCountStart || params.visitCountEnd) && (
                    <span>
                      拜访次数：{params.visitCountStart}~
                      {params.visitCountEnd > 1000
                        ? '不限'
                        : params.visitCountEnd}
                      /
                    </span>
                  )}
                </span>
              )}
              {params.imageShopTypeList &&
                params.imageShopTypeList.items &&
                params.imageShopTypeList.items.length > 0 && (
                  <span>
                    形象店：
                    {params.imageShopTypeList.items.map(
                      (item: any, index: number) => {
                        return (
                          <span>
                            {item.label}
                            {index + 1 != params.imageShopTypeList.items.length
                              ? '、'
                              : ''}
                          </span>
                        );
                      },
                    )}
                    /
                  </span>
                )}
              {params.notDetect && params.notDetect != '' && (
                <span>待检量：{params.notDetectText}/</span>
              )}
              {params.ratioToBeInspected && params.ratioToBeInspected != '' && (
                <span>待检比例：{params.ratioToBeInspectedText}/</span>
              )}
            </div>
            <div className={styles.popup_body}>
              {divValuePoint()}
              {/* {divParkingNum()} */}
              {divPropertyOrCashHeldInReserve(1)}
              {divDetectingPermeability()}
              {divMoreElse()}
            </div>
            <div style={{ padding: '5px 15px' }}>{divValue(4)}</div>
          </Popup>
          <Popup
            visible={visibleTime}
            showCloseButton
            onClose={() => {
              setVisibleTime(false);
            }}
            position="bottom"
            bodyStyle={{ width: '100vw', height: '60vh', display: 'block' }}
          >
            {/* {mockContent} */}
            <div className={styles.mores}>选择上次集采时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setTimeMore(val);
              }}
              value={timeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (timeMore != null) {
                    let startTime = dateFormat(timeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(timeMore[1], 'Y-MM-dd');
                    setVisibleTime(false);
                    form.setFieldsValue({
                      lastCollectionTime: `${startTime} ~ ${endTime}`,
                    });
                    setParams({
                      ...params,
                      lastCollectionTime: `${startTime}_${endTime}`,
                      flag: false,
                    });
                  } else {
                    setVisibleTime(false);
                    form.setFieldsValue({
                      lastCollectionTime: '',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                      flag: false,
                    });
                  }
                }}
              >
                确定
              </Button>
            </div>
          </Popup>
          <Popup
            visible={visitVisibleTime}
            showCloseButton
            onClose={() => {
              setVisitVisibleTime(false);
            }}
            position="bottom"
            bodyStyle={{ width: '100vw', height: '60vh', display: 'block' }}
          >
            {/* {mockContent} */}
            <div className={styles.mores}>选择未集采时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setVisitTimeMore(val);
              }}
              value={visitTimeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setVisitTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (visitTimeMore != null) {
                    let startTime = dateFormat(visitTimeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(visitTimeMore[1], 'Y-MM-dd');
                    setVisitVisibleTime(false);
                    form.setFieldsValue({
                      notLastCollectionTime: `${startTime} ~ ${endTime}`,
                    });
                    setParams({
                      ...params,
                      notLastCollectionTime: `${startTime}_${endTime}`,
                      flag: false,
                    });
                  } else {
                    setVisitVisibleTime(false);
                    form.setFieldsValue({
                      notLastCollectionTime: '',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                      flag: false,
                    });
                  }
                }}
              >
                确定
              </Button>
            </div>
          </Popup>
          <Popup
            visible={lastSalesCallTimeVisibleTime}
            showCloseButton
            onClose={() => {
              setlastSalesCallTimeVisibleTime(false);
            }}
            position="bottom"
            bodyStyle={{ width: '100vw', height: '60vh', display: 'block' }}
          >
            {/* {mockContent} */}
            <div className={styles.mores}>选择上次拜访（销售）时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setlastSalesCallTimeMore(val);
              }}
              value={lastSalesCallTimeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setlastSalesCallTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (lastSalesCallTimeMore != null) {
                    let startTime = dateFormat(lastSalesCallTimeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(lastSalesCallTimeMore[1], 'Y-MM-dd');
                    setlastSalesCallTimeVisibleTime(false);
                    form.setFieldsValue({
                      lastSalesCallTime: `${startTime} ~ ${endTime}`,
                    });
                    setParams({
                      ...params,
                      lastSalesCallTime: `${startTime}_${endTime}`,
                      flag: false,
                    });
                  } else {
                    setlastSalesCallTimeVisibleTime(false);
                    form.setFieldsValue({
                      lastSalesCallTime: '',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                      flag: false,
                    });
                  }
                }}
              >
                确定
              </Button>
            </div>
          </Popup>
          <Popup
            visible={lastCarSourceVisitTime}
            showCloseButton
            onClose={() => {
              setlastCarSourceVisitTime(false);
            }}
            position="bottom"
            bodyStyle={{ width: '100vw', height: '60vh', display: 'block' }}
          >
            {/* {mockContent} */}
            <div className={styles.mores}>选择上次拜访（车源）时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setlastCarSourceVisitTimeMore(val);
              }}
              value={lastCarSourceVisitTimeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setlastCarSourceVisitTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (lastCarSourceVisitTimeMore != null) {
                    let startTime = dateFormat(lastCarSourceVisitTimeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(lastCarSourceVisitTimeMore[1], 'Y-MM-dd');
                    setlastCarSourceVisitTime(false);
                    form.setFieldsValue({
                      lastCarSourceVisitTime: `${startTime} ~ ${endTime}`,
                    });
                    setParams({
                      ...params,
                      lastCarSourceVisitTime: `${startTime}_${endTime}`,
                      flag: false,
                    });
                  } else {
                    setlastCarSourceVisitTime(false);
                    form.setFieldsValue({
                      lastCarSourceVisitTime: '',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                      flag: false,
                    });
                  }
                }}
              >
                确定
              </Button>
            </div>
          </Popup>
          <Popup
            visible={notLastSalesCallTimeVisible}
            showCloseButton
            onClose={() => {
              setnotLastSalesCallTimeVisible(false);
            }}
            position="bottom"
            bodyStyle={{ width: '100vw', height: '60vh', display: 'block' }}
          >
            {/* {mockContent} */}
            <div className={styles.mores}>选择未拜访（销售）时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setnotLastSalesCallTimeMore(val);
              }}
              value={notLastSalesCallTimeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setnotLastSalesCallTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (notLastSalesCallTimeMore != null) {
                    let startTime = dateFormat(notLastSalesCallTimeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(notLastSalesCallTimeMore[1], 'Y-MM-dd');
                    setnotLastSalesCallTimeVisible(false);
                    form.setFieldsValue({
                      notLastSalesCallTime: `${startTime} ~ ${endTime}`,
                    });
                    setParams({
                      ...params,
                      notLastSalesCallTime: `${startTime}_${endTime}`,
                      flag: false,
                    });
                  } else {
                    setnotLastSalesCallTimeVisible(false);
                    form.setFieldsValue({
                      notLastSalesCallTime: '',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                      flag: false,
                    });
                  }
                }}
              >
                确定
              </Button>
            </div>
          </Popup>
          <Popup
            visible={notLastCarSourceVisitTimeVisible}
            showCloseButton
            onClose={() => {
              setnotLastCarSourceVisitTimeVisible(false);
            }}
            position="bottom"
            bodyStyle={{ width: '100vw', height: '60vh', display: 'block' }}
          >
            {/* {mockContent} */}
            <div className={styles.mores}>选择未拜访（车源）时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setnotLastCarSourceVisitTimeMore(val);
              }}
              value={notLastCarSourceVisitTimeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setnotLastCarSourceVisitTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (notLastCarSourceVisitTimeMore != null) {
                    let startTime = dateFormat(notLastCarSourceVisitTimeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(notLastCarSourceVisitTimeMore[1], 'Y-MM-dd');
                    setnotLastCarSourceVisitTimeVisible(false);
                    form.setFieldsValue({
                      notLastCarSourceVisitTime: `${startTime} ~ ${endTime}`,
                    });
                    setParams({
                      ...params,
                      notLastCarSourceVisitTime: `${startTime}_${endTime}`,
                      flag: false,
                    });
                  } else {
                    setnotLastCarSourceVisitTimeVisible(false);
                    form.setFieldsValue({
                      notLastCarSourceVisitTime: '',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                      flag: false,
                    });
                  }
                }}
              >
                确定
              </Button>
            </div>
          </Popup>
          <Popup
            visible={visible}
            showCloseButton
            onClose={() => {
              setVisible(false);
            }}
            position="bottom"
            bodyStyle={{ width: '100vw', height: '60vh', display: 'block' }}
          >
            {/* {mockContent} */}
            <div className={styles.mores}>选择未拜访（车源）时间段</div>
            <Calendar
              // defaultValue={defaultRange}
              className={styles.calendar_custom}
              selectionMode="range"
              onChange={(val) => {
                console.log(val);
                setnotLastCarSourceVisitTimeMore(val);
              }}
              value={notLastCarSourceVisitTimeMore}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setnotLastCarSourceVisitTimeMore(null);
                }}
              >
                重置
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  if (notLastCarSourceVisitTimeMore != null) {
                    let startTime = dateFormat(notLastCarSourceVisitTimeMore[0], 'Y-MM-dd');
                    let endTime = dateFormat(notLastCarSourceVisitTimeMore[1], 'Y-MM-dd');
                    setnotLastCarSourceVisitTimeVisible(false);
                    form.setFieldsValue({
                      notLastCarSourceVisitTime: `${startTime} ~ ${endTime}`,
                    });
                    setParams({
                      ...params,
                      notLastCarSourceVisitTime: `${startTime}_${endTime}`,
                      flag: false,
                    });
                  } else {
                    setnotLastCarSourceVisitTimeVisible(false);
                    form.setFieldsValue({
                      notLastCarSourceVisitTime: '',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                      flag: false,
                    });
                  }
                }}
              >
                确定
              </Button>
            </div>
          </Popup>
          <NameList
            value={userIdList}
            bd={!companyId.flag}
            nameVisible={nameVisible}
            onSubmit={handelUserName}
            onCancel={handleUserNameCancel}
          />
          <Merchants
            visit={true}
            cityFlag={false}
            checkFlag={false}
            ButtonFlag={ButtonFlag}
            nameVisible={companyVisible}
            onSubmit={handelCompanyName}
            onCancel={handleCompanyNameCancel}
          />
          <Market
            nameVisible={marketVisible}
            onSubmit={handelUserMarket}
            onCancel={handleUserMarketCancel}
            value={false}
            list={[]}
          />
          <MarketMultiple
            nameVisible={selectMarketVisible}
            onSubmit={handelSelectMarket}
            onCancel={handleUserMarketCancel}
            value={true}
            list={params.belongMarketIds || []}
          />
        </div>
      </div>
    </div>
  );
}
