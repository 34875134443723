import SpinLoading from "antd-mobile/es/components/spin-loading";
import Toast from "antd-mobile/es/components/toast";
import { FC, useEffect, useRef, useState,useCallback } from "react";
import { history,useLocation, useParams } from "umi";
import { Badge, Button, Card, Checkbox, CheckList, DotLoading, Form, ImageViewer, InfiniteScroll, Input, List, Loading, Modal, Popover, Popup, PullToRefresh, Radio, SearchBar, Stepper, TabBar, Tabs, Tag, TextArea, TreeSelect } from "antd-mobile";
import styles from './index.less';
import 集采订单 from './img/集采订单.png';
import 集采订单2 from './img/集采订单2.png';
import 检测产品1 from './img/检测产品1.png';
import 检测产品2 from './img/检测产品2.png';
import 套餐配置 from './img/套餐配置.png';
import 套餐配置2 from './img/套餐配置2.png';
import initMerchant from './img/initMerchant.png';
import 微信 from './img/微信.png';
import 支付宝 from './img/支付宝.png';
import 返回 from './img/返回.png';
import error from './img/error_2.png';
// @ts-ignore
import test from './img/test.jpg';
// @ts-ignore
import logo from './img/logo.jpg';
import Merchant from "../comments/Merchant";
import MerchantRewind from "../comments/Merchant";
import { getMCarDealerVisitVo } from "../details/service";
import { CameraOutline, DeleteOutline, DownFill, ReceivePaymentOutline } from "antd-mobile-icons";
import { cancelOrder, collectPrice, confirmCouponAgain, confirmRRewindCouponAgain, couponExchangeCancelOrder, couponExchangeFinancialConfirm, couponExchangeOrderList, couponExchangeUploadPaymentProof, createOrder, deleteCouponByIds, financialConfirm, financialRefuse, getCompanyCoupon, getCompanyCouponList, getCouponRecord, getList, getWxAccessToken, hasPurchaseRecord, orderList, RewindCreateOrder, saveCompanyBelongMarket, uploadPaymentProof } from "./service";
import datas from '../utils/省市区线数据.json';
import CollectCkecked from "../comments/CollectCkecked";
import RewindCkecked from "../comments/CollectCkecked";
import Navbar from "../utils/Navbar";
// @ts-ignore
import QRCode from 'qrcode.react';
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";
import { CountDown } from "@nutui/nutui-react";
import { dateFormat } from "../utils/util";
import { sleep } from "antd-mobile/es/utils/sleep";
import Market from "../comments/Market";
import { style } from "@mui/system";
import dayjs from "dayjs";

export const isIOS = () => {
  var ua = navigator.userAgent.toLocaleLowerCase();
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  return isIOS;
};
const Collect: FC<any> = (props) => {
  const [form] = Form.useForm();
  const [formRewind] = Form.useForm();
  const [formGift] = Form.useForm();
  const [formDelete] = Form.useForm();
  const [recordForm] = Form.useForm();
  const [formBudget] = Form.useForm();
  const [formFinancialRefuse] = Form.useForm();
  // @ts-ignore
  const [activeKey, setActiveKey] = useState<number>(parseInt(localStorage.getItem("activeKey") || 0))
  const [nameVisible, setNameVisible] = useState<boolean>(false)
  const [rewindVisible, setRewindVisible] = useState<boolean>(false)
  const [nameProposeVisible, setNameProposeVisible] = useState<boolean>(false)
  const [featuredFlag, setFeaturedFlag] = useState<boolean>(false)
  const [collectVisible, setCollectVisible] = useState<boolean>(false)
  const [collectRewindVisible, setCollectRewindVisible] = useState<boolean>(false)
  const [formRewindVisible, setFormRewindVisible] = useState<boolean>(false)
  const [visibleDetete, setVisibleDetete] = useState<boolean>(false)
  const [QRCodeVisible, setQRCodeVisible] = useState<boolean>(false)
  const [recordVisible, setRecordVisible] = useState<boolean>(false)
  const [batchBuyNoDetail, setBatchBuyNoDetail] = useState<any>([])
  const [queryCoupon, setQueryCoupon] = useState<any>([])
  const [deatilAllPrice, setDeatilAllPrice] = useState<any>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [options, setoptions] = useState<any>([]);
  const [inquiryBusinessPrice, setInquiryBusinessPrice] = useState<any>([]);
  const [rewindList, setRewindList] = useState<any>([]);
  const [rewindCheckList, setRewindCheckList] = useState<any>([]);
  const [rewindCompany, setRewindCompany] = useState<any>({});
  const [companyName, setCompanyName] = useState<string>("选择下单商户")
  const [belongMarketName, setBelongMarketName] = useState<string>("--")
  const [doorHeadPhoto, setDoorHeadPhoto] = useState<string>("")
  const [companyId, setCompanyId] = useState<any>(0)
  const [contactCityCode, setContactCityCode] = useState<any>(0)
  const [spreadMax, setSpreadMax] = useState<number>(1000)
  const [minNum, setMinNum] = useState<any>(0)
  const [contactCityName, setContactCityName] = useState<string>("")
  const [contactMobileNo, setContactMobileNo] = useState<string>("--")
  const [batchBuyNo, setBatchBuyNo] = useState<string>("")
  const [collectList, setCollectList] = useState<any>([])
  const [giftList, setGiftList] = useState<any>([])
  const [cityCode, setCityCode] = useState<any>([]);
  const [priceNum, setPriceNum] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);
  const [marketReceipts, setMarketReceipts] = useState<boolean>(false);
  const [pageNo, setpageNo] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true)
  const [QRFlag, setQRFlag] = useState(false)
  const [data, setData] = useState<any>([])
  const [list, setList] = useState<any>([])
  const [listValue, setListValue] = useState<any>([])
  const [listLength, setListLength] = useState<any>([])
  const [checkSizeList, setCheckSizeList] = useState<any>([])
  const [imgProofList, setImgProofList] = useState<any>({})
  const [giftConsultFlag, setGiftConsultFlag] = useState<any>({})
  const [pooledOrders, setPooledOrders] = useState<any[]>([]);  
  const [repositoryImg, setRepositoryImg] = useState<any[]>([]);  
  const [collectPriceValue, setCollectPriceValue] = useState<any>([]);  
  const [orderInformation, setOrderInformation] = useState<any>([]);  
  const [Images, setImages] = useState<string>("");  
  const [imageVisible, setImageVisible] = useState<boolean>(false);  
  const [credentialsVisible, setcredentialsVisible] = useState<boolean>(false);  
  const [formFlag, setFormFlag] = useState<boolean>(false);  
  const [flag, setFlag] = useState<boolean>(false);  
  const [imageShop, setImageShop] = useState<boolean>(true);  
  const [purchaseType, setPurchaseType] = useState<number>(0);  
  const [giftConsultFlagIndex, setGiftConsultFlagIndex] = useState<number>(0);  
  const [checkKeyValue, setcheckKeyValue] = useState<string>("-1");  
  const [purchaseNum, setPurchaseNum] = useState<any>([0,-1]);  
  const [marketVisible, setMarketVisible] = useState<boolean>(false);
  const position = localStorage.getItem("position")
  const batchBuyAuthority = localStorage.getItem("batchBuyAuthority")
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    payStatus: -1,
  });
  const [checkState, setCheckState] = useState({ check: false, index: 0 });
  const location = useLocation();
  const dataFlag:any = location.state;
  const query: { key: string } = useParams();
  let num = 0
  const tabs = [
    {
      key: 0,
      title: '检测产品',
      img: 检测产品2,
      icon: 检测产品1,
    },
    {
      key: 1,
      title: '套餐配置',
      img: 套餐配置2,
      icon: 套餐配置,
    },
    {
      key: 2,
      title: '集采订单',
      img: 集采订单2,
      icon: 集采订单,
    },
    {
      key: 3,
      title: '集采换卷',
      img: 集采订单2,
      icon: 集采订单,
    },
    // {
    //   key: 4,
    //   title: '商户库存',
    //   img: 检测产品2,
    //   icon: 检测产品1,
    // },
  ]  
  const ref = useRef<HTMLDivElement>(null)
 
  const onButtonClick = useCallback((batchBuyNo:any) => {
    if (ref.current === null) {
      return
    }
    toPng(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = batchBuyNo
        link.href = dataUrl
        link.target = '_self'
        link.click()
      })
      .catch((err) => {
      })
  }, [ref])
  
  const onSearch = async (value: string) => {
    let params:any = {}
    params.couponGroupName = value
    initValue(params)
  };
  // const onButtonClickIOS = async () => {
  //   if (ref.current === null) {
  //     return
  //   }
  //   let ele = ref.current
  //   html2canvas(ele).then((canvas) => {
  //     var canvimg = document.createElement('img')
  //     canvimg.src = canvas.toDataURL('image/png')
  //     canvimg.style.width = '100%'
  //     canvimg.style.height = '100%'
  //     ref.current.appendChild(canvimg)
  //     Toast.show({
  //       icon: "success",
  //       content: "长按二维码保存"
  //     })
  //     setQRFlag(true)
  //   })
  // };
  const onFinancialConfirm = (batchBuyNo:any)=>{
    if(checkKeyValue === "8"){
      couponExchangeFinancialConfirm({exchangeNo:batchBuyNo}).then((res)=>{   
        if(res.retCode === 0){
          Toast.show({
            icon: "success",
            content: "审核通过"
          })
          reset2()
        }else{
          Toast.show({
            icon: "fail",
            content:res.retMsg
          })
        }
      })
      .catch(()=>{
        Toast.show({
          icon: "fail",
          content:"审核失败"
        })
      })

    }else{
      financialConfirm({batchBuyNo:batchBuyNo}).then((res)=>{   
        if(res.retCode === 0){
          Toast.show({
            icon: "success",
            content: "审核通过"
          })
          setParams({
            ...params,
            pageNo: -1,
            pageSize: 20,
            payStatus: params.payStatus,
          });
        }else{
          Toast.show({
            icon: "fail",
            content:res.retMsg
          })
        }
      })
      .catch(()=>{
        Toast.show({
          icon: "fail",
          content:"审核失败"
        })
      })

    }
  }
  const onFinancialRefuse = (batchBuyNo:any)=>{
    const remark = formFinancialRefuse.getFieldsValue();
    financialRefuse({batchBuyNo:batchBuyNo,remark:remark.forms}).then((res)=>{   
      if(res.retCode === 0){
        Toast.show({
          icon: "success",
          content: "已驳回"
        })
        setParams({
          ...params,
          pageNo: -1,
          pageSize: 20,
          payStatus: params.payStatus,
        });
      }else{
        Toast.show({
          icon: "fail",
          content:res.retMsg
        })
      }
    })
    .catch(()=>{
      Toast.show({
        icon: "fail",
        content:"驳回失败"
      })
    })
  }
  const onSearchOrder = async (value: string) => {
    setParams({
      ...params,
      pageNo: -1,
      pageSize: 20,
      // payStatus: params.payStatus,
      searchInfo: value
    });
  };
  const wxShareRewindConfig = async (batchBuyNo:any,imgProof:any) => {
    let str = ""
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if(imgProof && imgProof.length){
      imgProof.map((img:any,index:number)=>{
        str = str + img + `${index +1 != imgProof.length ? "," : "" }`
      })
    }
    if (msg.retCode === 0) {
      window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
      });
      window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function () {
          // 回调
        },
        fail: function (res: { errMsg: string | string[]; }) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.chooseImage({
        count: checkKeyValue === "8" ? 1 : 9, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album",'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'batch', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: async function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds;
          if (localIds && checkKeyValue === "8") {
            if(checkKeyValue === "8"){
              await window.wx.uploadImage({
                localId: localIds[0].toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: function (res: { serverId: any }) {
                  var serverId = res.serverId; // 返回图片的服务器端ID
                  //console.log(serverId);
                  let paramData:any = {
                    imgPath:str,
                  }
                  paramData.exchangeNo = batchBuyNo
                  paramData.mediaId = serverId
                  setTimeout(() => {
                    couponExchangeUploadPaymentProof(paramData).then((msg:any)=>{
                      if(msg.retCode === 0){
                        Toast.show({
                          icon: 'success',
                          content: '上传成功',
                        });
                        reset2()
                      }else{
                        Toast.show({
                          icon: 'fail',
                          content: msg.retMsg,
                        });
                      }
                    })
                    .catch(()=>{
                      Toast.show({
                        icon: 'fail',
                        content: '上传失败',
                      });
                    })
                  }, 1500);
                  // mediaIds.push(serverId)
                },
                errMsg: function () {
                  Toast.show({
                    icon: 'fail',
                    content: '上传失败',
                  });
                  return;
                },
              });
              if (isIOS() === true) {
                await window.wx.getLocalImgData({
                  localId: localIds[0], // 图片的localID
                  success: function (res: { localData: any }) {
                    var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                    //console.log(localData);
                    repositoryImg[batchBuyNo][0] = localData;
                    setRepositoryImg([...repositoryImg]);
                  },
                });
              } else {
                repositoryImg[batchBuyNo][0] = localIds[0];
                setRepositoryImg([...repositoryImg]);
              }
            }else{
              let list:any = []
              localIds.map(async (item: any, index: number) => {
                await window.wx.uploadImage({
                  localId: item.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                  isShowProgressTips: 1, // 默认为1，显示进度提示
                  success: function (res: { serverId: any }) {
                    var serverId = res.serverId; // 返回图片的服务器端ID
                    //console.log(serverId);
                    list.push(serverId);

                    // mediaIds.push(serverId)
                  },
                  errMsg: function () {
                    Toast.show({
                      icon: 'fail',
                      content: '上传失败',
                    });
                    return;
                  },
                });
                if (isIOS() === true) {
                  await window.wx.getLocalImgData({
                    localId: item, // 图片的localID
                    success: function (res: { localData: any }) {
                      var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                      //console.log(localData);
                      repositoryImg[batchBuyNo][index] = localData;
                      setRepositoryImg([...repositoryImg]);
                    },
                  });
                } else {
                  repositoryImg[batchBuyNo][index] = item;
                  setRepositoryImg([...repositoryImg]);
                }
              });
              let paramData:any = {
                imgPath:str,
              }
              paramData.batchBuyNo = batchBuyNo
              paramData.mediaIds = list
              setTimeout(() => {
                uploadPaymentProof(paramData).then((msg:any)=>{
                  if(msg.retCode === 0){
                    Toast.show({
                      icon: 'success',
                      content: '上传成功',
                    });
                    setParams({
                      pageNo: -1,
                      pageSize: 20,
                    })
                  }else{
                    Toast.show({
                      icon: 'fail',
                      content: msg.retMsg,
                    });
                  }
                })
                .catch(()=>{
                  Toast.show({
                    icon: 'fail',
                    content: '上传失败',
                  });
                })
              }, 1500);
            }
          } 
        },
      });
    }
    setImageVisible(false)
  };
  const wxShareConfig = async (batchBuyNo:any,imgProof:any) => {
    let str = ""
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if(imgProof && imgProof.length){
      imgProof.map((img:any,index:number)=>{
        str = str + img + `${index +1 != imgProof.length ? "," : "" }`
      })
    }
    if (msg.retCode === 0) {
      window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
      });
      window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function () {
          // 回调
        },
        fail: function (res: { errMsg: string | string[]; }) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.chooseImage({
        count: 9, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album",'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'batch', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds;
          if (localIds) {
            //console.log(localIds);
            
            let list:any = []
            localIds.map(async (item: any, index: number) => {
              await window.wx.uploadImage({
                localId: item.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: function (res: { serverId: any }) {
                  var serverId = res.serverId; // 返回图片的服务器端ID
                  //console.log(serverId);
                  list.push(serverId);

                  // mediaIds.push(serverId)
                },
                errMsg: function () {
                  Toast.show({
                    icon: 'fail',
                    content: '上传失败',
                  });
                  return;
                },
              });
              if (isIOS() === true) {
                await window.wx.getLocalImgData({
                  localId: item, // 图片的localID
                  success: function (res: { localData: any }) {
                    var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                    //console.log(localData);
                    repositoryImg[batchBuyNo][index] = localData;
                    setRepositoryImg([...repositoryImg]);
                  },
                });
              } else {
                repositoryImg[batchBuyNo][index] = item;
                setRepositoryImg([...repositoryImg]);
              }
            });
            setTimeout(() => {
              uploadPaymentProof({
                mediaIds:list,
                batchBuyNo:batchBuyNo,
                imgPath:str
              }).then((msg:any)=>{
                if(msg.retCode === 0){
                  Toast.show({
                    icon: 'success',
                    content: '上传成功',
                  });
                  setParams({
                    pageNo: -1,
                    pageSize: 20,
                  })
                }else{
                  Toast.show({
                    icon: 'fail',
                    content: msg.retMsg,
                  });
                }
              })
              .catch(()=>{
                Toast.show({
                  icon: 'fail',
                  content: '上传失败',
                });
              })
            }, 1500);
          } 
        },
      });
    }
  };
  const handelUserName = async (value: any) => {
    if(value && value.length > 0){
      let arr = value[0].split('===');
      
      setCompanyName(arr[0])
      setCompanyId(arr[1]);
      const msg = await getMCarDealerVisitVo({
        companyId: arr[1],
      });
      if(msg.retCode === 0){
        if(msg.data && msg.data.companyInfoData && !msg.data.companyInfoData.memberId ){
          Toast.show({
            icon: "fail",
            content: "商户未在车事通注册，请前往注册"
          })
          setCompanyId(0)
          return
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.belongMarketName ){
          setBelongMarketName(msg.data.mCompanyInfo.belongMarketName)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.doorHeadPhoto ){
          setDoorHeadPhoto(msg.data.mCompanyInfo.doorHeadPhoto)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactCityCode ){
          setContactCityCode(msg.data.mCompanyInfo.contactCityCode)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactCityName ){
          setContactCityName(msg.data.mCompanyInfo.contactCityName)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactMobileNo ){
          setContactMobileNo(msg.data.mCompanyInfo.contactMobileNo)
          hasPurchaseRecord({phone:msg.data.mCompanyInfo.contactMobileNo}).then((res)=>{
            if(res.retCode === 0){
              if(res.data){
                setPurchaseType(2)
              }else{
                setPurchaseType(1)
              }
            }
          })
        }
      }
      onValuesChange(-1)
      setNameVisible(false)
    }
  };
  const handelRewind = async (value: any) => {
    if(value && value.length > 0){
      let arr = value[0].split('===');
      const msg = await getMCarDealerVisitVo({
        companyId: arr[1],
      });
      if(msg.retCode === 0){
        if(msg.data && msg.data.companyInfoData && !msg.data.companyInfoData.memberId ){
          Toast.show({
            icon: "fail",
            content: "商户未在车事通注册，请前往注册"
          })
          return
        }
        const rewind:any = {};
        rewind.belongMarketName = msg.data.mCompanyInfo.belongMarketName;
        rewind.doorHeadPhoto = msg.data.mCompanyInfo.doorHeadPhoto;
        rewind.contactCityName = msg.data.mCompanyInfo.contactCityName;
        rewind.contactMobileNo = msg.data.mCompanyInfo.contactMobileNo;
        rewind.contactCityCode = msg.data.mCompanyInfo.contactCityCode;
        rewind.companyId = arr[1];
        rewind.companyName = arr[0];
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactMobileNo ){
          getCompanyCouponList({phone:msg.data.mCompanyInfo.contactMobileNo}).then((res)=>{
            if(res.retCode === 0){
              if(res.data && res.data.length > 0){
                let rewindNowList:any = [];
                res.data.map((item:any,i:number)=>{
                  let array = item;
                  let childRewind:any = [];
                  for (let index = 0; index < array.size; index++) {
                    // const element = array[index];
                    childRewind.push(array);
                  }
                  rewindNowList.push({
                    name: item.couponName,
                    list:childRewind
                  })
                })
                //console.log(rewindNowList);
                
                setRewindList(rewindNowList)
              }
            }
          })
        }
        setRewindCompany(rewind);
        setCompanyId(arr[1]);
      }
      setRewindVisible(false)
    }
  };
  useEffect(()=>{
    setRewindCheckList([])
    setRewindList([])
  },[companyId])
  const handelProposeName = async (value: any) => {
    if(value && value.length > 0){
      let arr = value[0].split('===');
      formBudget.setFieldValue("recommendCompanyName",arr[0])
      formBudget.setFieldValue("recommendCompanyId",arr[1])
      const msg = await getMCarDealerVisitVo({
        companyId: arr[1],
      });
      if(msg.retCode === 0){
        if(msg.data && msg.data.companyInfoData && !msg.data.companyInfoData.memberId ){
          Toast.show({
            icon: "fail",
            content: "商户未在车事通注册，请前往注册"
          })
          return
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactCityCode && msg.data.mCompanyInfo.contactCityCode === contactCityCode){
          setPurchaseNum([1,1])
          formBudget.setFieldValue("recommendSize",1)
        }else{
          setPurchaseNum([1,2])
          formBudget.setFieldValue("recommendSize",1)
        }
      }
      setGiftList([])
      onValuesChange(-1)
      setNameProposeVisible(false)
    }
  };
  const companyValue = async (value: any) => {
    if(value && value.companyId){
      const msg = await getMCarDealerVisitVo({
        companyId: value.companyId,
      });
      if(msg.retCode === 0){
        if(msg.data && msg.data.companyInfoData && !msg.data.companyInfoData.memberId ){
          Toast.show({
            icon: "fail",
            content: "商户未在车事通注册，请前往注册"
          })
          return
        }
        setCompanyName(value.companyName)
        setCompanyId(value.companyId);
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.belongMarketName ){
          setBelongMarketName(msg.data.mCompanyInfo.belongMarketName)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.doorHeadPhoto ){
          setDoorHeadPhoto(msg.data.mCompanyInfo.doorHeadPhoto)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactCityCode ){
          setContactCityCode(msg.data.mCompanyInfo.contactCityCode)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactCityName ){
          setContactCityName(msg.data.mCompanyInfo.contactCityName)
        }
        if(msg.data && msg.data.mCompanyInfo && msg.data.mCompanyInfo.contactMobileNo ){
          setContactMobileNo(msg.data.mCompanyInfo.contactMobileNo)
        }
      }
      setGiftList([])
      onValuesChange(-1)
      setNameVisible(false)
    }
  };
  const handelGroupName = async (value: any,data:any) => {
    delete giftList[groupIndex]
    if(value && value.length > 0){
      let arr = value[0].split('++');
      form.setFieldValue(`giftCouponGroupId${groupIndex}`,arr[0])
      form.setFieldValue(`couponGroupName${groupIndex}`,arr[1])
      const values = form.getFieldsValue()
      // form.setFieldValue(`price${groupIndex}`,arr[2])
      data.map((item:any)=>{
        if(`${arr[0]}` === `${item.couponGroupId}`){
          // let list:any = {}
          // list[item.couponGroupId] = item;
          inquiryBusinessPrice[item.couponGroupId] = item;
          setInquiryBusinessPrice({...inquiryBusinessPrice})
          if(item.singlePrice ){
            if(values[`num${groupIndex}`] === 1){
              form.setFieldValue(`price${groupIndex}`,item.singlePrice)
            }else{
              form.setFieldValue(`price${groupIndex}`,item.price)
            }
            // form.setFieldValue(`price${groupIndex}`,item.singlePrice)
            form.setFieldValue(`numNow${groupIndex}`,1)
          }else{
            form.setFieldValue(`price${groupIndex}`,item.price)
            form.setFieldValue(`numNow${groupIndex}`,2)
          }
        }
      })
      setCollectVisible(false)
      onValuesChange(-1)
    }
  };
  const handelRewindName = async (value: any,data:any) => {
    console.log(data,value);
    
    if(value && value.length > 0){
      let arr = value[0].split('++');
      formRewind.setFieldValue(`couponGroupId`,arr[0])
      formRewind.setFieldValue(`couponGroupName`,arr[1])
      formRewind.setFieldValue(`size`,1)
      let priceRewind = 0;
      if(rewindCompany && rewindCompany.exchangeBefore){
        rewindCompany.exchangeBefore.map((item:any)=>{
          if(item.price && item.size){
            priceRewind = priceRewind + parseInt(item.price) * item.size
          }
        })
      }
      if(arr[1] == "事故排查检"){
        setSpreadMax(1)
      }else{
        setSpreadMax(100000)
      }
      data.map((item:any)=>{
        if(`${arr[0]}` === `${item.couponGroupId}`){
          if(item.goodsOrigin){
            item.goodsOrigin.price = item.goodsOrigin.batchBuyPrice
            if(item.goodsOrigin.batchBuyPrice > priceRewind){
              console.log(rewindCompany);
              item.goodsOrigin.spread = item.goodsOrigin.batchBuyPrice - priceRewind
            }else{
              item.goodsOrigin.spread = 0
            }
            formRewind.setFieldsValue(item.goodsOrigin)
          }else{
            if(item.price > priceRewind){
              item.spread = item.price - priceRewind
            }else{
              item.spread = 0
            }
            formRewind.setFieldsValue(item)
          }
        }
      })

      setCollectRewindVisible(false)
      setFormRewindVisible(true)
      // onRewindValuesChange()
    }
  };
  const onRewindValuesChange = () => {
    const values = formRewind.getFieldsValue();
    const pirceValue = rewindCompany.exchangeBefore;
    let totalPrice = 0; 
    let rewindPrice = values.size * values.price; 
    if(pirceValue && pirceValue.length > 0){
      pirceValue.map((item:any)=>{
        if(item.price && item.size){
          totalPrice = totalPrice + parseInt(item.price) * item.size
        }
      })
    }
    if(totalPrice < rewindPrice){
      formRewind.setFieldsValue({spread: rewindPrice - totalPrice})
    }else{
      formRewind.setFieldsValue({spread: 0 })
    }
  }
  const onRewindFinish = () => {
    const data:any = {};
    const exchangeAfter:any = {};
    const values = formRewind.getFieldsValue();
    exchangeAfter.couponGroupId = values.couponGroupId;
    exchangeAfter.size = values.size;
    data.paymentAmount = values.spread;
    data.exchangeAfter = exchangeAfter;
    data.phone = rewindCompany.contactMobileNo;
    data.exchangeBefore = rewindCompany.exchangeBefore;
    let allValue = 0;
    let allSize = 0;
    let flag = 0;
    if(values.couponGroupName == "事故排查检"){
      data.exchangeBefore.forEach((item:any)=>{
        allValue = allValue + item.size*parseInt(item.price)
        allSize = allSize + item.size
        if(allSize > 1){
          if(item.price && parseInt(item.price) > 200){
            flag = 1
          }else{
            flag = 2
          }
        }
      })
    }
    if(flag == 1){
      Toast.show({
        icon: 'fail',
        content: "事故排查检只可单张兑换！！！",
      });
      return
    }
    if(flag == 2  && allValue < 200){
      Toast.show({
        icon: 'fail',
        content: "事故排查检不可补差价！！！",
      });
      return
    }
    RewindCreateOrder(data).then((res:any)=>{
      if(res.retCode === 0){
        Toast.show({
          icon: 'success',
          content: '更换成功',
        });
        formRewind.resetFields();
        setRewindCompany({});
        setRewindCheckList([]);
        setRewindList([])
        setActiveKey(2)
        setcheckKeyValue("8")
      }else{
        Toast.show({
          icon: 'fail',
          content: res.retMsg,
        });
      }
    })
    .catch(()=>{
      Toast.show({
        icon: 'fail',
        content: "更换失败",
      });
    })
    setFormRewindVisible(false)
  };
  const handleUserNameCancel = () => {
    setNameVisible(false)
  };
  const handleRewindCancel = () => {
    setRewindVisible(false)
  };
  const handleProposeCancel = () => {
    setNameProposeVisible(false)
  };
  const handleGroupCancel = () => {
    setCollectVisible(false)
  };
  const handleRewindNameCancel = () => {
    setCollectRewindVisible(false)
  };
  const initValue = async (value:any) => {
    let params:any = {}
    params.couponGroupName = value.couponGroupName
    params.cityCode = value.cityCode
    const msg = await getList(params)
    if(msg.retCode === 0){
      if(msg.data && msg.data.datas && msg.data.datas.length >0) {
        setData(msg.data.datas)
        onValuesChange(-1)
      }
    }
  }
  const onValuesChange = (indexI:number) => {
    const allValues = form.getFieldsValue();
    collectList.forEach((item:any,index:number)=>{
      let totalPrice = 0
      if(allValues[`giftCouponGroupId${index}`]){
        data.map((child:any)=>{
          let gitfPriceTotal = 0
          let giftNum:number = 0
          let nowPrice = 0
          if(`${allValues[`giftCouponGroupId${index}`]}` === `${child.couponGroupId}`){
            if(child.singlePrice){
              if(allValues[`num${index}`] === 1){
                form.setFieldValue(`price${index}`, child.singlePrice)
                nowPrice = child.singlePrice
              }else{
                form.setFieldValue(`price${index}`, child.price)
                nowPrice = child.price
              }
              form.setFieldValue(`numNow${index}`,1)
            }else{
              form.setFieldValue(`price${index}`, child.price)
              form.setFieldValue(`numNow${index}`,2)
              nowPrice = child.price
            }
            totalPrice = nowPrice * parseFloat(allValues[`num${index}`])
          }
          if((child.couponGroupId && (`${child.couponGroupId}` === `${allValues[`giftCouponGroupId${index}`]}`)) || allValues[`couponGroupName${index}`] == "批批车"){
            if(child.priceCondition && child.giftRatio){
              gitfPriceTotal = totalPrice * child.giftRatio
              if(child.goodsGift && child.goodsGift.price && child.giftFlag && gitfPriceTotal > 0){
                giftNum = gitfPriceTotal/child.goodsGift.price;
                if(giftNum >= 0){
                  if(!giftConsultFlag[`${child.couponGroupId}${giftConsultFlagIndex}`]){
                    giftList[index] = {
                      couponGroupId: child.goodsGift.couponGroupId,
                      couponGroupName: child.goodsGift.couponGroupName,
                      // @ts-ignore
                      num: totalPrice >= child.priceCondition ? parseInt(giftNum) : 0,
                      price: child.goodsGift.price,
                      mainCouponGroupId: child.couponGroupId,
                    }
                  }else{
                    if(child.inquiryBusinessPrice){
                      giftList[indexI] = {
                        couponGroupId: child.inquiryBusinessPrice.rowId,
                        couponGroupName: child.inquiryBusinessPrice.orderName,
                        // @ts-ignore
                        num: totalPrice >= child.priceCondition ? parseInt(giftNum) : 0,
                        price: child.goodsGift.price,
                        mainCouponGroupId: child.couponGroupId,
                      }
                    }
                  }
                }
              }
            }else{
              delete giftList[index]
            }
          }
        })
      }
    })
    if(giftList && giftList.length>0 ){
      const giftValues = formGift.getFieldsValue();
      giftList.map((item:any,index:number)=>{
        if(item){
          formGift.setFieldValue(`giftCouponGroupId${index}`,item.couponGroupId)
          formGift.setFieldValue(`couponGroupName${index}`,item.couponGroupName)
          formGift.setFieldValue(`price${index}`,item.price)
          // formGift.setFieldValue(`num${index}`,0)
          if(giftValues[`num${index}`] > item.num){
            formGift.setFieldValue(`num${index}`,item.num)
          }
        }else{
          delete giftList[index]
        }
      })
    }
    if(purchaseNum && purchaseNum[1] > 1){
      if(priceNum >= 3000 && priceNum < 5000){
        setPurchaseNum([1,2])
      }
      if(priceNum >= 5000){
        setPurchaseNum([2,3])
        formBudget.setFieldValue("recommendSize",2)
      }
    }
    priceNumValue()
    setGiftList(giftList)
  }
  const onSearchCoupon = async (value:any) => {
    const params:any = {pageNo:1,pageSize:500};
    if(value != ""){
      params.nameAndPhone = value;
    }
    const res = await getCompanyCoupon(params);
    if (res.data) {
      setList(res.data.datas);
    }
  }
  useEffect(()=>{
    const allValues = form.getFieldsValue()
    getList({cityCode:contactCityCode})
    .then((res)=>{
      if(res.retCode === 0 && res.data && res.data.datas && res.data.datas.length > 0) {
        setData(res.data.datas)
        let AllPrice = 0 
        if(collectList && collectList.length > 0){
            collectList.forEach((child:any,index:number)=>{
              let gitfPriceTotal = 0
              let giftNum:number = 0
              let priceNow = 0
              res.data.datas.map((item:any)=>{
              if(`${item.couponGroupId}` === `${allValues[`giftCouponGroupId${index}`]}` || allValues[`couponGroupName${index}`] == "批批车"){
                if(item.singlePrice){
                  if(allValues[`num${index}`] === 1){
                    priceNow = item.singlePrice
                    form.setFieldValue(`price${index}`,item.singlePrice)
                  }else{
                    form.setFieldValue(`price${index}`,item.price)
                    priceNow = item.price
                  }
                  form.setFieldValue(`numNow${index}`,1)
                }else{
                  form.setFieldValue(`price${index}`,item.price)
                  form.setFieldValue(`numNow${index}`,2)
                  priceNow = item.price
                }
                AllPrice = AllPrice + priceNow*parseInt(allValues[`num${index}`])
                gitfPriceTotal = priceNow*parseInt(allValues[`num${index}`])
                //console.log(priceNow,1111,gitfPriceTotal);
                if(item.goodsGift && item.goodsGift.price ){
                  //console.log(allValues,item,item.goodsGift.price ,11111);
                  giftNum = (gitfPriceTotal/item.goodsGift.price) * item.giftRatio
                  if(giftNum >= 0){
                    giftList[index] = {
                      couponGroupId: item.goodsGift.couponGroupId,
                      couponGroupName: item.goodsGift.couponGroupName,
                      // @ts-ignore
                      num:gitfPriceTotal >= item.priceCondition ? parseInt(giftNum) : 0,
                      price: item.goodsGift.price,
                      mainCouponGroupId: item.couponGroupId
                    }
                  }else{
                    delete giftList[index]
                  }
                }else{
                  delete giftList[index]
                }
              }
            })
          })
        }
        if(giftList && giftList.length>0){
          giftList.map((item:any,index:number)=>{
            if(item){
              formGift.setFieldValue(`giftCouponGroupId${index}`,item.couponGroupId)
              formGift.setFieldValue(`couponGroupName${index}`,item.couponGroupName)
              formGift.setFieldValue(`price${index}`,item.price)
              // formGift.setFieldValue(`num${index}`,item.num)
            }else{
              delete giftList[index]
            }
          })
        }
        setGiftList([...giftList])
        setPriceNum(AllPrice)
        priceNumValue()
      }
    })
  },[contactCityCode , activeKey])
  const init = async () => {
    let list: any[] = [];
    let list2: any[] = [];
    let list3: any[] = [];
    datas.RECORDS.map((item: any) => {
      if (item.deep === 0) {
        list.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 1) {
        list2.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      // if (item.deep === 2) {
      //   list3.push({
      //     label: item.name,
      //     value: item.row_id,
      //     pid: item.pid,
      //   });
      // }
    });
    let city: any[] = [];
    let city2: any[] = [];
    list2.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list3.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city.push(data);
    });
    list.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list2.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city2.push(data);
    });
    setoptions(city2);
  };
  useEffect(()=>{
    if(activeKey === 0){
      initValue({})
    }
    init()
    //console.log(query.key);
    if(query.key){
      setActiveKey(parseInt(query.key))
    }
    onSearchCoupon("")
  },[])
  useEffect(()=>{
    onValuesChange(-1)
  },[contactCityCode])
  useEffect(()=>{
    if(dataFlag){
      companyValue(dataFlag)
      setActiveKey(1)
    }
  },[dataFlag])
  const collectPriceValueInit = () =>{
    collectPrice({}).then((res)=>{
      if(res.retCode === 0){
        setCollectPriceValue(res.data)
      }
    })
  }
  useEffect(()=>{
    onValuesChange(-1)
    if(activeKey === 2){
      collectPriceValueInit()
    }
  },[activeKey])
  const onReset = () => {
    form.resetFields()
    formGift.resetFields()
    formBudget.resetFields()
    setGiftList([])
    setCollectList([])
    setPriceNum(0)
    setPurchaseNum(-1)
    setCompanyId(0)
    setCompanyName("选择下单商户")
    setBelongMarketName("--")
    setDoorHeadPhoto("")
  }
  const onFinish = () => {
    if(companyId === 0){
      setFlag(false)
      Toast.show({
        icon: "fail",
        content: "请选择商户"
      })
      return
    }
    form.validateFields().then(() => {
      setFlag(true)
      // onSubmitGrounp();
      setRecordVisible(true)
    });
  }
  const onSubmitGrounp = async () => {
    const allValues = form.getFieldsValue()
    const giftValues = formGift.getFieldsValue()
    const budgetValues = formBudget.getFieldsValue()
    const record = recordForm.getFieldsValue()
    let list:any[] = []
    collectList.map((item:any,index:number)=>{
      if(allValues[`giftCouponGroupId${index}`] && allValues[`num${index}`]){
        list.push({
          num: parseInt(allValues[`num${index}`]),
          couponGroupId: parseInt(allValues[`giftCouponGroupId${index}`]),
          giftFlag: false
        })
      }
    })
    giftList.map((item:any,index:number)=>{
      if(giftValues[`giftCouponGroupId${index}`]){
        list.push({
          num: parseInt(giftValues[`num${index}`]) || 0,
          couponGroupId: parseInt(giftValues[`giftCouponGroupId${index}`]),
          giftFlag: true,
          mainCouponGroupId: item.mainCouponGroupId,
          giftRemark: allValues[`giftRemark${index}`],
        })
      }
    })
    const params:any = {
      companyId: companyId,
      price: priceNum,
      batchBuyDataList: list,
      onlyRecordFlag: record.onlyRecordFlag,
    }
    if(marketReceipts){
      params.chargeType = 0
    }
    if((giftList.length === 0 && priceNum >= 3000) || (giftList.length > 0 && priceNum >= 5000)){
      params.imageShop = imageShop
    }
    if((purchaseType === 1) && (budgetValues.recommendSize != 0)){
      params.recommendCompanyId = budgetValues.recommendCompanyId 
      params.recommendSize = budgetValues.recommendSize 
    }
    createOrder(params).then((res:any)=>{
      if(res.retCode === 0){
        Toast.show({
          icon: "success",
          content: "提交成功"
        })
        onReset()
        setActiveKey(2)
        setGiftConsultFlag({})
      }else{
        Toast.show({
          icon: "fail",
          content:res.retMsg
        })
      }
      setFlag(false)
    })
    .catch(()=>{
      Toast.show({
        icon: "fail",
        content:"提交失败"
      })
      setFlag(false)
    })
  }
  const onValueChange = () => {
    const allValues = form.getFieldsValue()
    const budgetValues = formBudget.getFieldsValue()
    let budgetNum:number = 0
    
    if(allValues[`giftCouponGroupId0`] && budgetValues.budget){
      data.map((child:any)=>{
        let TotalBudgetNum:number = parseInt(budgetValues.budget)
        if(child.couponGroupId && (`${child.couponGroupId}` === `${allValues[`giftCouponGroupId0`]}`)){
          // @ts-ignore
          budgetNum = parseInt(TotalBudgetNum/child.price)
        }
      })
      form.setFieldValue("num0", budgetNum)
      onValuesChange(-1)
    }
  }
  useEffect(()=>{
    onValuesChange(-1)
    
  },[])
  const priceNumValue = () => {
    const allValueNow = form.getFieldsValue()
    let price = 0
    collectList.map((item:any,index:number)=> {
      let totalPrice = 0
      if(allValueNow[`price${index}`] && allValueNow[`num${index}`]){
        totalPrice = parseFloat(allValueNow[`price${index}`]) * parseFloat(allValueNow[`num${index}`])
        price = price + totalPrice
      }
    })
    setPriceNum(price)
  }
  useEffect(() => {
    if(activeKey === 2){
      // setParams({
      //   pageNo: -1,
      //   pageSize: 20,
      // });
      orderValue()
    }
  }, [params]);
  // useEffect(() => {
  //   if(activeKey === 2){
  //     reload()
  //   }
  // }, [activeKey]);
  // const reload = (pm: any = {}) => {
  //   //console.log(pm);
    
  //   setParams({
  //     ...params,
  //     pageNo: -1,
  //     pageSize: 20,
  //     payStatus: pm.payStatus,
  //   });
  // };
  const reset = (pm: any = {}) => {
    setParams({
      pageNo: -1,
      pageSize: 20,
      payStatus: params.payStatus,
    });
    collectPriceValueInit()
  };
  const reset2 = (pm: any = {}) => {
    setParams({
      pageNo: -1,
      pageSize: 20,
      payStatus: "8",
    });
    collectPriceValueInit()
  };
  const onChange = (v: any) => {
    //console.log(v);
    
    setcheckKeyValue(`${v}`)
    // reload({ payStatus: v});
    
    setParams({
      ...params,
      pageNo: -1,
      pageSize: 20,
      payStatus: v,
    });
  };
  const onRewindChange = (v: any) => {
    //console.log(v);
    setRewindCheckList(v)
    if(v && v.length > 0){
      let list: any = [];
      let exchangeBefore:any = [];
      let obj:any = {};
      v.map((item:any)=>{
        let arr = item.split('++');
        list.push(arr[2]);
      })
      list.forEach((item:any)=>{
        obj[item] = (obj[item] + 1) || 1
      })
      for (let key in obj) {
        let keyList = {
          name:key,
          size:obj[key]
        }
        exchangeBefore.push(keyList)
      }
      setCheckSizeList(exchangeBefore)
    }
  };
  const onRewindSubmit = (v: any) => {
    if(v && v.length > 0){
      let list: any = [];
      let exchangeBefore:any = [];
      let obj:any = {};
      let objPrice:any = {};
      v.map((item:any)=>{
        let arr = item.split('++');
        list.push({
          couponId:arr[0],
          price:arr[3]
        });
      })
      list.forEach((item:any)=>{
        obj[item.couponId] = (obj[item.couponId] + 1) || 1
        objPrice[item.couponId] = item.price
      })
      for (let key in obj) {
        let keyList = {
          couponGroupId:key,
          size:obj[key],
          price: objPrice[key]
        }
        exchangeBefore.push(keyList)
      }
      //console.log(exchangeBefore,list,v,obj);
      setRewindCompany({...rewindCompany,exchangeBefore:exchangeBefore})
      setCollectRewindVisible(true)
      //console.log(rewindCompany);
    }
  };
  const onDelete = (v: any) => {
    formDelete.validateFields().then(() => {
      onDeleteOrder(v);
    })
    .catch(()=>{
      setVisibleDetete(true)
    })
  }
  const onDeleteOrder = (v: any) => {
    const values = formDelete.getFieldsValue();
    let paramData:any = {remark:values.forms}
    if(checkKeyValue === "8"){
      paramData.exchangeNo = v
      couponExchangeCancelOrder(paramData).then((res:any)=>{
        if(res.retCode === 0){
          Toast.show({
            icon: "success",
            content: "已取消订单"
          })
          reset2()
        }else{
          Toast.show({
            icon: "fail",
            content:res.retMsg
          })
        }
      })
      .catch(()=>{
        Toast.show({
          icon: "fail",
          content:"订单取消失败"
        })
      })
    }else{
      paramData.batchBuyNo = v
      cancelOrder(paramData).then((res:any)=>{
        if(res.retCode === 0){
          Toast.show({
            icon: "success",
            content: "已取消订单"
          })
          reset()
        }else{
          Toast.show({
            icon: "fail",
            content:res.retMsg
          })
        }
      })
      .catch(()=>{
        Toast.show({
          icon: "fail",
          content:"订单取消失败"
        })
      })
    }
  };
  const stateEnum = [
    { name: '常规检测', value: 1 },
    {
      name: '豪车检测',
      value: 2,
    },
    {
      name: '查询服务',
      value: 99,
    },
    {
      name: '动力三包检+万高延保',
      value: 3,
    },
    // { name: '新能源检测', value: 3 },
  ];
  const orderValue = async()=>{
    setHasMore(false)
    let temp = pooledOrders
    if(params.pageNo < 1){
      temp = []
      params.pageNo = 1
    }
    if(checkKeyValue === "-1"){
      delete params.payStatus
    }
    let num =  params.pageNo + 1
    setpageNo(num)
    //console.log(params);
    
    if(checkKeyValue === "8"){
      const {datas,totalPage} = await couponExchangeOrderList(params)
      const orders = []
      const tempImages:any = {}
      for(const key in datas){
        const item = datas[key]
        tempImages[item.batchBuyNo] = item.imgProof?.split(",")
        orders.push(item)
      }
      setImgProofList(tempImages)
      setPooledOrders([...temp,...orders])
      setHasMore(totalPage>num)
    }else{
      const {datas,totalPage} = await orderList(params)
      const orders = []
      const tempImages:any = {}
      for(const key in datas){
        const item = datas[key]
        tempImages[item.batchBuyNo] = item.imgProof?.split(",")
        orders.push(item)
      }
      setImgProofList(tempImages)
      setPooledOrders([...temp,...orders])
      setHasMore(totalPage>num)
    }
  }
  // 重新发卷
  const onRecurl = (batchBuyNo:string) => {
    if(checkKeyValue === "8"){
      confirmRRewindCouponAgain({exchangeNo:batchBuyNo}).then((res:any)=>{
        if(res.retCode === 0){
          Toast.show({
            icon: 'success',
            content: '发放成功',
          })
        }else{
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          }) 
        }
      })
      .catch(()=>{
        Toast.show({
          icon: 'fail',
          content: '发放失败',
        }) 
      })
    }else{
    confirmCouponAgain({batchBuyNo: batchBuyNo}).then((res:any)=>{
      if(res.retCode === 0){
        Toast.show({
          icon: 'success',
          content: '发放成功',
        })
      }else{
        Toast.show({
          icon: 'fail',
          content: res.retMsg,
        }) 
      }
    })
    .catch(()=>{
      Toast.show({
        icon: 'fail',
        content: '发放失败',
      }) 
    })
  }
  }
  useEffect(()=>{
    reset()
  },[])
  const handelUserMarket = async (value: any) => {
    //console.log(value);
    // localStorage.setItem('belongMarketId', value[0].id);
    // formComany.setFieldValue('belongMarketName', value[0].marketName);
    if(value && value[0]){
      saveCompanyBelongMarket({companyId:companyId,marketId:value[0].id})
      .then((res:any)=>{
        if(res.retCode === 0){
          setBelongMarketName(value[0].marketName)
        }else{
          Toast.show({
            icon: 'fail',
            content: res.retMsg,
          }) 
        }
        setMarketVisible(false);
      })
      .catch(()=>{
        Toast.show({
          icon: 'fail',
          content: '发放失败',
        }) 
      })
    }
  };
  const handleUserMarketCancel = async () => {
    setMarketVisible(false);
  };
  const orderEnum = [
    { name: '全部', value: -1 },
    { name: '待收款', value: 0 },
    { name: '已收款', value: 1 },
    { name: '已取消', value: 2 },
    { name: '待审核', value: 7 },
    { name: '换卷', value: 8 },
  ];
  const onAnOrder = (item:any) => {
    setActiveKey(1)
    collectList[collectList.length -1] = item;
    collectList.push({})
    setCollectList([...collectList])
    inquiryBusinessPrice[item.couponGroupId] = item;
    setInquiryBusinessPrice({...inquiryBusinessPrice})
    form.setFieldValue(`giftCouponGroupId${collectList.length-1}`,item.couponGroupId)
    form.setFieldValue(`couponGroupName${collectList.length-1}`,item.couponGroupName)
    if(item.singlePrice){
      form.setFieldValue(`price${collectList.length-1}`,item.singlePrice)
      form.setFieldValue(`singlePrice${collectList.length-1}`,item.singlePrice)
      form.setFieldValue(`numNow${collectList.length-1}`,1)
      form.setFieldValue(`num${collectList.length-1}`,1)
      // setMinNum(1)
    }else{
      form.setFieldValue(`price${collectList.length-1}`,item.price)
      form.setFieldValue(`numNow${collectList.length-1}`,2)
      form.setFieldValue(`num${collectList.length-1}`,2)
      // setMinNum(2)
    }
    setContactCityCode(contactCityCode)
    onValuesChange(-1)
  }
  
  const onSubmit = async (item: any) => {
    let value: any[] = [];
    if (item.couponGroupId) {
      const msg = await getCouponRecord({
        couponGroupId: item.couponGroupId,
        phone: item.companyPhone,
        pageSize: 500,
      });
      //console.log(msg);
      if (msg.data) {
        msg.data.datas.map((child: any) => {
          if (child.couponIsUsed != 1) {
            value.push(child.id);
          }
        });
      }
    }
    //console.log(value);

    // Modal.confirm({
    //   content: `共${value.length}张${item.couponName}，确认全部核销？`,
    //   bodyClassName: styles.modal,
    //   onConfirm: async () => {
    //     const msg = await deleteCouponByIds({ couponIds: value });
    //     if (msg.retCode === 0) {
    //       Toast.show({
    //         icon: 'success',
    //         content: msg.retMsg,
    //       });
    //       // back()
    //     } else {
    //       Toast.show({
    //         icon: 'fail',
    //         content: msg.retMsg,
    //       });
    //     }
    //   },
    // });
  };
  
  const onCheckChange = (value: any) => {
    setListValue(value)
    //console.log(value);
    
  };

  // 检测师通过手机号查询  工作人员通过VIN码
  // const getReport = async () => {
  //   const msg = await getCouponRecord({
  //     couponGroupId: query.couponGroupId,
  //     phone: query.phone,
  //     pageSize: 500
  //   });
  //   //console.log(msg);
  //   if (msg.data && msg.data.datas && msg.data.datas.length>0) {
  //     setList(msg.data.datas);
  //     let index = 0
  //     msg.data.datas.map((item:any)=>{
  //       if(item.couponIsUsed != 1){
  //         index++
  //       }
  //     })
  //     //console.log(index);
  //     setListLength(index)
  //   }
  // };
  const stateMap: any = { 0: '待收款', 1: '已收款', 2: '已取消', 3: '已退款', 4: "订单超时",5:"财务待确认", 6:"财务对付款凭证驳回",7:"待审核"};
  const stateColor: any = { 0: '#FF611B', 1: '#808080', 2: '#FA2020', 3: '#FA2020', 4: "#FA2020", 5: "#FA2020", 6: "#FA2020",7:"#FF611B"};
  return(
    <div className={styles.page}>
      <Navbar name= {"集采管理"}></Navbar>
      <div className={styles.collect}>
        {activeKey === 0 && (
          <>
            <div className={styles.title}>
              <div className={styles.search}>
                <div onClick={()=>{
                  setVisible(true)
                }}>
                  <span>{cityCode && cityCode.length > 1 && cityCode[1].label ? cityCode[1].label : "全国"}</span>
                  <DownFill
                    style={{
                      fontSize:"10px",
                      marginRight: "5px"
                    }}
                  />
                </div>
                <SearchBar
                  onSearch={onSearch}
                  // style={{ '--background': '#E9ECF0'}}
                  placeholder="搜索产品"
                />
              </div>
            </div>
            <div className={styles.content}>
              <Tabs defaultActiveKey={"1"}>
                {stateEnum.map((tab:any,tabIndex:number)=>{
                  return (
                    <Tabs.Tab title={tab.name} key={tab.value}>
                      {data && data.length > 0 && data.map((item:any)=>{
                        if(item.type === tab.value){
                          if(item.type === 99){
                            return (
                              <div className={styles.test_0}>
                                <div className={styles.test_order}>
                                  <img src={item.imgUrl ? item.imgUrl : test} alt="" />
                                  <div className={styles.test_order_0}>
                                    <div className={styles.test_order_title}>{item.couponGroupName}</div>
                                    <div className={styles.test_order_price}>
                                      <div>￥<span>{item.price ? item.price : "--"}</span> {item.originPrice && (<del>原价{item.originPrice}</del>)}</div>
                                      <div onClick={()=>{
                                        onAnOrder(item)
                                      }} className={styles.footer_button}>下单</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }else{
                            return (
                              <div className={styles.test}>
                                <div className={styles.test_order}>
                                  <img src={item.imgUrl ? item.imgUrl : test} alt="" />
                                  <div className={styles.test_order_0}>
                                    <div className={styles.test_order_title}>{item.couponGroupName}</div>
                                    <div className={styles.test_order_tabs}>
                                      {item.couponGroupName && item.couponGroupName != "车辆批发检" &&(
                                        <>
                                        {(item.couponGroupName === "事故排查检" || item.couponGroupName.indexOf("豪车")> -1) ? <span>百天回购</span> :<span>终身回购</span>}
                                        </>
                                      )}
                                      <span>检测报告</span>
                                    </div>
                                    <div className={styles.test_order_result}>
                                      使用范围：{item.useDesc ? item.useDesc : "--"}
                                    </div>
                                    <div className={styles.test_order_price}>
                                      <div>￥<span>{item.price ? item.price : "--"}</span> {item.originPrice && (<del>原价{item.originPrice}</del>)}</div>
                                      <div onClick={()=>{
                                        onAnOrder(item)
                                      }} className={styles.footer_button}>下单</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        }
                      })}
                    </Tabs.Tab>
                  )
                })}
              </Tabs>
            </div>
          </>
        )}
        {activeKey === 1 && (
          <div className={styles.content_0}>
            <div className={styles.packages}>
              <div className={styles.company}>
                <img height={40} src={doorHeadPhoto === "" ? initMerchant : doorHeadPhoto} alt="" />
                <div className={styles.company_name}>
                  <div onClick={()=>{
                    if(purchaseType != 1){
                      setNameVisible(true)
                      localStorage.removeItem("conmanyVisitSignType")
                    }
                  }}>
                    <a style={{color:"#222222"}} 
                      onClick={()=>{
                        setNameVisible(true)
                        localStorage.removeItem("conmanyVisitSignType")
                      }}>{companyName}</a>
                      {purchaseType === 1 && (
                        <Checkbox
                          style={{
                            '--icon-size': '18px',
                            '--font-size': '14px',
                            '--gap': '6px',
                            marginLeft:"10px"
                          }}
                          defaultChecked={featuredFlag}
                          onChange={(v)=>{
                            setFeaturedFlag(v)
                          }}
                        >
                          被推荐商户
                        </Checkbox>
                      )}
                  </div>
                  <div className={styles.company_name_0}>{contactMobileNo} | {belongMarketName} {`${companyId}` != "0" && (<span onClick={()=>{
                    setNameVisible(false)
                    setMarketVisible(true)
                  }}>修改市场</span>
                  )}</div>
                </div>
                <div className={styles.back} onClick={()=>{
                  setNameVisible(true)
                  localStorage.removeItem("conmanyVisitSignType")
                }}>
                  <img src={返回} alt="" />
                </div>
              </div>
              {/* <div className={styles.company_phone}>
                <div>付款/发卷手机号</div>
                <div>{contactMobileNo}</div>
              </div> */}
              <div className={styles.packages_form}>
                <div className={styles.budget_form}>
                  <Form form={formBudget} layout='horizontal' mode='card' onValuesChange={onValueChange}>
                    <Form.Item 
                      className={styles.header}
                      label={
                        <>
                          <div className={styles.header_budget}>
                            <span>预算金额</span>
                            <span style={{color:"#222222",marginLeft:"15px"}}>￥</span>
                          </div>
                        </> 
                      } 
                      name="budget"
                      >
                      {/* <span style={{marginRight:"3px"}}>￥</span> */}
                      <Input placeholder='输入金额' />
                    </Form.Item>
                    {purchaseType === 1 && featuredFlag && (
                      <>
                        <Form.Item
                          className={styles.form_item_prchase}
                          label={
                            <>
                              <span>转介绍商户</span>
                              {purchaseNum && purchaseNum[1] && purchaseNum[1] === 1 && (
                                <span style={{color:"red",fontSize:"12px"}}>(同城)</span>
                              )}
                              {purchaseNum && purchaseNum[1] && purchaseNum[1] > 1 && (
                                <span style={{color:"red",fontSize:"12px"}}>(异地)</span>
                              )}
                              
                            </>
                          } 
                          name="recommendCompanyName"
                          onClick={()=>{
                            setNameProposeVisible(true)
                          }}
                          >
                          <Input placeholder='选择介绍商户' />
                        </Form.Item>
                        <Form.Item
                          label={'转介绍商户'}
                          name="recommendCompanyId"
                          style={{display:"none"}}
                          >
                          <Input placeholder='选择介绍商户' />
                        </Form.Item>
                        <Form.Item
                          label="数量" 
                          name="recommendSize"
                          className={styles.form_num_0}
                          >
                          <Stepper min={purchaseNum[0] || 0} max={purchaseNum[1] || 0}/>
                        </Form.Item>
                      </>
                    )}
                  </Form>
                </div>
                <div className={styles.title}>
                  <span>
                    购买检测产品
                    <Checkbox style={{marginLeft:"10px"}} onChange={(v)=>{
                      setMarketReceipts(v)
                    }}>
                      市场收款
                    </Checkbox>
                  </span>
                  <span className={styles.title_button} onClick={()=>{
                    collectList.push({})
                    setCollectList([...collectList])
                    //console.log(inquiryBusinessPrice);
                  }}>+ 添加</span>
                </div>
                {collectList && collectList.length>0 && collectList.map((item:any,index:number)=>{
                  const values = form.getFieldsValue();
                  //console.log(values);
                  
                  if(item){
                    return (
                      <Form form={form} layout='horizontal' className={styles.form_border} mode='card' onValuesChange={onValuesChange}>
                        <Form.Header>
                          <div>集采产品{index + 1}</div>
                          <span onClick={()=>{
                            let list: any[] = []
                            let gift: any[] = []
                            collectList.map((child:any,childIndex:number)=>{
                              if(childIndex != index){
                                list.push(child)
                              }else{
                                list.push(undefined)
                              }
                            })
                            const arr = list.filter((item:any)=> item)
                            giftList.map((child:any,childIndex:number)=>{
                              if(childIndex != index){
                                gift.push(child)
                              }
                            })
                            if(arr.length === 0){
                              onReset()
                            }
                            // gift = giftList.filter((indexItem:number)=> indexItem === index)
                            setGiftList(gift)
                            setCollectList(arr)
                          }}><DeleteOutline /> 删除</span>
                        </Form.Header>
                        <Form.Item 
                          name={`couponGroupName${index}`}
                          label='集采产品名称' 
                          onClick={()=>{
                            setGroupIndex(index)
                            setCollectVisible(true)
                          }}
                          
                        >
                          <Input placeholder='请选择产品' readOnly/>
                        </Form.Item>
                        <Form.Item 
                          name={`giftCouponGroupId${index}`}
                          style={{display:"none"}}
                          rules={[{ required: values[`couponGroupName${index}`] == "批批车" ? false : true, message: '请选择产品' }]}
                        >
                          <Input placeholder='请选择产品' />
                        </Form.Item>
                        <Form.Item
                          name={`num${index}`}
                          label='集采数量'
                          className={styles.form_num_0}
                          initialValue={2}
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value) {
                                  return form.setFieldValue(`num${index}`, 2)
                                }
                                return Promise.resolve();
                              },
                              required: true,
                            }),
                          ]}
                        >
                          {/* <Input placeholder='输入数量' /> */}
                          <Stepper min={values[`numNow${index}`]} onChange={(v)=>{
                            
                          }}/>
                        </Form.Item>
                        <Form.Item
                          name={`numNow${index}`}
                          label='集采数量'
                          style={{display:"none"}}
                        >
                          <Stepper min={values[`numNow${index}`]}/>
                        </Form.Item>
                        <Form.Item
                          name={`singlePrice${index}`}
                          label='集采数量'
                          style={{display:"none"}}
                        >
                          <Input placeholder='请选择产品' />
                        </Form.Item>
                        <Form.Item name={`price${index}`} label={"集采单价"} className={styles.form_price_one}>
                          <Input placeholder='￥--' readOnly/>
                        </Form.Item>
                        <div className={styles.form_gift}>
                          {giftList && giftList.length>0 && giftList.map((item:any,giftIndex:number)=>{
                            if(item && index === giftIndex){
                              return (
                                <Form form={formGift} layout='horizontal' mode='card'>
                                  <Form.Header> 
                                    <a className={styles.giftConsultFlag} onClick={()=>{
                                      const values = form.getFieldsValue(); 
                                      giftConsultFlag[`${values[`giftCouponGroupId${index}`]}${index}`] = !giftConsultFlag[`${values[`giftCouponGroupId${index}`]}${index}`]
                                      setGiftConsultFlag({...giftConsultFlag})
                                      setGiftConsultFlagIndex(index)
                                      //console.log(giftConsultFlag);
                                      onValuesChange(index)
                                    }}>
                                      切换为 {giftConsultFlag[`${form.getFieldsValue()[`${`giftCouponGroupId${index}`}`]}${index}`] ? "事故排查检" : "查询服务"}
                                    </a>
                                  </Form.Header>
                                  <Form.Item label={<>赠送产品 </>} name={`couponGroupName${giftIndex}`}>
                                    <Input placeholder='请选择产品' readOnly/>
                                  </Form.Item>
                                  <Form.Item 
                                    name={`giftCouponGroupId${giftIndex}`}
                                    style={{display:"none"}}
                                  >
                                    <Input placeholder='请选择产品' />
                                  </Form.Item>
                                  <Form.Item label='集采数量' name={`num${giftIndex}`} className={styles.form_num_0}>
                                    <Stepper max={item.num} min={0}/>
                                  </Form.Item>
                                </Form>
                              )
                            }
                          })}
                        </div>
                        {giftList && giftList.length>0 && giftList[index] && (
                          <Form.Item name={`giftRemark${index}`} label='备注'>
                            <Input placeholder='请输入备注，如：赠送数量增加2个 '/>
                          </Form.Item>
                        )}
                      </Form>
                    )
                  }
                })}
                <Form layout='horizontal' mode='card'>
                  <Form.Header>
                    <div></div>
                    <span className={styles.total}>共计：<span>￥{priceNum != 0 ? priceNum : "--"}</span></span>
                  </Form.Header>
                  <Form.Item style={{display:"none"}}></Form.Item>
                </Form>
                {/* {giftList && giftList.length>0 && (
                  <div className={styles.title} style={{marginTop:"10px"}}>
                    <span>赠送产品</span>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        )}
        {activeKey === 2 && (
          <div className={styles.order}>
            <Tabs defaultActiveKey={"-1"} onChange={onChange}>
              {orderEnum.map((item:any)=>{
                return (
                  <Tabs.Tab title={item.name} key={item.value}/>
                )
              })}
            </Tabs>
            <div className={styles.order_search}>
              <SearchBar
                onSearch={onSearchOrder}
                // style={{ '--background': '#E9ECF0'}}
                placeholder="商户名称或者BD查询"
              />
              {collectPriceValue && ((position && position.indexOf("大区") > -1) || (batchBuyAuthority && batchBuyAuthority === "99") ) && (<div className={styles.collectPriceValue}>今日收款: 共{collectPriceValue.paid || 0}笔，合计{collectPriceValue.paidPrice || 0}元</div>)}
            </div>
            <div className={styles.order_list}>
              <div className={styles.order_list_content}>
                <PullToRefresh
                  onRefresh={async () => {
                    await sleep(1000);
                    if((checkKeyValue == "8")){
                      reset2()
                    }else{
                      reset()
                    }
                  }}
                >
                  {pooledOrders && pooledOrders.length > 0 && pooledOrders.map((item:any)=>{
                    let startTime = new Date(new Date().toLocaleDateString()).getTime();
                    const day = 60*60*1000*24 - 1 + startTime;
                    const days = Date.parse(item.createdAt);
                    const dateTime = day - days;
                    if(item.tasteFlag){
                      return (
                        <Card
                          title={
                            <div className={styles.company}>
                              <img src={item.doorHeadPhoto != "" && item.doorHeadPhoto ? item.doorHeadPhoto : initMerchant }/>
                              <div className={styles.company_name}>
                                <div>{item.memberName}</div>
                                <span>{item?.memberPhone} | {item.memberMarketName ? item.memberMarketName : "--"}</span>
                              </div>
                              <div className={styles.back} style={{color: stateColor[item.payStatus]}}>
                                <span>{stateMap[item.payStatus]}</span>
                              </div>
                            </div>
                          }
                        >
                          <div className={styles.order_list_content_text}>
                            {item.details && item.details.length>0 && item.details.map((child:any)=>{
                              return (
                                <div className={styles.test_order_footer}>
                                  <div style={{fontWeight:"400",fontSize:"13px"}}>
                                    BD：{item.bdName}
                                  </div>
                                  <div style={{fontWeight:"400",fontSize:"13px"}}>
                                    {child.couponName} x {child.num}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                          <div className={styles.order_list_content_foot}>
                            <div className={styles.bd}></div>
                            <div className={styles.order_list_content_footer}>
                              {((position && position.indexOf("大区") > -1) || (batchBuyAuthority && batchBuyAuthority === "99") )&& (
                                <div
                                  className={styles.order_list_content_footer_1}
                                  onClick={() => {
                                    history.push("./collection-details",item)
                                  }}
                                >
                                  {item.payStatus === 7 ? "审核" : "订单详情"}
                                </div>
                              )}
                              {item.payStatus != 4 && item.payStatus != 1 && (
                                <div
                                  className={styles.order_list_content_footer_0}
                                  onClick={() => {
                                    setVisibleDetete(true)
                                    setBatchBuyNo(item.batchBuyNo)
                                  }}
                                >
                                  取消订单
                                </div>
                              )}
                            </div>
                          </div>
                        </Card>
                      )
                    }else{
                      if(item.imgProof && item.imgProof != "" && (typeof item.imgProof === "string")){
                        let list = item.imgProof.split(",")
                        if(list && list.length > 0){
                          item.imgProof = list
                        }
                      }
                      return (
                        <Card
                          title={
                            <div className={styles.company}>
                              <img src={item.doorHeadPhoto != "" && item.doorHeadPhoto ? item.doorHeadPhoto : initMerchant }/>
                              <div className={styles.company_name}>
                                <div>{item.memberName} {checkKeyValue != "8" && (
                                  <>
                                   <span style={{color:"red"}}>{item.onlyRecordFlag ? "仅记录" : "发卷" }</span> <span style={{color:"red"}}>({item.purchase ? "复购" : "新购" }{item.imageShop && "形象店"})</span>{`${item.chargeType}`=== "0" && <span style={{color:"red",marginLeft:"5px"}}>市场收费</span>}
                                  </>
                                )}</div>
                                <span>{item?.memberPhone} | {item.memberMarketName ? item.memberMarketName : "--"}</span>
                              </div>
                              <div className={styles.back} style={{color: stateColor[item.payStatus]}}>
                                {item.payStatus === 0 ?
                                  <>
                                    <CountDown style={{marginLeft:"10px",fontSize:"12px",fontWeight:"400",textAlign:"right"}} endTime={Date.now() + dateTime} format="HH时mm分ss秒"/>
                                    <span>剩余支付时间</span>
                                  </>
                                :
                                  <span>{stateMap[item.payStatus]}</span>
                                }
                              </div>
                            </div>
                          }
                        >
                          <div className={styles.order_list_content_text}>
                            <div
                              onClick={() => {
                                if(checkKeyValue != "8"){
                                  history.push("./collection-details",item)
                                }
                              }}
                            >
                              {item.details && item.details.length>0 && item.details.map((child:any)=>{
                                if(child.recommendFlag){
                                  return (
                                    <div className={styles.test_present}>
                                      <div className={styles.test_present_0}>
                                        <div className={styles.test_present_title}>
                                          转介绍商户
                                        </div>
                                        <div>
                                          <div style={{fontSize:'13px'}}>{child.memberName} <span>({child.sameCityFlag ? "同城" : "异地" })</span></div>
                                          <div style={{fontSize:'12px'}}>{child.memberPhone}</div>
                                        </div>
                                      </div>
                                      <div className={styles.test_present_0}>
                                        <div className={styles.test_present_title}>
                                          转介绍商品 
                                        </div>
                                        <div>
                                          {child.couponName} x {child.num}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }else if(child.type === 99){
                                  return (
                                    <div className={styles.test}>
                                      <div className={styles.test_order}>
                                        <img style={{width:"15%"}} src={child.imgUrl ? child.imgUrl : test} alt="" />
                                        <div className={styles.test_order_0} style={{width:"65%"}}>
                                          <div className={styles.test_order_title}>{child.couponName ? child.couponName : "--"} <span style={{color:"red",fontSize:"12px",fontWeight:"400"}}>{child.giftFlag && "(赠)"}</span> </div>
                                        </div>
                                        <div className={styles.test_order_price}>
                                          {!child.giftFlag && (
                                            <div>
                                              ￥{child.num * child.price}
                                            </div>
                                          )}
                                          <span>x{child.num}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }else{
                                  return (
                                    <div className={styles.test}>
                                      <div className={styles.test_order}>
                                        <img src={child.imgUrl ? child.imgUrl : test} alt="" />
                                        <div className={styles.test_order_0}>
                                          <div className={styles.test_order_title}>{child.couponName ? child.couponName : "--"} <span style={{color:"red",fontSize:"12px",fontWeight:"400"}}>{child.giftFlag && "(赠)"}</span> </div>
                                          <div className={styles.test_order_tabs}>
                                            {child.couponName && child.couponName != "车辆批发检" &&(
                                              <>
                                              {(child.couponName === "事故排查检" || child.couponName.indexOf("豪车")> -1) ? <span>百天回购</span> :<span>终身回购</span>}
                                              </>
                                            )}
                                            <span>检测报告</span>
                                          </div>
                                          <div className={styles.test_order_result}>
                                            适用范围：{child.useDesc ? child.useDesc : "--"}
                                          </div>
                                        </div>
                                        <div className={styles.test_order_price}>
                                          {!child.giftFlag && (
                                            <div>
                                              ￥{child.num * child.price}
                                            </div>
                                          )}
                                          <span>x{child.num}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              })}
                              {item.exchangeAfter && item.exchangeAfter.map((child:any)=>{
                                return (
                                  <div className={styles.test_present}>
                                    <span style={{color:"red", marginRight:"5px",fontSize:"12px"}}>换</span>
                                    <span style={{fontSize:"12px"}}>{child.couponName} X {child.size}</span>
                                  </div>
                                )
                              })}
                              {item.exchangeBefore && item.exchangeBefore.map((child:any)=>{
                                return (
                                  <div className={styles.test_present}>
                                    <span style={{color:"rgb(128, 128, 128)", marginRight:"5px",fontSize:"12px"}}>销</span>
                                    <span style={{color:"rgb(128, 128, 128)", marginRight:"5px",fontSize:"12px"}}>{child.couponName} X {child.size}</span>
                                  </div>
                                )
                              })}
                            </div>
                            <div className={styles.test_order_footer}>
                              <div style={{fontWeight:"400",fontSize:"13px"}}>
                                下单时间：{item.createdAt ? dateFormat(item.createdAt,"yyyy-MM-dd hh:mm:ss") : "-"}
                              </div>
                              <div>
                                合计：<span>￥{item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className={styles.order_list_content_foot}>
                            <div className={styles.bd}>BD：{item.bdName}</div>
                            <div className={styles.order_list_content_footer}>
                              {((position && position.indexOf("大区") > -1) || (batchBuyAuthority && batchBuyAuthority === "99") )  && checkKeyValue != "8" && (
                                <div
                                  className={styles.order_list_content_footer_1}
                                  onClick={() => {
                                    history.push("./collection-details",item)
                                  }}
                                >
                                  {item.payStatus === 7 ? "审核" : "订单详情"}
                                </div>
                              )}
                              {item.payStatus === 1 && item.batchBuyStatus != 1 && (
                                <div
                                  className={styles.order_list_content_footer_0}
                                  onClick={() => {
                                    Modal.confirm({
                                      content: '确重新发卷',
                                      onConfirm: () => {
                                        onRecurl(item.batchBuyNo)
                                      },
                                    })
                                  }}
                                >
                                  重新发卷
                                </div>
                              )}
                              {/* {item.imgProof && (
                                <div
                                  className={styles.order_list_content_footer_0}
                                  onClick={() => {
                                    // Modal.show({
                                    //   closeOnMaskClick: true,
                                    //   content: (
                                    //     <div className={styles.batchBuyNo}>
                                    //       <img onClick={()=>{
                                    //         setImages(item.imgProof)
                                    //         setImageVisible(true)
                                    //       }} style={{maxHeight:"50vh",width:"100%"}} src={item.imgProof} alt="" />
                                    //       <div>订单编号:{item.batchBuyNo}</div>
                                    //     </div>
                                    //   )
                                    // });
                                    setImages(item.imgProof)
                                    setImageVisible(true)
                                  }}
                                >
                                  查看凭证
                                </div>
                              )} */}
                              {batchBuyAuthority && batchBuyAuthority === "99" && item.payStatus === 5 && (
                                <div
                                  className={styles.order_list_content_footer_1}
                                  onClick={() => {
                                    Modal.confirm({
                                      title: '确认审核通过？',
                                      closeOnMaskClick: true,
                                      content: (
                                        <div className={styles.batchBuyNo}>
                                          <div className={styles.batchBuyNo_img}>
                                            {item.imgProof && item.imgProof.length > 0 && item.imgProof.map((img:any)=>{
                                              return (
                                                <div className={styles.batchBuyNo_img_0}>
                                                  <img onClick={()=>{
                                                    setImages(img)
                                                    setImageVisible(true)
                                                  }} style={{width:"50px",height:"50px"}} src={img} alt="" />
                                                </div>
                                                )
                                            })}
                                          </div>
                                          <div>订单编号:{item.batchBuyNo}</div>
                                        </div>
                                      ),
                                      onConfirm:()=>{
                                        onFinancialConfirm(item.batchBuyNo)
                                      }
                                    });
                                  }}
                                >
                                  财务审核
                                </div>
                              )}
                              {batchBuyAuthority && batchBuyAuthority === "99" && item.payStatus === 5 && (
                                <div
                                  className={styles.order_list_content_footer_0}
                                  onClick={() => {
                                    Modal.confirm({
                                      title: '确认驳回订单？',
                                      closeOnMaskClick: true,
                                      content: (
                                        <div className={styles.batchBuyNo}>
                                          <img onClick={()=>{
                                            setImages(item.imgProof)
                                            setImageVisible(true)
                                          }} style={{maxHeight:"50vh",width:"100%"}} src={item.imgProof} alt="" />
                                          <div>订单编号:{item.batchBuyNo}</div>
                                          <Form form={formFinancialRefuse}>
                                            <Form.Item
                                              name="forms"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: '请输入取消原因',
                                                },
                                              ]}
                                              className={styles.form_item}
                                            >
                                              <TextArea
                                                placeholder="请输入取消原因"
                                                maxLength={100}
                                                rows={5}
                                                showCount
                                                onChange={(v) => {
                                                }}
                                              />
                                            </Form.Item>
                                          </Form>
                                        </div>
                                      ),
                                      onConfirm:()=>{
                                        onFinancialRefuse(item.batchBuyNo)
                                      }
                                    });
                                  }}
                                >
                                  驳回
                                </div>
                              )}
                              {((item.payStatus ===  0) ||(item.payStatus ===  5) || (item.payStatus === 6)) && 
                                <div
                                  className={styles.order_list_content_footer_1}
                                  onClick={() => {
                                    setcredentialsVisible(true)
                                    setOrderInformation(item)
                                  }}
                                >
                                  支付凭证
                                </div>
                                }
                              {((item.payStatus ===  0) || (item.payStatus === 6)) && (
                                <>
                                  <div
                                    className={styles.order_list_content_footer_0}
                                    onClick={() => {
                                      setVisibleDetete(true)
                                      setBatchBuyNo(item.batchBuyNo)
                                    }}
                                  >
                                    取消订单
                                  </div>
                                  {/* {checkKeyValue != "8" && ( */}
                                    <div
                                      className={styles.order_list_content_footer_1}
                                      onClick={() => {
                                        //console.log(item);
                                        setQRCodeVisible(true)
                                        setBatchBuyNoDetail(item)
                                        setDeatilAllPrice(item.price)
                                      }}
                                    >
                                      <ReceivePaymentOutline fontSize={16}/> <span>收款码</span>
                                    </div>
                                  {/* )} */}
                                </>
                              )}
                            </div>
                          </div>
                        </Card>
                      )
                    }
                  }) }
                </PullToRefresh>
                <InfiniteScroll 
                  loadMore={async () => {
                    if (checkState.check) {
                      return;
                    }
                    setParams({
                      pageNo: params.pageNo + 1,
                      pageSize: 20
                    })
                  }}
                  hasMore={hasMore} 
                />
              </div>
            </div>
          </div>
        )}
        {activeKey === 1 && (
          <>
          {((giftList.length === 0 && priceNum >= 3000) || (giftList.length > 0 && priceNum >= 5000)) && ( 
            <div className={styles.footer_imageShop}>
              <Checkbox defaultChecked={imageShop} onChange={(v)=>{
                setImageShop(v)
              }}>
                申请形象店
              </Checkbox>
              <Popover
                content='形象店需要满足一定检测量'
                trigger='click'
                placement='top'
              >
                <img src={error}/>
              </Popover>
            </div>
          )}
          <div className={styles.footer}>
            <Button
              color="primary"
              fill="outline"
              onClick={() => {
                onReset()
              }}
            >
              重置
            </Button>
            <Button
              size="middle"
              color="primary"
              onClick={async() => {
                await onFinish()
              }}
            >
              提交订单
            </Button>
          </div>
          </>
        )}
        {activeKey === 3 && (
          <div className={styles.content_0} >
            <div className={styles.packages}>
              <div className={styles.company} onClick={()=>{
                  setRewindVisible(true)
                }}>
                <img height={40} src={rewindCompany.doorHeadPhoto || initMerchant} alt="" />
                <div className={styles.company_name}>
                  <div>
                    <a style={{color:"#222222"}}>{rewindCompany.companyName || "请选择车商"}</a>
                  </div>
                  <div className={styles.company_name_0}>{rewindCompany.contactMobileNo || "--"} | {rewindCompany.belongMarketName || "--"}</div>
                </div>
                <div className={styles.back}>
                  <img src={返回} alt="" />
                </div>
              </div>
              <div className={styles.company_rewind}>
                {rewindList && rewindList.length > 0 &&
                  <CheckList multiple={true} value={rewindCheckList} onChange={onRewindChange}>
                    <Tabs>
                      {rewindList.map((item:any,index:number)=>{
                        return (
                          <Tabs.Tab title={item.name} key={index}>
                            {item.list && item.list.length > 0 && item.list.map((child:any,childIndex:number)=>{
                              return(
                                <CheckList.Item value={`${child.couponGroupId}++${childIndex}++${item.name}++${child.batchBuyPrice}`} disabled={child.exchangeCouponFlag ? false : true}>
                                  <div className={styles.test}>
                                    <div className={styles.test_order}>
                                      <img src={child.imgUrl && child.imgUrl} alt="" />
                                      <div className={styles.test_order_0}>
                                        <div className={styles.test_order_title}>{child.couponName}</div>
                                        <div className={styles.test_order_tabs}>
                                          {child.couponName && child.couponName != "车辆批发检" &&(
                                            <>
                                            {(child.couponName === "事故排查检" || child.couponName.indexOf("豪车")> -1) ? <span>百天回购</span> :<span>终身回购</span>}
                                            </>
                                          )}
                                          <span>检测报告</span>
                                        </div>
                                        <div className={styles.test_order_result}>
                                          使用范围：{child.useDesc || "--"}
                                        </div>
                                        <div className={styles.test_order_price}>
                                          <div>
                                            {child.exchangeCouponFlag ? <>￥<span>{child.batchBuyPrice}</span></> : <span style={{fontSize:"14px",color:"rgb(213, 213, 213)"}}>不可换卷</span>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CheckList.Item>
                              )
                            })}
                          </Tabs.Tab>
                        )
                      })}
                    </Tabs>
                  </CheckList>
                }
              </div>
              <div className={styles.footer} style={{padding:"5px 25px",justifyContent:"space-between"}}>
                <div>
                  {/* 已选：{checkSizeList && checkSizeList.length > 0 && checkSizeList.map((item:any)=>{
                    return <div>{item.name}:{item.size}个</div>
                  })} */}
                  
                  <Popover
                    content={
                      <>
                        {checkSizeList && checkSizeList.length > 0 && checkSizeList.map((item:any)=>{
                          return <div>{item.name}:{item.size}个</div>
                        })}
                      </>
                    }
                    trigger='click'
                    placement='top'
                  >
                    <Badge content={rewindCheckList.length || 0}>
                      <div onClick={()=>{

                      }}>已选</div>
                    </Badge>
                  </Popover>
                </div>
                <div className={styles.button} >
                  <Button size='middle' color='primary' onClick={()=>{
                    onRewindSubmit(rewindCheckList)
                  }}>
                    确定
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeKey === 4 && (
          <>
            <div className={styles.title}>
              <div className={styles.search}>
                <SearchBar
                  onSearch={onSearchCoupon}
                  style={{width:"100%"}}
                  placeholder="搜索手机号或城市或产品名称"
                />
              </div>
            </div>
            <div className={styles.list}>
              {list && list.length > 0 && list.map((item: any, index: number) => {
                return (
                  <Card key={index} style={{ borderRadius: '4px' }}>
                    <div className={styles.title}>
                      <div style={{ fontSize: '14px' }}>
                        产品名称: {item.couponName}{' '}
                        <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                          剩余：{item.remain} 已使用：{item.used}
                        </span>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={styles.content}>
                        <div>
                          用户：
                          {item.companyName ? item.companyName : '-'}
                        </div>
                        <div>城市：{item.city ? item.city : '-'}</div>
                        <div>
                          手机号：
                          <a href={`tel:${item.companyPhone}`}>
                            {item.companyPhone ? item.companyPhone : '-'}
                          </a>
                        </div>
                        <div>市场：{item.market ? item.market : '-'}</div>
                        <div>业务员：{item.staffName ? item.staffName : '-'}</div>
                        {/* <div>
                          本月消耗台次：{item.monthUsedNum ? item.monthUsedNum : '-'}
                        </div>
                        <div>
                          车位数：{item.parkingSpaces ? item.parkingSpaces : '-'}
                        </div> */}
                        <div>
                          发放时间：
                          {item.createTime
                            ? dayjs(item.createTime).format('YYYY-MM-DD HH:mm')
                            : '未查询到时间'}
                        </div>
                        <div>
                          领取时间：
                          {item.lastCouponTakeTime
                            ? dayjs(item.lastCouponTakeTime).format(
                                'YYYY-MM-DD HH:mm',
                              )
                            : '未查询到时间'}
                        </div>

                        <div style={{ textAlign: 'right'}}>
                          <Tag color="#305FCC" style={{ marginLeft: '3px' }}onClick={() => {
                                // couponRecord(item);
                                setQueryCoupon(item)
                              }}>
                            {/* <span
                              onClick={() => {
                                // couponRecord(item);
                              }}
                            >
                              
                            </span> */}
                              库存信息
                          </Tag>
                          {/* <Tag
                            color="#305FCC"
                            fill="outline"
                            style={{ marginLeft: '3px' }}
                          >
                            <span
                              onClick={() => {
                                history.push('./placeAnOrder');
                              }}
                            >
                              预约下单
                            </span>
                          </Tag> */}
                          <Tag color="#305FCC" fill="outline" style={{ marginLeft: '3px' }} onClick={() => {
                            onSubmit(item);
                          }}>
                            全部核销
                          </Tag>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </>
        )
        }
        <div className={styles.footer}>
          {tabs.map((item:any)=>{
            return (
              <div 
                className={styles.footer_0}
                onClick={()=>{
                  setActiveKey(item.key)
                  setcheckKeyValue("-1")
                  localStorage.setItem("activeKey",item.key)
                }}
              >
                <img src={activeKey === item.key ? item.img : item.icon} alt="" />
                <div>{item.title}</div>
              </div>
            )
          })}
        </div>
      </div>
      <Merchant
        visit={true}
        cityFlag={false}
        checkFlag={false}
        nameVisible={nameProposeVisible}
        onSubmit={handelProposeName}
        onCancel={handleProposeCancel} ButtonFlag={false} />
      <Merchant
        visit={true}
        cityFlag={false}
        checkFlag={false}
        nameVisible={nameVisible}
        onSubmit={handelUserName}
        onCancel={handleUserNameCancel} ButtonFlag={false} />
      <MerchantRewind
        visit={true}
        cityFlag={false}
        checkFlag={false}
        nameVisible={rewindVisible}
        onSubmit={handelRewind}
        onCancel={handleRewindCancel} ButtonFlag={false} />
      <CollectCkecked
        checkFlag={false}
        modelFlag ={false}
        contactCityName={contactCityName}
        contactCityCode={contactCityCode}
        nameVisible={collectVisible}
        onSubmit={handelGroupName}
        onCancel={handleGroupCancel}
      />
      <RewindCkecked
        checkFlag={false}
        modelFlag ={true}
        contactCityName={rewindCompany.contactCityName}
        contactCityCode={rewindCompany.contactCityCode}
        nameVisible={collectRewindVisible}
        onSubmit={handelRewindName}
        onCancel={handleRewindNameCancel}
      />
      <Market
        nameVisible={marketVisible}
        onSubmit={handelUserMarket}
        onCancel={handleUserMarketCancel}
        value={false} list={undefined}      />
      <Modal
        visible={recordVisible}
        closeOnAction
        title={'订单状态'}
        content={
          <Form form={recordForm}>
            <List>
              <List.Item prefix="是否仅记录">
                <Form.Item
                  name="onlyRecordFlag"
                  rules={[{ required: true, message: '选择是否仅记录订单' }]}
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={1}>不发卷</Radio>
                    <Radio value={0}>发卷</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setRecordVisible(false);
          setFlag(true)
        }}
        showCloseButton
        onAction={() => {
          setFlag(false)
          recordForm.validateFields().then(() => {
            setFlag(true)
            onSubmitGrounp();
          })
          .catch(()=>{
            setFlag(true)
            setRecordVisible(true)
          })
        }}
      ></Modal>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        position="right"
        bodyStyle={{ width: '100%' }}
      >
        <div style={{ width: 'inherit' }}>
          <TreeSelect
            options={options}
            onChange={(value, nodes) => {
              setCityCode(nodes.options);
            }}
          />
          <div className={styles.time_button}>
            <Button
              color="primary"
              fill="outline"
              onClick={() => {
                setVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="middle"
              color="primary"
              onClick={() => {
                if (cityCode.length === 1) {
                  Toast.show({
                    content: '请选择城市',
                  });
                  return;
                }else{
                  if (cityCode && cityCode.length > 1 && cityCode[1].value){
                    let params:any = {}
                    params.cityCode = cityCode[1].value
                    initValue(params)
                    setContactCityCode(cityCode[1].value)
                  }
                }
                setVisible(false);
              }}
            >
              确定
            </Button>
          </div>
        </div>
      </Popup>
      <Popup
        visible={QRCodeVisible}
        onMaskClick={() => {
          setQRCodeVisible(false);
          setQRFlag(false)
        }}
        onClose={() => {
          setQRCodeVisible(false);
          setQRFlag(false)
        }}
        showCloseButton
        position="right"
        bodyStyle={{ width: '100%' ,background:"#1D6AF8"}}
      >
        <div style={{ width: 'inherit' }}>
          <div className={styles.qrcode_detail}>
            <div ref={ref}>
              {!QRFlag && (
                <div style={{width:"100%",background:"white",borderRadius:"10px"}}>
                  <div>
                    南京维真信息技术有限公司
                  </div>
                  <div>
                    ￥<span>{deatilAllPrice}</span>
                  </div>
                  <QRCode
                    value={batchBuyNoDetail.exchangeNo ? `https://bd.wzyanche.com/rewindOrder/${batchBuyNoDetail.exchangeNo}` : `https://bd.wzyanche.com/order/${batchBuyNoDetail.batchBuyNo}`}
                    // value={'https://bd.wzyanche.com/pamphlets/2'}
                    // size={300}
                    style={{width:"78%",height:"auto"}}
                    fgColor="#000000" 
                    // imageSettings={{
                    //   src: logo ,
                    //   height: 30,
                    //   width: 30,
                    //   excavate: true
                    // }}
                  />
                  <div className={styles.footer}>
                    <img src={微信} alt="" />
                    <img src={支付宝} alt="" />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.footer_button}>
              <div
                className={styles.order_list_content_footer_0}
                onClick={() => {
                  setQRCodeVisible(false);
                  setQRFlag(false)
                }}
              >
                返回
              </div>
              {/* <div
                className={styles.order_list_content_footer_1}
                onClick={() => {
                  if(isIOS()){
                    onButtonClickIOS()
                  }else{
                    onButtonClick(batchBuyNoDetail.batchBuyNo)
                  }
                }}
              >
                保存二维码
              </div> */}
            </div>
          </div>
        </div>
      </Popup>
      <Modal
        visible={visibleDetete}
        bodyClassName={styles.model_botton}
        content={
          <div className={styles.forms}>
            <Form form={formDelete}>
              <Form.Item
                name="forms"
                rules={[
                  {
                    required: true,
                    message: '请输入取消原因',
                  },
                ]}
                className={styles.form_item}
              >
                <TextArea
                  placeholder="请输入取消原因"
                  maxLength={100}
                  rows={5}
                  showCount
                  onChange={(v) => {
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        }
        closeOnAction
        onClose={() => {
          setVisibleDetete(false)
        }}
        actions={[
          {
            key: 'online',
            text: '取消',
            className: styles.online
          },
          {
            key: 'confirm',
            text: '确定',
            onClick: ()=>{
              onDelete(batchBuyNo);
            },
            className: styles.confirm
          },
        ]}
      />
      <Modal
        visible={credentialsVisible}
        closeOnMaskClick
        title={"支付凭证"}
        content={
          <div className={styles.batchBuyNo}>
            <div className={styles.batchBuyNo_img}>
              {imgProofList && imgProofList[orderInformation.batchBuyNo] &&imgProofList[orderInformation.batchBuyNo].map((img:any)=>{
                return (
                  <div className={styles.batchBuyNo_img_0}>
                    <Badge color='#ACACAC' content={<span onClick={()=>{
                      if(imgProofList && imgProofList[orderInformation.batchBuyNo]){
                        let list:any = []
                        imgProofList[orderInformation.batchBuyNo].map((imgClick:any)=>{
                          if(imgClick != img){
                            list.push(imgClick)
                          }
                        })
                        //console.log(list)
                        imgProofList[orderInformation.batchBuyNo] = list
                        setImgProofList({...imgProofList})
                      }
                    }}>x</span>}>
                      <img onClick={()=>{
                        setImages(img)
                        setImageVisible(true)
                      }} src={img} alt="" />
                    </Badge>
                  </div>
                  )
              })}
              <div
                className={styles.increaseIcon}
                onClick={() => {
                  if(checkKeyValue == "8"){
                    wxShareRewindConfig(orderInformation.batchBuyNo, imgProofList[orderInformation.batchBuyNo])
                  }else{
                    wxShareConfig(orderInformation.batchBuyNo, imgProofList[orderInformation.batchBuyNo])
                  }
                }}
              >
                <CameraOutline fontSize={20} />
                <div style={{ marginTop: '2px' }}>照片</div>
              </div>
            </div>
            {orderInformation.batchBuyNo &&
              <div>订单编号:{orderInformation.batchBuyNo}</div>
            }
          </div>
        }
        closeOnAction
        onClose={() => {
          setcredentialsVisible(false)
        }}
        actions={[
        ]}
      />
      <ImageViewer
        image={Images}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />
      <Popup
        visible={formRewindVisible}
        showCloseButton
        onClose={() => {
          setFormRewindVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '30vh',
          padding: '20px',
        }}
        bodyClassName={styles.formRewind}
      >
        <Form form={formRewind} layout='horizontal' mode='card' onValuesChange={onRewindValuesChange}>
          <Form.Item 
            className={styles.header}
            label={"更换产品"} 
            name="couponGroupId"
            style={{display:"none"}}
            >
            <Input/>
          </Form.Item>
          <Form.Item 
            className={styles.header}
            label={"更换产品"} 
            name="couponGroupName"
            disabled
            >
            <Input/>
          </Form.Item>
          <Form.Item 
            className={styles.header}
            label={"使用范围"} 
            name="useDesc"
            disabled
            >
            <Input/>
          </Form.Item>
          <Form.Item 
            className={styles.header}
            label={"价格"} 
            name="price"
            disabled
            >
            <Input/>
          </Form.Item>
          <Form.Item
            label="数量" 
            name="size"
            className={styles.form_num_0}
            >
            <Stepper min={1} max={spreadMax}/>
          </Form.Item>
          <Form.Item 
            className={styles.header_0}
            label={"差价"} 
            name="spread"
            extra={"￥"}
            // disabled
            >
            <Input disabled/>
          </Form.Item>
        </Form>
        <div className={styles.button_4}>
          <Button
            block
            type="submit"
            color="primary"
            onClick={async () => {
              await onRewindFinish();
            }}
          >
            更换
          </Button>
        </div>
      </Popup>
      {/* <Popup
        visible={formRewindVisible}
        showCloseButton
        onClose={() => {
          setFormRewindVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '30vh',
          padding: '20px',
        }}
        bodyClassName={styles.formRewind}
      >
      <PullToRefresh>
        <div className={styles.list}>
        <CheckList multiple={true} value={listValue} onChange={onCheckChange}>
          {list && list.length > 0 && list.map((item: any, index: number) => {
            if(item.couponIsUsed != 1){
              return (
              <CheckList.Item value={`${item.id}`}>
                <Card key={index} style={{ borderRadius: '4px' ,}} >
                  <div className={styles.title} >
                    <div style={{ fontSize: '14px' }}>产品名称: {item.couponName} <span style={{marginLeft:"5px"}}>id:{item.id}</span></div>
                  </div>
                  <div className={styles.detail}>
                    <div className={styles.content}>
                      <div>
                        用户：{queryCoupon.companyName != "undefined" ? queryCoupon.companyName : "-" }
                      </div>
                      <div>
                        是否领取：{item.couponIsTake === 1 ? "未领取" : '已领取'}
                      </div>
                      <div>
                        是否使用：{item.couponIsUsed === 1 ? "已使用" : '未使用'}
                      </div>
                      <div>
                        用户领取时间：
                        {item.couponTakeTime
                          ? dayjs(item.couponTakeTime).format('YYYY-MM-DD HH:mm')
                          : '未查询到领取时间'}
                      </div>
                      <div>
                        用户使用时间：
                        {item.couponUseTime
                          ? dayjs(item.couponUseTime).format('YYYY-MM-DD HH:mm')
                          : '未查询到使用时间'}
                      </div>
                      <div>
                        使用的订单号：{item.useOrderNo ? item.useOrderNo : '-'}
                      </div>
                      <div style={{ textAlign: 'right',position:'absolute',right:"5%",bottom:"0" }}>
                        <Tag color="#305FCC" fill="outline" style={{ marginLeft: '3px' }}>
                          <span onClick={()=>{
                          }}>查看二维码</span>
                        </Tag>
                      </div>
                    </div>
                  </div>
                </Card>
                </CheckList.Item>
              );
            }
          })}
          </CheckList>
        </div>
      </PullToRefresh>
        <div className={styles.footer}>
          <div>
            已选：{listValue.length}/{listLength} 个
          </div>
          <div className={styles.button} >
            <Button color='primary' fill='outline' onClick={()=>{
              // onCancel()
              // back()
            }}>
              取消
            </Button>
            <Button size='middle' color='primary' onClick={()=>{
              onSubmit(listValue)
            }}>
              确认核销
            </Button>
          </div>
        </div>
      </Popup> */}
    </div>
  )
}

export default Collect;