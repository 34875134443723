// @ts-ignore
/* eslint-disable */
import {
  Button,
  Card,
  Checkbox,
  CheckList,
  DatePicker,
  Form,
  Image,
  ImageUploadItem,
  ImageViewer,
  Input,
  List,
  Modal,
  Picker,
  Popup,
  Radio,
  SpinLoading,
  Stepper,
  Tabs,
  Tag,
  TextArea,
  Toast,
} from 'antd-mobile/es';
import { Children, useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import {
  degreeIntentList,
  displayTypeList,
  mediaLists,
  newVisitResultList,
  reasonsForFailure,
  reasonsForFailureAll,
  reasonsForFailureNew,
  serveTypeList,
  strangerVisitResultList,
  visitResultList,
  visitSignTypeList,
} from '../utils/signIn';
import {
  checkInDetails,
  getAddress,
  orglist,
  visitDetail,
  save,
  saveCall,
  saveComany,
} from './service';
import dayjs from 'dayjs';
import { history, useLocation } from 'umi';
import { labellist, remarkLists, typelist } from '../programme/service';
import { getWxAccessToken } from '../login/service';
import AddOutline from 'antd-mobile-icons/es/AddOutline';
import Item from 'antd-mobile/es/components/dropdown/item';
import callOnSb from '../callOnSb';
import { dateFormat } from '../utils/util';
import { sleep } from 'antd-mobile/es/utils/sleep';
import Login from '../login';
import { getMCarDealerVisitVo } from '../details/service';
import Merchant from '../comments/Merchant';
import Market from '../comments/Market';
import { parseResultName } from '../PayAVisit/newsPlan';
import MyPie from '../programme/common/MyPie';

export const isIOS = () => {
  var ua = navigator.userAgent.toLocaleLowerCase();
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  return isIOS;
};
const dateJs = () => {
  const year = dayjs().year();
  const month = dayjs().month() + 1;
  const date = dayjs().date() + 1;
  return new Date(`${year}-${month}-${date} 00:00:00`);
};
export const base64ImgtoFile = (dataurl: string, filename = 'file') => {
  let arr = dataurl.split(',');
  // @ts-ignore
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split('/')[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
};
export default function See() {
  const [form] = Form.useForm();
  const [formCall] = Form.useForm();
  const [formComany] = Form.useForm();
  const [partnerform] = Form.useForm();
  const [mediaform] = Form.useForm();
  const [formPrice] = Form.useForm();
  const [testOrgIdform] = Form.useForm();
  const [remarkform] = Form.useForm();
  const [serveTypeVisible, setserveTypeVisible] = useState<boolean>(false);
  const [visitSignTypeVisible, setvisitSignTypeVisible] =
    useState<boolean>(false);
  const [visitResultVisible, setvisitResultVisible] = useState<boolean>(false);
  const [visitList, setVisitList] = useState<any>([]);
  const [visitReasonsVisible, setvisitReasonsVisible] =
    useState<boolean>(false);
  const [displayTypeVisible, setdisplayTypeVisible] = useState<boolean>(false);
  const [outerMerchant, setouterMerchant] = useState<string>('');
  const [nameVisible, setNameVisible] = useState<boolean>(false);
  const [marketVisible, setMarketVisible] = useState<boolean>(false);
  const [timeVisible, setTimeVisible] = useState<boolean>(false);
  const [detectOrgNameVisible, setdetectOrgNameVisible] =
    useState<boolean>(false);
  const [visible4, setVisible4] = useState<boolean>(false);
  const [mediaVisible, setmediaVisible] = useState<boolean>(false);
  const [priceVisible, setPriceVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visible2, setVisible2] = useState<boolean>(false);
  const [visible3, setVisible3] = useState<boolean>(false);
  const [serveType, setserveType] = useState<any>([]);
  const [testOrgId, settestOrgId] = useState<any>([]);
  const [visitSignType, setvisitSignType] = useState<any>([]);
  const [visitResult, setvisitResult] = useState<any>(localStorage.getItem("visitResult"));
  const [displayType, setdisplayType] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [mediaList, setmediaList] = useState<any>([]);
  const [testOrgList, settestOrgList] = useState<any>([]);
  // const [belongMarketId, setbelongMarketId] = useState<number>(-1);
  const [mediaType, setmediaType] = useState<any>([]);
  const [orgPicket, setorgPicket] = useState<any>([]);
  // const [companyId, setCompanyId] = useState<any>([]);
  const [visitContent, setvisitContent] = useState<string>('');
  const [visitSummary, setVisitSummary] = useState<string>('');
  const [regionAnnotations, setregionAnnotations] = useState<string>('');
  const [breakThroughLogic, setBreakThroughLogic] = useState<string>('');
  const [MeritList, setMeritList] = useState<any>([]);
  const [remarkValue, setRemarkValue] = useState<string>('');
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [remark, setRemark] = useState<any>([]);
  const [carLabelIds, setcarLabelIds] = useState<any>([]);
  const [doorway, setDoorway] = useState<any>([]);
  const [point, setPoint] = useState<any>([]);
  const [advertise, setadvertise] = useState<any>([]);
  const [powerModel, setpowerModel] = useState<any>([]);
  const [initMeritList, setInitMeritList] = useState<any>([]);
  const [PremiumImgs, setPremiumImg] = useState<any>(
    localStorage.getItem('premiumImg'),
  );
  const [appointmentVisitScreenshot, setappointmentVisitScreenshot] = useState<any>(
    localStorage.getItem('premiumImg'),
  );
  const [priceValue, setPriceValue] = useState<any[]>([]);
  const [initcarLabelIds, setinitcarLabelIds] = useState<any>([]);
  const [localIdsImgs, setlocalIdsImg] = useState<string | null>(
    localStorage.getItem('localIdsImg'),
  );
  const [pointImgs, setpointImg] = useState<string | null>(
    localStorage.getItem('pointImg'),
  );
  const [advertiseImgs, setadvertiseImg] = useState<string | null>(
    localStorage.getItem('advertiseImg'),
  );
  const [powerModelImgs, setpowerModelImg] = useState<string | null>(
    localStorage.getItem('powerModelImg'),
  );
  const [address, setAddress] = useState<string>('');
  const [partnerNumber, setpartnerNumber] = useState<number>(0);
  const [mediaNumber, setmediaNumber] = useState<number>(0);
  const [testOrgNumber, settestOrgNumber] = useState<number>(0);
  const [ids, setIds] = useState<number>();
  const [edit, setEdit] = useState<number>();
  const [editId, setEditId] = useState<number>();
  const [allImage, setAllImage] = useState<any>([]);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [typeList, setTypeList] = useState<any>([]);
  const [redioFlag, setRedioFlag] = useState<any>(1);
  const [redioFlag2, setRedioFlag2] = useState<any>(1);
  const [redioFlag3, setRedioFlag3] = useState<any>(1);
  const [moreMap, setMoreMap] = useState<any>({});
  const avatar = localStorage.getItem('avatar');
  const [companyDeatail, setCompanyDeatail] = useState<any>([]);
  const [degreeIntentVisible, setdegreeIntentVisible] =
    useState<boolean>(false);
  const [newCarDealers, setNewCarDealers] = useState<boolean>(true);
  const [degreeIntent, setdegreeIntent] = useState<number>();
  const [recommendationsVisible, setRecommendationsVisible] =
    useState<boolean>(false);
  const [recommendationsList, setrecommendationsList] = useState<any>([]);
  const [scoreList, setScoreList] = useState<any>([]);
  const location = useLocation();
  const dataFlag: any = location.state;
  const belongMarketId = localStorage.getItem('belongMarketId');
  const localIdsImg = localStorage.getItem('localIdsImg');
  const pointImg = localStorage.getItem('pointImg');
  const advertiseImg = localStorage.getItem('advertiseImg');
  const powerModelImg = localStorage.getItem('powerModelImg');
  const premiumImg = localStorage.getItem('premiumImg');
  const [checkCollect, setCheckCollect] = useState<any>([])
  const [collectVisible, setCollectVisible] = useState<boolean>(false);
  const [nextVisitTimeVisible, setnextVisitTimeVisible] = useState<boolean>(false);
  const infoRef: any = useRef([]);
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年';
      case 'month':
        return data + '月';
      default:
        return data;
    }
  }, []);
  const offeringsList = [
    "基础检测",
    "升级检测",
    "豪车检测",
    "数据车况",
  ]
  const onChanger = (v: any) => {
    console.log(v);
    setRedioFlag(v);
  };
  const onChanged = (v: any) => {
    console.log(v);
    setRedioFlag2(v);
  };
  const onChangec = (v: any) => {
    console.log(v);

    setRedioFlag3(v);
  };
  // 异地推荐
  const goTobackRecommendations = () => {
    setRecommendationsVisible(true);
  };
  const handleRecommendationsCancel = async () => {
    setRecommendationsVisible(false);
  };
  const handelrecommendationsUserName = async (value: any) => {
    console.log(value);
    if (value.length > 0) {
      value.map((item: any) => {
        let arr = item.split('===');
        console.log(arr);
        formComany.setFieldValue('recommendations', arr[0]);
        setrecommendationsList({ companyId: arr[1], companyName: arr[0] });
      });
    }
    onValuesChange();
    setRecommendationsVisible(false);
  };
  const addressList = async (latitude: any, longitude: any) => {
    const msg = await getAddress({
      lat: latitude,
      lng: longitude,
    });
    if (msg.retCode === 0) {
      console.log(msg.data.result);
      setAddress(msg.data.result);
      form.setFieldValue('address', msg.data.result);
    }
  };
  const wxShareConfigs = async () => {
    // setUserId(["ChenHong"])
    // callOnBD(["ChenHong"])
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/",
    });
    console.log(wx);
    if (msg.retCode === 0) {
      await wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['getLocation'],
      });
      await wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['getLocation'],
        success: function (res) {
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: async function (res) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed; // 速度，以米/每秒计
          var accuracy = res.accuracy; // 位置精度
          addressList(latitude, longitude);
        },
      });
    }
  };
  const wxShareConfig = async (type: number) => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
        success: function (res) {
          // 回调
        },
      });
      wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function (res) {
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'normal', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds[0];
          wx.uploadImage({
            localId: localIds.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
              var serverId = res.serverId; // 返回图片的服务器端ID
              console.log(serverId);
              if (type === 1) {
                // setDoorway({
                //   mediaId: serverId,
                //   urlName: '门头近照',
                //   urlType: type,
                // });
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '门头近照',
                  urlType: type,
                });
                localStorage.setItem('doorway', list);
                // infoRef.current.doorway = {
                //   mediaId: serverId,
                //   urlName: '门头近照',
                //   urlType: type,
                // }
                // localStorage.setItem("doorway")
              }
              if (type === 2) {
                // setPoint({
                //   mediaId: serverId,
                //   urlName: '门头含车位',
                //   urlType: type,
                // });
                // infoRef.current.point = {
                //   mediaId: serverId,
                //   urlName: '门头含车位',
                //   urlType: type,
                // }
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '门头含车位',
                  urlType: type,
                });
                localStorage.setItem('point', list);
              }
              if (type === 3) {
                // setadvertise({
                //   mediaId: serverId,
                //   urlName: '竟对广告',
                //   urlType: type,
                // });
                // infoRef.current.advertise = {
                //   mediaId: serverId,
                //   urlName: '竟对广告',
                //   urlType: type,
                // }
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '竟对广告',
                  urlType: type,
                });
                localStorage.setItem('advertise', list);
              }
              if (type === 4) {
                // setpowerModel({
                //   mediaId: serverId,
                //   urlName: '动力型车型',
                //   urlType: type,
                // });
                // infoRef.current.powerModel = {
                //   mediaId: serverId,
                //   urlName: '动力型车型',
                //   urlType: type,
                // }
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '动力型车型',
                  urlType: type,
                });
                localStorage.setItem('powerModel', list);
              }
              if (type === 5) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '高配版车型',
                  urlType: type,
                });
                localStorage.setItem('premium', list);
              }
              if (type === 6) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '预约拜访截图',
                  urlType: type,
                });
                localStorage.setItem('appointmentVisitScreenshot', list);
              }
            },
            errMsg: function (res) {
              Toast.show({
                icon: 'fail',
                content: '上传失败',
              });
              return;
            },
          });
          if (isIOS() === true) {
            wx.getLocalImgData({
              localId: localIds, // 图片的localID
              success: function (res) {
                var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                if (type === 1) {
                  setlocalIdsImg(localData);
                  localStorage.setItem('localIdsImg', localData);
                  infoRef.current.localIdsImg = localData;
                }
                if (type === 2) {
                  setpointImg(localData);
                  localStorage.setItem('pointImg', localData);
                  infoRef.current.pointImg = localData;
                }
                if (type === 3) {
                  setadvertiseImg(localData);
                  localStorage.setItem('advertiseImg', localData);
                  infoRef.current.advertiseImg = localData;
                }
                if (type === 4) {
                  setpowerModelImg(localData);
                  localStorage.setItem('powerModelImg', localData);
                  infoRef.current.powerModelImg = localData;
                }
                if (type === 5) {
                  setPremiumImg(localData);
                  localStorage.setItem('premiumImg', localData);
                  infoRef.current.premiumImg = localData;
                }
                if (type === 6) {
                  setappointmentVisitScreenshot(localData);
                  localStorage.setItem('appointmentVisitScreenshot', localData);
                  infoRef.current.appointmentVisitScreenshot = localData;
                }
              },
            });
          } else {
            if (type === 1) {
              setlocalIdsImg(localIds);
              localStorage.setItem('localIdsImg', localIds);
              infoRef.current.localIdsImg = localIds;
            }
            if (type === 2) {
              setpointImg(localIds);
              localStorage.setItem('pointImg', localIds);
              infoRef.current.pointImg = localIds;
            }
            if (type === 3) {
              setadvertiseImg(localIds);
              localStorage.setItem('advertiseImg', localIds);
              infoRef.current.advertiseImg = localIds;
            }
            if (type === 4) {
              setpowerModelImg(localIds);
              localStorage.setItem('powerModelImg', localIds);
              infoRef.current.powerModelImg = localIds;
            }
            if (type === 5) {
              setPremiumImg(localIds);
              localStorage.setItem('premiumImg', localIds);
              infoRef.current.premiumImg = localIds;
            }
            if (type === 6) {
              setappointmentVisitScreenshot(localIds);
              localStorage.setItem('appointmentVisitScreenshot', localIds);
              infoRef.current.appointmentVisitScreenshot = localIds;
            }
          }
          // onValuesChange();
        },
      });
    }
  };
  const valueNode = async (companyId:any,userId:any)=>{
    const score = await remarkLists({
      companyId: companyId,
      userId: userId != 1 ? userId : localStorage.getItem("userId")
    });
    if(score.data){
      setScoreList({data:score.data,companyId:companyId});
      score.data.map((item:any)=>{
        if(item.labelName === "专业人设"){
          valuePoint({companyId:companyId,parentId:item.labelId})
        }
      })
    }
  }
  const valuePoint = async (value:any)=>{
    const res = await labellist({
      companyId: value.companyId,
      parentId: value.parentId,
    });
    if (res.retCode === 0) {
      setMeritList(res.data);
    }
  }
  const initValue = async (dataFlag: any) => {
    console.log(dataFlag);

    if (dataFlag.visitCompanyRelations && dataFlag.visitSignType === 13) {
      console.log(dataFlag.visitCompanyRelations, '-------------');

      setrecommendationsList({
        companyId: dataFlag.visitCompanyRelations[0].companyId,
        companyName: dataFlag.visitCompanyRelations[0].companyName,
      });
      formComany.setFieldValue(
        'recommendations',
        dataFlag.visitCompanyRelations[0].companyName,
      );
    }
    if (
      dataFlag.companyId &&
      dataFlag.companyId != [] &&
      dataFlag.companyId != -1
    ) {
      // setCompanyId(dataFlag.companyId);
      const msg = await getMCarDealerVisitVo({
        companyId: dataFlag.companyId,
      });
      
      valueNode(dataFlag.companyId,1)
      if (msg.retCode === 0) {
        setCompanyDeatail(msg.data);
        form?.setFieldsValue(msg.data.mCarDealers);
        formCall?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCompanyInfo);
        if (msg.data.mCompanyInfo) {
          // setdegreeIntent(msg.data.mCompanyInfo.degreeIntent)
          if (msg.data.mCompanyInfo.degreeIntent) {
            infoRef.current.degreeIntent = msg.data.mCompanyInfo.degreeIntent;
          }
          if (`${msg.data.mCompanyInfo.kilometerFlag}` === 'true') {
            formComany?.setFieldsValue({ kilometerFlag: 1 });
          } else if (`${msg.data.mCompanyInfo.kilometerFlag}` === 'false') {
            formComany?.setFieldsValue({ kilometerFlag: 0 });
          }
          if (`${msg.data.mCompanyInfo.recommendCompanyFlag}` === 'true') {
            formComany?.setFieldsValue({ recommendCompanyFlag: 1 });
          } else if (
            `${msg.data.mCompanyInfo.recommendCompanyFlag}` === 'false'
          ) {
            formComany?.setFieldsValue({ recommendCompanyFlag: 0 });
          }
        }
        // form?.setFieldsValue({ price: msg.data.mCarDealers.price });
        if (msg.data.mCarDealers) {
          if (msg.data.mCarDealers.displayType) {
            // setdisplayType(msg.data.mCarDealers.displayType);
            infoRef.current.displayType = msg.data.mCarDealers.displayType;
          }
          if (msg.data.mCarDealers && msg.data.mCarDealers.outerMerchant) {
            setouterMerchant(`${msg.data.mCarDealers.outerMerchant}`);
          }
          if (msg.data.mCompanyInfo.contactDetailAddress) {
            setAddress(msg.data.mCompanyInfo.contactDetailAddress);
          }
          if (msg.data.mCompanyInfo.belongMarketId) {
            localStorage.setItem(
              'belongMarketId',
              msg.data.mCompanyInfo.belongMarketId,
            );
          }
        }
        const list = msg.data;
        // 自媒体
        if (list.mCarDealerMedia && list.mCarDealerMedia.length > 0) {
          list.mCarDealerMedia.map((item: any) => {
            if (item.account != '-1' && item.mediaName != '无') {
              setmediaList(list.mCarDealerMedia);
            } else {
              setRedioFlag2(2);
            }
          });
        } else {
          setRedioFlag2(2);
        }
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0) {
          list.mCarDealerPartners.map((item: any) => {
            if (item.partnerName === '无') {
              setRedioFlag(2);
            } else {
              setPartnerList(list.mCarDealerPartners);
            }
          });
        } else {
          setRedioFlag(2);
        }
        // 竞队信息
        if (list.carDealerCompetitors && list.carDealerCompetitors.length > 0) {
          list.carDealerCompetitors.map((item: any) => {
            if (item.testOrgId != -1) {
              settestOrgList(list.carDealerCompetitors);
            } else {
              setRedioFlag3(2);
            }
          });
        } else {
          setRedioFlag3(2);
        }
        if (msg.data.mCarDealers && msg.data.mCarDealers.displayType) {
          displayTypeList[0].map((item: any) => {
            if (msg.data.mCarDealers.displayType === item.value) {
              formComany?.setFieldsValue({ displayTypeName: item.label });
            }
          });
        }
      }

      valuePoint({companyId:dataFlag.companyId})
    } else {
      valuePoint({})
    }
    if (dataFlag.id) {
      const detail = await checkInDetails({
        id: dataFlag.id,
      });
      const append = await visitDetail({
        id: dataFlag.id,
      });
      if(append.data && append.data.userId && append.data.companyId){
        valueNode(append.data.companyId,append.data.userId)
      }
      if (detail.retCode === 0) {
        setEditId(detail.data.id);
        setEdit(detail.data);
        form.setFieldValue('reasonForFailure', detail.data.reasonForFailure);
        if (detail.data.visitResult) {
          setvisitResult(detail.data.visitResult)
          if (detail.data.visitResult === '1' || detail.data.visitResult === 1) {
            form?.setFieldsValue({ visitResultName: '失败' });
          } else {
            form?.setFieldsValue({ visitResultName: '成功' });
          }
        }
        if (detail.data.productName) {
          form?.setFieldsValue({ productName: detail.data.productName });
        }
        const list = detail.data;
        // 自媒体
        if (list.mCarDealerMedia && list.mCarDealerMedia.length > 0) {
          list.mCarDealerMedia.map((item: any) => {
            if (item.account != '-1' && item.mediaName != '无') {
              setmediaList(list.mCarDealerMedia);
            } else {
              setRedioFlag2(2);
            }
          });
        } else {
          setRedioFlag2(2);
        }
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0) {
          list.mCarDealerPartners.map((item: any) => {
            if (item.partnerName === '无') {
              setRedioFlag(2);
            } else {
              setPartnerList(list.mCarDealerPartners);
            }
          });
        } else {
          setRedioFlag(2);
        }
        // 竞队信息
        if (list.carDealerCompetitors && list.carDealerCompetitors.length > 0) {
          list.carDealerCompetitors.map((item: any) => {
            if (item.testOrgId != -1) {
              settestOrgList(list.carDealerCompetitors);
            } else {
              setRedioFlag3(2);
            }
          });
        } else {
          setRedioFlag3(2);
        }
        if (detail.data.checkInPictures) {
          detail.data.checkInPictures.map((item: any) => {
            if (item.urlType === 1) {
              setlocalIdsImg(item.url);
              localStorage.setItem('localIdsImg', item.url);
              let list = JSON.stringify({
                mediaId: item.mediaId,
                urlName: '门头近照',
                urlType: item.urlType,
                id: item.id,
              });
              localStorage.setItem('doorway', list);
            }
            if (item.urlType === 2) {
              setpointImg(item.url);
              localStorage.setItem('pointImg', item.url);
              let list = JSON.stringify({
                mediaId: item.mediaId,
                urlName: '门头含车位',
                urlType: item.urlType,
                id: item.id,
              });
              localStorage.setItem('point', list);
            }
            if (item.urlType === 3) {
              setadvertiseImg(item.url);
              localStorage.setItem('advertiseImg', item.url);
              let list = JSON.stringify({
                mediaId: item.mediaId,
                urlName: '竟对广告',
                urlType: item.urlType,
                id: item.id,
              });
              localStorage.setItem('advertise', list);
            }
            if (item.urlType === 4) {
              setpowerModelImg(item.url);
              localStorage.setItem('powerModelImg', item.url);
              let list = JSON.stringify({
                mediaId: item.mediaId,
                urlName: '竟对广告',
                urlType: item.urlType,
                id: item.id,
              });
              localStorage.setItem('powerModel', list);
            }
            if (item.urlType === 5) {
              localStorage.setItem('PremiumImg', item.url);
              setPremiumImg(item.url);
              let list = JSON.stringify({
                mediaId: item.mediaId,
                urlName: '竟对广告',
                urlType: item.urlType,
                id: item.id,
              });
              localStorage.setItem('premium', list);
            }
            if (item.urlType === 6) {
              localStorage.setItem('appointmentVisitScreenshot', item.url);
              setappointmentVisitScreenshot(item.url);
              let list = JSON.stringify({
                mediaId: item.mediaId,
                urlName: '预约拜访截图',
                urlType: item.urlType,
                id: item.id,
              });
              localStorage.setItem('appointmentVisitScreenshot', list);
            }
          });
        }
      }
    }
    if (dataFlag.carLabelIds) {
      setinitcarLabelIds(dataFlag.carLabelIds);
    }
    form?.setFieldsValue(dataFlag);
    form?.setFieldsValue({ companyName: dataFlag.companyName });
    // form?.setFieldsValue({address: dataFlag.contactDetailAddress });
    if (dataFlag.partnerInfoList) {
      dataFlag.partnerInfoList.map((item: any, index: any) => {
        partnerList[index] = item;
        setPartnerList(partnerList);
      });
    }
    if (dataFlag.mediaInfoList) {
      dataFlag.mediaInfoList.map((item: any, index: any) => {
        mediaList[index] = item;
        setmediaList(mediaList);
      });
    }
    if (dataFlag.competitorList) {
      dataFlag.competitorList.map((item: any, index: any) => {
        testOrgList[index] = item;
        settestOrgList(testOrgList);
      });
    }
    if (dataFlag.serveType) {
      // setserveType(dataFlag.serveType);
      infoRef.current.serveType = dataFlag.serveType;
    }
    if (dataFlag.visitContent) {
      setvisitContent(dataFlag.visitContent);
    }
    if (dataFlag.breakThroughLogic) {
      setBreakThroughLogic(dataFlag.breakThroughLogic);
    }
    if (dataFlag.visitSummary) {
      setVisitSummary(dataFlag.visitSummary);
    }
    if (dataFlag.valuesCall) {
      formCall?.setFieldsValue(dataFlag.valuesCall);
    }
    if (dataFlag.valuesComany) {
      formComany?.setFieldsValue(dataFlag.valuesComany);
    }
    if (dataFlag.id) {
      setIds(dataFlag.id);
    }
    if (dataFlag.doorway) {
      infoRef.current.doorway = dataFlag.doorway;
    }
    if (dataFlag.point) {
      infoRef.current.point = dataFlag.point;
    }
    if (dataFlag.advertise) {
      infoRef.current.advertise = dataFlag.advertise;
    }
    if (dataFlag.powerModel) {
      infoRef.current.powerModel = dataFlag.powerModel;
    }
    if (dataFlag.Premium) {
      infoRef.current.premium = dataFlag.Premium;
    }
    if (dataFlag.visitResultName && ((dataFlag.visitResultName === '失败') || (dataFlag.visitResultName === "待在拜访"))) {
      localStorage.setItem("visitResult","1")
      setvisitResult(1)
    }
    if (dataFlag.visitResultName &&  ((dataFlag.visitResultName === '成功') || (dataFlag.visitResultName === "拜访成交") || (dataFlag.visitResultName === "注册上线"))) {
      localStorage.setItem("visitResult","2")
      setvisitResult(2)
    }
    if (dataFlag.productName) {
      form?.setFieldsValue({ productName: dataFlag.productName });
    }
    if (dataFlag.reasonForFailure) {
      form?.setFieldsValue({ reasonForFailure: dataFlag.reasonForFailure });
    }
    if (dataFlag.degreeIntentName) {
      // setdegreeIntent(dataFlag.degreeIntent)
      infoRef.current.degreeIntent = dataFlag.degreeIntent;
    }
    if (dataFlag.remark) {
      setRemark(dataFlag.remark);
    }
    if (dataFlag.visitSignType) {
      // setRemark(dataFlag.remark)
      infoRef.current.visitSignType = dataFlag?.visitSignType;
    }
    if (dataFlag.degreeIntent) {
      // setRemark(dataFlag.remark)
      infoRef.current.degreeIntent = dataFlag?.degreeIntent;
    }
    setvisitSignType(dataFlag.visitSignType);
    if (dataFlag.visitSignType != 5) {
      setNewCarDealers(false);
    }
    // onValuesChange();
  };
  const onFinish = async () => {
    const doorway = localStorage.getItem('doorway');
    const point = localStorage.getItem('point');
    if (!doorway || !point) {
      setLoading(false);
      Toast.show({
        icon: 'fail',
        content: `请上传图片！！`,
      });
      return;
    }
    if (visitContent === '') {
      setLoading(false);
      Toast.show({
        icon: 'fail',
        content: '请填写拜访内容',
      });
      return;
    }
    if (visitSummary === '') {
      setLoading(false);
      Toast.show({
        icon: 'fail',
        content: '请填写拜访总结',
      });
      return;
    }
    if (breakThroughLogic === '') {
      setLoading(false);
      Toast.show({
        icon: 'fail',
        content: '请填写突破逻辑',
      });
      return;
    }
    let flag = false;
    initMeritList.map((item: any) => {
      item.mCarDealerLabelVos.map((child: any) => {
        carLabelIds.map((id: any) => {
          if (!remark[id] && child.id === id) {
            Toast.show({
              icon: 'fail',
              content: `${child.name}未填写！！`,
            });
            setLoading(false);
            flag = true;
            return;
          }
        });
      });
    });
    if (flag) {
      setLoading(false);
      return;
    }
    form.validateFields().then(() => {
      formCall.validateFields().then(() => {
        formComany.validateFields().then(() => {
          submit();
          setLoading(true);
        });
      });
    });
  };
  const submit = async () => {
    const companyId = localStorage.getItem('companyId');
    const values = form.getFieldsValue();
    const valueCall = formCall.getFieldsValue();
    const valueComany = formComany.getFieldsValue();
    console.log(values);
    let visitSignTypeCompanyId: number = 0;
    let list: any = {
      partnerType: parseInt(valueComany.partnerType),
      sellWay: parseInt(valueCall.sellWay),
      realLayoutNum: parseInt(valueCall.realLayoutNum),
      price: valueCall.price,
      displayType: parseInt(infoRef.current.displayType),
      storeCover: parseInt(valueComany.storeCover),
      imageStore: parseInt(valueComany.imageStore),
      parkingNum: parseInt(valueCall.parkingNum),
      intention: parseInt(valueCall.intention),
      outerMerchant: parseInt(valueComany.outerMerchant),
      kilometerFlag: parseInt(valueComany.kilometerFlag),
      recommendCompanyFlag: parseInt(valueComany.recommendCompanyFlag),
      companyName: valueComany.companyName,
      contactName: valueComany.contactName,
      contactMobileNo: valueComany.contactMobileNo,
      belongMarketName: valueComany.belongMarketName,
      belongingRegion: valueComany.belongingRegion,
      doorplate: valueComany.doorplate,
      floor: valueComany.floor,
      companyId:
        visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
      degreeIntent: infoRef.current.degreeIntent,
    };
    if (belongMarketId) {
      list.belongMarketId = belongMarketId;
    }
    if (testOrgList.length > 0 && redioFlag3 === 1) {
      const testOrgLists = testOrgList.filter((item: any) => item);
      values.competitorList = testOrgLists;
    }
    if (remark.length > 0) {
      const remarks = remark.filter((item: any) => item);
      values.saveRemarkInputDto = {
        labelList: remarks,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        bdMobile: localStorage.getItem('phone'),
      };
    }
    // if(remark.length < 8){
    //   Toast.show({
    //     icon: 'fail',
    //     content: "请填写至少八条价值点！！！",
    //   });
    // }
    if (mediaList.length > 0 && redioFlag2 === 1) {
      const mediaLists = mediaList.filter((item: any) => item);
      mediaLists.forEach((item: any) => {
        item.companyId =
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
      });
      values.mediaInfoList = mediaLists;
    }
    if (partnerList.length > 0 && redioFlag === 1) {
      const partnerLists = partnerList.filter((item: any) => item);
      partnerLists.forEach((item: any) => {
        item.companyId =
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
      });
      values.partnerInfoList = partnerLists;
    }
    // values.carDealerBasicOutputDTO.push()
    values.visitPlanId = ids;
    values.serveType = infoRef.current.serveType;
    values.entranceType = dataFlag.entranceType;
    values.visitSignType = infoRef.current.visitSignType;
    values.degreeIntent = infoRef.current.degreeIntent;
    values.visitResult = visitResult ? visitResult : localStorage.getItem("visitResult");
    values.displayType = infoRef.current.displayType;
    values.companyId =
      visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
    values.companyName = valueComany.companyName;
    values.token = localStorage.getItem('token');
    values.carDealerBasicOutputDTO = list;
    values.visitContent = visitContent;
    values.regionAnnotations = regionAnnotations;
    values.breakThroughLogic = breakThroughLogic;
    values.visitSummary = visitSummary;
    values.address = address;
    // values.visitCompanyRelationVos = recommendationsList;
    if (dataFlag.status && dataFlag.status === 4) {
      values.id = editId;
      if (!dataFlag.edit) {
        delete values.visitPlanId;
        delete values.id;
      }
    }
    let listImg: any = [];
    if (localStorage.getItem('doorway')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('doorway'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('point')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('point'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('advertise')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('advertise'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('powerModel')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('powerModel'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('premium')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('premium'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('appointmentVisitScreenshot')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('appointmentVisitScreenshot'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    
    values.checkInPictureVos = listImg;
    delete values.partnerType;
    delete values.outerMerchant;
    delete values.sellWay;
    delete values.realLayoutNum;
    delete values.price;
    delete values.intention;
    delete values.displayTypeName;
    delete values.storeCover;
    delete values.parkingNum;
    delete values.imageStore;
    delete values.serveTypeName;
    // delete values.visitSignTypeName;
    delete values.visitResultName;
    delete values.userId;
    delete values.userMobile;
    // if(values.visitResult === 1 || values.visitResult === "1"){
    //   values.planFlag = true
    //   history.replace("./programme",values)
    //   return
    // }else{
    //   values.planFlag = false
    // }
    save(values)
      .then((msg) => {
        setLoading(false);
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '签到成功',
          });
          localStorage.removeItem('values');
          localStorage.removeItem('visitContent');
          localStorage.removeItem('visitSummary');
          localStorage.removeItem('breakThroughLogic');
          localStorage.removeItem('companyId');
          localStorage.removeItem('visitResult');
          localStorage.removeItem('belongMarketId');
          localStorage.removeItem('localIdsImg');
          localStorage.removeItem('pointImg');
          localStorage.removeItem('advertiseImg');
          localStorage.removeItem('powerModelImg');
          localStorage.removeItem('premiumImg');
          localStorage.removeItem('doorway');
          localStorage.removeItem('point');
          localStorage.removeItem('advertise');
          localStorage.removeItem('appointmentVisitScreenshot');
          localStorage.removeItem('powerModel');
          localStorage.removeItem('premium');
          history.replace('./PayAVisit');
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: '提交失败',
        });
      });
  };
  const onRemarkFinish = () => {
    const values = remarkform.getFieldsValue();
    if (remarkValue === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写备注！',
        // duration: 0,
      });
      return;
    }
    if (!values.appraiseType) {
      Toast.show({
        icon: 'fail',
        content: '请选择评分！',
        // duration: 0,
      });
      return;
    }
    values.remarkContent = remarkValue;
    values.appraiseType = values.appraiseType;
    values.labelId = remarkChild.id;
    values.labelName = remarkChild.name;
    // values.remarkLabelUserId = localStorage.getItem("userId")
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = avatar;
    values.createTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    remark[remarkChild.id] = values;
    setRemark([...remark]);
    onValuesChange();
    setVisible4(false);
    console.log(remark);
  };
  const init = async () => {
    wxShareConfigs();
    const msg = await orglist();
    if (msg.retCode === 0) {
      console.log(msg.data);
      let list: any = [];
      msg.data.map((item: any) => {
        list.push({
          label: item.partnerName,
          value: item.id,
        });
      });
      setorgPicket([list]);
    }
    if (!dataFlag.companyId) {
      valuePoint({})
    }
  };
  const OnVisitSign = () => {
    let list: any = [];
    typelist({})
      .then((any) => {
        if (any.retCode === 0 && any.data.length > 0) {
          any.data.map((item: any, index: number) => {
            if (item.type != 12) {
              list.push({
                label: item.name,
                value: item.type,
              });
            }
          });
          console.log(list);
          setTypeList([list]);
          setvisitSignTypeVisible(true);
          // onValuesChange()
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.show({
          icon: 'fail',
          content: '拜访类型获取失败',
        });
      });
  };
  useEffect(() => {
    init();
    if (localStorage.getItem('values')) {
      let value = localStorage.getItem('values');
      if (value) {
        let json = JSON.parse(value);
        json.visitContent = localStorage.getItem('visitContent');
        json.breakThroughLogic = localStorage.getItem('breakThroughLogic');
        json.visitSummary = localStorage.getItem('visitSummary');
        json.storage = 1;
        // if(!dataFlag.companyId){
        //   dataFlag.companyId = json.companyId
        // }
        initValue(json);
      }
    }
    if (dataFlag) {
      if (dataFlag.companyId && dataFlag.companyId != -1) {
        localStorage.setItem('companyId', dataFlag.companyId);
      }
      initValue(dataFlag);
      if (dataFlag.carLabelIds) {
        setcarLabelIds(dataFlag.carLabelIds);
      }
      if (dataFlag.visitSignTypeName) {
        form?.setFieldsValue({ visitSignTypeName: dataFlag.visitSignTypeNam });
      }
      if (dataFlag.productName) {
        form?.setFieldsValue({ productName: dataFlag.productName });
      }
      if (dataFlag && dataFlag.serveType) {
        // infoRef.current.serveType = dataFlag?.serveType
        serveTypeList[0].map((item: any) => {
          if (dataFlag.serveType === item.value) {
            form?.setFieldsValue({ serveTypeName: item.label });
            // setserveType(dataFlag.serveType);
          }
        });
      }
    }
    if (dataFlag && dataFlag.carLabelIds) {
      let list: any = [];
      labellist({}).then((res) => {
        if (res.retCode === 0) {
          res.data.map((item: any) => {
            if (item.mCarDealerLabelVos && item.mCarDealerLabelVos.length > 0) {
              item.mCarDealerLabelVos.map((child: any) => {
                if (dataFlag.carLabelIds.indexOf(child.id) > -1) {
                  list.push(child);
                }
              });
            }
          });
        }
        setInitMeritList(res.data);
      });
      console.log(list, dataFlag.carLabelIds);
    }
  }, []);

  const onchanges = (v:any)=>{
    setCheckCollect(v)
  }
  const handelUserName = async (value: any) => {
    console.log(value);
    // setCompanyId(value[0]);
    localStorage.setItem('companyId', value[0]);
    setmediaList([]);
    setPartnerList([]);
    settestOrgList([]);
    if (value.length > 0) {
      const msg = await getMCarDealerVisitVo({
        companyId: value[0],
      });
      valueNode(value[0],1)
      if (msg.retCode === 0) {
        setCompanyDeatail(msg.data);
        form?.setFieldsValue(msg.data.mCarDealers);
        formCall?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCompanyInfo);
        if (msg.data.mCompanyInfo) {
          setdegreeIntent(msg.data.mCompanyInfo.degreeIntent);
          infoRef.current.degreeIntent = msg.data.mCompanyInfo.degreeIntent;
        }
        // form?.setFieldsValue({ price: msg.data.mCarDealers.price });
        if (msg.data.mCarDealers) {
          if (msg.data.mCarDealers.displayType) {
            // setdisplayType(msg.data.mCarDealers.displayType);
            infoRef.current.displayType = msg.data.mCarDealers.displayType;
          }
          if (msg.data.mCompanyInfo.contactDetailAddress) {
            setAddress(msg.data.mCompanyInfo.contactDetailAddress);
          }
          if (msg.data.mCompanyInfo.belongMarketId) {
            localStorage.setItem(
              'belongMarketId',
              msg.data.mCompanyInfo.belongMarketId,
            );
          }
        }
        const list = msg.data;
        // 自媒体
        if (list.mCarDealerMedia && list.mCarDealerMedia.length > 0) {
          list.mCarDealerMedia.map((item: any) => {
            if (item.account != '-1' && item.mediaName != '无') {
              setmediaList(list.mCarDealerMedia);
            } else {
              setRedioFlag2(2);
            }
          });
        } else {
          setRedioFlag2(2);
        }
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0) {
          list.mCarDealerPartners.map((item: any) => {
            if (item.partnerName === '无') {
              setRedioFlag(2);
            } else {
              setPartnerList(list.mCarDealerPartners);
            }
          });
        } else {
          setRedioFlag(2);
        }
        // 竞队信息
        if (list.carDealerCompetitors && list.carDealerCompetitors.length > 0) {
          list.carDealerCompetitors.map((item: any) => {
            if (item.testOrgId != -1) {
              settestOrgList(list.carDealerCompetitors);
            } else {
              setRedioFlag3(2);
            }
          });
        } else {
          setRedioFlag3(2);
        }
        if (msg.data.mCarDealers && msg.data.mCarDealers.displayType) {
          displayTypeList[0].map((item: any) => {
            if (msg.data.mCarDealers.displayType === item.value) {
              formComany?.setFieldsValue({ displayTypeName: item.label });
            }
          });
        }
      }
      
      valuePoint({ companyId: value[0]})
    }
    setNameVisible(false);
    onValuesChange();
  };
  const handleUserNameCancel = async () => {
    setNameVisible(false);
  };
  const handelUserMarket = async (value: any) => {
    console.log(value);
    localStorage.setItem('belongMarketId', value[0].id);
    formComany.setFieldValue('belongMarketName', value[0].marketName);
    setMarketVisible(false);
    onValuesChange();
  };
  const handleUserMarketCancel = async () => {
    setMarketVisible(false);
  };
  const onValuesChange = () => {
    console.log(111);
    const values = form.getFieldsValue();
    const valuesCall = formCall.getFieldsValue();
    const valuesComany = formComany.getFieldsValue();
    values.valuesCall = valuesCall;
    values.valuesComany = valuesComany;
    values.serveType = infoRef.current.serveType;
    values.homeSee = true;
    values.visitSignType = infoRef.current.visitSignType;
    values.degreeIntent = infoRef.current.degreeIntent;
    values.visitResult = visitResult;
    values.displayType = infoRef.current.displayType;
    values.partnerInfoList = partnerList;
    values.mediaInfoList = mediaList;
    values.competitorList = testOrgList;
    values.visitContent = visitContent;
    values.visitSummary = visitSummary;
    values.breakThroughLogic = breakThroughLogic;
    values.companyId = localStorage.getItem('companyId');
    values.localIdsImg = localIdsImg;
    values.doorway = infoRef.current.doorway;
    values.pointImg = pointImg;
    values.point = infoRef.current.point;
    values.advertiseImg = advertiseImg;
    values.appointmentVisitScreenshot = appointmentVisitScreenshot;
    values.advertise = infoRef.current.advertise;
    values.powerModelImg = powerModelImg;
    values.powerModel = infoRef.current.powerModel;
    values.PremiumImg = premiumImg;
    values.Premium = infoRef.current.premium;
    values.remark = remark;
    values.see = true;
    values.back = true;
    values.partner = partnerform.getFieldsValue();
    values.media = mediaform.getFieldsValue();
    values.testOrg = testOrgIdform.getFieldsValue();
    values.visitCompanyRelations = [recommendationsList];
    console.log(values);
    localStorage.setItem('values', JSON.stringify(values));
  };
  return (
    <div className={`${styles.card} ${styles.page}`}>
      <Navbar name={'拜访签到'}></Navbar>
      {loading && <Login></Login>}
      <div>
        <div style={{ paddingBottom: '50px' }}>
          <Card>
            <Form
              // onFinish={onFinish}
              form={form}
              onValuesChange={onValuesChange}
              footer={
                <div className={styles.submit}>
                  <Button
                    block
                    type="submit"
                    color="primary"
                    onClick={() => {
                      onFinish();
                    }}
                  >
                    保存
                  </Button>
                </div>
              }
            >
            {dataFlag && dataFlag.id && (<div style={{fontSize:"14px",padding:"5px 0 0 5px", color: "black"}}>计划ID：{dataFlag.id}</div>)}
              <List>
                <List.Item
                  prefix="拜访方式"
                  onClick={() => {
                    setserveTypeVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="serveTypeName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择拜访方式',
                            });
                            return Promise.reject(new Error('选择拜访方式'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="选择拜访方式" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="拜访类型"
                  onClick={() => {
                    if (dataFlag.homeSee || dataFlag.merchantFlag) {
                      OnVisitSign();
                    }
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="visitSignTypeName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择拜访类型',
                            });
                            return Promise.reject(new Error('请选择拜访类型'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择拜访类型" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="拜访状态"
                  onClick={() => {
                    setvisitResultVisible(true);
                    if(infoRef.current.visitSignType === 1 || infoRef.current.visitSignType === 5){
                      setVisitList(strangerVisitResultList)
                    }else if(infoRef.current.visitSignType === 2 || infoRef.current.visitSignType === 3 || infoRef.current.visitSignType === 4 || infoRef.current.visitSignType === 11 ){
                      setVisitList(newVisitResultList)
                    }else {
                      setVisitList(visitResultList)
                    }
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="visitResultName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择拜访状态',
                            });
                            return Promise.reject(new Error('请输入拜访状态'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入拜访状态" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="检测产品"
                  onClick={() => {
                    setCollectVisible(true)
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="productName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择检测产品',
                            });
                            return Promise.reject(new Error('请选择检测产品'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择检测产品" readOnly />
                  </Form.Item>
                </List.Item>
                {(visitResult === 1 || visitResult ==="1") && (infoRef.current.visitSignType === 2 || infoRef.current.visitSignType === 3) && (
                  <List.Item
                    prefix="商户托词"
                    onClick={() => {
                      setvisitReasonsVisible(true);
                    }}
                    title={'*'}
                  >
                    <Form.Item
                      name="reasonForFailure"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              Toast.show({
                                icon: 'fail',
                                content: '请选择商户托词',
                              });
                              return Promise.reject(
                                new Error('请选择商户托词'),
                              );
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <Input placeholder="请选择商户托词" readOnly />
                    </Form.Item>
                  </List.Item>
                )}
                {(visitResult === 1 || visitResult ==="1")&& (
                  <>
                  <List.Item
                    prefix="下次拜访时间"
                    onClick={() => {
                      setnextVisitTimeVisible(true);
                    }}
                    title={'*'}
                  >
                    <Form.Item
                      name="nextVisitTime"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              Toast.show({
                                icon: 'fail',
                                content: '请选择下次拜访时间',
                              });
                              return Promise.reject(
                                new Error('请选择下次拜访时间'),
                              );
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <Input placeholder="请选择下次拜访时间" readOnly />
                    </Form.Item>
                  </List.Item>
                  <div className={styles.automation}>
                    <div>*</div>
                    <span>系统会自动创建拜访计划</span>
                  </div>
                  </>
                )}
              </List>
            </Form>
          </Card>
          <Card
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                商户基础信息
              </div>
            }
          >
            <Form
              // onFinish={onFinish}
              form={formComany}
              onValuesChange={onValuesChange}
            >
              <List>
                {infoRef.current.visitSignType === 13 && (
                  <>
                  <List.Item
                    prefix="推荐商户"
                    onClick={() => {
                      // setMarketVisible(true)
  
                      if (dataFlag.homeSee || dataFlag.visitSignType === 5) {
                        // goToback(0);
                        setNameVisible(true);
                        if(dataFlag.visitSignType === 5){
                          localStorage.setItem("conmanyVisitSignType", dataFlag.visitSignType)
                        }
                      }
                    }}
                    title={'*'}
                  >
                    <Form.Item
                      name="companyName"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              Toast.show({
                                icon: 'fail',
                                content: '输入推荐商户',
                              });
                              return Promise.reject(new Error('输入推荐商户'));
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <Input placeholder="输入推荐商户" readOnly />
                    </Form.Item>
                  </List.Item>
                    <List.Item
                      prefix="被推荐商户"
                      onClick={() => {
                        // setMarketVisible(true)
  
                        if (dataFlag.homeSee) {
                          // goToback(0);
                          goTobackRecommendations();
                        }
                      }}
                      title={'*'}
                    >
                      <Form.Item
                        name="recommendations"
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                Toast.show({
                                  icon: 'fail',
                                  content: '输入被推荐商户',
                                });
                                return Promise.reject(new Error('输入被推荐商户'));
                              }
                              return Promise.resolve();
                            },
                            required: true,
                          }),
                        ]}
                        className={styles.form_item}
                      >
                        <Input placeholder="输入被推荐商户" readOnly />
                      </Form.Item>
                    </List.Item>
                  </>
                )}
                {infoRef.current.visitSignType != 13 &&
                <List.Item
                  prefix="商户名称"
                  onClick={() => {
                    // setMarketVisible(true)

                    if (dataFlag.homeSee || dataFlag.visitSignType === 5) {
                      // goToback(0);
                      setNameVisible(true);
                    }
                  }}
                  title={'*'}
                  className={`${newCarDealers && styles.list_newCarDealers}`}
                >
                  <Form.Item
                    name="companyName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入车商名称',
                            });
                            return Promise.reject(new Error('输入车商名称'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入车商名称" readOnly />
                  </Form.Item>
                </List.Item>}
                <List.Item prefix="联系人" title={'*'}>
                  <Form.Item
                    name="contactName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入联系姓名',
                            });
                            return Promise.reject(new Error('输入联系姓名'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入联系姓名" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="联系电话" title={'*'}>
                  <Form.Item
                    name="contactMobileNo"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入联系人电话',
                            });
                            return Promise.reject(new Error('输入联系人电话'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入联系人电话" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="地址" title={'*'}>
                  <Form.Item
                    name="address"
                    // rules={[{ required: true, message: '输入地址' }]}
                    className={styles.form_item}
                  >
                    {/* <Input placeholder="输入地址" readOnly/> */}
                    <div
                      className={styles.increase}
                      onClick={() => {
                        wxShareConfigs();
                      }}
                    >
                      重新获取定位
                    </div>
                  </Form.Item>
                </List.Item>
                {address != '' && (
                  <div className={styles.address}>{address}</div>
                )}
                {/* TODO */}
                <List.Item
                  prefix="归属市场"
                  title={'*'}
                  onClick={() => {
                    // goTobacks(0);
                    setMarketVisible(true);
                    // if(dataFlag){
                    //   console.log(1);
                    // }else{
                    // }
                  }}
                >
                  <Form.Item
                    name="belongMarketName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择市场',
                            });
                            return Promise.reject(new Error('选择市场'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="选择市场" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="归属区域">
                  <Form.Item
                    name="belongingRegion"
                    // rules={[{ required: true, message: '请输入归属区域' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入归属区域" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="楼层">
                  <Form.Item
                    name="floor"
                    // rules={[{ required: true, message: '请输入楼层' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入楼层" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="门牌号">
                  <Form.Item
                    name="doorplate"
                    // rules={[{ required: true, message: '请输入门牌号' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入门牌号" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="合伙性质" title={'*'}>
                  <Form.Item
                    name="partnerType"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择合伙性质',
                            });
                            return Promise.reject(new Error('选择合伙性质'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={1}>单一经营</Radio>
                      <Radio value={2}>多人合伙</Radio>
                      <Radio value={0}>未知</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="独立展厅" title={'*'}>
                  <Form.Item
                    name="outerMerchant"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            console.log(value);

                            Toast.show({
                              icon: 'fail',
                              content: '是否是独立展厅',
                            });
                            return Promise.reject(new Error('是否是独立展厅'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Radio.Group
                      onChange={(v) => {
                        console.log(v);
                        setouterMerchant(`${v}`);
                      }}
                    >
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                {outerMerchant === '1' && (
                  <List.Item prefix="独立展厅类型" title={'*'}>
                    <Form.Item
                      name="kilometerFlag"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value && value != 0) {
                              console.log(value);

                              Toast.show({
                                icon: 'fail',
                                content: '请选择独立展厅类型',
                              });
                              return Promise.reject(
                                new Error('请选择独立展厅类型'),
                              );
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <Radio.Group>
                        <Radio value={1}>一公里内</Radio>
                        <Radio value={0}>一公里外</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </List.Item>
                )}
                <List.Item prefix="合伙人" title={'*'}>
                  <Form.Item
                    name="partnerInfoList"
                    className={styles.form_item}
                  >
                    <div className={styles.partner}>
                      {/* {partnerList && partnerList.map((partnerItem:any)=>{
                      if(partnerItem.partnerName){
                        return(
                          <Tag
                            color='primary'
                            fill='outline'
                            style={{ '--border-radius': '6px' }}
                          >
                            {partnerItem.partnerName}
                          </Tag>
                        )
                      }
                    })} */}
                      {redioFlag === 2 && (
                        <div className={styles.redioName}>无</div>
                      )}
                      <div
                        className={styles.increase}
                        onClick={() => {
                          partnerform.resetFields();
                          setVisible(true);
                        }}
                      >
                        添加
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {partnerList.length > 0 && redioFlag != 2 && (
                  <div className={styles.mediaList}>
                    {partnerList &&
                      partnerList.map((partnerItem: any, index: number) => {
                        if (partnerItem && partnerItem.partnerName) {
                          return (
                            <div className={styles.mediaList_b}>
                              <div className={styles.mediaList_delete}>
                                <span>合伙人姓名</span>
                                <span>{partnerItem.partnerName}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>合伙人电话</span>
                                <span>{partnerItem.partnerPhone}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <div></div>
                                <Tag
                                  color="primary"
                                  onClick={() => {
                                    let list: any[] = partnerList;
                                    delete list[index];
                                    const partnerLists = list.filter(
                                      (item: any) => item,
                                    );
                                    console.log(list);
                                    setPartnerList(partnerLists);
                                  }}
                                >
                                  删除
                                </Tag>
                                {/* <div  className={styles.increase}>删除</div> */}
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
                <List.Item
                  prefix="展厅类型"
                  onClick={() => {
                    setdisplayTypeVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="displayTypeName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择展厅类型',
                            });
                            return Promise.reject(new Error('请选择展厅类型'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择展厅类型" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="形象店" title={'*'}>
                  <Form.Item
                    name="imageStore"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择是否是形象店',
                            });
                            return Promise.reject(
                              new Error('请选择是否是形象店'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                    initialValue={0}
                  >
                    <Radio.Group disabled>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="愿意异地推荐" title={'*'}>
                  <Form.Item
                    name="recommendCompanyFlag"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择是否愿意异地推荐',
                            });
                            return Promise.reject(
                              new Error('请选择是否愿意异地推荐'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="门头店覆盖" title={'*'}>
                  <Form.Item
                    name="storeCover"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '门头店是否覆盖',
                            });
                            return Promise.reject(new Error('门头店是否覆盖'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                    initialValue={0}
                  >
                    <Radio.Group disabled>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="意向度"
                  title={'*'}
                  onClick={() => {
                    setdegreeIntentVisible(true);
                  }}
                >
                  <Form.Item
                    name="degreeIntentName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择意向度',
                            });
                            return Promise.reject(new Error('请选择意向度'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    // rules={[{ required: true, message: '请选择意向度' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择意向度" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="门头近照" title={'*'}>
                  <Form.Item name="doorway" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {localIdsImgs && (
                        <Image
                          src={localIdsImgs}
                          width={40}
                          height={40}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(localIdsImgs);
                          }}
                        />
                      )}

                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(1);
                        }}
                      >
                        <AddOutline />
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="门头含车位" title={'*'}>
                  <Form.Item name="point" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {pointImgs && (
                        <Image
                          src={pointImgs}
                          width={40}
                          height={40}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(pointImgs);
                          }}
                        />
                      )}

                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(2);
                        }}
                      >
                        <AddOutline />
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="竟对广告">
                  <Form.Item name="advertise" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {advertiseImgs && (
                        <Image
                          src={advertiseImgs}
                          width={40}
                          height={40}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(advertiseImgs);
                          }}
                        />
                      )}

                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(3);
                        }}
                      >
                        <AddOutline />
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="动力型车型">
                  <Form.Item name="powerModel" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {powerModelImgs && (
                        <Image
                          src={powerModelImgs}
                          width={40}
                          height={40}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(powerModelImgs);
                          }}
                        />
                      )}

                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(4);
                        }}
                      >
                        <AddOutline />
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="高配版车型">
                  <Form.Item name="Premium" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {PremiumImgs && (
                        <Image
                          src={PremiumImgs}
                          width={40}
                          height={40}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(PremiumImgs);
                          }}
                        />
                      )}

                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(5);
                        }}
                      >
                        <AddOutline />
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
              </List>
            </Form>
          </Card>
          <Card title={'商户分类信息'}>
            <Form
              // onFinish={onFinish}
              form={formCall}
              onValuesChange={onValuesChange}
            >
              <List>
                <List.Item prefix="售车方式" title={'*'}>
                  <Form.Item
                    name="sellWay"
                    // rules={[{ required: true, message: '选择售车方式' }]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择售车方式',
                            });
                            return Promise.reject(new Error('选择售车方式'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={1}>零售</Radio>
                      <Radio value={2}>批发</Radio>
                      <Radio value={3}>批发&零售</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="车源量" extra="个" title={'*'}>
                  <Form.Item
                    name="realLayoutNum"
                    rules={[
                      () => ({
                        validator(_, value) {
                          console.log(value);

                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入数量',
                            });
                            return Promise.reject(new Error('输入数量'));
                          }
                          if (Number.isFinite(Number(value)) === false) {
                            return Promise.reject(
                              new Error('不可输入文字！！'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入数量" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="车位数" extra="个" title={'*'}>
                  <Form.Item
                    name="parkingNum"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入车位数',
                            });
                            return Promise.reject(new Error('输入车位数'));
                          }
                          if (Number.isFinite(Number(value)) === false) {
                            return Promise.reject(
                              new Error('不可输入文字！！'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入车位数" />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="价位"
                  extra="万元"
                  title={'*'}
                  className={styles.form_item_2}
                >
                  <Form.Item
                    name="price"
                    onClick={() => {
                      setPriceVisible(true);
                    }}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入价位',
                            });
                            return Promise.reject(new Error('输入价位'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入价位" readOnly />
                  </Form.Item>
                </List.Item>
                {/* <List.Item prefix="意向度" extra="天" title={'*'}>
                  <Form.Item
                    name="intention"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon:"fail",
                              content:"选择意向度天数"
                            })
                            return Promise.reject(new Error('选择天数'));
                          }
                          if (Number.isFinite(Number(value)) === false) {
                            return Promise.reject(
                              new Error('不可输入文字！！'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="选择天数" />
                  </Form.Item>
                </List.Item> */}
                <List.Item prefix="自媒体" title={'*'}>
                  <Form.Item name="mediaInfoList" className={styles.form_item}>
                    <div className={styles.partner}>
                      {redioFlag2 === 2 && (
                        <div className={styles.redioName}>无</div>
                      )}
                      <div
                        className={styles.increase}
                        onClick={() => {
                          mediaform.resetFields();
                          setVisible2(true);
                        }}
                      >
                        添加
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {mediaList.length > 0 && redioFlag2 != 2 && (
                  <div className={styles.mediaList}>
                    {mediaList &&
                      mediaList.map((partnerItem: any, index: number) => {
                        if (partnerItem && partnerItem.mediaName) {
                          return (
                            <div className={styles.mediaList_b}>
                              <div className={styles.mediaList_delete}>
                                <span>自媒体</span>
                                <span>{partnerItem.mediaName}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>账号</span>
                                <span>{partnerItem.account}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>粉丝数量</span>
                                <span>{partnerItem.fansNum || 0}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <div></div>
                                <Tag
                                  color="primary"
                                  onClick={() => {
                                    let list: any[] = mediaList;
                                    delete list[index];
                                    const mediaLists = list.filter(
                                      (item: any) => item,
                                    );
                                    console.log(list);
                                    setmediaList(mediaLists);
                                  }}
                                >
                                  删除
                                </Tag>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
                <List.Item prefix="竞队信息" title={'*'}>
                  <Form.Item name="testOrgId" className={styles.form_item}>
                    <div className={styles.partner}>
                      {redioFlag3 === 2 && (
                        <div className={styles.redioName}>无</div>
                      )}
                      <div
                        className={styles.increase}
                        onClick={() => {
                          testOrgIdform.resetFields();
                          setVisible3(true);
                        }}
                      >
                        添加
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {testOrgList.length > 0 && redioFlag3 != 2 && (
                  <div className={styles.mediaList}>
                    {testOrgList &&
                      testOrgList.map((partnerItem: any, index: number) => {
                        if (partnerItem) {
                          return (
                            <div className={styles.mediaList_b}>
                              <div className={styles.mediaList_delete}>
                                <span>检测机构</span>
                                <span>
                                  {partnerItem.detectOrgName
                                    ? partnerItem.detectOrgName
                                    : partnerItem.name}
                                </span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>合作时间</span>
                                <span>
                                  {partnerItem.cooperationTime
                                    ? dateFormat(
                                        partnerItem.cooperationTime,
                                        'yyyy-MM',
                                      )
                                    : '-'}
                                </span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>集采余额</span>
                                <span>{partnerItem.purchaseBalance}万</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>硬广覆盖</span>
                                <span>
                                  {partnerItem.hardCover === '1' ? '是' : '否'}
                                </span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <div></div>
                                <Tag
                                  color="primary"
                                  onClick={() => {
                                    let list: any[] = testOrgList;
                                    delete list[index];
                                    const testOrgLists = list.filter(
                                      (item: any) => item,
                                    );
                                    console.log(list);
                                    settestOrgList(testOrgLists);
                                  }}
                                >
                                  删除
                                </Tag>
                                {/* <div  className={styles.increase}>删除</div> */}
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
              </List>
            </Form>
          </Card>
          <Card
            title={
              <div>
                商户建议{' '}
                <span
                  style={{
                    color: 'red',
                    fontSize: '14px',
                    fontWeight: '400',
                    marginLeft: '10px',
                  }}
                >
                  *
                </span>
              </div>
            }
          >
            <TextArea
              // value={visitContent}
              defaultValue={
                localStorage.getItem('visitContent')
                  ? localStorage.getItem('visitContent')
                  : dataFlag.visitContent
              }
              // defaultValue={dataFlag.visitContent}
              name="visitContent"
              onChange={(v) => {
                console.log(v);
                localStorage.setItem('visitContent', v);
                setvisitContent(v);
                onValuesChange();
              }}
              placeholder="请输入内容"
              rows={5}
            />
          </Card>
          <Card style={{padding:'0'}}>
            <Card
              style={{
                borderBottom:"1px solid rgba(230, 230, 230, 1)",
                borderRadius:"0"
              }}
              title={
                <div>
                  拜访总结{' '}
                  <span
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: '10px',
                    }}
                  >
                    *
                  </span>
                </div>
              }
            >
              <TextArea
                name="visitSummary"
                onChange={(v) => {
                  console.log(v);
                  setVisitSummary(v);
                  localStorage.setItem('visitSummary', v);
                  onValuesChange();
                }}
                placeholder="请输入内容"
                value = {visitSummary}
                rows={5}
              />
            </Card>
            <Card
              title={
                <div>
                  突破逻辑{' '}
                  <span
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: '10px',
                    }}
                  >
                    *
                  </span>
                </div>
              }
            >
              <TextArea
                name="breakThroughLogic"
                onChange={(v) => {
                  console.log(v);
                  setBreakThroughLogic(v);
                  localStorage.setItem('breakThroughLogic', v);
                  onValuesChange();
                }}
                value={breakThroughLogic}
                placeholder="请输入内容"
                rows={5}
              />
            </Card>
          </Card>
          {/* {dataFlag.edit && (
            <Card
              title={
                <div>
                  大区总跟进批注{' '}
                  <span
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: '10px',
                    }}
                  >
                    *
                  </span>
                </div>
              }
            >
              <TextArea
                // value={visitContent}
                defaultValue={
                  localStorage.getItem('regionAnnotations')
                    ? localStorage.getItem('regionAnnotations')
                    : dataFlag.regionAnnotations
                }
                // defaultValue={dataFlag.visitContent}
                name="regionAnnotations"
                onChange={(v) => {
                  console.log(v);
                  setregionAnnotations(v);
                }}
                placeholder="请输入内容"
                rows={5}
              />
            </Card>
          )} */}
          {scoreList && scoreList.data && scoreList.data.length > 0 && (
            <Card
              title={
                <div>
                  价值节点{' '}
                </div>
              }
            >
            {scoreList && scoreList.data && scoreList.data.length > 0 && (
              <div style={{marginTop:"10px"}}>
                <MyPie onPointChange={valuePoint} companyId={scoreList.companyId} current={scoreList.data}></MyPie>
              </div>
            )}
            </Card>
          )}
          <Card>
            <div className={styles.footer}>
              <div className={styles.footer_1}>
                <div className={styles.footer_b}>
                  <div>备注信息</div>
                </div>
              </div>
              <div style={{overflow:"scroll",maxHeight:"68vh"}}>
                
              {MeritList &&
                MeritList.map((item: any, mIndex: number) => {
                  if (item.mCarDealerLabelVos.length > 0) {
                    let flag = false;
                    let testFlag = false
                    if (initcarLabelIds.length > 0) {
                      initcarLabelIds.map((i: any) => {
                        if (item.id === i) {
                          flag = true;
                        }
                      });
                    }
                    item.mCarDealerLabelVos.map((valueFlag:any)=>{
                      initcarLabelIds.map((i: any) => {
                        if (valueFlag.id === i) {
                          testFlag = true;
                        }
                      });
                    })
                    return (
                      <div>
                        <div
                          className={styles.footer_2}
                          key={`MeritList-${mIndex}`}
                        >
                          <div
                            className={styles.footer_2_title}
                            key={item.id}
                            style={{ color: `${flag && 'red'}`}}
                          >{`${item.name}`}</div>
                        </div>
                        {item.mCarDealerLabelVos &&
                          item.mCarDealerLabelVos.length > 0 &&
                          item.mCarDealerLabelVos.map(
                            (Child: any, cIndex: number) => {
                              let childFlag = false;
                              if (initcarLabelIds.length > 0) {
                                initcarLabelIds.map((i: any) => {
                                  if (Child.id === i) {
                                    childFlag = true;
                                  }
                                });
                              }
                              return (
                                <div
                                  style={{ paddingTop: '5px'}}
                                  key={`MeritList-${mIndex}-${cIndex}`}
                                >
                                  <div className={styles.remark_content_header}>
                                    <div
                                      key={Child.id}
                                      style={{
                                        color: `${childFlag && 'red'}`,
                                        width: '80%',
                                      }}
                                    >{`${
                                      Child.name
                                    }`}</div>
                                    <div
                                      className={styles.remark_content_header_0}
                                      onClick={() => {
                                        remarkform.resetFields();
                                        setRemarkValue('');
                                        setVisible4(true);
                                        let list = Child;
                                        list.index = `${mIndex + 1}.${
                                          cIndex + 1
                                        }`;
                                        list.childFlag = childFlag;
                                        setRemarkChild(Child);
                                      }}
                                    >
                                      备注
                                    </div>
                                  </div>
                                  {remark &&
                                    remark.map((res: any, resIndex: number) => {
                                      if (res && res.labelId === Child.id) {
                                        return (
                                          <div
                                            className={styles.remark}
                                            key={resIndex}
                                          >
                                            <div
                                              className={styles.remark_content}
                                            >
                                              <div
                                                className={
                                                  styles.remark_content_header
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.remark_content_header_staff
                                                  }
                                                >
                                                  {avatar != null ? (
                                                    <img
                                                      src={avatar}
                                                      alt=""
                                                      className={
                                                        styles.remark_content_header_staff_0
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_0
                                                      }
                                                    ></div>
                                                  )}
                                                  <div
                                                    className={
                                                      styles.remark_content_header_staff_1
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_1_name
                                                      }
                                                    >
                                                      {res.remarkLabelUserName}
                                                      <span>
                                                        {parseResultName(
                                                         `${res.appraiseType}`
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_1_time
                                                      }
                                                    >
                                                      {res.createTime
                                                        ? dateFormat(
                                                            res.createTime,
                                                            'yyyy-MM-dd hh:mm:ss',
                                                          )
                                                        : '-'}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  styles.remark_content_foot
                                                }
                                              >
                                                {res.remarkContent}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                    {Child.remarkRecordDetails &&
                                    Child.remarkRecordDetails.map(
                                      (child: any, mCarIndex: number) => {
                                        return (
                                          <div
                                            key={`mRemarkRecordDetails-${mCarIndex}-${cIndex}`}
                                          >
                                            {child.mRemarkRecordDetails && 
                                            child.mRemarkRecordDetails.length >
                                              0 &&
                                              child.mRemarkRecordDetails.map(
                                                (
                                                  pchildren: any,
                                                  pcIndex: number,
                                                ) => {
                                                  return (
                                                    <div>
                                                      {pchildren.mRemarkRecordDetails.length > 0 &&
                                                        pchildren.mRemarkRecordDetails.map(
                                                          (
                                                            children: any,
                                                            rIndex: number,
                                                          ) => {
                                                            pchildren.mRemarkRecordDetails.sort(
                                                              (a: any, b: any) => {
                                                                if (
                                                                  a.appraiseType < 3
                                                                ) {
                                                                  return 1;
                                                                } else {
                                                                  return -1;
                                                                }
                                                              },
                                                            );
                                                            return (
                                                              <div
                                                                className={
                                                                  styles.footer
                                                                }
                                                                key={`mCarDealerVisitVo-${mCarIndex}-${cIndex}-${pcIndex}-${rIndex}`}
                                                                style={{
                                                                  display:
                                                                    moreMap[
                                                                      `${pcIndex}`
                                                                    ] || rIndex < 3
                                                                      ? 'block'
                                                                      : 'none',
                                                                }}
                                                              >
                                                                <div
                                                                  className={
                                                                    styles.remark
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_header
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                        style={{
                                                                          position:
                                                                            'relative',
                                                                        }}
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            children.url
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'static',
                                                                          }}
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff_1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            {children.remarkLabelUserName
                                                                              ? children.remarkLabelUserName
                                                                              : '-'}{' '}
                                                                            <span>
                                                                              {parseResultName(
                                                                                `${children.appraiseType}`
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_time
                                                                            }
                                                                          > 
                                                                            {children.createTime
                                                                              ? dateFormat(
                                                                                  children.createTime,
                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                )
                                                                              : '-'}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_foot
                                                                      }
                                                                    >
                                                                      {
                                                                        children.remarkContent
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                  {children.mRemarkRecordDetails &&
                                                                    children
                                                                      .mRemarkRecordDetails
                                                                      .length > 0 &&
                                                                    children.mRemarkRecordDetails.map(
                                                                      (
                                                                        remarkChild: any,
                                                                        key: number,
                                                                      ) => {
                                                                        children.mRemarkRecordDetails.sort(
                                                                          (
                                                                            a: any,
                                                                            b: any,
                                                                          ) => {
                                                                            if (
                                                                              a.appraiseType <=
                                                                              3
                                                                            ) {
                                                                              return 1;
                                                                            } else {
                                                                              return -1;
                                                                            }
                                                                          },
                                                                        );
                                                                        return (
                                                                          <div
                                                                            key={
                                                                              key
                                                                            }
                                                                            style={{
                                                                              background:
                                                                                '#EFF3F9',
                                                                              marginLeft:
                                                                                '50px',
                                                                              marginBottom:
                                                                                '10px',
                                                                              borderRadius:
                                                                                '8px',
                                                                            }}
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_p
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      className={
                                                                                        styles.remark_content_header_staff_0
                                                                                      }
                                                                                      src={
                                                                                        remarkChild.url
                                                                                      }
                                                                                      style={{
                                                                                        position:
                                                                                          'static',
                                                                                      }}
                                                                                      alt=""
                                                                                    />
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header_staff_1
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1_name
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          remarkChild.remarkLabelUserName
                                                                                        }{' '}
                                                                                        <span>
                                                                                          {' '}
                                                                                          {parseResultName(
                                                                                            `${remarkChild.appraiseType}`
                                                                                          )}{' '}
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1_time
                                                                                        }
                                                                                      >
                                                                                        {remarkChild.createTime
                                                                                          ? dateFormat(
                                                                                              remarkChild.createTime,
                                                                                              'yyyy-MM-dd hh:mm:ss',
                                                                                            )
                                                                                          : '-'}
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_child
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    remarkChild.remarkContent
                                                                                  }
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        );
                                                                      },
                                                                    )}
                                                                </div>
                                                              </div>
                                                            );
                                                          },
                                                        )}
                                                      {pchildren.mRemarkRecordDetails &&
                                                        pchildren
                                                          .mRemarkRecordDetails
                                                          .length > 3 && (
                                                          <div
                                                            style={{
                                                              textAlign: 'right',
                                                              color: 'blue',
                                                            }}
                                                            onClick={(e) => {
                                                              moreMap[
                                                                `${pcIndex}`
                                                              ] =
                                                                !moreMap[
                                                                  `${pcIndex}`
                                                                ];
                                                              setMoreMap({
                                                                ...moreMap,
                                                              });
                                                              e.stopPropagation();
                                                              e.preventDefault();
                                                            }}
                                                          >
                                                            {moreMap[`${pcIndex}`]
                                                              ? '折叠-'
                                                              : '展开+'}
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                },
                                              )}
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              );
                            },
                          )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Card>
          {/* <Card title={"其他价值点"}> */}
        </div>
      </div>
      <Picker
        columns={serveTypeList}
        visible={serveTypeVisible}
        onCancel={() => {
          setserveTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // setserveType(value[0]);
          infoRef.current.serveType = value[0];
          form.setFieldValue('serveTypeName', extend.items[0]?.label);
          onValuesChange();
          setserveTypeVisible(false);
        }}
      />
      <Picker
        columns={typeList}
        visible={visitSignTypeVisible}
        onCancel={() => {
          setvisitSignTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setvisitSignType(value[0]);
          infoRef.current.visitSignType = value[0];
          form.setFieldValue('visitSignTypeName', extend.items[0]?.label);
          setvisitReasonsVisible(false);
          form.setFieldValue('reasonForFailure', "");
          onValuesChange();
          setvisitSignTypeVisible(false);
        }}
      />
      <Picker
        columns={visitList || visitResultList}
        visible={visitResultVisible}
        onCancel={() => {
          setvisitResultVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setvisitResult(value[0]);
          // @ts-ignore
          localStorage.setItem("visitResult",value[0])
          form.setFieldValue('visitResultName', extend.items[0]?.label);
          onValuesChange();
          setvisitResultVisible(false);
        }}
      />
      {infoRef.current.visitSignType === 2 && (
        <Picker
          columns={reasonsForFailureNew}
          visible={visitReasonsVisible}
          onCancel={() => {
            setvisitReasonsVisible(false);
          }}
          onConfirm={(value, extend) => {
            console.log(value[0]);
            // @ts-ignore
            form.setFieldValue('reasonForFailure', extend.items[0]?.label);
            onValuesChange();
            setvisitReasonsVisible(false);
          }}
        />
      )}
      {infoRef.current.visitSignType != 2 && infoRef.current.visitSignType != 3 && (
        <Picker
          columns={reasonsForFailureAll}
          visible={visitReasonsVisible}
          onCancel={() => {
            setvisitReasonsVisible(false);
          }}
          onConfirm={(value, extend) => {
            console.log(value[0]);
            // @ts-ignore
            form.setFieldValue('reasonForFailure', extend.items[0]?.label);
            onValuesChange();
            setvisitReasonsVisible(false);
          }}
        />
      )}
      {infoRef.current.visitSignType === 3 && (
        <Picker
          columns={reasonsForFailure}
          visible={visitReasonsVisible}
          onCancel={() => {
            setvisitReasonsVisible(false);
          }}
          onConfirm={(value, extend) => {
            console.log(value[0]);
            // @ts-ignore
            form.setFieldValue('reasonForFailure', extend.items[0]?.label);
            onValuesChange();
            setvisitReasonsVisible(false);
          }}
        />
      )}
      <Picker
        columns={displayTypeList}
        visible={displayTypeVisible}
        onCancel={() => {
          setdisplayTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // setdisplayType(value[0]);
          infoRef.current.displayType = value[0];
          formComany.setFieldValue('displayTypeName', extend.items[0]?.label);
          onValuesChange();
          setdisplayTypeVisible(false);
        }}
      />
      <DatePicker
        visible={nextVisitTimeVisible}
        onClose={() => {
          setnextVisitTimeVisible(false);
        }}
        min={new Date()}
        title={'下次拜访时间'}
        precision="minute"
        defaultValue={dateJs()}
        renderLabel={labelRenderer}
        onConfirm={(val) => {
          const year = dayjs(val).year();
          const month = dayjs(val).month() + 1;
          const date = dayjs(val).date();
          const hour = dayjs(val).hour();
          const minute = dayjs(val).minute();
          let mon;
          let day;
          let hou;
          let min;
          if (month < 10) {
            mon = `0${month}`;
          } else {
            mon = month;
          }
          if (date < 10) {
            day = `0${date}`;
          } else {
            day = date;
          }
          if (hour < 10) {
            hou = `0${hour}`;
          } else {
            hou = hour;
          }
          if (minute < 10) {
            min = `0${minute}`;
          } else {
            min = minute;
          }
          const msg = `${year}-${mon}-${day} ${hou}:${min}`;
          form?.setFieldsValue({ nextVisitTime: msg });
        }}
      />
      <Picker
        columns={mediaLists}
        visible={mediaVisible}
        onCancel={() => {
          setmediaVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // setmediaType(value[0]);
          mediaform.setFieldValue('mediaName', extend.items[0]?.label);
          mediaform.setFieldValue('mediaType', value[0]);
          onValuesChange();
          setmediaVisible(false);
        }}
      />
      <Picker
        columns={orgPicket}
        visible={detectOrgNameVisible}
        onCancel={() => {
          setdetectOrgNameVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // settestOrgId(value[0]);
          testOrgIdform.setFieldValue('detectOrgName', extend.items[0]?.label);
          testOrgIdform.setFieldValue('testOrgId', value[0]);
          onValuesChange();
          setdetectOrgNameVisible(false);
        }}
      />
      <DatePicker
        visible={timeVisible}
        onClose={() => {
          setTimeVisible(false);
        }}
        title={'合作时间'}
        precision="month"
        renderLabel={labelRenderer}
        onConfirm={(val) => {
          const year = dayjs(val).year();
          const month = dayjs(val).month() + 1;
          const m = month < 10 ? `0${month}` : month;
          const msg = `${year}-${m}`;
          testOrgIdform?.setFieldsValue({ cooperationTime: msg });
          onValuesChange();
        }}
      />
      <Modal
        visible={visible}
        closeOnAction
        title={'合伙人信息'}
        content={
          <Form form={partnerform}>
            <List>
              <List.Item prefix="是否有合伙人">
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: '是否有合伙人' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanger} defaultValue={redioFlag}>
                    <Radio value={1}>有</Radio>
                    <Radio value={2}>无</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              {redioFlag === 1 && (
                <>
                  <List.Item prefix="合伙人姓名">
                    <Form.Item
                      name="partnerName"
                      rules={[{ required: true, message: '输入合伙人姓名' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="输入合伙人姓名" />
                    </Form.Item>
                  </List.Item>
                  <List.Item prefix="合伙人电话">
                    <Form.Item
                      name="partnerPhone"
                      rules={[{ required: true, message: '输入合伙人电话' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="输入合伙人电话" />
                    </Form.Item>
                  </List.Item>
                </>
              )}
            </List>
          </Form>
        }
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        onAction={() => {
          partnerform
            .validateFields()
            .then(() => {
              let num = partnerList.length;
              setpartnerNumber(num);
              const values = partnerform.getFieldsValue();
              partnerList[num] = values;
              values.companyId = localStorage.getItem('companyId');
              onValuesChange();
              delete values.type;
              setPartnerList([...partnerList]);
            })
            .catch(() => {
              setVisible(true);
            });
        }}
      ></Modal>
      <Modal
        visible={visible2}
        closeOnAction
        title={'自媒体信息'}
        content={
          <Form form={mediaform}>
            <List>
              <List.Item prefix="是否有自媒体">
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: '是否有自媒体' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanged}>
                    <Radio value={1}>有</Radio>
                    <Radio value={2}>无</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              {redioFlag2 === 1 && (
                <>
                  <List.Item
                    prefix="自媒体类型"
                    onClick={() => {
                      setmediaVisible(true);
                    }}
                  >
                    <Form.Item
                      name="mediaName"
                      rules={[{ required: true, message: '选择自媒体类型' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="选择自媒体类型" readOnly />
                    </Form.Item>
                  </List.Item>
                  <List.Item
                    prefix="自媒体类型"
                    onClick={() => {
                      setmediaVisible(true);
                    }}
                    style={{display:"none"}}
                  >
                    <Form.Item
                      name="mediaType"
                      rules={[{ required: true, message: '选择自媒体类型' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="选择自媒体类型" readOnly />
                    </Form.Item>
                  </List.Item>
                  <List.Item prefix="自媒体账号">
                    <Form.Item
                      name="account"
                      rules={[{ required: true, message: '输入自媒体账号' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="输入自媒体账号" />
                    </Form.Item>
                  </List.Item>
                  <List.Item prefix="粉丝数量" extra={'万'}>
                    <Form.Item
                      name="fansNum"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject(
                                new Error('输入粉丝数量(万)'),
                              );
                            }
                            if (Number.isFinite(Number(value)) === false) {
                              return Promise.reject(
                                new Error('不可输入文字！！'),
                              );
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <Input placeholder="输入粉丝数量(万)" />
                    </Form.Item>
                  </List.Item>
                </>
              )}
            </List>
          </Form>
        }
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setVisible2(false);
        }}
        showCloseButton
        onAction={() => {
          mediaform
            .validateFields()
            .then(() => {
              let num = mediaList.length;
              setmediaNumber(num);
              const values = mediaform.getFieldsValue();
              // values.mediaType = mediaType;
              values.companyId = localStorage.getItem('companyId');
    
              values.fansNum = parseInt(values.fansNum);
              delete values.type;
              mediaList[num] = values;
              onValuesChange();
              setmediaList([...mediaList]);
            })
            .catch(() => {
              setVisible2(true);
            });
        }}
      ></Modal>
      <Modal
        visible={visible3}
        closeOnAction
        title={'竞队信息'}
        content={
          <Form form={testOrgIdform}>
            <List>
              <List.Item prefix="是否有竞队信息">
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: '是否有竞队信息' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChangec}>
                    <Radio value={1}>有</Radio>
                    <Radio value={2}>无</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              {redioFlag3 === 1 && (
                <>
                  <List.Item
                    prefix="检测机构"
                    onClick={() => {
                      setdetectOrgNameVisible(true);
                    }}
                  >
                    <Form.Item
                      name="detectOrgName"
                      rules={[{ required: true, message: '选择检测机构' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="选择检测机构" readOnly />
                    </Form.Item>
                  </List.Item>
                  <List.Item
                    prefix="检测机构"
                    onClick={() => {
                      setdetectOrgNameVisible(true);
                    }}
                    style={{display:"none"}}
                  >
                    <Form.Item
                      name="testOrgId"
                      rules={[{ required: true, message: '选择检测机构' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="选择检测机构" readOnly />
                    </Form.Item>
                  </List.Item>
                  <List.Item
                    prefix="合作时间"
                    onClick={() => {
                      setTimeVisible(true);
                    }}
                  >
                    <Form.Item
                      name="cooperationTime"
                      rules={[{ required: true, message: '选择合作时间' }]}
                      className={styles.form_item}
                    >
                      <Input placeholder="选择合作时间" readOnly />
                    </Form.Item>
                  </List.Item>
                  <List.Item prefix="集采余额" extra={'万'}>
                    <Form.Item
                      name="purchaseBalance"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject(new Error('输入集采余额'));
                            }
                            if (Number.isFinite(Number(value)) === false) {
                              return Promise.reject(
                                new Error('不可输入文字！！'),
                              );
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <Input placeholder="输入集采余额" />
                    </Form.Item>
                  </List.Item>
                  <List.Item prefix="硬广覆盖">
                    <Form.Item
                      name="hardCover"
                      rules={[{ required: true, message: '选择是否硬广覆盖' }]}
                      className={styles.form_item}
                    >
                      <Radio.Group>
                        <Radio value={'1'}>是</Radio>
                        <Radio value={'0'}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </List.Item>
                </>
              )}
            </List>
          </Form>
        }
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setVisible3(false);
        }}
        showCloseButton
        onAction={() => {
          testOrgIdform
            .validateFields()
            .then(() => {
              let num = testOrgList.length;
              settestOrgNumber(num);
              const values = testOrgIdform.getFieldsValue();
              // values.testOrgId = testOrgId;
              values.companyId = localStorage.getItem('companyId');
    
              values.hardCover = parseInt(values.hardCover);
              if (values.cooperationTime) {
                values.cooperationTime = `${values.cooperationTime}-01 00:00:00`;
              }
              delete values.type;
              testOrgList[num] = values;
              console.log(testOrgList);
              onValuesChange();
              settestOrgList([...testOrgList]);
            })
            .catch(() => {
              setVisible3(true);
            });
        }}
      ></Modal>

      <Popup
        visible={visible4}
        showCloseButton
        onClose={() => {
          setVisible4(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          maxHeight: '90vh',
          minHeight: '50vh',
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          className={styles.remarkName}
        >
          {remarkChild.name}
        </div>
        <Form form={remarkform}>
          <List>
            <List.Item prefix="评分">
              <Form.Item name="appraiseType" className={styles.form_item}>
                <Radio.Group>
                  {/* <Radio value={'1'}>极好</Radio> */}
                  <Radio value={'2'}>好</Radio>
                  <Radio value={'3'}>中</Radio>
                  <Radio value={'4'}>差</Radio>
                </Radio.Group>
              </Form.Item>
            </List.Item>
            <TextArea
              placeholder="请输入备注"
              maxLength={100}
              rows={5}
              showCount
              value={remarkValue}
              onChange={(v) => {
                setRemarkValue(v);
              }}
            />
          </List>
        </Form>
        <div style={{ width: '95%', paddingBottom: '10px' }}>
          <Button
            block
            type="button"
            color="primary"
            onClick={() => {
              onRemarkFinish();
            }}
          >
            提交
          </Button>
        </div>
      </Popup>
      <div className={styles.steepper}>
        <Modal
          visible={priceVisible}
          closeOnAction
          title={'价位范围'}
          content={
            <div className={styles.steeppers}>
              <Form form={formPrice} className={styles.form_modal}>
                <Form.Item
                  name="priceA"
                  rules={[{ required: true, message: '选择价位' }]}
                  className={styles.form_item}
                >
                  <Stepper />
                </Form.Item>
                <span style={{ fontSize: '16px', margin: 'auto' }}>-</span>
                <Form.Item
                  name="priceB"
                  rules={[{ required: true, message: '选择价位' }]}
                  className={styles.form_item}
                >
                  <Stepper />
                </Form.Item>
              </Form>
            </div>
          }
          actions={[
            {
              key: 'confirm',
              text: '确定',
            },
          ]}
          onClose={() => {
            setPriceVisible(false);
          }}
          showCloseButton
          onAction={() => {
            const values = formPrice.getFieldsValue();
            console.log(values);
            values.priceA = values.priceA ? values.priceA : 0;
            values.priceB = values.priceB ? values.priceB : 0;
            if (values.priceA > values.priceB) {
              Toast.show({
                icon: 'fail',
                content: '价格区间格式错误!!! (A < B)',
              });
              return;
            }
            formCall.setFieldsValue({
              price: `${values.priceA}-${values.priceB}`,
            });
            onValuesChange();
            setPriceValue(values);
          }}
        ></Modal>

        <Picker
          columns={degreeIntentList}
          visible={degreeIntentVisible}
          onCancel={() => {
            setdegreeIntentVisible(false);
          }}
          onConfirm={(value, extend) => {
            // setdegreeIntent(value[0]);
            infoRef.current.degreeIntent = value[0];
            formComany.setFieldValue(
              'degreeIntentName',
              extend.items[0]?.label,
            );
            setdegreeIntentVisible(false);
          }}
        />
        <ImageViewer
          image={allImage}
          visible={imageVisible}
          onClose={() => {
            setImageVisible(false);
          }}
        />
        <Merchant
          visit={false}
          checkFlag={false}
          cityFlag={false}
          ButtonFlag={false}
          nameVisible={nameVisible}
          onSubmit={handelUserName}
          onCancel={handleUserNameCancel}
        />
        <Market
          nameVisible={marketVisible}
          onSubmit={handelUserMarket}
          onCancel={handleUserMarketCancel}
          value={false}
        />
        <Merchant
          visit={true}
          checkFlag={false}
          cityFlag={false}
          ButtonFlag={false}
          nameVisible={recommendationsVisible}
          onSubmit={handelrecommendationsUserName}
          onCancel={handleRecommendationsCancel}
        />
        <Popup
          visible={collectVisible}
          onMaskClick={() => {
            setCollectVisible(false);
          }}
          className={styles.popup}
          onClose={() => {
            setCollectVisible(false);
          }}
          bodyStyle={{ height: '40vh' , padding:"20px", display:'flex',flexDirection:"column"}}
        >
          <CheckList multiple={true} style={{flex:"1 1"}} onChange={onchanges}>
            {offeringsList.length > 0 &&
              offeringsList.map((item: any, index: number) => {
                return (
                  <CheckList.Item value={item}>
                    {item}
                  </CheckList.Item>
                );
              })}
          </CheckList>
          <div className={styles.footer_3}>
            <div className={styles.button} >
              <Button color='primary' fill='outline' onClick={()=>{
                  setCollectVisible(false);
              }}>
                取消
              </Button>
              <Button size='middle' color='primary' onClick={()=>{
                let str = ""
                if(checkCollect && checkCollect.length>0){
                  checkCollect.map((item:any)=>{
                    str = str + item + "、"
                  })
                }
                form.setFieldValue("productName",str)
                onValuesChange()
                setCollectVisible(false);
              }}>
                确定
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}
