// @ts-ignore
/* eslint-disable */
import styles from './index.less';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../utils/Navbar';
import { Button, Calendar, CapsuleTabs, Card, DatePicker, Form, InfiniteScroll, Input, List, Modal, Picker, Popup, PullToRefresh, Radio, SearchBar, Selector, Switch, Tabs, TextArea, Toast } from 'antd-mobile/es';
import AddSquareOutline from 'antd-mobile-icons/es/AddSquareOutline';
import FilterOutline from 'antd-mobile-icons/es/FilterOutline';
import RightOutline from 'antd-mobile-icons/es/RightOutline';
import 成功 from './img/成功.png';
import 失败 from './img/失败.png';
import 切换 from './img/切换.png';
import 上一条 from './img/上一条.png';
import 下一条 from './img/下一条.png';
import { history, useLocation } from 'umi';
import { amendment, cancel, ListMore, Lists, Lists1, Lists2, Lists3, recordSave } from './service';
import { dateFormat } from '../utils/util';
import dayjs from 'dayjs';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { visitResultList, visitSignTypeList } from '../utils/signIn';
import { getWxAccessToken } from '../login/service';
import { getUserInfo } from '../merchant/service';

export const parseResultName = (appraiseType:number) => {
  let result
  if(appraiseType === 1){
    result = "极好"
  }
  if(appraiseType === 2){
    result = "好"
  }
  if(appraiseType === 3){
    result = "中"
  }
  if(appraiseType === 4){
    result = "差"
  }
  return result;
};
export default function PayAVisit() {
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [formMore] = Form.useForm();
  const [remarkform] = Form.useForm();
  const [checkoutFlag, setCheckoutFlag] = useState<string>('0');
  const [data, setData] = useState<any[]>([])
  const [dataTow, setDataTow] = useState<any[]>([])
  const [data3, setData3] = useState<any[]>([])
  const [data4, setData4] = useState<any[]>([])
  const [remarkValue, setRemarkValue] = useState<any>([])
  const [companyPhone, setcompanyPhone] = useState<string>('')
  const [pageNo, setpageNo] = useState<number>(1)
  const [pageNo2, setpageNo2] = useState<number>(1)
  const [pageNo1, setpageNo1] = useState<number>(1)
  const [hasMore, setHasMore] = useState(true)
  const [timeVisible, setTimeVisible] = useState(false)
  const [teamFlag, setTeamFlag] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [visibleTime, setVisibleTime] = useState<boolean>(false)
  const [timeId, setTimeId] = useState<number>(-1)
  const [visitResultVisible, setvisitResultVisible] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(false)
  const [visitResult, setvisitResult] = useState<number>(-1)
  const [marketNameId, setmarketNameId] = useState<number>(-1)
  const [times, setTimes] = useState<any>([])
  const today = dayjs()
  const [timeMore, setTimeMore] = useState<[Date, Date] | null>(null)
  const [visitSignTypeVisible, setvisitSignTypeVisible] = useState<boolean>(false)
  const [visitSignType, setvisitSignType] = useState<number>(-1)
  const infoRef = useRef();
  const [userId, setUserId] = useState<any>([])
  const [visible4, setVisible4] = useState<boolean>(false)
  const [remarkChild, setRemarkChild] = useState<any>([])
  const [remarkRecordId, setremarkRecordId] = useState<any>([])
  const [mCarDealerVisitId, setmCarDealerVisitId] = useState<any>([])
  let token = localStorage.getItem("token")
  const location = useLocation();
  const dataFlag = location.state;
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年'
      case 'month':
        return data + '月'
      case 'day':
        return data + '日'
      case 'hour':
        return data + '时'
      case 'minute':
        return data + '分'
      case 'second':
        return data + '秒'
      default:
        return data
    }
  }, [])
  const onChange = (v: any) =>{
    console.log(v);
    setCheckoutFlag(v)
    setpageNo(1)
    if(v === "4"){
      loadMore({
        pageNo:-1,
        pageSize:1,
        status:v
      })
    }else{
      loadMore({
        pageNo:-1,
        pageSize:20,
        status:v
      })
    }
  }
  const onChanges = () =>{
    setCheck(!check)
    // setpageNo(1)
    loadMore({
      pageNo: -1,
      pageSize: 1,
      status:"4"
    })
  }
  const loadMore = async(params:any) => {
    if(params.pageNo === -1){
      setData([])
      setDataTow([])
      setData3([])
      setData4([])
      params.pageNo = 1
    }
    if(params.companyPhone === ""){
      delete params.companyPhone
    }else if(companyPhone != ""){
      params.companyPhone = companyPhone
    }
    let num =  params.pageNo + 1
    setpageNo(num)
    if(params.status === "4"){
      let num1 =  params.pageNo - 1
      if(num1>0){
          setpageNo1(num1)
      }else{
        setpageNo1(1)
        // Toast.show({
        //   icon: "fail",
        //   content: "已经是第一条数据"
        // })
      }
    }
    params.teamFlag = infoRef.current
    if(times.length>0){
      params.startTime = times[0]
      params.endTime = times[1]
    }
    if(visitResult != -1){
      params.visitResults = visitResult
    }
    if(marketNameId != -1){
      params.belongMarketIds = [marketNameId]
    }
    if(userId.length>0){
      params.userIds = userId
    }
    if(visitSignType != -1){
      params.visitSignType = visitSignType
    }
    const append = await Lists(params)
    console.log(append);
    
    if(append !=[]){
      if(params.status === "0"){
        const arr = append
        append.map((item:any,index:number)=>{
          let list:any = []
          let list2:any = []
          if(item.visitCompanyRelations){
            item.visitCompanyRelations.map((child:any)=>{
              if(child.completedFlag){
                list.push(child)
              }else{
                list2.push(child)
              }
            })
            arr[index].visitCompanyRelationsTrue = list
            arr[index].visitCompanyRelationsFalse = list2
          }
        })
        
        setData(val=>[...val,...arr])
      }
      if(params.status === "1"){
        setDataTow(val=>[...val,...append])
      }
      if(params.status === "3"){
        setData3(val=>[...val,...append])
      }
      if(params.status === "4"){
        if(check === false){
          setData4(val=>[...val,...append])
        }else{
          if(append.length>0){
            setData4(append)
          }else{
            setpageNo(num-1)
            Toast.show({
              icon: "fail",
              content: "没有下一条数据"
            })
          }
        }
      }
      setHasMore(append.length > 0)
    }
  }
  const onAmendment = async()=>{
    const values = forms.getFieldsValue();

    const msg = await amendment({
      visitingTime: values.forms,
      id:values.id,
      amendmentRemark:values.remarkValue
    })
    if(msg.retCode === 0){
      Toast.show({
        icon: 'success',
        content: msg.retMsg,
      })
      loadMore({
        pageNo:-1,
        pageSize:20,
        status:"0"
      })
      // pullListMore(checkoutFlag)
    }else{
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      })
    }
  }
  useEffect(() => {
    // @ts-ignore
    infoRef.current = teamFlag;
  }, [teamFlag]);
  const onDelete = async(id:any)=>{
    const values = form.getFieldsValue();
    console.log(values);
    
    const msg = await cancel({ id: id,cancelRemark:values.forms})
    if(msg.retCode === 0){
      Toast.show({
        icon: 'success',
        content: msg.retMsg,
      })
      loadMore({
        pageNo:-1,
        pageSize:20,
        status:"0"
      })
      // pullListMore(checkoutFlag)
    }else{
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      })
    }
  }
  
  const onSearch = async (value: string) => {
    const params: any = {
      pageNo: -1,
      pageSize: 20,
      companyPhone: value,
      status:checkoutFlag
    };
    setpageNo(1)
    setcompanyPhone(value)
    loadMore(params)
    // const msg = await ListMore(params);
    // if (msg.retCode === 0) {
    //   setData(msg.data.datas);
    //   console.log(msg.data.datas);
      
    // }
  };
  const team = async () =>{
    loadMore({
      pageNo: -1,
      pageSize: 20,
      status:checkoutFlag,
    })
  }
  const onRemarkFinish = async() =>{
    const values =  remarkform.getFieldsValue();
    if(remarkValue === ""){
      Toast.show({
        icon: 'fail',
        content: "请填写备注！",
        // duration: 0,
      })
      return
    }
    values.remarkContent = remarkValue
    values.labelId = remarkChild.labelId
    values.labelName = remarkChild.labelName
    values.parentId = remarkChild.id
    values.remarkLabelUserName = localStorage.getItem("name")
    values.url = localStorage.getItem("avatar")
    values.mCarDealerVisitId = mCarDealerVisitId
    values.remarkRecordId = remarkRecordId
    console.log(values);
    
    const msg = await recordSave(values)
    if(msg.retCode === 0){
      Toast.show({
        icon: 'success',
        content: msg.retMsg,
      })
      loadMore({
        pageNo:-1,
        pageSize:20,
        status: checkoutFlag
      })
    }else{
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      })
    }
    setVisible4(false)
    
  }
  
  const wxShareConfig = async() => {
    // callOnBD(["ZhuGe"])
    const msg = await getWxAccessToken({
      url: window.location.href,
    })
    console.log(wx);
    if(msg.retCode === 0){
      await wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ["onMenuShareTimeline"] 
      });
      await wx.agentConfig({
        corpid: "ww4168efadaace928a",
        agentid: "1000013",
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ["selectEnterpriseContact"],
        success: function(res) {
          // 回调
        },
        fail: function(res) {
          if(res.errMsg.indexOf('function not exist') > -1){
              alert('版本过低请升级')
          }
        }
      })
    
    }
    // @ts-ignore
    wx.invoke("selectEnterpriseContact", {
      "fromDepartmentId": 0,// 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
      "mode": "single",// 必填，选择模式，single表示单选，multi表示多选
      "type": ["department", "user"],// 必填，选择限制类型，指定department、user中的一个或者多个
      },
      function(res){
        console.log(res);
        if (res.err_msg == "selectEnterpriseContact:ok"){
          if(typeof res.result == 'string'){
            res.result = JSON.parse(res.result) //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          var selectedDepartmentList = res.result.departmentList;// 已选的部门列表
          for (var i = 0; i < selectedDepartmentList.length; i++){
            var department = selectedDepartmentList[i];
            var departmentId = department.id;// 已选的单个部门ID
            var departemntName = department.name;// 已选的单个部门名称
            Toast.show({
              icon: 'success',
              content: departmentId,
            })
          }
          var selectedUserList = res.result.userList; // 已选的成员列表
          let list = []
          for (var i = 0; i < selectedUserList.length; i++){
            var user = selectedUserList[i];
            var userId = user.id; // 已选的单个成员ID
            var userName = user.name;// 已选的单个成员名称
            var userAvatar= user.avatar;// 已选的单个成员头像
            list.push(userId)
          }
          setUserId(list)
          callOnBD(list)
        }
      }
    );
  }
  const callOnBD = async(list:any)=>{
    const msg = await getUserInfo({
      userIds:list
    })
    console.log(msg.data);
    let lists:any = []
    msg.data.map((item:any)=>{
      lists.push(item.name)
    })
    formMore?.setFieldsValue({"visitContent":lists[0]});
    // const arr = new Set(lists)
    // console.log([arr]);
  }
  const dataFlagValue = (list:any)=>{
    formMore?.setFieldsValue(list);
    if(list.payAVisit){
      setVisible(true)
      setvisitResult(list.visitResult)
      setvisitSignType(list.visitSignType)
      setUserId(list.userId)
      setTimeMore(list.timeMore)
      setTimes(list.times)
      setmarketNameId(list.value[0].id)
      formMore.setFieldValue("belongMarketName",list.value[0].marketName)
    }
  }
  useEffect(()=>{
    if(dataFlag){
      dataFlagValue(dataFlag)
    }
  },[])
  return(
    <div className={styles.page}>
      <div className={styles.all}>
        <Navbar name= {"拜访中心"}></Navbar>
        <Form layout='horizontal'>
          <Form.Item
            label={
              <div className={styles.team} onClick={()=>{
                setTeamFlag(!teamFlag)
                team()
              }}>
                <span>{teamFlag ? "团队" : "个人"}</span>
                {/* <span className={styles.team_triangles}></span> */}
                <img style={{marginTop:"3px"}} src={切换} width={10} alt="" />
              </div>
            }
            extra={
              <div className={styles.extraPart} onClick={()=>{
                history.push("./programme")
              }}>
                <AddSquareOutline /><span>新建计划</span>
              </div>
            }
          >
            <SearchBar
              onSearch={onSearch}
              // onClear={getReport}
              // style={{ '--background': '#E9ECF0'}}
              placeholder="输入手机号查询"
            />
          </Form.Item>
        </Form>
        <div className={styles.tabs}>
          <Tabs
            style={{
              '--title-font-size': '14px',
            }}
            onChange={onChange}
          >
            <Tabs.Tab title='待执行' key='0' />
            <Tabs.Tab title='已改约' key='1' />
            <Tabs.Tab title='已取消' key='3' />
            <Tabs.Tab title='已完成' key='4' />
          </Tabs>
          <div className={styles.extraPart} style={{marginRight:'5px'}} onClick={()=>{
            setVisible(true)
          }}>
           <span>更多筛选</span><FilterOutline /> 
          </div>
        </div>
        {/* {checkoutFlag === "4" && (
          <div style={{background:"white"}}>
            <CapsuleTabs>
              <CapsuleTabs.Tab title='全部' key='1' />
              <CapsuleTabs.Tab title='未决策' key='2' />
              <CapsuleTabs.Tab title='未管理' key='3' />
              <CapsuleTabs.Tab title='已完成' key='4' />
            </CapsuleTabs>
          </div>
        )} */}
        <div style={{overflow:"scroll"}}>
          {checkoutFlag === "0" && (
            <div>
              <PullToRefresh
                onRefresh={async () => {
                  await sleep(1000)
                  setpageNo(1)
                  loadMore({
                    pageNo: -1,
                    pageSize: 20,
                    status:"0"
                  })
                }}
              >
                {data && data.map((item:any,index:number)=>{
                  if(item.visitSignType != 12){
                  return (
                    <Card
                      title={
                        <div>
                          <span style={{fontWeight:"400",fontSize:"14px"}}>{item.visitSignTypeName}</span>
                          <span style={{marginLeft:"53px",fontWeight:"400",fontSize:"14px"}}>拜访{item.numberOfVisits ? item.numberOfVisits : 0}次</span>
                        </div>
                      }
                      extra={<div style={{color:"#FF5705",fontWeight:"400",fontSize:"12px"}}>待执行</div>}
                      style={{ borderRadius: '8px' }}
                    >
                      <div className={styles.content}  onClick={()=>{
                        const data = item
                        data.type = 0
                        if(item.status === 4){
                          history.push("./see",data)
                        }else{
                          history.push("./awaitingDetail",data)
                        }
                      }}>
                        <div className={styles.content_0}>
                          <div>拜访车商</div>
                          <span>{item.companyName ? item.companyName : "-"}</span>
                        </div>
                        <div className={styles.content_0}>
                          <div>拜访BD</div>
                          <span>{item.userName ? item.userName : "-"}</span>
                        </div>
                        <div className={styles.content_0}>
                          <div>拜访时间</div>
                          <span>{item.visitingTime ?dateFormat(item.visitingTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                        </div>
                        <div className={styles.content_0}>
                          <div>拜访内容</div>
                          <span>{item.visitContent.length>10 ? item.visitContent.substring(0, 10) + '...':item.visitContent}</span>
                        </div>
                      </div>
                      <div className={styles.footer}>
                        <div className={styles.content_0}>
                          <div>创建人</div>
                          <span>{item.createUserName ? item.createUserName : "-"}  {item.createTime ?dateFormat(item.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                        </div>
                        <div className={styles.footer_0}>
                          <div className={styles.footer_button} onClick={()=>{
                            Modal.confirm({
                              title: '取消原因',
                              content: <div className={styles.forms}>
                                <Form form={form}>
                                  <Form.Item
                                    name="forms"
                                    rules={[{ required: true, message: '请输入取消原因' }]}
                                    className={styles.form_item}
                                  >
                                  <TextArea
                                    placeholder='请输入取消原因'
                                    maxLength={100}
                                    rows={5}
                                    showCount
                                    onChange={(v)=>{
                                      console.log(v);
                                    }}
                                  />
                                  </Form.Item>
                                </Form>
                            </div>,
                              onConfirm: () => {
                                onDelete(item.id)
                              },
                            })
                          }}>
                            取消
                          </div>
                          <div className={styles.footer_button} onClick={()=>{
                            setTimeId(item.id)
                            Modal.confirm({
                              title: '改约',
                              content: <div>
                                <Form form={forms}>
                                  <List>
                                    <List.Item prefix="改约时间" onClick={()=>{
                                      setTimeVisible(true)
                                    }}>
                                      <Form.Item
                                        name="forms"
                                        rules={[{ required: true, message: '请选择改约时间' }]}
                                        className={styles.form_item}
                                      >
                                        <Input placeholder="请选择改约时间" readOnly />
                                      </Form.Item>
                                    </List.Item>
                                    <Form.Item name="remarkValue">
                                      <TextArea
                                        placeholder='请输入改约原因'
                                        maxLength={100}
                                        rows={5}
                                        showCount
                                        onChange={(v)=>{
                                          setRemarkValue(v)
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item style={{display:"none"}} name="id" initialValue={item.id}>
                                      <Input placeholder="请选择改约时间"/>
                                    </Form.Item>
                                  </List>
                                </Form>
                              </div>,
                              onConfirm: () => {
                                // setTimeVisible(true)
                                onAmendment()
                              },
                            })
                          }}>
                            改约
                          </div>
                          <div className={styles.footer_button}  onClick={()=>{
                            const data = item.mCompanyInfo
                            data.id = item.id
                            data.carLabelIds = item.carLabelIds
                            data.companyId = item.companyId
                            data.contactMobileNo = item.mCompanyInfo.contactMobileNo
                            data.contactName = item.mCompanyInfo.contactName
                            data.visitSignTypeName = item.visitSignTypeName
                            data.visitSignType = item.visitSignType
                            data.visitContent = item.visitContent
                            
                            console.log(data);
                            history.push("./see",data)
                          }}>
                            拜访
                          </div>
                        </div>
                      </div>
                    </Card>
                  )
                  }else{
                    console.log(data);
                    
                    return(
                    <Card
                      title={
                        <div>
                          <span style={{fontWeight:"400",fontSize:"14px"}}>{item.visitSignTypeName}</span>
                          <span style={{marginLeft:"53px",fontWeight:"400",fontSize:"14px"}}>拜访{item.numberOfVisits ? item.numberOfVisits : 0}次</span>
                        </div>
                      }
                      extra={<div style={{color:"#FF5705",fontWeight:"400",fontSize:"12px"}}>待执行</div>}
                      style={{ borderRadius: '8px' }}
                    >
                      <div className={styles.content}  onClick={()=>{
                        const data = item
                        data.type = 0
                        history.push("./awaitingDetail",data)
                      }}>
                        <div className={styles.com}>
                          {item.visitCompanyRelationsFalse && item.visitCompanyRelationsFalse.map((child:any,childIndex:number)=>{
                            return(
                              <div className={styles.completed}>
                                <div className={styles.completed_0} style={{opacity:"0"}}>待拜访车商</div>
                                <div className={styles.completed_1}>
                                  <div>{child.companyName}</div>
                                  <span style={{color:"rgba(255, 87, 5, 1)"}}>待拜访</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <div  className={styles.com}>
                          {item.visitCompanyRelationsTrue && item.visitCompanyRelationsTrue.map((child:any,childIndex:number)=>{
                            return(
                              <div className={styles.completed}>
                                <div className={styles.completed_0} style={{opacity:"0"}}>已拜访车商</div>
                                <div className={styles.completed_1}>
                                  <div>{child.companyName}</div>
                                  <span style={{color:"rgba(41, 165, 31, 1)"}}>已拜访</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className={styles.footer}>
                        <div className={styles.content_0}>
                          <div>创建人</div>
                          <span>{item.createUserName ? item.createUserName : "-"}  {item.createTime ?dateFormat(item.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                        </div>
                        <div className={styles.footer_0}>
                          <div className={styles.footer_button}  onClick={()=>{
                            const data = item
                            console.log(data);
                            history.push("./callOnSb",data)
                          }}>
                            拜访
                          </div>
                        </div>
                      </div>
                    </Card>
                    )
                  }
                })}
                <InfiniteScroll loadMore={()=>{
                  return loadMore({
                    pageNo:pageNo,
                    pageSize:20,
                    status:"0"
                  })
                }} hasMore={hasMore} />
              </PullToRefresh>
            </div>
          ) }
          {checkoutFlag === "1" && (
            <>
            <PullToRefresh
                onRefresh={async () => {
                  await sleep(1000)
                  setpageNo(1)
                  loadMore({
                    pageNo: -1,
                    pageSize: 20,
                    status:"1"
                  })
                }}
              >
            {dataTow && dataTow.map((item:any)=>{
              return(
              <Card
                title={
                  <div>
                    <span style={{fontWeight:"400",fontSize:"14px"}}>{item.visitSignTypeName}</span>
                    <span style={{marginLeft:"53px",fontWeight:"400",fontSize:"14px"}}>拜访{item.numberOfVisits ? item.numberOfVisits : 0}次</span>
                  </div>
                }
                extra={<div style={{color:"#FF5705",fontSize:"12px"}}>已改约</div>}
                style={{ borderRadius: '8px' }}
              >
                <div className={styles.content} onClick={()=>{
                    const data = item
                    data.type = 1
                    history.push("./awaitingDetail",item)
                  }}>
                  <div className={styles.content_0}>
                    <div>拜访车商</div>
                    <span>{item.companyName ? item.companyName : "-"}</span>
                  </div>
                  <div className={styles.content_0}>
                    <div>拜访BD</div>
                    <span>{item.userName ? item.userName : "-"}</span>
                  </div>
                  <div className={styles.content_0}>
                    <div>拜访时间</div>
                    <span>{item.visitingTime ?dateFormat(item.visitingTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                  </div>
                  <div className={styles.content_0}>
                    <div>拜访内容</div>
                    <span>{item.visitContent.length>10 ? item.visitContent.substring(0, 10) + '...':item.visitContent}</span>
                  </div>
                </div>
                  <div className={styles.footer}>
                    <div className={styles.content_0}>
                      <div>创建人</div>
                      <span>{item.createUserName ? item.createUserName : "-"}  {item.createTime ?dateFormat(item.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                    </div>
                    <div className={styles.footer_0}>
                      <div className={styles.footer_button} onClick={()=>{
                        Modal.confirm({
                          title: '取消原因',
                          content: <div className={styles.forms}>
                            <Form form={form}>
                              <Form.Item
                                name="forms"
                                rules={[{ required: true, message: '请输入取消原因' }]}
                                className={styles.form_item}
                              >
                              <TextArea
                                placeholder='请输入取消原因'
                                maxLength={100}
                                rows={5}
                                showCount
                                onChange={(v)=>{
                                  console.log(v);
                                }}
                              />
                              </Form.Item>
                            </Form>
                        </div>,
                          onConfirm: () => {
                            onDelete(item.id)
                          },
                        })
                      }}>
                        取消
                      </div>
                      <div className={styles.footer_button} onClick={()=>{
                            const data = item.mCompanyInfo
                            data.id = item.id
                            data.companyId = item.companyId
                            data.contactMobileNo = item.mCompanyInfo.contactMobileNo
                            data.contactName = item.mCompanyInfo.contactName
                            data.visitSignTypeName = item.visitSignTypeName
                            data.visitSignType = item.visitSignType
                            data.visitContent = item.visitContent
                            
                            console.log(data);
                            history.push("./see",data)
                          }}>
                        拜访
                      </div>
                    </div>
                  </div>
              </Card>
              )
            })}
            <InfiniteScroll loadMore={(isRetry)=>{
              console.log(isRetry);
              
              return loadMore({
                pageNo:pageNo,
                pageSize:20,
                status:"1"
              });
            }} hasMore={hasMore} />
            </PullToRefresh>
            </>
          ) }
          {checkoutFlag === "3" && (
            <>
            <PullToRefresh
                onRefresh={async () => {
                  await sleep(1000)
                  setpageNo(1)
                  loadMore({
                    pageNo: -1,
                    pageSize: 20,
                    status:"3"
                  })
                }}
              >
            {data3 && data3.map((item:any)=>{
              return(
              <Card
                title={
                  <div>
                    <span style={{fontWeight:"400",fontSize:"14px"}}>{item.visitSignTypeName}</span>
                    <span style={{marginLeft:"53px",fontWeight:"400",fontSize:"14px"}}>拜访{item.numberOfVisits ? item.numberOfVisits : 0}次</span>
                  </div>
                }
                extra={<div style={{color:"#FF5705",fontSize:"12px"}}>已取消</div>}
                style={{ borderRadius: '8px' }}
              >
                <div className={styles.content} onClick={()=>{
                    const data = item
                    data.type = 3
                    history.push("./awaitingDetail",item)
                  }}>
                  <div className={styles.content_0}>
                    <div>拜访车商</div>
                    <span>{item.companyName ? item.companyName : "-"}</span>
                  </div>
                  <div className={styles.content_0}>
                    <div>拜访BD</div>
                    <span>{item.userName ? item.userName : "-"}</span>
                  </div>
                  <div className={styles.content_0}>
                    <div>拜访时间</div>
                    <span>{item.visitingTime ?dateFormat(item.visitingTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                  </div>
                  <div className={styles.content_0}>
                    <div>拜访内容</div>
                    <span>{item.visitContent.length>10 ? item.visitContent.substring(0, 10) + '...':item.visitContent}</span>
                  </div>
                </div>
                  <div className={styles.footer}>
                    <div className={styles.content_0}>
                      <div>创建人</div>
                      <span>{item.createUserName} {item.createTime ?dateFormat(item.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                    </div>
                    <div className={styles.footer_0}>
                        <div className={styles.footer_button} onClick={()=>{
                            // history.push("./programme")
                            const data = item.mCompanyInfo
                            data.id = item.id
                            data.companyId = item.companyId
                            console.log(data);
                            history.push("./programme",data)
                          }}>
                          新建计划
                        </div>
                    </div>
                  </div>
              </Card>
              )
            })}
            <InfiniteScroll loadMore={(isRetry)=>{
              console.log(isRetry);
              
              return loadMore({
                pageNo:pageNo,
                pageSize:20,
                status:"3"
              });
            }} hasMore={hasMore} />
            </PullToRefresh>
            </>
          ) }
          {checkoutFlag === "4" && (
            <>
            {/* <div >切换</div> */}
            <PullToRefresh
              onRefresh={async () => {
                await sleep(1000)
                setpageNo(1)
                loadMore({
                  pageNo: -1,
                  pageSize: 1,
                  status:"4"
                })
              }}
            >
            {data4 && data4.map((item:any,index:number)=>{
              return(
                <Card
                  title={
                    <div id={`${index}`}>
                      <span style={{fontWeight:"400",fontSize:"14px"}}>{item.visitSignTypeName}</span>
                      <span style={{marginLeft:"53px",fontWeight:"400",fontSize:"14px"}}>拜访{item.numberOfVisits ? item.numberOfVisits : 0}次</span>
                    </div>
                  }
                  extra={<div style={{color:"#949494",fontSize:"12px"}}>已完成</div>}
                  style={{ borderRadius: '8px' }}
                  >
                  <div className={styles.content} onClick={()=>{
                    history.push("./visitDetail",item)
                  }}>
                    <div className={styles.content_0}>
                      <div>拜访车商</div>
                      <span>{item.companyName ? item.companyName : "-"}</span>
                    </div>
                    <div className={styles.content_0}>
                      <div>联系人</div>
                      <span>{item.createUserName ? item.createUserName : "-"}</span>
                    </div>
                    <div className={styles.content_0}>
                      <div>拜访结论</div>
                      <span>{item.visitContent ? item.visitContent : "-"}</span>
                    </div>
                    <div className={styles.content_0}>
                      <div>拜访BD</div>
                      <span>{item.userName ? item.userName : "-"}</span>
                    </div>
                    <div className={styles.content_0}>
                      <div>拜访时间</div>
                      <span>{item.visitingTime ?dateFormat(item.visitingTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
                    </div>
                    <div className={styles.content_0}>
                      <div>拜访内容</div>
                      <span>{(item.visitContent.length>10 ? item.visitContent.substring(0, 10) + '...':item.visitContent)}</span>
                    </div>
                    {(item.mCarDealerVisitVo && item.mCarDealerVisitVo.visitResult && item.mCarDealerVisitVo.visitResult ==="1") ? (<img src={失败} alt="" />) : (<img src={成功} alt="" />)}
                  </div>
                  {item.mCarDealerVisitVo && item.mCarDealerVisitVo.mRemarkRecordVos && (
                    <div className={styles.footer_1}>
                      <div className={styles.footer_b}>
                        <div>备注信息</div>
                        <span>已完成</span>
                      </div>
                    </div>
                  )}
                  {item.mCarDealerVisitVo && item.mCarDealerVisitVo.mRemarkRecordVos && item.mCarDealerVisitVo.mRemarkRecordVos.map((child:any)=>{
                    console.log(child);
                    return (
                      <div>
                        {child.remarkRecordDetails.length>0 && child.remarkRecordDetails.map((children:any)=>{
                        return(
                          <div className={styles.footer}>
                            <div className={styles.footer_2}>
                              <div className={styles.footer_2_title}>{children.labelName} <span>已完成</span></div>
                              <div className={styles.footer_2_extra}>这里是价值点的文案说明</div>
                            </div>
                            <div className={styles.remark}>
                              <div className={styles.remark_content}>
                                <div className={styles.remark_content_header}>
                                  <div className={styles.remark_content_header_staff}>
                                    <img  className={styles.remark_content_header_staff_0} src={children.url} alt="" />
                                    <div className={styles.remark_content_header_staff_1}>
                                      <div className={styles.remark_content_header_staff_1_name}>{children.remarkLabelUserName ? children.remarkLabelUserName : "-"} <span>{parseResultName(children.appraiseType)}</span></div>
                                      <div className={styles.remark_content_header_staff_1_time}>{children.createTime ?dateFormat(children.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</div>
                                    </div>
                                  </div>
                                  <div className={styles.remark_content_header_0} onClick={()=>{
                                    remarkform.resetFields()
                                    setVisible4(true)
                                    setRemarkChild(children)
                                    setmCarDealerVisitId(item.mCarDealerVisitVo.id)
                                    setRemarkValue('')
                                    setremarkRecordId(child.id)
                                  }}>备注</div>
                                </div>
                                <div className={styles.remark_content_foot}>{children.remarkContent}</div>
                              </div>
                                {children.mRemarkRecordDetails && children.mRemarkRecordDetails.length>0 && children.mRemarkRecordDetails.map((remarkChild:any)=>{
                                  return(
                                    <div style={{background:"#EFF3F9",marginLeft:"50px",marginBottom:"10px",borderRadius:"8px"}}>
                                      <div className={styles.remark_p}>
                                        <div className={styles.remark_content}>
                                          <div className={styles.remark_content_header}>
                                            <div className={styles.remark_content_header_staff}>
                                              <img  className={styles.remark_content_header_staff_0} src={remarkChild.url} alt="" />
                                              <div className={styles.remark_content_header_staff_1}>
                                                <div className={styles.remark_content_header_staff_1_name}>{remarkChild.remarkLabelUserName} <span>{parseResultName(remarkChild.appraiseType)}</span></div>
                                                <div className={styles.remark_content_header_staff_1_time}>{remarkChild.createTime ?dateFormat(remarkChild.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className={styles.remark_child}>{remarkChild.remarkContent}</div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                            </div>
                          </div>
                        )
                      })}
                      </div>
                    )

                  })}
                  <div className={styles.footer_0}>
                    <div className={styles.footer_button} 
                      onClick={()=>{
                      // history.push("./programme")
                      const data = item.mCarDealerVisitVo
                      // data.id = item.id
                      data.companyId = item.companyId
                      data.companyName = item.companyName
                      console.log(data);
                      history.push("./programme",data)
                    }}>
                      新建计划
                    </div>
                    <div className={styles.footer_button}  onClick={()=>{
                      const data = item.mCarDealerVisitVo
                      // data.id = item.id
                      data.companyId = item.companyId
                      data.companyName = item.companyName
                      data.contactMobileNo = item.mCompanyInfo.contactMobileNo
                      data.contactName = item.mCompanyInfo.contactName
                      data.visitSignTypeName = item.visitSignTypeName
                      data.visitSignType = item.visitSignType
                      data.visitContent = item.visitContent
                      console.log(data);
                      history.push("./see",data)
                    }}>
                      拜访
                    </div>
                  </div>
                  <div  className={styles.menu_next}>
                    <div className={styles.check}>
                      {/* <Switch  onChange= {onChanges} checked={check} uncheckedText='加载模式' checkedText='单页模式' /> */}
                      <img style={{marginTop:"3px"}} src={切换} width={10} alt="" />
                      <div onClick={()=>{
                        onChanges()
                      }}>{check ? "单页" : "列表"}</div>
                    </div>
                    {check && (
                      <div>
                        <img src={上一条} alt="" onClick={()=>{
                          loadMore({
                            pageNo:pageNo1,
                            pageSize:1,
                            status:"4"
                          })
                        }} />
                        <img src={下一条} alt=""  onClick={()=>{
                          loadMore({
                            pageNo:pageNo,
                            pageSize:1,
                            status:"4"
                          })
                        }} />
                      </div>
                    )}
                  </div>
                </Card>
              )
            })}
            {!check && (
              <InfiniteScroll loadMore={(isRetry)=>{
                console.log(isRetry);
                return loadMore({
                  pageNo:pageNo,
                  pageSize:1,
                  status:"4"
                });
              }} hasMore={hasMore} />
            )}
            </PullToRefresh>
            </>
          ) }
        </div>
        <DatePicker
          visible={timeVisible}
          onClose={() => {
            setTimeVisible(false)
          }}
          title={"选择改约时间"}
          precision='minute'
          renderLabel={labelRenderer}
          onConfirm={val => {
            const year = dayjs(val).year()
            const month = dayjs(val).month() + 1
            const date = dayjs(val).date()
            const hour = dayjs(val).hour()
            const minute = dayjs(val).minute()
            let mon
            let day
            let hou
            let min
            if(month<10){
              mon = `0${month}`
            }else{
              mon=month
            }
            if(date<10){
              day = `0${date}`
            }else{
              day=date
            }
            if(hour<10){
              hou = `0${hour}`
            }else{
              hou=hour
            }
            if(minute<10){
              min = `0${minute}`
            }else{
              min=minute
            }
            const msg = `${year}-${mon}-${day} ${hou}:${min}`
            forms?.setFieldsValue({ forms: msg });
          }}
        />
        <Popup
          visible={visible}
          showCloseButton
          onClose={() => {
            setVisible(false)
          }}
          bodyStyle={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            minHeight: '50vh',
          }}
        >
        <div className={styles.more}>更多筛选</div>
        <Form form={formMore}>
          <List>
            <List.Item prefix="拜访结果" 
              onClick={()=>{
                setvisitResultVisible(true)
              }}
            >
              <Form.Item
                name="visitResultName"
                className={styles.form_item}
              >
                <Input placeholder="选择拜访结果" readOnly/>
              </Form.Item>
            </List.Item>
            <List.Item prefix="拜访时间段"
              onClick={()=>{
                setVisibleTime(true)
              }}
            >
              <Form.Item
                name="time"
                className={styles.form_item}
              >
                <Input placeholder="选择拜访时间段" readOnly/>
              </Form.Item>
            </List.Item>
            <List.Item prefix="拜访BD"
              onClick={()=>{
                wxShareConfig()
              }}
            >
              <Form.Item
                name="visitContent"
                className={styles.form_item}
              >
                <Input placeholder="选择拜访BD" readOnly/>
              </Form.Item>
            </List.Item>
            <List.Item prefix="选择市场"
              onClick={()=>{
                const values = formMore.getFieldsValue();
                values.payAVisit = true
                values.visitResult = visitResult
                values.userId = userId
                values.timeMore = timeMore
                values.times = times
                values.visitSignType = visitSignType
                history.replace("./market",values)
              }}
            >
              <Form.Item
                name="belongMarketName"
                className={styles.form_item}
              >
                <Input placeholder="选择市场" readOnly/>
              </Form.Item>
            </List.Item>
            <List.Item prefix="拜访类型" 
              onClick={()=>{
                setvisitSignTypeVisible(true)
              }}
            >
              <Form.Item
                name="visitSignTypeName"
                className={styles.form_item}
              >
                <Input placeholder="选择拜访类型" readOnly/>
              </Form.Item>
            </List.Item>
          </List>
        </Form>
          <div className={styles.time_button}>
            <Button color='primary' fill='outline' onClick={()=>{
              formMore.setFieldValue("time",'')
              formMore.setFieldValue("visitResultName",'')
              formMore.setFieldValue("visitContent",'')
              formMore.setFieldValue("belongMarketName",'')
              formMore.setFieldValue("visitSignTypeName",'')
              setTimeMore(null)
              setTimes([])
              setvisitResult(-1)
              setUserId([])
              setmarketNameId(-1)
              setvisitSignType(-1)
            }}>
              重置
            </Button>
            <Button size='middle' color='primary' onClick={()=>{
              setVisible(false)
              loadMore({
                pageNo:-1,
                pageSize:20,
                status:checkoutFlag
              })
            }}>
              确定
            </Button>
          </div>
        </Popup>
        <Picker
          columns={visitResultList}
          visible={visitResultVisible}
          onCancel={()=>{
            setvisitResultVisible(false)
          }}
          onConfirm={(value,extend)=>{
            console.log(value[0]);
            setvisitResult(value[0])
            formMore.setFieldValue("visitResultName",extend.items[0]?.label)
            setvisitResultVisible(false)
          }}
        />
        <Popup
          visible={visibleTime}
          showCloseButton
          onClose={() => {
            setVisibleTime(false)
          }}
          position='right'
          bodyStyle={{ width: '100vw' }}
        >
          {/* {mockContent} */}
          <div className={styles.mores}>选择时间段</div>
          <Calendar
            // defaultValue={defaultRange}
            className={styles.calendar_custom}
            selectionMode='range'
            onChange={val => {
              console.log(val)
              setTimeMore(val)
            }}
            value={timeMore}
          />
          <div className={styles.time_button}>
            <Button color='primary' fill='outline' onClick={()=>{
              setTimeMore(null)
            }}>
              重置
            </Button>
            <Button size='middle' color='primary' onClick={()=>{
              if(timeMore!=null){
                // console.log(dateFormat(timeMore[0],"Y-MM-dd hh:mm:ss"));
                let startTime = dateFormat(timeMore[0],"Y-MM-dd hh:mm:ss")
                let endTime = dateFormat(timeMore[1],"Y-MM-dd hh:mm:ss")
                const times = [startTime,endTime]
                setTimes(times)
                formMore.setFieldValue("time",`${startTime}~${endTime}`)
                setVisibleTime(false)
              }
            }}>
              确定
            </Button>
          </div>
        </Popup>
        <Picker
          columns={visitSignTypeList}
          visible={visitSignTypeVisible}
          onCancel={()=>{
            setvisitSignTypeVisible(false)
          }}
          onConfirm={(value,extend)=>{
            console.log(value[0]);
            setvisitSignType(value[0])
            formMore.setFieldValue("visitSignTypeName",extend.items[0]?.label)
            setvisitSignTypeVisible(false)
          }}
        />
      <Popup
        visible={visible4}
        showCloseButton
        onClose={() => {
          setVisible4(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
          paddingBottom: "20px"
        }}
      >
        {/* {console.log(remarkChild)} */}
        
      <Form form={remarkform}>
        <List>
          <div  className={styles.formList}>
            <div className={styles.remark_p}>
              <div className={styles.remark_content}>
                <div className={styles.remark_content_header}>
                  <div className={styles.remark_content_header_staff}>
                    <img  className={styles.remark_content_header_staff_0} src={remarkChild.url} alt="" />
                    <div className={styles.remark_content_header_staff_1}>
                      <div className={styles.remark_content_header_staff_1_name}>{remarkChild.remarkLabelUserName? remarkChild.remarkLabelUserName: "-"} <span>{parseResultName(remarkChild.appraiseType)}</span></div>
                      <div className={styles.remark_content_header_staff_1_time}>{remarkChild.createTime ?dateFormat(remarkChild.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.remark_child}>{remarkChild.remarkContent}</div>
              </div>
            </div>
          </div>
          {/* <List.Item prefix="是否已完成">
            <Form.Item
              name="finishType"
              // rules={[{ required: true, message: '选择是否硬广覆盖' }]}
              className={styles.form_item}
            >
              <Radio.Group>
                <Radio value={"1"}>已完成</Radio>
                <Radio value={"2"}>未完成</Radio>
                <Radio value={"3"}>未选择</Radio>
              </Radio.Group>
            </Form.Item>
          </List.Item> */}
          <List.Item prefix="评分">
            <Form.Item
              name="appraiseType"
              // rules={[{ required: true, message: '选择是否硬广覆盖' }]}
              className={styles.form_item}
            >
              <Radio.Group>
                {/* <Radio value={"1"}>极好</Radio> */}
                <Radio value={"2"}>好</Radio>
                <Radio value={"3"}>中</Radio>
                <Radio value={"4"}>差</Radio>
              </Radio.Group>
            </Form.Item>
          </List.Item>
          <TextArea
            placeholder='请输入备注'
            maxLength={100}
            rows={3}
            showCount
            value={remarkValue}
            onChange={(v)=>{
              setRemarkValue(v)
            }}
          />
        </List>
      </Form>
        <div className={styles.button_4}>
          <Button block type="submit" color="primary" onClick={()=>{
            onRemarkFinish()
          }}>
            提交
          </Button>
        </div>
      </Popup>
      </div>
    </div>
  )
}