// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { Toast } from 'antd-mobile';
let count = 1;
let count1 = 1;
let count2 = 1;
let count3 = 1;
export async function ListMore(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function cancel(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/cancel', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function amendment(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/amendment', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getRemarkRecord(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/getRemarkRecord', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function recordSave(data: any, options?: { [key: string]: any }) {
  delete data.createTime
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/remark/record/save', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function breakthroughLogicInTheRegione(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/breakthroughLogicInTheRegion', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function saveScreenshot(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/saveScreenshot', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function redisCount(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/redis/count', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function visitRemarks(data: any, options?: { [key: string]: any }) {
  delete data.createTime
  if(data.parentId === -1){
    delete data.parentId
  }
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/visitRemarks', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function Lists(data: any, options?: { [key: string]: any }) {
  const params = data;
  // params.token = localStorage.getItem('token');
  if(params.status === '6'|| params.status === 6 ){
    params.status = '0'
    params.planFlag = true
  }else{
    if(params.status != '0' && params.status != 0){
      params.planFlag = false

    }
  }
  if (params.status === '4' || params.status === 4) {
    params.annotationFlag = 0;
  }
  if (params.status === '0' || params.status === 0) {
    params.statusIn = [0,5];
    delete params.status
  }
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0 ) {
    return msg.data
  } else {
    Toast.show({
      icon:"fail",
      content: msg.retMsg
    })
    return [];
  }
}
export async function listAnnotate(data: any, options?: { [key: string]: any }) {
  const params = data;
  // params.token = localStorage.getItem('token');
  if(data.status === "5"){
    params.status = "4"
  }
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/listAnnotate', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0 ) {
    return msg.data
  } else {
    Toast.show({
      icon:"fail",
      content: msg.retMsg
    })
    return [];
  }
}
export async function Lists1(data: any, options?: { [key: string]: any }) {
  const params = data;
  params.pageNo = count1;
  params.pageSize = 20;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  count1++;
  if (msg.retCode === 0 && msg.data.datas) {
    return msg.data.datas;
  } else {
    return [];
  }
}
export async function Lists2(data: any, options?: { [key: string]: any }) {
  const params = data;
  params.pageNo = count2;
  params.pageSize = 20;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  count2++;
  if (msg.retCode === 0 && msg.data.datas) {
    return msg.data.datas;
  } else {
    return [];
  }
}
export async function Lists3(data: any, options?: { [key: string]: any }) {
  const params = data;
  params.pageNo = count3;
  params.pageSize = 20;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  count3++;
  if (msg.retCode === 0 && msg.data.datas) {
    return msg.data.datas;
  } else {
    return [];
  }
}
