import SpinLoading from "antd-mobile/es/components/spin-loading";
import Toast from "antd-mobile/es/components/toast";
import { useEffect, useState } from "react";
import styles from './index.less';
import { history,useLocation } from "umi";
import { Button, Card, Form, List, Popup, Radio, TextArea } from "antd-mobile";
import { dateFormat } from "../utils/util";
import { parseResultName } from "../PayAVisit";
import 成功 from './img/成功.png';
import 失败 from './img/失败.png';
import { recordSave } from "../PayAVisit/service";
import { checkInDetails } from "./service";

export default function visitDetail() {
  const location = useLocation();
  const dataFalg:any = location.state;
  const [remarkValue, setRemarkValue] = useState<any>([])
  const [visible4, setVisible4] = useState<boolean>(false)
  const [remarkChild, setRemarkChild] = useState<any>([])
  const [remarkRecordId, setremarkRecordId] = useState<any>([])
  const [mCarDealerVisitId, setmCarDealerVisitId] = useState<any>([])
  const [dataFalgs, setdataFalgs] = useState<any>([])
  const [remarkform] = Form.useForm();
  const onRemarkFinish = async() =>{
    const values =  remarkform.getFieldsValue();
    if(remarkValue === ""){
      Toast.show({
        icon: 'fail',
        content: "请填写备注！",
        // duration: 0,
      })
      return
    }
    values.remarkContent = remarkValue
    values.labelId = remarkChild.labelId
    values.labelName = remarkChild.labelName
    values.parentId = remarkChild.id
    values.remarkLabelUserName = localStorage.getItem("name")
    values.url = localStorage.getItem("avatar")
    values.mCarDealerVisitId = mCarDealerVisitId
    values.remarkRecordId = remarkRecordId
    console.log(values);
    
    const msg = await recordSave(values)
    if(msg.retCode === 0){
      Toast.show({
        icon: 'success',
        content: msg.retMsg,
      })
      initValue()
    }else{
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      })
    }
    setVisible4(false)
  }
  const initValue = async()=>{
    const append = await checkInDetails({
      id:dataFalg.id
    })
    if(append.retCode === 0){
      setdataFalgs(append.data)
    }
  }
  useEffect(() => {
    if(dataFalg){
      initValue()
    }
  }, []);
  return(
    <div className={styles.detail}>
       <Card
        title={
          <div>
            <span style={{fontWeight:"400",fontSize:"14px"}}>{dataFalgs.visitSignTypeName}</span>
            <span style={{marginLeft:"37px",fontWeight:"400",fontSize:"14px"}}>拜访{dataFalgs.numberOfVisits ? dataFalgs.numberOfVisits : 0}次</span>
          </div>
        }
        extra={<div style={{color:"#949494",fontSize:"12px"}}>已完成</div>}
        style={{ borderRadius: '8px' }}
        >
        <div className={styles.content}>
          {dataFalgs.mCompanyInfo && (
            <>
            <div className={styles.content_0}>
              <div>拜访车商</div>
              <span>{dataFalgs.mCompanyInfo.companyName ? dataFalgs.mCompanyInfo.companyName : "-"}</span>
            </div>
            <div className={styles.content_0}>
              <div>联系人</div>
              <span>{dataFalgs.mCompanyInfo.contactName ? dataFalgs.mCompanyInfo.contactName : "-"}</span>
            </div>
            <div className={styles.content_0}>
              <div>拜访结论</div>
              <span>{dataFalgs.visitContent ? dataFalgs.visitContent : "-"}</span>
            </div>
            <div className={styles.content_0}>
              <div>拜访BD</div>
              <span>{dataFalgs.mCompanyInfo.marketSalesManName ? dataFalgs.mCompanyInfo.marketSalesManName : "-"}</span>
            </div>
            <div className={styles.content_0}>
              <div>拜访时间</div>
              <span>{dataFalg.visitingTime ?dateFormat(dataFalg.visitingTime,"yyyy-MM-dd hh:mm:ss") : "-"}</span>
            </div>
            </>
          )}
          {(dataFalgs&& dataFalgs.visitResult && dataFalgs.visitResult ==="1") ? (<img src={失败} alt="" />) : (<img src={成功} alt="" />)}
        </div>
        
        {dataFalgs.mCarDealerPartners && (
          <>
          <div className={styles.tabs_content_0}>
            <div>合伙人</div>
            <div>{dataFalgs.mCarDealerPartners.length}</div>
          </div>
          {dataFalgs.mCarDealerPartners.map((item:any)=>{
            return(  
              <div className={styles.tabs_content_1}>
                <div className={styles.tabs_content_1_0}>
                  <div>合伙人姓名</div>
                  <div>{item.partnerName}</div>
                </div>
                <div className={styles.tabs_content_1_0}>
                  <div>合伙人电话</div>
                  <div>{item.partnerPhone}</div>
                </div>
              </div>
            )
          })}
          </>
        )}
        {dataFalgs.mCarDealerMedia &&dataFalgs.mCarDealerMedia.length>0 && (
          <>
            <div className={styles.tabs_content_0}>
              <div>自媒体信息</div>
              <div></div>
            </div>
            {dataFalgs.mCarDealerMedia.map((item:any)=>{
              return(
                <div className={styles.tabs_content_1}>
                  <div className={styles.tabs_content_1_0}>
                    <div>自媒体</div>
                    <div>{item.mediaName}</div>
                  </div>
                  <div className={styles.tabs_content_1_0}>
                    <div>账号</div>
                    <div>{item.account}</div>
                  </div>
                  <div className={styles.tabs_content_1_0}>
                    <div>粉丝量</div>
                    <div>{item.fansNum}万</div>
                  </div>
                </div>
              )
            })}
          </>
        )}
        {dataFalgs.mRemarkRecordVos && (
          <div className={styles.footer_1}>
            <div className={styles.footer_b}>
              <div>备注信息</div>
              <span>已完成</span>
            </div>
          </div>
        )}
        {dataFalgs.mRemarkRecordVos && dataFalgs.mRemarkRecordVos.map((child:any)=>{
          console.log(child);
          return (
            <div>
              {child.remarkRecordDetails.length>0 && child.remarkRecordDetails.map((children:any)=>{
              return(
                <div className={styles.footer}>
                  <div className={styles.footer_2}>
                    <div className={styles.footer_2_title}>{children.labelName} <span>已完成</span></div>
                    <div className={styles.footer_2_extra}>这里是价值点的文案说明</div>
                  </div>
                  <div className={styles.remark}>
                    <div className={styles.remark_content}>
                      <div className={styles.remark_content_header}>
                        <div className={styles.remark_content_header_staff}>
                          <img  className={styles.remark_content_header_staff_0} src={children.url} alt="" />
                          <div className={styles.remark_content_header_staff_1}>
                            <div className={styles.remark_content_header_staff_1_name}>{children.remarkLabelUserName ? children.remarkLabelUserName : "-"} <span>{parseResultName(children.appraiseType)}</span></div>
                            <div className={styles.remark_content_header_staff_1_time}>{children.createTime ? dateFormat(children.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</div>
                          </div>
                        </div>
                        <div className={styles.remark_content_header_0} onClick={()=>{
                          remarkform.resetFields()
                          setVisible4(true)
                          setRemarkChild(children)
                          // setmCarDealerVisitId(dataFalgs.mCarDealerVisitVo.id)
                          setRemarkValue('')
                          setremarkRecordId(child.id)
                        }}>备注</div>
                      </div>
                      <div className={styles.remark_content_foot}>{children.remarkContent}</div>
                    </div>
                    <div style={{background:"#EFF3F9",marginLeft:"50px",borderRadius:"8px"}}>
                      {children.mRemarkRecordDetails && children.mRemarkRecordDetails.length>0 && children.mRemarkRecordDetails.map((remarkChild:any)=>{
                        return(
                          <div className={styles.remark_p}>
                            <div className={styles.remark_content}>
                              <div className={styles.remark_content_header}>
                                <div className={styles.remark_content_header_staff}>
                                  <img  className={styles.remark_content_header_staff_0} src={remarkChild.url} alt="" />
                                  <div className={styles.remark_content_header_staff_1}>
                                    <div className={styles.remark_content_header_staff_1_name}>{remarkChild.remarkLabelUserName} <span>{parseResultName(remarkChild.appraiseType)}</span></div>
                                    <div className={styles.remark_content_header_staff_1_time}>{remarkChild.createTime ?dateFormat(remarkChild.createTime,"yyyy-MM-dd hh:mm:ss") : "-"}</div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.remark_child}>{remarkChild.remarkContent}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
            </div>
          )

        })}
        <div className={styles.footer_0}>
          <div className={styles.footer_button} 
            onClick={()=>{
            // history.push("./programme")
            const data = dataFalgs.mCarDealerVisitVo
            // data.id = dataFalgs.id
            data.companyId = dataFalgs.companyId
            data.companyName = dataFalgs.companyName
            console.log(data);
            history.push("./programme",data)
          }}>
            新建计划
          </div>
          <div className={styles.footer_button}  onClick={()=>{
            const data = dataFalgs.mCarDealerVisitVo
            // data.id = dataFalgs.id
            data.companyId = dataFalgs.companyId
            data.companyName = dataFalgs.companyName
            data.contactMobileNo = dataFalgs.mCompanyInfo.contactMobileNo
            data.contactName = dataFalgs.mCompanyInfo.contactName
            // 
            console.log(data);
            history.replace("./see",data)
          }}>
            拜访
          </div>
        </div>
      </Card>
      <Popup
        visible={visible4}
        showCloseButton
        onClose={() => {
          setVisible4(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
        }}
      >
      <Form form={remarkform}>
        <List>
          {/* <List.Item prefix="是否已完成">
            <Form.Item
              name="finishType"
              // rules={[{ required: true, message: '选择是否硬广覆盖' }]}
              className={styles.form_item}
            >
              <Radio.Group>
                <Radio value={"1"}>已完成</Radio>
                <Radio value={"2"}>未完成</Radio>
                <Radio value={"3"}>未选择</Radio>
              </Radio.Group>
            </Form.Item>
          </List.Item> */}
          <List.Item prefix="评分">
            <Form.Item
              name="appraiseType"
              // rules={[{ required: true, message: '选择是否硬广覆盖' }]}
              className={styles.form_item}
            >
              <Radio.Group>
                {/* <Radio value={"1"}>极好</Radio> */}
                <Radio value={"2"}>好</Radio>
                <Radio value={"3"}>中</Radio>
                <Radio value={"4"}>差</Radio>
              </Radio.Group>
            </Form.Item>
          </List.Item>
          <TextArea
            placeholder='请输入备注'
            maxLength={100}
            rows={5}
            showCount
            value={remarkValue}
            onChange={(v)=>{
              setRemarkValue(v)
            }}
          />
        </List>
      </Form>
      <Button block type="submit" color="primary" onClick={()=>{
        onRemarkFinish()
      }}>
        提交
      </Button>
      </Popup>
    </div>
  )
}