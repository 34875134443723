// @ts-ignore
/* eslint-disable */
import {
  Button,
  Card,
  CheckList,
  DatePicker,
  Empty,
  Form,
  Image,
  ImageViewer,
  Input,
  List,
  Modal,
  Picker,
  Popup,
  Radio,
  Slider,
  Stepper,
  Swiper,
  SwiperRef,
  Tag,
  TextArea,
  Toast,
} from 'antd-mobile/es';
import { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import {
  BrandTypeList,
  cooperationFrequencyList,
  customerList,
  degreeIntentList,
  detectionMethodList,
  displayTypeList,
  mediaLists,
  partnershipList,
  priceRangeList,
  reasonsForFailure,
  reasonsForFailureAll,
  reasonsForFailureNew,
  recommendedReasonsList,
  serveTypeList,
  vehicleTypeList,
  visitResultList,
} from '../utils/signIn';
import {
  getAddress,
  orglist,
  save,
} from './service';
import dayjs from 'dayjs';
import { history, useLocation } from 'umi';
import { labellist, remarkLists, typelist } from '../programme/service';
import { getWxAccessToken } from '../login/service';
import { dateFormat } from '../utils/util';
import Login from '../login';
import { getMCarDealerVisitVo } from '../details/service';
import Merchant from '../comments/Merchant';
import Market from '../comments/Market';
import {
  CameraOutline,
  DeleteOutline,
  EditSOutline,
} from 'antd-mobile-icons';
import { Lists } from '../merchant/service';
import { infoSave } from '../signIn/service';
export const isIOS = () => {
  var ua = navigator.userAgent.toLocaleLowerCase();
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  return isIOS;
};
const dateJs = () => {
  const year = dayjs().year();
  const month = dayjs().month() + 1;
  const date = dayjs().date() + 1;
  return new Date(`${year}-${month}-${date} 00:00:00`);
};
export const base64ImgtoFile = (dataurl: string, filename = 'file') => {
  let arr = dataurl.split(',');
  // @ts-ignore
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split('/')[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
};
const bodyStyle: any = {
  height: '40vh',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5% 5% 0 0',
};
export default function See() {
  const [form] = Form.useForm();
  const ref = useRef<SwiperRef>(null);
  const targetRef1 = useRef(null);
  const targetRef2= useRef(null);
  const targetRef3 = useRef(null);
  const targetRef4= useRef(null);
  const [formComany] = Form.useForm();
  const [partnerform] = Form.useForm();
  const [customerform] = Form.useForm();
  const [mediaform] = Form.useForm();
  const [merchantsform] = Form.useForm();
  const [formPrice] = Form.useForm();
  const [testOrgIdform] = Form.useForm();
  const [remarkform] = Form.useForm();
  const [serveTypeVisible, setserveTypeVisible] = useState<boolean>(false);
  const [visitingPersonnelVisible, setvisitingPersonnelVisible] = useState<boolean>(false);
  const [visitSignTypeVisible, setvisitSignTypeVisible] =
    useState<boolean>(false);
  const [visitResultVisible, setvisitResultVisible] = useState<boolean>(false);
  const [visitList, setVisitList] = useState<any>([]);
  const [visitReasonsVisible, setvisitReasonsVisible] =
    useState<boolean>(false);
  const [displayTypeVisible, setdisplayTypeVisible] = useState<boolean>(false);
  const [detectionMethodVisible, setdetectionMethodVisible] = useState<boolean>(false);
  const [outerMerchant, setouterMerchant] = useState<string>('');
  const [nameVisible, setNameVisible] = useState<boolean>(false);
  const [marketVisible, setMarketVisible] = useState<boolean>(false);
  const [timeVisible, setTimeVisible] = useState<boolean>(false);
  const [brandTypeList, setBrandTypeList] = useState<any>([]);
  const [vehicleTypeLists, setvehicleTypeList] = useState<any>([]);
  const [priceRangeLists, setpriceRangeList] = useState<any>([]);
  const [detectOrgNameVisible, setdetectOrgNameVisible] =
    useState<boolean>(false);
  const [visible4, setVisible4] = useState<boolean>(false);
  const [mediaVisible, setmediaVisible] = useState<boolean>(false);
  const [priceVisible, setPriceVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visible2, setVisible2] = useState<boolean>(false);
  const [visible3, setVisible3] = useState<boolean>(false);
  const [merchantsVisible, setmerchantsVisible] = useState<boolean>(false);
  const [merchantsNumber, setmerchantsNumber] = useState<number>(-1);
  const [merchantsList, setmerchantsList] = useState<any>([]);
  const [serveType, setserveType] = useState<any>([]);
  const [testOrgId, settestOrgId] = useState<any>([]);
  const [visitSignType, setvisitSignType] = useState<any>([]);
  const [brandTypeVisible, setbrandTypeVisible] = useState<boolean>(false);
  const [vehicleTypeVisible, setvehicleTypeVisible] = useState<boolean>(false);
  const [priceRangeVisible, setpriceRangeVisible] = useState<boolean>(false);
  const [partnershipVisible, setpartnershipVisible] = useState<boolean>(false);
  const [visitResult, setvisitResult] = useState<any>(
    localStorage.getItem('visitResult'),
  );
  const [checkPartners, setCheckPartners] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [mediaList, setmediaList] = useState<any>([]);
  const [testOrgList, settestOrgList] = useState<any>([]);
  const [jumpBumber, setJumpBumber] = useState<number>(2);
  const [customerLists, setcustomerLists] = useState<any>([]);
  // const [belongMarketId, setbelongMarketId] = useState<number>(-1);
  const [mediaType, setmediaType] = useState<any>([]);
  const [orgPicket, setorgPicket] = useState<any>([]);
  // const [companyId, setCompanyId] = useState<any>([]);
  const [visitContent, setvisitContent] = useState<string>('');
  const [visitSummary, setVisitSummary] = useState<string>('');
  const [regionAnnotations, setregionAnnotations] = useState<string>('');
  const [breakThroughLogic, setBreakThroughLogic] = useState<string>('');
  const [MeritList, setMeritList] = useState<any>([]);
  const [remarkValue, setRemarkValue] = useState<string>('');
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [remark, setRemark] = useState<any>([]);
  const [carLabelIds, setcarLabelIds] = useState<any>([]);
  const [doorway, setDoorway] = useState<any>([]);
  const [point, setPoint] = useState<any>([]);
  const [advertise, setadvertise] = useState<any>([]);
  const [customerVisible, setCustomerVisible] = useState<boolean>(false);
  const [customerVisible2, setCustomerVisible2] = useState<boolean>(false);
  const [defaultIndexPoint, setDefaultIndexPoint] = useState<number>(0);
  const [mCarDealerVisitVoId, setmCarDealerVisitVoId] = useState<number>(-1);
  const [energyType, setenergyType] = useState<number>(100);
  const [PremiumImgs, setPremiumImg] = useState<any>(
    localStorage.getItem('premiumImg'),
  );
  const [priceValue, setPriceValue] = useState<any[]>([]);
  const [initcarLabelIds, setinitcarLabelIds] = useState<any>([]);
  const [selectedValuePoints, setSelectedValuePoints] = useState<any>([]);
  const [allValuePoint, setAllValuePoint] = useState<any>([]);
  const [localIdsImgs, setlocalIdsImg] = useState<string | null>(
    localStorage.getItem('localIdsImg'),
  );
  const [pointImgs, setpointImg] = useState<string | null>(
    localStorage.getItem('pointImg'),
  );
  const [advertiseImgs, setadvertiseImg] = useState<string | null>(
    localStorage.getItem('advertiseImg'),
  );
  const [powerModelImgs, setpowerModelImg] = useState<string | null>(
    localStorage.getItem('powerModelImg'),
  );
  const [address, setAddress] = useState<string>('');
  const [partnerNumber, setpartnerNumber] = useState<number>(-1);
  const [mediaNumber, setmediaNumber] = useState<number>(-1);
  const [testOrgNumber, settestOrgNumber] = useState<number>(-1);
  const [customerNumber, setcustomerNumber] = useState<number>(-1);
  const [ids, setIds] = useState<number>();
  const [edit, setEdit] = useState<number>();
  const [editId, setEditId] = useState<number>();
  const [allImage, setAllImage] = useState<any>([]);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [typeList, setTypeList] = useState<any>([]);
  const [redioFlag, setRedioFlag] = useState<any>(1);
  const [redioFlag2, setRedioFlag2] = useState<any>(1);
  const [redioFlag3, setRedioFlag3] = useState<any>(1);
  const [moreMap, setMoreMap] = useState<any>({});
  const avatar = localStorage.getItem('avatar');
  const [companyDeatail, setCompanyDeatail] = useState<any>([]);
  const [couponUsedStatVos, setcouponUsedStatVos] = useState<any>([]);
  const [carDealerPartners, setcarDealerPartners] = useState<any>([]);
  const [degreeIntentVisible, setdegreeIntentVisible] =
    useState<boolean>(false);
  const [newCarDealers, setNewCarDealers] = useState<boolean>(true);
  const [degreeIntent, setdegreeIntent] = useState<number>();
  const [recommendationsVisible, setRecommendationsVisible] =
    useState<boolean>(false);
  const [recommendationsList, setrecommendationsList] = useState<any>([]);
  const [scoreList, setScoreList] = useState<any>([]);
  const location = useLocation();
  const dataFlag: any = location.state;
  const belongMarketId = localStorage.getItem('belongMarketId');
  const localIdsImg = localStorage.getItem('localIdsImg');
  const pointImg = localStorage.getItem('pointImg');
  const advertiseImg = localStorage.getItem('advertiseImg');
  const powerModelImg = localStorage.getItem('powerModelImg');
  const premiumImg = localStorage.getItem('premiumImg');
  const [checkCollect, setCheckCollect] = useState<any>([]);
  const [collectVisible, setCollectVisible] = useState<boolean>(false);
  const [recommendedReasonsVisible, setrecommendedReasonsVisible] = useState<boolean>(false);
  const [cooperationFrequencyVisible, setcooperationFrequencyVisible] = useState<boolean>(false);
  const [contactMobileNoFlag, setcontactMobileNoFlag] = useState<boolean>(true);
  const [nextVisitTimeVisible, setnextVisitTimeVisible] =
    useState<boolean>(false);
  const infoRef: any = useRef([]);
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年';
      case 'month':
        return data + '月';
      default:
        return data;
    }
  }, []);
  const offeringsList = ['基础检测', '升级检测', '豪车检测', '数据车况'];
  const onChanger = (v: any) => {
    console.log(v);
    setRedioFlag(v);
  };
  const onChanged = (v: any) => {
    console.log(v);
    setRedioFlag2(v);
  };
  const onChangec = (v: any) => {
    console.log(v);

    setRedioFlag3(v);
  };
  // 异地推荐
  const goTobackRecommendations = () => {
    setRecommendationsVisible(true);
  };
  const handleRecommendationsCancel = async () => {
    setRecommendationsVisible(false);
  };
  const handelrecommendationsUserName = async (value: any) => {
    console.log(value);
    if (value.length > 0) {
      value.map((item: any) => {
        let arr = item.split('===');
        console.log(arr);
        formComany.setFieldValue('recommendations', arr[0]);
        setrecommendationsList({ companyId: arr[1], companyName: arr[0] });
      });
    }
    onValuesChange();
    setRecommendationsVisible(false);
  };
  const addressList = async (latitude: any, longitude: any) => {
    const msg = await getAddress({
      lat: latitude,
      lng: longitude,
    });
    if (msg.retCode === 0) {
      console.log(msg.data.result);
      setAddress(msg.data.result);
      form.setFieldValue('address', msg.data.result);
    }
  };
  const wxShareConfigs = async () => {
    // setUserId(["ChenHong"])
    // callOnBD(["ChenHong"])
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/",
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['getLocation'],
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['getLocation'],
        success: function (res:any) {
          // 回调
        },
        fail: function (res:any) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: async function (res:any) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed; // 速度，以米/每秒计
          var accuracy = res.accuracy; // 位置精度
          addressList(latitude, longitude);
        },
      });
    }
  };
  const wxShareConfig = async (type: number) => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
        success: function (res:any) {
          // 回调
        },
      });
      window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function (res:any) {
          // 回调
        },
        fail: function (res:any) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'normal', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds[0];
          window.wx.uploadImage({
            localId: localIds.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res:any) {
              var serverId = res.serverId; // 返回图片的服务器端ID
              console.log(serverId);
              if (type === 1) {
                // setDoorway({
                //   mediaId: serverId,
                //   urlName: '门头近照',
                //   urlType: type,
                // });
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '门头近照',
                  urlType: type,
                });
                localStorage.setItem('doorway', list);
                // infoRef.current.doorway = {
                //   mediaId: serverId,
                //   urlName: '门头近照',
                //   urlType: type,
                // }
                // localStorage.setItem("doorway")
              }
              if (type === 2) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '门头含车位',
                  urlType: type,
                });
                localStorage.setItem('point', list);
              }
              if (type === 3) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '竞队广告',
                  urlType: type,
                });
                localStorage.setItem('advertise', list);
              }
              if (type === 4) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '签到证明',
                  urlType: type,
                });
                localStorage.setItem('powerModel', list);
              }
            },
            errMsg: function (res:any) {
              Toast.show({
                icon: 'fail',
                content: '上传失败',
              });
              return;
            },
          });
          if (isIOS() === true) {
            window.wx.getLocalImgData({
              localId: localIds, // 图片的localID
              success: function (res:any) {
                var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                if (type === 1) {
                  setlocalIdsImg(localData);
                  localStorage.setItem('localIdsImg', localData);
                  infoRef.current.localIdsImg = localData;
                }
                if (type === 2) {
                  setpointImg(localData);
                  localStorage.setItem('pointImg', localData);
                  infoRef.current.pointImg = localData;
                }
                if (type === 3) {
                  setadvertiseImg(localData);
                  localStorage.setItem('advertiseImg', localData);
                  infoRef.current.advertiseImg = localData;
                }
                if (type === 4) {
                  setpowerModelImg(localData);
                  localStorage.setItem('powerModelImg', localData);
                  infoRef.current.powerModelImg = localData;
                }
                if (type === 5) {
                  setPremiumImg(localData);
                  localStorage.setItem('premiumImg', localData);
                  infoRef.current.premiumImg = localData;
                }
              },
            });
          } else {
            if (type === 1) {
              setlocalIdsImg(localIds);
              localStorage.setItem('localIdsImg', localIds);
              infoRef.current.localIdsImg = localIds;
            }
            if (type === 2) {
              setpointImg(localIds);
              localStorage.setItem('pointImg', localIds);
              infoRef.current.pointImg = localIds;
            }
            if (type === 3) {
              setadvertiseImg(localIds);
              localStorage.setItem('advertiseImg', localIds);
              infoRef.current.advertiseImg = localIds;
            }
            if (type === 4) {
              setpowerModelImg(localIds);
              localStorage.setItem('powerModelImg', localIds);
              infoRef.current.powerModelImg = localIds;
            }
            if (type === 5) {
              setPremiumImg(localIds);
              localStorage.setItem('premiumImg', localIds);
              infoRef.current.premiumImg = localIds;
            }
          }
          // onValuesChange();
        },
      });
    }
  };
  const valueNode = async (companyId: any, userId: any) => {
    const score = await remarkLists({
      companyId: companyId,
      userId: userId != 1 ? userId : localStorage.getItem('userId'),
    });
    if (score.data) {
      setScoreList({ data: score.data, companyId: companyId });
      score.data.map((item: any) => {
        if (item.labelName === '专业人设') {
          valuePoint({ companyId: companyId, parentId: item.labelId });
        }
      });
    }
  };
  const valuePoint = async (value: any) => {
    const res = await labellist({
      companyId: value.companyId,
      parentId: value.parentId,
    });
    if (res.retCode === 0) {
      setMeritList(res.data);
    }
  };
  const initValue = async (dataFlag: any) => {
    console.log(dataFlag);

    if (dataFlag.visitCompanyRelations && dataFlag.visitSignType === 13) {
      console.log(dataFlag.visitCompanyRelations, '-------------');

      setrecommendationsList({
        companyId: dataFlag.visitCompanyRelations[0].companyId,
        companyName: dataFlag.visitCompanyRelations[0].companyName,
      });
      formComany.setFieldValue(
        'recommendations',
        dataFlag.visitCompanyRelations[0].companyName,
      );
    }
    if (
      dataFlag.companyId &&
      dataFlag.companyId != [] &&
      dataFlag.companyId != -1 &&
      dataFlag.storage != 1
    ) {
      // setCompanyId(dataFlag.companyId);
      const msg = await getMCarDealerVisitVo({
        companyId: dataFlag.companyId,
      });
      // const plan = await planCompanyDate({
      //   companyId: dataFlag.companyId,
      // })
      // if(plan.retCode === 0 && plan.data && plan.data.couponUsedStatVos){
      //   setcouponUsedStatVos(plan.data.couponUsedStatVos)
      // }
      // if(plan.retCode === 0 && plan.data && plan.data.carDealerPartners){
      //   setcarDealerPartners(plan.data.carDealerPartners)
      // }
      valueNode(dataFlag.companyId, 1);
      if (msg.retCode === 0) {
        setCompanyDeatail(msg.data);
        form?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCompanyInfo);
        if (msg.data.mCompanyInfo) {
          // setdegreeIntent(msg.data.mCompanyInfo.degreeIntent)
          if (msg.data.mCompanyInfo.degreeIntent) {
            infoRef.current.degreeIntent = msg.data.mCompanyInfo.degreeIntent;
          }
          if (`${msg.data.mCompanyInfo.kilometerFlag}` === 'true') {
            formComany?.setFieldsValue({ kilometerFlag: 1 });
          } else if (`${msg.data.mCompanyInfo.kilometerFlag}` === 'false') {
            formComany?.setFieldsValue({ kilometerFlag: 0 });
          }
          if (`${msg.data.mCompanyInfo.recommendCompanyFlag}` === 'true') {
            formComany?.setFieldsValue({ recommendCompanyFlag: 1 });
          } else if (
            `${msg.data.mCompanyInfo.recommendCompanyFlag}` === 'false'
          ) {
            formComany?.setFieldsValue({ recommendCompanyFlag: 0 });
          }
        }
        // form?.setFieldsValue({ price: msg.data.mCarDealers.price });
        if (msg.data.mCarDealers) {
          // if (msg.data.mCarDealers.displayType) {
          //   // setdisplayType(msg.data.mCarDealers.displayType);
          //   infoRef.current.displayType = msg.data.mCarDealers.displayType;
          // }
          if (msg.data.mCarDealers && msg.data.mCarDealers.outerMerchant) {
            setouterMerchant(`${msg.data.mCarDealers.outerMerchant}`);
          }
          if (msg.data.mCompanyInfo.contactDetailAddress) {
            setAddress(msg.data.mCompanyInfo.contactDetailAddress);
          }
          if (msg.data.mCompanyInfo.belongMarketId) {
            localStorage.setItem(
              'belongMarketId',
              msg.data.mCompanyInfo.belongMarketId,
            );
          }
        }
        const list = msg.data;
        // 客户来源
        if (list.customerSources && list.customerSources.length > 0) {
          setcustomerLists(list.customerSources);
        } 
        // 推荐商户
        if (list.recommendedMerchants && list.recommendedMerchants.length > 0) {
          setmerchantsList(list.recommendedMerchants);
        } 
        // 自媒体
        if (list.mCarDealerMedia && list.mCarDealerMedia.length > 0) {
          list.mCarDealerMedia.map((item: any) => {
            if (item.account != '-1' && item.mediaName != '无' && !dataFlag.storage) {
              setmediaList(list.mCarDealerMedia);
            }
          });
        } 
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0) {
          list.mCarDealerPartners.map((item: any) => {
            setPartnerList(list.mCarDealerPartners);
          });
        }
        // 竞队信息
        if (list.carDealerCompetitors && list.carDealerCompetitors.length > 0 && !dataFlag.storage) {
          list.carDealerCompetitors.map((item: any) => {
            settestOrgList(list.carDealerCompetitors);
          });
        }
        if (msg.data.mCarDealers && msg.data.mCarDealers.displayType) {
          displayTypeList[0].map((item: any) => {
            if (msg.data.mCarDealers.displayType === item.value) {
              formComany?.setFieldsValue({ displayTypeName: item.label });
            }
          });
        }
      }

      valuePoint({ companyId: dataFlag.companyId });
    } else {
      valuePoint({});
    }
    if (dataFlag.carLabelIds) {
      setinitcarLabelIds(dataFlag.carLabelIds);
    }
    form?.setFieldsValue(dataFlag);
    form?.setFieldsValue({ companyName: dataFlag.companyName });
    // form?.setFieldsValue({address: dataFlag.contactDetailAddress });
    if (dataFlag.partnerInfoList) {
      let list: any = []
      dataFlag.partnerInfoList.map((item: any, index: any) => {
        list.push(item)
        // partnerList[index] = item;
        setPartnerList(list);
      });
    }
    if (dataFlag.mediaInfoList) {
      let list: any = []
      dataFlag.mediaInfoList.map((item: any, index: any) => {
        // mediaList[index] = item;
        list.push(item)
        setmediaList(list);
      });
    }
    if (dataFlag.merchantsList) {
      let list: any = []
      dataFlag.merchantsList.map((item: any, index: any) => {
        // merchantsList[index] = item;
        list.push(item)
        setmerchantsList(list);
      });
    }
    if (dataFlag.customerLists) {
      let list: any = []
      dataFlag.customerLists.map((item: any, index: any) => {
        list.push(item)
        // customerLists[index] = item;
        setcustomerLists(list);
        // setmerchantsList(list);
      });
    }
    if (dataFlag.competitorList) {
      dataFlag.competitorList.map((item: any, index: any) => {
        testOrgList[index] = item;
        settestOrgList(testOrgList);
      });
    }
    if (dataFlag.serveType) {
      // setserveType(dataFlag.serveType);
      infoRef.current.serveType = dataFlag.serveType;
    }
    if (dataFlag.visitContent) {
      setvisitContent(dataFlag.visitContent);
    }
    if (dataFlag.breakThroughLogic) {
      setBreakThroughLogic(dataFlag.breakThroughLogic);
    }
    if (dataFlag.energyType) {
      setenergyType(dataFlag.energyType);
    }
    if (dataFlag.visitSummary) {
      setVisitSummary(dataFlag.visitSummary);
    }
    if (dataFlag.valuesComany) {
      formComany?.setFieldsValue(dataFlag.valuesComany);
    }
    if (dataFlag.id) {
      setIds(dataFlag.id);
    }
    if (dataFlag.doorway) {
      infoRef.current.doorway = dataFlag.doorway;
    }
    if (dataFlag.point) {
      infoRef.current.point = dataFlag.point;
    }
    if (dataFlag.advertise) {
      infoRef.current.advertise = dataFlag.advertise;
    }
    if (dataFlag.powerModel) {
      infoRef.current.powerModel = dataFlag.powerModel;
    }
    if (dataFlag.Premium) {
      infoRef.current.premium = dataFlag.Premium;
    }
    if (
      dataFlag.visitResultName &&
      (dataFlag.visitResultName === '失败' ||
        dataFlag.visitResultName === '待在拜访')
    ) {
      localStorage.setItem('visitResult', '1');
      setvisitResult(1);
    }
    if (
      dataFlag.visitResultName &&
      (dataFlag.visitResultName === '成功' ||
        dataFlag.visitResultName === '拜访成交' ||
        dataFlag.visitResultName === '注册上线')
    ) {
      localStorage.setItem('visitResult', '2');
      setvisitResult(2);
    }
    if (dataFlag.productName) {
      form?.setFieldsValue({ productName: dataFlag.productName });
    }
    if (dataFlag.reasonForFailure) {
      form?.setFieldsValue({ reasonForFailure: dataFlag.reasonForFailure });
    }
    if (dataFlag.degreeIntentName) {
      // setdegreeIntent(dataFlag.degreeIntent)
      infoRef.current.degreeIntent = dataFlag.degreeIntent;
    }
    if (dataFlag.remark) {
      setRemark(dataFlag.remark);
    }
    if (dataFlag.remarkValue) {
      setRemarkValue(dataFlag.remarkValue);
    }
    if (dataFlag.visitSignType) {
      infoRef.current.visitSignType = dataFlag?.visitSignType;
    }
    if (dataFlag.degreeIntent) {
      infoRef.current.degreeIntent = dataFlag?.degreeIntent;
    }
    setvisitSignType(dataFlag.visitSignType);
    if (dataFlag.visitSignType != 5) {
      setNewCarDealers(false);
    }
    // onValuesChange();
  };
  const onFinish = async () => {
    formComany.validateFields().then(() => {
      submit();
      setLoading(true);
    });
  };
  const submit = async () => {
    // @ts-ignore
    const companyId = parseInt(localStorage.getItem('companyId'));
    const values = form.getFieldsValue();
    const valueComany = formComany.getFieldsValue();
    console.log(values);
    let visitSignTypeCompanyId: number = 0;
    let list: any = {
      // partnerType: parseInt(valueComany.partnerType),
      sellWay: parseInt(valueComany.sellWay),
      // realLayoutNum: parseInt(valueComany.realLayoutNum),
      price: valueComany.price,
      displayType: parseInt(valueComany.displayType),
      storeCover: parseInt(valueComany.storeCover),
      imageStore: parseInt(valueComany.imageStore),
      parkingNum: parseInt(valueComany.parkingNum),
      actualDisplayVolume: parseInt(valueComany.actualDisplayVolume),
      // intention: parseInt(valueComany.intention),
      // outerMerchant: parseInt(valueComany.outerMerchant),
      kilometerFlag: parseInt(valueComany.kilometerFlag),
      // recommendCompanyFlag: parseInt(valueComany.recommendCompanyFlag),
      companyName: valueComany.companyName,
      contactName: valueComany.contactName,
      contactMobileNo: valueComany.contactMobileNo,
      belongingRegion: valueComany.belongingRegion,
      doorplate: valueComany.doorplate,
      floor: valueComany.floor,
      companyId:
        visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
      degreeIntent: infoRef.current.degreeIntent,
      detectionMethod: valueComany.detectionMethod,
      imageStoreFrontFlag: parseInt(valueComany.imageStoreFrontFlag),
      brandType: valueComany.brandType,
      vehicleType: valueComany.vehicleType,
      degreeIntentName: valueComany.degreeIntentName,
      priceRange: valueComany.priceRange,
      energyType: `油车${energyType}% 新能源${100-energyType}%`,
    };
    if (belongMarketId) {
      values.belongMarketId = belongMarketId;
      values.belongMarketName = valueComany.belongMarketName;
    }
    if (remark.length > 0) {
      const remarks = remark.filter((item: any) => item);
      values.saveRemarkInputDto = {
        labelList: remarks,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        bdMobile: localStorage.getItem('phone'),
      };
    }
    if (testOrgList.length > 0 && redioFlag3 === 1) {
      const testOrgLists = testOrgList.filter((item: any) => item);
      values.competitorList = testOrgLists;
    }
    if (mediaList.length > 0) {
      const mediaLists = mediaList.filter((item: any) => item);
      mediaLists.forEach((item: any) => {
        item.companyId =
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
      });
      values.mediaInfoList = mediaLists;
    }
    if (partnerList.length > 0) {
      const partnerLists = partnerList.filter((item: any) => item);
      partnerLists.forEach((item: any) => {
        item.companyId =
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
      });
      values.partnerInfoList = partnerLists;
    }
    if (customerLists.length > 0) {
      const customerLists2 = customerLists.filter((item: any) => item);
      customerLists2.forEach((item: any) => {
        item.companyId =
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
      });
      values.customerSourceVos = customerLists2;
    }
    if (merchantsList.length > 0) {
      const merchantsLists = merchantsList.filter((item: any) => item);
      merchantsLists.forEach((item: any) => {
        item.companyId =
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
      });
      values.recommendedMerchantVos = merchantsLists;
    }
    values.degreeIntent = infoRef.current.degreeIntent;
    values.id =
      visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
    values.companyName = valueComany.companyName;
    values.token = localStorage.getItem('token');
    values.carDealerBasicOutputDTO = list;
    values.address = address;
    values.contactMobileNo = dataFlag.contactMobileNo;
    values.contactName = dataFlag.contactName;
    // values.visitCompanyRelationVos = recommendationsList;
    if (dataFlag.status && dataFlag.status === 4) {
      values.id = editId;
      if (!dataFlag.edit) {
        delete values.visitPlanId;
        delete values.id;
      }
    }
    delete values.partnerType;
    delete values.outerMerchant;
    delete values.sellWay;
    delete values.realLayoutNum;
    delete values.price;
    delete values.intention;
    delete values.displayTypeName;
    delete values.storeCover;
    delete values.parkingNum;
    delete values.imageStore;
    delete values.serveTypeName;
    // delete values.visitSignTypeName;
    delete values.visitResultName;
    delete values.userId;
    delete values.userMobile;
    // if(values.visitResult === 1 || values.visitResult === "1"){
    //   values.planFlag = true
    //   history.replace("./programme",values)
    //   return
    // }else{
    //   values.planFlag = false
    // }
    save(values)
      .then((msg) => {
        setLoading(false);
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '修改成功',
          });
          localStorage.removeItem('values');
          localStorage.removeItem('visitContent');
          localStorage.removeItem('visitSummary');
          localStorage.removeItem('breakThroughLogic');
          localStorage.removeItem('companyId');
          localStorage.removeItem('visitResult');
          localStorage.removeItem('belongMarketId');
          localStorage.removeItem('localIdsImg');
          localStorage.removeItem('pointImg');
          localStorage.removeItem('advertiseImg');
          localStorage.removeItem('powerModelImg');
          localStorage.removeItem('premiumImg');
          localStorage.removeItem('doorway');
          localStorage.removeItem('point');
          localStorage.removeItem('advertise');
          localStorage.removeItem('powerModel');
          localStorage.removeItem('premium');
          history.goBack()
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: '提交失败',
        });
      });
  };
  const init = async () => {
    wxShareConfigs();
    const msg = await orglist();
    if (msg.retCode === 0) {
      console.log(msg.data);
      let list: any = [];
      msg.data.map((item: any) => {
        list.push({
          label: item.partnerName,
          value: item.id,
        });
      });
      setorgPicket([list]);
    }
  };
  const OnVisitSign = () => {
    let list: any = [];
    typelist({})
      .then((any) => {
        if (any.retCode === 0 && any.data.length > 0) {
          any.data.map((item: any, index: number) => {
            if (item.type != 12) {
              list.push({
                label: item.name,
                value: item.type,
              });
            }
          });
          console.log(list);
          setTypeList([list]);
          setvisitSignTypeVisible(true);
          // onValuesChange()
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.show({
          icon: 'fail',
          content: '拜访类型获取失败',
        });
      });
  };
  useEffect(() => {
    init();
    console.log(dataFlag);
    
    if (localStorage.getItem('values')) {
      let value = localStorage.getItem('values');
      if (value) {
        let json = JSON.parse(value);
        json.visitContent = localStorage.getItem('visitContent');
        json.breakThroughLogic = localStorage.getItem('breakThroughLogic');
        json.visitSummary = localStorage.getItem('visitSummary');
        json.storage = 1;
        initValue(json);
      }
    }
    if (dataFlag && dataFlag.carLabelIds) {
      let list: any = [];
      labellist({}).then((res) => {
        if (res.retCode === 0) {
          setAllValuePoint(res.data);
          res.data.map((item: any) => {
            if (item.mCarDealerLabelVos && item.mCarDealerLabelVos.length > 0) {
              item.mCarDealerLabelVos.map((child: any) => {
                if (dataFlag.carLabelIds.indexOf(child.id) > -1) {
                  list.push(child);
                }
              });
            }
          });
        }
      });
      console.log(list, dataFlag.carLabelIds);
      setSelectedValuePoints(list);
    }
    if (dataFlag) {
      if (dataFlag.companyId && dataFlag.companyId != -1) {
        localStorage.setItem('companyId', dataFlag.companyId);
      }
      if(localStorage.getItem('values')){
        dataFlag.storage = 1
      }
      initValue(dataFlag);
      if (dataFlag.carLabelIds) {
        setcarLabelIds(dataFlag.carLabelIds);
      }
      if (dataFlag.visitSignTypeName) {
        form?.setFieldsValue({ visitSignTypeName: dataFlag.visitSignTypeNam });
      }
      if (dataFlag.productName) {
        form?.setFieldsValue({ productName: dataFlag.productName });
      }
      if (dataFlag.visitingPersonnel) {
        form?.setFieldsValue({ visitingPersonnel: dataFlag.visitingPersonnel });
      }
      if (dataFlag && dataFlag.serveType) {
        // infoRef.current.serveType = dataFlag?.serveType
        serveTypeList[0].map((item: any) => {
          if (dataFlag.serveType === item.value) {
            form?.setFieldsValue({ serveTypeName: item.label });
            // setserveType(dataFlag.serveType);
          }
        });
      }
    }
  }, []);

  const onchanges = (v: any) => {
    setCheckCollect(v);
  };
  const onchangePartners = (v: any) => {
    setCheckPartners(v);
    console.log(v);
    
  };
  const handelUserName = async (value: any) => {
    console.log(value);
    // setCompanyId(value[0]);
    localStorage.setItem('companyId', value[0]);
    setmediaList([]);
    setPartnerList([]);
    settestOrgList([]);
    if (value.length > 0) {
      const msg = await getMCarDealerVisitVo({
        companyId: value[0],
      });
      valueNode(value[0], 1);
      if (msg.retCode === 0) {
        setCompanyDeatail(msg.data);
        form?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCarDealers);
        formComany?.setFieldsValue(msg.data.mCompanyInfo);
        if (msg.data.mCompanyInfo) {
          setdegreeIntent(msg.data.mCompanyInfo.degreeIntent);
          infoRef.current.degreeIntent = msg.data.mCompanyInfo.degreeIntent;
        }
        // form?.setFieldsValue({ price: msg.data.mCarDealers.price });
        if (msg.data.mCarDealers) {
          // if (msg.data.mCarDealers.displayType) {
          //   // setdisplayType(msg.data.mCarDealers.displayType);
          //   infoRef.current.displayType = msg.data.mCarDealers.displayType;
          // }
          if (msg.data.mCompanyInfo.contactDetailAddress) {
            setAddress(msg.data.mCompanyInfo.contactDetailAddress);
          }
          if (msg.data.mCompanyInfo.belongMarketId) {
            localStorage.setItem(
              'belongMarketId',
              msg.data.mCompanyInfo.belongMarketId,
            );
          }
        }
        const list = msg.data;
        // 自媒体
        if (list.mCarDealerMedia && list.mCarDealerMedia.length > 0 && !dataFlag.storage) {
          list.mCarDealerMedia.map((item: any) => {
            if (item.account != '-1' && item.mediaName != '无') {
              setmediaList(list.mCarDealerMedia);
            } else {
              setRedioFlag2(2);
            }
          });
        } else {
          setRedioFlag2(2);
        }
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0 && !dataFlag.storage) {
          list.mCarDealerPartners.map((item: any) => {
            if (item.partnerName === '无') {
              setRedioFlag(2);
            } else {
              setPartnerList(list.mCarDealerPartners);
            }
          });
        } else {
          setRedioFlag(2);
        }
        // 竞队信息
        if (list.carDealerCompetitors && list.carDealerCompetitors.length > 0 && !dataFlag.storage) {
          list.carDealerCompetitors.map((item: any) => {
            if (item.testOrgId != -1) {
              settestOrgList(list.carDealerCompetitors);
            } else {
              setRedioFlag3(2);
            }
          });
        } else {
          setRedioFlag3(2);
        }
        // if (msg.data.mCarDealers && msg.data.mCarDealers.displayType && !dataFlag.storage) {
        //   displayTypeList[0].map((item: any) => {
        //     if (msg.data.mCarDealers.displayType === item.value) {
        //       formComany?.setFieldsValue({ displayTypeName: item.label });
        //     }
        //   });
        // }
      }

      valuePoint({ companyId: value[0] });
    }
    setNameVisible(false);
    onValuesChange();
  };
  const toastValue = (value: any) => {
    let text = '';
    if (typeof value === 'number') {
      text = `${value}`;
    } else {
      text = `[${value.join(',')}]`;
    }
    setenergyType(value);
    console.log(value);
  };
  const handleUserNameCancel = async () => {
    setNameVisible(false);
  };
  const handelUserMarket = async (value: any) => {
    console.log(value);
    localStorage.setItem('belongMarketId', value[0].id);
    formComany.setFieldValue('belongMarketName', value[0].marketName);
    setMarketVisible(false);
    onValuesChange();
  };
  const handleUserMarketCancel = async () => {
    setMarketVisible(false);
  };
  const onValuesChange = () => {
    const values = form.getFieldsValue();
    const valuesComany = formComany.getFieldsValue();
    values.valuesComany = valuesComany;
    values.serveType = infoRef.current.serveType;
    values.homeSee = true;
    values.visitSignType = infoRef.current.visitSignType;
    values.degreeIntent = infoRef.current.degreeIntent;
    values.visitResult = visitResult;
    // values.displayType = infoRef.current.displayType;
    values.partnerInfoList = partnerList;
    values.mediaInfoList = mediaList;
    values.customerLists = customerLists;
    values.merchantsList = merchantsList;
    values.competitorList = testOrgList;
    values.visitContent = visitContent;
    values.visitSummary = visitSummary;
    values.breakThroughLogic = breakThroughLogic;
    values.companyId = localStorage.getItem('companyId');
    values.localIdsImg = localIdsImg;
    values.doorway = infoRef.current.doorway;
    values.pointImg = pointImg;
    values.point = infoRef.current.point;
    values.advertiseImg = advertiseImg;
    values.advertise = infoRef.current.advertise;
    values.powerModelImg = powerModelImg;
    values.powerModel = infoRef.current.powerModel;
    values.PremiumImg = premiumImg;
    values.Premium = infoRef.current.premium;
    values.remark = remark;
    values.remarkValue = remarkValue;
    values.energyType = energyType;
    values.see = true;
    values.back = true;
    values.partner = partnerform.getFieldsValue();
    values.media = mediaform.getFieldsValue();
    values.testOrg = testOrgIdform.getFieldsValue();
    values.visitCompanyRelations = [recommendationsList];
    localStorage.setItem('values', JSON.stringify(values));
    console.log(values);
    
  };
  // 合伙人信息
  const partnerSubmit = (type: number) => {
    const values = partnerform.getFieldsValue();
    values.companyId = localStorage.getItem('companyId');
    if (partnerNumber === -1) {
      let num = partnerList.length;
      partnerList[num] = values;
    } else {
      partnerList[partnerNumber] = values;
    }
    if(values.partnershipType === 4){
      values.partnership = values.partnershipName
    }
    onValuesChange();
    setPartnerList([...partnerList]);
    if (type === 1) {
      partnerform.resetFields();
    } else {
      // setVisible(false)
    }
  };
  // 自媒体信息
  const mediaSubmit = (type:number) => {
    const values = mediaform.getFieldsValue();
    let num = mediaList.length;
    setmediaNumber(num);
    values.mediaType = mediaType;
    values.companyId = localStorage.getItem('companyId');
    values.fansNum = parseInt(values.fansNum);
    if(mediaNumber === -1){
      let num = mediaList.length
      mediaList[num] = values;
      setmediaNumber(num);
    }else{
      mediaList[mediaNumber] = values;
    }
    onValuesChange();
    setmediaList([...mediaList]);
    if(type === 1){
      customerform.resetFields()
    }else{
      setVisible2(false)
    }
  }
  // 添加推荐商户
  const merchantsSubmit = (type:number) => {
    const values = merchantsform.getFieldsValue();
    let num = merchantsList.length;
    setmerchantsNumber(num);
    if(merchantsNumber === -1){
      let num = merchantsList.length
      merchantsList[num] = values;
      setmerchantsNumber(num);
    }else{
      merchantsList[merchantsNumber] = values;
    }
    onValuesChange();
    setmerchantsList([...merchantsList]);
    if(!contactMobileNoFlag){
      infoSave({companyName:values.companyName,contactMobileNo:values.contactPhone}).then((res)=>{
        if(res.retCode != 0){
          Toast.show({
            icon:"fail",
            content:res.retMsg
          })
        }
      })
      .catch(()=>{
        Toast.show({
          icon:"fail",
          content:"商户创建失败"
        })
      })
    }
    if(type === 1){
      merchantsform.resetFields()
    }else{
      setmerchantsVisible(false)
    }
  }
  // 客户来源
  const customerformSubmit = (type:number) => {
    const values = customerform.getFieldsValue();
    if(customerNumber === -1){
      let num = customerLists.length
      customerLists[num] = values;
      setcustomerNumber(num);
    }else{
      customerLists[customerNumber] = values;
    }
    onValuesChange();
    console.log(customerLists);
    
    setcustomerLists([...customerLists]);
    if(type === 1){
      customerform.resetFields()
    }else{
      setCustomerVisible(false)
    }
  }
  // 竞队信息
  const testOrgIdSubmit = (type:number) => {
    const values = testOrgIdform.getFieldsValue();
    // values.testOrgId = testOrgId;
    let url
    if(localStorage.getItem('advertise')){
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('advertise'));
      url = str.mediaId
    }
    values.url = advertiseImgs;
    values.advertise = url;
    values.companyId = localStorage.getItem('companyId');
    values.hardCover = parseInt(values.hardCover);
    if (values.cooperationTime) {
      values.cooperationTime = `${values.cooperationTime}`;
    }
    if(testOrgNumber === -1) {
      let num = testOrgList.length;
      testOrgList[num] = values;
      settestOrgNumber(num);
    }else{
      testOrgList[testOrgNumber] = values;
    }
    console.log(testOrgList);
    onValuesChange();

    settestOrgList([...testOrgList]);
    if(type === 1){
      testOrgIdform.resetFields()
    }else{
      setVisible3(false)
    }
  }
  const jump = (title:number) => {
    setJumpBumber(title)
    if(title === 1){
      // @ts-ignore
      targetRef1.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
    if(title === 2){
      // @ts-ignore
      targetRef2.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
    if(title === 3){
      // @ts-ignore
      targetRef3.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
    if(title === 4){
      // @ts-ignore
      targetRef4.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
  };
  const onchangeBrandType = (v: any) => {
    setBrandTypeList(v);
    console.log(v);
  };
  const onchangevehicleType = (v: any) => {
    setvehicleTypeList(v);
    console.log(v);
  };
  const onchangepriceRange = (v: any) => {
    setpriceRangeList(v);
    console.log(v);
  };
  return (
    <div className={`${styles.card} ${styles.page}`} >
      <base target="_blank"/>
      <Navbar name={'商户编辑'}></Navbar>
      {loading && <Login></Login>}
      <div>
        <div style={{ paddingBottom: '50px' }} ref={targetRef1}>
          <Card
            title={
              <div style={{ display: 'flex', alignItems: 'center' }} id={"商户信息"} ref={targetRef3}>
                商户基础信息
              </div>
            }
          >
            <Form
              // onFinish={onFinish}
              form={formComany}
              onValuesChange={onValuesChange}
              footer={
                <div className={styles.submit}>
                  <Button
                    block
                    type="submit"
                    color="primary"
                    onClick={() => {
                      onFinish();
                    }}
                  >
                    保存
                  </Button>
                </div>
              }
            >
              <List>
                <List.Item
                  prefix="联系人"
                  title={'*'}
                  style={{ display: 'none' }}
                >
                  <Form.Item
                    name="contactMobileNo"
                    className={styles.form_item}
                  >
                    <Input placeholder="输入联系姓名" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="联系人"
                  title={'*'}
                  className={styles.form_item_partner}
                >
                  <Form.Item
                    name="contactName"
                    className={styles.form_item_3}
                  ></Form.Item>
                  <div className={styles.partnerList}>
                    <div className={styles.partnerList_0}>
                      <span>
                        {formComany.getFieldsValue().contactName} {formComany.getFieldsValue().contactMobileNo}
                      </span>
                      <span>
                        <EditSOutline
                          fontSize={20}
                          style={{marginLeft:"10px"}}
                          onClick={() => {
                            partnerform.resetFields()
                            partnerform.setFieldsValue({partnerName: formComany.getFieldsValue().contactName})
                            partnerform.setFieldsValue({partnerPhone: formComany.getFieldsValue().contactMobileNo})
                            {partnerList && partnerList.length > 0 && partnerList.map((item:any)=>{
                              if(item.partnerName === formComany.getFieldsValue().contactName){
                                partnerform.setFieldsValue(item)
                              }
                            })}
                            setVisible(true);
                          }}
                        />
                      </span>
                    </div>
                    <div className={styles.partnerList_1}>
                      <span>老板</span>
                      {partnerList && partnerList.length > 0 && partnerList.map((item:any)=>{
                        if(item.partnerName === formComany.getFieldsValue().contactName){
                          return(
                            <>
                              {item.detectPrincipalFlag === 1 &&<span>决策人</span>}
                              {item.decisionMakerFlag === 1 &&<span>检测负责人</span>}
                              {item.partnership &&<span>{item.partnership}</span>}
                              {item.divideTheWork === 1 &&<a>收车</a>}
                              {item.divideTheWork === 2 &&<a>卖车</a>}
                            </>
                          )
                        }
                      })}
                    </div>
                  </div>
                  {partnerList &&
                    partnerList.length > 0 &&
                    partnerList.map((item: any, index: number) => {
                      if(item && item.partnerName != formComany.getFieldsValue().contactName ){
                        return (
                        <div className={styles.partnerList}>
                          <div className={styles.partnerList_0}>
                            <span>
                              {item.partnerName} {item.partnerPhone}
                            </span>
                            <span>
                              <DeleteOutline 
                                fontSize={20}
                                onClick={() => {
                                  let list = partnerList.filter((child:any)=>child.partnerPhone != item.partnerPhone)
                                  setPartnerList(list)
                                }}
                                />
                              <EditSOutline
                                fontSize={20}
                                style={{marginLeft:"10px"}}
                                onClick={() => {
                                  partnerform.setFieldsValue(item)
                                  setpartnerNumber(index)
                                  setVisible(true);
                                }}
                              />
                            </span>
                          </div>
                          <div className={styles.partnerList_1}>
                            {item.partnerName === formComany.getFieldsValue().contactName ? <span>老板</span> : <span>合伙人</span>}
                            {item.detectPrincipalFlag === 1 &&<span>决策人</span>}
                            {item.decisionMakerFlag === 1 &&<span>检测负责人</span>}
                            {item.partnership &&<span>{item.partnership}</span>}
                            {item.divideTheWork === 1 &&<a>收车</a>}
                            {item.divideTheWork === 2 &&<a>卖车</a>}
                          </div>
                        </div>
                      )
                    }
                    })}
                  <div
                    className={styles.partnerList_2}
                    onClick={() => {
                      partnerform.resetFields()
                      setpartnerNumber(-1)
                      setVisible(true);
                    }}
                  >
                    添加
                  </div>
                </List.Item>
                {/* <List.Item prefix="联系电话" title={'*'}>
                  <Form.Item
                    name="contactMobileNo"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入联系人电话',
                            });
                            return Promise.reject(new Error('输入联系人电话'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入联系人电话" readOnly />
                  </Form.Item>
                </List.Item> */}
                <List.Item prefix="地址" title={'*'}>
                  <Form.Item
                    name="address"
                    // rules={[{ required: true, message: '输入地址' }]}
                    className={styles.form_item}
                  >
                    {/* <Input placeholder="输入地址" readOnly/> */}
                    <div
                      className={styles.increase}
                      onClick={() => {
                        wxShareConfigs();
                      }}
                    >
                      重新获取定位
                    </div>
                  </Form.Item>
                </List.Item>
                {address != '' && (
                  <div className={styles.address}>{address}</div>
                )}
                <List.Item
                  prefix="归属市场"
                  title={'*'}
                  onClick={() => {
                    setMarketVisible(true);
                  }}
                >
                  <Form.Item
                    name="belongMarketName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择市场',
                            });
                            return Promise.reject(new Error('选择市场'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="选择市场" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="归属区域">
                  <Form.Item
                    name="belongingRegion"
                    // rules={[{ required: true, message: '请输入归属区域' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入归属区域" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="楼层">
                  <Form.Item
                    name="floor"
                    // rules={[{ required: true, message: '请输入楼层' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入楼层" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="门牌号">
                  <Form.Item
                    name="doorplate"
                    // rules={[{ required: true, message: '请输入门牌号' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入门牌号" />
                  </Form.Item>
                </List.Item>
                {/* <List.Item prefix="合伙性质" title={'*'}>
                  <Form.Item
                    name="partnerType"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择合伙性质',
                            });
                            return Promise.reject(new Error('选择合伙性质'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={1}>单一经营</Radio>
                      <Radio value={2}>多人合伙</Radio>
                      <Radio value={0}>未知</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item> */}
                {/* <List.Item prefix="愿意异地推荐" title={'*'}>
                  <Form.Item
                    name="recommendCompanyFlag"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择是否愿意异地推荐',
                            });
                            return Promise.reject(
                              new Error('请选择是否愿意异地推荐'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item> */}
                <List.Item prefix="门头店覆盖" title={'*'}>
                  <Form.Item
                    name="storeCover"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '门头店是否覆盖',
                            });
                            return Promise.reject(new Error('门头店是否覆盖'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                    initialValue={0}
                  >
                    <Radio.Group disabled>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
              </List>
            </Form>
          </Card>
          <Card title={'商户分类信息'}>
            <Form
              // onFinish={onFinish}
              form={formComany}
              onValuesChange={onValuesChange}
            >
              <List>
                <List.Item prefix="售车方式" title={'*'}>
                  <Form.Item
                    name="sellWay"
                    // rules={[{ required: true, message: '选择售车方式' }]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择售车方式',
                            });
                            return Promise.reject(new Error('选择售车方式'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      <Radio value={1}>零售</Radio>
                      <Radio value={2}>批发</Radio>
                      <Radio value={3}>批发&零售</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="展厅类型"
                  onClick={() => {
                    setdisplayTypeVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="displayTypeName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择展厅类型',
                            });
                            return Promise.reject(new Error('请选择展厅类型'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择展厅类型" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="展厅类型"
                  title={'*'}
                  style={{display:"none"}}
                >
                  <Form.Item
                    name="displayType"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择展厅类型',
                            });
                            return Promise.reject(new Error('请选择展厅类型'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择展厅类型" readOnly />
                  </Form.Item>
                </List.Item>
                {formComany.getFieldsValue().displayType === 1 && (
                  <List.Item prefix="独立展厅类型" title={'*'}>
                    <Form.Item
                      name="kilometerFlag"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value && value != 0) {
                              console.log(value);

                              Toast.show({
                                icon: 'fail',
                                content: '请选择独立展厅类型',
                              });
                              return Promise.reject(
                                new Error('请选择独立展厅类型'),
                              );
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <Radio.Group>
                        <Radio value={1}>一公里内</Radio>
                        <Radio value={0}>一公里外</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </List.Item>
                )}
                <List.Item
                  prefix="检测方式"
                  onClick={() => {
                    setdetectionMethodVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="detectionMethod"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择检测方式',
                            });
                            return Promise.reject(new Error('请选择检测方式'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择检测方式" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="车位数" extra="个" title={'*'}>
                  <Form.Item
                    name="parkingNum"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入车位数',
                            });
                            return Promise.reject(new Error('输入车位数'));
                          }
                          if (Number.isFinite(Number(value)) === false) {
                            return Promise.reject(
                              new Error('不可输入文字！！'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入车位数" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="车源量" extra="个" title={'*'}>
                  <Form.Item
                    name="actualDisplayVolume"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入车源量',
                            });
                            return Promise.reject(new Error('输入车源量'));
                          }
                          if (Number.isFinite(Number(value)) === false) {
                            return Promise.reject(
                              new Error('不可输入文字！！'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入车源量" />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="品牌类型"
                  onClick={() => {
                    setbrandTypeVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="brandType"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择品牌类型',
                            });
                            return Promise.reject(new Error('请选择品牌类型'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择品牌类型" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="能源类型" title={'*'}>
                  <Slider
                    defaultValue={energyType}
                    onAfterChange={toastValue}
                    max={100}
                    min={0}
                    popover={(value) => {
                      return (
                        <div>
                          <span style={{ marginRight: '10px' }}>
                            油车：{value}
                          </span>{' '}
                          <span>新能源：{100 - value || 0}</span>
                        </div>
                      );
                    }}
                  />
                  <div className={styles.mySlider}>
                    <div>
                      油车{' '}
                      <span style={{ color: '#1B5BDB' }}>
                        {energyType}%
                      </span>{' '}
                    </div>
                    <div>
                      <span style={{ color: '#0AB42A' }}>
                        {100 - energyType}%
                      </span>{' '}
                      新能源
                    </div>
                  </div>
                </List.Item>
                <List.Item
                  prefix="车辆类型"
                  onClick={() => {
                    setvehicleTypeVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="vehicleType"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择车辆类型',
                            });
                            return Promise.reject(new Error('请选择车辆类型'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择车辆类型" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="价格区间"
                  onClick={() => {
                    setpriceRangeVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="priceRange"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择价格区间',
                            });
                            return Promise.reject(new Error('请选择价格区间'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择价格区间" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="客户来源"
                  title={'*'}
                >
                  <Form.Item name="companyName" className={styles.form_item}>
                    <div className={styles.partner}>
                      {customerLists && customerLists.length === 0 && (
                        <div className={styles.redioName}>无</div>
                      )}
                      <div
                        className={styles.increase}
                        onClick={() => {
                          customerform.resetFields();
                          setcustomerNumber(-1)
                          setCustomerVisible(true);
                        }}
                      >
                        添加
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {customerLists && customerLists.length > 0 && (
                  <div className={styles.mediaList}>
                    {customerLists &&
                      customerLists.map((partnerItem: any, index: number) => {
                        if (partnerItem) {
                          return (
                            <div className={styles.mediaList_b}>
                              <div className={styles.mediaList_delete}>
                                <div>客户来源{index+1}</div>
                                <div>
                                  <Tag
                                    color="primary"
                                    fill="outline"
                                    onClick={() => {
                                      customerform.resetFields()
                                      setcustomerNumber(index)
                                      customerform.setFieldsValue(partnerItem)
                                      setCustomerVisible(true)
                                    }}
                                  >
                                    编辑
                                  </Tag>
                                  <Tag
                                    color="primary"
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                      let list: any[] = customerLists.filter(
                                        (item: any) => item != partnerItem
                                      );
                                      setcustomerLists(list);
                                    }}
                                  >
                                    删除
                                  </Tag>
                                </div>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>{partnerItem.customerSourceName}</span>
                                <span>{partnerItem.proportion}%</span>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
                <List.Item prefix="自媒体" title={'*'}>
                  <Form.Item name="mediaInfoList" className={styles.form_item}>
                    <div className={styles.partner}>
                      {mediaList && mediaList.length === 0 && (
                        <div className={styles.redioName}>无</div>
                      )}
                      <div
                        className={styles.increase}
                        onClick={() => {
                          mediaform.resetFields();
                          setmediaNumber(-1)
                          setVisible2(true);
                        }}
                      >
                        添加
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {mediaList.length > 0 && (
                  <div className={styles.mediaList}>
                    {mediaList &&
                      mediaList.map((partnerItem: any, index: number) => {
                        if (partnerItem) {
                          return (
                            <div className={styles.mediaList_b}>
                            <div className={styles.mediaList_delete}>
                              <div>自媒体信息{index+1}</div>
                              <div>
                                <Tag
                                  color="primary"
                                  fill="outline"
                                  onClick={() => {
                                    mediaform.resetFields()
                                    setmediaNumber(index)
                                    mediaform.setFieldsValue(partnerItem)
                                    setVisible2(true)
                                  }}
                                >
                                  编辑
                                </Tag>
                                <Tag
                                  color="primary"
                                  style={{ marginLeft: '10px' }}
                                  onClick={() => {
                                    let list: any[] = mediaList.filter(
                                      (item: any) => item != partnerItem
                                    );
                                    setmediaList(list);
                                  }}
                                >
                                  删除
                                </Tag>
                              </div>
                            </div>
                              <div className={styles.mediaList_delete}>
                                <span>自媒体</span>
                                <span>{partnerItem.mediaName}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>账号</span>
                                <span>{partnerItem.account}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>粉丝数量</span>
                                <span>{partnerItem.fansNum}</span>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
              </List>
            </Form>
          </Card>
          <Card title={'推荐商户'}>
            <Form form={formComany} onValuesChange={onValuesChange}>
              <List>
                <List.Item
                  prefix="推荐商户"
                  title={'*'}
                >
                  <Form.Item name="companyName" className={styles.form_item}>
                    <div className={styles.partner}>
                      {redioFlag2 === 2 && (
                        <div className={styles.redioName}>无</div>
                      )}
                      <div
                        className={styles.increase}
                        onClick={() => {
                          merchantsform.resetFields();
                          setmerchantsNumber(-1)
                          setmerchantsVisible(true);
                        }}
                      >
                        添加
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {merchantsList.length > 0 && (
                  <div className={styles.mediaList}>
                    {merchantsList &&
                      merchantsList.map((partnerItem: any, index: number) => {
                        if (partnerItem) {
                          return (
                            <div className={styles.mediaList_b}>
                            <div className={styles.mediaList_delete}>
                              <div>推荐商户信息{index+1}</div>
                              <div>
                                <Tag
                                  color="primary"
                                  fill="outline"
                                  onClick={() => {
                                    merchantsform.resetFields()
                                    setmerchantsNumber(index)
                                    merchantsform.setFieldsValue(partnerItem)
                                    setmerchantsVisible(true)
                                  }}
                                >
                                  编辑
                                </Tag>
                                <Tag
                                  color="primary"
                                  style={{ marginLeft: '10px' }}
                                  onClick={() => {
                                    let list: any[] = merchantsList.filter(
                                      (item: any) => item != partnerItem
                                    );
                                    setmerchantsList(list);
                                  }}
                                >
                                  删除
                                </Tag>
                              </div>
                            </div>
                              <div className={styles.mediaList_delete}>
                                <span>商户名称</span>
                                <span>{partnerItem.companyName}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>联系人</span>
                                <span>{partnerItem.contactName} {partnerItem.contactPhone}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>推荐方式</span>
                                <span>
                                  {partnerItem.recommendedType === 0 && "本地推荐"}
                                  {partnerItem.recommendedType === 1 && "异地推荐"}
                                </span>
                              </div>
                              {partnerItem.recommendedReasons && (
                                <div className={styles.mediaList_delete}>
                                  <span>推荐原因</span>
                                  <span>{partnerItem.recommendedReasons}</span>
                                </div>
                              )}
                              {partnerItem.cooperationFrequency && (
                                <div className={styles.mediaList_delete}>
                                  <span>合作频次</span>
                                  <span>{partnerItem.cooperationFrequency}</span>
                                </div>
                              )}
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
              </List>
            </Form>
          </Card>
          <Card title={'商家属性'}>
            <Form form={formComany} onValuesChange={onValuesChange}>
              <List>
                <List.Item prefix="形象店" title={'*'}>
                  <Form.Item
                    name="imageStore"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value && value != 0) {
                            Toast.show({
                              icon: 'fail',
                              content: '请选择是否是形象店',
                            });
                            return Promise.reject(
                              new Error('请选择是否是形象店'),
                            );
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                    initialValue={0}
                  > 
                    <Radio.Group disabled>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                {formComany.getFieldsValue().imageStore === 1 && (
                  <List.Item prefix="是否做形象店门头" title={'*'}>
                    <Form.Item
                      name="imageStoreFrontFlag"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value && value != 0) {
                              Toast.show({
                                icon: 'fail',
                                content: '请选择是否是形象店',
                              });
                              return Promise.reject(
                                new Error('请选择是否是形象店'),
                              );
                            }
                            return Promise.resolve();
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                      initialValue={0}
                    >
                      <Radio.Group disabled>
                        <Radio value={1}>是</Radio>
                        <Radio value={0}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </List.Item>
                )}
                <List.Item
                  prefix="客户意向度"
                  title={'*'}
                  onClick={() => {
                    setdegreeIntentVisible(true);
                  }}
                >
                  <Form.Item
                    name="degreeIntentName"
                    rules={[{ required: true, message: '请选择客户意向度' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请选择客户意向度" readOnly />
                  </Form.Item>
                </List.Item>
              </List>
            </Form>
          </Card>
          <Card title={'竞队信息'}>
            <Form form={formComany} onValuesChange={onValuesChange}>
              <List>
                <List.Item prefix="竞队信息" title={'*'}>
                  <Form.Item name="testOrgId" className={styles.form_item}>
                    <div className={styles.partner}>
                      {testOrgList.length === 0 && (
                        <div className={styles.redioName}>无</div>
                      )}
                      <div
                        className={styles.increase}
                        onClick={() => {
                          testOrgIdform.resetFields();
                          localStorage.removeItem("advertise")
                          setadvertiseImg("")
                          settestOrgNumber(-1)
                          setVisible3(true);
                        }}
                      >
                        添加
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                {testOrgList.length > 0 && (
                  <div className={styles.mediaList}>
                    {testOrgList &&
                      testOrgList.map((partnerItem: any, index: number) => {
                        if (partnerItem) {
                          return (
                            <div className={styles.mediaList_b}>
                              <div className={styles.mediaList_delete}>
                                <div>竞队信息{index+1}</div>
                                <div>
                                  <Tag
                                    color="primary"
                                    fill="outline"
                                    onClick={() => {
                                      testOrgIdform.resetFields()
                                      settestOrgNumber(index)
                                      localStorage.removeItem("advertise")
                                      setadvertiseImg(partnerItem.url)
                                      testOrgIdform.setFieldsValue(partnerItem)
                                      testOrgIdform.setFieldsValue({detectOrgName:partnerItem.detectOrgName? partnerItem.detectOrgName: partnerItem.name})
                                      setVisible3(true)
                                    }}
                                  >
                                    编辑
                                  </Tag>
                                  <Tag
                                    color="primary"
                                    onClick={() => {
                                      let list = testOrgList.filter(
                                        (item: any) => item != partnerItem,
                                      );
                                      console.log(list);
                                      settestOrgList(list);
                                    }}
                                    style={{ marginLeft: '10px' }}
                                  >
                                    删除
                                  </Tag>
                                </div>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>检测机构</span>
                                <span>
                                  {partnerItem.detectOrgName
                                    ? partnerItem.detectOrgName
                                    : partnerItem.name}
                                </span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>合作时间</span>
                                <span>
                                  {partnerItem.cooperationTime
                                    ? dateFormat(
                                        partnerItem.cooperationTime,
                                        'yyyy-MM',
                                      )
                                    : '-'}
                                </span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>集采余额</span>
                                <span>{partnerItem.purchaseBalance}万</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>集采库存</span>
                                <span>{partnerItem.purchasingStock}</span>
                              </div>
                              <div className={styles.mediaList_delete}>
                                <span>硬广覆盖</span>
                                <span>
                                  {partnerItem.hardCover === 1 ? '是' : '否'}
                                </span>
                              </div>
                              {partnerItem.url && (
                                <div className={styles.mediaList_delete}>
                                  <span>竞队广告</span>
                                  <Image
                                    src={partnerItem.url}
                                    style={{width:"60px",height:"60px",marginRight:"5px",borderRadius:"5px"}}
                                    fit="fill"
                                    onClick={() => {
                                      setImageVisible(true);
                                      setAllImage(partnerItem.url);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
              </List>
            </Form>
          </Card>
          <Card title={'产品信息'}>
            {couponUsedStatVos && couponUsedStatVos.length > 0 ?
              couponUsedStatVos.map((item:any)=>{
                return (
                  <div className={styles.couponUsedStatVos}>
                    <div>{item.couponName} X{item.number}</div>
                    <span>{dateFormat(item.createTime,"yyyy-MM-dd hh:mm:ss")}</span>
                  </div>
                )
              })
            :<Empty description="暂无产品信息" />}
          </Card>
          {/* {dataFlag.edit && (
            <Card
              title={
                <div>
                  大区总跟进批注{' '}
                  <span
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: '10px',
                    }}
                  >
                    *
                  </span>
                </div>
              }
            >
              <TextArea
                // value={visitContent}
                defaultValue={
                  localStorage.getItem('regionAnnotations')
                    ? localStorage.getItem('regionAnnotations')
                    : dataFlag.regionAnnotations
                }
                // defaultValue={dataFlag.visitContent}
                name="regionAnnotations"
                onChange={(v) => {
                  console.log(v);
                  setregionAnnotations(v);
                }}
                placeholder="请输入内容"
                rows={5}
              />
            </Card>
          )} */}
          {/* <Card title={"其他价值点"}> */}
        </div>
      </div>
      <Picker
        columns={serveTypeList}
        visible={serveTypeVisible}
        onCancel={() => {
          setserveTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // setserveType(value[0]);
          infoRef.current.serveType = value[0];
          form.setFieldValue('serveTypeName', extend.items[0]?.label);
          onValuesChange();
          setserveTypeVisible(false);
        }}
      />
      <Picker
        columns={typeList}
        visible={visitSignTypeVisible}
        onCancel={() => {
          setvisitSignTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setvisitSignType(value[0]);
          infoRef.current.visitSignType = value[0];
          form.setFieldValue('visitSignTypeName', extend.items[0]?.label);
          setvisitReasonsVisible(false);
          form.setFieldValue('reasonForFailure', '');
          onValuesChange();
          setvisitSignTypeVisible(false);
        }}
      />
      <Picker
        columns={visitList || visitResultList}
        visible={visitResultVisible}
        onCancel={() => {
          setvisitResultVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setvisitResult(value[0]);
          // @ts-ignore
          localStorage.setItem('visitResult', value[0]);
          form.setFieldValue('visitResultName', extend.items[0]?.label);
          onValuesChange();
          setvisitResultVisible(false);
        }}
      />
      {infoRef.current.visitSignType === 2 && (
        <Picker
          columns={reasonsForFailureNew}
          visible={visitReasonsVisible}
          onCancel={() => {
            setvisitReasonsVisible(false);
          }}
          onConfirm={(value, extend) => {
            console.log(value[0]);
            // @ts-ignore
            form.setFieldValue('reasonForFailure', extend.items[0]?.label);
            onValuesChange();
            setvisitReasonsVisible(false);
          }}
        />
      )}
      {infoRef.current.visitSignType != 2 &&
        infoRef.current.visitSignType != 3 && (
          <Picker
            columns={reasonsForFailureAll}
            visible={visitReasonsVisible}
            onCancel={() => {
              setvisitReasonsVisible(false);
            }}
            onConfirm={(value, extend) => {
              console.log(value[0]);
              // @ts-ignore
              form.setFieldValue('reasonForFailure', extend.items[0]?.label);
              onValuesChange();
              setvisitReasonsVisible(false);
            }}
          />
        )}
      {infoRef.current.visitSignType === 3 && (
        <Picker
          columns={reasonsForFailure}
          visible={visitReasonsVisible}
          onCancel={() => {
            setvisitReasonsVisible(false);
          }}
          onConfirm={(value, extend) => {
            console.log(value[0]);
            // @ts-ignore
            form.setFieldValue('reasonForFailure', extend.items[0]?.label);
            onValuesChange();
            setvisitReasonsVisible(false);
          }}
        />
      )}
      <Picker
        columns={displayTypeList}
        visible={displayTypeVisible}
        onCancel={() => {
          setdisplayTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // setdisplayType(value[0]);
          // infoRef.current.displayType = value[0];
          formComany.setFieldValue('displayType', value[0]);
          formComany.setFieldValue('displayTypeName', extend.items[0]?.label);
          onValuesChange();
          setdisplayTypeVisible(false);
        }}
      />
      <Picker
        columns={detectionMethodList}
        visible={detectionMethodVisible}
        onCancel={() => {
          setdetectionMethodVisible(false);
        }}
        onConfirm={(value, extend) => {
          formComany.setFieldValue('detectionMethod', extend.items[0]?.label);
          onValuesChange();
          setdetectionMethodVisible(false);
        }}
      />
      <DatePicker
        visible={nextVisitTimeVisible}
        onClose={() => {
          setnextVisitTimeVisible(false);
        }}
        min={new Date()}
        title={'下次拜访时间'}
        precision="minute"
        defaultValue={dateJs()}
        renderLabel={labelRenderer}
        onConfirm={(val) => {
          const year = dayjs(val).year();
          const month = dayjs(val).month() + 1;
          const date = dayjs(val).date();
          const hour = dayjs(val).hour();
          const minute = dayjs(val).minute();
          let mon;
          let day;
          let hou;
          let min;
          if (month < 10) {
            mon = `0${month}`;
          } else {
            mon = month;
          }
          if (date < 10) {
            day = `0${date}`;
          } else {
            day = date;
          }
          if (hour < 10) {
            hou = `0${hour}`;
          } else {
            hou = hour;
          }
          if (minute < 10) {
            min = `0${minute}`;
          } else {
            min = minute;
          }
          const msg = `${year}-${mon}-${day} ${hou}:${min}`;
          form?.setFieldsValue({ nextVisitTime: msg });
        }}
      />
      <Picker
        columns={mediaLists}
        visible={mediaVisible}
        onCancel={() => {
          setmediaVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setmediaType(value[0]);
          mediaform.setFieldValue('mediaName', extend.items[0]?.label);
          onValuesChange();
          setmediaVisible(false);
        }}
      />
      <Picker
        columns={orgPicket}
        visible={detectOrgNameVisible}
        onCancel={() => {
          setdetectOrgNameVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          settestOrgId(value[0]);
          testOrgIdform.setFieldValue('detectOrgName', extend.items[0]?.label);
          testOrgIdform.setFieldValue('testOrgId', value[0]);
          onValuesChange();
          setdetectOrgNameVisible(false);
        }}
      />
      <Picker
        columns={partnershipList}
        visible={partnershipVisible}
        onCancel={() => {
          setpartnershipVisible(false);
        }}
        onConfirm={(value, extend) => {
          partnerform.setFieldValue('partnership', extend.items[0]?.label);
          setpartnershipVisible(false);
        }}
      />
      <DatePicker
        visible={timeVisible}
        onClose={() => {
          setTimeVisible(false);
        }}
        title={'合作时间'}
        precision="month"
        renderLabel={labelRenderer}
        onConfirm={(val) => {
          const year = dayjs(val).year();
          const month = dayjs(val).month() + 1;
          const m = month < 10 ? `0${month}` : month;
          const msg = `${year}-${m}-01 00:00:00`;
          
          testOrgIdform?.setFieldsValue({ cooperationTime: msg });
          onValuesChange();
        }}
      />
      <Modal
        visible={visible}
        closeOnAction
        title={'联系人信息'}
        content={
          <Form form={partnerform}>
            <List>
              <List.Item prefix="姓名"
                title={"*"}>
                <Form.Item
                  name="partnerName"
                  rules={[{ required: true, message: '输入合伙人姓名' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入合伙人姓名" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="联系电话"
                title={"*"}>
                <Form.Item
                  name="partnerPhone"
                  rules={[{ required: true, message: '输入合伙人电话' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入合伙人电话" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="合伙人关系" style={{ display: 'none' }}>
                <Form.Item
                  name="partnershipType"
                  rules={[{ required: true, message: '选择合伙人关系' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择合伙人关系" />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="合伙人关系"
                title={"*"}
                onClick={() => {
                  setpartnershipVisible(true);
                }}
              >
                <Form.Item
                  name="partnership"
                  rules={[{ required: true, message: '选择合伙人关系' }]}
                  className={styles.form_item}
                >
                  <Input
                    placeholder="选择合伙人关系"
                    readOnly={true}
                  />
                </Form.Item>
              </List.Item>
              {partnerform.getFieldsValue().partnershipType === 4 && (
                <List.Item
                  prefix=""
                  title={"*"}
                >
                  <Form.Item
                    name="partnershipName"
                    rules={[{ required: true, message: '填写合伙人关系' }]}
                    className={styles.form_item}
                  >
                    <Input
                      placeholder="填写合伙人关系"
                    />
                  </Form.Item>
              </List.Item>
              )}
              <List.Item prefix="分工"
                title={"*"}>
                <Form.Item
                  name="divideTheWork"
                  rules={[{ required: true, message: '分工' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanger}>
                    <Radio value={1}>收车</Radio>
                    <Radio value={2}>卖车</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item prefix="是否决策人"
                title={"*"}>
                <Form.Item
                  name="decisionMakerFlag"
                  rules={[{ required: true, message: '选择是否决策人' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanger}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item prefix="是否检测负责人"
                title={"*"}>
                <Form.Item
                  name="detectPrincipalFlag"
                  rules={[{ required: true, message: '选择是否检测负责人' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanger}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online,
            onClick: async () => {
              partnerform
                .validateFields()
                .then(() => {
                  partnerSubmit(0);
                })
                .catch(() => {
                  setVisible(true);
                });
            },
          },
        ]}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        onAction={() => {}}
      ></Modal>
      <Modal
        visible={visible2}
        closeOnAction
        title={'自媒体信息'}
        content={
          <Form form={mediaform}>
            <List>
              <List.Item
                prefix="自媒体类型"
                onClick={() => {
                  setmediaVisible(true);
                }}
                title={"*"}
              >
                <Form.Item
                  name="mediaName"
                  rules={[{ required: true, message: '选择自媒体类型' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择自媒体类型" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item prefix="自媒体账号" title={"*"}>
                <Form.Item
                  name="account"
                  rules={[{ required: true, message: '输入自媒体账号' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入自媒体账号" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="粉丝数量" extra={'万'} title={"*"}>
                <Form.Item
                  name="fansNum"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('输入粉丝数量(万)'));
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(new Error('不可输入文字！！'));
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入粉丝数量(万)" />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online,
            onClick: () => {
              mediaform
                .validateFields()
                .then(() => {
                  mediaSubmit(0);
                })
                .catch(() => {
                  setVisible2(true);
                });
            },
          },
          // {
          //   key: 'confirm',
          //   text: '提交并继续添加自媒体信息',
          //   onClick:()=>{
          //     mediaSubmit(1)
          //   },
          //   className: styles.confirm
          // },
          // {
          //   key: 'confirm',
          //   text: '取消',
          //   onClick:()=>{
          //     setVisible2(false)
          //   },
          //   className: styles.confirm
          // },
        ]}
        onClose={() => {
          setVisible2(false);
        }}
        showCloseButton
        onAction={() => {}}
      ></Modal>
      <Modal
        visible={merchantsVisible}
        closeOnAction
        onClose={() => {
          setmerchantsVisible(false);
        }}
        showCloseButton
        title={'添加推荐商户'}
        content={
          <Form form={merchantsform}>
            <List>
              <List.Item
                prefix="联系电话"
                title={"*"}
                extra={
                  <a
                    onClick={async () => {
                      let list: any = await Lists({
                        contactMobileNo:
                          merchantsform.getFieldsValue().contactPhone,
                          cityFlag:true
                      });
                      if (list.data.length === 1) {
                        setcontactMobileNoFlag(true);
                        merchantsform.setFieldsValue(list.data[0]);
                        merchantsform.setFieldsValue({companyId:list.data[0].id});
                      } else {
                        if(list.data.length > 1){
                          Toast.show({
                            icon:"fail",
                            content:"商户数量过多，请输入完整手机号查询!!!"
                          })
                        }else{
                          Toast.show({
                            icon:"fail",
                            content:"未查询到绑定商户"
                          })
                        }
                        setcontactMobileNoFlag(false);
                      }
                    }}
                  >
                    商户查询
                  </a>
                }
              >
                <Form.Item
                  name="contactPhone"
                  rules={[{ required: true, message: '输入联系电话' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入联系电话" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="商户名称" style={{ display: 'none' }}
                title={"*"}>
                <Form.Item
                  name="companyId"
                  rules={[{ required: true, message: '输入商户名称' }]}
                  className={styles.form_item}
                  style={{ display: 'none' }}
                >
                  <Input
                    placeholder="输入商户名称"
                    readOnly={contactMobileNoFlag}
                  />
                </Form.Item>
              </List.Item>
              <List.Item prefix="商户名称"
                title={"*"}>
                <Form.Item
                  name="companyName"
                  rules={[{ required: true, message: '输入商户名称' }]}
                  className={styles.form_item}
                >
                  <Input
                    placeholder="输入商户名称"
                    readOnly={contactMobileNoFlag}
                  />
                </Form.Item>
              </List.Item>
              <List.Item prefix="联系人姓名"
                title={"*"}>
                <Form.Item
                  name="contactName"
                  rules={[{ required: true, message: '输入联系人姓名' }]}
                  className={styles.form_item}
                >
                  <Input
                    placeholder="输入联系人姓名"
                    readOnly={contactMobileNoFlag}
                  />
                </Form.Item>
              </List.Item>
              <List.Item prefix="推荐方式"
                title={"*"}>
                <Form.Item
                  name="recommendedType"
                  rules={[{ required: true, message: '选择推荐方式' }]}
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={1}>本地推荐</Radio>
                    <Radio value={2}>异地推荐</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="推荐原因"
                onClick={() => {
                  setrecommendedReasonsVisible(true);
                }}
              >
                <Form.Item
                  name="recommendedReasons"
                  className={styles.form_item}
                >
                  <Input placeholder="选择推荐原因" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="合作频次"
                onClick={() => {
                  setcooperationFrequencyVisible(true);
                }}
              >
                <Form.Item
                  name="cooperationFrequency"
                  className={styles.form_item}
                >
                  <Input placeholder="选择合作频次" readOnly />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online,
            onClick: () => {
              merchantsform
                .validateFields()
                .then(() => {
                  merchantsSubmit(0);
                })
                .catch(() => {
                  setmerchantsVisible(true);
                });
            },
          },
        ]}
        onAction={() => {}}
      ></Modal>
      <Modal
        visible={customerVisible}
        closeOnAction
        title={'客户来源'}
        content={
          <Form form={customerform}>
            <List>
              <List.Item
                prefix="客户来源"
                onClick={() => {
                  setCustomerVisible2(true);
                }}
                title={"*"}
              >
                <Form.Item
                  name="customerSourceName"
                  rules={[{ required: true, message: '选择客户来源' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择客户来源" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item prefix="来源占比" extra={'%'}
                title={"*"}>
                <Form.Item
                  name="proportion"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('输入来源占比(%)'));
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(new Error('不可输入文字！！'));
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入来源占比(%)" />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online,
            onClick: () => {
              customerform
                .validateFields()
                .then(() => {
                  customerformSubmit(0);
                })
                .catch(() => {
                  setCustomerVisible(true);
                });
            },
          },
          // {
          //   key: 'confirm',
          //   text: '提交并继续添加客户来源',
          //   onClick:()=>{
          //     customerformSubmit(1)
          //   },
          //   className: styles.confirm
          // },
          // {
          //   key: 'confirm',
          //   text: '取消',
          //   onClick:()=>{
          //     setCustomerVisible(false)
          //   },
          //   className: styles.confirm
          // },
        ]}
        onClose={() => {
          setCustomerVisible(false);
        }}
        showCloseButton
        onAction={() => {}}
      ></Modal>
      <Picker
        columns={cooperationFrequencyList}
        visible={cooperationFrequencyVisible}
        onCancel={() => {
          setcooperationFrequencyVisible(false);
        }}
        onConfirm={(value, extend) => {
          merchantsform.setFieldValue('cooperationFrequency', extend.items[0]?.label);
          setcooperationFrequencyVisible(false);
        }}
      />
      <Picker
        columns={recommendedReasonsList}
        visible={recommendedReasonsVisible}
        onCancel={() => {
          setrecommendedReasonsVisible(false);
        }}
        onConfirm={(value, extend) => {
          merchantsform.setFieldValue('recommendedReasons', extend.items[0]?.label);
          setrecommendedReasonsVisible(false);
        }}
      />
      <Picker
        columns={customerList}
        visible={customerVisible2}
        onCancel={() => {
          setCustomerVisible2(false);
        }}
        onConfirm={(value, extend) => {
          customerform.setFieldValue('customerSourceName', extend.items[0]?.label);
          setCustomerVisible2(false);
        }}
      />
      <Modal
        visible={visible3}
        closeOnAction
        title={'竞队信息'}
        content={
          <Form form={testOrgIdform}>
            <List>
              <List.Item
                title={"*"}
                prefix="检测机构"
                onClick={() => {
                  setdetectOrgNameVisible(true);
                }}
              >
                <Form.Item
                  name="detectOrgName"
                  rules={[{ required: true, message: '选择检测机构' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测机构" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="检测机构"
                onClick={() => {
                  setdetectOrgNameVisible(true);
                }}
                style={{ display: 'none' }}
              >
                <Form.Item
                  name="testOrgId"
                  rules={[{ required: true, message: '选择检测机构' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测机构" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="合作时间"
                title={"*"}
                onClick={() => {
                  setTimeVisible(true);
                }}
              >
                <Form.Item
                  name="cooperationTime"
                  rules={[{ required: true, message: '选择合作时间' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择合作时间" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item prefix="集采余额" extra={'万'}
                title={"*"}>
                <Form.Item
                  name="purchaseBalance"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('输入集采余额'));
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(new Error('不可输入文字！！'));
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入集采余额" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="集采库存"
                title={"*"}>
                <Form.Item
                  name="purchasingStock"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('输入集采库存'));
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(new Error('不可输入文字！！'));
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入集采库存" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="硬广覆盖"
                title={"*"}>
                <Form.Item
                  name="hardCover"
                  rules={[{ required: true, message: '选择是否硬广覆盖' }]}
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              {testOrgIdform.getFieldsValue().hardCover === 1 && (
                <List.Item prefix="竞队广告"
                title={"*"}>
                  <Form.Item name="doorway" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {advertiseImgs && (
                        <Image
                          src={advertiseImgs}
                          style={{
                            width: '60px',
                            height: '60px',
                            marginRight: '5px',
                            borderRadius: '5px',
                          }}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(advertiseImgs);
                          }}
                        />
                      )}

                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(3);
                        }}
                      >
                        <CameraOutline fontSize={20} />
                        <div style={{ marginTop: '2px' }}>照片</div>
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
              )}
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online,
            onClick: () => {
              testOrgIdform
                .validateFields()
                .then(() => {
                  testOrgIdSubmit(0);
                })
                .catch(() => {
                  setVisible3(true);
                });
            },
          },
          // {
          //   key: 'confirm',
          //   text: '提交并继续添加竞队信息',
          //   onClick:()=>{
          //     testOrgIdSubmit(1)
          //   },
          //   className: styles.confirm
          // },
          // {
          //   key: 'confirm',
          //   text: '取消',
          //   onClick:()=>{
          //     setVisible3(false);
          //   },
          //   className: styles.confirm
          // },
        ]}
        onClose={() => {
          setVisible3(false);
        }}
        showCloseButton
        onAction={() => {}}
      ></Modal>

      <div className={styles.steepper}>
        <Modal
          visible={priceVisible}
          closeOnAction
          title={'价位范围'}
          content={
            <div className={styles.steeppers}>
              <Form form={formPrice} className={styles.form_modal}>
                <Form.Item
                  name="priceA"
                  rules={[{ required: true, message: '选择价位' }]}
                  className={styles.form_item}
                >
                  <Stepper />
                </Form.Item>
                <span style={{ fontSize: '16px', margin: 'auto' }}>-</span>
                <Form.Item
                  name="priceB"
                  rules={[{ required: true, message: '选择价位' }]}
                  className={styles.form_item}
                >
                  <Stepper />
                </Form.Item>
              </Form>
            </div>
          }
          actions={[
            {
              key: 'confirm',
              text: '确定',
            },
          ]}
          onClose={() => {
            setPriceVisible(false);
          }}
          showCloseButton
          onAction={() => {
            const values = formPrice.getFieldsValue();
            console.log(values);
            values.priceA = values.priceA ? values.priceA : 0;
            values.priceB = values.priceB ? values.priceB : 0;
            if (values.priceA > values.priceB) {
              Toast.show({
                icon: 'fail',
                content: '价格区间格式错误!!! (A < B)',
              });
              return;
            }
            formComany.setFieldsValue({
              price: `${values.priceA}-${values.priceB}`,
            });
            onValuesChange();
            setPriceValue(values);
          }}
        ></Modal>

        <Picker
          columns={degreeIntentList}
          visible={degreeIntentVisible}
          onCancel={() => {
            setdegreeIntentVisible(false);
          }}
          onConfirm={(value, extend) => {
            // setdegreeIntent(value[0]);
            infoRef.current.degreeIntent = value[0];
            formComany.setFieldValue(
              'degreeIntentName',
              extend.items[0]?.label,
            );
            setdegreeIntentVisible(false);
          }}
        />
        <ImageViewer
          image={allImage}
          visible={imageVisible}
          onClose={() => {
            setImageVisible(false);
          }}
        />
        <Merchant
          visit={false}
          checkFlag={false}
          cityFlag={false}
          ButtonFlag={false}
          nameVisible={nameVisible}
          onSubmit={handelUserName}
          onCancel={handleUserNameCancel}
        />
        <Market
          nameVisible={marketVisible}
          onSubmit={handelUserMarket}
          onCancel={handleUserMarketCancel}
          value={false} list={undefined}        />
        <Merchant
          visit={true}
          checkFlag={false}
          cityFlag={false}
          ButtonFlag={false}
          nameVisible={recommendationsVisible}
          onSubmit={handelrecommendationsUserName}
          onCancel={handleRecommendationsCancel}
        />
        <Popup
          visible={collectVisible}
          onMaskClick={() => {
            setCollectVisible(false);
          }}
          className={styles.popup}
          onClose={() => {
            setCollectVisible(false);
          }}
          bodyStyle={{
            height: '40vh',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CheckList
            multiple={true}
            style={{ flex: '1 1', overflow:"scroll" }}
            onChange={onchanges}
          >
            {offeringsList.length > 0 &&
              offeringsList.map((item: any, index: number) => {
                return <CheckList.Item value={item}>{item}</CheckList.Item>;
              })}
          </CheckList>
          <div className={styles.footer_3}>
            <div className={styles.button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setCollectVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  let str = '';
                  if (checkCollect && checkCollect.length > 0) {
                    checkCollect.map((item: any) => {
                      str = str + item + '、';
                    });
                  }
                  form.setFieldValue('productName', str);
                  onValuesChange();
                  setCollectVisible(false);
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
      <Popup
        visible={visitingPersonnelVisible}
        className={styles.popup}
        onMaskClick={() => {
          setvisitingPersonnelVisible(false);
        }}
        onClose={() => {
          setvisitingPersonnelVisible(false);
        }}
        bodyStyle={{
          height: '40vh',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CheckList multiple={true} style={{ flex: '1 1', overflow:"scroll" }} onChange={onchangePartners} className={styles.checkList}>
          {carDealerPartners && carDealerPartners.length > 0 &&
            carDealerPartners.map((item: any, index: number) => {
              return <CheckList.Item value={item.partnerName}>{item.partnerName} {item.partnerPhone}</CheckList.Item>;
            })}
        </CheckList>
        <div className={styles.footer_3}>
          <div className={styles.button}>
            <Button
              color="primary"
              fill="outline"
              onClick={() => {
                setvisitingPersonnelVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="middle"
              color="primary"
              onClick={() => {
                let str = '';
                if (checkPartners && checkPartners.length > 0) {
                  checkPartners.map((item: any,index:number) => {
                    str = str + item + `${index+1 != checkPartners.length ? "、" : ""}`;
                  });
                }
                form.setFieldValue('visitingPersonnel', str);
                setvisitingPersonnelVisible(false);
              }}
            >
              确定
            </Button>
          </div>
        </div>
      </Popup>
        {/* 品牌类型 */}
        <Popup
          visible={brandTypeVisible}
          className={styles.popup}
          onMaskClick={() => {
            setbrandTypeVisible(false);
          }}
          onClose={() => {
            setbrandTypeVisible(false);
          }}
          bodyStyle={bodyStyle}
        >
          <CheckList
            multiple={true}
            style={{ flex: '1 1', overflow: 'scroll' }}
            onChange={onchangeBrandType}
            className={styles.checkList}
          >
            {BrandTypeList &&
              BrandTypeList.length > 0 &&
              BrandTypeList[0].map((item: any, index: number) => {
                return (
                  <CheckList.Item value={item.label}>
                    {item.label}
                  </CheckList.Item>
                );
              })}
          </CheckList>
          <div className={styles.footer_3}>
            <div className={styles.button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setbrandTypeVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  let str = '';
                  if (brandTypeList && brandTypeList.length > 0) {
                    brandTypeList.map((item: any, index: number) => {
                      str =
                        str +
                        item +
                        `${index + 1 != brandTypeList.length ? '、' : ''}`;
                    });
                  }
                  formComany.setFieldValue('brandType', str);
                  setbrandTypeVisible(false);
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
        {/* 车辆类型 */}
        <Popup
          visible={vehicleTypeVisible}
          className={styles.popup}
          onMaskClick={() => {
            setvehicleTypeVisible(false);
          }}
          onClose={() => {
            setvehicleTypeVisible(false);
          }}
          bodyStyle={bodyStyle}
        >
          <CheckList
            multiple={true}
            style={{ flex: '1 1', overflow: 'scroll' }}
            onChange={onchangevehicleType}
            className={styles.checkList}
          >
            {vehicleTypeList &&
              vehicleTypeList.length > 0 &&
              vehicleTypeList[0].map((item: any, index: number) => {
                return (
                  <CheckList.Item value={item.label}>
                    {item.label}
                  </CheckList.Item>
                );
              })}
          </CheckList>
          <div className={styles.footer_3}>
            <div className={styles.button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setvehicleTypeVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  let str = '';
                  if (vehicleTypeLists && vehicleTypeLists.length > 0) {
                    vehicleTypeLists.map((item: any, index: number) => {
                      str =
                        str +
                        item +
                        `${index + 1 != vehicleTypeLists.length ? '、' : ''}`;
                    });
                  }
                  formComany.setFieldValue('vehicleType', str);
                  setvehicleTypeVisible(false);
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
        {/* 价格区间 */}
        <Popup
          visible={priceRangeVisible}
          className={styles.popup}
          onMaskClick={() => {
            setpriceRangeVisible(false);
          }}
          onClose={() => {
            setpriceRangeVisible(false);
          }}
          bodyStyle={bodyStyle}
        >
          <CheckList
            multiple={true}
            style={{ flex: '1 1', overflow: 'scroll' }}
            onChange={onchangepriceRange}
            className={styles.checkList}
          >
            {priceRangeList &&
              priceRangeList.length > 0 &&
              priceRangeList[0].map((item: any, index: number) => {
                return (
                  <CheckList.Item value={item.label}>
                    {item.label}
                  </CheckList.Item>
                );
              })}
          </CheckList>
          <div className={styles.footer_3}>
            <div className={styles.button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setpriceRangeVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  let str = '';
                  if (priceRangeLists && priceRangeLists.length > 0) {
                    priceRangeLists.map((item: any, index: number) => {
                      str =
                        str +
                        item +
                        `${index + 1 != priceRangeLists.length ? '、' : ''}`;
                    });
                  }
                  formComany.setFieldValue('priceRange', str);
                  setpriceRangeVisible(false);
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}
