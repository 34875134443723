import Toast from 'antd-mobile/es/components/toast';
import React, { useEffect, useState } from 'react';
import styles from './index.less';
import { history, useLocation } from 'umi';
import {
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  List,
  Modal,
  Popup,
  Radio,
  Tag,
  TextArea,
} from 'antd-mobile';
import { dateFormat } from '../utils/util';
import { cancel, recordSave } from '../PayAVisit/service';
import { detail, save, visitClick } from './service';
import { labellist, remarkLists } from '../programme/service';
import { parseResultName } from '../PayAVisit/newsPlan';
import { sleep } from 'antd-mobile/es/utils/sleep';
import MyPie from '../programme/common/MyPie';

export const type = (type: number) => {
  let result;
  if (type === 1) {
    result = '已改约';
  }
  if (type === 3) {
    result = '已取消';
  }
  if (type === 0) {
    result = '待执行';
  }
  return result;
};
export default function visitDetail() {
  const location = useLocation();
  const dataFlag: any = location.state;
  const [form] = Form.useForm();
  const [remarkValue, setRemarkValue] = useState<any>([]);
  const [visible4, setVisible4] = useState<boolean>(false);
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [remarkRecordId, setremarkRecordId] = useState<any>([]);
  const [mCarDealerVisitId, setmCarDealerVisitId] = useState<any>([]);
  const [dataFlags, setdataFlags] = useState<any>([]);
  const [isView, setIsView] = useState<any[]>([]);
  const [isViewInt, setIsViewInt] = useState<any[]>([]);
  const [isViewDone, setIsViewDone] = useState<any[]>([]);
  const [meritList, setMeritList] = useState<any>([]);
  const [meritListDone, setMeritListDone] = useState<any>([]);
  const [valuez, setValues] = useState<any>([]);
  const [moreMap, setMoreMap] = useState<any>({});
  const [scoreList, setScoreList] = useState<any>([]);
  const [remarkform] = Form.useForm();
  const onRemarkFinish = async () => {
    const values = remarkform.getFieldsValue();
    if (remarkValue === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写备注！',
        // duration: 0,
      });
      return;
    }
    values.remarkContent = remarkValue;
    values.labelId = remarkChild.labelId;
    values.labelName = remarkChild.labelName;
    values.parentId = remarkChild.id;
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = localStorage.getItem('avatar');
    values.mCarDealerVisitId = mCarDealerVisitId;
    values.remarkRecordId = remarkRecordId;
    console.log(values);

    const msg = await recordSave(values);
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '保存成功',
      });
      initValue();
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
    setVisible4(false);
  };
  const initValue = async () => {
    const append = await detail({
      id: dataFlag.id,
    });

    if (dataFlag && dataFlag.companyId && dataFlag.userId) {
      scoreInit(dataFlag)
      valuePoint({})
    }
    if (!dataFlag.clickFlag && localStorage.getItem('isLeaderInDept') === '1') {
      visitClick({ id: dataFlag.id })
        .then((msg) => {
          if (msg.retCode === 0) {
            // Toast.show({
            //   icon: 'success',
            //   content: msg.retMsg,
            // });
          } else {
            Toast.show({
              icon: 'fail',
              content: msg.retMsg,
            });
          }
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '查看失败',
          });
        });
    }
    if (append.retCode === 0) {
      setdataFlags(append.data);
    }
    // const res = await labellist({});
    // if (res.retCode === 0) {

    // }
    labellist({ companyId: dataFlag.companyId }).then((res) => {
      if (res.retCode === 0) {
        if (res.data.length > 0) {
          let data = res.data;
          data.map((item: any, index: number) => {
            if (valuez[item.id]) {
              isView[index] = true;
              setIsView([...isView]);
            }
            if (item.mCarDealerLabelVos && item.mCarDealerLabelVos.length > 0) {
              item.mCarDealerLabelVos.map((child: any, childIndex: number) => {
                let flag = false;
                if (valuez[child.id]) {
                  isView[index] = true;
                  setIsView([...isView]);
                }
                if (
                  child.remarkRecordDetails &&
                  child.remarkRecordDetails.length > 0
                ) {
                  child.remarkRecordDetails.map((children: any) => {
                    if (
                      children.mRemarkRecordDetails &&
                      children.mRemarkRecordDetails.length > 0
                    ) {
                      children.mRemarkRecordDetails.map((childItem: any) => {
                        if (
                          childItem.mRemarkRecordDetails &&
                          childItem.mRemarkRecordDetails.length > 0
                        ) {
                          childItem.mRemarkRecordDetails.map(
                            (childrenItem: any) => {
                              if (
                                childrenItem.mRemarkRecordDetails &&
                                childrenItem.mRemarkRecordDetails.length > 0
                              ) {
                                childrenItem.mRemarkRecordDetails.map(
                                  (flagItem: any) => {
                                    if (
                                      flagItem.appraiseType &&
                                      flagItem.appraiseType < 3
                                    ) {
                                      flag = true;
                                    }
                                  },
                                );
                              }
                            },
                          );
                        }
                      });
                    }
                  });
                  if (flag) {
                    delete data[index].mCarDealerLabelVos[childIndex];
                  }
                }
              });
            }
          });
          setMeritList(data);
        }
      }
    });
    labellist({ companyId: dataFlag.companyId }).then((res) => {
      if (res.retCode === 0) {
        if (res.data.length > 0) {
          let data = res.data;
          data.map((item: any, index: number) => {
            if (item.mCarDealerLabelVos && item.mCarDealerLabelVos.length > 0) {
              item.mCarDealerLabelVos.map((child: any, childIndex: number) => {
                let flag = false;
                if (
                  child.remarkRecordDetails &&
                  child.remarkRecordDetails.length > 0
                ) {
                  child.remarkRecordDetails.map((children: any) => {
                    if (
                      children.mRemarkRecordDetails &&
                      children.mRemarkRecordDetails.length > 0
                    ) {
                      children.mRemarkRecordDetails.map((childItem: any) => {
                        if (
                          childItem.mRemarkRecordDetails &&
                          childItem.mRemarkRecordDetails.length > 0
                        ) {
                          childItem.mRemarkRecordDetails.map(
                            (childrenItem: any) => {
                              if (
                                childrenItem.mRemarkRecordDetails &&
                                childrenItem.mRemarkRecordDetails.length > 0
                              ) {
                                childrenItem.mRemarkRecordDetails.map(
                                  (flagItem: any) => {
                                    if (
                                      flagItem.appraiseType &&
                                      flagItem.appraiseType < 3
                                    ) {
                                      flag = true;
                                    }
                                  },
                                );
                              }
                            },
                          );
                        }
                      });
                    }
                  });
                  if (
                    flag === false &&
                    data[index] &&
                    data[index].mCarDealerLabelVos
                  ) {
                    delete data[index].mCarDealerLabelVos[childIndex];
                  }
                } else {
                  delete data[index];
                }
              });
            }
          });
          console.log(data);
          let list = data;
          if (list.length > 0) {
            list.map((item: any, index: number) => {
              let doneFlag = false;
              if (
                item.mCarDealerLabelVos &&
                item.mCarDealerLabelVos.length > 0
              ) {
                item.mCarDealerLabelVos.map((child: any) => {
                  if (child.remarkRecordDetails) {
                    doneFlag = true;
                  } else {
                    doneFlag = false;
                  }
                });
              }
              if (!doneFlag) {
                delete list[index];
              }
            });
          }
          console.log(list);
          const filteredArr = list.filter((item: any) => item);
          console.log(filteredArr);
          setMeritListDone(filteredArr);
        }
      }
    });
    if (dataFlag.carLabelIds) {
      dataFlag.carLabelIds.map((item: any) => {
        valuez[item] = item;
        setValues([...valuez]);
      });
    }
  };
  const Finish = async () => {
    let list: any = [];
    valuez.map((item: any) => {
      if (item != undefined) {
        list.push(item);
      }
    });
    const data = {
      companyId: dataFlag.companyId,
      userId: dataFlag.createUserId,
      visitSignType: dataFlag.visitSignType,
      visitContent: dataFlag.visitContent,
      userName: dataFlag.createUserName,
      companyName: dataFlag.companyName,
      targetAmount: dataFlag.targetAmount,
      visitingTime: dataFlag.visitingTime,
      labelIds: list,
      token: localStorage.getItem('token'),
    };
    save(data)
      .then((msg) => {
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '提交成功',
          });
          history.goBack();
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
          return;
        }
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: '提交失败',
        });
      });
  };
  const rowClick = (index: number) => {
    const cache = isView;
    cache[index] = !cache[index];
    setIsView([...cache]);
  };
  const rowClickDone = (index: number) => {
    const cache = isViewDone;
    cache[index] = !cache[index];
    setIsViewDone([...cache]);
  };
  const onDelete = async (id: any) => {
    const values = form.getFieldsValue();
    console.log(values);

    const msg = await cancel({ id: id, cancelRemark: values.forms, status: 6 });
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '成功驳回计划',
      });
      form.resetFields();
      // pullListMore(checkoutFlag)
      history.goBack();
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };
  const valuePoint = async (value:any)=>{
    const res = await labellist({
      companyId: value.companyId,
      parentId: value.parentId,
    });
    const msg = await labellist({
      companyId: value.companyId,
      parentId: value.parentId,
    });
    if (res.retCode === 0) {
      if (res.data && res.data.length > 0) {
        let unfinished = res.data;
        unfinished.map((item: any, index: number) => {
          if (
            item.mCarDealerLabelVos &&
            item.mCarDealerLabelVos.length > 0
          ) {
            item.mCarDealerLabelVos.map(
              (child: any, childIndex: number) => {
                let flag = false;
                if (
                  child.remarkRecordDetails &&
                  child.remarkRecordDetails.length > 0
                ) {
                  child.remarkRecordDetails.map((children: any) => {
                    if (
                      children.mRemarkRecordDetails &&
                      children.mRemarkRecordDetails.length > 0
                    ) {
                      children.mRemarkRecordDetails.map(
                        (childItem: any) => {
                          if (
                            childItem.mRemarkRecordDetails &&
                            childItem.mRemarkRecordDetails.length > 0
                          ) {
                            childItem.mRemarkRecordDetails.map(
                              (childrenItem: any) => {
                                if (
                                  childrenItem.mRemarkRecordDetails &&
                                  childrenItem.mRemarkRecordDetails
                                    .length > 0
                                ) {
                                  childrenItem.mRemarkRecordDetails.map(
                                    (flagItem: any) => {
                                      if (
                                        flagItem.appraiseType &&
                                        flagItem.appraiseType < 3
                                      ) {
                                        flag = true;
                                      }
                                    },
                                  );
                                }
                              },
                            );
                          }
                        },
                      );
                    }
                  });
                  if (flag) {
                    delete unfinished[index].mCarDealerLabelVos[childIndex];
                  }
                }
              },
            );
          }
        });
        console.log(unfinished);
        
        setMeritList(unfinished);
      }
    }
    if (msg.retCode === 0) {
      if (msg.data && msg.data.length > 0) {
        let alreadyFinalize = msg.data;
        alreadyFinalize.map((item: any, index: number) => {
          if (
            item &&
            item.mCarDealerLabelVos &&
            item.mCarDealerLabelVos.length > 0
          ) {
            item.mCarDealerLabelVos.map(
              (child: any, childIndex: number) => {
                let flag = false;
                if (
                  child.remarkRecordDetails &&
                  child.remarkRecordDetails.length > 0
                ) {
                  child.remarkRecordDetails.map((children: any) => {
                    if (
                      children.mRemarkRecordDetails &&
                      children.mRemarkRecordDetails.length > 0
                    ) {
                      children.mRemarkRecordDetails.map(
                        (childItem: any) => {
                          if (
                            childItem.mRemarkRecordDetails &&
                            childItem.mRemarkRecordDetails.length > 0
                          ) {
                            childItem.mRemarkRecordDetails.map(
                              (childrenItem: any) => {
                                if (
                                  childrenItem.mRemarkRecordDetails &&
                                  childrenItem.mRemarkRecordDetails
                                    .length > 0
                                ) {
                                  childrenItem.mRemarkRecordDetails.map(
                                    (flagItem: any) => {
                                      if (
                                        flagItem.appraiseType &&
                                        flagItem.appraiseType < 3
                                      ) {
                                        flag = true;
                                      }
                                    },
                                  );
                                }
                              },
                            );
                          }
                        },
                      );
                    }
                  });
                  if (
                    !flag &&
                    alreadyFinalize[index] &&
                    alreadyFinalize[index].mCarDealerLabelVos &&
                    alreadyFinalize[index].mCarDealerLabelVos[childIndex]
                  ) {
                    delete alreadyFinalize[index].mCarDealerLabelVos[childIndex];
                  }
                } else {
                  delete alreadyFinalize[index];
                }
              },
            );
          }
        });
        let list = alreadyFinalize;
        if (list.length > 0) {
          list.map((item: any, index: number) => {
            let doneFlag = false;
            if (
              item.mCarDealerLabelVos &&
              item.mCarDealerLabelVos.length > 0
            ) {
              item.mCarDealerLabelVos.map((child: any) => {
                if (child.remarkRecordDetails) {
                  doneFlag = true;
                } else {
                  doneFlag = false;
                }
              });
            }
            if (!doneFlag) {
              delete list[index];
            }
          });
        }
        const filteredArr = alreadyFinalize.filter((item: any) => item);
        console.log(filteredArr);
        setMeritListDone(filteredArr);
      }
    }
  }
  const scoreInit = async (dataFlag:any) =>{
    if(dataFlag.companyId && dataFlag.userId){
      const score = await remarkLists({
        companyId: dataFlag.companyId,
        userId: dataFlag.userId,
      });
      if(score.data){
        setScoreList({data:score.data,companyId:dataFlag.companyId});
        score.data.map((item:any)=>{
          if(item.labelName === "专业人设"){
            valuePoint({companyId:dataFlag.companyId,parentId:item.labelId})
          }
        })
      }
    }
  }
  useEffect(() => {
    if (dataFlag) {
      console.log(dataFlag);
      initValue();
    }
  }, []);
  const displayMap: any = {
    0: '未知',
    1: '独立展厅',
    2: '露天市场',
    3: '大棚市场',
    4: '室内市场',
  };
  const degreeIntentMap: any = {
    1: '30天以上',
    2: '14天-30天',
    3: '7-14天',
    4: '3天内',
    5: '3天内',
  };
  return (
    <div className={styles.detail}>
      <Card
        title={
          <div>
            <span style={{ fontWeight: '400', fontSize: '14px' }}>
              {dataFlag.visitSignTypeName}
            </span>
            <span
              style={{
                marginLeft: '37px',
                fontWeight: '400',
                fontSize: '14px',
              }}
            >
              拜访
              {`${dataFlags.numberOfVisits}` != ''
                ? dataFlags.numberOfVisits + 1
                : 0}
              次
            </span>
          </div>
        }
        extra={
          <div
            style={{ color: '#FF5705', fontWeight: '400', fontSize: '12px' }}
          >
            {type(dataFlag.type)}
          </div>
        }
        style={{ borderRadius: '8px' }}
      >
        <div className={styles.content}>
          <div className={styles.content_0}>
            <div>计划ID</div>
            <span>{dataFlags.id || 0}</span>
          </div>
          {dataFlags.visitSignType !== 12 && (
            <div className={styles.content_0}>
              <div>拜访车商</div>
              <span>{dataFlags.companyName || '-'}</span>
            </div>
          )}
          {dataFlags.visitSignType === 13 && (
            <>
              {dataFlags.visitCompanyRelations &&
                dataFlags.visitCompanyRelations.map((item: any) => {
                  return (
                    <div className={styles.content_0}>
                      <div>推荐车商</div>
                      <span>{item.companyName || '-'}</span>
                    </div>
                  );
                })}
            </>
          )}
          {dataFlags.visitSignType === 12 && (
            <div className={styles.com}>
              {dataFlags.visitCompanyRelations &&
                dataFlags.visitCompanyRelations.map(
                  (child: any, childIndex: number) => {
                    return (
                      <div
                        className={styles.completed}
                        key={`${childIndex}-wait`}
                        style={{ display: 'flex', marginTop: '10px' }}
                      >
                        <div
                          className={styles.completed_0}
                          style={{
                            opacity: childIndex === 0 ? 100 : 0,
                            width: '34%',
                          }}
                        >
                          拜访车商(
                          {dataFlags.visitCompanyRelations.length})
                        </div>
                        <div
                          className={styles.completed_1}
                          style={{ display: 'flex', flex: 1 }}
                        >
                          <div style={{ flex: 1 }}>{child.companyName}</div>
                          {!child.completedFlag ? (
                            <span
                              style={{
                                color: 'rgba(255, 87, 5, 1)',
                              }}
                            >
                              待拜访
                            </span>
                          ) : (
                            <span
                              style={{
                                color: 'rgba(41, 165, 31, 1)',
                              }}
                            >
                              已拜访
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  },
                )}
            </div>
          )}
          {dataFlags.visitSignType !== 12 && dataFlags.mCompanyInfo && (
            <>
              <div className={styles.content_0}>
                <div>商户库存</div>
                <span>{dataFlags.mCompanyInfo.notUsedNum || '0'}</span>
              </div>
              <div className={styles.content_0}>
                <div>城市</div>
                <span>{dataFlags.mCompanyInfo?.contactCityName || '-'}</span>
              </div>
              <div className={styles.content_0}>
                <div>车位数</div>
                <span>{dataFlags.mCompanyInfo.parkingNum || '0'}</span>
              </div>
              <div className={styles.content_0}>
                <div>市场</div>
                <span>{dataFlags.mCompanyInfo?.belongMarketName || '-'}</span>
              </div>
              {/* <div className={styles.content_0}>
                <div>联系人</div>
                <span>{dataFlags.mCompanyInfo?.contactName || '-'}</span>
              </div> */}
              <div className={styles.content_0}>
                <div>展厅</div>
                <span>
                  {displayMap[dataFlags.mCompanyInfo?.contactCityName] || '-'}
                </span>
              </div>
              <div className={styles.content_0}>
                <div>集采次数</div>
                <span>
                  {dataFlags.mCompanyInfo.collectiveProcurementNum || '0'}
                </span>
              </div>
              <div className={styles.content_0}>
                <div>意向度</div>
                <span>
                  {degreeIntentMap[dataFlags.mCompanyInfo.degreeIntent] || '-'}
                </span>
              </div>
            </>
          )}
          <div className={styles.content_0}>
            <div>姓名</div>
            <span>{dataFlags.updateUserName || '-'}</span>
          </div>
          <div className={styles.content_0}>
            <div>拜访时间</div>
            <span>
              {dataFlag.visitingTime
                ? dateFormat(dataFlag.visitingTime, 'yyyy-MM-dd hh:mm:ss')
                : '-'}
            </span>
          </div>
          {dataFlags.visitSignType !== 12 && (
            <>
              <div className={styles.content_0}>
                <div>目标金额</div>
                <div className={styles.result}>
                  {dataFlags.targetAmount ? dataFlags.targetAmount : '0'}元
                </div>
              </div>
              <div className={styles.content_0}>
                <div>目标方案</div>
                <div className={styles.result}>
                  {dataFlags.targetPlanType === 2 && '集采城市限价' }
                  {dataFlags.targetPlanType === 1 && '形象店' }
                  {dataFlags.targetPlanType === 0 && '产品买赠方案'}
                </div>
              </div>
              <div className={styles.content_0}>
                <div>检测产品</div>
                <span>
                  {dataFlags.productName ? dataFlags.productName : '-'}
                </span>
              </div>
              <div className={styles.content_0}>
                <div>商户建议</div>
                <div className={styles.result}>
                  {dataFlags.visitContent ? dataFlags.visitContent : '-'}
                </div>
              </div>
            </>
          )}
        </div>

        {scoreList && scoreList.data && scoreList.data.length > 0 && (
          <div>
            <MyPie onPointChange={valuePoint} companyId={scoreList.companyId} current={scoreList.data}></MyPie>
          </div>
        )}
        <div className={styles.merit}>
          <div className={styles.merit_title_0} style={{ fontWeight: '600' }}>
            选择本次拜访价值点 <Tag color="rgba(254, 26, 26, 0.3)">未完成</Tag>
          </div>
          {meritList &&
            meritList.map((item: any, index: number) => {
              if (
                item.mCarDealerLabelVos &&
                item.mCarDealerLabelVos.length > 0
              ) {
                let flag = false;
                if (valuez && valuez.length > 0 && valuez.indexOf(item.id)>-1) {
                  flag = true;
                }
                return (
                  <div>
                    <div
                      className={styles.merit_title}
                      style={{ color: `${flag && 'red'}` }}
                      key={item.id}
                    >
                      <div
                        onClick={() => {
                          console.log(isView);

                          rowClick(index);
                        }}
                      >
                        {item.name}{' '}
                        <span
                          style={{
                            transform: `${
                              !isView[index]
                                ? 'rotate(-135deg)'
                                : 'rotate(-45deg)'
                            }`,
                          }}
                        ></span>
                      </div>
                      <Checkbox
                        // defaultChecked={valuez}
                        onChange={(checked) => {
                          console.log(valuez);

                          let list: any[] = [];
                          if (checked) {
                            item.mCarDealerLabelVos.map((check: any) => {
                              valuez[check.id] = check.id;
                            });
                            valuez[item.id] = item.id;
                            setValues([...valuez]);
                          } else {
                            item.mCarDealerLabelVos.map((check: any) => {
                              valuez[check.id] = undefined;
                            });
                            valuez[item.id] = undefined;
                            setValues([...valuez]);
                          }
                        }}
                        value={item.id}
                        checked={valuez.indexOf(item.id) > -1}
                      />
                    </div>
                    {isView[index] && (
                      <div className={styles.merit_check}>
                        {item.mCarDealerLabelVos.length > 0 &&
                          item.mCarDealerLabelVos.map(
                            (ChildItem: any, cIndex: number) => {
                              let ChildFlag = false;
                              if (valuez[ChildItem.id] === ChildItem.id) {
                                ChildFlag = true;
                              }
                              return (
                                <Checkbox.Group value={valuez}>
                                  <div
                                    className={styles.merit_check_0}
                                    // key={`MeritList-${index}-${cIndex}`}
                                    style={{ color: `${ChildFlag && 'red'}` }}
                                    key={ChildItem.id}
                                  >
                                    <div>{ChildItem.name}</div>
                                    <Checkbox
                                      onChange={(checked) => {
                                        if (checked) {
                                          console.log(valuez);
                                          valuez[ChildItem.id] = ChildItem.id;
                                          setValues([...valuez]);
                                        } else {
                                          valuez[ChildItem.id] = undefined;
                                          setValues([...valuez]);
                                        }
                                      }}
                                      value={ChildItem.id}
                                    />
                                  </div>
                                  {ChildItem.remarkRecordDetails &&
                                    ChildItem.remarkRecordDetails.map(
                                      (child: any, mCarIndex: number) => {
                                        return (
                                          <div
                                            key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                                          >
                                            {child.mRemarkRecordDetails &&
                                              child.mRemarkRecordDetails
                                                .length > 0 &&
                                              child.mRemarkRecordDetails.map(
                                                (
                                                  pchildren: any,
                                                  pcIndex: number,
                                                ) => {
                                                  return (
                                                    <div>
                                                      {pchildren
                                                        .mRemarkRecordDetails
                                                        .length > 0 &&
                                                        pchildren.mRemarkRecordDetails.map(
                                                          (
                                                            children: any,
                                                            rIndex: number,
                                                          ) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  styles.footer_c
                                                                }
                                                                key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                                                style={{
                                                                  display:
                                                                    moreMap[
                                                                      `${pcIndex}`
                                                                    ] ||
                                                                    rIndex < 3
                                                                      ? 'block'
                                                                      : 'none',
                                                                }}
                                                              >
                                                                <div
                                                                  className={
                                                                    styles.remark
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_header
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                        style={{
                                                                          position:
                                                                            'relative',
                                                                        }}
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            children.url
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'static',
                                                                          }}
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff_1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            {children.remarkLabelUserName
                                                                              ? children.remarkLabelUserName
                                                                              : '-'}{' '}
                                                                            <span>
                                                                              {parseResultName(
                                                                                `${children.appraiseType}`,
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_time
                                                                            }
                                                                          >
                                                                            {children.createTime
                                                                              ? dateFormat(
                                                                                  children.createTime,
                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                )
                                                                              : '-'}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_foot
                                                                      }
                                                                    >
                                                                      {
                                                                        children.remarkContent
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                  {children.mRemarkRecordDetails &&
                                                                    children
                                                                      .mRemarkRecordDetails
                                                                      .length >
                                                                      0 &&
                                                                    children.mRemarkRecordDetails.map(
                                                                      (
                                                                        remarkChild: any,
                                                                        key: number,
                                                                      ) => {
                                                                        return (
                                                                          <div
                                                                            key={
                                                                              key
                                                                            }
                                                                            style={{
                                                                              background:
                                                                                '#EFF3F9',
                                                                              marginLeft:
                                                                                '50px',
                                                                              marginBottom:
                                                                                '10px',
                                                                              borderRadius:
                                                                                '8px',
                                                                            }}
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_p
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      className={
                                                                                        styles.remark_content_header_staff_0
                                                                                      }
                                                                                      src={
                                                                                        remarkChild.url
                                                                                      }
                                                                                      style={{
                                                                                        position:
                                                                                          'static',
                                                                                      }}
                                                                                      alt=""
                                                                                    />
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header_staff_1
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1_name
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          remarkChild.remarkLabelUserName
                                                                                        }{' '}
                                                                                        <span>
                                                                                          {' '}
                                                                                          {parseResultName(
                                                                                            `${remarkChild.appraiseType}`,
                                                                                          )}{' '}
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1_time
                                                                                        }
                                                                                      >
                                                                                        {remarkChild.createTime
                                                                                          ? dateFormat(
                                                                                              remarkChild.createTime,
                                                                                              'yyyy-MM-dd hh:mm:ss',
                                                                                            )
                                                                                          : '-'}
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_child
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    remarkChild.remarkContent
                                                                                  }
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        );
                                                                      },
                                                                    )}
                                                                </div>
                                                              </div>
                                                            );
                                                          },
                                                        )}
                                                      {pchildren.mRemarkRecordDetails &&
                                                        pchildren
                                                          .mRemarkRecordDetails
                                                          .length > 3 && (
                                                          <div
                                                            style={{
                                                              textAlign:
                                                                'right',
                                                              color: 'blue',
                                                            }}
                                                            onClick={(e) => {
                                                              moreMap[
                                                                `${pcIndex}`
                                                              ] =
                                                                !moreMap[
                                                                  `${pcIndex}`
                                                                ];
                                                              setMoreMap({
                                                                ...moreMap,
                                                              });
                                                              e.stopPropagation();
                                                              e.preventDefault();
                                                            }}
                                                          >
                                                            {moreMap[
                                                              `${pcIndex}`
                                                            ]
                                                              ? '折叠-'
                                                              : '展开+'}
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                },
                                              )}
                                          </div>
                                        );
                                      },
                                    )}
                                </Checkbox.Group>
                              );
                            },
                          )}
                      </div>
                    )}
                  </div>
                );
              }
            })}
          <div>
            <div className={styles.merit_title_1} style={{ fontWeight: '600' }}>
              已完成价值点 <Tag color="rgba(23, 181, 1, 0.3)">已完成</Tag>
            </div>
            {meritListDone && meritListDone.length > 0 ? (
              meritListDone.map((item: any, index: any) => {
                if (
                  item.mCarDealerLabelVos &&
                  item.mCarDealerLabelVos.length > 0
                ) {
                  return (
                    <div>
                      <div className={styles.merit_title}>
                        <div
                          onClick={() => {
                            rowClickDone(index);
                          }}
                        >
                          {item.name}{' '}
                          <span
                            style={{
                              transform: `${
                                !isViewDone[index]
                                  ? 'rotate(-135deg)'
                                  : 'rotate(-45deg)'
                              }`,
                            }}
                          ></span>
                        </div>
                        <Checkbox
                          // defaultChecked={valuez}
                          onChange={(checked) => {
                            let list: any[] = [];
                            if (checked) {
                              item.mCarDealerLabelVos.map((check: any) => {
                                valuez[check.id] = check.id;
                              });
                              valuez[item.id] = item.id;
                              setValues([...valuez]);
                            } else {
                              item.mCarDealerLabelVos.map((check: any) => {
                                valuez[check.id] = undefined;
                              });
                              valuez[item.id] = undefined;
                              setValues([...valuez]);
                            }
                          }}
                          value={item.id}
                        />
                      </div>
                      {isViewDone[index] && (
                        <div className={styles.merit_check}>
                          {item.mCarDealerLabelVos.length > 0 &&
                            item.mCarDealerLabelVos.map(
                              (ChildItem: any, cIndex: number) => {
                                return (
                                  <Checkbox.Group value={valuez}>
                                    <div
                                      className={styles.merit_check_0}
                                      key={`MeritList-${index}-${cIndex}`}
                                    >
                                      <div>{ChildItem.name}</div>
                                      <Checkbox
                                        onChange={(checked) => {
                                          if (checked) {
                                            console.log(valuez);
                                            valuez[ChildItem.id] = ChildItem.id;
                                            setValues([...valuez]);
                                          } else {
                                            valuez[ChildItem.id] = undefined;
                                            setValues([...valuez]);
                                          }
                                        }}
                                        value={ChildItem.id}
                                      />
                                    </div>
                                    {ChildItem.remarkRecordDetails &&
                                      ChildItem.remarkRecordDetails.map(
                                        (child: any, mCarIndex: number) => {
                                          return (
                                            <div
                                              key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                                            >
                                              {child.mRemarkRecordDetails &&
                                                child.mRemarkRecordDetails
                                                  .length > 0 &&
                                                child.mRemarkRecordDetails.map(
                                                  (
                                                    pchildren: any,
                                                    pcIndex: number,
                                                  ) => {
                                                    return (
                                                      <div>
                                                        {pchildren
                                                          .mRemarkRecordDetails
                                                          .length > 0 &&
                                                          pchildren.mRemarkRecordDetails.map(
                                                            (
                                                              children: any,
                                                              rIndex: number,
                                                            ) => {
                                                              return (
                                                                <div
                                                                  className={
                                                                    styles.footer_c
                                                                  }
                                                                  key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                                                  style={{
                                                                    display:
                                                                      moreMap[
                                                                        `${pcIndex}`
                                                                      ] ||
                                                                      rIndex < 3
                                                                        ? 'block'
                                                                        : 'none',
                                                                  }}
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'relative',
                                                                          }}
                                                                        >
                                                                          <img
                                                                            className={
                                                                              styles.remark_content_header_staff_0
                                                                            }
                                                                            src={
                                                                              children.url
                                                                            }
                                                                            style={{
                                                                              position:
                                                                                'static',
                                                                            }}
                                                                            alt=""
                                                                          />
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff_1_name
                                                                              }
                                                                            >
                                                                              {children.remarkLabelUserName
                                                                                ? children.remarkLabelUserName
                                                                                : '-'}{' '}
                                                                              <span>
                                                                                {parseResultName(
                                                                                  `${children.appraiseType}`,
                                                                                )}
                                                                              </span>
                                                                            </div>
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff_1_time
                                                                              }
                                                                            >
                                                                              {children.createTime
                                                                                ? dateFormat(
                                                                                    children.createTime,
                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                  )
                                                                                : '-'}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_foot
                                                                        }
                                                                      >
                                                                        {
                                                                          children.remarkContent
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                    {children.mRemarkRecordDetails &&
                                                                      children
                                                                        .mRemarkRecordDetails
                                                                        .length >
                                                                        0 &&
                                                                      children.mRemarkRecordDetails.map(
                                                                        (
                                                                          remarkChild: any,
                                                                          key: number,
                                                                        ) => {
                                                                          return (
                                                                            <div
                                                                              key={
                                                                                key
                                                                              }
                                                                              style={{
                                                                                background:
                                                                                  '#EFF3F9',
                                                                                marginLeft:
                                                                                  '50px',
                                                                                marginBottom:
                                                                                  '10px',
                                                                                borderRadius:
                                                                                  '8px',
                                                                              }}
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_p
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header_staff
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        className={
                                                                                          styles.remark_content_header_staff_0
                                                                                        }
                                                                                        src={
                                                                                          remarkChild.url
                                                                                        }
                                                                                        style={{
                                                                                          position:
                                                                                            'static',
                                                                                        }}
                                                                                        alt=""
                                                                                      />
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1_name
                                                                                          }
                                                                                        >
                                                                                          {
                                                                                            remarkChild.remarkLabelUserName
                                                                                          }{' '}
                                                                                          <span>
                                                                                            {' '}
                                                                                            {parseResultName(
                                                                                              `${remarkChild.appraiseType}`,
                                                                                            )}{' '}
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1_time
                                                                                          }
                                                                                        >
                                                                                          {remarkChild.createTime
                                                                                            ? dateFormat(
                                                                                                remarkChild.createTime,
                                                                                                'yyyy-MM-dd hh:mm:ss',
                                                                                              )
                                                                                            : '-'}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_child
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      remarkChild.remarkContent
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          );
                                                                        },
                                                                      )}
                                                                  </div>
                                                                </div>
                                                              );
                                                            },
                                                          )}
                                                        {pchildren.mRemarkRecordDetails &&
                                                          pchildren
                                                            .mRemarkRecordDetails
                                                            .length > 3 && (
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  'right',
                                                                color: 'blue',
                                                              }}
                                                              onClick={(e) => {
                                                                moreMap[
                                                                  `${pcIndex}`
                                                                ] =
                                                                  !moreMap[
                                                                    `${pcIndex}`
                                                                  ];
                                                                setMoreMap({
                                                                  ...moreMap,
                                                                });
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                              }}
                                                            >
                                                              {moreMap[
                                                                `${pcIndex}`
                                                              ]
                                                                ? '折叠-'
                                                                : '展开+'}
                                                            </div>
                                                          )}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                            </div>
                                          );
                                        },
                                      )}
                                  </Checkbox.Group>
                                );
                              },
                            )}
                        </div>
                      )}
                    </div>
                  );
                }
              })
            ) : (
              <Empty description="暂无已完成价值点" />
            )}
          </div>
        </div>
        <div className={styles.footer_0}>
          {(dataFlag.status === 3 || dataFlag.status === 6) && (
            <div
              className={styles.footer_button}
              onClick={() => {
                // history.push("./programme")
                const data = dataFlags;
                // data.id = dataFlags.id
                data.companyId = dataFlags.companyId;
                data.companyName = dataFlags.companyName;
                console.log(data);
                history.push('./programme', data);
              }}
            >
              新建计划
            </div>
          )}
          {dataFlag &&
            localStorage.getItem('isLeaderInDept') === '1' &&
            (dataFlag.status === 0 || dataFlag.status === 1) && (
              <div
                className={styles.footer_button}
                onClick={() => {
                  Modal.confirm({
                    title: '驳回原因',
                    content: (
                      <div className={styles.forms}>
                        <Form form={form}>
                          <Form.Item
                            name="forms"
                            rules={[
                              {
                                required: true,
                                message: '请输入驳回原因',
                              },
                            ]}
                            className={styles.form_item}
                          >
                            <TextArea
                              placeholder="请输入驳回原因"
                              maxLength={100}
                              rows={5}
                              showCount
                              onChange={(v) => {
                                console.log(v);
                              }}
                            />
                          </Form.Item>
                        </Form>
                      </div>
                    ),
                    onConfirm: async () => {
                      const values = form.getFieldsValue();
                      if (!values.forms) {
                        await sleep(1000);
                        Toast.show({
                          icon: 'fail',
                          content: '填写驳回原因',
                        });
                        throw new Error();
                      } else {
                        onDelete(dataFlag.id);
                      }
                    },
                  });
                }}
              >
                驳回
              </div>
            )}
          {dataFlag.userId === localStorage.getItem('userId') && (
            <div
              className={styles.footer_button}
              onClick={() => {
                const data = dataFlags;
                data.id = dataFlags.id;
                data.companyId = dataFlags.companyId;
                data.companyName = dataFlags.companyName;
                data.contactMobileNo = dataFlags.contactMobileNo;
                data.contactName = dataFlags.contactName;
                //
                console.log(data);
                history.replace('./see', data);
              }}
            >
              拜访
            </div>
          )}
          {dataFlag.status != 3 && dataFlag.status != 6 && (
            <div
              className={styles.footer_button}
              onClick={() => {
                Finish();
              }}
            >
              保存
            </div>
          )}
        </div>
      </Card>
      <Popup
        visible={visible4}
        showCloseButton
        onClose={() => {
          setVisible4(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
        }}
      >
        <Form form={remarkform}>
          <List>
            <List.Item prefix="是否已完成">
              <Form.Item
                name="finishType"
                // rules={[{ required: true, message: '选择是否硬广覆盖' }]}
                className={styles.form_item}
              >
                <Radio.Group>
                  <Radio value={'1'}>已完成</Radio>
                  <Radio value={'2'}>未完成</Radio>
                  <Radio value={'3'}>未选择</Radio>
                </Radio.Group>
              </Form.Item>
            </List.Item>
            <List.Item prefix="评分">
              <Form.Item
                name="appraiseType"
                // rules={[{ required: true, message: '选择是否硬广覆盖' }]}
                className={styles.form_item}
              >
                <Radio.Group>
                  {/* <Radio value={'1'}>极好</Radio> */}
                  <Radio value={'2'}>好</Radio>
                  <Radio value={'3'}>中</Radio>
                  <Radio value={'4'}>差</Radio>
                </Radio.Group>
              </Form.Item>
            </List.Item>
            <TextArea
              placeholder="请输入备注"
              maxLength={100}
              rows={5}
              showCount
              value={remarkValue}
              onChange={(v) => {
                setRemarkValue(v);
              }}
            />
          </List>
        </Form>
        <Button
          block
          type="submit"
          color="primary"
          onClick={() => {
            onRemarkFinish();
          }}
        >
          提交
        </Button>
      </Popup>
    </div>
  );
}
