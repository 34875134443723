
// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function save(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/info/save', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
