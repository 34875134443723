// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
import { sleep } from 'antd-mobile/es/utils/sleep'
import { Toast } from 'antd-mobile';


export async function ListMore(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/car/info/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getUserInfo(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/user/getUserInfo', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function distribution(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/info/distribution', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function allotUser(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/commenterAndTeacher/relation/allotUser', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function fatherSonCompany(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/father/son/company/fatherSonCompany', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function allotInspector(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/inspector/relation/allotInspector', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function extension(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/coupon/extension', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function usersync(data:any, options?: { [key: string]: any }) {
  const username = 'admin';
  const password = 'wzyc2015';
  const basicAuth = Buffer.from(`${username}:${password}`).toString('base64');
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/usersync/user/sync', {
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${basicAuth}`,
    },
    ...(options || {}),
  });
}
export async function transferStart(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>(`/api/coupon/transfer/start/${data.phone}/${data.stockPhone}`, {
    method: 'get',
    ...(options || {}),
  });
}
export async function Lists(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  for (const key in params) {
    console.log(params[key]);
    
    if(params[key] === []){
      delete params[key]
    }
  }
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/company/car/info/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if(msg.retCode === 0) {
    return {
      success: true,
      data: msg.data.datas,
      total: msg.data.totalCount,
    };
  } else {
    Toast.show({
      icon:"fail",
      content: msg.retMsg
    })
    return []
  }
}
export async function ListNew(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  for (const key in data) {
    if(data[key] && (data[key] === [] || data[key].length === 0)){
      delete params[key]
    }
  }
  if(data.score){
    delete params.score
  }
  if(data.companyTypes && data.companyTypes.indexOf(2) > -1 && data.companyTypes.indexOf(0) === -1){
    let list = data.companyTypes;
    list.push(0)
    params.companyTypes = list
  }
  if(data.companyTypes && data.companyTypes.indexOf(2) === -1){
    let list: any[] = []
    data.companyTypes.map((item:any)=>{
      if(item && item != 0){
        list.push(item)
      }
    })
    params.companyTypes = list
  }
  if(params.companyTypes && params.companyTypes.length === 0){
    delete params.companyTypes
  }
  if(data.stock){
    params.stock = data.stock[0]
  }
  if(data.visitCountEnd >1000){
    delete params.visitCountEnd
  }
  if(data.stock && data.stock === 2){
    delete params.stock
  }
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/company/car/info/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if(msg.retCode === 0) {
    return msg.data
  } else {
    Toast.show({
      icon:"fail",
      content: msg.retMsg
    })
    return []
  }
}
export async function ListFlag(
  data: any,
  options?: { [key: string]: any },
) {
  let count = 1
  const params = data;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/company/visit/sort/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if(msg.retCode === 0) {
    return {
      success: true,
      data: msg.data.datas,
      total: msg.data.totalCount,
    };
  } else {
    Toast.show({
      icon:"fail",
      content: msg.retMsg
    })
    return []
  }
}
export async function userList(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/user/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0) {
    return msg.data
  } else {
    return {totalPage:0}
  }
}