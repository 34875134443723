import { Button, Card, Form, Input, List } from "antd-mobile";
import { useEffect, useState } from "react";
import Navbar from "../utils/Navbar";
import styles from './index.less';
import { history,useLocation } from "umi";
import 新增 from './img/新增.png';
import { carBrand } from "./service";
export default function Bookings() {
  const [companyId, setCompanyId] = useState<any>([])
  const [companyPhone, setCompanyPhone] = useState<any>([])
  const location = useLocation();
  const dataFlag:any = location.state;
  const [form] = Form.useForm();
  const carModel =  async() => {
    const msg = await carBrand({})
  }
  useEffect(()=>{
    console.log(dataFlag);
    carModel()
    if(dataFlag){
      form?.setFieldsValue(dataFlag);
      setCompanyId(dataFlag.companyId)
      setCompanyPhone(dataFlag.companyPhone)
    }
  },[])
  return (
    <div className={styles.bookings}>
      <Navbar name="预约验车"></Navbar>
      <Card>
        <Form
          // onFinish={onFinish}
          form={form}
        >
            <List>
              <List.Item prefix="选择车商" onClick={()=>{
                const values = form.getFieldsValue();
                values.bookings = true
                history.replace("./merchant",values)
              }}
              title={"*"}
              >
                <Form.Item
                  name="companyName"
                  rules={[{ required: true, message: '选择车商' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择车商" readOnly/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="联系人"
              title={"*"}
              >
                <Form.Item
                  name="contactName"
                  rules={[{ required: true, message: '请输入联系人' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="请输入联系人"/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="联系电话"
              title={"*"}
              >
                <Form.Item
                  name="contactMobileNo"
                  rules={[{ required: true, message: '请输入联系电话' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="请输入联系电话"/>
                </Form.Item>
              </List.Item>
            </List>
        </Form>
      </Card>
      <Card>
        <div style={{height:"5px"}}></div>
        <div className={styles.book} onClick={()=>{
          if(companyPhone){
            history.replace("./products",companyPhone)
          }
        }}>
          <img src={新增} alt="" />
          <span>选择验车产品</span>
        </div>
        <Form
          // onFinish={onFinish}
          form={form}
          footer={
            <Button block className={styles.submit} type="submit" color="primary">
              确定预约
            </Button>
          }
        >
            <List>
              <List.Item prefix="品牌车型" onClick={()=>{
                // const values = form.getFieldsValue();
                // values.bookings = true
                // history.replace("./merchant",values)
              }}
              title={"*"}
              >
                <Form.Item
                  name="carModel"
                  rules={[{ required: true, message: '选择品牌车型' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择品牌车型" readOnly/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="车架号"
              title={"*"}
              >
                <Form.Item
                  name="vin"
                  rules={[{ required: true, message: '请输入车架号' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="请输入车架号"/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="验车站点" onClick={()=>{
                const values = form.getFieldsValue();
                values.bookings = true
                history.replace("./merchant",values)
              }}
              title={"*"}
              >
                <Form.Item
                  name="companyName"
                  rules={[{ required: true, message: '选择验车站点' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择验车站点" readOnly/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="验车时间" onClick={()=>{
                const values = form.getFieldsValue();
                values.bookings = true
                history.replace("./merchant",values)
              }}
              title={"*"}
              >
                <Form.Item
                  name="orderTime"
                  rules={[{ required: true, message: '选择检测时间' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测时间" readOnly/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="验车时段" onClick={()=>{
                const values = form.getFieldsValue();
                values.bookings = true
                history.replace("./merchant",values)
              }}
              title={"*"}
              >
                <Form.Item
                  name="bindTimeId"
                  rules={[{ required: true, message: '选择验车时间段' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择验车时间段" readOnly/>
                </Form.Item>
              </List.Item>
            </List>
        </Form>
      </Card>
    </div>
  )
}