// @ts-ignore
/* eslint-disable */
import { request } from '@@/plugin-request/request';
import { history } from 'umi';
import { getWxAccessToken } from '../login/service';
import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Toast } from 'antd-mobile';
import dayjs from 'dayjs';
// const wx = require('wx')
export async function filterReportImage(report: any) {
  const imgList: any = [];
  let index: number = 0;
  Object.keys(report).forEach((item: any) => {
    report[item].forEach((childItem: any) => {
      if (childItem.img !== '') {
        const childItemImgList: any[] = [];
        childItem.img.split(',').forEach((img: any) => {
          childItemImgList.push({
            imgUrl: img,
            index: index++,
          });
        });
        childItem.imgList = childItemImgList;
        imgList.push(...childItemImgList);
      } else {
        childItem.imgList = [];
      }
    });
  });
  return {
    report,
    imgList,
  };
}

export function uuid(len: number, radix: number) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid: any[] = [];
  let i = 0;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
}

export function isImage(img: string) {
  const suffix = [
    'bmp',
    'jpg',
    'jpeg',
    'png',
    'tif',
    'gif',
    'pcx',
    'tga',
    'exif',
    'fpx',
    'svg',
    'psd',
    'cdr',
    'pcd',
    'dxf',
    'ufo',
    'eps',
    'ai',
    'raw',
    'WMF',
    'webp',
    'avif',
    'apng',
  ];
  const url = img.split('.');
  const index = suffix.findIndex((item: string) => {
    return item === url[4];
  });

  if (index === -1) {
    return false;
  } else {
    return true;
  }
}

export function isJson(str: string) {
  if (typeof str === 'string') {
    try {
      const obj = eval(`(${str})`);
      if (typeof obj === 'object' && obj) {
        return obj;
      } else {
        return str;
      }
    } catch (e) {
      return str;
    }
  } else {
    return str;
  }
}

export function arrFilter(data: any) {
  let result = data.filter((x: any, index: any, self: any) => {
    let arrs: any[] = [];
    data.forEach((item: any, i: number) => {
      arrs.push(item);
    });
    return arrs.indexOf(x) === index;
  });
  return result;
}

export function timeFn(start: any, end: any) {
  let startTime = new Date(start); //将-转化为/，使用new Date
  let endTime = new Date(end); //将-转化为/，使用new Date
  let dateDiff = endTime.getTime() - startTime.getTime(); //时间差的毫秒数
  let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
  let leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
  //计算相差分钟数
  let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
  //计算相差秒数
  let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000);
  return `${dayDiff}天${hours}时${minutes}分${seconds}秒`;
}
/**
*日期格式化
*/
const dateFormat = (date:any, fmt:any) => {
  date = new Date(date)
  var a = ['日', '一', '二', '三', '四', '五', '六']
  var o = {
    'Y': dayjs(date).year(), // 月份
    'M+': dayjs(date).month() + 1, // 月份
    'd+': dayjs(date).date(), // 日
    'h+': dayjs(date).hour(), // 小时
    'm+': dayjs(date).minute(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds(), // 毫秒
    'w': date.getDay(), // 周
    'W': a[date.getDay()], // 大写周
    'T': 'T'
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    // @ts-ignore
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}
export {dateFormat}


/***
 * 获取用户列配置
 * @param data
 * @param options
 */
export async function getUserTableColumn(tableId: string, options?: { [key: string]: any }) {
  console.log('=== tableId ', tableId);
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/user/getUserTableColumn', {
    method: 'POST',
    data: { tableId: tableId },
    ...(options || {}),
  });
}
export async function getUserTableColumnReportForm(tableId: string, options?: { [key: string]: any }) {
  console.log('=== tableId ', tableId);
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/user/getUserTableColumn', {
    method: 'POST',
    data: { tableId: tableId, userId: -1000 },
    ...(options || {}),
  });
}
export const getWidth = () => {
  return { width: window.innerWidth };
};

/***
 * 存储用户列配置
 * @param tableId
 * @param options?
 */
export async function saveUserTableColumn(
  tableId: string,
  data: string,
  options?: { [key: string]: any },
) {
  console.log('tableid:', tableId);
  console.log('data:', data);

  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/user/saveUserTableColumn', {
    method: 'POST',
    data: { tableId: tableId, data: data },
    ...(options || {}),
  });
}
export async function saveUserTableColumnReportForm(
  tableId: string,
  data: string,
  options?: { [key: string]: any },
) {
  console.log('tableid:', tableId);
  console.log('data:', data);

  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/user/saveUserTableColumn', {
    method: 'POST',
    data: { tableId: tableId, data: data, userId: -1000 },
    ...(options || {}),
  });
}

/***
 * 重置用户列配置
 * @param tableId
 * @param options?
 */
export async function rmUserTableColumn(tableId: string, options?: { [key: string]: any }) {
  console.log('tableid:', tableId);

  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/user/rmUserTableClumn', {
    method: 'POST',
    data: { tableId: tableId },
    ...(options || {}),
  });
}
export async function rmUserTableColumnReportForm(tableId: string, options?: { [key: string]: any }) {
  console.log('tableid:', tableId);

  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/user/rmUserTableClumn', {
    method: 'POST',
    data: { tableId: tableId , userId: -1000 },
    ...(options || {}),
  });
}

export async function wxShareConfig() {
    let list: any[] = []
    const msg = await getWxAccessToken({
      url: window.location.href,
    })
    console.log(wx);
    if(msg.retCode === 0){
      await wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ["onMenuShareTimeline"] 
      });
      await wx.agentConfig({
        corpid: "ww4168efadaace928a",
        agentid: "1000013",
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ["selectEnterpriseContact"],
        success: function(res) {
          // 回调
        },
        fail: function(res) {
          if(res.errMsg.indexOf('function not exist') > -1){
              alert('版本过低请升级')
          }
        }
      })
    
    }
    // WWOpenData.bind(document.querySelector('ww-open-data'))
    // WWOpenData.on(event: string, callback: Function)
    // WWOpenData.on("", )
    // 
    // @ts-ignore
    wx.invoke("selectEnterpriseContact", {
      "fromDepartmentId": 0,// 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
      "mode": "single",// 必填，选择模式，single表示单选，multi表示多选
      "type": ["department", "user"],// 必填，选择限制类型，指定department、user中的一个或者多个
      },
      function(res){
        console.log(res);
        if (res.err_msg == "selectEnterpriseContact:ok"){
          if(typeof res.result == 'string'){
            res.result = JSON.parse(res.result) //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          var selectedDepartmentList = res.result.departmentList;// 已选的部门列表
          for (var i = 0; i < selectedDepartmentList.length; i++){
            var department = selectedDepartmentList[i];
            var departmentId = department.id;// 已选的单个部门ID
            var departemntName = department.name;// 已选的单个部门名称
            Toast.show({
              icon: 'success',
              content: departmentId,
            })
          }
          var selectedUserList = res.result.userList; // 已选的成员列表
          for (var i = 0; i < selectedUserList.length; i++){
            var user = selectedUserList[i];
            var userId = user.id; // 已选的单个成员ID
            var userName = user.name;// 已选的单个成员名称
            var userAvatar= user.avatar;// 已选的单个成员头像
            // setCurrent(userName)
            list.push(userId)
          }
        }
      }
    );
  return list
}


