export const serveTypeList = [
  [
    { label: '上门拜访', value: 1 },
    { label: '企微联系', value: 2 },
    { label: '电话联系', value: 3 },
    { label: '线上会议', value: 4 },
    { label: '线上直播', value: 5 },
    { label: '其他（默认上门拜访）', value: 6 },
  ],
];
export const visitSignTypeList = [
  [
    { label: '注册拜访', value: 1 },
    { label: '新购拜访', value: 2 },
    { label: '复购拜访', value: 3 },
    { label: '形象店', value: 4 },
    { label: '陌拜', value: 5 },
    { label: '复购拜访剩余0单', value: 6 },
    { label: '复购拜访剩余0-3单', value: 7 },
    { label: '复购拜访剩余3-5单', value: 8 },
    { label: '复购拜访剩余5-10单', value: 9 },
    { label: '复购拜访其他', value: 10 },
    { label: '陪访', value: 11 },
  ],
];

export const visitSignName = (appraiseType: number) => {
  let result;
  if (appraiseType === 1) {
    result = '注册拜访';
  }
  if (appraiseType === 2) {
    result = '新购拜访';
  }
  if (appraiseType === 3) {
    result = '复购拜访';
  }
  if (appraiseType === 4) {
    result = '形象店';
  }
  if (appraiseType === 5) {
    result = '陌拜';
  }
  if (appraiseType === 6) {
    result = '复购拜访剩余0单';
  }
  if (appraiseType === 7) {
    result = '复购拜访剩余0-3单';
  }
  if (appraiseType === 8) {
    result = '复购拜访剩余3-5单';
  }
  if (appraiseType === 9) {
    result = '复购拜访剩余5-10单';
  }
  if (appraiseType === 10) {
    result = '复购拜访其他';
  }
  if (appraiseType === 11) {
    result = '陪访';
  }
  return result;
};
export const visitResultList = [
  [
    { label: '失败', value: 1 },
    { label: '成功', value: 2 },
  ],
];
export const strangerVisitResultList = [
  [
    { label: '待再拜访', value: 1 },
    { label: '注册上线', value: 2 },
  ],
];
export const newVisitResultList = [
  [
    { label: '待再拜访', value: 1 },
    { label: '拜访成交', value: 2 },
  ],
];
export const visitResultLists = [
  [
    { label: '失败', value: false },
    { label: '成功', value: true },
  ],
];
export const isOrIsnt = [
  [
    { label: '是', value: true },
    { label: '否', value: false },
  ],
];
export const detectionRatio = [
  [
    { label: '1以下', value: "0-1" },
    { label: '1~2', value: "1-2" },
    { label: '2~3', value: "2-3" },
    { label: '3以上', value: "3-200" },
  ],
];
export const displayTypeList = [
  [
    { label: '独立展厅', value: 1 },
    { label: '露天市场', value: 2 },
    { label: '大棚市场', value: 3 },
    { label: '室内市场', value: 4 },
    { label: '未知', value: 0 },
  ],
];
export const degreeIntentList = [
  [
    // {label: "3个月内", value: 0 },
    { label: '30天以上', value: 1 },
    { label: '14天-30天', value: 2 },
    { label: '7-14天', value: 3 },
    { label: '3天-7天', value: 6 },
    { label: '3天内', value: 4 },
    { label: '1天内', value: 5 },
  ],
];
export const customerIntentionList = [
  [
    // {label: "3个月内", value: 0 },
    { label: '快速跟进商户(3-7天内)', value: 1 },
    { label: '潜在意向商户（1个月内）', value: 2 },
    { label: '高意向商户（2周内）', value: 3 },
    { label: '长期跟进的商户（3个月内）', value: 4 },
  ],
];
export const merchantType = [
  [
    // {label: "3个月内", value: 0 },
    { label: '合作商户复购重点', value: "repurchaseFocusFlag" },
    { label: '注册商户新购重点', value: "newPurchasesFocusFlag" },
    { label: '待激活复购', value: "activationRepurchaseFlag" },
    { label: '合作商户车源拜访重点', value: "vehicleSourceVisitsFlag" },
  ],
];
export const valuePointsToBeAddressed = [
  [
    // {label: "3个月内", value: 0 },
    { label: '8个以下', value: "0-8" },
    { label: '8-16个', value: "8-16" },
    { label: '16-24个', value: "16-24" },
    { label: '24-32个', value: "24-32" },
    { label: '32个以上', value: "32-200" },
  ],
];
export const detectingPermeability = [
  [
    // {label: "3个月内", value: 0 },
    { label: '低于10%', value: "0-0.1" },
    { label: '10%-20%', value: "0.1-0.2" },
    { label: '20%-30%', value: "0.2-0.3" },
    { label: '30%-50%', value: "0.3-0.5" },
    { label: '50%-100%', value: "0.5-1" },
    { label: '100%以上', value: "1-200" },
  ],
];
export const mediaLists = [
  [
    { label: '抖音', value: 1 },
    { label: '快手', value: 2 },
    { label: '微信视频号', value: 3 },
  ],
];
export const testJianCe = [
  [
    { label: '查博士', value: 1 },
    { label: '268V', value: 2 },
    { label: '维真验车', value: 3 },
  ],
];
export const reasonsForFailureAll = [
  [
    { label: '备注原因：个人未校准价值点', value: 1 },
    { label: '批注原因:  个人价值点未培训', value: 2 },
  ],
];
export const reasonsForFailureNew = [
  [
    { label: '备注原因', value: 1 },
    { label: '批注原因', value: 2 },
    { label: '未准确说明价值点', value: 3 },
    { label: '个人能力原因未让商户明白价值点', value: 4 },
  ],
];
export const reasonsForFailure = [
  [
    { label: '剩余库存较多，使用差不多再买', value: 1 },
    { label: '不会解读报告，不会应用报告卖车', value: 2 },
    { label: '检测标准认知不清楚', value: 3 },
    { label: '去买了竞队信息的产品', value: 4 },
  ],
];
export const judgment = [
  [
    { label: '全部', value: 4 },
    { label: '事故车', value: 0 },
    { label: '泡水车', value: 1 },
    { label: '火烧车', value: 2 },
    { label: '检测合格', value: 3 },
  ],
];
export const BrandTypeList = [
  [
    { label: '美系', value: 0 },
    { label: '德欧系', value: 1 },
    { label: '日韩系', value: 2 },
    { label: '国产', value: 3 },
  ],
];
export const vehicleTypeList = [
  [
    { label: '跑车', value: 0 },
    { label: '轿跑', value: 1 },
    { label: '轿车', value: 2 },
    { label: 'SUV', value: 3 },
    { label: 'MPV', value: 4 },
    { label: '房车', value: 5 },
    { label: '旅行车', value: 6 },
    { label: '代步车', value: 7 },
  ],
];
export const priceRangeList= [
  [
    { label: '5万以下', value: 0 },
    { label: '5-10万', value: 1 },
    { label: '10-15万', value: 2 },
    { label: '15-20万', value: 3 },
    { label: '20万-30万', value: 4 },
    { label: '30-40万', value: 5 },
    { label: '40-50万', value: 6 },
    { label: '50-70万', value: 7 },
    { label: '70-150万', value: 8 },
    { label: '150-250万', value: 9 },
    { label: '250万以上', value: 10 },
  ],
];
export const partnershipList = [
  [
    { label: '家庭带小孩', value: 5 },
    { label: '夫妻不带小孩', value: 1 },
    { label: '兄弟姐妹', value: 2 },
    { label: '事业合伙', value: 3 },
    { label: '其他', value: 4 },
  ],
];
export const customerList = [
  [
    { label: '汽车之家', value: 0 },
    { label: '懂车帝', value: 1 },
    { label: '58同城', value: 2 },
    { label: '易车', value: 3 },
    { label: '抖音', value: 4 },
    { label: '快手', value: 5 },
    { label: '瓜子', value: 6 },
    { label: '阿里拍卖', value: 7 },
    { label: '闲鱼', value: 8 },
    { label: '同行介绍', value: 9 },
    { label: '客户介绍', value: 10 },
  ],
];
export const recommendedReasonsList = [
  [
    { label: '收车（买车）', value: 0 },
    { label: '批发（卖车）', value: 1 },
    { label: '合车（一起卖）', value: 2 },
    { label: '操作（我帮你卖）', value: 3 },
    { label: '交易服务（金融、保险、过户）', value: 4 },
    { label: '车后服务（维修、保养、整备）', value: 5 },
  ],
];
export const cooperationFrequencyList = [
  [
    { label: '一月多次', value: 0 },
    { label: '一年多次', value: 1 },
    { label: '天天合作', value: 2 },
  ],
];
export const detectionMethodList = [
  [
    { label: '车商展厅检测', value: 0 },
    { label: '检测门店检测', value: 1 },
    { label: '租借举升设备检测', value: 2 },
  ],
];
export const ValuePointScreening =  [
  { label: '专业人设', value:1 ,key:"specializedFlag"},
  { label: '检测人设', value: 2,key:"detectFlag" },
  { label: '数据人设', value: 3,key:"dataPersonaFlag" },
  { label: '报价成交', value: 4,key:"quoteFlag" },
  { label: '预约实检', value: 5,key:"bookingFlag" },
];
export const MerchantsToBeActivated =  [
  { label: '是', value: 1 },
  { label: '否', value: 2 },
];
export const Annotationscore =  [
  { text: '好以上（含好）', key:1 },
  { text: '好以下', key: 2 },
];
export const Annotationscoring =  [
  { label: '好以上（含好）', value:true },
  { label: '好以下', value: false },
];
// 当月检测渗透率
export const Detectionforthemonth =  [
  { label: '低于10%（B1或B2）', value: "0_0.1" },
  { label: '10%-20%（B1）', value: "0.1_0.2" },
  { label: '20%-30%（B1）', value: "0.2_0.3" },
  { label: '30%-50%（B1）', value: "0.3_0.5" },
  { label: '50%-100%（A2）', value: "0.5_1" },
  { label: '100%以上（A1）', value: "1" },
];
// 上月检测渗透率
export const Detectionlastmonth =  [
  { label: '低于10%（B1或B2）', value: "0_0.1" },
  { label: '10%-20%（B1）', value: "0.1_0.2" },
  { label: '20%-30%（B1）', value: "0.2_0.3" },
  { label: '30%-50%（B1）', value: "0.3_0.5" },
  { label: '50%-100%（A2）', value: "0.5_1" },
  { label: '100%以上（A1）', value: "1" },
];
export const ExhibitionHallType =  [
  { label: '独立展厅（1公里内）', value:0 },
  { label: '独立展厅（1公里外）', value: 1 },
  { label: '露天市场', value: 2 },
  { label: '室内市场', value: 3 },
  { label: '大棚市场', value: 4 },
  { label: '未知', value: 5 },
];
export const WhethertiedtoSalesOrTesting =  [
  { label: '已绑定销售', value:0 },
  { label: '未绑定销售', value: 1 },
  { label: '已绑定检测', value: 2 },
  { label: '未绑定检测', value: 3 },
];
export const MerchantType =  [
  { label: '合作复购', value:0 },
  { label: '合作新购', value: 1 },
  { label: '已认证待合作', value: 2 },
  // { label: '已注册待认证', value: 3 },
];
export const intentionality = [
  { label: '30天以上', value: 1 },
  { label: '14天-30天', value: 2 },
  { label: '7-14天', value: 3 },
  { label: '3天-7天', value: 6 },
  { label: '3天内', value: 4 },
  { label: '1天内', value: 5 },
];
export const imageStoreList =  [
  { label: '门头形象店', value:0 },
  { label: '非门头形象店', value: 1 },
  { label: '非形象店', value: 2 },
];