// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from 'C:/Users/Admin/Desktop/calling/wz-call-on/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/pages/home').default,
    "exact": true
  },
  {
    "path": "/home",
    "component": require('@/pages/home').default,
    "exact": true
  },
  {
    "path": "/PayAVisit",
    "component": require('@/pages/PayAVisit/newsPlan').default,
    "exact": true
  },
  {
    "path": "/merchant",
    "component": require('@/pages/merchant').default,
    "exact": true
  },
  {
    "path": "/businesses",
    "component": require('@/pages/businesses').default,
    "exact": true
  },
  {
    "path": "/details",
    "component": require('@/pages/details').default,
    "exact": true
  },
  {
    "path": "/detailEdit",
    "component": require('@/pages/detailEdit').default,
    "exact": true
  },
  {
    "path": "/see",
    "component": require('@/pages/see').default,
    "exact": true
  },
  {
    "path": "/signIn",
    "component": require('@/pages/signIn').default,
    "exact": true
  },
  {
    "path": "/programme",
    "component": require('@/pages/programme').default,
    "exact": true
  },
  {
    "path": "/market",
    "component": require('@/pages/market').default,
    "exact": true
  },
  {
    "path": "/report",
    "component": require('@/pages/report').default,
    "exact": true
  },
  {
    "path": "/offerings",
    "component": require('@/pages/offerings').default,
    "exact": true
  },
  {
    "path": "/bookings",
    "component": require('@/pages/bookings').default,
    "exact": true
  },
  {
    "path": "/products",
    "component": require('@/pages/products').default,
    "exact": true
  },
  {
    "path": "/visitDetail",
    "component": require('@/pages/visitDetail').default,
    "exact": true
  },
  {
    "path": "/awaitingDetail",
    "component": require('@/pages/awaitingDetail').default,
    "exact": true
  },
  {
    "path": "/checkMerchant",
    "component": require('@/pages/checkMerchant').default,
    "exact": true
  },
  {
    "path": "/callOnSb",
    "component": require('@/pages/callOnSb').default,
    "exact": true
  },
  {
    "path": "/checkIn",
    "component": require('@/pages/checkIn').default,
    "exact": true
  },
  {
    "path": "/dataCentres",
    "component": require('@/pages/dataCentres').default,
    "exact": true
  },
  {
    "path": "/collect",
    "component": require('@/pages/collect').default,
    "exact": true
  },
  {
    "path": "/collect/:key",
    "component": require('@/pages/collect').default,
    "exact": true
  },
  {
    "path": "/collection-details",
    "component": require('@/pages/collection-details').default,
    "exact": true
  },
  {
    "path": "/remarkDetail/:rowId",
    "component": require('@/pages/remarkDetail').default,
    "exact": true
  },
  {
    "path": "/details/:rowId",
    "component": require('@/pages/details').default,
    "exact": true
  },
  {
    "component": require('@/pages/404').default,
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
