import {
  Button,
  Card,
  Checkbox,
  CheckList,
  DatePicker,
  Empty,
  Form,
  Input,
  List,
  NavBar,
  Picker,
  Popup,
  Radio,
  Switch,
  Tag,
  TextArea,
  Toast,
  TreeSelect,
  Image,
  ImageViewer
} from 'antd-mobile/es';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import { history, useLocation } from 'umi';
import {
  getNewRepTotal,
  labellist,
  Lists,
  remarkLists,
  save,
  saves,
  typelist,
  visitSave,
} from './service';
import { getWxAccessToken } from '../login/service';
import { getUserInfo } from '../merchant/service';
import 新增 from '../merchant/img/新增.png';
import { getMCarDealerVisitVo } from '../details/service';
import Login from '../login';
import { getSummaryNumber } from '../home/service';
import Merchant from '../comments/Merchant';
import data from '../utils/省市区线数据.json';
import { customerIntentionList, degreeIntentList } from '../utils/signIn';
import Market from '../comments/Market';
import { parseResultName } from '../PayAVisit/newsPlan';
import { dateFormat } from '../utils/util';
import CollectCkecked from '../comments/CollectCkecked';
import MyPie from './common/MyPie';
import { AddOutline } from 'antd-mobile-icons';
import { getQyWxImg } from '../checkIn/service';

export default function Programme() {
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const location = useLocation();
  const [timeVisible, setTimeVisible] = useState<boolean>(false);
  const [MarketVisible, setMarketVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>([]);
  const [meritList, setMeritList] = useState<any>([]);
  const [meritListDone, setMeritListDone] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [statusVisible, setStatusVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isView, setIsView] = useState<any[]>([]);
  const [isViewDone, setIsViewDone] = useState<any[]>([]);
  const [value, setValue] = useState<any>([]);
  const [valuez, setValues] = useState<any>([]);
  const [userId, setUserId] = useState<any>([]);
  const [status, setStatus] = useState<number | string | null>(-1);
  const [belongMarketId, setbelongMarketId] = useState<number | string | null>(
    -1,
  );
  const [typeList, setTypeList] = useState<any>([]);
  const [checkMerchant, setcheckMerchant] = useState<any>([]);
  const [checkMerchantList, setCheckMerchantList] = useState<any>([]);
  const [recommendationsList, setrecommendationsList] = useState<any>([]);
  const [visitContent, setvisitContent] = useState<string>('');
  const [belongMarketName, setbelongMarketName] = useState<string>('');
  const [companyId, setCompanyId] = useState<number>(-1);
  const [num, setNum] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [nameVisible, setNameVisible] = useState<boolean>(false);
  const [visible2, setVisible2] = useState<boolean>(false);
  const [recommendationsVisible, setRecommendationsVisible] =
    useState<boolean>(false);
  const [checkFlag, setCheckFlag] = useState<boolean>(false);
  const [options, setoptions] = useState<any>([]);
  const [newRepTotal, setNewRepTotal] = useState<any>([]);
  const [mediaImage, setMediaImage] = useState<any>([]);
  const [cityCode, setCityCode] = useState<any>([]);
  const [allImage, setAllImage] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [collectVisible, setCollectVisible] = useState<boolean>(false);
  const [focusCompanyVisitFlag, setfocusCompanyVisitFlag] = useState<boolean>(false);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [degreeIntentVisible, setdegreeIntentVisible] =
    useState<boolean>(false);
  const [customerIntentionVisible, setcustomerIntentionVisible] =
    useState<boolean>(false);
  const [degreeIntent, setdegreeIntent] = useState<number>();
  const [moreMap, setMoreMap] = useState<any>({});
  const [contactCityCode, setContactCityCode] = useState<any>(0);
  const [contactCityName, setContactCityName] = useState<string>('');
  const [scoreList, setScoreList] = useState<any>([]);
  const [checkCollect, setCheckCollect] = useState<any>([]);
  const [visitGoodsVos, setVisitGoodsVos] = useState<any>([]);
  const infoRef: any = useRef([]);
  const dataFlag: any = location.state;
  const dateJs = () => {
    const year = dayjs().year();
    const month = dayjs().month() + 1;
    const date = dayjs().date() + 1;
    return new Date(`${year}-${month}-${date} 00:00:00`);
  };
  const offeringsList = ['基础检测', '升级检测', '豪车检测', '数据车况'];
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年';
      case 'month':
        return data + '月';
      case 'day':
        return data + '日';
      case 'hour':
        return data + '时';
      case 'minute':
        return data + '分';
      case 'second':
        return data + '秒';
      default:
        return data;
    }
  }, []);
  const onchanges = (v: any) => {
    setCheckCollect(v);
  };
  const pullListMore = async () => {
    // const msg = await ListMore({})
    // setCurrent(msg.data.datas)
    // @ts-ignore
    let list = JSON.parse(localStorage.getItem('summaryNumber'));
    // @ts-ignore
    let visit = JSON.parse(localStorage.getItem('visit'));
    valuePoint({})
    let flag = false;
    const nowTime = form.getFieldsValue().visitingTime;
    const arferTime = dayjs().subtract(-2, 'day').format('YYYY-MM-DD 00:00');
    console.log(nowTime >= arferTime);
    if (nowTime >= arferTime) {
      flag = true;
    }
    const any = await typelist({});
    let lists: any = [];
    if (any.retCode === 0 && any.data.length > 0) {
      any.data.map((item: any, index: number) => {
        // if (
        //   localStorage.getItem('summaryNumber') &&
        //   localStorage.getItem('visit') &&
        //   !flag
        // ) {
        //   if (visit.carVisit > list.vehicleSourceNum) {
        //     if (item.type === 12 || item.type === 13) {
        //       lists.push({
        //         label: item.name,
        //         value: item.type,
        //       });
        //     }
        //   } else if (visit.rePurchase > list.repurchaseNum) {
        //     if (item.type === 12 || item.type === 3 || item.type === 13) {
        //       lists.push({
        //         label: item.name,
        //         value: item.type,
        //       });
        //     }
        //   } else if (visit.newPurchase > list.newPurchasesNum) {
        //     if (
        //       item.type === 12 ||
        //       item.type === 3 ||
        //       item.type === 2 ||
        //       item.type === 13
        //     ) {
        //       lists.push({
        //         label: item.name,
        //         value: item.type,
        //       });
        //     }
        //   } else if (visit.store > list.imageShopNum) {
        //     if (
        //       item.type === 12 ||
        //       item.type === 3 ||
        //       item.type === 2 ||
        //       item.type === 4 ||
        //       item.type === 13
        //     ) {
        //       lists.push({
        //         label: item.name,
        //         value: item.type,
        //       });
        //     }
        //   } else {
        //     lists.push({
        //       label: item.name,
        //       value: item.type,
        //     });
        //   }
        // } else {
        //   lists.push({
        //     label: item.name,
        //     value: item.type,
        //   });
        // }
        if (item.type === 12) {
          setNum(item.num);
        }
        lists.push({
          label: item.name,
          value: item.type,
        });
      });
      setTypeList([lists]);
    }
  };
  const wxShareConfig = async () => {
    // setUserId(["ChenHong"])
    // callOnBD(["ChenHong"])
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/",
    });
    console.log(window.wx);
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareTimeline'],
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['selectEnterpriseContact'],
        success: function () {
          // 回调
        },
        fail: function (res: any) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
    }
    // WWOpenData.bind(document.querySelector('ww-open-data'))
    // WWOpenData.on(event: string, callback: Function)
    // WWOpenData.on("", )
    //
    // @ts-ignore
    window.wx.invoke(
      'selectEnterpriseContact',
      {
        fromDepartmentId: 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        mode: 'single', // 必填，选择模式，single表示单选，multi表示多选
        type: ['department', 'user'], // 必填，选择限制类型，指定department、user中的一个或者多个
      },
      function (res: any) {
        console.log(res);
        if (res.err_msg == 'selectEnterpriseContact:ok') {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result); //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          var selectedDepartmentList = res.result.departmentList; // 已选的部门列表
          for (var i = 0; i < selectedDepartmentList.length; i++) {
            var department = selectedDepartmentList[i];
            var departmentId = department.id; // 已选的单个部门ID
            var departemntName = department.name; // 已选的单个部门名称
            Toast.show({
              icon: 'success',
              content: departmentId,
            });
          }
          var selectedUserList = res.result.userList; // 已选的成员列表
          let list = [];
          for (var i = 0; i < selectedUserList.length; i++) {
            var user = selectedUserList[i];
            var userId = user.id; // 已选的单个成员ID
            var userName = user.name; // 已选的单个成员名称
            var userAvatar = user.avatar; // 已选的单个成员头像
            // setCurrent(userName)
            list.push(userId);
          }
          setUserId(list);
          callOnBD(list);
          // Toast.show({
          //   icon:"success",
          //   content:userId
          // })
        }
      },
    );
  };

  const handleGroupCancel = () => {
    setCollectVisible(false);
  };
  const handelGroupName = async (value: any) => {
    console.log(value);

    if (value && value.length > 0) {
      // let arr = value[0].split('++');
      let name = '';
      let list: { goodsOriginId: any; goodsOriginName: any }[] = [];
      value.map((item: any) => {
        let arr = item.split('++');
        list.push({
          goodsOriginId: arr[0],
          goodsOriginName: arr[1],
        });
        name = name + arr[1] + '、';
      });
      form.setFieldValue('visitGoodsVos', name);
      setVisitGoodsVos(list);
      setCollectVisible(false);
    }
  };
  const callOnBD = async (list: any) => {
    const msg = await getUserInfo({
      userIds: list,
    });
    console.log(msg.data);
    let lists: any = [];
    msg.data.map((item: any) => {
      lists.push(item.name);
    });
    form?.setFieldsValue({ userName: lists[0] });
    // const arr = new Set(lists)
    // console.log([arr]);
  };
  const getUserId = () => {
    if (!userId || !userId[0]) {
      return localStorage.getItem('userId');
    }
    const isStr = Object.prototype.toString.call(userId) == '[object String]';
    if (isStr) {
      return userId;
    }
    const isArr = Object.prototype.toString.call(userId) == '[object Array]';
    if (isArr) {
      return userId[0] || localStorage.getItem('userId');
    }
    return localStorage.getItem('userId');
  };
  const getSummary = () => {
    getSummaryNumber({
      token: localStorage.getItem('token'),
    }).then((msg) => {
      if (msg.retCode === 0) {
        // setData(msg.data)
        var dd = new Date();
        const year = dayjs(dd).year();
        const month = dayjs(dd).month() + 1;
        const date = dayjs(dd).date();
        const day = year + '-' + month + '-' + date;
        const newDate = Date.parse(`${day} 17:00:00`);
        const oldDate = Date.parse(`${dayjs().format('YYYY-MM-DD HH:mm:ss')}`);
        console.log(newDate, oldDate);
        msg.data.map((item: any) => {
          if (newDate > oldDate) {
            if (item.type === 0) {
              let num =
                item.statisticsVo.imageShopNum +
                item.statisticsVo.newPurchasesNum +
                item.statisticsVo.repurchaseNum +
                item.statisticsVo.vehicleSourceNum;
              if (num < 12) {
                let list = JSON.stringify(item.statisticsVo);
                localStorage.setItem('summaryNumber', list);
                console.log(1111);
              } else {
                localStorage.removeItem('summaryNumber');
              }
            }
          } else {
            if (item.type === 1) {
              let num =
                item.statisticsVo.imageShopNum +
                item.statisticsVo.newPurchasesNum +
                item.statisticsVo.repurchaseNum +
                item.statisticsVo.vehicleSourceNum;
              if (num < 12) {
                let list = JSON.stringify(item.statisticsVo);
                localStorage.setItem('summaryNumber', list);
                console.log(1111);
              } else {
                localStorage.removeItem('summaryNumber');
              }
            }
          }
        });
        if (
          localStorage.getItem('summaryNumber') &&
          localStorage.getItem('visit')
        ) {
          // localStorageNumber();
        }
      } else {
        Toast.show({
          icon: 'fail',
          content: msg.retMsg,
        });
      }
    });
  };

  const init = async () => {
    let list: any[] = [];
    let list2: any[] = [];
    let list3: any[] = [];
    data.RECORDS.map((item: any) => {
      if (item.deep === 0) {
        list.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 1) {
        list2.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 2) {
        list3.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
    });
    let city: any[] = [];
    let city2: any[] = [];
    list2.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list3.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city.push(data);
    });
    list.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list2.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city2.push(data);
    });
    setoptions(city2);
    console.log(city2);
    const res = await getNewRepTotal({});
    if (res.retCode === 0) {
      setNewRepTotal(res.data);
    }
    const any = await typelist({});
    console.log(typeList);

    if (dataFlag && dataFlag.visitType && any.data && any.data.length > 0) {
      setStatus(dataFlag.visitType);
      any.data.map((item: any) => {
        if (item.type === dataFlag.visitType) {
          form.setFieldValue('visitSign', item.name);
        }
      });
    }
  };
  const submits = async () => {
    const values = forms.getFieldsValue();
    values.belongMarketId = localStorage.getItem('belongMarketId');
    values.contactProvinceCode = cityCode[0].value;
    values.contactCityCode = cityCode[1].value;
    values.contactRegionCode = cityCode[2].value;
    values.degreeIntent = degreeIntent;
    values.contactProvinceName = values.contactDetailAddress;
    values.remoteRecommendFlag = true;
    const msg = await saves(values);
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '计划创建成功',
      });
      form.setFieldValue('recommendations', msg.data.companyName);
      setrecommendationsList({
        companyId: msg.data.id,
        companyName: msg.data.companyName,
      });
      setVisible2(false);
      setRecommendationsVisible(false);
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };
  const onFinish = async () => {
    forms.validateFields().then(() => {
      submits();
    });
  };
  const submit = async function (type: number) {
    setLoading(true);
    let list: any = [];
    let list2: any = [];
    let list3: any = [];
    valuez.map((item: any) => {
      if (item != undefined) {
        list.push(item);
      }
    });
    if (meritList) {
      meritList.map((item: any) => {
        list2.push(item.id);
        list.filter((children: any) => list.indexOf(item.id) === -1);
      });
    }
    list.forEach((item: any) => {
      if (list2.indexOf(item) === -1) {
        list3.push(item);
      }
    });
    console.log(list,list3);
    
    // return
    const values = form.getFieldsValue();
    if (type === 3) {
      if (checkMerchant.length > num) {
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: `车商不可大于${num}个`,
        });
        return;
      }
      if (!values.visitingTime) {
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: `请选择拜访时间`,
        });
        return;
      }
      let visitCompanyRelationVos: any = [];
      // checkMerchantList.map((item:any)=>{
      //   visitCompanyRelationVos.push({
      //     companyId:item.companyId,
      //     companyName:item.companyName,
      //   })
      // })
      if (checkMerchant.length === 0) {
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: `请选择拜访车商`,
        });
        return;
      }
      checkMerchant.map((item: any) => {
        console.log(checkMerchant);

        visitCompanyRelationVos.push({
          companyId: item.companyId,
          companyName: item.companyName,
        });
      });
      const data = {
        userId: getUserId(),
        visitSignType: status,
        userName: values.userName,
        companyName: values.companyName,
        token: localStorage.getItem('token'),
        visitCompanyRelationVos: visitCompanyRelationVos,
        visitingTime: values.visitingTime,
        planFlag: false
      };
      save(data)
        .then((msg) => {
          setLoading(false);
          if (msg.retCode === 0) {
            Toast.show({
              icon: 'success',
              content: '提交成功',
            });
            // getSummary();
            history.goBack();
          } else {
            Toast.show({
              icon: 'fail',
              content: msg.retMsg,
            });
            return;
          }
        })
        .catch((error) => {
          setLoading(false);
          Toast.show({
            icon: 'fail',
            content: '提交失败',
          });
        });
    } else {
      if (values.focusCompanyVisit && !mediaImage.url) {
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: '请上传预约拜访截图',
        });
        return;
      }
      const data: any = {
        companyId: companyId,
        userId: getUserId(),
        visitSignType: status,
        visitContent: visitContent,
        userName: values.userName,
        targetPlanType: values.targetPlanType,
        companyName: values.companyName,
        targetAmount: parseInt(values.targetAmount),
        visitingTime: values.visitingTime,
        focusCompanyVisit: values.focusCompanyVisit,
        appointmentVisitScreenshot: mediaImage.url,
        labelIds: list3,
        productName: values.product,
        purchasingNum: values.purchasingNum,
        customerIntention: values.customerIntention,
        reqPurVisitRate: values.reqPurVisitRate
          ? values.reqPurVisitRate / 100
          : 0,
        cpTargetAmount: values.cpTargetAmount,
        visitCompanyRelationVos: recommendationsList,
        token: localStorage.getItem('token'),
      };
      if (dataFlag && dataFlag.parentId) {
        data.parentId = dataFlag.parentId;
      }
      if(dataFlag && (dataFlag.visitResult === 1 || dataFlag.visitResult === "1")){
        data.planFlag = true
      }else{
        data.planFlag = false
      }
      // if (list3.length < 8 && status != 13) {
      //   Toast.show({
      //     icon: 'fail',
      //     content: '拜访计划至少选择八个价值点！！',
      //   });
      //   setLoading(false);
      //   return;
      // }
      if(dataFlag && (dataFlag.visitResult === 1 || dataFlag.visitResult === "1")){
        const res = await visitSave(dataFlag)
        if(res.retCode != 0){
            Toast.show({
              icon: 'fail',
              content: res.retMsg,
            });
        }
      }
      console.log(data, values);
      save(data)
        .then((msg) => {
          setLoading(false);
          if (msg.retCode === 0) {
            // getSummary();
            if(msg.retCode === 0){
              Toast.show({
                icon: 'success',
                content: '提交成功',
              });
            }
            if (type === 2) {
              history.replace('./programme');
              setvisitContent('');
              setValues([]);
              form.resetFields();
              return;
            }
            // history.replace('./PayAVisit');
            history.goBack();
          } else {
            setLoading(false);
            Toast.show({
              icon: 'fail',
              content: msg.retMsg,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Toast.show({
            icon: 'fail',
            content: '提交失败',
          });
        });
    }

    setDisabled(false);
  };
  const Finish = async (type: number) => {
    if (status === 12) {
      submit(type);
      return;
    }
    form.validateFields().then(() => {
      submit(type);
    });
  };
  const onChange = (e: boolean, value: any) => {
    if (e) {
      let list = checkMerchantList;
      list.push(value);
      // const Lists = list.filter((item: any) => item);
      let Lists: any = [];
      list.forEach((key: any, index: any) => {
        if (Lists.indexOf(key) === -1) {
          Lists.push(key);
        }
      });
      setCheckMerchantList(Lists);
      console.log(e, Lists);
    } else {
      console.log(e);
      const list = checkMerchantList.filter((item: any) => item != value);
      console.log(e, list);
      setCheckMerchantList(list);
    }
  };
  const onChangeVisit = (e: boolean, value: any) => {
    if (e) {
      let list = visitGoodsVos;
      list.push(value);
      // const Lists = list.filter((item: any) => item);
      let Lists: any = [];
      list.forEach((key: any, index: any) => {
        if (Lists.indexOf(key) === -1) {
          Lists.push(key);
        }
      });
      setVisitGoodsVos(Lists);
      console.log(e, Lists);
    } else {
      console.log(e);
      const list = visitGoodsVos.filter((item: any) => item != value);
      console.log(e, list);
      setVisitGoodsVos(list);
    }
  };
  const initValue = async (dataFlag: any) => {
    form?.setFieldsValue({ visitContent: dataFlag.visitContent });
    if (dataFlag.userName) {
      form?.setFieldsValue({ userName: dataFlag.userName });
    }
    form?.setFieldsValue({ companyName: dataFlag.companyName });
    form?.setFieldsValue({ targetAmount: dataFlag.targetAmount });
    form?.setFieldsValue({ visitingTime: dataFlag.visitingTime });
    form?.setFieldsValue({ visitSign: dataFlag.visitSign });
    setCompanyId(dataFlag.companyId);

    if (dataFlag.companyId) {
      const msg = await getMCarDealerVisitVo({
        companyId: dataFlag.companyId,
      });
      scoreInit(dataFlag.companyId)
      if (msg.retCode === 0) {
        if (msg.data.mCompanyInfo) {
          setbelongMarketId(msg.data.mCompanyInfo.belongMarketId);
          setbelongMarketName(msg.data.mCompanyInfo.belongMarketName);
        }
      }
    }
    // setValues(dataFlag.labelIds)
    if (dataFlag.userId) {
      setUserId(dataFlag.userId);
    }
    if (dataFlag.productName) {
      form.setFieldsValue({product:dataFlag.productName})
    }
    if (dataFlag.visitSignTypeName) {
      form.setFieldsValue({visitSign:dataFlag.visitSignTypeName})
    }
    if (dataFlag.nextVisitTime) {
      form.setFieldsValue({visitingTime:dataFlag.nextVisitTime})
    }
    if (dataFlag.visitSignType) {
      setStatus(dataFlag.visitSignType);
    }
    if (dataFlag.value) {
      let list: any[] = [];
      dataFlag.value.map((item: any) => {
        let arr = item.split('-');
        console.log(arr);
        list.push({
          companyId: arr[0],
          companyName: arr[1],
        });
      });
      console.log(dataFlag.value);

      setCheckMerchantList(dataFlag.value);
      setcheckMerchant(list);
    }
    if (dataFlag.remoteRecommendFlag) {
      setStatus(2);
      form.setFieldValue('visitSign', '新购拜访');
    }
    if (dataFlag.visitSignTypeName) {
      form.setFieldValue('visitSign', dataFlag.visitSignTypeName);
    }
  };
  const handelUserName = async (value: any) => {
    console.log(value);
    let list: { companyId: any; companyName: any }[] = [];
    if (value.length > 0) {
      if (checkFlag) {
        value.map((item: any) => {
          let arr = item.split('===');
          console.log(arr);
          list.push({
            companyId: arr[1],
            companyName: arr[0],
          });
        });
        setcheckMerchant(list);
      } else {
        value.map(async (item: any) => {
          let arr = item.split('===');
          console.log(arr);
          form.setFieldValue('companyName', arr[0]);
          setCompanyId(arr[1]);
          const msg = await getMCarDealerVisitVo({
            companyId: arr[1],
          });
          scoreInit(arr[1])
          if (
            msg.data &&
            msg.data.mCompanyInfo &&
            msg.data.mCompanyInfo.contactCityCode
          ) {
            setContactCityCode(msg.data.mCompanyInfo.contactCityCode);
          }
          if (
            msg.data &&
            msg.data.mCompanyInfo &&
            msg.data.mCompanyInfo.contactCityName
          ) {
            setContactCityName(msg.data.mCompanyInfo.contactCityName);
          }
          scoreInit(arr[1])
          const any = await typelist({});
          console.log(typeList);

          if (msg.data && msg.data.visitType && any.data && any.data.length > 0) {
            setStatus(msg.data.visitType);
            any.data.map((item: any) => {
              if (item.type === msg.data.visitType && !form.getFieldValue("visitSign")) {
                form.setFieldValue('visitSign', item.name);
              }
            });
          }
        });
      }
    }
    setNameVisible(false);
  };
  const scoreInit = async (companyId:any) =>{
    if(companyId && localStorage.getItem('userId')){
      const score = await remarkLists({
        companyId: companyId,
        userId: localStorage.getItem('userId'),
      });
      if(score.data){
        setScoreList({data:score.data,companyId:companyId});
        score.data.map((item:any)=>{
          if(item.labelName === "专业人设"){
            valuePoint({companyId:companyId,parentId:item.labelId})
          }
        })
      }
    }
  }
  const valuePoint = async (value:any)=>{
    const res = await labellist({
      companyId: value.companyId,
      parentId: value.parentId,
    });
    const msg = await labellist({
      companyId: value.companyId,
      parentId: value.parentId,
    });
    if (res.retCode === 0) {
      if (res.data && res.data.length > 0) {
        let unfinished = res.data;
        unfinished.map((item: any, index: number) => {
          if (
            item.mCarDealerLabelVos &&
            item.mCarDealerLabelVos.length > 0
          ) {
            item.mCarDealerLabelVos.map(
              (child: any, childIndex: number) => {
                let flag = false;
                if (
                  child.remarkRecordDetails &&
                  child.remarkRecordDetails.length > 0
                ) {
                  child.remarkRecordDetails.map((children: any) => {
                    if (
                      children.mRemarkRecordDetails &&
                      children.mRemarkRecordDetails.length > 0
                    ) {
                      children.mRemarkRecordDetails.map(
                        (childItem: any) => {
                          if (
                            childItem.mRemarkRecordDetails &&
                            childItem.mRemarkRecordDetails.length > 0
                          ) {
                            childItem.mRemarkRecordDetails.map(
                              (childrenItem: any) => {
                                if (
                                  childrenItem.mRemarkRecordDetails &&
                                  childrenItem.mRemarkRecordDetails
                                    .length > 0
                                ) {
                                  childrenItem.mRemarkRecordDetails.map(
                                    (flagItem: any) => {
                                      if (
                                        flagItem.appraiseType &&
                                        flagItem.appraiseType < 3
                                      ) {
                                        flag = true;
                                      }
                                    },
                                  );
                                }
                              },
                            );
                          }
                        },
                      );
                    }
                  });
                  if (flag) {
                    delete unfinished[index].mCarDealerLabelVos[childIndex];
                  }
                }
              },
            );
          }
        });
        console.log(unfinished);
        
        setMeritList(unfinished);
      }
    }
    if (msg.retCode === 0) {
      if (msg.data && msg.data.length > 0) {
        let alreadyFinalize = msg.data;
        alreadyFinalize.map((item: any, index: number) => {
          if (
            item &&
            item.mCarDealerLabelVos &&
            item.mCarDealerLabelVos.length > 0
          ) {
            item.mCarDealerLabelVos.map(
              (child: any, childIndex: number) => {
                let flag = false;
                if (
                  child.remarkRecordDetails &&
                  child.remarkRecordDetails.length > 0
                ) {
                  child.remarkRecordDetails.map((children: any) => {
                    if (
                      children.mRemarkRecordDetails &&
                      children.mRemarkRecordDetails.length > 0
                    ) {
                      children.mRemarkRecordDetails.map(
                        (childItem: any) => {
                          if (
                            childItem.mRemarkRecordDetails &&
                            childItem.mRemarkRecordDetails.length > 0
                          ) {
                            childItem.mRemarkRecordDetails.map(
                              (childrenItem: any) => {
                                if (
                                  childrenItem.mRemarkRecordDetails &&
                                  childrenItem.mRemarkRecordDetails
                                    .length > 0
                                ) {
                                  childrenItem.mRemarkRecordDetails.map(
                                    (flagItem: any) => {
                                      if (
                                        flagItem.appraiseType &&
                                        flagItem.appraiseType < 3
                                      ) {
                                        flag = true;
                                      }
                                    },
                                  );
                                }
                              },
                            );
                          }
                        },
                      );
                    }
                  });
                  if (
                    !flag &&
                    data &&
                    alreadyFinalize[index] &&
                    alreadyFinalize[index].mCarDealerLabelVos &&
                    alreadyFinalize[index].mCarDealerLabelVos[childIndex]
                  ) {
                    delete alreadyFinalize[index].mCarDealerLabelVos[childIndex];
                    console.log(data);
                  }
                } else {
                  delete alreadyFinalize[index];
                }
              },
            );
          }
        });
        let list = alreadyFinalize;
        if (list.length > 0) {
          list.map((item: any, index: number) => {
            let doneFlag = false;
            if (
              item.mCarDealerLabelVos &&
              item.mCarDealerLabelVos.length > 0
            ) {
              item.mCarDealerLabelVos.map((child: any) => {
                if (child.remarkRecordDetails) {
                  doneFlag = true;
                } else {
                  doneFlag = false;
                }
              });
            }
            if (!doneFlag) {
              delete list[index];
            }
          });
        }
        const filteredArr = alreadyFinalize.filter((item: any) => item);
        console.log(filteredArr);
        setMeritListDone(filteredArr);
      }
    }
  }
  const handelrecommendationsUserName = async (value: any) => {
    console.log(value);
    if (value.length > 0) {
      value.map((item: any) => {
        let arr = item.split('===');
        console.log(arr);
        form.setFieldValue('recommendations', arr[0]);
        setrecommendationsList({ companyId: arr[1], companyName: arr[0] });
      });
    }
    setRecommendationsVisible(false);
  };
  const handleUserNameCancel = async () => {
    setNameVisible(false);
  };
  const handleRecommendationsCancel = async () => {
    setRecommendationsVisible(false);
  };
  useEffect(() => {
    // @ts-ignore
    infoRef.current = status;
  }, [status]);
  const goTobackM = () => {
    setCheckFlag(true);
    setNameVisible(true);
  };
  const goToback = () => {
    setCheckFlag(false);
    setNameVisible(true);
  };
  const goTobackRecommendations = () => {
    setCheckFlag(false);
    setRecommendationsVisible(true);
  };
  const rowClick = (index: number) => {
    const cache = isView;
    cache[index] = !cache[index];
    setIsView([...cache]);
  };
  const rowClickDone = (index: number) => {
    const cache = isViewDone;
    cache[index] = !cache[index];
    setIsViewDone([...cache]);
  };
  const localStorageNumber = () => {
    // @ts-ignore
    let list = JSON.parse(localStorage.getItem('summaryNumber'));
    // @ts-ignore
    let visit = JSON.parse(localStorage.getItem('visit'));
    console.log(list);
    if (
      visit.carVisit &&
      visit.carVisit > (list.vehicleSourceNum ? list.vehicleSourceNum : 0)
    ) {
      setStatus(12);
      form.setFieldValue('visitSign', '车源拜访');
    } else if (
      visit.rePurchase &&
      visit.rePurchase > (list.repurchaseNum ? list.repurchaseNum : 0)
    ) {
      setStatus(3);
      form.setFieldValue('visitSign', '复购拜访');
    } else if (
      visit.newPurchase &&
      visit.newPurchase > (list.newPurchasesNum ? list.newPurchasesNum : 0)
    ) {
      setStatus(2);
      form.setFieldValue('visitSign', '新购拜访');
    } else if (
      visit.store &&
      visit.store > (list.imageShopNum ? list.imageShopNum : 0)
    ) {
      setStatus(4);
      form.setFieldValue('visitSign', '形象店');
    }
    if (dataFlag) {
      initValue(dataFlag);
    }
    pullListMore();
  };
  const handelUserMarket = async (value: any) => {
    console.log(value);
    localStorage.setItem('belongMarketId', value[0].id);
    forms.setFieldValue('belongMarketName', value[0].marketName);
    setMarketVisible(false);
  };
  const handleUserMarketCancel = async () => {
    setMarketVisible(false);
  };
  const wxShareConfigImg = async () => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
        success: function (res) {
          // 回调
        },
      });
      window.wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function (res) {
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'normal', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds;
          localIds.map(async (item: any, index: number) => {
            await window.wx.uploadImage({
              localId: item.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
              isShowProgressTips: 1, // 默认为1，显示进度提示
              success: function (res: { serverId: any }) {
                let serverId = res.serverId; // 返回图片的服务器端ID
                getQyWxImg({mediaId:serverId})
                .then((ret:any)=>{
                  setMediaImage({
                    mediaId:serverId,
                    url: ret
                  });
                })
              },
              errMsg: function () {
                Toast.show({
                  icon: 'fail',
                  content: '上传失败',
                });
                setLoading(false);
                return;
              },
            });
          });
          // onValuesChange();
        },
      });
    }
  };
  useEffect(() => {
    init();
    console.log(dataFlag);

    // if(!dataFlag.parentId){
    pullListMore();
    // }
    if (
      localStorage.getItem('summaryNumber') &&
      localStorage.getItem('visit')
    ) {
      if (!dataFlag || !dataFlag.parentId) {
        // localStorageNumber();
      }
    } else {
      if (dataFlag) {
        initValue(dataFlag);
      }
    }
    console.log();
  }, []);
  return (
    <div>
      <Navbar name="新建拜访计划"></Navbar>
      {loading && <Login></Login>}
      <div className={`${styles.programme} ${styles.page}`}>
        <Card>
          <Form
            onFinish={() => false}
            form={form}
            footer={
              <>
                {status === 12 ? (
                  <Button
                    block
                    className={styles.submit}
                    type="submit"
                    color="primary"
                    onClick={() => {
                      setDisabled(true);
                      Finish(3);
                    }}
                  >
                    确认计划
                  </Button>
                ) : (
                  <>
                    <Button
                      block
                      type="submit"
                      className={styles.submit}
                      color="primary"
                      onClick={() => {
                        setDisabled(true);
                        Finish(1);
                      }}
                    >
                      <>
                        <div>确认计划</div>
                        <div style={{ fontSize: '10px' }}>返回拜访中心</div>
                      </>
                    </Button>
                    <Button
                      block
                      type="submit"
                      className={styles.submit}
                      style={{ border: '1px solid rgba(27, 91, 219, 1)' }}
                      onClick={() => {
                        setDisabled(true);
                        Finish(2);
                      }}
                    >
                      <>
                        <div
                          style={{
                            color: 'rgba(27, 91, 219, 1)',
                            fontSize: '14px',
                          }}
                        >
                          保存计划
                        </div>
                        <div
                          style={{
                            fontSize: '10px',
                            color: 'rgba(27, 91, 219, 1)',
                          }}
                        >
                          再次新建拜访计划
                        </div>
                      </>
                    </Button>
                  </>
                )}
              </>
            }
          >
            <List>
              <List.Item
                prefix="拜访类型"
                title={'*'}
                onClick={() => {
                  setStatusVisible(true);
                }}
              >
                <Form.Item
                  name="visitSign"
                  rules={[{ required: true, message: '请选择拜访类型' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="请选择拜访类型" readOnly />
                </Form.Item>
              </List.Item>
              {status === 12 && (
                <>
                  <List.Item
                    prefix="拜访商户"
                    title={'*'}
                    onClick={() => {
                      // console.log(11);
                      goTobackM();
                      localStorage.setItem(
                        'conmanyVisitSignType',
                        infoRef.current,
                      );
                    }}
                  >
                    <Form.Item
                      name="userName"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (checkMerchant > num) {
                              return Promise.reject(
                                new Error(`选择车源不可大于${num}个`),
                              );
                            }
                          },
                          required: true,
                        }),
                      ]}
                      className={styles.form_item}
                    >
                      <span>
                        {checkMerchant && checkMerchant.length}/{num}
                      </span>
                      <div className={styles.marketList}>
                        添加拜访商户
                        <img src={新增} />
                      </div>
                    </Form.Item>
                  </List.Item>
                  <div className={styles.checkMerchant}>
                    {checkMerchant &&
                      checkMerchant.length > 0 &&
                      checkMerchant.map((item: any, index: number) => {
                        console.log(checkMerchantList);
                        return (
                          <div
                            className={styles.checkMerchant_text}
                            key={`${item.companyId}-${item.companyName}`}
                          >
                            <div>{item.companyName}</div>
                            <Checkbox
                              defaultChecked
                              value={`${item.companyId}-${item.companyName}`}
                              onChange={(checked) => {
                                onChange(
                                  checked,
                                  `${item.companyId}-${item.companyName}`,
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
              <List.Item
                prefix="拜访BD"
                title={'*'}
                onClick={() => {
                  // console.log(11);
                  wxShareConfig();
                }}
              >
                <Form.Item
                  name="userName"
                  rules={[{ required: true, message: '请选择拜访BD' }]}
                  className={styles.form_item}
                  initialValue={localStorage.getItem('name')}
                >
                  <Input placeholder="请选择拜访BD" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="拜访时间"
                title={'*'}
                onClick={() => {
                  setTimeVisible(true);
                }}
              >
                <Form.Item
                  name="visitingTime"
                  rules={[{ required: true, message: '请选择时间' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="请选择时间" readOnly />
                </Form.Item>
              </List.Item>
              {status != 12 && status != 13 && (
                <>
                  <List.Item
                    prefix="选择产品"
                    title={'*'}
                    onClick={() => {
                      setCollectVisible(true);
                    }}
                  >
                    <Form.Item
                      name="product"
                      rules={[{ required: true, message: '请选择产品' }]}
                      className={styles.form_item}
                    >
                      {/* <div className={styles.marketList}>
                        添加检测产品
                        <img src={新增} />
                      </div> */}
                      <Input placeholder="请选择产品" readOnly />
                    </Form.Item>
                  </List.Item>
                  {/* <div className={styles.checkMerchant}>
                    {visitGoodsVos &&
                      visitGoodsVos.length > 0 &&
                      visitGoodsVos.map((item: any, index: number) => {
                        return (
                          <div
                            className={styles.checkMerchant_text}
                            key={`${item.goodsOriginId}-${item.goodsOriginName}`}
                          >
                            <div>{item.goodsOriginName}</div>
                            <Checkbox
                              defaultChecked
                              value={`${item.goodsOriginId}-${item.goodsOriginName}`}
                              onChange={(checked) => {
                                onChangeVisit(
                                  checked,
                                  `${item.goodsOriginId}-${item.goodsOriginName}`,
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                  </div> */}
                </>
              )}
              {status != 12 && (
                <>
                  {status != 5 && status != 13 && (
                    <List.Item
                      prefix="拜访商户"
                      onClick={() => {
                        // setMarketVisible(true)
                        goToback();
                        if (status != 13) {
                          localStorage.setItem(
                            'conmanyVisitSignType',
                            infoRef.current,
                          );
                        }
                      }}
                      title={'*'}
                    >
                      <Form.Item
                        name="companyName"
                        rules={[
                          { required: true, message: '选择或者输入车商' },
                        ]}
                        className={styles.form_item}
                      >
                        <Input placeholder="选择或者输入车商" readOnly />
                      </Form.Item>
                    </List.Item>
                  )}
                  {status === 13 && (
                    <>
                      <List.Item
                        prefix="推荐商户"
                        title={'*'}
                        onClick={() => {
                          // setMarketVisible(true)
                          goToback();
                        }}
                      >
                        <Form.Item
                          name="companyName"
                          rules={[
                            { required: true, message: '选择或者输入推荐商户' },
                          ]}
                          className={styles.form_item}
                        >
                          <Input placeholder="选择或者输入推荐商户" readOnly />
                        </Form.Item>
                      </List.Item>
                      <List.Item
                        prefix="被推荐商户"
                        title={'*'}
                        onClick={() => {
                          // setMarketVisible(true)
                          goTobackRecommendations();
                        }}
                      >
                        <Form.Item
                          name="recommendations"
                          rules={[
                            { required: true, message: '选择被推荐商户' },
                          ]}
                          className={styles.form_item}
                          extra={
                            <div
                              className={styles.menu_new}
                              onClick={() => {
                                setVisible2(true);
                                setRecommendationsVisible(false);
                              }}
                            >
                              <div>
                                <span>新增商户</span>
                              </div>
                            </div>
                          }
                        >
                          <Input placeholder="选择被推荐商户" readOnly />
                        </Form.Item>
                      </List.Item>
                    </>
                  )}
                  {status != 13 && (
                    <>
                      <List.Item prefix="目标方案" title={'*'}>
                        <Form.Item
                          name="targetPlanType"
                          className={styles.form_item}
                          initialValue={0}
                        >
                          <Radio.Group>
                            <Radio value={0}>产品买赠方案</Radio>
                            <Radio value={1}>形象店</Radio>
                            <Radio value={2}>集采城市限价</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </List.Item>
                      <List.Item prefix="目标金额" title={'*'} extra={'元'}>
                        <Form.Item
                          name="targetAmount"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject(
                                    new Error('输入目标金额'),
                                  );
                                }
                                if (parseInt(value) < 2000 && status === 2) {
                                  return Promise.reject(
                                    new Error('新购拜访目标金额不可少于2000元'),
                                  );
                                }
                                if (parseInt(value) < 3000 && status === 3) {
                                  return Promise.reject(
                                    new Error('复购拜访目标金额不可少于3000元'),
                                  );
                                }
                                if (parseInt(value) < 3000 && status === 4) {
                                  return Promise.reject(
                                    new Error('形象店目标金额不可少于3000元'),
                                  );
                                }
                                if (Number.isFinite(Number(value)) === false) {
                                  return Promise.reject(
                                    new Error('不可输入文字！！'),
                                  );
                                }
                                return Promise.resolve();
                              },
                              required: true,
                            }),
                          ]}
                          className={styles.form_item}
                        >
                          <Input placeholder="输入目标金额" />
                        </Form.Item>
                      </List.Item>
                      <List.Item prefix="重点商户" title={'*'}>
                        <Form.Item
                          name="focusCompanyVisit"
                          className={styles.form_item}
                          initialValue={0}
                        >
                          <Radio.Group onChange={(e:any)=>{
                            console.log(e);
                            
                            setfocusCompanyVisitFlag(!focusCompanyVisitFlag)
                          }}>
                            <Radio value={0}>否</Radio>
                            <Radio value={1}>是</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </List.Item>
                      {focusCompanyVisitFlag&& (
                        <List.Item prefix="预约拜访截图" title={'*'}>
                          <Form.Item name="appointmentVisitScreenshot" className={styles.form_item}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {mediaImage && mediaImage.url && (
                                <Image
                                  src={mediaImage.url}
                                  width={40}
                                  height={40}
                                  fit="fill"
                                  onClick={() => {
                                    setImageVisible(true);
                                    setAllImage(mediaImage.url);
                                  }}
                                />
                              )}

                              <div
                                className={styles.increaseIcon}
                                onClick={() => {
                                  wxShareConfigImg();
                                }}
                              >
                                <AddOutline />
                              </div>
                            </div>
                          </Form.Item>
                        </List.Item>
                      )}
                      {/* <List.Item
                        prefix="客户意向"
                        title={'*'}
                        onClick={() => {
                          setcustomerIntentionVisible(true);
                        }}
                      >
                        <Form.Item
                          name="customerIntention"
                          rules={[{ required: true, message: '请选择客户意向' }]}
                          className={styles.form_item}
                        >
                          <Input placeholder="请选择客户意向" readOnly />
                        </Form.Item>
                      </List.Item>
                      <List.Item prefix="集采次数" title={'*'}>
                        <Form.Item
                          name="purchasingNum"
                          initialValue={newRepTotal && newRepTotal.totalCount ? newRepTotal.totalCount : 0}
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject(
                                    new Error('输入集采次数'),
                                  );
                                }
                                if (Number.isFinite(Number(value)) === false) {
                                  return Promise.reject(
                                    new Error('不可输入文字！！'),
                                  );
                                }
                                return Promise.resolve();
                              },
                              required: true,
                            }),
                          ]}
                          className={styles.form_item}
                        >
                          <Input placeholder="输入集采次数" />
                        </Form.Item>
                      </List.Item>
                      <List.Item prefix="复购拜访成交率" title={'*'} extra={'%'}>
                        <Form.Item
                          name="reqPurVisitRate"
                          initialValue={newRepTotal && newRepTotal.reqPurVisitRate ? newRepTotal.reqPurVisitRate*100 : 0}
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (`${value}` === "") {
                                  return Promise.reject(
                                    new Error('输入复购拜访成交率'),
                                  );
                                }
                                if (Number.isFinite(Number(value)) === false) {
                                  return Promise.reject(
                                    new Error('不可输入文字！！'),
                                  );
                                }
                                return Promise.resolve();
                              },
                              required: true,
                            }),
                          ]}
                          className={styles.form_item}
                        >
                          <Input placeholder="输入复购拜访成交率" />
                        </Form.Item>
                      </List.Item> */}
                      {/* <div className={styles.card_content}>
                        <Card
                          title={
                            <div>
                              拜访内容 <span className={styles.card_x}>*</span>
                            </div>
                          }
                        >
                          <TextArea
                            name="visitContent"
                            value={visitContent}
                            onChange={(v) => {
                              console.log(v);
                              setvisitContent(v);
                            }}
                            placeholder="请输入内容"
                            rows={5}
                            maxLength={500}
                            showCount
                          />
                        </Card>
                      </div> */}
                      {scoreList && scoreList.data && scoreList.data.length > 0 && (
                        <div style={{marginTop:"20px"}}>
                          <MyPie onPointChange={valuePoint} companyId={scoreList.companyId} current={scoreList.data}></MyPie>
                        </div>
                      )}
                      <div className={styles.merit}>
                        <div
                          className={styles.merit_title_0}
                          style={{ fontWeight: '600' }}
                        >
                          选择本次拜访价值点{' '}
                          <Tag color="rgba(254, 26, 26, 0.3)">未完成</Tag>
                        </div>
                        {meritList &&
                          meritList.map((item: any, index: any) => {
                            if (
                              item.mCarDealerLabelVos &&
                              item.mCarDealerLabelVos.length > 0
                            ) {
                              return (
                                <div>
                                  <div className={styles.merit_title}>
                                    <div
                                      onClick={() => {
                                        rowClick(index);
                                      }}
                                    >
                                      {item.name}{' '}
                                      <span
                                        style={{
                                          transform: `${
                                            !isView[index]
                                              ? 'rotate(-135deg)'
                                              : 'rotate(-45deg)'
                                          }`,
                                        }}
                                      ></span>
                                    </div>
                                    <Checkbox
                                      key={`${item.id}-unfinished`}
                                      onChange={(checked) => {
                                        let list: any[] = [];
                                        if (checked) {
                                          item.mCarDealerLabelVos.map(
                                            (check: any) => {
                                              valuez[check.id] = check.id;
                                            },
                                          );
                                          valuez[item.id] = item.id;
                                          setValues([...valuez]);
                                        } else {
                                          item.mCarDealerLabelVos.map(
                                            (check: any) => {
                                              valuez[check.id] = undefined;
                                            },
                                          );
                                          valuez[item.id] = undefined;
                                          setValues([...valuez]);
                                        }
                                      }}
                                      value={valuez[item.id]}
                                      checked={valuez[item.id] ? true : false}
                                    />
                                  </div>
                                  {isView[index] && (
                                    <div className={styles.merit_check}>
                                      {item.mCarDealerLabelVos.length > 0 &&
                                        item.mCarDealerLabelVos.map(
                                          (ChildItem: any, cIndex: number) => {
                                            return (
                                              <Checkbox.Group value={valuez}>
                                                <div
                                                  className={
                                                    styles.merit_check_0
                                                  }
                                                  key={`MeritList-${index}-${cIndex}`}
                                                >
                                                  <div>{ChildItem.name}</div>
                                                  <Checkbox
                                                    onChange={(checked) => {
                                                      if (checked) {
                                                        console.log(valuez);
                                                        valuez[ChildItem.id] =
                                                          ChildItem.id;
                                                        setValues([...valuez]);
                                                      } else {
                                                        valuez[ChildItem.id] =
                                                          undefined;
                                                        setValues([...valuez]);
                                                      }
                                                    }}
                                                    value={ChildItem.id}
                                                  />
                                                </div>
                                                {ChildItem.remarkRecordDetails &&
                                                  ChildItem.remarkRecordDetails.map(
                                                    (
                                                      child: any,
                                                      mCarIndex: number,
                                                    ) => {
                                                      return (
                                                        <div
                                                          key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                                                        >
                                                          {child.mRemarkRecordDetails &&
                                                            child
                                                              .mRemarkRecordDetails
                                                              .length > 0 &&
                                                            child.mRemarkRecordDetails.map(
                                                              (
                                                                pchildren: any,
                                                                pcIndex: number,
                                                              ) => {
                                                                return (
                                                                  <div>
                                                                    {pchildren
                                                                      .mRemarkRecordDetails
                                                                      .length >
                                                                      0 &&
                                                                      pchildren.mRemarkRecordDetails.map(
                                                                        (
                                                                          children: any,
                                                                          rIndex: number,
                                                                        ) => {
                                                                          return (
                                                                            <div
                                                                              className={
                                                                                styles.footer
                                                                              }
                                                                              key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                                                              style={{
                                                                                display:
                                                                                  moreMap[
                                                                                    `${pcIndex}`
                                                                                  ] ||
                                                                                  rIndex <
                                                                                    3
                                                                                    ? 'block'
                                                                                    : 'none',
                                                                              }}
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content_header_staff
                                                                                      }
                                                                                      style={{
                                                                                        position:
                                                                                          'relative',
                                                                                      }}
                                                                                    >
                                                                                      <img
                                                                                        className={
                                                                                          styles.remark_content_header_staff_0
                                                                                        }
                                                                                        src={
                                                                                          children.url
                                                                                        }
                                                                                        style={{
                                                                                          position:
                                                                                            'static',
                                                                                        }}
                                                                                        alt=""
                                                                                      />
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header_staff_1
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1_name
                                                                                          }
                                                                                        >
                                                                                          {children.remarkLabelUserName
                                                                                            ? children.remarkLabelUserName
                                                                                            : '-'}{' '}
                                                                                          <span>
                                                                                            {parseResultName(
                                                                                              `${children.appraiseType}`,
                                                                                            )}
                                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff_1_time
                                                                                          }
                                                                                        >
                                                                                          {children.createTime
                                                                                            ? dateFormat(
                                                                                                children.createTime,
                                                                                                'yyyy-MM-dd hh:mm:ss',
                                                                                              )
                                                                                            : '-'}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_foot
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      children.remarkContent
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                                {children.mRemarkRecordDetails &&
                                                                                  children
                                                                                    .mRemarkRecordDetails
                                                                                    .length >
                                                                                    0 &&
                                                                                  children.mRemarkRecordDetails.map(
                                                                                    (
                                                                                      remarkChild: any,
                                                                                      key: number,
                                                                                    ) => {
                                                                                      return (
                                                                                        <div
                                                                                          key={
                                                                                            key
                                                                                          }
                                                                                          style={{
                                                                                            background:
                                                                                              '#EFF3F9',
                                                                                            marginLeft:
                                                                                              '50px',
                                                                                            marginBottom:
                                                                                              '10px',
                                                                                            borderRadius:
                                                                                              '8px',
                                                                                          }}
                                                                                        >
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_p
                                                                                            }
                                                                                          >
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content
                                                                                              }
                                                                                            >
                                                                                              <div
                                                                                                className={
                                                                                                  styles.remark_content_header
                                                                                                }
                                                                                              >
                                                                                                <div
                                                                                                  className={
                                                                                                    styles.remark_content_header_staff
                                                                                                  }
                                                                                                >
                                                                                                  <img
                                                                                                    className={
                                                                                                      styles.remark_content_header_staff_0
                                                                                                    }
                                                                                                    src={
                                                                                                      remarkChild.url
                                                                                                    }
                                                                                                    style={{
                                                                                                      position:
                                                                                                        'static',
                                                                                                    }}
                                                                                                    alt=""
                                                                                                  />
                                                                                                  <div
                                                                                                    className={
                                                                                                      styles.remark_content_header_staff_1
                                                                                                    }
                                                                                                  >
                                                                                                    <div
                                                                                                      className={
                                                                                                        styles.remark_content_header_staff_1_name
                                                                                                      }
                                                                                                    >
                                                                                                      {
                                                                                                        remarkChild.remarkLabelUserName
                                                                                                      }{' '}
                                                                                                      <span>
                                                                                                        {' '}
                                                                                                        {parseResultName(
                                                                                                          `${remarkChild.appraiseType}`,
                                                                                                        )}{' '}
                                                                                                      </span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                      className={
                                                                                                        styles.remark_content_header_staff_1_time
                                                                                                      }
                                                                                                    >
                                                                                                      {remarkChild.createTime
                                                                                                        ? dateFormat(
                                                                                                            remarkChild.createTime,
                                                                                                            'yyyy-MM-dd hh:mm:ss',
                                                                                                          )
                                                                                                        : '-'}
                                                                                                    </div>
                                                                                                  </div>
                                                                                                </div>
                                                                                              </div>
                                                                                              <div
                                                                                                className={
                                                                                                  styles.remark_child
                                                                                                }
                                                                                              >
                                                                                                {
                                                                                                  remarkChild.remarkContent
                                                                                                }
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      );
                                                                                    },
                                                                                  )}
                                                                              </div>
                                                                            </div>
                                                                          );
                                                                        },
                                                                      )}
                                                                    {pchildren.mRemarkRecordDetails &&
                                                                      pchildren
                                                                        .mRemarkRecordDetails
                                                                        .length >
                                                                        3 && (
                                                                        <div
                                                                          style={{
                                                                            textAlign:
                                                                              'right',
                                                                            color:
                                                                              'blue',
                                                                          }}
                                                                          onClick={(
                                                                            e,
                                                                          ) => {
                                                                            moreMap[
                                                                              `${pcIndex}`
                                                                            ] =
                                                                              !moreMap[
                                                                                `${pcIndex}`
                                                                              ];
                                                                            setMoreMap(
                                                                              {
                                                                                ...moreMap,
                                                                              },
                                                                            );
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                          }}
                                                                        >
                                                                          {moreMap[
                                                                            `${pcIndex}`
                                                                          ]
                                                                            ? '折叠-'
                                                                            : '展开+'}
                                                                        </div>
                                                                      )}
                                                                  </div>
                                                                );
                                                              },
                                                            )}
                                                        </div>
                                                      );
                                                    },
                                                  )}
                                              </Checkbox.Group>
                                            );
                                          },
                                        )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          })}
                        <div
                          className={styles.merit_title_1}
                          style={{ fontWeight: '600' }}
                        >
                          已完成价值点{' '}
                          <Tag color="rgba(23, 181, 1, 0.3)">已完成</Tag>
                        </div>
                        {meritListDone && meritListDone.length > 0 ? (
                          <div>
                            {meritListDone &&
                              meritListDone.map((item: any, index: any) => {
                                if (
                                  item.mCarDealerLabelVos &&
                                  item.mCarDealerLabelVos.length > 0
                                ) {
                                  return (
                                    <div>
                                      <div className={styles.merit_title}>
                                        <div
                                          onClick={() => {
                                            rowClickDone(index);
                                          }}
                                        >
                                          {item.name}{' '}
                                          <span
                                            style={{
                                              transform: `${
                                                !isViewDone[index]
                                                  ? 'rotate(-135deg)'
                                                  : 'rotate(-45deg)'
                                              }`,
                                            }}
                                          ></span>
                                        </div>
                                        <Checkbox
                                          // defaultChecked={valuez}
                                          key={`${item.id}-done`}
                                          onChange={(checked) => {
                                            let list: any[] = [];
                                            if (checked) {
                                              item.mCarDealerLabelVos.map(
                                                (check: any) => {
                                                  valuez[check.id] = check.id;
                                                },
                                              );
                                              valuez[item.id] = item.id;
                                              setValues([...valuez]);
                                            } else {
                                              item.mCarDealerLabelVos.map(
                                                (check: any) => {
                                                  valuez[check.id] = undefined;
                                                },
                                              );
                                              valuez[item.id] = undefined;
                                              setValues([...valuez]);
                                            }
                                          }}
                                          value={item.id}
                                          checked={valuez[item.id] ? true : false}
                                        />
                                      </div>
                                      {isViewDone[index] && (
                                        <div className={styles.merit_check}>
                                          {item.mCarDealerLabelVos.length > 0 &&
                                            item.mCarDealerLabelVos.map(
                                              (
                                                ChildItem: any,
                                                cIndex: number,
                                              ) => {
                                                return (
                                                  <Checkbox.Group
                                                    value={valuez}
                                                    
                                                  >
                                                    <div
                                                      className={
                                                        styles.merit_check_0
                                                      }
                                                      key={`MeritList-${index}-${cIndex}`}
                                                    >
                                                      <div>
                                                        {ChildItem.name}
                                                      </div>
                                                      <Checkbox
                                                        onChange={(checked) => {
                                                          if (checked) {
                                                            console.log(valuez);
                                                            valuez[
                                                              ChildItem.id
                                                            ] = ChildItem.id;
                                                            setValues([
                                                              ...valuez,
                                                            ]);
                                                          } else {
                                                            valuez[
                                                              ChildItem.id
                                                            ] = undefined;
                                                            setValues([
                                                              ...valuez,
                                                            ]);
                                                          }
                                                        }}
                                                        value={ChildItem.id}
                                                      />
                                                    </div>
                                                    {ChildItem.remarkRecordDetails &&
                                                      ChildItem.remarkRecordDetails.map(
                                                        (
                                                          child: any,
                                                          mCarIndex: number,
                                                        ) => {
                                                          return (
                                                            <div
                                                              key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                                                            >
                                                              {child.mRemarkRecordDetails &&
                                                                child
                                                                  .mRemarkRecordDetails
                                                                  .length > 0 &&
                                                                child.mRemarkRecordDetails.map(
                                                                  (
                                                                    pchildren: any,
                                                                    pcIndex: number,
                                                                  ) => {
                                                                    return (
                                                                      <div>
                                                                        {pchildren
                                                                          .mRemarkRecordDetails
                                                                          .length >
                                                                          0 &&
                                                                          pchildren.mRemarkRecordDetails.map(
                                                                            (
                                                                              children: any,
                                                                              rIndex: number,
                                                                            ) => {
                                                                              return (
                                                                                <div
                                                                                  className={
                                                                                    styles.footer
                                                                                  }
                                                                                  key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                                                                  style={{
                                                                                    display:
                                                                                      moreMap[
                                                                                        `${pcIndex}`
                                                                                      ] ||
                                                                                      rIndex <
                                                                                        3
                                                                                        ? 'block'
                                                                                        : 'none',
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        styles.remark_content
                                                                                      }
                                                                                    >
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_header
                                                                                        }
                                                                                      >
                                                                                        <div
                                                                                          className={
                                                                                            styles.remark_content_header_staff
                                                                                          }
                                                                                          style={{
                                                                                            position:
                                                                                              'relative',
                                                                                          }}
                                                                                        >
                                                                                          <img
                                                                                            className={
                                                                                              styles.remark_content_header_staff_0
                                                                                            }
                                                                                            src={
                                                                                              children.url
                                                                                            }
                                                                                            style={{
                                                                                              position:
                                                                                                'static',
                                                                                            }}
                                                                                            alt=""
                                                                                          />
                                                                                          <div
                                                                                            className={
                                                                                              styles.remark_content_header_staff_1
                                                                                            }
                                                                                          >
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_name
                                                                                              }
                                                                                            >
                                                                                              {children.remarkLabelUserName
                                                                                                ? children.remarkLabelUserName
                                                                                                : '-'}{' '}
                                                                                              <span>
                                                                                                {parseResultName(
                                                                                                  `${children.appraiseType}`,
                                                                                                )}
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              className={
                                                                                                styles.remark_content_header_staff_1_time
                                                                                              }
                                                                                            >
                                                                                              {children.createTime
                                                                                                ? dateFormat(
                                                                                                    children.createTime,
                                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                                  )
                                                                                                : '-'}
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                      <div
                                                                                        className={
                                                                                          styles.remark_content_foot
                                                                                        }
                                                                                      >
                                                                                        {
                                                                                          children.remarkContent
                                                                                        }
                                                                                      </div>
                                                                                    </div>
                                                                                    {children.mRemarkRecordDetails &&
                                                                                      children
                                                                                        .mRemarkRecordDetails
                                                                                        .length >
                                                                                        0 &&
                                                                                      children.mRemarkRecordDetails.map(
                                                                                        (
                                                                                          remarkChild: any,
                                                                                          key: number,
                                                                                        ) => {
                                                                                          return (
                                                                                            <div
                                                                                              key={
                                                                                                key
                                                                                              }
                                                                                              style={{
                                                                                                background:
                                                                                                  '#EFF3F9',
                                                                                                marginLeft:
                                                                                                  '50px',
                                                                                                marginBottom:
                                                                                                  '10px',
                                                                                                borderRadius:
                                                                                                  '8px',
                                                                                              }}
                                                                                            >
                                                                                              <div
                                                                                                className={
                                                                                                  styles.remark_p
                                                                                                }
                                                                                              >
                                                                                                <div
                                                                                                  className={
                                                                                                    styles.remark_content
                                                                                                  }
                                                                                                >
                                                                                                  <div
                                                                                                    className={
                                                                                                      styles.remark_content_header
                                                                                                    }
                                                                                                  >
                                                                                                    <div
                                                                                                      className={
                                                                                                        styles.remark_content_header_staff
                                                                                                      }
                                                                                                    >
                                                                                                      <img
                                                                                                        className={
                                                                                                          styles.remark_content_header_staff_0
                                                                                                        }
                                                                                                        src={
                                                                                                          remarkChild.url
                                                                                                        }
                                                                                                        style={{
                                                                                                          position:
                                                                                                            'static',
                                                                                                        }}
                                                                                                        alt=""
                                                                                                      />
                                                                                                      <div
                                                                                                        className={
                                                                                                          styles.remark_content_header_staff_1
                                                                                                        }
                                                                                                      >
                                                                                                        <div
                                                                                                          className={
                                                                                                            styles.remark_content_header_staff_1_name
                                                                                                          }
                                                                                                        >
                                                                                                          {
                                                                                                            remarkChild.remarkLabelUserName
                                                                                                          }{' '}
                                                                                                          <span>
                                                                                                            {' '}
                                                                                                            {parseResultName(
                                                                                                              `${remarkChild.appraiseType}`,
                                                                                                            )}{' '}
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                          className={
                                                                                                            styles.remark_content_header_staff_1_time
                                                                                                          }
                                                                                                        >
                                                                                                          {remarkChild.createTime
                                                                                                            ? dateFormat(
                                                                                                                remarkChild.createTime,
                                                                                                                'yyyy-MM-dd hh:mm:ss',
                                                                                                              )
                                                                                                            : '-'}
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  </div>
                                                                                                  <div
                                                                                                    className={
                                                                                                      styles.remark_child
                                                                                                    }
                                                                                                  >
                                                                                                    {
                                                                                                      remarkChild.remarkContent
                                                                                                    }
                                                                                                  </div>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          );
                                                                                        },
                                                                                      )}
                                                                                  </div>
                                                                                </div>
                                                                              );
                                                                            },
                                                                          )}
                                                                        {pchildren.mRemarkRecordDetails &&
                                                                          pchildren
                                                                            .mRemarkRecordDetails
                                                                            .length >
                                                                            3 && (
                                                                            <div
                                                                              style={{
                                                                                textAlign:
                                                                                  'right',
                                                                                color:
                                                                                  'blue',
                                                                              }}
                                                                              onClick={(
                                                                                e,
                                                                              ) => {
                                                                                moreMap[
                                                                                  `${pcIndex}`
                                                                                ] =
                                                                                  !moreMap[
                                                                                    `${pcIndex}`
                                                                                  ];
                                                                                setMoreMap(
                                                                                  {
                                                                                    ...moreMap,
                                                                                  },
                                                                                );
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                              }}
                                                                            >
                                                                              {moreMap[
                                                                                `${pcIndex}`
                                                                              ]
                                                                                ? '折叠-'
                                                                                : '展开+'}
                                                                            </div>
                                                                          )}
                                                                      </div>
                                                                    );
                                                                  },
                                                                )}
                                                            </div>
                                                          );
                                                        },
                                                      )}
                                                  </Checkbox.Group>
                                                );
                                              },
                                            )}
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        ) : (
                          <Empty description="暂无已完成价值点" />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </List>
          </Form>
        </Card>
      </div>
      <div style={{ height: '100px' }}></div>
      <Picker
        columns={typeList}
        value={value}
        visible={statusVisible}
        onCancel={() => {
          setStatusVisible(false);
        }}
        onConfirm={(value, extend) => {
          setStatus(value[0]);
          infoRef.current = value[0];
          form.setFieldValue('visitSign', extend.items[0]?.label);
          setStatusVisible(false);
        }}
      />
      <DatePicker
        visible={timeVisible}
        onClose={() => {
          setTimeVisible(false);
        }}
        min={new Date()}
        title={'拜访时间'}
        precision="minute"
        defaultValue={dateJs()}
        renderLabel={labelRenderer}
        onConfirm={(val) => {
          const year = dayjs(val).year();
          const month = dayjs(val).month() + 1;
          const date = dayjs(val).date();
          const hour = dayjs(val).hour();
          const minute = dayjs(val).minute();
          let mon;
          let day;
          let hou;
          let min;
          if (month < 10) {
            mon = `0${month}`;
          } else {
            mon = month;
          }
          if (date < 10) {
            day = `0${date}`;
          } else {
            day = date;
          }
          if (hour < 10) {
            hou = `0${hour}`;
          } else {
            hou = hour;
          }
          if (minute < 10) {
            min = `0${minute}`;
          } else {
            min = minute;
          }
          const msg = `${year}-${mon}-${day} ${hou}:${min}`;
          pullListMore();
          form?.setFieldsValue({ visitingTime: msg });
        }}
      />
      <Popup
        position="right"
        visible={visible2}
        className="123123"
        bodyClassName={styles.bodyClass}
      >
        <Form
          // style={{marginTop:"20px"}}
          form={forms}
          // footer={
          //   <Button
          //     block
          //     className={styles.submit}
          //     type="submit"
          //     color="primary"
          //     onClick={() => {
          //     }}
          //   >
          //     新增车商
          //   </Button>
          // }
        >
          <NavBar
            onBack={() => {
              setVisible2(false);
              setRecommendationsVisible(false);
            }}
          >
            新建商户
          </NavBar>
          <List>
            <List.Item
              prefix="归属市场"
              onClick={() => {
                setMarketVisible(true);
              }}
              title={'*'}
            >
              <Form.Item
                name="belongMarketName"
                rules={[{ required: true, message: '选择市场' }]}
                className={styles.form_item}
              >
                <Input placeholder="选择市场" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item prefix="车商名称" title={'*'}>
              <Form.Item
                name="companyName"
                rules={[{ required: true, message: '请输入车商名称' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入车商名称" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="联系人" title={'*'}>
              <Form.Item
                name="contactName"
                rules={[{ required: true, message: '请输入联系人' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入联系人" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="联系电话" title={'*'}>
              <Form.Item
                name="contactMobileNo"
                rules={[{ required: true, message: '请输入联系电话' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入联系电话" />
              </Form.Item>
            </List.Item>
            <List.Item
              prefix="车商地址"
              title={'*'}
              onClick={() => {
                setVisible(true);
              }}
            >
              <Form.Item
                name="contactDetailAddress"
                rules={[{ required: true, message: '请选择车商地址' }]}
                className={styles.form_item}
              >
                <Input placeholder="请选择车商地址" readOnly />
              </Form.Item>
            </List.Item>
            <List.Item prefix="归属区域">
              <Form.Item
                name="belongingRegion"
                // rules={[{ required: true, message: '请输入归属区域' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入归属区域" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="楼层">
              <Form.Item
                name="floor"
                // rules={[{ required: true, message: '请输入楼层' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入楼层" />
              </Form.Item>
            </List.Item>
            <List.Item prefix="门牌号">
              <Form.Item
                name="doorplate"
                // rules={[{ required: true, message: '请输入门牌号' }]}
                className={styles.form_item}
              >
                <Input placeholder="请输入门牌号" />
              </Form.Item>
            </List.Item>
            {/* <List.Item prefix="商户类型"
              title={"*"}>
                <Form.Item
                  name="companyType"
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={0}>合作商户</Radio>
                    <Radio value={1}>待激活商户</Radio>
                    <Radio value={2}>注册商户</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item> */}
            <List.Item
              prefix="意向度"
              title={'*'}
              onClick={() => {
                setdegreeIntentVisible(true);
              }}
            >
              <Form.Item
                name="degreeIntentName"
                rules={[{ required: true, message: '请选择意向度' }]}
                className={styles.form_item}
              >
                <Input placeholder="请选择意向度" readOnly />
              </Form.Item>
            </List.Item>
          </List>
          <div className={styles.footer}>
            <div className={styles.button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setVisible2(false);
                  setRecommendationsVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  onFinish();
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Form>
      </Popup>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        position="right"
        bodyStyle={{ width: '100%' }}
      >
        <div style={{ width: 'inherit' }}>
          <TreeSelect
            options={options}
            onChange={(value, nodes) => {
              console.log(value, nodes);
              setCityCode(nodes.options);
            }}
          />
          <div className={styles.time_button}>
            <Button
              color="primary"
              fill="outline"
              onClick={() => {
                setVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="middle"
              color="primary"
              onClick={() => {
                console.log(cityCode);
                if (cityCode.length === 1) {
                  Toast.show({
                    content: '请选择城市',
                  });
                  return;
                }
                if (cityCode.length === 2) {
                  Toast.show({
                    content: '请选择区/县',
                  });
                  return;
                }
                forms.setFieldValue(
                  'contactDetailAddress',
                  `${cityCode[0].label}${cityCode[1].label}${cityCode[2].label}`,
                );
                setVisible(false);
              }}
            >
              确定
            </Button>
          </div>
        </div>
      </Popup>
      <Picker
        columns={degreeIntentList}
        visible={degreeIntentVisible}
        onCancel={() => {
          setdegreeIntentVisible(false);
        }}
        onConfirm={(value: any, extend) => {
          setdegreeIntent(value[0]);
          forms.setFieldValue('degreeIntentName', extend.items[0]?.label);
          setdegreeIntentVisible(false);
        }}
      />
      <Picker
        columns={customerIntentionList}
        visible={customerIntentionVisible}
        onCancel={() => {
          setcustomerIntentionVisible(false);
        }}
        onConfirm={(value: any, extend) => {
          console.log(extend.items[0]?.label);

          form.setFieldValue('customerIntention', extend.items[0]?.label);
          setcustomerIntentionVisible(false);
        }}
      />
      <Merchant
        visit={true}
        cityFlag={false}
        checkFlag={checkFlag}
        ButtonFlag={false}
        nameVisible={nameVisible}
        onSubmit={handelUserName}
        onCancel={handleUserNameCancel}
      />
      <Merchant
        visit={true}
        cityFlag={true}
        checkFlag={checkFlag}
        ButtonFlag={false}
        nameVisible={recommendationsVisible}
        onSubmit={handelrecommendationsUserName}
        onCancel={handleRecommendationsCancel}
      />
      <Market
        nameVisible={MarketVisible}
        onSubmit={handelUserMarket}
        onCancel={handleUserMarketCancel}
        value={false}
        list={[]}
      />
      {/* <CollectCkecked
        checkFlag={true}
        contactCityName={contactCityName}
        contactCityCode={contactCityCode}
        nameVisible={collectVisible}
        onSubmit={handelGroupName}
        onCancel={handleGroupCancel}
      /> */}
      <Popup
        visible={collectVisible}
        onMaskClick={() => {
          setCollectVisible(false);
        }}
        className={styles.popup}
        onClose={() => {
          setCollectVisible(false);
        }}
        bodyStyle={{
          height: '40vh',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CheckList multiple={true} style={{ flex: '1 1' }} onChange={onchanges}>
          {offeringsList.length > 0 &&
            offeringsList.map((item: any, index: number) => {
              return <CheckList.Item value={item}>{item}</CheckList.Item>;
            })}
        </CheckList>
        <div className={styles.footer}>
          <div className={styles.button}>
            <Button
              color="primary"
              fill="outline"
              onClick={() => {
                setCollectVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="middle"
              color="primary"
              onClick={() => {
                let str = '';
                if (checkCollect && checkCollect.length > 0) {
                  checkCollect.map((item: any) => {
                    str = str + item + '、';
                  });
                }
                form.setFieldValue('product', str);
                setCollectVisible(false);
              }}
            >
              确定
            </Button>
          </div>
        </div>
      </Popup>
      
      <ImageViewer
        image={allImage}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />
    </div>
  );
}
