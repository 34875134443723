import SpinLoading from "antd-mobile/es/components/spin-loading";
import Toast from "antd-mobile/es/components/toast";
import { FC, useEffect } from "react";
import { login } from "./service";
import { history,useLocation } from "umi";
import { DotLoading, Loading } from "antd-mobile";
const Login: FC<any> = (props) => {
  const loginStyle: React.CSSProperties = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "10000",
    background: "rgba(233, 236, 240, 0.8)"
  }
  return(
    <div style={loginStyle} >
       <Loading color="primary" />
    </div>
  )
}

export default Login;