import Card from 'antd-mobile/es/components/card';
import Steps from 'antd-mobile/es/components/steps';
import { Step } from 'antd-mobile/es/components/steps/step';
import Tabs from 'antd-mobile/es/components/tabs';
import { useEffect, useState } from 'react';
import { Link, Prompt, Redirect, Router, useLocation, useParams } from 'umi';
import Navbar from '../utils/Navbar';
import { dateFormat } from '../utils/util';
import initMerchant from './img/initMerchant.png';
import styles from './index.less';
import proxy from '../../../config/proxy';
import {
  Button,
  DatePicker,
  DotLoading,
  Empty,
  Form,
  Image,
  ImageViewer,
  Modal,
  Toast,
} from 'antd-mobile/es';
import {
  detectionRecords,
  fatherOrSonCompany,
  getCompanyRecord,
  getCouponUsedStatAls,
  getHistoryVisitImgVinList,
  getMCarDealerVisitVo,
} from './service';
import { degreeIntentList, displayTypeList } from '../utils/signIn';
import { parseResultName } from '../PayAVisit';
import { history } from 'umi';
import { InformationCircleFill } from 'antd-mobile-icons';
import 框 from './img/框.png';
import Merchant from '../comments/Merchant';
import { fatherSonCompany } from '../merchant/service';
import { getWxAccessToken, login } from '../login/service';
import {SpinLoading } from "antd-mobile";

export default function dealerDetails() {
  const [loading, setLoading] = useState<boolean>(true);
  const [detail, setDetail] = useState<any>([]);
  const [companyDeatail, setCompanyDeatail] = useState<any>([]);
  const [visitDeatail, setVisitDeatail] = useState<any>([]);
  const [conponDeatail, setconponDeatail] = useState<any>([]);
  const [fatherOrSonList, setfatherOrSonList] = useState<any>([]);
  const [inKuchegen, setInKuchegen] = useState<any>([]);
  const [allImage, setAllImage] = useState<any>([]);
  const [detectionRecord, setdetectionRecord] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);
  const [companyVisible, setCompanyVisible] = useState<boolean>(false);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [ButtonFlag, setButtonFlag] = useState<boolean>(false);
  const [token, setToken] = useState<string>(localStorage.getItem("token")||"");
  const [companyId, setCompanyId] = useState<any>({ companyId: -1 });
  const { rowId }: { rowId: string } = useParams();
  const [checkState, setCheckState] = useState<string>('1');
  const location = useLocation();
  const dataFlag = location.state;
  // @ts-ignore
  const query = location?.query;
  const loginStyle: React.CSSProperties = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "10000",
    background: "rgba(255, 255, 255, 1)"
  }
  const initValue = async (rowId: any) => {
    if (rowId) {
      const res = await getCompanyRecord({
        companyId: rowId,
      });
      const msg = await getMCarDealerVisitVo({
        companyId: rowId,
      });
      if (msg.retCode === 0) {
        const list = msg.data;
        wxOnMenuShareConfig(msg.data)
        const conpon = await getCouponUsedStatAls({
          companyPhone: msg.data.mCompanyInfo.contactMobileNo,
        });
        if (conpon.retCode === 0) {
          setconponDeatail(conpon.data);
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        }
        if ( msg.data.fatherSonFlag != null) {
          const fatherOrSon = await fatherOrSonCompany({
            id: rowId,
            fatherSonFlag:  msg.data.fatherSonFlag,
          });
          if (fatherOrSon.retCode === 0) {
            setfatherOrSonList(fatherOrSon.data);
          }
        }
        // 自媒体
        if (list.mCarDealerMedia && list.mCarDealerMedia.length > 0) {
          list.mCarDealerMedia.map((item: any) => {
            if (item.account === '-1') {
              list.mCarDealerMedia = [];
            }
          });
        }
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0) {
          list.mCarDealerPartners.map((item: any) => {
            if (item.partnerName === '无') {
              list.mCarDealerPartners = [];
            }
          });
        }
        // 竞队信息
        if (list.carDealerCompetitors && list.carDealerCompetitors.length > 0) {
          list.carDealerCompetitors.map((item: any) => {
            if (item.testOrgId === -1) {
              list.carDealerCompetitors = [];
            }
          });
        }
        setCompanyDeatail(list);
      }
      if (res.retCode === 0) {
        setVisitDeatail(res.data);
      }
    }
    const record = await detectionRecords({
      companyId: rowId,
    });
    const visit = await getHistoryVisitImgVinList({
      companyId: rowId,
    });
    if (record.retCode === 0) {
      setdetectionRecord(record.data);
    }
    if (visit.retCode === 0) {
      setInKuchegen(visit.data);
    }
  };

  const handelCompanyName = async (value: any) => {
    setButtonFlag(true);
    if (value && value.length > 0) {
      let arr = value[0].split('===');
      fatherSonCompany({
        fatherCompanyId: arr[1],
        id: companyId.companyId,
      })
        .then((res: any) => {
          if (res.retCode === 0) {
            Toast.show({
              icon: 'success',
              content: '绑定成功',
            });
            setCompanyVisible(false);
          } else {
            Toast.show({
              icon: 'fail',
              content: res.retMsg,
            });
          }
          setButtonFlag(false);
        })
        .catch((err) => {
          Toast.show({
            icon: 'fail',
            content: '绑定失败',
          });
          setButtonFlag(false);
        });
    }
  };
  const handleCompanyNameCancel = async () => {
    setCompanyVisible(false);
  };
  const stateEnum = [
    { name: '在库', value: 1 },
    { name: '未检测', value: 2 },
    { name: '已检测', value: 4 },
    { name: '已售出', value: 5 },
    {
      name: '未下单',
      value: 3,
    },
  ];
  const onChange = (v: any) => {
    setCheckState(v);
  };
  useEffect(() => {
    if (query.code && query.rowId && query.code != localStorage.getItem("token")) {
      login({ token: query.code })
        .then((msg) => {
          if (msg.retCode === 0) {
            localStorage.setItem('name', msg.data.name);
            localStorage.setItem('position', msg.data.position);
            localStorage.setItem('userId', msg.data.userId);
            localStorage.setItem('avatar', msg.data.avatar);
            localStorage.setItem('token', query.code);
            setToken(query.code)
            let flag = false;
            if (msg.data.isLeaderInDept) {
              let list = JSON.parse(msg.data.isLeaderInDept);
              if (list.length > 0) {
                list.map((data: any) => {
                  if (data === 1 || data === '1') {
                    flag = true;
                  }
                });
              }
              if (msg.data.isLeaderInDept === '[1]') {
                flag = true;
              }
            }
            if (msg.data.batchBuyAuthority) {
              localStorage.setItem(
                'batchBuyAuthority',
                msg.data.batchBuyAuthority,
              );
            }
            if (msg.data.userType === 0 || msg.data.userType === '0') {
              flag = true;
            }
            if (flag) {
              localStorage.setItem('isLeaderInDept', '1');
            } else {
              localStorage.removeItem('isLeaderInDept');
            }
            // initValue(query.rowId);
          }
        })
        .catch((error) => [
          Toast.show({
            icon: 'fail',
            content: '获取用户信息失败，请退出重新进入',
          }),
        ]);
    }
  }, []);
  useEffect(()=>{
    if (query.rowId && token) {
      // setDetail(rowId);
      if(!query.code){
        initValue(query.rowId);
      } else if(query.code && (query.code === token)){
        initValue(query.rowId);
      }
    }
  },[token])
  const wxOnMenuShareConfig = async (data: any) => {
    const msg = await getWxAccessToken({
      url: window.location.href,
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareAppMessage', 'shareWechatMessage'],
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareAppMessage', 'shareAppMessage'],
        success: function (res: any) {
          // 回调
          window.wx.checkJsApi({
            jsApiList: ['shareAppMessage'], // 需要检测的JS接口列表
            success: function (res: any) {
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
              console.log(res);
            },
          });
        },
        fail: function (res: { errMsg: string | string[] }) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.onMenuShareAppMessage({
        title: data.mCompanyInfo.companyName, // 分享标题
        desc: `销售：${data.mCompanyInfo.marketSalesManName || "-"}`, // 分享描述
        link: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww4168efadaace928a&redirect_uri=${proxy['/api'].target}/details?rowId=${query.rowId}&response_type=code&scope=snsapi_base&state=STATE&agentid=1000013#wechat_redirect`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
        imgUrl:
          data.mCompanyInfo && data.mCompanyInfo.doorHeadPhoto
            ? data.mCompanyInfo.doorHeadPhoto
            : initMerchant, // 分享图标
        enableIdTrans: 0, // 是否开启id转译，不填默认为0
        success: function (res: any) {
          // 用户确认分享后执行的回调函数
        },
        error: function (res: any) {
          if (res.errMsg.indexOf('no permission') > 0) {
            alert('未agentConfig');
          }
        },
        cancel: function (res: any) {
          // 用户取消分享后执行的回调函数
        },
      });
    }
  };
  return (
    <div className={styles.page}>
      <Navbar name="车商详情"></Navbar>
      {loading && (
        <div style={loginStyle} >
          <SpinLoading color='primary' style={{ '--size': '48px' }}  />
        </div>
      )}
      <div className={styles.Body} >
        {companyDeatail.mCompanyInfo && (
          <div className={styles.customBody}>
            <div className={styles.customBody_title}>
              <div className={styles.customBody_title_t}>
                {companyDeatail.mCompanyInfo.doorHeadPhoto ? (
                  <Image
                    src={companyDeatail.mCompanyInfo.doorHeadPhoto}
                    width={45}
                    height={45}
                    fit="fill"
                  />
                ) : (
                  <Image src={initMerchant} width={45} height={45} fit="fill" />
                )}
              </div>
              <div className={styles.customBody_title_text}>
                <div className={styles.customBody_title_text_name}>
                  {companyDeatail.mCompanyInfo.companyName}{' '}
                  {companyDeatail.mCompanyInfo.fatherSonFlag && <div>主商户</div>}
                  {!companyDeatail.mCompanyInfo.fatherSonFlag && companyDeatail.mCompanyInfo.fatherSonFlag != null && (
                    <div>子商户</div>
                  )}
                </div>
                {companyDeatail.mCarDealers && (
                  <div className={styles.customBody_title_text_tab}>
                    <span>
                      {companyDeatail.mCarDealers.parkingNum
                        ? companyDeatail.mCarDealers.parkingNum
                        : '0'}{' '}
                      · 价位{' '}
                      {companyDeatail.mCarDealers.price
                        ? companyDeatail.mCarDealers.price
                        : '0'}
                    </span>
                    {companyDeatail.mCarDealers.sellWay && (
                      <span>
                        {companyDeatail.mCarDealers.sellWay === 1 && '零售'}
                        {companyDeatail.mCarDealers.sellWay === 2 && '批发'}
                        {companyDeatail.mCarDealers.sellWay === 3 &&
                          '零售&批发'}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.customBody_title_f}>
                拜访
                {companyDeatail.mCompanyInfo.visitCount
                  ? companyDeatail.mCompanyInfo.visitCount
                  : '0'}
                次
              </div>
            </div>
            <div className={styles.customBody_content_2}>
              <div className={styles.customBody_content_0}>
                <div>联系人</div>
                <span>{companyDeatail.mCompanyInfo.contactName}</span>
              </div>
              <div className={styles.customBody_content_0}>
                <div>联系方式</div>
                <span>
                  {companyDeatail.mCompanyInfo.contactMobileNo}
                  <a
                    href={`tel:${companyDeatail.mCompanyInfo.contactMobileNo}`}
                  >
                    拨号
                  </a>
                </span>
              </div>
              <div className={styles.customBody_content_0}>
                <div>库存数量</div>
                <span>{companyDeatail.notUsedNum || 0}</span>
              </div>
              <div className={styles.customBody_content_0}>
                <div>集采时间</div>
                <span>
                  {companyDeatail.mCompanyInfo.createTime
                    ? dateFormat(
                        companyDeatail.mCompanyInfo.createTime,
                        'yyyy-MM-dd hh:mm:ss',
                      )
                    : '-'}
                </span>
              </div>
              <div className={styles.customBody_content_0}>
                <div>车商地址</div>
                {companyDeatail.mCompanyInfo.belongMarketName ||
                companyDeatail.mCompanyInfo.floor ||
                companyDeatail.mCompanyInfo.doorplate ? (
                  <span>
                    {companyDeatail.mCompanyInfo.belongMarketName &&
                      companyDeatail.mCompanyInfo.belongMarketName}
                    {companyDeatail.mCompanyInfo.floor &&
                      `/${companyDeatail.mCompanyInfo.floor}`}
                    {companyDeatail.mCompanyInfo.doorplate &&
                      `/${companyDeatail.mCompanyInfo.doorplate}室`}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </div>
        )}
        <Card>
          <Tabs activeLineMode={'fixed'} defaultActiveKey={'1'}>
            {companyDeatail.mCompanyInfo && (
              <Tabs.Tab title="商户信息" key="1">
                <Card className={styles.tab}>
                  <div className={styles.tabs}>
                    <div className={styles.tabs_title}>商户基础信息</div>
                    <div className={styles.tabs_content}>
                      {companyDeatail.mCompanyInfo && (
                        <>
                          <div className={styles.tabs_content_0}>
                            <div>商户名称</div>
                            <div>{companyDeatail.mCompanyInfo.companyName}</div>
                          </div>
                          <div className={styles.tabs_content_0}>
                            <div>联系人</div>
                            <div>{companyDeatail.mCompanyInfo.contactName}</div>
                          </div>
                          <div className={styles.tabs_content_0}>
                            <div>联系电话</div>
                            <div>
                              {companyDeatail.mCompanyInfo.contactMobileNo}
                            </div>
                          </div>
                          <div className={styles.tabs_content_0}>
                            <div>地址</div>
                            <div>
                              {companyDeatail.mCompanyInfo.contactDetailAddress}
                            </div>
                          </div>
                          <div className={styles.tabs_content_0}>
                            <div>归属市场</div>
                            <div>
                              {companyDeatail.mCompanyInfo.belongMarketName}
                            </div>
                          </div>
                          <div className={styles.tabs_content_0}>
                            <div>归属区域</div>
                            <div>
                              {companyDeatail.mCompanyInfo.belongingRegion
                                ? companyDeatail.mCompanyInfo.belongingRegion
                                : '-'}
                            </div>
                          </div>
                          <div className={styles.tabs_content_0}>
                            <div>门牌号</div>
                            <div>
                              {companyDeatail.mCompanyInfo.doorplate
                                ? companyDeatail.mCompanyInfo.doorplate
                                : '-'}
                            </div>
                          </div>
                          <div className={styles.tabs_content_0}>
                            <div>楼层</div>
                            <div>
                              {companyDeatail.mCompanyInfo.floor
                                ? companyDeatail.mCompanyInfo.floor
                                : '-'}
                            </div>
                          </div>
                        </>
                      )}
                      {companyDeatail.mCarDealers && (
                        <div className={styles.tabs_content_0}>
                          <div>合伙性质</div>
                          <div>
                            {companyDeatail.mCarDealers.partnerType === 1 &&
                              '单一经营'}
                            {companyDeatail.mCarDealers.partnerType === 2 &&
                              '多人合伙'}
                            {companyDeatail.mCarDealers.partnerType === 0 &&
                              '未知'}
                          </div>
                        </div>
                      )}
                      {companyDeatail.mCarDealers && (
                        <div className={styles.tabs_content_0}>
                          <div>独立展厅</div>
                          <div>
                            {/* {companyDeatail.mCarDealers.kilometerFlag === 0 &&
                              '否'} */}
                            {companyDeatail.mCarDealers.outerMerchant
                              ? '是'
                              : '否'}
                          </div>
                        </div>
                      )}
                      {companyDeatail.mCarDealers &&
                        companyDeatail.mCarDealers.outerMerchant && (
                          <div className={styles.tabs_content_0}>
                            <div>独立展厅类型</div>
                            <div>
                              {companyDeatail.mCompanyInfo.kilometerFlag
                                ? '一公里内独立展厅'
                                : '一公里外独立展厅'}
                            </div>
                          </div>
                        )}
                      {companyDeatail.mCompanyInfo &&
                        `${companyDeatail.mCompanyInfo.recommendCompanyFlag}` !=
                          '' && (
                          <div className={styles.tabs_content_0}>
                            <div>是否愿意异地推荐</div>
                            <div>
                              {companyDeatail.mCompanyInfo.recommendCompanyFlag
                                ? '是'
                                : '否'}
                            </div>
                          </div>
                        )}
                      <div className={styles.tabs_content_0}>
                        <div>合伙人</div>
                        <div>
                          {companyDeatail.mCarDealerPartners &&
                          companyDeatail.mCarDealerPartners.length > 0
                            ? companyDeatail.mCarDealerPartners.length
                            : '无'}
                        </div>
                      </div>
                      {companyDeatail.mCarDealerPartners &&
                        companyDeatail.mCarDealerPartners.length > 0 && (
                          <>
                            {companyDeatail.mCarDealerPartners.map(
                              (item: any) => {
                                return (
                                  <div className={styles.tabs_content_1}>
                                    <div className={styles.tabs_content_1_0}>
                                      <div>合伙人姓名</div>
                                      <div>
                                        {item.partnerName
                                          ? item.partnerName
                                          : '无'}
                                      </div>
                                    </div>
                                    <div className={styles.tabs_content_1_0}>
                                      <div>合伙人电话</div>
                                      <div>
                                        {item.partnerPhone
                                          ? item.partnerPhone
                                          : '无'}
                                      </div>
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </>
                        )}

                      <div className={styles.tabs_content_0}>
                        <div>自媒体信息</div>
                        <div>
                          {companyDeatail.mCarDealerMedia &&
                          companyDeatail.mCarDealerMedia.length > 0
                            ? companyDeatail.mCarDealerMedia.length
                            : '无'}
                        </div>
                      </div>
                      {companyDeatail.mCarDealerMedia &&
                        companyDeatail.mCarDealerMedia.length > 0 && (
                          <>
                            {companyDeatail.mCarDealerMedia.map((item: any) => {
                              return (
                                <div className={styles.tabs_content_1}>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>自媒体</div>
                                    <div>
                                      {item.mediaName ? item.mediaName : '无'}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>账号</div>
                                    <div>
                                      {item.account ? item.account : '无'}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>粉丝量</div>
                                    <div>
                                      {item.fansNum ? item.fansNum : '0'}万
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      <div className={styles.tabs_content_0}>
                        <div>客户来源</div>
                        <div>
                          {companyDeatail.customerSources &&
                          companyDeatail.customerSources.length > 0
                            ? companyDeatail.customerSources.length
                            : '无'}
                        </div>
                      </div>
                      {companyDeatail.customerSources &&
                        companyDeatail.customerSources.length > 0 && (
                          <>
                            {companyDeatail.customerSources.map((item: any) => {
                              return (
                                <div className={styles.tabs_content_1}>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>{item.customerSourceName}</div>
                                    <div>
                                      {item.proportion ? item.proportion : '无'}%
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      <div className={styles.tabs_content_0}>
                        <div>推荐商户</div>
                        <div>
                          {companyDeatail.recommendedMerchants &&
                          companyDeatail.recommendedMerchants.length > 0
                            ? companyDeatail.recommendedMerchants.length
                            : '无'}
                        </div>
                      </div>
                      {companyDeatail.recommendedMerchants &&
                        companyDeatail.recommendedMerchants.length > 0 && (
                          <>
                            {companyDeatail.recommendedMerchants.map((item: any) => {
                              return (
                                <div className={styles.tabs_content_1}>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>商户名称</div>
                                    <div>
                                      {item.companyName ? item.companyName : '-'}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>{item.contactName}</div>
                                    <div>
                                      {item.contactPhone}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>推荐方式</div>
                                    <div>
                                      {item.recommendedType === 1 && "本地推荐" }
                                      {item.recommendedType === 2 && "异地推荐" }
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>推荐原因</div>
                                    <div>
                                      {item.recommendedReasons}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>合作频次</div>
                                    <div>
                                      {item.cooperationFrequency}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      <div className={styles.tabs_content_0}>
                        <div>竞队信息</div>
                        <div>
                          {companyDeatail.carDealerCompetitors &&
                          companyDeatail.carDealerCompetitors.length > 0
                            ? companyDeatail.carDealerCompetitors.length
                            : '无'}
                        </div>
                      </div>
                      {companyDeatail.carDealerCompetitors &&
                        companyDeatail.carDealerCompetitors.length > 0 && (
                          <>
                            {companyDeatail.carDealerCompetitors.map(
                              (item: any) => {
                                return (
                                  <div className={styles.tabs_content_1}>
                                    <div className={styles.tabs_content_1_0}>
                                      <div>检测机构</div>
                                      <div>{item.name ? item.name : '无'}</div>
                                    </div>
                                    <div className={styles.tabs_content_1_0}>
                                      <div>合作时间</div>
                                      <div>
                                        {item.cooperationTime
                                          ? dateFormat(
                                              item.cooperationTime,
                                              'yyyy-MM-dd hh:mm:ss',
                                            )
                                          : '-'}
                                      </div>
                                    </div>
                                    <div className={styles.tabs_content_1_0}>
                                      <div>集采余额</div>
                                      <div>
                                        {item.purchaseBalance
                                          ? item.purchaseBalance
                                          : '0'}
                                        元
                                      </div>
                                    </div>
                                    <div className={styles.tabs_content_1_0}>
                                      <div>集采库存</div>
                                      <div>
                                        {item.purchasingStock
                                          ? item.purchasingStock
                                          : '0'}
                                      </div>
                                    </div>
                                    <div className={styles.tabs_content_1_0}>
                                      <div>硬广覆盖</div>
                                      <div>
                                        {item.hardCover === 1 ? '是' : '否'}
                                      </div>
                                    </div>
                                    {item.advertise && (
                                      <div className={styles.mediaList_delete}>
                                        <span>竞队广告</span>
                                        <Image
                                          src={item.advertise}
                                          style={{width:"60px",height:"60px",marginRight:"5px",borderRadius:"5px"}}
                                          fit="fill"
                                          onClick={() => {
                                            setImageVisible(true);
                                            setAllImage(item.advertise);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              },
                            )}
                          </>
                        )}
                      {companyDeatail.mCarDealers && (
                        <div className={styles.tabs_content_0}>
                          <div>展厅类型</div>
                          {displayTypeList[0].map((item: any) => {
                            if (
                              item.value ===
                              companyDeatail.mCarDealers.displayType
                            ) {
                              return <div>{item.label}</div>;
                            }
                          })}
                        </div>
                      )}
                      {companyDeatail.mCarDealers && (
                        <div className={styles.tabs_content_0}>
                          <div>形象店</div>
                          <div>
                            {companyDeatail.mCarDealers.imageStore === 1 &&
                              '是'}
                            {companyDeatail.mCarDealers.imageStore === 0 &&
                              '否'}
                          </div>
                        </div>
                      )}
                      {companyDeatail.mCarDealers && (
                        <div className={styles.tabs_content_0}>
                          <div>是否做形象店门头</div>
                          <div>
                            {companyDeatail.mCarDealers.imageStoreFrontFlag === 1 &&
                              '是'}
                            {companyDeatail.mCarDealers.imageStoreFrontFlag === 0 &&
                              '否'}
                          </div>
                        </div>
                      )}
                      {companyDeatail.mCarDealers && (
                        <div className={styles.tabs_content_0}>
                          <div>门头店覆盖</div>
                          <div>
                            {companyDeatail.mCarDealers.storeCover === 1 &&
                              '是'}
                            {companyDeatail.mCarDealers.storeCover === 0 &&
                              '否'}
                          </div>
                        </div>
                      )}
                      {}
                      {companyDeatail.mCompanyInfo && (
                        <div className={styles.tabs_content_0}>
                          <div>意向度</div>
                          {companyDeatail.mCompanyInfo.degreeIntent && degreeIntentList && degreeIntentList[0].map((item:any)=>{
                            if(companyDeatail.mCompanyInfo.degreeIntent === item.value){
                              return (
                                <div>
                                  {item.label}
                                </div>
                              )
                            }
                          })}
                        </div>
                      )}
                      {companyDeatail.registrationTime && (
                        <div className={styles.tabs_content_0}>
                          <div>注册时间</div>
                          <div>
                            {companyDeatail.registrationTime
                              ? dateFormat(
                                  companyDeatail.registrationTime,
                                  'yyyy-MM-dd',
                                )
                              : '-'}
                          </div>
                        </div>
                      )}
                      {companyDeatail.signUpForTheVoucherTime && (
                        <div className={styles.tabs_content_0}>
                          <div>注册体验券体验时间</div>
                          <div>
                            {companyDeatail.signUpForTheVoucherTime
                              ? dateFormat(
                                  companyDeatail.signUpForTheVoucherTime,
                                  'yyyy-MM-dd',
                                )
                              : '-'}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.tabs}>
                    {companyDeatail && (
                      <>
                        <div className={styles.tabs_title}>商户分类信息</div>
                        <div className={styles.tabs_content}>
                          {companyDeatail.mCarDealers && (
                            <>
                              <div className={styles.tabs_content_0}>
                                <div>售车方式</div>
                                <div>
                                  {companyDeatail.mCarDealers.sellWay === 1 &&
                                    '零售'}
                                  {companyDeatail.mCarDealers.sellWay === 2 &&
                                    '批发'}
                                  {companyDeatail.mCarDealers.sellWay === 3 &&
                                    '批发&零售'}
                                </div>
                              </div>
                              <div className={styles.tabs_content_0}>
                                <div>检测方式</div>
                                <div>
                                  {' '}
                                  {companyDeatail.mCarDealers.detectionMethod
                                    ? companyDeatail.mCarDealers.detectionMethod
                                    : '-'}
                                </div>
                              </div>
                              <div className={styles.tabs_content_0}>
                                <div>车位数</div>
                                <div>
                                  {' '}
                                  {companyDeatail.mCarDealers.parkingNum
                                    ? companyDeatail.mCarDealers.parkingNum
                                    : '-'}
                                </div>
                              </div>
                              <div className={styles.tabs_content_0}>
                                <div>车源量</div>
                                <div>
                                  {' '}
                                  {companyDeatail.mCarDealers.actualDisplayVolume
                                    ? companyDeatail.mCarDealers.actualDisplayVolume
                                    : '0'}
                                </div>
                              </div>
                              <div className={styles.tabs_content_0}>
                                <div>品牌类型</div>
                                <div>
                                  {' '}
                                  {companyDeatail.mCarDealers.brandType
                                    ? companyDeatail.mCarDealers.brandType
                                    : '-'}
                                </div>
                              </div>
                              <div className={styles.tabs_content_0}>
                                <div>能源类型</div>
                                <div>
                                  {' '}
                                  {companyDeatail.mCarDealers.energyType
                                    ? companyDeatail.mCarDealers.energyType
                                    : '-'}
                                </div>
                              </div>
                              <div className={styles.tabs_content_0}>
                                <div>车辆类型</div>
                                <div>
                                  {' '}
                                  {companyDeatail.mCarDealers.vehicleType
                                    ? companyDeatail.mCarDealers.vehicleType
                                    : '-'}
                                </div>
                              </div>
                              <div className={styles.tabs_content_0}>
                                <div>价格区间</div>
                                <div>
                                  {' '}
                                  {companyDeatail.mCarDealers.priceRange
                                    ? companyDeatail.mCarDealers.priceRange
                                    : '-'}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </Tabs.Tab>
            )}
            {visitDeatail && (
              <Tabs.Tab title="拜访记录" key="2">
                <Card className={styles.tab}>
                  <div className={styles.tabs}>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>月注册拜访次数</div>
                        <div>
                          {visitDeatail.monthEnrollVisitNum
                            ? visitDeatail.monthEnrollVisitNum
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>月新购拜访次数</div>
                        <div>
                          {visitDeatail.monthNewPurchaseVisitNum
                            ? visitDeatail.monthNewPurchaseVisitNum
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>月复购拜访次数</div>
                        <div>
                          {visitDeatail.monthRepurchaseVisitNum
                            ? visitDeatail.monthRepurchaseVisitNum
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>月车源拜访次数</div>
                        <div>
                          {visitDeatail.monthVehicleSourceVisitNum
                            ? visitDeatail.monthVehicleSourceVisitNum
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>最后一次拜访时间</div>
                        <div>
                          {visitDeatail.lastVisitingTime
                            ? dateFormat(
                                visitDeatail.lastVisitingTime,
                                'yyyy-MM-dd hh:mm:ss',
                              )
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>未解决问题数</div>
                        <div>
                          {visitDeatail.notResolvedIssueNum
                            ? visitDeatail.notResolvedIssueNum
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>成功问题数</div>
                        <div>
                          {visitDeatail.succeedIssueNum
                            ? visitDeatail.succeedIssueNum
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabs_content}>
                      <div className={styles.tabs_content_0}>
                        <div>失败决问题数</div>
                        <div>
                          {visitDeatail.failIssueNum
                            ? visitDeatail.failIssueNum
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Steps current={1} direction="vertical">
                    {visitDeatail.recordVoList &&
                      visitDeatail.recordVoList.length > 0 &&
                      visitDeatail.recordVoList.map((item: any) => {
                        return (
                          <Step
                            title={
                              item.createTime
                                ? dateFormat(
                                    item.createTime,
                                    'yyyy-MM-dd hh:mm:ss',
                                  )
                                : '-'
                            }
                            description={
                              <div className={styles.step_content}>
                                <div className={styles.step_content_title}>
                                  <div className={styles.step_content_title_0}>
                                    <div>拜访类型</div>
                                    <div>{item.visitSignName}</div>
                                  </div>
                                  <div className={styles.step_content_title_0}>
                                    <div>拜访结果</div>
                                    <div>
                                      {item.visitResult === '0'
                                        ? '成功'
                                        : '失败'}
                                    </div>
                                  </div>
                                  <div className={styles.step_content_title_0}>
                                    <div>拜访结论</div>
                                    <div>{item.visitContent}</div>
                                  </div>
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                  拜访备注
                                </div>
                                {item.remarkRecordDetails &&
                                  item.remarkRecordDetails.length > 0 &&
                                  item.remarkRecordDetails.map((child: any) => {
                                    return (
                                      <div className={styles.step_content_text}>
                                        <div
                                          className={
                                            styles.step_content_text_header
                                          }
                                        >
                                          <div
                                            className={
                                              styles.step_remark_header
                                            }
                                          >
                                            {child.labelName}
                                          </div>
                                          {/* <div
                                          className={
                                            styles.step_content_text_note
                                          }
                                        >
                                          价值点说明介绍
                                        </div> */}
                                        </div>
                                        {child.mRemarkRecordDetails &&
                                          child.mRemarkRecordDetails.length >
                                            0 &&
                                          child.mRemarkRecordDetails.map(
                                            (children: any) => {
                                              return (
                                                <div
                                                  className={
                                                    styles.step_content_text_0
                                                  }
                                                >
                                                  <div>
                                                    {children.labelName}
                                                  </div>
                                                  {children.mRemarkRecordDetails &&
                                                    children
                                                      .mRemarkRecordDetails
                                                      .length > 0 &&
                                                    children.mRemarkRecordDetails.map(
                                                      (itemChild: any) => {
                                                        return (
                                                          <div
                                                            className={
                                                              styles.remark
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles.remark_content_header_staff
                                                              }
                                                            >
                                                              <img
                                                                className={
                                                                  styles.remark_content_header_staff_0
                                                                }
                                                                src={
                                                                  itemChild.url
                                                                }
                                                                alt=""
                                                              />
                                                              <div
                                                                className={
                                                                  styles.remark_content
                                                                }
                                                              >
                                                                <div
                                                                  className={
                                                                    styles.remark_content_header_staff_1_name
                                                                  }
                                                                >
                                                                  <div>
                                                                    {itemChild.remarkLabelUserName
                                                                      ? itemChild.remarkLabelUserName
                                                                      : '-'}{' '}
                                                                    {itemChild.createTime
                                                                      ? dateFormat(
                                                                          itemChild.createTime,
                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                        )
                                                                      : '-'}
                                                                  </div>
                                                                  <span>
                                                                    {parseResultName(
                                                                      itemChild.appraiseType,
                                                                    )}
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    styles.remark_content_foot
                                                                  }
                                                                >
                                                                  {
                                                                    itemChild.remarkContent
                                                                  }
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {itemChild
                                                              .mRemarkRecordDetails
                                                              .length > 0 && (
                                                              <div
                                                                className={
                                                                  styles.remark_p
                                                                }
                                                              >
                                                                {itemChild.mRemarkRecordDetails.map(
                                                                  (
                                                                    itemChildren: any,
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            itemChildren.url
                                                                          }
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            <div>
                                                                              {itemChildren.remarkLabelUserName
                                                                                ? itemChildren.remarkLabelUserName
                                                                                : '-'}{' '}
                                                                              {itemChildren.createTime
                                                                                ? dateFormat(
                                                                                    itemChildren.createTime,
                                                                                    'yyyy-MM-dd hh:mm:ss',
                                                                                  )
                                                                                : '-'}
                                                                            </div>
                                                                            <span>
                                                                              {parseResultName(
                                                                                itemChildren.appraiseType,
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_foot
                                                                            }
                                                                          >
                                                                            {
                                                                              itemChildren.remarkContent
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    );
                                                                  },
                                                                )}
                                                              </div>
                                                            )}
                                                          </div>
                                                        );
                                                      },
                                                    )}
                                                </div>
                                              );
                                            },
                                          )}
                                      </div>
                                    );
                                  })}
                              </div>
                            }
                          />
                        );
                      })}
                  </Steps>
                </Card>
              </Tabs.Tab>
            )}
            {conponDeatail && (
              <Tabs.Tab title="集采记录" key="3">
                {conponDeatail && (
                  <>
                    {conponDeatail.couponUsed && (
                      <div className={styles.tab}>
                        <div className={styles.tab_collecting}>
                          新购时间{' '}
                          <span>
                            {conponDeatail.newPurchaseTime
                              ? dateFormat(
                                  conponDeatail.newPurchaseTime,
                                  'yyyy-MM-dd hh:mm:ss',
                                )
                              : '-'}
                          </span>
                        </div>
                        <div className={styles.tab_collecting}>
                          注册-新购间隔时间{' '}
                          <span>
                            {conponDeatail.newPurchaseIntervalTime
                              ? conponDeatail.newPurchaseIntervalTime
                              : '0'}
                            天
                          </span>
                        </div>
                        <div className={styles.tab_collecting}>
                          年集采次数{' '}
                          <span>
                            {conponDeatail.yearPickNum
                              ? conponDeatail.yearPickNum
                              : 0}
                            次
                            <InformationCircleFill
                              style={{ fontSize: 20, marginLeft: '3px' }}
                              color="var(--adm-color-primary)"
                              onClick={() => {
                                setVisible(true);
                              }}
                            />
                          </span>
                        </div>
                        <div className={styles.tab_collecting}>
                          平均订单集采金额{' '}
                          <span>
                            {conponDeatail.averagePickNum
                              ? conponDeatail.averagePickNum
                              : 0}
                            元
                          </span>
                        </div>
                        <div className={styles.tab_collecting}>
                          年集采产品数{' '}
                          <span>
                            {conponDeatail.yearProjectNum
                              ? conponDeatail.yearProjectNum
                              : 0}
                            个
                          </span>
                        </div>
                        <div className={styles.tab_collecting}>
                          平均订单集采台次{' '}
                          <span>
                            {conponDeatail.averageOrderNum
                              ? conponDeatail.averageOrderNum
                              : 0}
                            台次
                          </span>
                        </div>
                        <div className={styles.tab_collecting}>
                          库存预计使用天数{' '}
                          <span>
                            {conponDeatail.inventoryUseDays
                              ? conponDeatail.inventoryUseDays
                              : 0}
                            天
                          </span>
                        </div>
                        <div className={styles.tab_collecting}>
                          上次产品赠送比例{' '}
                          <span>
                            {conponDeatail.lastProjectGiftingRatio
                              ? conponDeatail.lastProjectGiftingRatio
                              : 0}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className={styles.tab_collecting_0}>集采明细</div>
                    {conponDeatail.couponUsed && (
                      <div className={styles.tab}>
                        <div className={styles.tab_collecting}>
                          集采金额{' '}
                          <span>{conponDeatail.couponUsed.price || 0}元</span>
                        </div>
                        <div className={styles.tab_collecting}>
                          库存 <span>{conponDeatail.couponUsed.quantity}</span>
                        </div>
                      </div>
                    )}
                    <div className={styles.tab_collecting_0}>集采记录</div>
                    {conponDeatail.couponUsedStat &&
                      conponDeatail.couponUsedStat.map((item: any) => {
                        return (
                          <>
                            <div
                              className={styles.step_collecting}
                              style={{ marginBottom: '10px' }}
                            >
                              <div className={styles.step_collecting_title}>
                                <div className={styles.step_collecting_title_0}>
                                  <div>{item.couponName}</div>
                                  <div>{item.quantity}</div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    {/* <img style={{width:"100%"}} src={框} alt="" onClick={()=>{
                        setVisible(false);
                      }}/> */}
                    <Steps current={1} direction="vertical">
                      {conponDeatail.couponUsedStatAlls &&
                        conponDeatail.couponUsedStatAlls.map((item: any) => {
                          return (
                            <Step
                              title={
                                item.createTime
                                  ? dateFormat(
                                      item.createTime,
                                      'yyyy-MM-dd hh:mm:ss',
                                    )
                                  : '-'
                              }
                              description={
                                <div className={styles.step_collecting}>
                                  <div className={styles.step_collecting_title}>
                                    <div
                                      className={styles.step_collecting_title_0}
                                    >
                                      <div>集采金额</div>
                                      <div>{item.price || 0}元</div>
                                    </div>
                                    <div
                                      className={styles.step_collecting_title_0}
                                    >
                                      <div>{item.couponName}</div>
                                      <div>{item.used + item.remain}</div>
                                    </div>
                                  </div>
                                </div>
                              }
                            />
                          );
                        })}
                    </Steps>
                  </>
                )}
              </Tabs.Tab>
            )}
            <Tabs.Tab title="检测记录" key="4">
              {detectionRecord && (
                <div className={styles.tab}>
                  {`${detectionRecord.monthDetectNum}` != '' && (
                    <div className={styles.tab_collecting}>
                      月实际检测台次{' '}
                      <span>{detectionRecord.monthDetectNum}</span>
                    </div>
                  )}
                  {`${detectionRecord.detectPermeability}` != '' && (
                    <div className={styles.tab_collecting}>
                      检测渗透率{' '}
                      <span>{detectionRecord.detectPermeability * 100}%</span>
                    </div>
                  )}
                  {`${detectionRecord.accumulatedUsedPasses}` != '' && (
                    <div className={styles.tab_collecting}>
                      累计已使用台次{' '}
                      <span>{detectionRecord.accumulatedUsedPasses}台次</span>
                    </div>
                  )}
                  {`${detectionRecord.lastDetectIntervalDays}` != '' && (
                    <div className={styles.tab_collecting}>
                      最后一次检测间隔天数（D）{' '}
                      <span>{detectionRecord.lastDetectIntervalDays}</span>
                    </div>
                  )}
                  {`${detectionRecord.averageThreeMonthsDetectNum}` != '' && (
                    <div className={styles.tab_collecting}>
                      近三个月月均检测台次{' '}
                      <span>
                        {detectionRecord.averageThreeMonthsDetectNum}台次
                      </span>
                    </div>
                  )}
                  {detectionRecord.monthBookingDetectNum &&
                    `${detectionRecord.monthBookingDetectNum}` != '' && (
                      <div className={styles.tab_collecting}>
                        月预约检测台次{' '}
                        <span>{detectionRecord.monthBookingDetectNum}台次</span>
                      </div>
                    )}
                  {detectionRecord.monthAmendmentDetectNum &&
                    `${detectionRecord.monthAmendmentDetectNum}` != '' && (
                      <div className={styles.tab_collecting}>
                        月改约检测台次{' '}
                        <span>
                          {detectionRecord.monthAmendmentDetectNum}台次
                        </span>
                      </div>
                    )}
                  {detectionRecord.monthWalkInTestingNum &&
                    `${detectionRecord.monthWalkInTestingNum}` != '' && (
                      <div className={styles.tab_collecting}>
                        月无预约检测台次{' '}
                        <span>{detectionRecord.monthWalkInTestingNum}台次</span>
                      </div>
                    )}
                  {detectionRecord.averageArrivalInterval &&
                    `${detectionRecord.averageArrivalInterval}` != '' && (
                      <div className={styles.tab_collecting}>
                        平均预约-到店时间间隔{' '}
                        <span>{detectionRecord.averageArrivalInterval}</span>
                      </div>
                    )}
                  {detectionRecord.averageCheckOutInterval &&
                    `${detectionRecord.averageCheckOutInterval}` != '' && (
                      <div className={styles.tab_collecting}>
                        平均检测完成-离店时间间隔{' '}
                        <span>{detectionRecord.averageCheckOutInterval}</span>
                      </div>
                    )}
                </div>
              )}
            </Tabs.Tab>
            <Tabs.Tab title="关联商户" key="5">
              {fatherOrSonList &&
                fatherOrSonList.length > 0 ?
                fatherOrSonList.map((item: any) => {
                  return (
                    <div className={styles.customBody}>
                      <div
                        className={styles.customBody_title}
                        // onClick={() => {
                        //   history.replace('./details', item);
                        // }}
                      >
                        <div className={styles.customBody_title_father}>
                          <div className={styles.customBody_title_t}>
                            {item.doorHeadPhoto ? (
                              <Image
                                src={`${item.doorHeadPhoto}?x-image-process=image/resize,l_100`}
                                width={45}
                                height={45}
                                fit="fill"
                              />
                            ) : (
                              <Image
                                src={initMerchant}
                                width={45}
                                height={45}
                                fit="fill"
                              />
                            )}
                          </div>
                        </div>
                        <div className={styles.customBody_title_text}>
                          <div className={styles.customBody_title_text_name}>
                            {/* <div>{item.companyName}</div> <span></span> {item.remoteRecommendFlag && <a>推荐</a>} */}
                            <div
                              style={{
                                color: '#222222',
                                fontSize: '16px',
                                marginLeft: '0',
                              }}
                            >
                              {item.companyName}
                            </div>
                            {item.fatherSonFlag && <div>主商户</div>}
                            {!item.fatherSonFlag &&
                              item.fatherSonFlag != null && <div>子商户</div>}
                            {/* {item.repurchaseFocusFlag && <a>合作商户复购重点</a>}
                              {item.newPurchasesFocusFlag && <a>注册商户新购重点</a>}
                              {item.activationRepurchaseFlag && <a>待激活复购</a>}
                              {item.vehicleSourceVisitsFlag && <a>合作商户车源拜访重点</a>} */}
                          </div>
                          <div className={styles.customBody_title_text_tab}>
                            <span>
                              车位数 {item.parkingNum ? item.parkingNum : '0'} ·
                              价位 {item.price ? item.price : '0'}
                            </span>
                            {item.sellWay && (
                              <span>
                                {item.sellWay === 1 && '零售'}
                                {item.sellWay === 2 && '批发'}
                                {item.sellWay === 3 && '零售&批发'}
                              </span>
                            )}
                            {`${item.companyType}` != '' && (
                              <span>
                                {`${item.companyType}` === '0' && '合作商户'}
                                {item.companyType === 1 && '待激活商户'}
                                {item.companyType === 2 && '注册商户'}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className={styles.customBody_title_f}>
                          拜访{item.visitCount ? item.visitCount : '0'}次
                        </div>
                      </div>
                      <div className={styles.customBody_content}>
                        <div className={styles.customBody_content_0} >
                          <div>联系人</div>
                          <span>
                            {item.contactName} {item.contactMobileNo}
                            <a href={`tel:${item.contactMobileNo}`}>拨号</a>
                          </span>
                        </div>
                        <div className={styles.customBody_content_0}>
                          <div>库存数量</div>
                          <span>{item.notUsedNum ? item.notUsedNum : '0'}</span>
                        </div>
                        <div className={styles.customBody_content_0}>
                          <div>车商地址</div>
                          {item.belongMarketName ||
                          item.floor ||
                          item.doorplate ? (
                            <span>
                              {item.belongMarketName && item.belongMarketName}
                              {item.floor && `/${item.floor}`}
                              {item.doorplate && `/${item.doorplate}室`}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                        {/* <div className={styles.customBody_content_0}>
                          <div>集采时间</div>
                          <span>
                            {item.createTime
                              ? dateFormat(item.createTime, 'yyyy-MM-dd hh:mm:ss')
                              : '-'}
                          </span>
                        </div> */}
                      </div>
                      <div className={styles.customBody_footer}>
                        <div style={{ width: '22%', paddingLeft: '10px' }}>
                          BD：
                          {item.marketSalesManName
                            ? item.marketSalesManName
                            : '-'}
                        </div>
                      </div>
                    </div>
                  );
                }) : <Empty description="暂无关联商户信息" />}
            </Tabs.Tab>
            <Tabs.Tab title="在库车源" key="6">
              <Tabs
                style={{
                  '--title-font-size': '14px',
                }}
                defaultActiveKey={'1'}
                className={styles.tab_appraise}
                onChange={onChange}
              >
                {stateEnum.map((item, index) => (
                  <Tabs.Tab title={item.name} key={item.value} />
                ))}
              </Tabs>
              {inKuchegen && inKuchegen.length > 0 ? (
                inKuchegen.map((item: any, index: any) => {
                  if (
                    item.url &&
                    (checkState === '1' ||
                      (checkState === '2' && item.status === 1) ||
                      (checkState === '3' && !item.reserveFlag) ||
                      (checkState === '4' && `${item.status}` === '0') ||
                      (checkState === '5' && `${item.status}` === '2'))
                  ) {
                    return (
                      <div className={styles.media_img}>
                        <div className={styles.media_img_1}>
                          <img
                            style={{ borderRadius: '10%' }}
                            src={item.url}
                            onClick={() => {
                              setImageVisible(true);
                              setAllImage(item.url);
                            }}
                          />
                        </div>
                        <div className={styles.media_text}>
                          <div className={styles.media_text_0}>
                            车架号：{item.vin ? item.vin : '车架号识别失败'}
                            <>
                              {
                                <a
                                  style={{
                                    color: `${
                                      item.reserveFlag
                                        ? 'rgba(39, 171, 17, 1)'
                                        : 'rgba(255, 105, 32, 1)'
                                    }`,
                                  }}
                                >
                                  {item.status === 0 && '已检测'}
                                  {item.status === 1 && '未检测'}
                                  {item.status === 2 && '已售出'}
                                  {/* {!item.reserveFlag && '未下单'} */}
                                </a>
                              }
                            </>
                          </div>
                          <div className={styles.media_text_1}>
                            {item.detectOrgName && (
                              <>检测机构：{item.detectOrgName}</>
                            )}
                          </div>
                          <div className={styles.media_text_1}>
                            {item.notReserveReason && !item.reserveFlag && (
                              <>不可预约理由：{item.notReserveReason}</>
                            )}
                          </div>
                          {`${item.reserveFlag}` != '' && (
                            <div className={styles.media_text_1}>
                              <>是否下单：{item.reserveFlag ? '是' : '否'}</>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <Empty description="暂无车源信息" />
              )}
            </Tabs.Tab>
          </Tabs>
          <div className={`${styles.footer} ${styles.step_menu}`}>
            <div
              className={styles.footer_button}
              onClick={() => {
                setCompanyVisible(true);
                setCompanyId({ companyId: query.rowId });
              }}
            >
              选择主商户
            </div>
            <div
              className={styles.footer_button_0}
              onClick={() => {
                const data = companyDeatail.mCompanyInfo;
                data.companyId = companyDeatail.mCompanyInfo.id;
                data.partnerInfoList = companyDeatail.mCarDealerPartners;
                data.mediaInfoList = companyDeatail.mCarDealerMedia;
                data.competitorList = companyDeatail.carDealerCompetitors;
                console.log(companyDeatail);
                history.push('./detailEdit', data);
              }}
            >
              编辑信息
            </div>
            <div
              className={styles.footer_button}
              onClick={() => {
                // history.push("./programme")
                const data = companyDeatail.mCompanyInfo;
                data.companyId = companyDeatail.mCompanyInfo.id;
                console.log(data);
                history.push('./programme', data);
              }}
            >
              拜访计划
            </div>
            <div
              className={styles.footer_button}
              onClick={() => {
                // history.push("./programme")
                localStorage.removeItem('values');
                localStorage.removeItem('visitContent');
                localStorage.removeItem('companyId');
                localStorage.removeItem('belongMarketId');
                localStorage.removeItem('localIdsImg');
                localStorage.removeItem('pointImg');
                localStorage.removeItem('advertiseImg');
                localStorage.removeItem('powerModelImg');
                localStorage.removeItem('premiumImg');
                localStorage.removeItem('doorway');
                localStorage.removeItem('point');
                localStorage.removeItem('advertise');
                localStorage.removeItem('powerModel');
                localStorage.removeItem('premium');
                const data = companyDeatail.mCompanyInfo;
                data.companyId = companyDeatail.mCompanyInfo.id;
                data.homeSee = true;
                data.entranceType = 2;
                localStorage.setItem(
                  'companyId',
                  companyDeatail.mCompanyInfo.id,
                );
                delete data.id;
                console.log(data);
                history.push('./see', data);
              }}
            >
              拜访
            </div>
          </div>
        </Card>
      </div>
      <Merchant
        visit={true}
        cityFlag={false}
        checkFlag={false}
        ButtonFlag={ButtonFlag}
        nameVisible={companyVisible}
        onSubmit={handelCompanyName}
        onCancel={handleCompanyNameCancel}
      />
      <ImageViewer
        image={allImage}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />
      <Modal
        visible={visible}
        closeOnAction
        closeOnMaskClick
        title={'集采记录'}
        content={<></>}
        actions={[
          {
            key: 'confirm',
            text: '确定',
          },
        ]}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        onAction={() => {
          setVisible(false);
        }}
      ></Modal>
    </div>
  );
}
