import { Button, Checkbox, CheckList, InfiniteScroll, List, SearchBar } from "antd-mobile";
import SpinLoading from "antd-mobile/es/components/spin-loading";
import Toast from "antd-mobile/es/components/toast";
import { useEffect, useState } from "react";
import { history,useLocation } from "umi";
import Navbar from "../utils/Navbar";
import { marketList } from "./service";
import styles from './index.less';
import { getVisitTemplate } from "../report/service";
export default function Market() {
  const [checkList, setCheckList] = useState<any>([])
  const [value, setValue] = useState<any>([])
  const [marketName, setmarketName] = useState<string>('')
  const [pageNo, setpageNo] = useState<number>(1)
  const [hasMore, setHasMore] = useState(true)
  const location = useLocation();
  const dataFlag = location.state;

  const initValue = async()=>{
    const append = await getVisitTemplate({})
    setCheckList((val: any)=>[...val,...append])
    setHasMore(append.length > 0)
  }
  const onChange = (v: any)=>{
    console.log(v);
    setValue(v)
    
  }
  const onSearch = async (value: string) => {
    setpageNo(1)
    setmarketName(value)
    // const msg = await ListMore(params);
    // if (msg.retCode === 0) {
    //   setData(msg.data.datas);
    //   console.log(msg.data.datas);
      
    // }
  };
  useEffect(() => {
    initValue()
  }, []);
  return(
    <div className={styles.market}>
      <Navbar name= {"选择产品"}></Navbar>
      <div className={styles.search}>
        <SearchBar
          onSearch={onSearch}
          placeholder="请输入产品名"
        />
      </div>
      <CheckList multiple={false} onChange={onChange}>
        {checkList.length>0 && checkList.map((item:any)=>{
          if(item.name.indexOf(marketName)>-1){
            return<CheckList.Item value={item}>{item.name}</CheckList.Item>
          }
        })}
      </CheckList>
      {/* <InfiniteScroll loadMore={(isRetry)=>{
        return initValue({
          pageNo:pageNo,
          pageSize:20,
        });
      }} hasMore={hasMore} /> */}
      <div style={{height:"60px"}}></div>
      <div className={styles.footer}>
        <div>
          已选：{value.length} 个
        </div>
        <div className={styles.button} >
          <Button color='primary' fill='outline' onClick={()=>{
            if(dataFlag){
              const dataFlags:any = dataFlag
              history.replace("./see",dataFlags)
            }
          }}>
            取消
          </Button>
          <Button size='middle' color='primary' onClick={()=>{
            if(dataFlag){
              const dataFlags:any = dataFlag
              dataFlags.belongMarketName = value[0].name
              dataFlags.belongMarketId = value[0].topTemplateId
              history.replace("./see",value[0])
            }
          }}>
            确定
          </Button>
        </div>
      </div>
    </div>
  )
}