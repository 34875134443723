import styles from './index.less';
import React, { useEffect, useRef, useState } from 'react';
import { Button, NavBar, Popup, Switch, Tabs, Toast } from 'antd-mobile/es';
import { history, useLocation } from 'umi';
import 商户中心 from './img/1.png';
import 拜访中心 from './img/2.png';
import 报告中心 from './img/3.png';
import 集采中心 from './img/4.png';
import 公告 from './img/公告.png';
import { getStatistics, login } from '../login/service';
import Navbar from '../utils/Navbar';
import '../common.css';
import dayjs from 'dayjs';
import { dateFormat } from '../utils/util';
import { companyRemarkNumber, getSummaryNumber } from './service';
import { BaseTable } from 'ali-react-table';
import Login from '../login';
export default function IndexPage() {
  const [checkoutFlag, setCheckoutFlag] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [operation, setOperation] = useState<string>('');
  const [personalData, setPersonalData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [summaryNumber, setSummaryNumber] = useState<any>([]);
  const [remarkNumber, setRemarkNumber] = useState<any>([]);
  const [monthlyTarget, setMonthlyTarget] = useState<any>([]);
  const [remarkFlag, setRemarkFlag] = useState<boolean>(false);
  const [checkoutState, setCheckoutState] = useState<string>('0');
  const [htmlVisible, setHtmlVisible] = useState<boolean>(false);
  const infoRef = useRef();
  const location = useLocation();
  // @ts-ignore
  const query = location?.query;
  const token = localStorage.getItem('token');
  const name = localStorage.getItem('name');
  const position = localStorage.getItem('position');
  const avatar = localStorage.getItem('avatar');
  const testContent = (key: string) => {
    let content = 0;
    if (monthlyTarget[`${key}`]) {
      if (Number.isInteger(monthlyTarget[`${key}`])) {
        content = monthlyTarget[`${key}`];
      } else {
        content = monthlyTarget[`${key}`].toFixed(1);
      }
    }
    return content;
  };
  const back = () => {};
  const loginToken = async (token: any) => {
    login({ token: token })
      .then((msg) => {
        if (msg.retCode === 0) {
          setPersonalData(msg.data);
          localStorage.setItem('name', msg.data.name);
          localStorage.setItem('position', msg.data.position);
          localStorage.setItem('userId', msg.data.userId);
          localStorage.setItem('avatar', msg.data.avatar);
          let flag = false;
          if (msg.data.isLeaderInDept) {
            let list = JSON.parse(msg.data.isLeaderInDept);
            if (list.length > 0) {
              list.map((item: any) => {
                if (item === 1 || item === '1') {
                  flag = true;
                }
              });
            }
            if (msg.data.isLeaderInDept === '[1]') {
              flag = true;
            }
          }
          if (msg.data.batchBuyAuthority) {
            localStorage.setItem(
              'batchBuyAuthority',
              msg.data.batchBuyAuthority,
            );
          }
          if (msg.data.userType === 0 || msg.data.userType === '0') {
            flag = true;
          }
          if (msg.data.allotInspectorFlag) {
            localStorage.setItem('allotInspectorFlag', '1');
          }
          if (flag) {
            localStorage.setItem('isLeaderInDept', '1');
          } else {
            localStorage.removeItem('isLeaderInDept');
          }
          localStorage.setItem('token', query.code);
          statements(false);
          getSummary(token);
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        setLoading(false)
      })
      .catch((error) => [
        Toast.show({
          icon: 'fail',
          content: '获取用户信息失败，请退出重新进入',
        }),
        setLoading(false)
      ]);
    // const msg = await login({token:token})
  };
  const statements = (teamFlag: boolean) => {
    setLoading(true)
    getSummary(query.code ? query.code : localStorage.getItem("token"))
    getStatistics({
      teamFlag: teamFlag,
    })
      .then((msg) => {
        if (msg.retCode === 0) {
          setData(msg.data);
          if (!teamFlag) {
            let list = JSON.stringify(msg.data);
            localStorage.setItem('visit', list);
          }
          console.log(msg.data);
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        setLoading(false)
      })
      .catch((error) => [
        Toast.show({
          icon: 'fail',
          content: '获取用户信息失败，请退出重新进入',
        }),
        setLoading(false)
      ]);
  };
  const onChange = (v: any) => {
    setCheckoutState(v);
    if (data) {
      if (v === '0' && data.ProductMonthlyGoal) {
        setMonthlyTarget(data.ProductMonthlyGoal);
      }
      if (v === '0' && data.VehicleConditionMonthlyGoal) {
        setMonthlyTarget(data.VehicleConditionMonthlyGoal);
      }
    }
  };
  const getSummary = (token: any) => {
    getSummaryNumber({
      token: token,
    })
      .then((msg) => {
        if (msg.retCode === 0) {
          const day = new Date(
            dayjs(new Date()).format('YYYY-MM-DD') + ' 17:00:00',
          );
          console.log(day);
          const oldDate = new Date();
          msg.data.map((item: any) => {
            if (day > oldDate) {
              if (item.type === 0) {
                console.log(item.statisticsVo);
                setSummaryNumber(item.statisticsVo);
                let imageShopNum = item.statisticsVo.imageShopNum
                  ? item.statisticsVo.imageShopNum
                  : 0;
                let newPurchasesNum = item.statisticsVo.newPurchasesNum
                  ? item.statisticsVo.newPurchasesNum
                  : 0;
                let repurchaseNum = item.statisticsVo.repurchaseNum
                  ? item.statisticsVo.repurchaseNum
                  : 0;
                let vehicleSourceNum = item.statisticsVo.vehicleSourceNum
                  ? item.statisticsVo.vehicleSourceNum
                  : 0;
                let num =
                  imageShopNum +
                  newPurchasesNum +
                  repurchaseNum +
                  vehicleSourceNum;
                if (num < 12) {
                  let list = JSON.stringify(item.statisticsVo);
                  localStorage.setItem('summaryNumber', list);
                  setOperation('今日');
                } else {
                  localStorage.removeItem('summaryNumber');
                }
              }
            } else {
              if (item.type === 1) {
                console.log(item.statisticsVo);
                setSummaryNumber(item.statisticsVo);
                let imageShopNum = item.statisticsVo.imageShopNum
                  ? item.statisticsVo.imageShopNum
                  : 0;
                let newPurchasesNum = item.statisticsVo.newPurchasesNum
                  ? item.statisticsVo.newPurchasesNum
                  : 0;
                let repurchaseNum = item.statisticsVo.repurchaseNum
                  ? item.statisticsVo.repurchaseNum
                  : 0;
                let vehicleSourceNum = item.statisticsVo.vehicleSourceNum
                  ? item.statisticsVo.vehicleSourceNum
                  : 0;
                let num =
                  imageShopNum +
                  newPurchasesNum +
                  repurchaseNum +
                  vehicleSourceNum;
                if (num < 12) {
                  let list = JSON.stringify(item.statisticsVo);
                  localStorage.setItem('summaryNumber', list);
                  setOperation('明日');
                } else {
                  localStorage.removeItem('summaryNumber');
                }
              }
            }
          });
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
      })
      .catch((error) => [
        Toast.show({
          icon: 'fail',
          content: '获取目标数据失败！',
        }),
      ]);
    // companyRemarkNumber({})
    //   .then((msg) => {
    //     if (msg.retCode === 0) {
    //       setRemarkNumber(msg.data);
    //     } else {
    //       Toast.show({
    //         icon: 'fail',
    //         content: msg.retMsg,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     Toast.show({
    //       icon: 'fail',
    //       content: '获取备注信息失败！',
    //     });
    //   });
  };
  useEffect(() => {
    // setLoading(true)
    if (query.code) {
      if (localStorage.getItem('token') != query.code) {
        loginToken(query.code);
      } else {
        statements(false);
        getSummary(query.code);
      }
    } else {
      statements(false);
      getSummary(localStorage.getItem('token'));
    }
    const date = dateFormat(new Date(),"Y-MM-dd");
    if((!localStorage.getItem("dateTime")) || (localStorage.getItem("dateTime") != date)){
      setHtmlVisible(true)
      localStorage.setItem("dateTime",date)
    }
  }, []);
  useEffect(() => {
    // @ts-ignore
    infoRef.current = !checkoutFlag;
  }, [checkoutFlag]);
  const textEnum = [
    { name: '检测产品月度目标', value: 0 },
    { name: '数据车况月度目标', value: 1 },
  ];

  const columns: any = [
    { code: 'typeName', name: '动作', align: 'left' },
    {
      code: 'goal',
      name: '目标',
      align: 'center',
      render: (_: any, record: any) => {
        return <div>{record.goal || 0}</div>;
      },
    },
    {
      code: 'planned',
      name: '计划',
      align: 'center',
      render: (_: any, record: any) => {
        return <div>{record.planned || 0}</div>;
      },
    },
    {
      code: 'complete',
      name: '实际',
      align: 'center',
      render: (_: any, record: any) => {
        return <div>{record.complete || 0}</div>;
      },
    },
    {
      code: 'clinch',
      name: '成交',
      align: 'center',
      render: (_: any, record: any) => {
        return <div>{record.clinch || 0}</div>;
      },
    },
  ];
  return (
    <div className={styles.all}>
      {loading && (
        <Login
          className={styles.loading}
          style={{ height: '90%', bottom: '0' }}
        ></Login>
      )}
      <div className={styles.page}>
        <div className={styles.title}>
          <div className={styles.home}>
            <Navbar name="首页"></Navbar>
          </div>
          <div className={styles.personal}>
            <div className={styles.avatar}>
              <img
                src={personalData.avatar ? personalData.avatar : avatar}
                width={50}
              />
              <div>
                <div className={styles.avatar_p}>
                  hi，{personalData.position ? personalData.position : position}{' '}
                  {personalData.name ? personalData.name : name}
                </div>
                <div className={styles.avatar_r}>
                  {dayjs().format('YYYY年MM月DD日')} 星期
                  {dateFormat(dayjs(), 'W')}
                </div>
              </div>
            </div>
            <div>
              <Button
                size="small"
                block
                shape="rounded"
                color="primary"
                style={{ fontSize: '12px' }}
                onClick={() => {
                  const data: any = { homeSee: true, entranceType: 0 };
                  if (localStorage.getItem('visitId') != '-1') {
                    localStorage.removeItem('values');
                    localStorage.removeItem('visitContent');
                    localStorage.removeItem('companyId');
                    localStorage.removeItem('belongMarketId');
                    localStorage.removeItem('localIdsImg');
                    localStorage.removeItem('pointImg');
                    localStorage.removeItem('advertiseImg');
                    localStorage.removeItem('powerModelImg');
                    localStorage.removeItem('premiumImg');
                    localStorage.removeItem('doorway');
                    localStorage.removeItem('point');
                    localStorage.removeItem('advertise');
                    localStorage.removeItem('powerModel');
                    localStorage.removeItem('premium');
                  }
                  localStorage.setItem('visitId', '-1');
                  history.push('/see', data);
                }}
              >
                即时拜访
              </Button>
              <Button
                size="small"
                block
                shape="rounded"
                color="primary"
                style={{
                  background: 'rgba(17, 175, 21, 1)',
                  marginTop: '5px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  const data: any = { homeSee: true, entranceType: 0 };
                  localStorage.setItem('visitId', '-1');
                  history.push('/programme', data);
                }}
              >
                拜访计划
              </Button>
            </div>
          </div>
          <div className={styles.functionality}>
            <div
              onClick={() => {
                history.push('./merchant');
              }}
            >
              <img src={商户中心} width={40} />
              <div>商户管理</div>
            </div>
            <div
              onClick={() => {
                history.push('./PayAVisit');
              }}
            >
              <img src={拜访中心} width={40} />
              <div>拜访管理</div>
            </div>
            <div
              onClick={() => {
                history.push('./report');
              }}
            >
              <img src={报告中心} width={40} />
              <div>报告管理</div>
            </div>
            <div
              onClick={() => {
                history.push('./collect');
              }}
            >
              <img src={集采中心} width={40} />
              <div>集采中心</div>
            </div>
            <div
              onClick={() => {
                history.push('./dataCentres');
              }}
            >
              <img src={报告中心} width={40} />
              <div>数据中心</div>
            </div>
          </div>
          <div className={styles.bulletin} onClick={()=>{
            setHtmlVisible(true);
          }}>
            <img src={公告} height={16} width={30} />
            <div>
              {operation != '' ? (
                <span style={{ color: 'red' }}>
                  {operation}
                  计划未完成
                </span>
              ) : (
                <span>拜访目标已完成！！！</span>
              )}
            </div>
            <div>{dayjs().format('YYYY/MM/DD')}</div>
          </div>
        </div>
        <div className={styles.theData}>
          <div className={styles.header}>
            <div>数据统计</div>
            <div className={styles.checkout}>
              <div
                onClick={() => {
                  setCheckoutFlag(!checkoutFlag);
                  statements(!checkoutFlag);
                }}
                className={`${checkoutFlag ? styles.checkout_0 : ''}`}
              >
                团队
              </div>
              <div
                onClick={() => {
                  setCheckoutFlag(!checkoutFlag);
                  statements(!checkoutFlag);
                }}
                className={`${!checkoutFlag ? styles.checkout_0 : ''}`}
              >
                个人
              </div>
            </div>
          </div>
          {/* <div style={{width:"100%"}}>
            <iframe width={"100%"} src={"https://bdtest.wzyanche.com/维真检测业务.html"} frameBorder="0"></iframe>
          </div> */}
          <div className={`${styles.content} ${styles.content_0}`}>
            <div className={styles.content_titile}>我的待办</div>
            <div className={styles.text}>
              <div className={styles.text_0}>
                <div className={styles.text_title_1}>明日拜访计划</div>
                <div className={styles.text_title_0}>
                  {data.tomorrowVisitPlan || 0}
                </div>
              </div>
              <div className={styles.text_0}>
                <div className={styles.text_title_1}>拜访签到</div>
                <div className={styles.text_title_0}>
                  {data.signedIn || 0}
                </div>
              </div>
              <div className={styles.text_0}>
                <div className={styles.text_title_1}>拜访备注</div>
                <div className={styles.text_title_0}>
                  {data.visitNotesNum || 0}
                </div>
              </div>
              <div className={styles.text_0}>
                <div className={styles.text_title_1}>车源拜访</div>
                <div className={styles.text_title_0}>
                  {data.carSourceVisitNum || 0}
                </div>
              </div>
              <div className={styles.text_0}>
                <div className={styles.text_title_1}>车位总数/车源待录入</div>
                <div className={styles.text_title_0}>
                  {data.parkingNum || 0}/
                  {data.vehicleSourceToBeEnteredNum || 0}
                </div>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.text_0} style={{width:'40%'}}>
                <div className={styles.text_title_1}>车源拜访目标/已建计划</div>
                <div className={styles.text_title_0}>
                  {data.carVisit || 0}/{summaryNumber.vehicleSourceNum || 0}
                </div>
              </div>
              <div className={styles.text_0}  style={{width:'40%'}}>
                <div className={styles.text_title_1}>复购目标/已建计划</div>
                <div className={styles.text_title_0}>
                  {data.rePurchase || 0}/{summaryNumber.repurchaseNum || 0}
                </div>
              </div>
              <div className={styles.text_0}  style={{width:'40%'}}>
                <div className={styles.text_title_1}>新购目标/已建计划</div>
                <div className={styles.text_title_0}>
                  {data.newPurchase || 0}/{summaryNumber.newPurchasesNum || 0}
                </div>
              </div>
              <div className={styles.text_0}  style={{width:'40%'}}>
                <div className={styles.text_title_1}>形象店目标/已建计划</div>
                <div className={styles.text_title_0}>
                  {data.store || 0}/{summaryNumber.imageShopNum || 0}
                </div>
              </div>
              <div className={styles.text_0} style={{width:'40%'}}>
                <div className={styles.text_title_1}>陌拜目标</div>
                <div className={styles.text_title_0}>
                  {data.noSee || 0}
                </div>
              </div>
              <div className={styles.text_0} style={{width:'40%'}}>
                <div className={styles.text_title_1}>注册目标</div>
                <div className={styles.text_title_0}>
                  {data.register || 0}
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.content} ${styles.content_0}`}>
            <div className={styles.content_titile}>今日数据</div>
            <div className={styles.content_table}>
              <BaseTable
                dataSource={
                  data.visitPlanVos && data.visitPlanVos.length > 0
                    ? data.visitPlanVos
                    : [
                        { typeName: '车源拜访' },
                        { typeName: '复购拜访' },
                        { typeName: '新购拜访' },
                        { typeName: '新购形象店' },
                        { typeName: '复购形象店' },
                        { typeName: '陌拜' },
                        { typeName: '注册拜访' },
                        { typeName: '预约实检' },
                        { typeName: '预约检测' },
                        { typeName: '实查' },
                      ]
                }
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
      
      <Popup
          visible={htmlVisible}
          bodyStyle={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            height: '100vh',
            paddingBottom: '20px',
          }}
        >
          <div
            className={styles.home_popup}
            onClick={() => {
              setHtmlVisible(false);
            }}
          >
            <div className={styles.home_0}></div>
            <div className={styles.home_x}>×</div>
          </div>
          <div className={styles.popUp}>
            <div className={styles.popUp_information}>
              <div className={styles.popUp_information_title}>维真检测业务</div>
              <div>
                <div className={styles.popUp_information_content}>
                  <div style={{backgroundColor: "rgba(179, 179, 179, 1)"}} className={styles.popUp_information_content_0}>slogan</div>
                  <div>维真验车、真实车况</div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className={styles.popUp_information_content}>
                  <div className={styles.popUp_information_content_0}>RTB</div>
                  <div>举升检测</div>
                  <div>真实车况</div>
                  <div>终身保障</div>
                  <div>务效率</div>
                </div>
                <div className={styles.popUp_information_content}>
                  <div className={styles.popUp_information_content_0}>价值阐述</div>
                  <div>持证检测师、专业检测工具、更促进交易的检测标准</div>
                  <div>车况亮点图片展示</div>
                  <div>终身回购保障</div>
                  <div>一键下单、实时响应</div>
                </div>
              </div>
            </div>
            <div className={styles.headline}>卖点分析</div>
            <div className={styles.popUp_information}>
              <div>
                <div className={`${styles.popUp_information_0_content} ${styles.popUp_information_1_content}`}>
                  <div className={styles.popUp_information_0_content_0}>类别</div>
                  <div className={styles.popUp_information_0_content_1}>卖点</div>
                  <div className={styles.popUp_information_0_content_1}>卖点解析</div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>卖⻋效率</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1、报告简洁、便于销售</div>
                    <div>2、车辆亮点图片展示</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    1、检测报告车辆亮点图片展示，对于车况会更好的展示在买家面前，能够促进成交
                  </div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>车辆保障</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1. 大平台，有保障</div>
                    <div>2. 三⾮终⾝回购</div>
                    <div>3.人保保单延保</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1、检测合格车辆享终身回购</div>
                    <div>2、发动机和变速箱延保（1年12万公里）由人保出具保单</div>
                  </div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>专业设备</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.170项检测工具</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.通过专业170项检测工具（【举升机 、内窥镜、漆膜仪、刹车油检测仪、OBD等） 对车辆进行深度检测</div>
                  </div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>服务效率</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.高效、实时检测</div>
                    <div>2.服务及时，专人对接</div>
                    <div>3.无需加急费</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1、检测及时性，当天送检车辆必须检测完成、检测服务性，上门接送车，送报告，签署合同</div>
                    <div>2、根据买家要求上门检测，更有灵活性</div>
                    <div>3、小程序预约下单，操作便捷</div>
                    <div>4、 ⾼效的检测服务，无需加急费，实时检测</div>
                  </div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>增值能⼒</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.多平台打通</div>
                    <div>2.国家网站可查的司法鉴定资质</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.国家网站【】可查的司法鉴定资质</div>
                    <div>2.懂车帝的品牌效应，增加曝光率，每车必检可以增加邀约客户到店率</div>
                    <div>3.一键发车，多了卖车渠道</div>
                    <div>4.司法鉴定资质可帮助交易维权、避免退一赔三</div>
                  </div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>检测质量</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.更促进交易的检测标准</div>
                    <div>2.持证检测师、170项检测工具备</div>
                    <div>3.官方渠道【出险、公里数、维保】数据查询</div>
                    <div>4.国家网站可查的司法鉴定资质</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.更促进交易的检测标准，专业设备、持证检测师</div>
                    <div>2.官方渠道【出险、公里数、维保】查询，保证车况的真实性</div>
                    <div>3.产品丰富，客户可以根据自己的需求进行下单检</div>
                    <div>4.国家网站【】可查的司法鉴定资质</div>
                    <div>5.检测结果准确，失误率不到万分之二</div>
                    <div>6.真实车况，更促进交易的检测标准</div>
                    <div>7.专业检测系统、检测报告亮点展示</div>
                  </div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>深度检测</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.举升检测</div>
                    <div>2.内窥镜检测</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.每台必举升底盘+重要部位内窥镜拍照</div>
                    <div>2.重要部位必拆卸</div>
                    <div>3.每车必查出险记录</div>
                    <div>4.深度检测、出具明确三非报告</div>
                  </div>
                </div>
                <div className={styles.popUp_information_0_content}>
                  <div className={styles.popUp_information_0_content_0}>购⻋决策</div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.报告亮点展示</div>
                  </div>
                  <div className={styles.popUp_information_0_content_1}>
                    <div>1.报告简洁、便于销售</div>
                    <div>2.车况销售亮点展示</div>
                    <div>3.报告结论清晰</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
    </div>
  );
}
