
// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function save(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/info/save', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
export async function parseThePicture(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/img/parseThePicture', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
export async function getQyWxImg(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>(`/api/qy/wx/img/getQyWxImg?mediaId=${data.mediaId}`, {
    method: 'POST',
    ...(options || {}),
  });
}
export async function vehicleSourceNotes(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/label/vehicleSourceNotes', {
    data:data,
    method: 'POST',
    ...(options || {}),
  },);
}
export async function visitImgDelete(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/img/delete', {
    data:data,
    method: 'POST',
    ...(options || {}),
  },);
}
export async function verification(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/img/verification', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
