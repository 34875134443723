// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function carBrand(data: any, options?: { [key: string]: any }) {
  const username = 'zcT8yVdOR03rZmC61gznY0v6';
  const password = '8KPX7jDtLevDrPtKODVdPw36zZ02cAwO';

  const basicAuth = Buffer.from(`${username}:${password}`).toString('base64');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${basicAuth}`,
    },
    body: JSON.stringify(data),
    ...(options || {}),
  };

  try {
    const response = await fetch('/wz/open/carBrand', requestOptions);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}