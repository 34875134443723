// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function statementList(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/statement/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function leaderboardList(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/plan/leaderboard/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function bookingLeaderboardList(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/booking/leaderboard/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function detectionStatisticList(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/detection/statistic/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getUserCheckByCountCircle(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/circle/getUserCheckByCountCircle', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getRegionRelationColor(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/region/color/getRegionRelationColor', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getMonthlyStatistics(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/monthly/statistics/getMonthlyStatistics', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function saveRepurchase(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/business/report/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function reportList(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/verify/report/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function auditData(data:any, options?: { [key: string]: any }) {
  return await request<{
    extra: any;
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/data/check/auditData', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}