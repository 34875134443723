import { FC, useEffect, useRef, useState } from 'react';
import styles from './index.less';
import { Helmet, history, useLocation } from 'umi';
import { Button, CheckList, InfiniteScroll, Popup, SearchBar } from 'antd-mobile';
import { userList } from '../merchant/service';
import { marketList } from '../market/service';
type NameLists = {
  nameVisible: boolean,
  value: boolean,
  list: any,
  onCancel: () => void;
  onSubmit: (values: any) => void;
};
const NameList: FC<NameLists> = (props) => {
  const {nameVisible,onCancel,onSubmit,value,list} = props;
  const [nameList, setNameList] = useState<any[]>([]);
  const [pageNoName, setpageNoName] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true)
  const [nameValue, setNameValue] = useState<any>([]);
  const infoRef:any = useRef();
  const initValue = async(params:any)=>{
    setHasMore(false)
    let temp = nameList
    if(params.pageNo === -1){
      // temp = []
      setNameList([])
      params.pageNo = 1
    }
    if(infoRef.current){
      params.marketName = infoRef.current
    }
    let num =  params.pageNo + 1
    setpageNoName(num)
    const append = await marketList(params)
    setNameList((val: any)=>[...val,...append])
    setHasMore(append.length > 0)
  }
  const onSearchName = async (name: string) => {
    const params: any = {
      pageNo: -1,
      pageSize: 20,
    };
    infoRef.current = name
    initValue({...params,name,flag:true});
  };
  const onChange = (v: any)=>{
    console.log(v);
    setNameValue(v)
  }
  // useEffect(()=>{
  //   if(nameVisible){
  //     setNameValue(list)
  //   }
  // },[nameVisible])
  return(
    <div className={styles.market}>
      <Popup position="right" visible={nameVisible} className="123123" bodyClassName={styles.bodyClass}>
        <div className={styles.search}>
          <SearchBar
            onSearch={onSearchName}
            placeholder="请输入市场名"
          />
        </div>
        <div className={styles.container}>
        {nameList.length > 0 && (
          <div className={styles.checkList}>
            <CheckList multiple={value} onChange={onChange} value={nameValue}>
              { nameList.length > 0 && nameList.map((item:any,key:number)=>{
                return<CheckList.Item value={item}>{item.marketName}</CheckList.Item>
              })}
            </CheckList>
          </div>
        )}
        <InfiniteScroll loadMore={ async (isRetry)=>{
              await initValue({
                pageNo:pageNoName,
                pageSize:20,
              });
            }} hasMore={hasMore} />
          </div>
        <div className={styles.footer}>
          <div>
            已选：{nameValue.length || 0} 个
          </div>
          <div className={styles.button} >
            <Button color='primary' fill='outline' onClick={()=>{
              onCancel()
            }}>
              取消
            </Button>
            <Button size='middle' color='primary' onClick={()=>{
              onSubmit(nameValue)
            }}>
              确定
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  )
}

export default NameList;