// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
import { sleep } from 'antd-mobile/es/utils/sleep'


export async function ListMore(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/car/info/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getUserInfo(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/user/getUserInfo', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function Lists(
  data: any,
  options?: { [key: string]: any },
) {
  let count = 1
  const params = data;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/company/car/info/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if(msg.retCode === 0) {
    return {
      success: true,
      data: msg.data.datas,
      total: msg.data.totalCount,
    };
  } else {
    return []
  }
}