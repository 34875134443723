
// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function relationList(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/company/relation/list', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
export async function vehicleSourceSave(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/remark/record/vehicleSourceSave', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
export async function getVehicleSource(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/remark/record/getVehicleSource', {
    data:data,
    method: 'POST',
    ...(options || {}),
  });
}
