// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function getRecord(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/getRecord', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function regionConfirm(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/regionConfirm', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function modifyPrice(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/modifyPrice', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function config(options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/data/config.json', {
    method: 'get',
    ...(options || {}),
  });
}