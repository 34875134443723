import { Button, Toast ,Image, Card, Popup, Form, List, Radio, TextArea, ImageViewer, Modal} from "antd-mobile";
import { Children, useEffect, useState } from "react";
import {history, useLocation } from "umi";
import Navbar from "../utils/Navbar";
import styles from './index.less';
import { getVehicleSource, relationList, vehicleSourceSave } from "./service";
import initMerchant from './img/initMerchant.png';
import { labellist } from "../programme/service";
import { dateFormat } from "../utils/util";
import dayjs from "dayjs";
import { parseResultName } from "../PayAVisit/newsPlan";
import { cancel, recordSave } from "../PayAVisit/service";
import { visitClick } from "../awaitingDetail/service";

export default function callOnSb() {
  const [form] = Form.useForm();
  const location = useLocation();
  const dataFlag:any = location.state;
  const [options, setoptions] = useState<any>([])
  const [MeritList, setMeritList] = useState<any>([]);
  const [initcarLabelIds, setinitcarLabelIds] = useState<any>([]);
  const [remarkform] = Form.useForm();
  const [visible4, setVisible4] = useState<boolean>(false);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [imageVisibles, setImageVisibles] = useState<boolean>(false);
  const [indexs, setIndexs] = useState<number>(0);
  const [remarkValue, setRemarkValue] = useState<string>('');
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [allImage, setAllImage] = useState<any>([]);
  const [allImages, setAllImages] = useState<any>([]);
  const [remark, setRemark] = useState<any>([]);
  const [remarks, setRemarks] = useState<any>([]);
  const [moreMap, setMoreMap] = useState<any>({});
  const [remarkRecordId, setremarkRecordId] = useState<any>([]);
  const [mCarDealerVisitId, setmCarDealerVisitId] = useState<any>([]);
  const [visitPlanId, setVisitPlanId] = useState<number>();
  const avatar = localStorage.getItem('avatar');
  // @ts-ignore
  const query = location?.query;
  const initValue = async (id:number) => {
    const res = await labellist({});
    if (res.retCode === 0) {
      setMeritList(res.data);
    }
    const msg = await relationList({
      visitId:id
    })
    if(!dataFlag.clickFlag){
      visitClick({id: dataFlag.id}).then((msg)=>{
        if (msg.retCode === 0) {
          // Toast.show({
          //   icon: 'success',
          //   content: msg.retMsg,
          // });
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
      })
      .catch(()=>{
        Toast.show({
          icon: 'fail',
          content: "查看失败",
        });
      })
    }
    // const any = await getVehicleSource({
    //   visitPanSonId:id
    // })
    let list:any = []
    if(msg.retCode === 0 && msg.data.length>0){
      msg.data.map((item:any)=>{
        if(item.mRemarkRecordDetails && item.mRemarkRecordDetails.length>0){
          item.mRemarkRecordDetails.map((child:any)=>{
            if(child.mRemarkRecordDetails && child.mRemarkRecordDetails.length>0){
              child.mRemarkRecordDetails.map((children:any)=>{
                list.push(children)
              })
            }
          })
        }
      })
      setRemark(list)
    }
    if(msg.retCode === 0){
      setoptions(msg.data)
    }else{
      Toast.show({
        icon:"fail",
        content: msg.retMsg
      })
    }
  }
  const onFinishRemark = () => {
    remarkform.validateFields().then(() => {
      // setLoading(true)
      onRemarkFinish();
    });
  };
  const onDelete = async (id: any) => {
    const values = form.getFieldsValue();
    console.log(values);

    const msg = await cancel({ id: id, cancelRemark: values.forms,status: 6 });
    if (msg.retCode === 0) {
      Toast.show({
        icon: 'success',
        content: '成功驳回计划',
      });
      form.resetFields();
      history.goBack()
      // pullListMore(checkoutFlag)
    } else {
      Toast.show({
        icon: 'fail',
        content: msg.retMsg,
      });
    }
  };
  const onRemarkFinish = async () => {
    const values = remarkform.getFieldsValue();
    if (remarkValue === '') {
      Toast.show({
        icon: 'fail',
        content: '请填写备注！',
        // duration: 0,
      });
      return;
    }
    // values.createTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    values.remarkContent = remarkValue;
    values.labelId = remarkChild.labelId;
    values.labelName = remarkChild.labelName;
    values.parentId = remarkChild.id;
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = localStorage.getItem('avatar');
    values.visitPlanId = dataFlag.id;
    values.remarkRecordId = remarkRecordId;
    let list = remark
    list.push({
      createTime:dayjs().format('YYYY-MM-DD HH:mm:ss'),
      remarkContent:remarkValue,
      labelId:remarkChild.labelId,
      parentId:remarkChild.id,
      remarkLabelUserName:localStorage.getItem('name'),
      visitPlanId:dataFlag.id,
      appraiseType:values.appraiseType,
      url:localStorage.getItem('avatar'),
    })
    let arr = list.reverse()
    console.log(arr);
    setRemarks(arr)
    setRemark(arr)
    recordSave(values).then((msg)=>{
      if (msg.retCode === 0) {
        Toast.show({
          icon: 'success',
          content: msg.retMsg,
        });
        setVisible4(false);
      } else {
        Toast.show({
          icon: 'fail',
          content: msg.retMsg,
        });
      }
      // setLoading(false);
    })
    .catch((error)=>{
      Toast.show({
        icon: 'fail',
        content: error,
      });
      // setLoading(false);
    })
  };
  useEffect(() => {
    if(dataFlag){
      console.log(dataFlag);
      initValue(dataFlag.id)
      if (dataFlag.carLabelIds) {
        setinitcarLabelIds(dataFlag.carLabelIds);
      }
    }
  }, []);
  const onClickValue = (item:any) => {
    const data = options 
    data.companyId = item.companyId
    data.companyName = item.companyName
    data.doorHeadPhoto = item.doorHeadPhoto
    data.visitImgVinHistory = item.visitImgVinHistory
    data.rowId = item.rowId
    data.completedFlag = item.completedFlag
    data.groupPhotoPeopleCar = item.groupPhotoPeopleCar
    data.parkingSpacesNum = item.parkingSpacesNum
    data.carSourceNum = item.carSourceNum
    history.push("./checkIn",data)

  }
  return(
    <div style={{background:"white",padding:"10px 0"}}>
      <Navbar name={'拜访签到'}></Navbar>
      {options && options.visitCompanyRelations && options.visitCompanyRelations.map((item:any)=>{
        return(
          <>
            <div className={styles.callOnSb} onClick={()=>{
                onClickValue(item)
              }}>
              <div className={styles.callOnSb_0}>
                <div className={styles.callOnSb_img}>
                    {item.doorHeadPhoto ? (
                      <Image
                        src={`${item.doorHeadPhoto}?x-image-process=image/resize,l_100`}
                        width={45}
                        height={45}
                        fit="fill"
                      />
                    ) : 
                      <Image
                        src={initMerchant}
                        width={45}
                        height={45}
                        fit="fill"
                      />
                    }
                </div>
                <div>{item.companyName}</div>
              </div>
              {item.completedFlag ? <div style={{color:"rgba(139, 140, 142, 1)",padding:"5 15px",fontSize:"12px"}}>已拜访</div> :
              <div className={styles.footer_button} >
                拜访
              </div>}
            </div>
            {item.groupPhotoPeopleCar && (
              <div className={styles.carImg}>
                <div>人车合影</div>
                <img onClick={()=>{
                  setImageVisible(true)
                  setAllImage(item.groupPhotoPeopleCar)
                }} src={item.groupPhotoPeopleCar} alt="" />
              </div>
            )}
            {item.parkingSpacesNum && (
              <div className={styles.carImg}>
                <div>车位数</div>
                <div>{item.parkingSpacesNum}</div>
              </div>
            )}
            {item.carSourceNum && (
              <div className={styles.carImg}>
                <div>车源数</div>
                <div>{item.carSourceNum}</div>
              </div>
            )}
            {item.completedFlag && (
              <div className={styles.call_text}>
                <div className={styles.call_text_0} style={{alignItems:"flex-start"}}>
                  <span>车源照片</span>
                  {item.visitImgVinList ?
                  <div className={styles.call_text_carImg}>
                  {item.visitImgVinList && item.visitImgVinList.length>0 && item.visitImgVinList.map((img:any,index:number)=>{
                    return (
                      <div className={styles.callOnSb_img}>
                        <img onClick={()=>{
                          setImageVisible(true)
                          setIndex(index)
                          setAllImage(img.url)
                        }} src={img.url} alt="" />
                        <div>
                          {img.vin && <div>车架号: {img.vin ? img.vin : "无"}</div>}
                          <div>状态：
                            {img.status === 0 &&
                              '已检测'}
                            {img.status === 1 &&
                              '未检测'}
                            {img.status === 2 &&
                              '已售出'}
                            {/* {!img.reserveFlag && '未下单'} */}
                          </div>
                          {`${img.detectOrgName}` != "" ? <div>检测机构：
                            {img.detectOrgName}
                          </div> : <div>检测机构：无</div>
                          }
                          {`${img.reserveFlag}` != "" && <div>是否预约下单：
                            {img.reserveFlag ? "是" : " 否"}
                          </div>
                          }
                          {img.notReserveReason &&  <div>不可预约理由：
                            {img.notReserveReason}
                          </div>
                          }
                        </div>
                      </div>
                    )
                  })}
                  </div>
                  :
                 <div className={styles.call_text_carImg}>
                    {item.url ? item.url.split(",").map((img:any,index:number)=>{
                      return (
                        <div className={styles.callOnSb_img}>
                          <img onClick={()=>{
                            setImageVisible(true)
                            setIndex(index)
                            setAllImage(img)
                          }} src={img} alt="" />
                        </div>
                      )
                    }) : "暂无车源照片"}
                  </div>
                  }
                </div>
                {/* <div className={styles.call_text_0}>
                  <span>是否预约验车</span>
                  <div className={styles.call_text_carImg}>{item.bookingFlag ? "是" : "否"}</div>
                </div> */}
                {/* <div className={styles.call_text_0}>
                  <span>愿意异地推荐</span>
                  <div className={styles.call_text_carImg}>{item.recommendCompanyFlag ? "是" : "否"}</div>
                </div>
                <div className={styles.call_text_0}>
                  <span>备注</span>
                  <div className={styles.call_text_carImg}>{item.remark ? item.remark : "无"}</div>
                </div> */}
                <div className={styles.remark}>
                {item.mRemarkRecordDetails &&
                  item.mRemarkRecordDetails.map(
                    (child: any, mCarIndex: number) => {
                      return (
                        <div
                          key={`mRemarkRecordDetails-${mCarIndex}-${index}`}
                        >
                          <div
                            className={styles.parent_label}
                            key={`mCarDealerVisitVo-${mCarIndex}-${index}`}
                            style={{ fontSize: '16px' }}
                          >{`${mCarIndex + 1} ${child.labelName}`}</div>
                          {child.mRemarkRecordDetails.length > 0 &&
                            child.mRemarkRecordDetails.map(
                              (pchildren: any, pcIndex: number) => {
                                return (
                                  <div>
                                    <div className={styles.footer}>
                                      <div
                                        className={styles.footer_2}
                                        key={`${pcIndex}-wait`}
                                        style={{
                                          fontSize: '12px',
                                          paddingBottom: 0,
                                          fontWeight: 'normal',
                                          display:
                                            pcIndex < 3
                                              ? 'block'
                                              : 'none',
                                        }}
                                      >
                                        <div
                                          className={`${styles.footer_2_title} ${styles.status}`}
                                        >
                                          <span
                                            className={styles.label_name}
                                          >{`${mCarIndex + 1}.${
                                            pcIndex + 1
                                          } ${
                                            pchildren.labelName
                                          }`}</span>
                                          {/* <span className={styles.status}>
                                            {pchildren
                                              .mRemarkRecordDetails[0] &&
                                              [
                                                '未选择',
                                                '已完成',
                                                '未完成',
                                                '未选择',
                                              ][
                                                pchildren
                                                  .mRemarkRecordDetails[0]
                                                  .finishType || 0
                                              ]}
                                          </span> */}
                                        </div>
                                        {/*<div className={styles.footer_2_extra}>这里是价值点的文案说明</div>*/}
                                      </div>
                                    </div>
                                    {pchildren.mRemarkRecordDetails
                                      .length > 0 &&
                                      pchildren.mRemarkRecordDetails.map(
                                        (
                                          children: any,
                                          rIndex: number,
                                        ) => (
                                          <div
                                            className={styles.footer}
                                            key={`mCarDealerVisitVo-${mCarIndex}-${index}-${pcIndex}-${rIndex}`}
                                            style={{
                                              display:
                                                moreMap[`${pcIndex}`] ||
                                                rIndex < 3
                                                  ? 'block'
                                                  : 'none',
                                            }}
                                          >
                                            {/*<div className={styles.footer_2} style={{ fontSize: '12px', paddingBottom: 0, fontWeight: 'normal', }}>*/}
                                            {/*  <div className={`${styles.footer_2_title} ${styles.status}`} >*/}
                                            {/*      <span className={ styles.label_name } >{`${mCarIndex + 1}.${ pcIndex + 1 } ${ children.labelName }`}1</span>*/}
                                            {/*      <span className={ styles.status } >*/}
                                            {/*        已完成*/}
                                            {/*      </span>*/}
                                            {/*  </div>*/}
                                            {/*  /!*<div className={styles.footer_2_extra}>这里是价值点的文案说明</div>*!/*/}
                                            {/*</div>*/}
                                            <div
                                              className={styles.remark}
                                            >
                                              <div
                                                className={
                                                  styles.remark_content
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.remark_content_header
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.remark_content_header_staff
                                                    }
                                                    style={{
                                                      position:
                                                        'relative',
                                                    }}
                                                  >
                                                    <img
                                                      className={
                                                        styles.remark_content_header_staff_0
                                                      }
                                                      src={children.url}
                                                      style={{
                                                        position:
                                                          'static',
                                                      }}
                                                      alt=""
                                                    />
                                                    <div
                                                      className={
                                                        styles.remark_content_header_staff_1
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          styles.remark_content_header_staff_1_name
                                                        }
                                                      >
                                                        {children.remarkLabelUserName
                                                          ? children.remarkLabelUserName
                                                          : '-'}{' '}
                                                        <span>
                                                          {parseResultName(
                                                            children.appraiseType,
                                                          )}
                                                        </span>
                                                        {/* {children.remarkLabelUserName === localStorage.getItem("name") &&
                                                          <span>
                                                            自评
                                                          </span>
                                                        } */}
                                                      </div>
                                                      <div
                                                        className={
                                                          styles.remark_content_header_staff_1_time
                                                        }
                                                      >
                                                        {children.createTime
                                                          ? dateFormat(
                                                              children.createTime,
                                                              'yyyy-MM-dd hh:mm:ss',
                                                            )
                                                          : '-'}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.remark_content_header_0
                                                    }
                                                    onClick={() => {
                                                      console.log(remark);
                                                      
                                                      remarkform.resetFields();
                                                      setVisible4(true);
                                                      setRemarkChild(
                                                        children,
                                                      );
                                                      setmCarDealerVisitId(
                                                        item.id,
                                                      );
                                                      setRemarkValue('');
                                                      setremarkRecordId(
                                                        child.id,
                                                      );
                                                      setVisitPlanId(item.id)
                                                    }}
                                                  >
                                                    批注
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    styles.remark_content_foot
                                                  }
                                                >
                                                  {children.remarkContent}
                                                </div>
                                              </div>
                                              {remarks && remarks.length > 0 && remarks.map((remarkItem:any,key:number)=>{
                                                  if(remarkItem.parentId === children.id){
                                                    return (
                                                      <div
                                                        key={key}
                                                        style={{
                                                          background:
                                                            '#EFF3F9',
                                                          marginLeft:
                                                            '50px',
                                                          marginBottom:
                                                            '10px',
                                                          borderRadius:
                                                            '8px',
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            styles.remark_p
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              styles.remark_content
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles.remark_content_header
                                                              }
                                                            >
                                                              <div
                                                                className={
                                                                  styles.remark_content_header_staff
                                                                }
                                                              >
                                                                <img
                                                                  className={
                                                                    styles.remark_content_header_staff_0
                                                                  }
                                                                  src={
                                                                    remarkItem.url
                                                                  }
                                                                  style={{
                                                                    position:
                                                                      'static',
                                                                  }}
                                                                  alt=""
                                                                />
                                                                <div
                                                                  className={
                                                                    styles.remark_content_header_staff_1
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content_header_staff_1_name
                                                                    }
                                                                  >
                                                                    {
                                                                      remarkItem.remarkLabelUserName
                                                                    }{' '}
                                                                    <span>
                                                                      {' '}
                                                                      {parseResultName(
                                                                        remarkItem.appraiseType,
                                                                      )}{' '}
                                                                    </span>
                                                                    {remarkItem.remarkLabelUserName === children.remarkLabelUserName &&
                                                                      <span>
                                                                        自评
                                                                      </span>
                                                                    }
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      styles.remark_content_header_staff_1_time
                                                                    }
                                                                  >
                                                                    {remarkItem.createTime
                                                                      ? dateFormat(
                                                                        remarkItem.createTime,
                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                        )
                                                                      : '-'}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                styles.remark_child
                                                              }
                                                            >
                                                              {
                                                                remarkItem.remarkContent
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                              })}
                                              {children.mRemarkRecordDetails &&
                                                children
                                                  .mRemarkRecordDetails
                                                  .length > 0 &&
                                                children.mRemarkRecordDetails.map(
                                                  (remarkChild: any,key:number) => {
                                                    return (
                                                      <div
                                                        key={key}
                                                        style={{
                                                          background:
                                                            '#EFF3F9',
                                                          marginLeft:
                                                            '50px',
                                                          marginBottom:
                                                            '10px',
                                                          borderRadius:
                                                            '8px',
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            styles.remark_p
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              styles.remark_content
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles.remark_content_header
                                                              }
                                                            >
                                                              <div
                                                                className={
                                                                  styles.remark_content_header_staff
                                                                }
                                                              >
                                                                <img
                                                                  className={
                                                                    styles.remark_content_header_staff_0
                                                                  }
                                                                  src={
                                                                    remarkChild.url
                                                                  }
                                                                  style={{
                                                                    position:
                                                                      'static',
                                                                  }}
                                                                  alt=""
                                                                />
                                                                <div
                                                                  className={
                                                                    styles.remark_content_header_staff_1
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content_header_staff_1_name
                                                                    }
                                                                  >
                                                                    {
                                                                      remarkChild.remarkLabelUserName
                                                                    }{' '}
                                                                    <span>
                                                                      {' '}
                                                                      {parseResultName(
                                                                        remarkChild.appraiseType,
                                                                      )}{' '}
                                                                    </span>
                                                                    {remarkChild.remarkLabelUserName === children.remarkLabelUserName &&
                                                                      <span>
                                                                        自评
                                                                      </span>
                                                                    }
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      styles.remark_content_header_staff_1_time
                                                                    }
                                                                  >
                                                                    {remarkChild.createTime
                                                                      ? dateFormat(
                                                                          remarkChild.createTime,
                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                        )
                                                                      : '-'}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                styles.remark_child
                                                              }
                                                            >
                                                              {
                                                                remarkChild.remarkContent
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  },
                                                )}
                                            </div>
                                          </div>
                                        ),
                                      )}
                                    {pchildren.mRemarkRecordDetails &&
                                      pchildren.mRemarkRecordDetails
                                        .length > 3 && (
                                        <div
                                          style={{
                                            textAlign: 'right',
                                            color: 'blue',
                                          }}
                                          onClick={(e) => {
                                            moreMap[`${pcIndex}`] =
                                              !moreMap[`${pcIndex}`];
                                            setMoreMap({ ...moreMap });
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }}
                                        >
                                          {moreMap[`${pcIndex}`]
                                            ? '折叠-'
                                            : '展开+'}
                                        </div>
                                      )}
                                  </div>
                                );
                              },
                            )}
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            )}
          </>
        )
      })}
      <div className={styles.footer_0}>
        {options && (localStorage.getItem("isLeaderInDept") === "1" ) && (options.status === 0 || options.status === 1) && (
          <Button
            block
            type="submit"
            color="primary"
            className={styles.submit}
            onClick={() => {
              Modal.confirm({
                title: '驳回原因',
                content: (
                  <div className={styles.forms}>
                    <Form form={form}>
                      <Form.Item
                        name="forms"
                        rules={[
                          {
                            required: true,
                            message: '请输入驳回原因',
                          },
                        ]}
                        className={styles.form_item}
                      >
                        <TextArea
                          placeholder="请输入驳回原因"
                          maxLength={100}
                          rows={5}
                          showCount
                          onChange={(v) => {
                            console.log(v);
                          }}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                ),
                onConfirm: () => {
                  onDelete(dataFlag.id);
                },
              });
            }}
          >
            驳回
          </Button>
        )}
      </div>
      
      
      <Popup
        visible={visible4}
        showCloseButton
        onClose={() => {
          setVisible4(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
          maxHeight: '100vh'
        }}
      >
        <div
          className={styles.remarkName}
          // style={{ color: `${remarkChild.childFlag && 'red'}` }}
        >
          {remarkChild.index} {remarkChild.name}
        </div>
        <Form form={remarkform}>
          <List>
            <List.Item prefix="评分">
              <Form.Item
                name="appraiseType"
                // rules={[{ required: true, message: '选择是否硬广覆盖' }]}
                className={styles.form_item}
              >
                <Radio.Group>
                  {/* <Radio value={'1'}>极好</Radio> */}
                  <Radio value={'2'}>好</Radio>
                  <Radio value={'3'}>中</Radio>
                  <Radio value={'4'}>差</Radio>
                </Radio.Group>
              </Form.Item>
            </List.Item>
            <TextArea
              placeholder="请输入备注"
              maxLength={100}
              rows={5}
              showCount
              value={remarkValue}
              onChange={(v) => {
                setRemarkValue(v);
              }}
            />
          </List>
        </Form>
        <div style={{width:"95%",paddingBottom:"10px"}}>
          <Button
            block
            type="button"
            color="primary"
            onClick={() => {
              onFinishRemark();
            }}
          >
            提交
          </Button>
        </div>
      </Popup>
      
      {/* <ImageViewer
        image={allImage}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false)
        }}
      /> */}
      
      <ImageViewer.Multi
        images={allImages}
        visible={imageVisibles}
        defaultIndex={indexs}
        onClose={() => {
          setImageVisibles(false)
        }}
      />
      <ImageViewer
        image={allImage}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false)
        }}
      />
    </div>
  )
}