// @ts-ignore
/* eslint-disable */
import styles from './index.less';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Empty,
  Form,
  ImageViewer,
  List,
  Popup,
  PullToRefresh,
  Radio,
  setDefaultConfig,
  Tabs,
  TextArea,
  Toast,
} from 'antd-mobile/es';
import 成功 from './img/成功.png';
import 拜访成交 from './img/拜访成交.png';
import 注册上线 from './img/注册上线.png';
import 失败 from './img/失败.png';
import 待在拜访 from './img/待在拜访.png';
import initMerchant from './img/initMerchant.png';
import { history, useLocation, useParams } from 'umi';
import { getRemarkRecord, Lists, recordSave } from './service';
import { dateFormat } from '../utils/util';
import dayjs from 'dayjs';
import { detail } from '../awaitingDetail/service';
import Navbar from '../utils/Navbar';
import { getWxAccessToken, login } from '../login/service';
import Login from '../login';
import proxy from '../../../config/proxy';
import {
  breakthroughLogicInTheRegione,
  visitRemarks,
} from '../PayAVisit/service';
import MyPie from '../programme/common/MyPie';
import { remarkLists } from '../programme/service';
import { sleep } from 'antd-mobile/es/utils/sleep';

export const parseResultName = (appraiseType: string) => {
  let result;
  if (appraiseType === '1') {
    result = '极好';
  }
  if (appraiseType === '2') {
    result = '好';
  }
  if (appraiseType === '3') {
    result = '中';
  }
  if (appraiseType === '4') {
    result = '差';
  }
  return result;
};
export default function PayAVisit() {
  const [loading, setLoading] = useState<boolean>(true);
  const [formMore] = Form.useForm();
  const [remarkform] = Form.useForm();
  const [newRemark, setNewRemark] = useState<any>([]);
  const [newRemarkList, setNewRemarkList] = useState<any>([]);
  const [remark, setRemark] = useState<any>([]);
  const [remarks, setRemarks] = useState<any>([]);
  const { rowId }: { rowId: string } = useParams();
  const [data, setData] = useState<any>([]);
  const [remarkValue, setRemarkValue] = useState<any>([]);
  const [visitPlanId, setVisitPlanId] = useState<number>();
  const [visible, setVisible] = useState<boolean>(false);
  const [visitResult, setvisitResult] = useState<any>(-1);
  const [parentId, setParentId] = useState<any>(-1);
  const [marketNameId, setmarketNameId] = useState<number>(-1);
  const [times, setTimes] = useState<any>([]);
  const [timeMore, setTimeMore] = useState<[Date, Date] | null>(null);
  const [visitSignType, setvisitSignType] = useState<any>(-1);
  const [userId, setUserId] = useState<any>([]);
  const [defaultKey, setDefaultKey] = useState<string>('0');
  const [visible4, setVisible4] = useState<boolean>(false);
  const [visible5, setVisible5] = useState<boolean>(false);
  const [visible6, setVisible6] = useState<boolean>(false);
  const [annotations, setAnnotations] = useState<any>([]);
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [remarkRecordId, setremarkRecordId] = useState<any>([]);
  const [mCarDealerVisitId, setmCarDealerVisitId] = useState<any>([]);
  const [moreMap, setMoreMap] = useState<any>({});
  const [iPhone, setiPhone] = useState<boolean>(true);
  const [allImage, setAllImage] = useState<any>([]);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [appraiseList, setAppraiseList] = useState<any>([]);
  const [scoreList, setScoreList] = useState<any>([]);
  const [marginalValue, setMarginalValue] = useState<any>([]);
  const [marginalList, setMarginalList] = useState<any>([]);
  const [record, setRecord] = useState<any>([]);
  const [modifyAnnotations, setModifyAnnotations] = useState<any>([]);
  const userIdSocre = localStorage.getItem('name') || '0';
  const location = useLocation();
  const dataFlag = location.state;
  // @ts-ignore
  const query = location?.query;
  const infoRef = useRef([]);
  const loadData = async (rowId: any) => {
    const append = await detail({
      id: rowId,
    });
    if (append.retCode === 0) {
      setLoading(false);
      setData(append.data);
      if (append.data && append.data.companyId && append.data.userId) {
        valueNode(append.data.companyId, append.data.userId);
      }
      wxOnMenuShareConfig(append.data);
      if (append.data && append.data.annotationFlag) {
        onAppraiseChange(0, append.data.companyId, rowId, true);
      } else {
        onAppraiseChange(0, append.data.companyId, rowId, false);
      }
    }
  };
  const valueNode = async (companyId: any, userId: any) => {
    const score = await remarkLists({
      companyId: companyId,
      userId: userId != 1 ? userId : localStorage.getItem('userId'),
    });
    if (score.data) {
      setScoreList({ data: score.data, companyId: companyId });
      score.data.map((item: any) => {
        if (item.labelName === '专业人设') {
          setParentId(item.labelId);
        }
      });
    }
  };
  // useEffect(() => {
  //   loadData();
  // }, [params]);
  const remarkRecord = (flag:number) => {
    if(flag === 1){
      if (newRemark[rowId]) {
        newRemark[rowId] = false;
        setNewRemark([...newRemark]);
      } else {
        newRemark[rowId] = true;
        setNewRemark([...newRemark]);
      }
    }
    getRemarkRecord({ id: rowId,parentId:parentId }).then((msg) => {
      if (msg.retCode === 0) {
        // Toast.show({
        //   icon: 'success',
        //   content: msg.retMsg,
        // });
        // loadMore({});
        setRemarks([]);
        setNewRemarkList(msg.data);
        // pullListMore(checkoutFlag)
      } else {
        Toast.show({
          icon: 'fail',
          content: msg.retMsg,
        });
      }
    });
  };

  const onFinishRemark = () => {
    remarkform
      .validateFields()
      .then(() => {
        onRemarkFinish();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onRemarkFinish = async () => {
    const values = remarkform.getFieldsValue();
    if (remarkValue === '') {
      setLoading(false);
      Toast.show({
        icon: 'fail',
        content: '请填写批注！',
        // duration: 0,
      });
      return;
    }
    // values.createTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    values.remarkContent = remarkValue;
    values.labelId = remarkChild.labelId;
    values.labelName = remarkChild.labelName;
    values.parentId = remarkChild.id;
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = localStorage.getItem('avatar');
    values.visitPlanId = mCarDealerVisitId;
    values.remarkRecordId = remarkRecordId;
    let list = remark;
    recordSave(values)
      .then((msg) => {
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '批注成功',
          });
          setVisible4(false);
          // setParams({ ...params, pageNo: -1, flag: false });
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        if (msg.data) {
          list.push(msg.data);
        }
        let arr = list.reverse();
        console.log(arr, list);
        setRemarks(arr);
        setRemark(arr);
        setLoading(false);
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: error,
        });
        setLoading(false);
      });
  };
  const onFinishAnnotations = () => {
    remarkform
      .validateFields()
      .then(() => {
        onAnnotationsFinish();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onFinishMarginal = async (values: any) => {
    breakthroughLogicInTheRegione({
      id: values.id,
      regionBreakThroughLogic: marginalValue,
    })
      .then((msg) => {
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '批注成功',
          });
          // setVisible5(false);
          // setParams({ ...params, pageNo: -1, flag: false });
          // history.go(0)
          marginalList[values.id] = marginalValue;
          setMarginalList([...marginalList]);
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        setLoading(false);
        setVisible6(false);
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: error,
        });
        setLoading(false);
      });
  };
  const onAnnotationsFinish = async () => {
    const values = remarkform.getFieldsValue();
    // values.createTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    values.remarkContent = remarkValue;
    values.labelId = remarkChild.labelId;
    values.labelName = remarkChild.labelName;
    values.parentId = remarkChild.id;
    values.id = annotations.id;
    values.remarkLabelUserName = localStorage.getItem('name');
    values.url = localStorage.getItem('avatar');
    values.visitPlanId = mCarDealerVisitId;
    values.remarkRecordId = remarkRecordId;
    let list: any = [];
    recordSave(values)
      .then((msg) => {
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '修改成功',
          });
          if (msg.data && msg.data) {
            list[msg.data.id] = msg.data;
          }
          setVisible5(false);
          setModifyAnnotations(list);
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        Toast.show({
          icon: 'fail',
          content: error,
        });
        setLoading(false);
      });
  };
  const dataFlagValue = (list: any) => {
    formMore?.setFieldsValue(list);
    loadData(rowId);
    if (list.payAVisit) {
      setVisible(true);
      setvisitResult(list.visitResult);
      setvisitSignType(list.visitSignType);
      setUserId(list.userId);
      setTimeMore(list.timeMore);
      setTimes(list.times);
      if (list.value && list.value.length > 0 && list.value[0]) {
        setmarketNameId(list.value[0].id);
        formMore.setFieldValue('belongMarketName', list.value[0].marketName);
      }
    }
  };
  useEffect(() => {
    if (dataFlag) {
      dataFlagValue(dataFlag);
    }

    let userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        userAgent,
      )
    ) {
      console.log('前端是移动端');
      setiPhone(true);
    } else {
      console.log('前端是pc端');
      setiPhone(false);
    }
    // console.log(rowId,token);
    // localStorage.setItem("token",token)
  }, []);

  const wxOnMenuShareConfig = async (data: any) => {
    // callOnBD(["ZhuGe"])
    const msg = await getWxAccessToken({
      url: window.location.href,
    });
    if (msg.retCode === 0) {
      await window.wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareAppMessage', 'shareWechatMessage'],
      });
      await window.wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['onMenuShareAppMessage', 'shareAppMessage'],
        success: function (res: any) {
          // 回调
          window.wx.checkJsApi({
            jsApiList: ['shareAppMessage'], // 需要检测的JS接口列表
            success: function (res: any) {
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
              console.log(res);
            },
          });
        },
        fail: function (res: { errMsg: string | string[] }) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      window.wx.onMenuShareAppMessage({
        title: data.companyName, // 分享标题
        desc: `${data.userName}于${
          data.visitingTime
            ? dateFormat(data.visitingTime, 'yyyy-MM-dd hh:mm:ss')
            : '-'
        }拜访`, // 分享描述
        link: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww4168efadaace928a&redirect_uri=${proxy['/api'].target}/remarkDetail/${data.id}&response_type=code&scope=snsapi_base&state=STATE&agentid=1000013#wechat_redirect`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
        imgUrl:
          data.mCompanyInfo && data.mCompanyInfo.doorHeadPhoto
            ? data.mCompanyInfo.doorHeadPhoto
            : initMerchant, // 分享图标
        enableIdTrans: 0, // 是否开启id转译，不填默认为0
        success: function (res: any) {
          // 用户确认分享后执行的回调函数
        },
        error: function (res: any) {
          if (res.errMsg.indexOf('no permission') > 0) {
            alert('未agentConfig');
          }
        },
        cancel: function (res: any) {
          // 用户取消分享后执行的回调函数
        },
      });
    }
  };
  const onAppraiseChange = (
    v: any,
    companyId: any,
    visitId: any,
    annotationFlag: boolean,
  ) => {
    visitRemarks({
      appraiseType: v,
      companyId: companyId,
      id: rowId,
      annotationFlag: annotationFlag,
      parentId: parentId,
    }).then((res: any) => {
      if (res.retCode === 0) {
        appraiseList[visitId] = res.data;
        setAppraiseList([...appraiseList]);
      }
    });
    setDefaultKey(v);
  };
  const valuePoint = async (value: any) => {
    console.log(value);
    setParentId(value.parentId);
  };
  useEffect(() => {
    if(newRemark[rowId]){
      remarkRecord(2)
    }else{
      if (data && data.annotationFlag) {
        onAppraiseChange('0', data.companyId, data.id, true);
      } else {
        onAppraiseChange('0', data.companyId, data.id, false);
      }
    }
  }, [parentId]);
  useEffect(() => {
    if (query.code) {
      login({ token: query.code })
        .then((msg) => {
          if (msg.retCode === 0) {
            localStorage.setItem('name', msg.data.name);
            localStorage.setItem('position', msg.data.position);
            localStorage.setItem('userId', msg.data.userId);
            localStorage.setItem('avatar', msg.data.avatar);
            let flag = false;
            if (msg.data.isLeaderInDept) {
              let list = JSON.parse(msg.data.isLeaderInDept);
              if (list.length > 0) {
                list.map((data: any) => {
                  if (data === 1 || data === '1') {
                    flag = true;
                  }
                });
              }
              if (msg.data.isLeaderInDept === '[1]') {
                flag = true;
              }
            }
            if (msg.data.batchBuyAuthority) {
              localStorage.setItem(
                'batchBuyAuthority',
                msg.data.batchBuyAuthority,
              );
            }
            if (msg.data.userType === 0 || msg.data.userType === '0') {
              flag = true;
            }
            if (flag) {
              localStorage.setItem('isLeaderInDept', '1');
            } else {
              localStorage.removeItem('isLeaderInDept');
            }
            localStorage.setItem('token', query.code);
            loadData(rowId);
          } else {
            Toast.show({
              icon: 'fail',
              content: msg.retMsg,
            });
          }
        })
        .catch((error) => [
          Toast.show({
            icon: 'fail',
            content: '获取用户信息失败，请退出重新进入',
          }),
        ]);
    }
  }, []);
  const appraiseTypeEnum = [
    { name: '全部', value: 0 },
    { name: '极好', value: 1 },
    {
      name: '好',
      value: 2,
    },
    { name: '中', value: 3 },
    {
      name: '差',
      value: 4,
    },
    { name: '待沟通', value: 5 },
  ];
  const displayMap: any = {
    0: '未知',
    1: '独立展厅',
    2: '露天市场',
    3: '大棚市场',
    4: '室内市场',
  };
  const degreeIntentMap: any = {
    1: '30天以上',
    2: '14天-30天',
    3: '7-14天',
    4: '3天内',
    5: '3天内',
  };
  const stateMap: any = {
    0: '待执行',
    1: '已编辑',
    3: '已取消',
    6: '已驳回',
    4: '已完成',
  };
  return (
    <div className={styles.page}>
      <div className={styles.all}>
        <Navbar name={'备注详情'}></Navbar>
        {loading && (
          <Login
            className={styles.loading}
            style={{ height: '90%', bottom: '0' }}
          ></Login>
        )}
        <div
          className={styles.content}
          style={{ overflow: `${loading ? '' : ''}` }}
        >
          <PullToRefresh
            onRefresh={async () => {
              await sleep(1000);
              setLoading(true);
              loadData(rowId);
            }}
          >
            <Card
              title={
                <div>
                  <span style={{ fontWeight: '400', fontSize: '14px' }}>
                    {data.visitSignTypeName}
                  </span>
                  <span
                    style={{
                      marginLeft: '53px',
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                  >
                    拜访
                    {`${data.numberOfVisits}` != ''
                      ? data.numberOfVisits + 1
                      : 0}
                    次
                  </span>
                  {data.focusCompanyVisit && (
                    <span
                      style={{
                        marginLeft: '15px',
                        fontWeight: '400',
                        fontSize: '12px',
                        color: 'red',
                      }}
                    >
                      重点商户
                    </span>
                  )}
                </div>
              }
              extra={
                <div
                  style={{
                    color: '#FF5705',
                    fontWeight: '400',
                    fontSize: '12px',
                  }}
                >
                  {stateMap[data.status]}
                </div>
              }
            >
              <div className={styles.content}>
                <div className={styles.content_0}>
                  <div>计划ID</div>
                  <span>{data.id || 0}</span>
                </div>
                {data.visitSignType !== 13 ? (
                  <>
                    {data.visitSignType !== 12 ? (
                      <div className={styles.content_0}>
                        <div>拜访车商</div>
                        <span>{data.companyName ? data.companyName : '-'}</span>
                      </div>
                    ) : (
                      <div
                        className={styles.content}
                        onClick={() => {
                          // if (data.visitSignType === 12) {
                          //   history.push('./callOnSb', data);
                          //   return;
                          // }
                          if (data.mCarDealerVisit) {
                            data.visitContent =
                              data.mCarDealerVisit.visitContent;
                          }
                          data.type = 0;
                          if (data.status === 4) {
                            data.edit = true;
                            history.push('./see', data);
                          } else {
                            // history.push('./awaitingDetail', data);
                          }
                        }}
                      >
                        <div className={styles.com}>
                          {data.visitCompanyRelations &&
                            data.visitCompanyRelations.map(
                              (child: any, childIndex: number) => {
                                return (
                                  <div
                                    className={styles.completed}
                                    key={`${childIndex}-${childIndex}-wait`}
                                    style={{
                                      display:
                                        moreMap[`${childIndex}`] ||
                                        childIndex < 5
                                          ? 'flex'
                                          : 'none',
                                    }}
                                  >
                                    <div
                                      className={styles.completed_0}
                                      style={{ opacity: '0' }}
                                    >
                                      拜访车商(
                                      {data.visitCompanyRelations.length})
                                    </div>
                                    <div className={styles.completed_1}>
                                      <div>{child.companyName}</div>
                                      {!child.completedFlag ? (
                                        <span
                                          style={{
                                            color: 'rgba(255, 87, 5, 1)',
                                          }}
                                        >
                                          待拜访
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color: 'rgba(41, 165, 31, 1)',
                                          }}
                                        >
                                          已拜访
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                            )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className={styles.content_0}>
                      <div>推荐车商</div>
                      <span>{data.companyName ? data.companyName : '-'}</span>
                    </div>
                    {data.visitCompanyRelations &&
                      data.visitCompanyRelations.map((visitCompany: any) => {
                        return (
                          <div className={styles.content_0}>
                            <div>被推荐车商</div>
                            <span>
                              {visitCompany.companyName
                                ? visitCompany.companyName
                                : '-'}
                            </span>
                          </div>
                        );
                      })}
                  </>
                )}
                {data.mCompanyInfo && (
                  <>
                    <div className={styles.content_0}>
                      <div>商户库存</div>
                      <span>
                        {data.mCompanyInfo.notUsedNum
                          ? data.mCompanyInfo.notUsedNum
                          : '0'}
                      </span>
                    </div>
                    <div className={styles.content_0}>
                      <div>城市</div>
                      <span>
                        {data.mCompanyInfo.contactCityName
                          ? data.mCompanyInfo.contactCityName
                          : '-'}
                      </span>
                    </div>
                    {data.mCarDealerVisitVo &&
                      data.mCarDealerVisitVo.mCarDealers && (
                        <div className={styles.content_0}>
                          <div>车位数</div>
                          <span>
                            {data.mCarDealerVisitVo.mCarDealers.parkingNum
                              ? data.mCarDealerVisitVo.mCarDealers.parkingNum
                              : '0'}
                          </span>
                        </div>
                      )}
                    <div className={styles.content_0}>
                      <div>市场</div>
                      <span>
                        {data.mCompanyInfo.belongMarketName
                          ? data.mCompanyInfo.belongMarketName
                          : '-'}
                      </span>
                    </div>
                    {data.mCarDealerVisitVo &&
                      data.mCarDealerVisitVo.mCarDealers && (
                        <div className={styles.content_0}>
                          <div>展厅</div>
                          <span>
                            {data.mCarDealerVisitVo.mCarDealers.displayType
                              ? displayMap[
                                  data.mCarDealerVisitVo.mCarDealers.displayType
                                ]
                              : '-'}
                          </span>
                        </div>
                      )}
                    <div className={styles.content_0}>
                      <div>集采次数</div>
                      <span>
                        {data.mCompanyInfo.collectiveProcurementNum || '0'}
                      </span>
                    </div>
                    <div className={styles.content_0}>
                      <div>意向度</div>
                      <span>
                        {degreeIntentMap[data.mCompanyInfo.degreeIntent] || '-'}
                      </span>
                    </div>
                    {data.mCarDealerVisitVo &&
                      data.mCarDealerVisitVo.mCarDealerMedia &&
                      data.mCarDealerVisitVo.mCarDealerMedia.length > 0 && (
                        <>
                          <div className={styles.content_0}>
                            <div>自媒体信息</div>
                            <div>
                              {data.mCarDealerVisitVo.mCarDealerMedia &&
                              data.mCarDealerVisitVo.mCarDealerMedia.length > 0
                                ? data.mCarDealerVisitVo.mCarDealerMedia.length
                                : '无'}
                            </div>
                          </div>
                          {data.mCarDealerVisitVo.mCarDealerMedia.map(
                            (itemMedias: any) => {
                              return (
                                <div className={styles.tabs_content_1}>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>自媒体</div>
                                    <div>
                                      {itemMedias.mediaName
                                        ? itemMedias.mediaName
                                        : '无'}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>账号</div>
                                    <div>
                                      {itemMedias.account
                                        ? itemMedias.account
                                        : '无'}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>粉丝量</div>
                                    <div>
                                      {itemMedias.fansNum
                                        ? itemMedias.fansNum
                                        : '0'}
                                      万
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </>
                      )}
                    {data.mCarDealerVisitVo &&
                      data.mCarDealerVisitVo.carDealerCompetitors &&
                      data.mCarDealerVisitVo.carDealerCompetitors.length >
                        0 && (
                        <>
                          <div className={styles.content_0}>
                            <div>竞队信息</div>
                            <div>
                              {data.mCarDealerVisitVo.carDealerCompetitors &&
                              data.mCarDealerVisitVo.carDealerCompetitors
                                .length > 0
                                ? data.mCarDealerVisitVo.carDealerCompetitors
                                    .length
                                : '无'}
                            </div>
                          </div>
                          {data.mCarDealerVisitVo.carDealerCompetitors.map(
                            (itemPartners: any) => {
                              return (
                                <div className={styles.tabs_content_1}>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>检测机构</div>
                                    <div>
                                      {itemPartners.name
                                        ? itemPartners.name
                                        : '无'}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>合作时间</div>
                                    <div>
                                      {itemPartners.cooperationTime
                                        ? dateFormat(
                                            itemPartners.cooperationTime,
                                            'yyyy-MM-dd hh:mm:ss',
                                          )
                                        : '-'}
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>集采余额</div>
                                    <div>
                                      {itemPartners.purchaseBalance
                                        ? itemPartners.purchaseBalance
                                        : '0'}
                                      元
                                    </div>
                                  </div>
                                  <div className={styles.tabs_content_1_0}>
                                    <div>硬广覆盖</div>
                                    <div>
                                      {itemPartners.hardCover === 1
                                        ? '是'
                                        : '否'}
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </>
                      )}
                  </>
                )}
                <div className={styles.content_0}>
                  <div>姓名</div>
                  <span>{data.userName ? data.userName : '-'}</span>
                </div>
                {data.checkInPictures &&
                  data.checkInPictures.map((img: any, key: number) => {
                    return (
                      <div key={key} className={styles.content_img}>
                        <div>{img.urlName}</div>
                        <span>
                          <img
                            src={img.url}
                            onClick={() => {
                              setImageVisible(true);
                              setAllImage(img.url);
                            }}
                          />
                        </span>
                      </div>
                    );
                  })}

                {data.mCarDealerVisitVo &&
                  data.mCarDealerVisitVo.checkInPictures &&
                  data.mCarDealerVisitVo.checkInPictures.map(
                    (img: any, key: number) => {
                      return (
                        <div key={key} className={styles.content_img}>
                          <div>{img.urlName}</div>
                          <span>
                            <img
                              src={img.url}
                              onClick={() => {
                                setImageVisible(true);
                                setAllImage(img.url);
                              }}
                            />
                          </span>
                        </div>
                      );
                    },
                  )}
                <div className={styles.content_0}>
                  <div>拜访时间</div>
                  <span>
                    {data.visitingTime
                      ? dayjs(data.visitingTime).format('YYYY-MM-DD HH:mm:ss')
                      : '-'}
                  </span>
                </div>
                <div className={styles.content_0}>
                  <div>实际拜访时间</div>
                  <span>
                    {data.actualVisitTime
                      ? dayjs(data.actualVisitTime).format('YYYY-MM-DD HH:mm:ss')
                      : '-'}
                  </span>
                </div>
                {data.nextVisitTime && (
                  <div className={styles.content_0}>
                    <div>下次拜访时间</div>
                    <span>
                      {data.nextVisitTime
                        ? dayjs(data.nextVisitTime).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )
                        : '-'}
                    </span>
                  </div>
                )}
                {data.visitSignType != 12 && data.visitSignType != 13 && (
                  <div className={styles.content_0}>
                    <div>目标金额</div>
                    <span>{data.targetAmount ? data.targetAmount : 0}元</span>
                  </div>
                )}
                {data.visitSignType != 12 && data.visitSignType != 13 && (
                  <div className={styles.content_0}>
                    <div>目标方案</div>
                    <span>
                        {data.targetPlanType === 2 && '集采城市限价' }
                        {data.targetPlanType === 1 && '形象店' }
                        {data.targetPlanType === 0 && '产品买赠方案'}
                    </span>
                  </div>
                )}
                {data.productName && (
                  <div className={styles.content_0}>
                    <div>检测产品</div>
                    <span>{data.productName}</span>
                  </div>
                )}
                {data.visitSignType !== 12 && data.visitSignType != 13 && (
                  <>
                    {data.mCarDealerVisitVo ? (
                      <div className={styles.content_0}>
                        <div>拜访内容</div>
                        <span>{data.mCarDealerVisitVo.visitContent || ''}</span>
                        {data.status >= 4 && data.status != 6 ? (
                          data && data.visitResults === false ? (
                            <img
                              src={
                                data.visitSignType === 1 ||
                                data.visitSignType === 5 ||
                                data.visitSignType === 2 ||
                                data.visitSignType === 3 ||
                                data.visitSignType === 4 ||
                                data.visitSignType === 11
                                  ? 待在拜访
                                  : 失败
                              }
                              alt=""
                            />
                          ) : (
                            <img
                              src={
                                data.visitSignType === 2 ||
                                data.visitSignType === 3 ||
                                data.visitSignType === 4 ||
                                data.visitSignType === 11
                                  ? `${
                                      data.visitSignType === 1 ||
                                      data.visitSignType === 5
                                        ? 注册上线
                                        : 拜访成交
                                    }`
                                  : 成功
                              }
                              alt=""
                            />
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <div className={styles.content_0}>
                        <div>拜访内容</div>
                        <span>{data.visitContent || ''}</span>
                        {data.status >= 4 && data.status != 6 ? (
                          data && data.visitResults === false ? (
                            <img src={失败} alt="" />
                          ) : (
                            <img src={成功} alt="" />
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </>
                )}
                {data.visitSummary && (
                  <div className={styles.content_0}>
                    <div>拜访总结</div>
                    <span>{data.visitSummary || ''}</span>
                  </div>
                )}
                {data.breakThroughLogic && (
                  <div className={styles.content_0}>
                    <div>突破逻辑</div>
                    <span>{data.breakThroughLogic || ''}</span>
                    <div
                      className={styles.remark_content_header_0}
                      style={{ width: 'auto', lineHeight: '1.5' }}
                      onClick={() => {
                        setVisible6(true);
                        setMarginalValue('');
                        setRecord(data);
                      }}
                    >
                      批注
                    </div>
                  </div>
                )}
                {marginalList && marginalList[data.id] ? (
                  <div className={styles.content_0}>
                    <div>突破逻辑（批注）</div>
                    <span>{marginalList[data.id]}</span>
                    <div
                      className={styles.remark_content_header_0}
                      style={{ width: 'auto', lineHeight: '1.5' }}
                      onClick={() => {
                        setVisible6(true);
                        setMarginalValue(marginalList[data.id]);
                        setRecord(data);
                      }}
                    >
                      修改
                    </div>
                  </div>
                ) : (
                  <>
                    {data.regionBreakThroughLogic && (
                      <div className={styles.content_0}>
                        <div>突破逻辑（批注）</div>
                        <span>{data.regionBreakThroughLogic || ''}</span>
                        <div
                          className={styles.remark_content_header_0}
                          style={{ width: 'auto', lineHeight: '1.5' }}
                          onClick={() => {
                            setVisible6(true);
                            setMarginalValue(data.regionBreakThroughLogic);
                            setRecord(data);
                          }}
                        >
                          修改
                        </div>
                      </div>
                    )}
                  </>
                )}
                {data.status === 1 && (
                  <div className={styles.content_0}>
                    <div>编辑原因</div>
                    <span>
                      {data.amendmentRemark ? data.amendmentRemark : '-'}
                    </span>
                  </div>
                )}
                {data.mCarDealerVisitVo &&
                  data.mCarDealerVisitVo.reasonForFailure && (
                    <div className={styles.content_0}>
                      <div>商户托词</div>
                      <span>
                        {data.mCarDealerVisitVo.reasonForFailure
                          ? data.mCarDealerVisitVo.reasonForFailure
                          : '-'}
                      </span>
                    </div>
                  )}
              </div>
              <div className={styles.footer_1}>
                <div className={styles.footer_b}>
                  <div>批注信息</div>
                  <div
                    className={styles.footer_button_2}
                    onClick={() => {
                      remarkRecord(1);
                    }}
                  >
                    {newRemark[data.id] ? '查看全部批注' : '查看最新批注'}
                  </div>
                </div>
              </div>

              {scoreList && scoreList.data && scoreList.data.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                  <MyPie
                    onPointChange={valuePoint}
                    companyId={scoreList.companyId}
                    current={scoreList.data}
                  ></MyPie>
                </div>
              )}
              <div>
                {newRemark[data.id] ? (
                  <div className={styles.remarkScroll}>
                    {newRemarkList &&
                      newRemarkList.length > 0 ?
                      newRemarkList.map((child: any, mCarIndex: number) => {
                        return (
                          <div>
                            <div
                              className={styles.parent_label}
                              // key={`mCarDealerVisitVo-${mCarIndex}-${index}`}
                              style={{ fontSize: '16px' }}
                            >{`${child.labelName}`}</div>
                            {child.mRemarkRecordDetails.length > 0 &&
                              child.mRemarkRecordDetails.map(
                                (pchildren: any, pcIndex: number) => {
                                  return (
                                    <div>
                                      <div className={styles.footer}></div>
                                      {pchildren.mRemarkRecordDetails.length >
                                        0 &&
                                        pchildren.mRemarkRecordDetails.map(
                                          (children: any, rIndex: number) => {
                                            return (
                                              <div
                                                className={styles.footer}
                                                style={{
                                                  display:
                                                    moreMap[`${pcIndex}`] ||
                                                    rIndex < 3
                                                      ? 'block'
                                                      : 'none',
                                                }}
                                              >
                                                <div
                                                  className={styles.footer_2}
                                                  style={{
                                                    fontSize: '12px',
                                                    paddingBottom: 0,
                                                    fontWeight: 'normal',
                                                  }}
                                                >
                                                  <div
                                                    className={`${styles.footer_2_title} ${styles.status}`}
                                                  >
                                                    <span
                                                      className={
                                                        styles.label_name
                                                      }
                                                    >
                                                      {`${children.labelName}`}1
                                                    </span>
                                                    <span
                                                      className={styles.status}
                                                    >
                                                      已完成
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.footer_2_extra
                                                    }
                                                  >
                                                    这里是价值点的文案说明
                                                  </div>
                                                </div>
                                                <div className={styles.remark}>
                                                  <div
                                                    className={
                                                      styles.remark_content
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.remark_content_header
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          styles.remark_content_header_staff
                                                        }
                                                        style={{
                                                          position: 'relative',
                                                        }}
                                                      >
                                                        <img
                                                          className={
                                                            styles.remark_content_header_staff_0
                                                          }
                                                          src={children.url}
                                                          style={{
                                                            position: 'static',
                                                          }}
                                                          alt=""
                                                        />
                                                        <div
                                                          className={
                                                            styles.remark_content_header_staff_1
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              styles.remark_content_header_staff_1_name
                                                            }
                                                          >
                                                            {children.remarkLabelUserName
                                                              ? children.remarkLabelUserName
                                                              : '-'}{' '}
                                                            <span>
                                                              {parseResultName(
                                                                `${children.appraiseType}`,
                                                              )}
                                                            </span>
                                                          </div>
                                                          <div
                                                            className={
                                                              styles.remark_content_header_staff_1_time
                                                            }
                                                          >
                                                            {children.createTime
                                                              ? dateFormat(
                                                                  children.createTime,
                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                )
                                                              : '-'}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          styles.remark_content_header_0
                                                        }
                                                        onClick={() => {
                                                          console.log(remark);
                                                          remarkform.resetFields();
                                                          setVisible4(true);
                                                          setRemarkChild(
                                                            children,
                                                          );
                                                          setmCarDealerVisitId(
                                                            data.id,
                                                          );
                                                          setRemarkValue('');
                                                          setremarkRecordId(
                                                            child.id,
                                                          );
                                                          setVisitPlanId(data.id);
                                                        }}
                                                      >
                                                        批注
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.remark_content_foot
                                                      }
                                                    >
                                                      {children.remarkContent}
                                                    </div>
                                                  </div>
                                                  {remarks &&
                                                    remarks.length > 0 &&
                                                    remarks.map(
                                                      (remarkItem: any) => {
                                                        if (
                                                          remarkItem.visitPlanId ===
                                                            data.id &&
                                                          remarkItem.parentId ===
                                                            children.id
                                                        ) {
                                                          return (
                                                            <div
                                                              style={{
                                                                background:
                                                                  '#EFF3F9',
                                                                marginLeft:
                                                                  '50px',
                                                                marginBottom:
                                                                  '10px',
                                                                borderRadius:
                                                                  '8px',
                                                              }}
                                                            >
                                                              {modifyAnnotations &&
                                                              modifyAnnotations[
                                                                remarkItem.id
                                                              ] ? (
                                                                <div
                                                                  className={
                                                                    styles.remark_p
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_header
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            modifyAnnotations[
                                                                              remarkItem
                                                                                .id
                                                                            ].url
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'static',
                                                                          }}
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff_1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            {
                                                                              modifyAnnotations[
                                                                                remarkItem
                                                                                  .id
                                                                              ]
                                                                                .remarkLabelUserName
                                                                            }{' '}
                                                                            <span>
                                                                              {' '}
                                                                              {parseResultName(
                                                                                `${
                                                                                  modifyAnnotations[
                                                                                    remarkItem
                                                                                      .id
                                                                                  ]
                                                                                    .appraiseType
                                                                                }`,
                                                                              )}{' '}
                                                                            </span>
                                                                            {modifyAnnotations[
                                                                              remarkItem
                                                                                .id
                                                                            ]
                                                                              .remarkLabelUserName ===
                                                                              children.remarkLabelUserName && (
                                                                              <span>
                                                                                自评
                                                                              </span>
                                                                            )}
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_time
                                                                            }
                                                                          >
                                                                            {modifyAnnotations[
                                                                              remarkItem
                                                                                .id
                                                                            ]
                                                                              .createTime
                                                                              ? dateFormat(
                                                                                  modifyAnnotations[
                                                                                    remarkItem
                                                                                      .id
                                                                                  ]
                                                                                    .createTime,
                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                )
                                                                              : '-'}
                                                                          </div>
                                                                        </div>
                                                                        {userIdSocre ===
                                                                          modifyAnnotations[
                                                                            remarkItem
                                                                              .id
                                                                          ]
                                                                            .remarkLabelUserName && (
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_0
                                                                            }
                                                                            onClick={() => {
                                                                              console.log(
                                                                                remark,
                                                                              );
                                                                              remarkform.resetFields();
                                                                              setVisible5(
                                                                                true,
                                                                              );
                                                                              setRemarkChild(
                                                                                children,
                                                                              );
                                                                              setmCarDealerVisitId(
                                                                                data.id,
                                                                              );
                                                                              setRemarkValue(
                                                                                modifyAnnotations[
                                                                                  remarkItem
                                                                                    .id
                                                                                ]
                                                                                  .remarkContent,
                                                                              );
                                                                              setremarkRecordId(
                                                                                child.id,
                                                                              );
                                                                              setVisitPlanId(
                                                                                data.id,
                                                                              );
                                                                              remarkform?.setFieldsValue(
                                                                                {
                                                                                  appraiseType:
                                                                                    modifyAnnotations[
                                                                                      remarkItem
                                                                                        .id
                                                                                    ]
                                                                                      .appraiseType,
                                                                                },
                                                                              );
                                                                              setAnnotations(
                                                                                modifyAnnotations[
                                                                                  remarkItem
                                                                                    .id
                                                                                ],
                                                                              );
                                                                            }}
                                                                          >
                                                                            修改
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        styles.remark_child
                                                                      }
                                                                    >
                                                                      {
                                                                        modifyAnnotations[
                                                                          remarkItem
                                                                            .id
                                                                        ]
                                                                          .remarkContent
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <div
                                                                  className={
                                                                    styles.remark_p
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_header
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            remarkItem.url
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'static',
                                                                          }}
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff_1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            {
                                                                              remarkItem.remarkLabelUserName
                                                                            }{' '}
                                                                            <span>
                                                                              {' '}
                                                                              {parseResultName(
                                                                                `${remarkItem.appraiseType}`,
                                                                              )}{' '}
                                                                            </span>
                                                                            {remarkItem.remarkLabelUserName ===
                                                                              children.remarkLabelUserName && (
                                                                              <span>
                                                                                自评
                                                                              </span>
                                                                            )}
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_time
                                                                            }
                                                                          >
                                                                            {remarkItem.createTime
                                                                              ? dateFormat(
                                                                                  remarkItem.createTime,
                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                )
                                                                              : '-'}
                                                                          </div>
                                                                        </div>
                                                                        {userIdSocre ===
                                                                          remarkItem.remarkLabelUserName && (
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_0
                                                                            }
                                                                            onClick={() => {
                                                                              console.log(
                                                                                remark,
                                                                              );
                                                                              remarkform.resetFields();
                                                                              setVisible5(
                                                                                true,
                                                                              );
                                                                              setRemarkChild(
                                                                                children,
                                                                              );
                                                                              setmCarDealerVisitId(
                                                                                data.id,
                                                                              );
                                                                              setRemarkValue(
                                                                                remarkItem.remarkContent,
                                                                              );
                                                                              setremarkRecordId(
                                                                                child.id,
                                                                              );
                                                                              setVisitPlanId(
                                                                                data.id,
                                                                              );
                                                                              remarkform?.setFieldsValue(
                                                                                {
                                                                                  appraiseType:
                                                                                    remarkItem.appraiseType,
                                                                                },
                                                                              );
                                                                              setAnnotations(
                                                                                remarkItem,
                                                                              );
                                                                            }}
                                                                          >
                                                                            修改
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        styles.remark_child
                                                                      }
                                                                    >
                                                                      {
                                                                        remarkItem.remarkContent
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      },
                                                    )}
                                                  {children.mRemarkRecordDetails &&
                                                    children.mRemarkRecordDetails
                                                      .length > 0 &&
                                                    children.mRemarkRecordDetails.map(
                                                      (
                                                        remarkChild: any,
                                                        key: number,
                                                      ) => {
                                                        console.log(
                                                          children.mRemarkRecordDetails,
                                                        );

                                                        return (
                                                          <div
                                                            key={key}
                                                            style={{
                                                              background:
                                                                '#EFF3F9',
                                                              marginLeft: '50px',
                                                              marginBottom:
                                                                '10px',
                                                              borderRadius: '8px',
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                styles.remark_p
                                                              }
                                                            >
                                                              {modifyAnnotations &&
                                                              modifyAnnotations[
                                                                remarkChild.id
                                                              ] ? (
                                                                <div
                                                                  className={
                                                                    styles.remark_p
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_header
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            modifyAnnotations[
                                                                              remarkChild
                                                                                .id
                                                                            ].url
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'static',
                                                                          }}
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff_1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            {
                                                                              modifyAnnotations[
                                                                                remarkChild
                                                                                  .id
                                                                              ]
                                                                                .remarkLabelUserName
                                                                            }{' '}
                                                                            <span>
                                                                              {' '}
                                                                              {parseResultName(
                                                                                `${
                                                                                  modifyAnnotations[
                                                                                    remarkChild
                                                                                      .id
                                                                                  ]
                                                                                    .appraiseType
                                                                                }`,
                                                                              )}{' '}
                                                                            </span>
                                                                            {modifyAnnotations[
                                                                              remarkChild
                                                                                .id
                                                                            ]
                                                                              .remarkLabelUserName ===
                                                                              children.remarkLabelUserName && (
                                                                              <span>
                                                                                自评
                                                                              </span>
                                                                            )}
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_time
                                                                            }
                                                                          >
                                                                            {modifyAnnotations[
                                                                              remarkChild
                                                                                .id
                                                                            ]
                                                                              .createTime
                                                                              ? dateFormat(
                                                                                  modifyAnnotations[
                                                                                    remarkChild
                                                                                      .id
                                                                                  ]
                                                                                    .createTime,
                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                )
                                                                              : '-'}
                                                                          </div>
                                                                        </div>
                                                                        {modifyAnnotations[
                                                                          remarkChild
                                                                            .id
                                                                        ]
                                                                          .remarkLabelUserName ===
                                                                          userIdSocre && (
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_0
                                                                            }
                                                                            onClick={() => {
                                                                              console.log(
                                                                                remark,
                                                                              );
                                                                              remarkform.resetFields();
                                                                              setVisible5(
                                                                                true,
                                                                              );
                                                                              setRemarkChild(
                                                                                children,
                                                                              );
                                                                              setmCarDealerVisitId(
                                                                                data.id,
                                                                              );
                                                                              setRemarkValue(
                                                                                modifyAnnotations[
                                                                                  remarkChild
                                                                                    .id
                                                                                ]
                                                                                  .remarkContent,
                                                                              );
                                                                              setremarkRecordId(
                                                                                child.id,
                                                                              );
                                                                              setVisitPlanId(
                                                                                data.id,
                                                                              );
                                                                              remarkform?.setFieldsValue(
                                                                                {
                                                                                  appraiseType:
                                                                                    modifyAnnotations[
                                                                                      remarkChild
                                                                                        .id
                                                                                    ]
                                                                                      .appraiseType,
                                                                                },
                                                                              );
                                                                              setAnnotations(
                                                                                modifyAnnotations[
                                                                                  remarkChild
                                                                                    .id
                                                                                ],
                                                                              );
                                                                            }}
                                                                          >
                                                                            修改
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        styles.remark_child
                                                                      }
                                                                    >
                                                                      {
                                                                        modifyAnnotations[
                                                                          remarkChild
                                                                            .id
                                                                        ]
                                                                          .remarkContent
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <div
                                                                  className={
                                                                    styles.remark_p
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_content_header
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content_header_staff
                                                                        }
                                                                      >
                                                                        <img
                                                                          className={
                                                                            styles.remark_content_header_staff_0
                                                                          }
                                                                          src={
                                                                            remarkChild.url
                                                                          }
                                                                          style={{
                                                                            position:
                                                                              'static',
                                                                          }}
                                                                          alt=""
                                                                        />
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header_staff_1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_name
                                                                            }
                                                                          >
                                                                            {
                                                                              remarkChild.remarkLabelUserName
                                                                            }{' '}
                                                                            <span>
                                                                              {' '}
                                                                              {parseResultName(
                                                                                `${remarkChild.appraiseType}`,
                                                                              )}{' '}
                                                                            </span>
                                                                            {remarkChild.remarkLabelUserName ===
                                                                              children.remarkLabelUserName && (
                                                                              <span>
                                                                                自评
                                                                              </span>
                                                                            )}
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff_1_time
                                                                            }
                                                                          >
                                                                            {remarkChild.createTime
                                                                              ? dateFormat(
                                                                                  remarkChild.createTime,
                                                                                  'yyyy-MM-dd hh:mm:ss',
                                                                                )
                                                                              : '-'}
                                                                          </div>
                                                                        </div>
                                                                        {remarkChild.remarkLabelUserName ===
                                                                          userIdSocre && (
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_0
                                                                            }
                                                                            onClick={() => {
                                                                              console.log(
                                                                                remark,
                                                                              );
                                                                              remarkform.resetFields();
                                                                              setVisible5(
                                                                                true,
                                                                              );
                                                                              setRemarkChild(
                                                                                children,
                                                                              );
                                                                              setmCarDealerVisitId(
                                                                                data.id,
                                                                              );
                                                                              setRemarkValue(
                                                                                remarkChild.remarkContent,
                                                                              );
                                                                              setremarkRecordId(
                                                                                child.id,
                                                                              );
                                                                              setVisitPlanId(
                                                                                data.id,
                                                                              );
                                                                              remarkform?.setFieldsValue(
                                                                                {
                                                                                  appraiseType:
                                                                                    remarkChild.appraiseType,
                                                                                },
                                                                              );
                                                                              setAnnotations(
                                                                                remarkChild,
                                                                              );
                                                                            }}
                                                                          >
                                                                            修改
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        styles.remark_child
                                                                      }
                                                                    >
                                                                      {
                                                                        remarkChild.remarkContent
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        );
                                                      },
                                                    )}
                                                </div>
                                              </div>
                                            );
                                          },
                                        )}
                                      {pchildren.mRemarkRecordDetails &&
                                        pchildren.mRemarkRecordDetails.length >
                                          3 && (
                                          <div
                                            style={{
                                              textAlign: 'right',
                                              color: 'blue',
                                            }}
                                            onClick={(e) => {
                                              moreMap[`${pcIndex}`] =
                                                !moreMap[`${pcIndex}`];
                                              setMoreMap({
                                                ...moreMap,
                                              });
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}
                                          >
                                            {moreMap[`${pcIndex}`]
                                              ? '折叠-'
                                              : '展开+'}
                                          </div>
                                        )}
                                    </div>
                                  );
                                },
                              )}
                          </div>
                        );
                      }) : <Empty description="暂无价值点" />}
                  </div>
                ) : (
                  <>
                    <Tabs
                      style={{
                        '--title-font-size': '14px',
                      }}
                      className={styles.tab_appraise}
                      activeKey={defaultKey}
                      onChange={(v) => {
                        if (data && data.annotationFlag) {
                          onAppraiseChange(v, data.companyId, data.id, true);
                        } else {
                          onAppraiseChange(v, data.companyId, data.id, false);
                        }
                      }}
                    >
                      {appraiseTypeEnum.map((data, index) => (
                        <Tabs.Tab title={data.name} key={data.value} />
                      ))}
                    </Tabs>
                    <div className={styles.remarkScroll}>
                      {appraiseList && appraiseList[data.id] ? (
                        appraiseList[data.id].length > 0 ? (
                          appraiseList[data.id].map(
                            (child: any, mCarIndex: number) => {
                              return (
                                <div>
                                  <div
                                    className={styles.parent_label}
                                    style={{ fontSize: '16px' }}
                                  >{`${child.labelName}`}</div>
                                  {child.mRemarkRecordDetails.length > 0 &&
                                    child.mRemarkRecordDetails.map(
                                      (pchildren: any, pcIndex: number) => {
                                        return (
                                          <div>
                                            <div className={styles.footer}>
                                              <div
                                                className={styles.footer_2}
                                                // key={`${pcIndex}-wait`}
                                                style={{
                                                  fontSize: '12px',
                                                  paddingBottom: 0,
                                                  fontWeight: 'normal',
                                                  // display:
                                                  //   pcIndex < 3
                                                  //     ? 'block'
                                                  //     : 'none',
                                                }}
                                              >
                                                <div
                                                  className={`${styles.footer_2_title} ${styles.status}`}
                                                >
                                                  <span
                                                    className={styles.label_name}
                                                  >{`${pchildren.labelName}`}</span>
                                                </div>
                                              </div>
                                            </div>
                                            {pchildren.mRemarkRecordDetails &&
                                              pchildren.mRemarkRecordDetails
                                                .length > 0 &&
                                              pchildren.mRemarkRecordDetails.map(
                                                (children: any, rIndex: number) => {
                                                  // pchildren.mRemarkRecordDetails.sort(
                                                  //   (a: any, b: any) => {
                                                  //     if (a.appraiseType < 3) {
                                                  //       return 1;
                                                  //     } else {
                                                  //       return -1;
                                                  //     }
                                                  //   },
                                                  // );
                                                  return (
                                                    <div
                                                      className={styles.footer}
                                                      style={{
                                                        display:
                                                          moreMap[`${pcIndex}`] ||
                                                          rIndex < 3
                                                            ? 'block'
                                                            : 'none',
                                                      }}
                                                    >
                                                      <div
                                                        className={styles.remark}
                                                      >
                                                        <div
                                                          className={
                                                            styles.remark_content
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              styles.remark_content_header
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles.remark_content_header_staff
                                                              }
                                                              style={{
                                                                position:
                                                                  'relative',
                                                              }}
                                                            >
                                                              <img
                                                                className={
                                                                  styles.remark_content_header_staff_0
                                                                }
                                                                src={children.url}
                                                                style={{
                                                                  position:
                                                                    'static',
                                                                }}
                                                                alt=""
                                                              />
                                                              <div
                                                                className={
                                                                  styles.remark_content_header_staff_1
                                                                }
                                                              >
                                                                <div
                                                                  className={
                                                                    styles.remark_content_header_staff_1_name
                                                                  }
                                                                >
                                                                  {children.remarkLabelUserName
                                                                    ? children.remarkLabelUserName
                                                                    : '-'}{' '}
                                                                  <span>
                                                                    {parseResultName(
                                                                      `${children.appraiseType}`,
                                                                    )}
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    styles.remark_content_header_staff_1_time
                                                                  }
                                                                >
                                                                  {children.createTime
                                                                    ? dateFormat(
                                                                        children.createTime,
                                                                        'yyyy-MM-dd hh:mm:ss',
                                                                      )
                                                                    : '-'}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                styles.remark_content_header_0
                                                              }
                                                              onClick={() => {
                                                                console.log(remark);

                                                                remarkform.resetFields();
                                                                setVisible4(true);
                                                                setRemarkChild(
                                                                  children,
                                                                );
                                                                setmCarDealerVisitId(
                                                                  data.id,
                                                                );
                                                                setRemarkValue('');
                                                                setremarkRecordId(
                                                                  child.id,
                                                                );
                                                                setVisitPlanId(
                                                                  data.id,
                                                                );
                                                              }}
                                                            >
                                                              批注
                                                            </div>
                                                          </div>
                                                          <div
                                                            className={
                                                              styles.remark_content_foot
                                                            }
                                                          >
                                                            {children.remarkContent}
                                                          </div>
                                                        </div>
                                                        {remark &&
                                                          remark.length > 0 &&
                                                          remark.map(
                                                            (
                                                              remarkItem: any,
                                                              key: number,
                                                            ) => {
                                                              if (
                                                                remarkItem.visitPlanId ===
                                                                  data.id &&
                                                                remarkItem.parentId ===
                                                                  children.id
                                                              ) {
                                                                return (
                                                                  <div
                                                                    key={key}
                                                                    style={{
                                                                      background:
                                                                        '#EFF3F9',
                                                                      marginLeft:
                                                                        '50px',
                                                                      marginBottom:
                                                                        '10px',
                                                                      borderRadius:
                                                                        '8px',
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className={
                                                                        styles.remark_p
                                                                      }
                                                                    >
                                                                      {modifyAnnotations &&
                                                                      modifyAnnotations[
                                                                        remarkItem
                                                                          .id
                                                                      ] ? (
                                                                        <div
                                                                          className={
                                                                            styles.remark_p
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff
                                                                                }
                                                                              >
                                                                                <img
                                                                                  className={
                                                                                    styles.remark_content_header_staff_0
                                                                                  }
                                                                                  src={
                                                                                    modifyAnnotations[
                                                                                      remarkItem
                                                                                        .id
                                                                                    ]
                                                                                      .url
                                                                                  }
                                                                                  style={{
                                                                                    position:
                                                                                      'static',
                                                                                  }}
                                                                                  alt=""
                                                                                />
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_name
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      modifyAnnotations[
                                                                                        remarkItem
                                                                                          .id
                                                                                      ]
                                                                                        .remarkLabelUserName
                                                                                    }{' '}
                                                                                    <span>
                                                                                      {' '}
                                                                                      {parseResultName(
                                                                                        `${
                                                                                          modifyAnnotations[
                                                                                            remarkItem
                                                                                              .id
                                                                                          ]
                                                                                            .appraiseType
                                                                                        }`,
                                                                                      )}{' '}
                                                                                    </span>
                                                                                    {modifyAnnotations[
                                                                                      remarkItem
                                                                                        .id
                                                                                    ]
                                                                                      .remarkLabelUserName ===
                                                                                      children.remarkLabelUserName && (
                                                                                      <span>
                                                                                        自评
                                                                                      </span>
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_time
                                                                                    }
                                                                                  >
                                                                                    {modifyAnnotations[
                                                                                      remarkItem
                                                                                        .id
                                                                                    ]
                                                                                      .createTime
                                                                                      ? dateFormat(
                                                                                          modifyAnnotations[
                                                                                            remarkItem
                                                                                              .id
                                                                                          ]
                                                                                            .createTime,
                                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                                        )
                                                                                      : '-'}
                                                                                  </div>
                                                                                </div>
                                                                                {userIdSocre ===
                                                                                  modifyAnnotations[
                                                                                    remarkItem
                                                                                      .id
                                                                                  ]
                                                                                    .remarkLabelUserName && (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_0
                                                                                    }
                                                                                    onClick={() => {
                                                                                      console.log(
                                                                                        remark,
                                                                                      );
                                                                                      remarkform.resetFields();
                                                                                      setVisible5(
                                                                                        true,
                                                                                      );
                                                                                      setRemarkChild(
                                                                                        children,
                                                                                      );
                                                                                      setmCarDealerVisitId(
                                                                                        data.id,
                                                                                      );
                                                                                      setRemarkValue(
                                                                                        modifyAnnotations[
                                                                                          remarkItem
                                                                                            .id
                                                                                        ]
                                                                                          .remarkContent,
                                                                                      );
                                                                                      setremarkRecordId(
                                                                                        child.id,
                                                                                      );
                                                                                      setVisitPlanId(
                                                                                        data.id,
                                                                                      );
                                                                                      remarkform?.setFieldsValue(
                                                                                        {
                                                                                          appraiseType:
                                                                                            modifyAnnotations[
                                                                                              remarkItem
                                                                                                .id
                                                                                            ]
                                                                                              .appraiseType,
                                                                                        },
                                                                                      );
                                                                                      setAnnotations(
                                                                                        modifyAnnotations[
                                                                                          remarkItem
                                                                                            .id
                                                                                        ],
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    修改
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            </div>
                                                                            <div
                                                                              className={
                                                                                styles.remark_child
                                                                              }
                                                                            >
                                                                              {
                                                                                modifyAnnotations[
                                                                                  remarkItem
                                                                                    .id
                                                                                ]
                                                                                  .remarkContent
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <div
                                                                          className={
                                                                            styles.remark_p
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff
                                                                                }
                                                                              >
                                                                                <img
                                                                                  className={
                                                                                    styles.remark_content_header_staff_0
                                                                                  }
                                                                                  src={
                                                                                    remarkItem.url
                                                                                  }
                                                                                  style={{
                                                                                    position:
                                                                                      'static',
                                                                                  }}
                                                                                  alt=""
                                                                                />
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_name
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      remarkItem.remarkLabelUserName
                                                                                    }{' '}
                                                                                    <span>
                                                                                      {' '}
                                                                                      {parseResultName(
                                                                                        `${remarkItem.appraiseType}`,
                                                                                      )}{' '}
                                                                                    </span>
                                                                                    {remarkItem.remarkLabelUserName ===
                                                                                      children.remarkLabelUserName && (
                                                                                      <span>
                                                                                        自评
                                                                                      </span>
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_time
                                                                                    }
                                                                                  >
                                                                                    {remarkItem.createTime
                                                                                      ? dateFormat(
                                                                                          remarkItem.createTime,
                                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                                        )
                                                                                      : '-'}
                                                                                  </div>
                                                                                </div>
                                                                                {userIdSocre ===
                                                                                  remarkItem.remarkLabelUserName && (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_0
                                                                                    }
                                                                                    onClick={() => {
                                                                                      console.log(
                                                                                        remark,
                                                                                      );
                                                                                      remarkform.resetFields();
                                                                                      setVisible5(
                                                                                        true,
                                                                                      );
                                                                                      setRemarkChild(
                                                                                        children,
                                                                                      );
                                                                                      setmCarDealerVisitId(
                                                                                        data.id,
                                                                                      );
                                                                                      setRemarkValue(
                                                                                        remarkItem.remarkContent,
                                                                                      );
                                                                                      setremarkRecordId(
                                                                                        child.id,
                                                                                      );
                                                                                      setVisitPlanId(
                                                                                        data.id,
                                                                                      );
                                                                                      remarkform?.setFieldsValue(
                                                                                        {
                                                                                          appraiseType:
                                                                                            remarkItem.appraiseType,
                                                                                        },
                                                                                      );
                                                                                      setAnnotations(
                                                                                        remarkItem,
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    修改
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            </div>
                                                                            <div
                                                                              className={
                                                                                styles.remark_child
                                                                              }
                                                                            >
                                                                              {
                                                                                remarkItem.remarkContent
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                );
                                                              }
                                                            },
                                                          )}
                                                        {children.mRemarkRecordDetails &&
                                                          children
                                                            .mRemarkRecordDetails
                                                            .length > 0 &&
                                                          children.mRemarkRecordDetails.map(
                                                            (
                                                              remarkChild: any,
                                                              key: number,
                                                            ) => {
                                                              // children.mRemarkRecordDetails.sort(
                                                              //   (
                                                              //     a: any,
                                                              //     b: any,
                                                              //   ) => {
                                                              //     if (
                                                              //       a.appraiseType <=
                                                              //       3
                                                              //     ) {
                                                              //       return 1;
                                                              //     } else {
                                                              //       return -1;
                                                              //     }
                                                              //   },
                                                              // );
                                                              return (
                                                                <div
                                                                  key={key}
                                                                  style={{
                                                                    background:
                                                                      '#EFF3F9',
                                                                    marginLeft:
                                                                      '50px',
                                                                    marginBottom:
                                                                      '10px',
                                                                    borderRadius:
                                                                      '8px',
                                                                  }}
                                                                >
                                                                  {modifyAnnotations &&
                                                                  modifyAnnotations[
                                                                    remarkChild.id
                                                                  ] ? (
                                                                    <div
                                                                      className={
                                                                        styles.remark_p
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff
                                                                            }
                                                                          >
                                                                            <img
                                                                              className={
                                                                                styles.remark_content_header_staff_0
                                                                              }
                                                                              src={
                                                                                modifyAnnotations[
                                                                                  remarkChild
                                                                                    .id
                                                                                ]
                                                                                  .url
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  'static',
                                                                              }}
                                                                              alt=""
                                                                            />
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff_1
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1_name
                                                                                }
                                                                              >
                                                                                {
                                                                                  modifyAnnotations[
                                                                                    remarkChild
                                                                                      .id
                                                                                  ]
                                                                                    .remarkLabelUserName
                                                                                }{' '}
                                                                                <span>
                                                                                  {' '}
                                                                                  {parseResultName(
                                                                                    `${
                                                                                      modifyAnnotations[
                                                                                        remarkChild
                                                                                          .id
                                                                                      ]
                                                                                        .appraiseType
                                                                                    }`,
                                                                                  )}{' '}
                                                                                </span>
                                                                                {modifyAnnotations[
                                                                                  remarkChild
                                                                                    .id
                                                                                ]
                                                                                  .remarkLabelUserName ===
                                                                                  children.remarkLabelUserName && (
                                                                                  <span>
                                                                                    自评
                                                                                  </span>
                                                                                )}
                                                                              </div>
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1_time
                                                                                }
                                                                              >
                                                                                {modifyAnnotations[
                                                                                  remarkChild
                                                                                    .id
                                                                                ]
                                                                                  .createTime
                                                                                  ? dateFormat(
                                                                                      modifyAnnotations[
                                                                                        remarkChild
                                                                                          .id
                                                                                      ]
                                                                                        .createTime,
                                                                                      'yyyy-MM-dd hh:mm:ss',
                                                                                    )
                                                                                  : '-'}
                                                                              </div>
                                                                            </div>
                                                                            {userIdSocre ===
                                                                              modifyAnnotations[
                                                                                remarkChild
                                                                                  .id
                                                                              ]
                                                                                .remarkLabelUserName && (
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_0
                                                                                }
                                                                                onClick={() => {
                                                                                  console.log(
                                                                                    remark,
                                                                                  );
                                                                                  remarkform.resetFields();
                                                                                  setVisible5(
                                                                                    true,
                                                                                  );
                                                                                  setRemarkChild(
                                                                                    children,
                                                                                  );
                                                                                  setmCarDealerVisitId(
                                                                                    data.id,
                                                                                  );
                                                                                  setRemarkValue(
                                                                                    modifyAnnotations[
                                                                                      remarkChild
                                                                                        .id
                                                                                    ]
                                                                                      .remarkContent,
                                                                                  );
                                                                                  setremarkRecordId(
                                                                                    child.id,
                                                                                  );
                                                                                  setVisitPlanId(
                                                                                    data.id,
                                                                                  );
                                                                                  remarkform?.setFieldsValue(
                                                                                    {
                                                                                      appraiseType:
                                                                                        modifyAnnotations[
                                                                                          remarkChild
                                                                                            .id
                                                                                        ]
                                                                                          .appraiseType,
                                                                                    },
                                                                                  );
                                                                                  setAnnotations(
                                                                                    modifyAnnotations[
                                                                                      remarkChild
                                                                                        .id
                                                                                    ],
                                                                                  );
                                                                                }}
                                                                              >
                                                                                修改
                                                                              </div>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          className={
                                                                            styles.remark_child
                                                                          }
                                                                        >
                                                                          {
                                                                            modifyAnnotations[
                                                                              remarkChild
                                                                                .id
                                                                            ]
                                                                              .remarkContent
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  ) : (
                                                                    <div
                                                                      className={
                                                                        styles.remark_p
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          styles.remark_content
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            styles.remark_content_header
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header_staff
                                                                            }
                                                                          >
                                                                            <img
                                                                              className={
                                                                                styles.remark_content_header_staff_0
                                                                              }
                                                                              src={
                                                                                remarkChild.url
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  'static',
                                                                              }}
                                                                              alt=""
                                                                            />
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff_1
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1_name
                                                                                }
                                                                              >
                                                                                {
                                                                                  remarkChild.remarkLabelUserName
                                                                                }{' '}
                                                                                <span>
                                                                                  {' '}
                                                                                  {parseResultName(
                                                                                    `${remarkChild.appraiseType}`,
                                                                                  )}{' '}
                                                                                </span>
                                                                                {remarkChild.remarkLabelUserName ===
                                                                                  children.remarkLabelUserName && (
                                                                                  <span>
                                                                                    自评
                                                                                  </span>
                                                                                )}
                                                                              </div>
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1_time
                                                                                }
                                                                              >
                                                                                {remarkChild.createTime
                                                                                  ? dateFormat(
                                                                                      remarkChild.createTime,
                                                                                      'yyyy-MM-dd hh:mm:ss',
                                                                                    )
                                                                                  : '-'}
                                                                              </div>
                                                                            </div>
                                                                            {userIdSocre ===
                                                                              remarkChild.remarkLabelUserName && (
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_0
                                                                                }
                                                                                onClick={() => {
                                                                                  console.log(
                                                                                    remark,
                                                                                  );
                                                                                  remarkform.resetFields();
                                                                                  setVisible5(
                                                                                    true,
                                                                                  );
                                                                                  setRemarkChild(
                                                                                    children,
                                                                                  );
                                                                                  setmCarDealerVisitId(
                                                                                    data.id,
                                                                                  );
                                                                                  setRemarkValue(
                                                                                    remarkChild.remarkContent,
                                                                                  );
                                                                                  setremarkRecordId(
                                                                                    child.id,
                                                                                  );
                                                                                  setVisitPlanId(
                                                                                    data.id,
                                                                                  );
                                                                                  remarkform?.setFieldsValue(
                                                                                    {
                                                                                      appraiseType:
                                                                                        remarkChild.appraiseType,
                                                                                    },
                                                                                  );
                                                                                  setAnnotations(
                                                                                    remarkChild,
                                                                                  );
                                                                                }}
                                                                              >
                                                                                修改
                                                                              </div>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          className={
                                                                            styles.remark_child
                                                                          }
                                                                        >
                                                                          {
                                                                            remarkChild.remarkContent
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              );
                                                            },
                                                          )}
                                                      </div>
                                                    </div>
                                                  );
                                                },
                                              )}
                                            {pchildren.mRemarkRecordDetails &&
                                              pchildren.mRemarkRecordDetails
                                                .length > 3 && (
                                                <div
                                                  style={{
                                                    textAlign: 'right',
                                                    color: 'blue',
                                                  }}
                                                  onClick={(e) => {
                                                    moreMap[`${pcIndex}`] =
                                                      !moreMap[`${pcIndex}`];
                                                    setMoreMap({
                                                      ...moreMap,
                                                    });
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  {moreMap[`${pcIndex}`]
                                                    ? '折叠-'
                                                    : '展开+'}
                                                </div>
                                              )}
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              );
                            },
                          )
                        ) : (
                          <Empty description="暂无价值点" />
                        )
                      ) : (
                        <>
                          {data.mRemarkRecordDetails &&
                            data.mRemarkRecordDetails.map(
                              (child: any, mCarIndex: number) => {
                                return (
                                  <div>
                                    <div
                                      className={styles.parent_label}
                                      style={{ fontSize: '16px' }}
                                    >{`${child.labelName}`}</div>
                                    {child.mRemarkRecordDetails.length > 0 &&
                                      child.mRemarkRecordDetails.map(
                                        (pchildren: any, pcIndex: number) => {
                                          return (
                                            <div>
                                              <div className={styles.footer}>
                                                <div
                                                  className={styles.footer_2}
                                                  // key={`${pcIndex}-wait`}
                                                  style={{
                                                    fontSize: '12px',
                                                    paddingBottom: 0,
                                                    fontWeight: 'normal',
                                                    // display:
                                                    //   pcIndex < 3
                                                    //     ? 'block'
                                                    //     : 'none',
                                                  }}
                                                >
                                                  <div
                                                    className={`${styles.footer_2_title} ${styles.status}`}
                                                  >
                                                    <span
                                                      className={styles.label_name}
                                                    >{` ${pchildren.labelName}`}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              {pchildren.mRemarkRecordDetails
                                                .length > 0 &&
                                                pchildren.mRemarkRecordDetails.map(
                                                  (
                                                    children: any,
                                                    rIndex: number,
                                                  ) => {
                                                    // pchildren.mRemarkRecordDetails.sort(
                                                    //   (a: any, b: any) => {
                                                    //     if (a.appraiseType < 3) {
                                                    //       return 1;
                                                    //     } else {
                                                    //       return -1;
                                                    //     }
                                                    //   },
                                                    // );
                                                    return (
                                                      <div
                                                        className={styles.footer}
                                                        style={{
                                                          display:
                                                            moreMap[`${pcIndex}`] ||
                                                            rIndex < 3
                                                              ? 'block'
                                                              : 'none',
                                                        }}
                                                      >
                                                        <div
                                                          className={styles.remark}
                                                        >
                                                          <div
                                                            className={
                                                              styles.remark_content
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                styles.remark_content_header
                                                              }
                                                            >
                                                              <div
                                                                className={
                                                                  styles.remark_content_header_staff
                                                                }
                                                                style={{
                                                                  position:
                                                                    'relative',
                                                                }}
                                                              >
                                                                <img
                                                                  className={
                                                                    styles.remark_content_header_staff_0
                                                                  }
                                                                  src={children.url}
                                                                  style={{
                                                                    position:
                                                                      'static',
                                                                  }}
                                                                  alt=""
                                                                />
                                                                <div
                                                                  className={
                                                                    styles.remark_content_header_staff_1
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.remark_content_header_staff_1_name
                                                                    }
                                                                  >
                                                                    {children.remarkLabelUserName
                                                                      ? children.remarkLabelUserName
                                                                      : '-'}{' '}
                                                                    <span>
                                                                      {parseResultName(
                                                                        `${children.appraiseType}`,
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      styles.remark_content_header_staff_1_time
                                                                    }
                                                                  >
                                                                    {children.createTime
                                                                      ? dateFormat(
                                                                          children.createTime,
                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                        )
                                                                      : '-'}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div
                                                                className={
                                                                  styles.remark_content_header_0
                                                                }
                                                                onClick={() => {
                                                                  console.log(
                                                                    remark,
                                                                  );

                                                                  remarkform.resetFields();
                                                                  setVisible4(true);
                                                                  setRemarkChild(
                                                                    children,
                                                                  );
                                                                  setmCarDealerVisitId(
                                                                    data.id,
                                                                  );
                                                                  setRemarkValue(
                                                                    '',
                                                                  );
                                                                  setremarkRecordId(
                                                                    child.id,
                                                                  );
                                                                  setVisitPlanId(
                                                                    data.id,
                                                                  );
                                                                }}
                                                              >
                                                                批注
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                styles.remark_content_foot
                                                              }
                                                            >
                                                              {
                                                                children.remarkContent
                                                              }
                                                            </div>
                                                          </div>
                                                          {remark &&
                                                            remark.length > 0 &&
                                                            remark.map(
                                                              (
                                                                remarkItem: any,
                                                                key: number,
                                                              ) => {
                                                                if (
                                                                  remarkItem.visitPlanId ===
                                                                    data.id &&
                                                                  remarkItem.parentId ===
                                                                    children.id
                                                                ) {
                                                                  return (
                                                                    <div
                                                                      key={key}
                                                                      style={{
                                                                        background:
                                                                          '#EFF3F9',
                                                                        marginLeft:
                                                                          '50px',
                                                                        marginBottom:
                                                                          '10px',
                                                                        borderRadius:
                                                                          '8px',
                                                                      }}
                                                                    >
                                                                      {modifyAnnotations &&
                                                                      modifyAnnotations[
                                                                        remarkItem
                                                                          .id
                                                                      ] ? (
                                                                        <div
                                                                          className={
                                                                            styles.remark_p
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff
                                                                                }
                                                                              >
                                                                                <img
                                                                                  className={
                                                                                    styles.remark_content_header_staff_0
                                                                                  }
                                                                                  src={
                                                                                    modifyAnnotations[
                                                                                      remarkItem
                                                                                        .id
                                                                                    ]
                                                                                      .url
                                                                                  }
                                                                                  style={{
                                                                                    position:
                                                                                      'static',
                                                                                  }}
                                                                                  alt=""
                                                                                />
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_name
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      modifyAnnotations[
                                                                                        remarkItem
                                                                                          .id
                                                                                      ]
                                                                                        .remarkLabelUserName
                                                                                    }{' '}
                                                                                    <span>
                                                                                      {' '}
                                                                                      {parseResultName(
                                                                                        `${
                                                                                          modifyAnnotations[
                                                                                            remarkItem
                                                                                              .id
                                                                                          ]
                                                                                            .appraiseType
                                                                                        }`,
                                                                                      )}{' '}
                                                                                    </span>
                                                                                    {modifyAnnotations[
                                                                                      remarkItem
                                                                                        .id
                                                                                    ]
                                                                                      .remarkLabelUserName ===
                                                                                      children.remarkLabelUserName && (
                                                                                      <span>
                                                                                        自评
                                                                                      </span>
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_time
                                                                                    }
                                                                                  >
                                                                                    {modifyAnnotations[
                                                                                      remarkItem
                                                                                        .id
                                                                                    ]
                                                                                      .createTime
                                                                                      ? dateFormat(
                                                                                          modifyAnnotations[
                                                                                            remarkItem
                                                                                              .id
                                                                                          ]
                                                                                            .createTime,
                                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                                        )
                                                                                      : '-'}
                                                                                  </div>
                                                                                </div>
                                                                                {userIdSocre ===
                                                                                  modifyAnnotations[
                                                                                    remarkItem
                                                                                      .id
                                                                                  ]
                                                                                    .remarkLabelUserName && (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_0
                                                                                    }
                                                                                    onClick={() => {
                                                                                      console.log(
                                                                                        remark,
                                                                                      );
                                                                                      remarkform.resetFields();
                                                                                      setVisible5(
                                                                                        true,
                                                                                      );
                                                                                      setRemarkChild(
                                                                                        children,
                                                                                      );
                                                                                      setmCarDealerVisitId(
                                                                                        data.id,
                                                                                      );
                                                                                      setRemarkValue(
                                                                                        modifyAnnotations[
                                                                                          remarkItem
                                                                                            .id
                                                                                        ]
                                                                                          .remarkContent,
                                                                                      );
                                                                                      setremarkRecordId(
                                                                                        child.id,
                                                                                      );
                                                                                      setVisitPlanId(
                                                                                        data.id,
                                                                                      );
                                                                                      remarkform?.setFieldsValue(
                                                                                        {
                                                                                          appraiseType:
                                                                                            modifyAnnotations[
                                                                                              remarkItem
                                                                                                .id
                                                                                            ]
                                                                                              .appraiseType,
                                                                                        },
                                                                                      );
                                                                                      setAnnotations(
                                                                                        modifyAnnotations[
                                                                                          remarkItem
                                                                                            .id
                                                                                        ],
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    修改
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            </div>
                                                                            <div
                                                                              className={
                                                                                styles.remark_child
                                                                              }
                                                                            >
                                                                              {
                                                                                modifyAnnotations[
                                                                                  remarkItem
                                                                                    .id
                                                                                ]
                                                                                  .remarkContent
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <div
                                                                          className={
                                                                            styles.remark_p
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header
                                                                              }
                                                                            >
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff
                                                                                }
                                                                              >
                                                                                <img
                                                                                  className={
                                                                                    styles.remark_content_header_staff_0
                                                                                  }
                                                                                  src={
                                                                                    remarkItem.url
                                                                                  }
                                                                                  style={{
                                                                                    position:
                                                                                      'static',
                                                                                  }}
                                                                                  alt=""
                                                                                />
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_name
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      remarkItem.remarkLabelUserName
                                                                                    }{' '}
                                                                                    <span>
                                                                                      {' '}
                                                                                      {parseResultName(
                                                                                        `${remarkItem.appraiseType}`,
                                                                                      )}{' '}
                                                                                    </span>
                                                                                    {remarkItem.remarkLabelUserName ===
                                                                                      children.remarkLabelUserName && (
                                                                                      <span>
                                                                                        自评
                                                                                      </span>
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_staff_1_time
                                                                                    }
                                                                                  >
                                                                                    {remarkItem.createTime
                                                                                      ? dateFormat(
                                                                                          remarkItem.createTime,
                                                                                          'yyyy-MM-dd hh:mm:ss',
                                                                                        )
                                                                                      : '-'}
                                                                                  </div>
                                                                                </div>
                                                                                {userIdSocre ===
                                                                                  remarkItem.remarkLabelUserName && (
                                                                                  <div
                                                                                    className={
                                                                                      styles.remark_content_header_0
                                                                                    }
                                                                                    onClick={() => {
                                                                                      console.log(
                                                                                        remark,
                                                                                      );
                                                                                      remarkform.resetFields();
                                                                                      setVisible5(
                                                                                        true,
                                                                                      );
                                                                                      setRemarkChild(
                                                                                        children,
                                                                                      );
                                                                                      setmCarDealerVisitId(
                                                                                        data.id,
                                                                                      );
                                                                                      setRemarkValue(
                                                                                        remarkItem.remarkContent,
                                                                                      );
                                                                                      setremarkRecordId(
                                                                                        child.id,
                                                                                      );
                                                                                      setVisitPlanId(
                                                                                        data.id,
                                                                                      );
                                                                                      remarkform?.setFieldsValue(
                                                                                        {
                                                                                          appraiseType:
                                                                                            remarkItem.appraiseType,
                                                                                        },
                                                                                      );
                                                                                      setAnnotations(
                                                                                        remarkItem,
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    修改
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            </div>
                                                                            <div
                                                                              className={
                                                                                styles.remark_child
                                                                              }
                                                                            >
                                                                              {
                                                                                remarkItem.remarkContent
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  );
                                                                }
                                                              },
                                                            )}
                                                          {children.mRemarkRecordDetails &&
                                                            children
                                                              .mRemarkRecordDetails
                                                              .length > 0 &&
                                                            children.mRemarkRecordDetails.map(
                                                              (
                                                                remarkChild: any,
                                                                key: number,
                                                              ) => {
                                                                // children.mRemarkRecordDetails.sort(
                                                                //   (
                                                                //     a: any,
                                                                //     b: any,
                                                                //   ) => {
                                                                //     if (
                                                                //       a.appraiseType <=
                                                                //       3
                                                                //     ) {
                                                                //       return 1;
                                                                //     } else {
                                                                //       return -1;
                                                                //     }
                                                                //   },
                                                                // );
                                                                return (
                                                                  <div
                                                                    key={key}
                                                                    style={{
                                                                      background:
                                                                        '#EFF3F9',
                                                                      marginLeft:
                                                                        '50px',
                                                                      marginBottom:
                                                                        '10px',
                                                                      borderRadius:
                                                                        '8px',
                                                                    }}
                                                                  >
                                                                    {modifyAnnotations &&
                                                                    modifyAnnotations[
                                                                      remarkChild.id
                                                                    ] ? (
                                                                      <div
                                                                        className={
                                                                          styles.remark_p
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            styles.remark_content
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff
                                                                              }
                                                                            >
                                                                              <img
                                                                                className={
                                                                                  styles.remark_content_header_staff_0
                                                                                }
                                                                                src={
                                                                                  modifyAnnotations[
                                                                                    remarkChild
                                                                                      .id
                                                                                  ]
                                                                                    .url
                                                                                }
                                                                                style={{
                                                                                  position:
                                                                                    'static',
                                                                                }}
                                                                                alt=""
                                                                              />
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1_name
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    modifyAnnotations[
                                                                                      remarkChild
                                                                                        .id
                                                                                    ]
                                                                                      .remarkLabelUserName
                                                                                  }{' '}
                                                                                  <span>
                                                                                    {' '}
                                                                                    {parseResultName(
                                                                                      `${
                                                                                        modifyAnnotations[
                                                                                          remarkChild
                                                                                            .id
                                                                                        ]
                                                                                          .appraiseType
                                                                                      }`,
                                                                                    )}{' '}
                                                                                  </span>
                                                                                  {modifyAnnotations[
                                                                                    remarkChild
                                                                                      .id
                                                                                  ]
                                                                                    .remarkLabelUserName ===
                                                                                    children.remarkLabelUserName && (
                                                                                    <span>
                                                                                      自评
                                                                                    </span>
                                                                                  )}
                                                                                </div>
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1_time
                                                                                  }
                                                                                >
                                                                                  {modifyAnnotations[
                                                                                    remarkChild
                                                                                      .id
                                                                                  ]
                                                                                    .createTime
                                                                                    ? dateFormat(
                                                                                        modifyAnnotations[
                                                                                          remarkChild
                                                                                            .id
                                                                                        ]
                                                                                          .createTime,
                                                                                        'yyyy-MM-dd hh:mm:ss',
                                                                                      )
                                                                                    : '-'}
                                                                                </div>
                                                                              </div>
                                                                              {userIdSocre ===
                                                                                modifyAnnotations[
                                                                                  remarkChild
                                                                                    .id
                                                                                ]
                                                                                  .remarkLabelUserName && (
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_0
                                                                                  }
                                                                                  onClick={() => {
                                                                                    console.log(
                                                                                      remark,
                                                                                    );
                                                                                    remarkform.resetFields();
                                                                                    setVisible5(
                                                                                      true,
                                                                                    );
                                                                                    setRemarkChild(
                                                                                      children,
                                                                                    );
                                                                                    setmCarDealerVisitId(
                                                                                      data.id,
                                                                                    );
                                                                                    setRemarkValue(
                                                                                      modifyAnnotations[
                                                                                        remarkChild
                                                                                          .id
                                                                                      ]
                                                                                        .remarkContent,
                                                                                    );
                                                                                    setremarkRecordId(
                                                                                      child.id,
                                                                                    );
                                                                                    setVisitPlanId(
                                                                                      data.id,
                                                                                    );
                                                                                    remarkform?.setFieldsValue(
                                                                                      {
                                                                                        appraiseType:
                                                                                          modifyAnnotations[
                                                                                            remarkChild
                                                                                              .id
                                                                                          ]
                                                                                            .appraiseType,
                                                                                      },
                                                                                    );
                                                                                    setAnnotations(
                                                                                      modifyAnnotations[
                                                                                        remarkChild
                                                                                          .id
                                                                                      ],
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  修改
                                                                                </div>
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_child
                                                                            }
                                                                          >
                                                                            {
                                                                              modifyAnnotations[
                                                                                remarkChild
                                                                                  .id
                                                                              ]
                                                                                .remarkContent
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    ) : (
                                                                      <div
                                                                        className={
                                                                          styles.remark_p
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            styles.remark_content
                                                                          }
                                                                        >
                                                                          <div
                                                                            className={
                                                                              styles.remark_content_header
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                styles.remark_content_header_staff
                                                                              }
                                                                            >
                                                                              <img
                                                                                className={
                                                                                  styles.remark_content_header_staff_0
                                                                                }
                                                                                src={
                                                                                  remarkChild.url
                                                                                }
                                                                                style={{
                                                                                  position:
                                                                                    'static',
                                                                                }}
                                                                                alt=""
                                                                              />
                                                                              <div
                                                                                className={
                                                                                  styles.remark_content_header_staff_1
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1_name
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    remarkChild.remarkLabelUserName
                                                                                  }{' '}
                                                                                  <span>
                                                                                    {' '}
                                                                                    {parseResultName(
                                                                                      `${remarkChild.appraiseType}`,
                                                                                    )}{' '}
                                                                                  </span>
                                                                                  {remarkChild.remarkLabelUserName ===
                                                                                    children.remarkLabelUserName && (
                                                                                    <span>
                                                                                      自评
                                                                                    </span>
                                                                                  )}
                                                                                </div>
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_staff_1_time
                                                                                  }
                                                                                >
                                                                                  {remarkChild.createTime
                                                                                    ? dateFormat(
                                                                                        remarkChild.createTime,
                                                                                        'yyyy-MM-dd hh:mm:ss',
                                                                                      )
                                                                                    : '-'}
                                                                                </div>
                                                                              </div>
                                                                              {userIdSocre ===
                                                                                remarkChild.remarkLabelUserName && (
                                                                                <div
                                                                                  className={
                                                                                    styles.remark_content_header_0
                                                                                  }
                                                                                  onClick={() => {
                                                                                    console.log(
                                                                                      remark,
                                                                                    );
                                                                                    remarkform.resetFields();
                                                                                    setVisible5(
                                                                                      true,
                                                                                    );
                                                                                    setRemarkChild(
                                                                                      children,
                                                                                    );
                                                                                    setmCarDealerVisitId(
                                                                                      data.id,
                                                                                    );
                                                                                    setRemarkValue(
                                                                                      remarkChild.remarkContent,
                                                                                    );
                                                                                    setremarkRecordId(
                                                                                      child.id,
                                                                                    );
                                                                                    setVisitPlanId(
                                                                                      data.id,
                                                                                    );
                                                                                    remarkform?.setFieldsValue(
                                                                                      {
                                                                                        appraiseType:
                                                                                          remarkChild.appraiseType,
                                                                                      },
                                                                                    );
                                                                                    setAnnotations(
                                                                                      remarkChild,
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  修改
                                                                                </div>
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              styles.remark_child
                                                                            }
                                                                          >
                                                                            {
                                                                              remarkChild.remarkContent
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                );
                                                              },
                                                            )}
                                                        </div>
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              {pchildren.mRemarkRecordDetails &&
                                                pchildren.mRemarkRecordDetails
                                                  .length > 3 && (
                                                  <div
                                                    style={{
                                                      textAlign: 'right',
                                                      color: 'blue',
                                                    }}
                                                    onClick={(e) => {
                                                      moreMap[`${pcIndex}`] =
                                                        !moreMap[`${pcIndex}`];
                                                      setMoreMap({
                                                        ...moreMap,
                                                      });
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                    }}
                                                  >
                                                    {moreMap[`${pcIndex}`]
                                                      ? '折叠-'
                                                      : '展开+'}
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        },
                                      )}
                                  </div>
                                );
                              },
                            )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Card>
          </PullToRefresh>
        </div>
        <div className={styles.visible4}>
          <Popup
            visible={visible4}
            showCloseButton
            onClose={() => {
              setVisible4(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '40vh',
              paddingBottom: '20px',
            }}
          >
            <Form form={remarkform}>
              <List>
                <div className={styles.formList}>
                  <div className={styles.remark_p}>
                    <div className={styles.remark_content}>
                      <div className={styles.remark_content_header}>
                        <div className={styles.remark_content_header_staff}>
                          <img
                            className={styles.remark_content_header_staff_0}
                            src={remarkChild.url}
                            alt=""
                          />
                          <div className={styles.remark_content_header_staff_1}>
                            <div
                              className={
                                styles.remark_content_header_staff_1_name
                              }
                            >
                              {remarkChild.remarkLabelUserName
                                ? remarkChild.remarkLabelUserName
                                : '-'}
                              <span>
                                {parseResultName(`${remarkChild.appraiseType}`)}
                              </span>
                            </div>
                            <div
                              className={
                                styles.remark_content_header_staff_1_time
                              }
                            >
                              {remarkChild.createTime
                                ? dateFormat(
                                    remarkChild.createTime,
                                    'yyyy-MM-dd hh:mm:ss',
                                  )
                                : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.remark_child}>
                        {remarkChild.remarkContent}
                      </div>
                    </div>
                  </div>
                </div>
                <List.Item prefix="评分">
                  <Form.Item
                    name="appraiseType"
                    rules={[{ required: true, message: '选择评分' }]}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      {/* <Radio value={1}>极好</Radio> */}
                      <Radio value={2}>好</Radio>
                      <Radio value={3}>中</Radio>
                      <Radio value={4}>差</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <TextArea
                  placeholder="请输入批注"
                  maxLength={100}
                  rows={3}
                  showCount
                  value={remarkValue}
                  onChange={(v) => {
                    setRemarkValue(v);
                  }}
                />
              </List>
            </Form>
            <div className={styles.button_4}>
              <Button
                block
                type="submit"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  onFinishRemark();
                }}
              >
                提交
              </Button>
            </div>
          </Popup>
          <Popup
            visible={visible5}
            showCloseButton
            onClose={() => {
              setVisible5(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '40vh',
              paddingBottom: '20px',
            }}
          >
            {/* {console.log(remarkChild)} */}

            <Form form={remarkform}>
              <List>
                <div className={styles.formList}>
                  <div className={styles.remark_p}>
                    <div className={styles.remark_content}>
                      <div className={styles.remark_content_header}>
                        <div className={styles.remark_content_header_staff}>
                          <img
                            className={styles.remark_content_header_staff_0}
                            src={remarkChild.url}
                            alt=""
                          />
                          <div className={styles.remark_content_header_staff_1}>
                            <div
                              className={
                                styles.remark_content_header_staff_1_name
                              }
                            >
                              {remarkChild.remarkLabelUserName
                                ? remarkChild.remarkLabelUserName
                                : '-'}
                              <span>
                                {parseResultName(`${remarkChild.appraiseType}`)}
                              </span>
                            </div>
                            <div
                              className={
                                styles.remark_content_header_staff_1_time
                              }
                            >
                              {remarkChild.createTime
                                ? dateFormat(
                                    remarkChild.createTime,
                                    'yyyy-MM-dd hh:mm:ss',
                                  )
                                : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.remark_child}>
                        {remarkChild.remarkContent}
                      </div>
                    </div>
                  </div>
                </div>
                <List.Item prefix="评分">
                  <Form.Item
                    name="appraiseType"
                    rules={[{ required: true, message: '选择评分' }]}
                    className={styles.form_item}
                    // initialValue={annotations.appraiseType}
                  >
                    <Radio.Group>
                      {/* <Radio value={1}>极好</Radio> */}
                      <Radio value={2}>好</Radio>
                      <Radio value={3}>中</Radio>
                      <Radio value={4}>差</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <TextArea
                  placeholder="请输入批注"
                  maxLength={100}
                  rows={3}
                  showCount
                  value={remarkValue}
                  // defaultValue={annotations.remarkContent}
                  onChange={(v) => {
                    setRemarkValue(v);
                  }}
                />
              </List>
            </Form>
            <div className={styles.button_4}>
              <Button
                block
                type="submit"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  onFinishAnnotations();
                }}
              >
                提交
              </Button>
            </div>
          </Popup>

          <Popup
            visible={visible6}
            showCloseButton
            onClose={() => {
              setVisible6(false);
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '30vh',
              padding: '20px',
            }}
          >
            <Form form={remarkform}>
              {record && <div> 突破逻辑：{record.breakThroughLogic || ''}</div>}
              <List>
                <TextArea
                  placeholder="请输入批注"
                  maxLength={100}
                  rows={5}
                  showCount
                  value={marginalValue}
                  onChange={(v) => {
                    setMarginalValue(v);
                  }}
                />
              </List>
            </Form>
            <div className={styles.button_4}>
              <Button
                block
                type="submit"
                color="primary"
                onClick={() => {
                  if(marginalValue != ""){
                    setLoading(true);
                    onFinishMarginal(record);
                  }else{
                    Toast.show({
                      icon: 'fail',
                      content: '批注不可为空',
                    });
                  }
                }}
              >
                提交
              </Button>
            </div>
          </Popup>
        </div>
      </div>
      <ImageViewer
        image={allImage}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />
    </div>
  );
}
