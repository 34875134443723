import { Button, Checkbox, CheckList, InfiniteScroll, List, SearchBar } from "antd-mobile";
import SpinLoading from "antd-mobile/es/components/spin-loading";
import Toast from "antd-mobile/es/components/toast";
import { useEffect, useRef, useState } from "react";
import { history,useLocation } from "umi";
import Navbar from "../utils/Navbar";
import { marketList } from "./service";
import styles from './index.less';
export default function Market() {
  const [checkList, setCheckList] = useState<any>([])
  const [value, setValue] = useState<any>([])
  const [marketName, setmarketName] = useState<string>('')
  const [pageNo, setpageNo] = useState<number>(1)
  const [hasMore, setHasMore] = useState(true)
  const location = useLocation();
  const dataFlag:any = location.state;
  const infoRef = useRef();

  const initValue = async(params:any)=>{
    if(params.pageNo === -1){
      setCheckList([])
      params.pageNo = 1
    }
    if(infoRef.current){
      params.marketName = infoRef.current
    }
    let num =  params.pageNo + 1
    setpageNo(num)
    const append = await marketList(params)
    setCheckList((val: any)=>[...val,...append])
    setHasMore(append.length > 0)
  }
  const onChange = (v: any)=>{
    console.log(v);
    setValue(v)
    
  }
  const onSearch = async (value: string) => {
    const params: any = {
      pageNo: -1,
      pageSize: 20,
    };
    // @ts-ignore
    infoRef.current = value
    setpageNo(1)
    setmarketName(value)
    initValue(params)
    // const msg = await ListMore(params);
    // if (msg.retCode === 0) {
    //   setData(msg.data.datas);
    //   console.log(msg.data.datas);
      
    // }
  };
  useEffect(() => {
    // initValue()
  }, []);
  return(
    <div className={styles.market}>
      <Navbar name= {"选择市场"}></Navbar>
      <div className={styles.search}>
        <SearchBar
          onSearch={onSearch}
          placeholder="请输入市场名"
        />
      </div>
      <CheckList multiple onChange={onChange}>
        {checkList.length>0 && checkList.map((item:any)=>{
          return<CheckList.Item value={item}>{item.marketName}</CheckList.Item>
        })}
      </CheckList>
      <InfiniteScroll loadMore={(isRetry)=>{
        return initValue({
          pageNo:pageNo,
          pageSize:20,
        });
      }} hasMore={hasMore} />
      <div style={{height:"60px"}}></div>
      <div className={styles.footer}>
        <div>
          已选：{value.length} 个
        </div>
        <div className={styles.button} >
          <Button color='primary' fill='outline' onClick={()=>{
            let data:any= {}
            if(dataFlag){
              data = dataFlag
            }
            data.market = false
            data.merchant = true
            if(dataFlag?.businesses){
              history.replace("./businesses",dataFlag)
            }else if(dataFlag?.payAVisit){
              history.replace("./PayAVisit",dataFlag)
            }else if(dataFlag?.back){
              history.replace("./see",dataFlag)
            }else if(dataFlag?.detail){
              history.replace("./detailEdit",dataFlag)
            }else{
              history.replace("./merchant",data)
            }
          }}>
            取消
          </Button>
          <Button size='middle' color='primary' onClick={()=>{
            if(value && value.length>0){
              let data:any= {}
              if(dataFlag){
                data = dataFlag
              }
              console.log(data);
              data.value = value
              data.market = false
              data.merchant = true
              if(dataFlag?.businesses){
                history.replace("./businesses",data)
              }else if(dataFlag?.payAVisit){
                const values = dataFlag
                values.value = value
                history.replace("./PayAVisit",values)
              }else if(dataFlag?.back){
                const values = dataFlag
                values.value = value
                history.replace("./see",values)
              }else if(dataFlag?.detail){
                const values = dataFlag
                values.value = value
                history.replace("./detailEdit",values)
              }else{
                history.replace("./merchant",data)
              }
            }
          }}>
            确定
          </Button>
        </div>
      </div>
    </div>
  )
}