import { RequestInterceptor } from 'umi-request';
import type { RequestConfig } from 'umi';
import { Toast } from 'antd-mobile/es';
import dayjs from 'dayjs';

const optionResult: RequestInterceptor = (
  url: string,
  options: RequestInit,
) => {
  if (window.wz && window.wz.getToken()) {
    const token = window.wz.getToken();
    options.headers = {};
    options.headers['Authorization'] = `Bearer ${token}`;
  } else {
    if (localStorage.getItem('token')) {
      console.log(url);
      if(url != "/usersync/user/sync"){
        const token = localStorage.getItem('token');
        options.headers = {};
        options.headers['Authorization'] = `Bearer ${token}`;
      }
    }
  }
  return { url, options };
};


export const request: RequestConfig = {
  errorHandler: (error: any) => {
    const { response } = error;
    if (!response) {
      Toast.show({
        icon: 'fail',
        content: '网络异常',
      });
    }
    throw error;
  },
  timeout: 60000,
  requestInterceptors: [optionResult],
};
