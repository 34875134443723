// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
const username = 'admin';
const password = 'wzyc2015';
const basicAuth = Buffer.from(`${username}:${password}`).toString('base64');

export async function login(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/login', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getStatistics(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/statistics/getStatistics', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function createOrder(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/createOrder', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getList(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/goodsGroupConfig/getListForBatchBuy', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function financialConfirm(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/financialConfirm', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function couponExchangeFinancialConfirm(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/couponExchange/financialConfirm', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function financialRefuse(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/financialRefuse', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function hasPurchaseRecord(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/purchaseView/hasPurchaseRecord', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getCompanyCouponList(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/coupon/getCompanyCouponList', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function RewindCreateOrder(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/couponExchange/createOrder', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function collectPrice(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/collectPrice', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getWxAccessToken(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/wx/jsapi/ticket/getJsapiTicket', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function confirmCouponAgain(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/confirmCouponAgain', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function uploadPaymentProof(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/uploadPaymentProof', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function couponExchangeUploadPaymentProof(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/couponExchange/uploadPaymentProof', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function cancelOrder(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/batchBuy/cancelOrder', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}

export async function confirmRRewindCouponAgain(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/couponExchange/confirmCouponAgain', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function couponExchangeCancelOrder(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/couponExchange/cancelOrder', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function saveCompanyBelongMarket(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/company/info/saveCompanyBelongMarket', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function orderList(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/batchBuy/getList', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0) {
    return msg.data
  } else {
    return {totalPage:0}
  }
}
export async function couponExchangeOrderList(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  delete params.payStatus
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api//couponExchange/getList', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0) {
    let list = msg.data;
    if(msg.data.datas.length > 0){
      msg.data.datas.map((item:any,index:number)=> {
        list.datas[index].batchBuyNo = item.exchangeNo
      })
    }
    return list
  } else {
    return {totalPage:0}
  }
}

export async function getCompanyCoupon(data: any, options?: { [key: string]: any }) {
  return await request<{
    retCode?: number;
    retMsg?: string;
    data?: any;
  }>('/coupon/open/getCompanyCoupon', {
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${basicAuth}`,
    },
    ...(options || {}),
  });
}
export async function getCouponRecord(data: any, options?: { [key: string]: any }) {
  return await request<{
    message: any;
    retCode?: number;
    retMsg?: string;
    data?: any;
  }>('/coupon/open/getCouponRecord', {
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${basicAuth}`,
    },
    ...(options || {}),
  });
}
export async function deleteCouponByIds(data: any, options?: { [key: string]: any }) {
  return await request<{
    message: any;
    retCode?: number;
    retMsg?: string;
    data?: any;
  }>('/coupon/coupon/deleteCouponByIds', {
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${basicAuth}`,
    },
    ...(options || {}),
  });
}