// @ts-ignore
/* eslint-disable */
import {
  Button,
  Card,
  CheckList,
  DatePicker,
  Form,
  Image,
  ImageViewer,
  Input,
  List,
  Modal,
  Picker,
  Popup,
  Radio,
  Stepper,
  Swiper,
  SwiperRef,
  TextArea,
  Toast,
} from 'antd-mobile/es';
import { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import {
  BrandTypeList,
  cooperationFrequencyList,
  customerList,
  degreeIntentList,
  detectionMethodList,
  displayTypeList,
  mediaLists,
  partnershipList,
  priceRangeList,
  reasonsForFailure,
  reasonsForFailureAll,
  reasonsForFailureNew,
  recommendedReasonsList,
  serveTypeList,
  vehicleTypeList,
  visitResultList,
} from '../utils/signIn';
import {
  checkInDetails,
  getAddress,
  orglist,
  visitDetail,
  infoSave,
  planCompanyDate,
  firstSignIn,
} from './service';
import dayjs from 'dayjs';
import { history, useLocation } from 'umi';
import { labellist, remarkLists } from '../programme/service';
import { getWxAccessToken } from '../login/service';
import Login from '../login';
import { getMCarDealerVisitVo } from '../details/service';
import Merchant from '../comments/Merchant';
import Market from '../comments/Market';
import {
  CameraOutline,
  DeleteOutline,
  EditSOutline,
} from 'antd-mobile-icons';
import { Lists } from '../merchant/service';
export const isIOS = () => {
  var ua = navigator.userAgent.toLocaleLowerCase();
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  return isIOS;
};
const dateJs = () => {
  const year = dayjs().year();
  const month = dayjs().month() + 1;
  const date = dayjs().date() + 1;
  return new Date(`${year}-${month}-${date} 00:00:00`);
};
export const base64ImgtoFile = (dataurl: string, filename = 'file') => {
  let arr = dataurl.split(',');
  // @ts-ignore
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split('/')[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
};
export default function See() {
  const [form] = Form.useForm();
  const ref = useRef<SwiperRef>(null);
  const [partnerform] = Form.useForm();
  const [customerform] = Form.useForm();
  const [mediaform] = Form.useForm();
  const [merchantsform] = Form.useForm();
  const [formPrice] = Form.useForm();
  const [testOrgIdform] = Form.useForm();
  const [remarkform] = Form.useForm();
  const [serveTypeVisible, setserveTypeVisible] = useState<boolean>(false);
  const [visitSignTypeVisible, setvisitSignTypeVisible] =
    useState<boolean>(false);
  const [visitResultVisible, setvisitResultVisible] = useState<boolean>(false);
  const [visitList, setVisitList] = useState<any>([]);
  const [visitReasonsVisible, setvisitReasonsVisible] =
    useState<boolean>(false);
  const [displayTypeVisible, setdisplayTypeVisible] = useState<boolean>(false);
  const [detectionMethodVisible, setdetectionMethodVisible] = useState<boolean>(false);
  const [outerMerchant, setouterMerchant] = useState<string>('');
  const [nameVisible, setNameVisible] = useState<boolean>(false);
  const [marketVisible, setMarketVisible] = useState<boolean>(false);
  const [timeVisible, setTimeVisible] = useState<boolean>(false);
  const [detectOrgNameVisible, setdetectOrgNameVisible] =
    useState<boolean>(false);
  const [visible4, setVisible4] = useState<boolean>(false);
  const [mediaVisible, setmediaVisible] = useState<boolean>(false);
  const [priceVisible, setPriceVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visible2, setVisible2] = useState<boolean>(false);
  const [visible3, setVisible3] = useState<boolean>(false);
  const [merchantsVisible, setmerchantsVisible] = useState<boolean>(false);
  const [merchantsNumber, setmerchantsNumber] = useState<number>(-1);
  const [merchantsList, setmerchantsList] = useState<any>([]);
  const [serveType, setserveType] = useState<any>([]);
  const [testOrgId, settestOrgId] = useState<any>([]);
  const [visitSignType, setvisitSignType] = useState<any>([]);
  const [brandTypeVisible, setbrandTypeVisible] = useState<boolean>(false);
  const [vehicleTypeVisible, setvehicleTypeVisible] = useState<boolean>(false);
  const [priceRangeVisible, setpriceRangeVisible] = useState<boolean>(false);
  const [partnershipVisible, setpartnershipVisible] = useState<boolean>(false);
  const [visitResult, setvisitResult] = useState<any>(
    localStorage.getItem('visitResult'),
  );
  const [displayType, setdisplayType] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [mediaList, setmediaList] = useState<any>([]);
  const [testOrgList, settestOrgList] = useState<any>([]);
  const [customerLists, setcustomerLists] = useState<any>([]);
  // const [belongMarketId, setbelongMarketId] = useState<number>(-1);
  const [mediaType, setmediaType] = useState<any>([]);
  const [orgPicket, setorgPicket] = useState<any>([]);
  // const [companyId, setCompanyId] = useState<any>([]);
  const [visitContent, setvisitContent] = useState<string>('');
  const [visitSummary, setVisitSummary] = useState<string>('');
  const [regionAnnotations, setregionAnnotations] = useState<string>('');
  const [breakThroughLogic, setBreakThroughLogic] = useState<string>('');
  const [MeritList, setMeritList] = useState<any>([]);
  const [remarkValue, setRemarkValue] = useState<string>('');
  const [remarkChild, setRemarkChild] = useState<any>([]);
  const [remark, setRemark] = useState<any>([]);
  const [carLabelIds, setcarLabelIds] = useState<any>([]);
  const [doorway, setDoorway] = useState<any>([]);
  const [point, setPoint] = useState<any>([]);
  const [advertise, setadvertise] = useState<any>([]);
  const [customerVisible, setCustomerVisible] = useState<boolean>(false);
  const [customerVisible2, setCustomerVisible2] = useState<boolean>(false);
  const [defaultIndexPoint, setDefaultIndexPoint] = useState<number>(0);
  const [energyType, setenergyType] = useState<number>(100);
  const [PremiumImgs, setPremiumImg] = useState<any>(
    localStorage.getItem('premiumImg'),
  );
  const [priceValue, setPriceValue] = useState<any[]>([]);
  const [initcarLabelIds, setinitcarLabelIds] = useState<any>([]);
  const [selectedValuePoints, setSelectedValuePoints] = useState<any>([]);
  const [allValuePoint, setAllValuePoint] = useState<any>([]);
  const [localIdsImgs, setlocalIdsImg] = useState<string | null>(
    localStorage.getItem('localIdsImg'),
  );
  const [pointImgs, setpointImg] = useState<string | null>(
    localStorage.getItem('pointImg'),
  );
  const [advertiseImgs, setadvertiseImg] = useState<string | null>(
    localStorage.getItem('advertiseImg'),
  );
  const [powerModelImgs, setpowerModelImg] = useState<string | null>(
    localStorage.getItem('powerModelImg'),
  );
  const [address, setAddress] = useState<string>('');
  const [partnerNumber, setpartnerNumber] = useState<number>(-1);
  const [mediaNumber, setmediaNumber] = useState<number>(-1);
  const [testOrgNumber, settestOrgNumber] = useState<number>(-1);
  const [customerNumber, setcustomerNumber] = useState<number>(-1);
  const [ids, setIds] = useState<number>();
  const [edit, setEdit] = useState<number>();
  const [editId, setEditId] = useState<number>();
  const [allImage, setAllImage] = useState<any>([]);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [typeList, setTypeList] = useState<any>([]);
  const [redioFlag, setRedioFlag] = useState<any>(1);
  const [redioFlag2, setRedioFlag2] = useState<any>(1);
  const [redioFlag3, setRedioFlag3] = useState<any>(1);
  const [moreMap, setMoreMap] = useState<any>({});
  const avatar = localStorage.getItem('avatar');
  const [companyDeatail, setCompanyDeatail] = useState<any>([]);
  const [couponUsedStatVos, setcouponUsedStatVos] = useState<any>([]);
  const [degreeIntentVisible, setdegreeIntentVisible] =
    useState<boolean>(false);
  const [newCarDealers, setNewCarDealers] = useState<boolean>(true);
  const [degreeIntent, setdegreeIntent] = useState<number>();
  const [recommendationsVisible, setRecommendationsVisible] =
    useState<boolean>(false);
  const [recommendationsList, setrecommendationsList] = useState<any>([]);
  const [scoreList, setScoreList] = useState<any>([]);
  const location = useLocation();
  const dataFlag: any = location.state;
  const belongMarketId = localStorage.getItem('belongMarketId');
  const localIdsImg = localStorage.getItem('localIdsImg');
  const pointImg = localStorage.getItem('pointImg');
  const advertiseImg = localStorage.getItem('advertiseImg');
  const powerModelImg = localStorage.getItem('powerModelImg');
  const premiumImg = localStorage.getItem('premiumImg');
  const [checkCollect, setCheckCollect] = useState<any>([]);
  const [collectVisible, setCollectVisible] = useState<boolean>(false);
  const [recommendedReasonsVisible, setrecommendedReasonsVisible] = useState<boolean>(false);
  const [cooperationFrequencyVisible, setcooperationFrequencyVisible] = useState<boolean>(false);
  const [contactMobileNoFlag, setcontactMobileNoFlag] = useState<boolean>(true);
  const [nextVisitTimeVisible, setnextVisitTimeVisible] =
    useState<boolean>(false);
  const infoRef: any = useRef([]);
  const labelRenderer = useCallback((type: string, data: number) => {
    switch (type) {
      case 'year':
        return data + '年';
      case 'month':
        return data + '月';
      default:
        return data;
    }
  }, []);
  const offeringsList = ['基础检测', '升级检测', '豪车检测', '数据车况'];
  const onChanger = (v: any) => {
    console.log(v);
    setRedioFlag(v);
  };
  const onChanged = (v: any) => {
    console.log(v);
    setRedioFlag2(v);
  };
  const onChangec = (v: any) => {
    console.log(v);

    setRedioFlag3(v);
  };
  // 异地推荐
  const goTobackRecommendations = () => {
    setRecommendationsVisible(true);
  };
  const handleRecommendationsCancel = async () => {
    setRecommendationsVisible(false);
  };
  const handelrecommendationsUserName = async (value: any) => {
    console.log(value);
    if (value.length > 0) {
      value.map((item: any) => {
        let arr = item.split('===');
        console.log(arr);
        form.setFieldValue('recommendations', arr[0]);
        setrecommendationsList({ companyId: arr[1], companyName: arr[0] });
      });
    }
    onValuesChange();
    setRecommendationsVisible(false);
  };
  const addressList = async (latitude: any, longitude: any) => {
    const msg = await getAddress({
      lat: latitude,
      lng: longitude,
    });
    if (msg.retCode === 0) {
      console.log(msg.data.result);
      setAddress(msg.data.result);
      form.setFieldValue('address', msg.data.result);
    }
  };
  const wxShareConfigs = async () => {
    // setUserId(["ChenHong"])
    // callOnBD(["ChenHong"])
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/",
    });
    console.log(wx);
    if (msg.retCode === 0) {
      await wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['getLocation'],
      });
      await wx.agentConfig({
        corpid: 'ww4168efadaace928a',
        agentid: '1000013',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['getLocation'],
        success: function (res) {
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: async function (res) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed; // 速度，以米/每秒计
          var accuracy = res.accuracy; // 位置精度
          addressList(latitude, longitude);
        },
      });
    }
  };
  const wxShareConfig = async (type: number) => {
    const msg = await getWxAccessToken({
      url: window.location.href,
      // url: "https://bd.wzyanche.com/see"
    });
    if (msg.retCode === 0) {
      wx.config({
        beta: true,
        debug: false,
        appId: 'ww4168efadaace928a',
        timestamp: msg.data.timestamp,
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: [
          'chooseImage',
          'getLocalImgData',
          'previewImage',
          'uploadImage',
          'downloadImage',
        ],
        success: function (res) {
          // 回调
        },
      });
      wx.agentConfig({
        corpid: 'ww4168efadaace928a', // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000013', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr,
        signature: msg.data.signature,
        jsApiList: ['uploadImage'], //必填，传入需要使用的接口名称
        success: function (res) {
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'normal', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res: { localIds: any }) {
          if (res.localIds <= 0) {
            return;
          }
          var localIds = res.localIds[0];
          wx.uploadImage({
            localId: localIds.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
              var serverId = res.serverId; // 返回图片的服务器端ID
              console.log(serverId);
              if (type === 1) {
                // setDoorway({
                //   mediaId: serverId,
                //   urlName: '门头近照',
                //   urlType: type,
                // });
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '门头近照',
                  urlType: type,
                });
                localStorage.setItem('doorway', list);
                // infoRef.current.doorway = {
                //   mediaId: serverId,
                //   urlName: '门头近照',
                //   urlType: type,
                // }
                // localStorage.setItem("doorway")
              }
              if (type === 2) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '门头含车位',
                  urlType: type,
                });
                localStorage.setItem('point', list);
              }
              if (type === 3) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '竞队广告',
                  urlType: type,
                });
                localStorage.setItem('advertise', list);
              }
              if (type === 4) {
                let list = JSON.stringify({
                  mediaId: serverId,
                  urlName: '签到证明',
                  urlType: type,
                });
                localStorage.setItem('powerModel', list);
              }
            },
            errMsg: function (res) {
              Toast.show({
                icon: 'fail',
                content: '上传失败',
              });
              return;
            },
          });
          if (isIOS() === true) {
            wx.getLocalImgData({
              localId: localIds, // 图片的localID
              success: function (res) {
                var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                if (type === 1) {
                  setlocalIdsImg(localData);
                  localStorage.setItem('localIdsImg', localData);
                  infoRef.current.localIdsImg = localData;
                }
                if (type === 2) {
                  setpointImg(localData);
                  localStorage.setItem('pointImg', localData);
                  infoRef.current.pointImg = localData;
                }
                if (type === 3) {
                  setadvertiseImg(localData);
                  localStorage.setItem('advertiseImg', localData);
                  infoRef.current.advertiseImg = localData;
                }
                if (type === 4) {
                  setpowerModelImg(localData);
                  localStorage.setItem('powerModelImg', localData);
                  infoRef.current.powerModelImg = localData;
                }
                if (type === 5) {
                  setPremiumImg(localData);
                  localStorage.setItem('premiumImg', localData);
                  infoRef.current.premiumImg = localData;
                }
              },
            });
          } else {
            if (type === 1) {
              setlocalIdsImg(localIds);
              localStorage.setItem('localIdsImg', localIds);
              infoRef.current.localIdsImg = localIds;
            }
            if (type === 2) {
              setpointImg(localIds);
              localStorage.setItem('pointImg', localIds);
              infoRef.current.pointImg = localIds;
            }
            if (type === 3) {
              setadvertiseImg(localIds);
              localStorage.setItem('advertiseImg', localIds);
              infoRef.current.advertiseImg = localIds;
            }
            if (type === 4) {
              setpowerModelImg(localIds);
              localStorage.setItem('powerModelImg', localIds);
              infoRef.current.powerModelImg = localIds;
            }
            if (type === 5) {
              setPremiumImg(localIds);
              localStorage.setItem('premiumImg', localIds);
              infoRef.current.premiumImg = localIds;
            }
          }
          // onValuesChange();
        },
      });
    }
  };
  const valueNode = async (companyId: any, userId: any) => {
    const score = await remarkLists({
      companyId: companyId,
      userId: userId != 1 ? userId : localStorage.getItem('userId'),
    });
    if (score.data) {
      setScoreList({ data: score.data, companyId: companyId });
      score.data.map((item: any) => {
        if (item.labelName === '专业人设') {
          valuePoint({ companyId: companyId, parentId: item.labelId });
        }
      });
    }
  };
  const valuePoint = async (value: any) => {
    const res = await labellist({
      companyId: value.companyId,
      parentId: value.parentId,
    });
    if (res.retCode === 0) {
      setMeritList(res.data);
    }
  };
  const initValue = async (dataFlag: any) => {
    console.log(dataFlag);

    if (dataFlag.visitCompanyRelations && dataFlag.visitSignType === 13) {
      console.log(dataFlag.visitCompanyRelations, '-------------');

      setrecommendationsList({
        companyId: dataFlag.visitCompanyRelations[0].companyId,
        companyName: dataFlag.visitCompanyRelations[0].companyName,
      });
      form.setFieldValue(
        'recommendations',
        dataFlag.visitCompanyRelations[0].companyName,
      );
    }
    if (
      dataFlag.companyId &&
      dataFlag.companyId != [] &&
      dataFlag.companyId != -1 &&
      dataFlag.storage != 1
    ) {
      // setCompanyId(dataFlag.companyId);
      const msg = await getMCarDealerVisitVo({
        companyId: dataFlag.companyId,
      });
      valueNode(dataFlag.companyId, 1);
      if (msg.retCode === 0) {
        setCompanyDeatail(msg.data);
        form?.setFieldsValue(msg.data.mCarDealers);
        form?.setFieldsValue(msg.data.mCompanyInfo);
        const list = msg.data;
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0) {
          list.mCarDealerPartners.map((item: any) => {
            setPartnerList(list.mCarDealerPartners);
          });
        }
      }
      valuePoint({ companyId: dataFlag.companyId });
    } else {
      valuePoint({});
    }
    if (dataFlag.id) {
      const append = await visitDetail({
        id: dataFlag.id,
      });
      if (append.data && append.data.userId && append.data.companyId) {
        valueNode(append.data.companyId, append.data.userId);
        const list = append.data
        // 客户来源
        if (list.customerSources && list.customerSources.length > 0) {
          setcustomerLists(list.customerSources);
        }
        // 推荐商户
        if (list.recommendedMerchants && list.recommendedMerchants.length > 0) {
          setmerchantsList(list.recommendedMerchants);
        }
      }
    }
    form?.setFieldsValue(dataFlag);
    form?.setFieldsValue({ companyName: dataFlag.companyName });
    // form?.setFieldsValue({address: dataFlag.contactDetailAddress });
    if (dataFlag.partnerInfoList) {
      let list: any = []
      dataFlag.partnerInfoList.map((item: any, index: any) => {
        list.push(item)
        // partnerList[index] = item;
        setPartnerList(list);
      });
    }
    if (dataFlag.id) {
      setIds(dataFlag.id);
    }
    if (dataFlag && dataFlag.serveType) {
      // infoRef.current.serveType = dataFlag?.serveType
      serveTypeList[0].map((item: any) => {
        if (dataFlag.serveType === item.value) {
          form?.setFieldsValue({ serveTypeName: item.label });
          // setserveType(dataFlag.serveType);
        }
      });
    }
    // onValuesChange();
  };
  const onFinish = async () => {
    const doorway = localStorage.getItem('doorway');
    const point = localStorage.getItem('point');
    if ((!doorway || !point) && form.getFieldsValue().serveType === 1) {
      setLoading(false);
      Toast.show({
        icon: 'fail',
        content: `请上传图片！！`,
      });
      return;
    }
    form.validateFields().then(() => {
      form.validateFields().then(() => {
        submit();
        setLoading(true);
      });
    });
  };
  const submit = async () => {
    const companyId = localStorage.getItem('companyId');
    const values = form.getFieldsValue();
    const valueComany = form.getFieldsValue();
    console.log(values);
    let visitSignTypeCompanyId: number = 0;
    let list: any = {
      companyName: valueComany.companyName,
      contactName: valueComany.contactName,
      contactMobileNo: valueComany.contactMobileNo,
      belongMarketName: valueComany.belongMarketName,
      belongingRegion: valueComany.belongingRegion,
      doorplate: valueComany.doorplate,
      floor: valueComany.floor,
      companyId:
        visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
    };
    if (belongMarketId) {
      list.belongMarketId = belongMarketId;
    }
    values.visitPlanId = dataFlag.id;
    values.visitSignType = dataFlag.visitSignType;
    values.companyId =
      visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId;
    values.companyName = valueComany.companyName;
    values.token = localStorage.getItem('token');
    values.carDealerBasicOutputDTO = list;
    values.address = address;
    if (dataFlag.status && dataFlag.status === 4) {
      values.id = editId;
      if (!dataFlag.edit) {
        delete values.visitPlanId;
        delete values.id;
      }
    }
    let listImg: any = [];
    if (localStorage.getItem('doorway')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('doorway'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('point')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('point'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('advertise')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('advertise'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('powerModel')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('powerModel'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    if (localStorage.getItem('premium')) {
      // @ts-ignore
      let str = JSON.parse(localStorage.getItem('premium'));
      listImg.push({
        urlName: str.urlName,
        urlType: str.urlType,
        mediaId: str.mediaId,
        id: str.id,
        companyName: values.companyName,
        companyId:
          visitSignTypeCompanyId === 0 ? companyId : visitSignTypeCompanyId,
        carDealerVisitId: ids,
      });
    }
    values.checkInPictureVos = listImg;
    firstSignIn(values)
      .then((msg) => {
        setLoading(false);
        if (msg.retCode === 0) {
          Toast.show({
            icon: 'success',
            content: '签到成功',
          });
          localStorage.removeItem('values');
          localStorage.removeItem('visitContent');
          localStorage.removeItem('visitSummary');
          localStorage.removeItem('breakThroughLogic');
          localStorage.removeItem('companyId');
          localStorage.removeItem('visitResult');
          localStorage.removeItem('belongMarketId');
          localStorage.removeItem('localIdsImg');
          localStorage.removeItem('pointImg');
          localStorage.removeItem('advertiseImg');
          localStorage.removeItem('powerModelImg');
          localStorage.removeItem('premiumImg');
          localStorage.removeItem('doorway');
          localStorage.removeItem('point');
          localStorage.removeItem('advertise');
          localStorage.removeItem('powerModel');
          localStorage.removeItem('premium');
          history.replace('./PayAVisit');
        } else {
          Toast.show({
            icon: 'fail',
            content: msg.retMsg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: '提交失败',
        });
      });
  };
  const onRemarkFinish = (item: any, flag: number) => {
    const values = remarkform.getFieldsValue();
    if (!values[`remarkContent${item.id}`]) {
      Toast.show({
        icon: 'fail',
        content: '请填写备注！',
        // duration: 0,
      });
      return;
    }
    if (!values[`appraiseType${item.id}`]) {
      Toast.show({
        icon: 'fail',
        content: '请选择评分！',
        // duration: 0,
      });
      return;
    }
    let list: any = remark;
    if (allValuePoint && allValuePoint.length > 0 && flag === 1) {
      allValuePoint.map((itemPoint: any) => {
        if (
          itemPoint.mCarDealerLabelVos &&
          itemPoint.mCarDealerLabelVos.length > 0
        ) {
          itemPoint.mCarDealerLabelVos.map((childPoint: any) => {
            if (
              values[`remarkContent${childPoint.id}`] &&
              values[`appraiseType${childPoint.id}`]
            ) {
              list[childPoint.id] = {
                remarkContent: values[`remarkContent${childPoint.id}`],
                appraiseType: values[`appraiseType${childPoint.id}`],
                labelId: childPoint.id,
                labelName:childPoint.name,
                remarkLabelUserName: localStorage.getItem('name'),
                url: avatar,
                createTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
              };
            }
          });
        }
      });
    }else{
      list[item.id] = {
        remarkContent: values[`remarkContent${item.id}`],
        appraiseType: values[`appraiseType${item.id}`],
        labelId: item.id,
        labelName:item.name,
        remarkLabelUserName: localStorage.getItem('name'),
        url: avatar,
        createTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      };
    }
    list.filter((res: any) => !res);
    setRemark(list);
    console.log(list);

    setRemarkValue(values);
    onValuesChange();
    if (flag === 1) {
      ref.current?.swipeNext();
    } else {
      setVisible4(false);
    }
  };
  const init = async () => {
    wxShareConfigs();
    const msg = await orglist();
    if (msg.retCode === 0) {
      console.log(msg.data);
      let list: any = [];
      msg.data.map((item: any) => {
        list.push({
          label: item.partnerName,
          value: item.id,
        });
      });
      setorgPicket([list]);
    }
    if (!dataFlag.companyId) {
      valuePoint({});
    }
  };
  const partnerSubmit = (type: number) => {
    const values = partnerform.getFieldsValue();
    values.companyId = localStorage.getItem('companyId');
    if (partnerNumber === -1) {
      let num = partnerList.length;
      partnerList[num] = values;
    } else {
      partnerList[partnerNumber] = values;
    }
    if(values.partnershipType === 4){
      values.partnership = values.partnershipName
    }
    onValuesChange();
    setPartnerList([...partnerList]);
    if (type === 1) {
      partnerform.resetFields();
    } else {
      // setVisible(false)
    }
  };
  useEffect(() => {
    init();
    if (localStorage.getItem('values')) {
      let value = localStorage.getItem('values');
      if (value) {
        let json = JSON.parse(value);
        json.visitContent = localStorage.getItem('visitContent');
        json.breakThroughLogic = localStorage.getItem('breakThroughLogic');
        json.visitSummary = localStorage.getItem('visitSummary');
        json.storage = 1;
        initValue(json);
      }
    }
    if (dataFlag && dataFlag.carLabelIds) {
      let list: any = [];
      labellist({}).then((res) => {
        if (res.retCode === 0) {
          setAllValuePoint(res.data);
          res.data.map((item: any) => {
            if (item.mCarDealerLabelVos && item.mCarDealerLabelVos.length > 0) {
              item.mCarDealerLabelVos.map((child: any) => {
                if (dataFlag.carLabelIds.indexOf(child.id) > -1) {
                  list.push(child);
                }
              });
            }
          });
        }
      });
      console.log(list, dataFlag.carLabelIds);
      setSelectedValuePoints(list);
    }
    if (dataFlag) {
      if (dataFlag.companyId && dataFlag.companyId != -1) {
        localStorage.setItem('companyId', dataFlag.companyId);
      }
      if(localStorage.getItem('values')){
        dataFlag.storage = 1
      }
      initValue(dataFlag);
      if (dataFlag.carLabelIds) {
        setcarLabelIds(dataFlag.carLabelIds);
      }
      if (dataFlag.visitSignTypeName) {
        form?.setFieldsValue({ visitSignTypeName: dataFlag.visitSignTypeNam });
      }
      if (dataFlag.productName) {
        form?.setFieldsValue({ productName: dataFlag.productName });
      }
    }
  }, []);

  const onchanges = (v: any) => {
    setCheckCollect(v);
  };
  const handelUserName = async (value: any) => {
    console.log(value);
    // setCompanyId(value[0]);
    localStorage.setItem('companyId', value[0]);
    setmediaList([]);
    setPartnerList([]);
    settestOrgList([]);
    if (value.length > 0) {
      const msg = await getMCarDealerVisitVo({
        companyId: value[0],
      });
      valueNode(value[0], 1);
      if (msg.retCode === 0) {
        setCompanyDeatail(msg.data);
        form?.setFieldsValue(msg.data.mCarDealers);
        form?.setFieldsValue(msg.data.mCarDealers);
        form?.setFieldsValue(msg.data.mCompanyInfo);
        if (msg.data.mCompanyInfo) {
          setdegreeIntent(msg.data.mCompanyInfo.degreeIntent);
          infoRef.current.degreeIntent = msg.data.mCompanyInfo.degreeIntent;
        }
        // form?.setFieldsValue({ price: msg.data.mCarDealers.price });
        if (msg.data.mCarDealers) {
          // if (msg.data.mCarDealers.displayType) {
          //   // setdisplayType(msg.data.mCarDealers.displayType);
          //   infoRef.current.displayType = msg.data.mCarDealers.displayType;
          // }
          if (msg.data.mCompanyInfo.contactDetailAddress) {
            setAddress(msg.data.mCompanyInfo.contactDetailAddress);
          }
          if (msg.data.mCompanyInfo.belongMarketId) {
            localStorage.setItem(
              'belongMarketId',
              msg.data.mCompanyInfo.belongMarketId,
            );
          }
        }
        const list = msg.data;
        // 自媒体
        if (list.mCarDealerMedia && list.mCarDealerMedia.length > 0 && !dataFlag.storage) {
          list.mCarDealerMedia.map((item: any) => {
            if (item.account != '-1' && item.mediaName != '无') {
              setmediaList(list.mCarDealerMedia);
            } else {
              setRedioFlag2(2);
            }
          });
        } else {
          setRedioFlag2(2);
        }
        // 合伙人
        if (list.mCarDealerPartners && list.mCarDealerPartners.length > 0 && !dataFlag.storage) {
          list.mCarDealerPartners.map((item: any) => {
            if (item.partnerName === '无') {
              setRedioFlag(2);
            } else {
              setPartnerList(list.mCarDealerPartners);
            }
          });
        } else {
          setRedioFlag(2);
        }
        // 竞队信息
        if (list.carDealerCompetitors && list.carDealerCompetitors.length > 0 && !dataFlag.storage) {
          list.carDealerCompetitors.map((item: any) => {
            if (item.testOrgId != -1) {
              settestOrgList(list.carDealerCompetitors);
            } else {
              setRedioFlag3(2);
            }
          });
        } else {
          setRedioFlag3(2);
        }
        // if (msg.data.mCarDealers && msg.data.mCarDealers.displayType && !dataFlag.storage) {
        //   displayTypeList[0].map((item: any) => {
        //     if (msg.data.mCarDealers.displayType === item.value) {
        //       form?.setFieldsValue({ displayTypeName: item.label });
        //     }
        //   });
        // }
      }

      valuePoint({ companyId: value[0] });
    }
    setNameVisible(false);
    onValuesChange();
  };
  const toastValue = (value: any) => {
    let text = '';
    if (typeof value === 'number') {
      text = `${value}`;
    } else {
      text = `[${value.join(',')}]`;
    }
    setenergyType(value);
    console.log(value);
  };
  const handleUserNameCancel = async () => {
    setNameVisible(false);
  };
  const handelUserMarket = async (value: any) => {
    console.log(value);
    localStorage.setItem('belongMarketId', value[0].id);
    form.setFieldValue('belongMarketName', value[0].marketName);
    setMarketVisible(false);
    onValuesChange();
  };
  const handleUserMarketCancel = async () => {
    setMarketVisible(false);
  };
  const onValuesChange = () => {
    const values = form.getFieldsValue();
    const valuesComany = form.getFieldsValue();
    values.valuesComany = valuesComany;
    values.homeSee = true;
    values.visitSignType = infoRef.current.visitSignType;
    values.degreeIntent = infoRef.current.degreeIntent;
    values.visitResult = visitResult;
    // values.displayType = infoRef.current.displayType;
    values.partnerInfoList = partnerList;
    values.mediaInfoList = mediaList;
    values.customerLists = customerLists;
    values.merchantsList = merchantsList;
    values.competitorList = testOrgList;
    values.visitContent = visitContent;
    values.visitSummary = visitSummary;
    values.breakThroughLogic = breakThroughLogic;
    values.companyId = localStorage.getItem('companyId');
    values.localIdsImg = localIdsImg;
    values.doorway = infoRef.current.doorway;
    values.pointImg = pointImg;
    values.point = infoRef.current.point;
    values.advertiseImg = advertiseImg;
    values.advertise = infoRef.current.advertise;
    values.powerModelImg = powerModelImg;
    values.powerModel = infoRef.current.powerModel;
    values.PremiumImg = premiumImg;
    values.Premium = infoRef.current.premium;
    values.remark = remark;
    values.remarkValue = remarkValue;
    values.energyType = energyType;
    values.see = true;
    values.back = true;
    values.partner = partnerform.getFieldsValue();
    values.media = mediaform.getFieldsValue();
    values.testOrg = testOrgIdform.getFieldsValue();
    values.visitCompanyRelations = [recommendationsList];
    localStorage.setItem('values', JSON.stringify(values));
    console.log(values);
    
  };
  return (
    <div className={`${styles.card} ${styles.page}`}>
      <Navbar name={'拜访签到'}></Navbar>
      {loading && <Login></Login>}
      <div>
        <div style={{ paddingBottom: '50px' }}>
          <Card>
            <Form
              form={form}
              onValuesChange={onValuesChange}
              footer={
                <div className={styles.submit}>
                  <Button
                    block
                    type="submit"
                    color="primary"
                    onClick={() => {
                      onFinish();
                    }}
                  >
                    保存
                  </Button>
                </div>
              }
            >
              {dataFlag && dataFlag.id && (
                <div
                  style={{
                    fontSize: '14px',
                    paddingTop: '12px',
                    color: 'black',
                  }}
                >
                  计划ID：{dataFlag.id}
                </div>
              )}
              <List>
                <List.Item
                  prefix="拜访方式"
                  onClick={() => {
                    setserveTypeVisible(true);
                  }}
                  title={'*'}
                >
                  <Form.Item
                    name="serveTypeName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择拜访方式',
                            });
                            return Promise.reject(new Error('选择拜访方式'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="选择拜访方式" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="拜访方式"
                  onClick={() => {
                    setserveTypeVisible(true);
                  }}
                  title={'*'}
                  style={{display:"none"}}
                >
                  <Form.Item
                    name="serveType"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择拜访方式',
                            });
                            return Promise.reject(new Error('选择拜访方式'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="选择拜访方式" readOnly />
                  </Form.Item>
                </List.Item>
                {form.getFieldsValue().serveType === 1 && (
                  <>
                <List.Item prefix="门头近照" title={'*'} className={styles.form_item_img}>
                  <Form.Item name="doorway" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {localIdsImgs && (
                        <Image
                          src={localIdsImgs}
                          style={{width:"60px",height:"60px",marginRight:"5px",borderRadius:"5px"}}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(localIdsImgs);
                          }}
                        />
                      )}

                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(1);
                        }}
                      >
                        <CameraOutline fontSize={20} />
                        <div style={{ marginTop: '2px' }}>照片</div>
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                <List.Item prefix="门头含车位" title={'*'} className={styles.form_item_img}>
                  <Form.Item name="point" className={styles.form_item}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {pointImgs && (
                        <Image
                          src={pointImgs}
                          style={{width:"60px",height:"60px",marginRight:"5px",borderRadius:"5px"}}
                          fit="fill"
                          onClick={() => {
                            setImageVisible(true);
                            setAllImage(pointImgs);
                          }}
                        />
                      )}
                      <div
                        className={styles.increaseIcon}
                        onClick={() => {
                          wxShareConfig(2);
                        }}
                      >
                        <CameraOutline fontSize={20} />
                        <div style={{ marginTop: '2px' }}>照片</div>
                      </div>
                    </div>
                  </Form.Item>
                </List.Item>
                </>
                )}
                <List.Item
                  prefix="商户名称"
                  onClick={() => {
                    // setMarketVisible(true)

                    if (dataFlag.homeSee || dataFlag.visitSignType === 5) {
                      // goToback(0);
                      setNameVisible(true);
                    }
                  }}
                  title={'*'}
                  className={`${newCarDealers && styles.list_newCarDealers}`}
                >
                  <Form.Item
                    name="companyName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '输入车商名称',
                            });
                            return Promise.reject(new Error('输入车商名称'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="输入车商名称" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="联系人"
                  title={'*'}
                  style={{ display: 'none' }}
                >
                  <Form.Item
                    name="contactMobileNo"
                    className={styles.form_item}
                  >
                    <Input placeholder="输入联系姓名" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item
                  prefix="联系人"
                  title={'*'}
                  className={styles.form_item_partner}
                >
                  <Form.Item
                    name="contactName"
                    className={styles.form_item_3}
                  ></Form.Item>
                  <div className={styles.partnerList}>
                    <div className={styles.partnerList_0}>
                      <span>
                        {form.getFieldsValue().contactName} {form.getFieldsValue().contactMobileNo}
                      </span>
                      <span>
                        <EditSOutline
                          fontSize={20}
                          style={{marginLeft:"10px"}}
                          onClick={() => {
                            partnerform.resetFields()
                            partnerform.setFieldsValue({partnerName: form.getFieldsValue().contactName})
                            partnerform.setFieldsValue({partnerPhone: form.getFieldsValue().contactMobileNo})
                            {partnerList && partnerList.length > 0 && partnerList.map((item:any)=>{
                              if(item.partnerName === form.getFieldsValue().contactName){
                                partnerform.setFieldsValue(item)
                              }
                            })}
                            setVisible(true);
                          }}
                        />
                      </span>
                    </div>
                    <div className={styles.partnerList_1}>
                      <span>老板</span>
                      {partnerList && partnerList.length > 0 && partnerList.map((item:any)=>{
                        if(item.partnerName === form.getFieldsValue().contactName){
                          return(
                            <>
                              {item.detectPrincipalFlag === 1 &&<span>决策人</span>}
                              {item.decisionMakerFlag === 1 &&<span>检测负责人</span>}
                              {item.partnership &&<span>{item.partnership}</span>}
                              {item.divideTheWork === 1 &&<a>收车</a>}
                              {item.divideTheWork === 2 &&<a>卖车</a>}
                            </>
                          )
                        }
                      })}
                    </div>
                  </div>
                  {partnerList &&
                    partnerList.length > 0 &&
                    partnerList.map((item: any, index: number) => {
                      if(item && item.partnerName != form.getFieldsValue().contactName ){
                        return (
                        <div className={styles.partnerList}>
                          <div className={styles.partnerList_0}>
                            <span>
                              {item.partnerName} {item.partnerPhone}
                            </span>
                            <span>
                              <DeleteOutline 
                                fontSize={20}
                                onClick={() => {
                                  let list = partnerList.filter((child:any)=>child.partnerPhone != item.partnerPhone)
                                  setPartnerList(list)
                                }}
                                />
                              <EditSOutline
                                fontSize={20}
                                style={{marginLeft:"10px"}}
                                onClick={() => {
                                  partnerform.setFieldsValue(item)
                                  setpartnerNumber(index)
                                  setVisible(true);
                                }}
                              />
                            </span>
                          </div>
                          <div className={styles.partnerList_1}>
                            {item.partnerName === form.getFieldsValue().contactName ? <span>老板</span> : <span>合伙人</span>}
                            {item.detectPrincipalFlag === 1 &&<span>决策人</span>}
                            {item.decisionMakerFlag === 1 &&<span>检测负责人</span>}
                            {item.partnership &&<span>{item.partnership}</span>}
                            {item.divideTheWork === 1 &&<a>收车</a>}
                            {item.divideTheWork === 2 &&<a>卖车</a>}
                          </div>
                        </div>
                      )
                    }
                    })}
                  <div
                    className={styles.partnerList_2}
                    onClick={() => {
                      partnerform.resetFields()
                      setpartnerNumber(-1)
                      setVisible(true);
                    }}
                  >
                    添加
                  </div>
                </List.Item>
                <List.Item prefix="地址" title={'*'}>
                  <Form.Item
                    name="address"
                    className={styles.form_item}
                  >
                    <div
                      className={styles.increase}
                      onClick={() => {
                        wxShareConfigs();
                      }}
                    >
                      重新获取定位
                    </div>
                  </Form.Item>
                </List.Item>
                {address != '' && (
                  <div className={styles.address}>{address}</div>
                )}
                <List.Item
                  prefix="归属市场"
                  title={'*'}
                  onClick={() => {
                    setMarketVisible(true);
                  }}
                >
                  <Form.Item
                    name="belongMarketName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            Toast.show({
                              icon: 'fail',
                              content: '选择市场',
                            });
                            return Promise.reject(new Error('选择市场'));
                          }
                          return Promise.resolve();
                        },
                        required: true,
                      }),
                    ]}
                    className={styles.form_item}
                  >
                    <Input placeholder="选择市场" readOnly />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="归属区域">
                  <Form.Item
                    name="belongingRegion"
                    // rules={[{ required: true, message: '请输入归属区域' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入归属区域" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="楼层" title={form.getFieldsValue().serveType === 1 ? '*' :""}>
                  <Form.Item
                    name="floor"
                    rules={[{ required: true, message: '请输入楼层' }]}
                    initialValue={1}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入楼层" />
                  </Form.Item>
                </List.Item>
                <List.Item prefix="门牌号" title={form.getFieldsValue().serveType === 1 ? '*' :""}>
                  <Form.Item
                    name="doorplate"
                    rules={[{ required: form.getFieldsValue().serveType === 1 ? true : false, message: '请输入门牌号' }]}
                    className={styles.form_item}
                  >
                    <Input placeholder="请输入门牌号" />
                  </Form.Item>
                </List.Item>
              </List>
            </Form>
          </Card>
        </div>
      </div>
      <Picker
        columns={serveTypeList}
        visible={serveTypeVisible}
        onCancel={() => {
          setserveTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // setserveType(value[0]);
          form.setFieldValue('serveTypeName', extend.items[0]?.label);
          form.setFieldValue('serveType', value[0]);
          onValuesChange();
          setserveTypeVisible(false);
        }}
      />
      <Picker
        columns={typeList}
        visible={visitSignTypeVisible}
        onCancel={() => {
          setvisitSignTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setvisitSignType(value[0]);
          infoRef.current.visitSignType = value[0];
          form.setFieldValue('visitSignTypeName', extend.items[0]?.label);
          setvisitReasonsVisible(false);
          form.setFieldValue('reasonForFailure', '');
          onValuesChange();
          setvisitSignTypeVisible(false);
        }}
      />
      <Picker
        columns={visitList || visitResultList}
        visible={visitResultVisible}
        onCancel={() => {
          setvisitResultVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setvisitResult(value[0]);
          // @ts-ignore
          localStorage.setItem('visitResult', value[0]);
          form.setFieldValue('visitResultName', extend.items[0]?.label);
          onValuesChange();
          setvisitResultVisible(false);
        }}
      />
      {infoRef.current.visitSignType === 2 && (
        <Picker
          columns={reasonsForFailureNew}
          visible={visitReasonsVisible}
          onCancel={() => {
            setvisitReasonsVisible(false);
          }}
          onConfirm={(value, extend) => {
            console.log(value[0]);
            // @ts-ignore
            form.setFieldValue('reasonForFailure', extend.items[0]?.label);
            onValuesChange();
            setvisitReasonsVisible(false);
          }}
        />
      )}
      {infoRef.current.visitSignType != 2 &&
        infoRef.current.visitSignType != 3 && (
          <Picker
            columns={reasonsForFailureAll}
            visible={visitReasonsVisible}
            onCancel={() => {
              setvisitReasonsVisible(false);
            }}
            onConfirm={(value, extend) => {
              console.log(value[0]);
              // @ts-ignore
              form.setFieldValue('reasonForFailure', extend.items[0]?.label);
              onValuesChange();
              setvisitReasonsVisible(false);
            }}
          />
        )}
      {infoRef.current.visitSignType === 3 && (
        <Picker
          columns={reasonsForFailure}
          visible={visitReasonsVisible}
          onCancel={() => {
            setvisitReasonsVisible(false);
          }}
          onConfirm={(value, extend) => {
            console.log(value[0]);
            // @ts-ignore
            form.setFieldValue('reasonForFailure', extend.items[0]?.label);
            onValuesChange();
            setvisitReasonsVisible(false);
          }}
        />
      )}
      <Picker
        columns={displayTypeList}
        visible={displayTypeVisible}
        onCancel={() => {
          setdisplayTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          // setdisplayType(value[0]);
          // infoRef.current.displayType = value[0];
          form.setFieldValue('displayType', value[0]);
          form.setFieldValue('displayTypeName', extend.items[0]?.label);
          onValuesChange();
          setdisplayTypeVisible(false);
        }}
      />
      <Picker
        columns={detectionMethodList}
        visible={detectionMethodVisible}
        onCancel={() => {
          setdetectionMethodVisible(false);
        }}
        onConfirm={(value, extend) => {
          form.setFieldValue('detectionMethod', extend.items[0]?.label);
          onValuesChange();
          setdetectionMethodVisible(false);
        }}
      />
      <Picker
        columns={BrandTypeList}
        visible={brandTypeVisible}
        onCancel={() => {
          setbrandTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          form.setFieldValue('brandType', extend.items[0]?.label);
          onValuesChange();
          setbrandTypeVisible(false);
        }}
      />
      <Picker
        columns={vehicleTypeList}
        visible={vehicleTypeVisible}
        onCancel={() => {
          setvehicleTypeVisible(false);
        }}
        onConfirm={(value, extend) => {
          form.setFieldValue('vehicleType', extend.items[0]?.label);
          onValuesChange();
          setvehicleTypeVisible(false);
        }}
      />
      <Picker
        columns={priceRangeList}
        visible={priceRangeVisible}
        onCancel={() => {
          setpriceRangeVisible(false);
        }}
        onConfirm={(value, extend) => {
          form.setFieldValue('priceRange', extend.items[0]?.label);
          onValuesChange();
          setpriceRangeVisible(false);
        }}
      />
      <DatePicker
        visible={nextVisitTimeVisible}
        onClose={() => {
          setnextVisitTimeVisible(false);
        }}
        min={new Date()}
        title={'下次拜访时间'}
        precision="minute"
        defaultValue={dateJs()}
        renderLabel={labelRenderer}
        onConfirm={(val) => {
          const year = dayjs(val).year();
          const month = dayjs(val).month() + 1;
          const date = dayjs(val).date();
          const hour = dayjs(val).hour();
          const minute = dayjs(val).minute();
          let mon;
          let day;
          let hou;
          let min;
          if (month < 10) {
            mon = `0${month}`;
          } else {
            mon = month;
          }
          if (date < 10) {
            day = `0${date}`;
          } else {
            day = date;
          }
          if (hour < 10) {
            hou = `0${hour}`;
          } else {
            hou = hour;
          }
          if (minute < 10) {
            min = `0${minute}`;
          } else {
            min = minute;
          }
          const msg = `${year}-${mon}-${day} ${hou}:${min}`;
          form?.setFieldsValue({ nextVisitTime: msg });
        }}
      />
      <Picker
        columns={mediaLists}
        visible={mediaVisible}
        onCancel={() => {
          setmediaVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          setmediaType(value[0]);
          mediaform.setFieldValue('mediaName', extend.items[0]?.label);
          onValuesChange();
          setmediaVisible(false);
        }}
      />
      <Picker
        columns={orgPicket}
        visible={detectOrgNameVisible}
        onCancel={() => {
          setdetectOrgNameVisible(false);
        }}
        onConfirm={(value, extend) => {
          console.log(value[0]);
          settestOrgId(value[0]);
          testOrgIdform.setFieldValue('detectOrgName', extend.items[0]?.label);
          testOrgIdform.setFieldValue('testOrgId', value[0]);
          onValuesChange();
          setdetectOrgNameVisible(false);
        }}
      />
      <Picker
        columns={partnershipList}
        visible={partnershipVisible}
        onCancel={() => {
          setpartnershipVisible(false);
        }}
        onConfirm={(value, extend) => {
          if (value[0]) {
            partnerform.setFieldValue('partnership', extend.items[0]?.label);
          }
          partnerform.setFieldValue('partnershipType', value[0]);
          setpartnershipVisible(false);
        }}
      />
      <DatePicker
        visible={timeVisible}
        onClose={() => {
          setTimeVisible(false);
        }}
        title={'合作时间'}
        precision="month"
        renderLabel={labelRenderer}
        onConfirm={(val) => {
          const year = dayjs(val).year();
          const month = dayjs(val).month() + 1;
          const m = month < 10 ? `0${month}` : month;
          const msg = `${year}-${m}-01 00:00:00`;
          
          testOrgIdform?.setFieldsValue({ cooperationTime: msg });
          onValuesChange();
        }}
      />
      <Modal
        visible={visible}
        closeOnAction
        title={'联系人信息'}
        content={
          <Form form={partnerform}>
            <List>
              <List.Item prefix="姓名"
                title={"*"}>
                <Form.Item
                  name="partnerName"
                  rules={[{ required: true, message: '输入合伙人姓名' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入合伙人姓名" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="联系电话"
                title={"*"}>
                <Form.Item
                  name="partnerPhone"
                  rules={[{ required: true, message: '输入合伙人电话' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入合伙人电话" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="合伙人关系" style={{ display: 'none' }}>
                <Form.Item
                  name="partnershipType"
                  rules={[{ required: true, message: '选择合伙人关系' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择合伙人关系" />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="合伙人关系"
                title={"*"}
                onClick={() => {
                  setpartnershipVisible(true);
                }}
              >
                <Form.Item
                  name="partnership"
                  rules={[{ required: true, message: '选择合伙人关系' }]}
                  className={styles.form_item}
                >
                  <Input
                    placeholder="选择合伙人关系"
                    readOnly={true}
                  />
                </Form.Item>
              </List.Item>
              {partnerform.getFieldsValue().partnershipType === 4 && (
                <List.Item
                  prefix=""
                  title={"*"}
                >
                  <Form.Item
                    name="partnershipName"
                    rules={[{ required: true, message: '填写合伙人关系' }]}
                    className={styles.form_item}
                  >
                    <Input
                      placeholder="填写合伙人关系"
                    />
                  </Form.Item>
              </List.Item>
              )}
              <List.Item prefix="分工"
                title={"*"}>
                <Form.Item
                  name="divideTheWork"
                  rules={[{ required: true, message: '分工' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanger}>
                    <Radio value={1}>收车</Radio>
                    <Radio value={2}>卖车</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item prefix="是否决策人"
                title={"*"}>
                <Form.Item
                  name="decisionMakerFlag"
                  rules={[{ required: true, message: '选择是否决策人' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanger}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item prefix="是否检测负责人"
                title={"*"}>
                <Form.Item
                  name="detectPrincipalFlag"
                  rules={[{ required: true, message: '选择是否检测负责人' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChanger}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online,
            onClick: async () => {
              partnerform
                .validateFields()
                .then(() => {
                  partnerSubmit(0);
                })
                .catch(() => {
                  setVisible(true);
                });
            },
          },
        ]}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        onAction={() => {}}
      ></Modal>
      <Modal
        visible={visible2}
        closeOnAction
        title={'自媒体信息'}
        content={
          <Form form={mediaform}>
            <List>
              <List.Item
                prefix="自媒体类型"
                onClick={() => {
                  setmediaVisible(true);
                }}
              >
                <Form.Item
                  name="mediaName"
                  rules={[{ required: true, message: '选择自媒体类型' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择自媒体类型" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item prefix="自媒体账号">
                <Form.Item
                  name="account"
                  rules={[{ required: true, message: '输入自媒体账号' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入自媒体账号" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="粉丝数量" extra={'万'}>
                <Form.Item
                  name="fansNum"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error('输入粉丝数量(万)'),
                          );
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(
                            new Error('不可输入文字！！'),
                          );
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入粉丝数量(万)" />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online
          },
          {
            key: 'confirm',
            text: '提交并继续添加自媒体信息',
            onClick:()=>{
              setVisible2(true)
              customerform.resetFields()
            },
            className: styles.confirm
          },
        ]}
        onClose={() => {
          setVisible2(false);
        }}
        showCloseButton
        onAction={() => {
          const values = mediaform.getFieldsValue();
          let num = mediaList.length;
          setmediaNumber(num);
          values.mediaType = mediaType;
          values.companyId = localStorage.getItem('companyId');
          values.fansNum = parseInt(values.fansNum);
          if(mediaNumber === -1){
            let num = mediaList.length
            mediaList[num] = values;
            setmediaNumber(num);
          }else{
            mediaList[mediaNumber] = values;
          }
          onValuesChange();
          setmediaList([...mediaList]);
        }}
      ></Modal>
      <Modal
        visible={merchantsVisible}
        closeOnAction
        title={'添加推荐商户'}
        content={
          <Form form={merchantsform}>
            <List>
              <List.Item
                prefix="联系电话"
                extra={<a onClick={async ()=>{
                  let list:any = await Lists({contactMobileNo:merchantsform.getFieldsValue().contactPhone})
                  if(list.data.length > 0){
                    setcontactMobileNoFlag(true)
                    merchantsform.setFieldsValue(list.data[0])
                  }else{
                    setcontactMobileNoFlag(false)
                  }
                }}>商户查询</a>}
              >
                <Form.Item
                  name="contactPhone"
                  rules={[{ required: true, message: '输入联系电话' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入联系电话" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="商户名称" style={{display:"none"}}>
                <Form.Item
                  name="companyId"
                  rules={[{ required: true, message: '输入商户名称' }]}
                  className={styles.form_item}
                  style={{display:"none"}}
                >
                  <Input placeholder="输入商户名称" readOnly={contactMobileNoFlag}/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="商户名称">
                <Form.Item
                  name="companyName"
                  rules={[{ required: true, message: '输入商户名称' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入商户名称" readOnly={contactMobileNoFlag}/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="联系人姓名">
                <Form.Item
                  name="contactName"
                  rules={[{ required: true, message: '输入联系人姓名' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入联系人姓名" readOnly={contactMobileNoFlag} />
                </Form.Item>
              </List.Item>
              <List.Item prefix="推荐方式">
                <Form.Item
                  name="recommendedType"
                  rules={[{ required: true, message: '选择推荐方式' }]}
                  className={styles.form_item}
                >
                  <Radio.Group onChange={onChangec}>
                    <Radio value={1}>本地推荐</Radio>
                    <Radio value={2}>异地推荐</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item prefix="推荐原因" onClick={()=>{
                setrecommendedReasonsVisible(true)
              }}>
                <Form.Item
                  name="recommendedReasons"
                  className={styles.form_item}
                >
                  <Input placeholder="选择推荐原因" readOnly/>
                </Form.Item>
              </List.Item>
              <List.Item prefix="合作频次" onClick={()=>{
                setcooperationFrequencyVisible(true)
              }}>
                <Form.Item
                  name="cooperationFrequency"
                  className={styles.form_item}
                >
                  <Input placeholder="选择合作频次" readOnly/>
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online
          },
          {
            key: 'confirm',
            text: '提交并继续添加推荐商户',
            onClick:()=>{
              setmerchantsVisible(true)
              merchantsform.resetFields()
            },
            className: styles.confirm
          },
        ]}
        onClose={() => {
          setmerchantsVisible(false);
        }}
        showCloseButton
        onAction={() => {
          const values = merchantsform.getFieldsValue();
          let num = merchantsList.length;
          setmerchantsNumber(num);
          if(merchantsNumber === -1){
            let num = merchantsList.length
            merchantsList[num] = values;
            setmerchantsNumber(num);
          }else{
            merchantsList[merchantsNumber] = values;
          }
          onValuesChange();
          setmerchantsList([...merchantsList]);
          if(!contactMobileNoFlag){
            infoSave({companyName:values.companyName,contactMobileNo:values.contactPhone}).then((res)=>{
              if(res.retCode != 0){
                Toast.show({
                  icon:"fail",
                  content:res.retMsg
                })
              }
            })
            .catch(()=>{
              Toast.show({
                icon:"fail",
                content:"商户创建失败"
              })
            })
          }
        }}
      ></Modal>
      <Modal
        visible={customerVisible}
        closeOnAction
        title={'客户来源'}
        content={
          <Form form={customerform}>
            <List>
              <List.Item
                prefix="客户来源"
                onClick={() => {
                  setCustomerVisible2(true);
                }}
              >
                <Form.Item
                  name="customerSourceName"
                  rules={[{ required: true, message: '选择客户来源' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择客户来源" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item prefix="来源占比" extra={'%'}>
                <Form.Item
                  name="proportion"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error('输入来源占比(%)'),
                          );
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(
                            new Error('不可输入文字！！'),
                          );
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入来源占比(%)" />
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online
          },
          {
            key: 'confirm',
            text: '提交并继续添加客户来源',
            onClick:()=>{
              setVisible(true)
              customerform.resetFields()
            },
            className: styles.confirm
          },
        ]}
        onClose={() => {
          setCustomerVisible(false);
        }}
        showCloseButton
        onAction={() => {
          const values = customerform.getFieldsValue();
          if(customerNumber === -1){
            let num = customerLists.length
            customerLists[num] = values;
            setcustomerNumber(num);
          }else{
            customerLists[customerNumber] = values;
          }
          onValuesChange();
          console.log(customerLists);
          
          setcustomerLists([...customerLists]);
        }}
      ></Modal>
      <Picker
        columns={cooperationFrequencyList}
        visible={cooperationFrequencyVisible}
        onCancel={() => {
          setcooperationFrequencyVisible(false);
        }}
        onConfirm={(value, extend) => {
          merchantsform.setFieldValue('cooperationFrequency', extend.items[0]?.label);
          setcooperationFrequencyVisible(false);
        }}
      />
      <Picker
        columns={recommendedReasonsList}
        visible={recommendedReasonsVisible}
        onCancel={() => {
          setrecommendedReasonsVisible(false);
        }}
        onConfirm={(value, extend) => {
          merchantsform.setFieldValue('recommendedReasons', extend.items[0]?.label);
          setrecommendedReasonsVisible(false);
        }}
      />
      <Picker
        columns={customerList}
        visible={customerVisible2}
        onCancel={() => {
          setCustomerVisible2(false);
        }}
        onConfirm={(value, extend) => {
          customerform.setFieldValue('customerSourceName', extend.items[0]?.label);
          setCustomerVisible2(false);
        }}
      />
      <Modal
        visible={visible3}
        closeOnAction
        title={'竞队信息'}
        content={
          <Form form={testOrgIdform}>
            <List>
              <List.Item
                prefix="检测机构"
                onClick={() => {
                  setdetectOrgNameVisible(true);
                }}
              >
                <Form.Item
                  name="detectOrgName"
                  rules={[{ required: true, message: '选择检测机构' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测机构" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="检测机构"
                onClick={() => {
                  setdetectOrgNameVisible(true);
                }}
                style={{display:"none"}}
              >
                <Form.Item
                  name="testOrgId"
                  rules={[{ required: true, message: '选择检测机构' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择检测机构" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item
                prefix="合作时间"
                onClick={() => {
                  setTimeVisible(true);
                }}
              >
                <Form.Item
                  name="cooperationTime"
                  rules={[{ required: true, message: '选择合作时间' }]}
                  className={styles.form_item}
                >
                  <Input placeholder="选择合作时间" readOnly />
                </Form.Item>
              </List.Item>
              <List.Item prefix="集采余额" extra={'万'}>
                <Form.Item
                  name="purchaseBalance"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('输入集采余额'));
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(
                            new Error('不可输入文字！！'),
                          );
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入集采余额" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="集采库存">
                <Form.Item
                  name="purchasingStock"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('输入集采库存'));
                        }
                        if (Number.isFinite(Number(value)) === false) {
                          return Promise.reject(
                            new Error('不可输入文字！！'),
                          );
                        }
                        return Promise.resolve();
                      },
                      required: true,
                    }),
                  ]}
                  className={styles.form_item}
                >
                  <Input placeholder="输入集采库存" />
                </Form.Item>
              </List.Item>
              <List.Item prefix="硬广覆盖">
                <Form.Item
                  name="hardCover"
                  rules={[{ required: true, message: '选择是否硬广覆盖' }]}
                  className={styles.form_item}
                >
                  <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </List.Item>
              <List.Item prefix="竞队广告">
                <Form.Item name="doorway" className={styles.form_item}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {advertiseImgs && (
                      <Image
                        src={advertiseImgs}
                        width={40}
                        height={40}
                        fit="fill"
                        onClick={() => {
                          setImageVisible(true);
                          setAllImage(advertiseImgs);
                        }}
                      />
                    )}

                    <div
                      className={styles.increaseIcon}
                      onClick={() => {
                        wxShareConfig(3);
                      }}
                    >
                      <CameraOutline fontSize={20} />
                      <div style={{ marginTop: '2px' }}>照片</div>
                    </div>
                  </div>
                </Form.Item>
              </List.Item>
            </List>
          </Form>
        }
        actions={[
          {
            key: 'online',
            text: '提交',
            className: styles.online
          },
          {
            key: 'confirm',
            text: '提交并继续添加竞队信息',
            onClick:()=>{
              setVisible3(true)
              testOrgIdform.resetFields()
            },
            className: styles.confirm
          },
        ]}
        onClose={() => {
          setVisible3(false);
        }}
        showCloseButton
        onAction={() => {
          const values = testOrgIdform.getFieldsValue();
          // values.testOrgId = testOrgId;
          let url
          if(localStorage.getItem('advertise')){
            // @ts-ignore
            let str = JSON.parse(localStorage.getItem('advertise'));
            url = str.mediaId
          }
          values.url = advertiseImgs;
          values.advertise = url;
          values.companyId = localStorage.getItem('companyId');
          values.hardCover = parseInt(values.hardCover);
          if (values.cooperationTime) {
            values.cooperationTime = `${values.cooperationTime}`;
          }
          if(testOrgNumber === -1) {
            let num = testOrgList.length;
            testOrgList[num] = values;
            settestOrgNumber(num);
          }else{
            testOrgList[testOrgNumber] = values;
          }
          console.log(testOrgList);
          onValuesChange();

          settestOrgList([...testOrgList]);
        }}
      ></Modal>

      <Popup
        visible={visible4}
        showCloseButton
        onClose={() => {
          setVisible4(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          maxHeight: '90vh',
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {dataFlag &&
        dataFlag.carLabelIds &&
        dataFlag.carLabelIds.indexOf(remarkChild.id) > -1 ? (
          <>
            <Swiper
              indicator={(total, current) => (
                <div className={styles.customIndicator}>
                  {`${current + 1} / ${total}`}
                </div>
              )}
              loop
              defaultIndex={defaultIndexPoint}
              ref={ref}
            >
              {selectedValuePoints &&
                selectedValuePoints.length > 0 &&
                selectedValuePoints.map((item: any) => {
                  return (
                    <Swiper.Item key={item.id} className={styles.swiper}>
                      <div
                        className={styles.remarkName}
                        style={{ color: 'red' }}
                      >
                        {item.name}
                      </div>
                      <Form form={remarkform}>
                        <List>
                          <List.Item prefix="评分">
                            <Form.Item
                              name={`appraiseType${item.id}`}
                              className={styles.form_item}
                            >
                              <Radio.Group>
                                <Radio value={'2'}>好</Radio>
                                <Radio value={'3'}>中</Radio>
                                <Radio value={'4'}>差</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </List.Item>
                          <List.Item>
                            <Form.Item
                              name={`remarkContent${item.id}`}
                              className={styles.form_item}
                            >
                              <TextArea
                                placeholder="请输入备注"
                                maxLength={100}
                                rows={5}
                                showCount
                              />
                            </Form.Item>
                          </List.Item>
                        </List>
                      </Form>
                      <div className={styles.footer_remark}>
                        <Button
                          block
                          type="button"
                          onClick={() => {
                            onRemarkFinish(item, 0);
                          }}
                        >
                          提交
                        </Button>
                        <Button
                          block
                          type="button"
                          color="primary"
                          onClick={() => {
                            onRemarkFinish(item, 1);
                          }}
                        >
                          提交并对下一个价值点备注
                        </Button>
                      </div>
                    </Swiper.Item>
                  );
                })}
            </Swiper>
          </>
        ) : (
          <>
            <div className={styles.remarkName}>{remarkChild.name}</div>
            <Form form={remarkform} className={styles.remarkform_2}>
              <List>
                <List.Item prefix="评分">
                  <Form.Item
                    name={`appraiseType${remarkChild.id}`}
                    className={styles.form_item}
                  >
                    <Radio.Group>
                      {/* <Radio value={'1'}>极好</Radio> */}
                      <Radio value={'2'}>好</Radio>
                      <Radio value={'3'}>中</Radio>
                      <Radio value={'4'}>差</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item>
                  <Form.Item
                    name={`remarkContent${remarkChild.id}`}
                    className={styles.form_item}
                  >
                    <TextArea
                      placeholder="请输入备注"
                      maxLength={100}
                      rows={5}
                      showCount
                    />
                  </Form.Item>
                </List.Item>
              </List>
            </Form>
            <div
              className={styles.footer_remark}
              style={{ justifyContent: 'center' }}
            >
              <Button
                block
                type="button"
                color="primary"
                style={{width:"100%"}}
                onClick={() => {
                  onRemarkFinish(remarkChild, 0);
                }}
              >
                提交
              </Button>
            </div>
          </>
        )}
      </Popup>
      <div className={styles.steepper}>
        <Modal
          visible={priceVisible}
          closeOnAction
          title={'价位范围'}
          content={
            <div className={styles.steeppers}>
              <Form form={formPrice} className={styles.form_modal}>
                <Form.Item
                  name="priceA"
                  rules={[{ required: true, message: '选择价位' }]}
                  className={styles.form_item}
                >
                  <Stepper />
                </Form.Item>
                <span style={{ fontSize: '16px', margin: 'auto' }}>-</span>
                <Form.Item
                  name="priceB"
                  rules={[{ required: true, message: '选择价位' }]}
                  className={styles.form_item}
                >
                  <Stepper />
                </Form.Item>
              </Form>
            </div>
          }
          actions={[
            {
              key: 'confirm',
              text: '确定',
            },
          ]}
          onClose={() => {
            setPriceVisible(false);
          }}
          showCloseButton
          onAction={() => {
            const values = formPrice.getFieldsValue();
            console.log(values);
            values.priceA = values.priceA ? values.priceA : 0;
            values.priceB = values.priceB ? values.priceB : 0;
            if (values.priceA > values.priceB) {
              Toast.show({
                icon: 'fail',
                content: '价格区间格式错误!!! (A < B)',
              });
              return;
            }
            form.setFieldsValue({
              price: `${values.priceA}-${values.priceB}`,
            });
            onValuesChange();
            setPriceValue(values);
          }}
        ></Modal>

        <Picker
          columns={degreeIntentList}
          visible={degreeIntentVisible}
          onCancel={() => {
            setdegreeIntentVisible(false);
          }}
          onConfirm={(value, extend) => {
            // setdegreeIntent(value[0]);
            infoRef.current.degreeIntent = value[0];
            form.setFieldValue(
              'degreeIntentName',
              extend.items[0]?.label,
            );
            setdegreeIntentVisible(false);
          }}
        />
        <ImageViewer
          image={allImage}
          visible={imageVisible}
          onClose={() => {
            setImageVisible(false);
          }}
        />
        <Merchant
          visit={false}
          checkFlag={false}
          cityFlag={false}
          ButtonFlag={false}
          nameVisible={nameVisible}
          onSubmit={handelUserName}
          onCancel={handleUserNameCancel}
        />
        <Market
          nameVisible={marketVisible}
          onSubmit={handelUserMarket}
          onCancel={handleUserMarketCancel}
          value={false}
        />
        <Merchant
          visit={true}
          checkFlag={false}
          cityFlag={false}
          ButtonFlag={false}
          nameVisible={recommendationsVisible}
          onSubmit={handelrecommendationsUserName}
          onCancel={handleRecommendationsCancel}
        />
        <Popup
          visible={collectVisible}
          onMaskClick={() => {
            setCollectVisible(false);
          }}
          className={styles.popup}
          onClose={() => {
            setCollectVisible(false);
          }}
          bodyStyle={{
            height: '40vh',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CheckList
            multiple={true}
            style={{ flex: '1 1' }}
            onChange={onchanges}
          >
            {offeringsList.length > 0 &&
              offeringsList.map((item: any, index: number) => {
                return <CheckList.Item value={item}>{item}</CheckList.Item>;
              })}
          </CheckList>
          <div className={styles.footer_3}>
            <div className={styles.button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setCollectVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  let str = '';
                  if (checkCollect && checkCollect.length > 0) {
                    checkCollect.map((item: any) => {
                      str = str + item + '、';
                    });
                  }
                  form.setFieldValue('productName', str);
                  onValuesChange();
                  setCollectVisible(false);
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}
