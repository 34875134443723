import {
  Button,
  Checkbox,
  CheckList,
  Dropdown,
  DropdownRef,
  InfiniteScroll,
  Input,
  List,
  Modal,
  Popover,
  Popup,
  Radio,
  SearchBar,
  Space,
  Tabs,
  Tag,
} from 'antd-mobile/es';
import SpinLoading from 'antd-mobile/es/components/spin-loading';
import Toast from 'antd-mobile/es/components/toast';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { history, useLocation } from 'umi';
import Navbar from '../utils/Navbar';
import styles from './index.less';
import { getVisitTemplate } from '../report/service';
import {
  auditData,
  bookingLeaderboardList,
  detectionStatisticList,
  getMonthlyStatistics,
  getRegionRelationColor,
  getUserCheckByCountCircle,
  leaderboardList,
  reportList,
  saveRepurchase,
  statementList,
} from './service';
import datas from '../utils/test.json';
import { DownFill } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import Login from '../login';
import error from './img/error_2.png';
import 数据模板 from './img/数据模板.png';
import dataLists from './data.json';
import {
  BaseTable,
  features,
  SortItem,
  useTablePipeline,
} from 'ali-react-table';
import * as fusion from '@alifd/next';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import { number } from 'echarts';

let date = new Date();
//周一
let mon = dayjs(
  new Date(
    date.getTime() -
      3600 * 1000 * 24 * (date.getDay() == 0 ? 6 : date.getDay() - 1),
  ),
).format('YYYY-MM-DD');
//周二
let tues = dayjs(
  new Date(
    date.getTime() +
      3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 2 - date.getDay()),
  ),
).format('YYYY-MM-DD');
//周三
let wednes = dayjs(
  new Date(
    date.getTime() +
      3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 3 - date.getDay()),
  ),
).format('YYYY-MM-DD');
//周四
let thurs = dayjs(
  new Date(
    date.getTime() +
      3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 4 - date.getDay()),
  ),
).format('YYYY-MM-DD');
//周五
let fri = dayjs(
  new Date(
    date.getTime() +
      3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 5 - date.getDay()),
  ),
).format('YYYY-MM-DD');
//周六
let satur = dayjs(
  new Date(
    date.getTime() +
      3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 6 - date.getDay()),
  ),
).format('YYYY-MM-DD');
//周日
let sun = dayjs(
  new Date(
    date.getTime() +
      3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 7 - date.getDay()),
  ),
).format('YYYY-MM-DD');
export default function DataCentres() {
  const location = useLocation();
  const [checkState, setCheckState] = useState<string>('0');
  const [checkFlag, setcheckFlag] = useState<any>({});
  const [timeType, setTimeType] = useState<any>({
    index: 0,
    time: [
      dayjs(new Date()).format('YYYY-MM-DD'),
      dayjs(new Date()).format('YYYY-MM-DD'),
    ],
  });
  const [data, setData] = useState<any>([]);
  const [datas, setDatas] = useState<any>([]);
  const [extra, setExtra] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [visitNum, setVisitNum] = useState<number>(0);
  const [noteNum, setNoteNum] = useState<number>(0);
  const [sensingNum, setSensingNum] = useState<number>(0);
  const [regionData, setregionData] = useState<any>([]);
  const [districtData, setdistrictData] = useState<any>([]);
  const [bdData, setBdData] = useState<any>([]);
  const [marketData, setmarketData] = useState<any>([]);
  const [color, setColor] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [KeyListColumns, setKeyListColumns] = useState<any>([]);
  const [firstlyVisible, setFirstlyVisible] = useState(false);
  const [regionName, setregionName] = useState<string>('所有大区');
  const [districtName, setdistrictName] = useState<string>('所有城市');
  const [bdDataName, setBdDataName] = useState<string>('所有人员');
  const [marketName, setmarketName] = useState<string>('所有市场');
  const [searchValue, setSearchValue] = useState<string>('');
  const ref = useRef<DropdownRef>(null);
  const infoRef: any = useRef([]);
  const keyToFixedList = [
    'complianceRate',
    'totalClinchRate',
    'newPurClinchRate',
    'repClinchRate',
    'rate',
    'kaTotalCount',
    'avgHour',
    'avgCount',
    'totalHourDay',
    'totalCountDay',
    'nCheckTotalCount',
    'yCheckTotalCount',
    'totalCount',
    'totalHour',
    'userCheckDay',
  ];
  const keyPerCentList = [
    'complianceRate',
    'totalClinchRate',
    'newPurClinchRate',
    'repClinchRate',
    'rate',
  ];
  const colorThisValue = (
    record: any,
    key: string,
    color: any,
    type: number,
  ) => {
    let colorValue = '';
    let widthValue = 0;
    let content = '';
    let flagType = '';
    let flagContent = '';
    let ThirtyMaxContent = '';
    let suffix = '';
    let ThirtyMax = '';
    if (color && color.length > 0) {
      color.map((item: any) => {
        if (
          item.regionName === record.region ||
          item.regionName === record.regionName ||
          item.regionName === record.regionalName
        ) {
          colorValue = item.colorValue;
        }
      });
    }
    if (record[key] && record[key] > 0) {
      widthValue = record[key] * (60 / datas[key]);
    }
    if (keyToFixedList.indexOf(key) > -1 && record[key]) {
      if (Number.isInteger(record[key])) {
        content = record[key];
      } else {
        content = record[key].toFixed(1);
      }
    } else {
      content = record[key];
    }
    // keyToFixedList.map((item:any)=>{
    //   if (item === key && record[key] ) {
    //     if(Number.isInteger(record[key])){
    //       content = record[key];
    //     }else{
    //       content = record[key].toFixed(1);
    //     }
    //   } else {
    //     content = record[key];
    //   }
    // })
    if (type === 1) {
      suffix = 'YDay';
    }
    if (type === 2) {
      suffix = 'BMonth';
    }
    if (checkState === '3' && record[`${key}ThirtyMax`]) {
      if (Number.isInteger(record[`${key}ThirtyMax`])) {
        ThirtyMaxContent = record[`${key}ThirtyMax`];
      } else {
        ThirtyMaxContent = record[`${key}ThirtyMax`].toFixed(1);
      }
    }
    if (record[`${key}${suffix}`]) {
      if (Number.isInteger(record[`${key}${suffix}`])) {
        flagContent = record[`${key}${suffix}`];
      } else {
        flagContent = record[`${key}${suffix}`].toFixed(1);
      }
      if (record[key] > record[`${key}${suffix}`]) {
        flagType = 'rgba(13, 175, 5, 1)';
      } else if (record[key] < record[`${key}${suffix}`]) {
        flagType = 'rgba(254, 26, 26, 1)';
        if (Number.isInteger(record[`${key}${suffix}`])) {
          flagContent = record[`${key}${suffix}`];
        } else {
          flagContent = record[`${key}${suffix}`].toFixed(1);
        }
      } else {
        flagType = '#222222';
      }
    } else {
      flagType = '#222222';
    }
    if (keyPerCentList.indexOf(key) > -1 && record[key]) {
      if (Number.isInteger(record[key])) {
        content = `${record[key] * 100}%`;
      } else {
        content = `${(record[key] * 100).toFixed(1)}%`;
      }
    }
    return (
      <div className={styles.table_histogram}>
        <span
          style={{
            backgroundColor: colorValue,
            width: `${widthValue}px`,
          }}
        ></span>
        <p style={{ width: `${checkState === '3' ? '60px' : '20px'}` }}>
          <a style={{ color: flagType }}>{content || 0}</a>{' '}
          {checkState === '3' && <a>（{flagContent || 0}）</a>}{' '}
          {checkState === '3' && <a>[{ThirtyMaxContent || 0}]</a>}
        </p>
      </div>
    );
  };
  const auditProportion = (record:any,key:any) => {
    let proportion:number = 0;
    let difference:any = 0;
    let differenceFlag:boolean = false;
    if(record[key]){
      if(key != "时间" && key != "总计"){
        proportion = record[key]/record["总计"]
        if(key === "王国强" || key === "朱伟"){
          difference = 0.42 * record["总计"]
          if(proportion < 0.42){
            differenceFlag = true
          }
        }else if(key === "吴盛林"){
          difference = 0.05 * record["总计"]
          if(proportion < 0.05){
            differenceFlag = true
          }
        }else if(key === "谢斌"){
          difference = 0.11 * record["总计"]
          if(proportion < 0.11){
            differenceFlag = true
          }
        }
        return <span><span style={{color:differenceFlag ? "red" : "rgb(9, 185, 21)" }}>{record[key]} </span><span>({difference.toFixed(0)})</span></span>
      }else{
        return <span>{record[key]}</span>
      }
    }else{
      return <span>-</span>
    }
  }
  const colorDayValue = (
    record: any,
    key: string,
    color: any,
    type: number,
  ) => {
    let colorValue = '';
    let widthValue = 0;
    let content = '';
    let flagType = '';
    let flagContent = '';
    let maxContent = '';
    if (color && color.length > 0) {
      color.map((item: any) => {
        if (item.regionName === record.regionalName || item.regionName === record.areaName) {
          colorValue = item.colorValue;
        }
      });
    }
    if (record[key]) {
      content = record[key];
    }
    if (record[key] && record[key] > 0) {
      widthValue = record[key] * (60 / datas[key]);
    }
    if (record[`${key}Last`]) {
      if (Number.isInteger(record[`${key}Last`])) {
        flagContent = record[`${key}Last`];
      } else {
        flagContent = record[`${key}Last`].toFixed(1);
      }
      if (record[key] > record[`${key}Last`]) {
        flagType = 'rgba(13, 175, 5, 1)';
      } else if (record[key] < record[`${key}Last`]) {
        flagType = 'rgba(254, 26, 26, 1)';
        if (Number.isInteger(record[`${key}`])) {
          flagContent = record[`${key}Last`];
        } else {
          flagContent = record[`${key}Last`].toFixed(1);
        }
      } else {
        flagType = '#222222';
      }
    } else {
      flagType = '#222222';
    }
    if (record[`${key}Max`]) {
      if (Number.isInteger(record[`${key}Max`])) {
        maxContent = record[`${key}Max`];
      } else {
        maxContent = record[`${key}Max`].toFixed(1);
      }
      if (Number.isInteger(record[`${key}`])) {
        maxContent = record[`${key}Max`];
      } else {
        maxContent = record[`${key}Max`].toFixed(1);
      }
    }
    return (
      <div className={styles.table_histogram}>
        <span
          style={{
            backgroundColor: colorValue,
            width: `${widthValue}px`,
          }}
        ></span>
        <p>
          <a style={{ color: flagType }}>{content || 0}</a>{' '}
          {checkState === '4' && <a>（{flagContent || 0}）</a>}{' '}
          {checkState === '4' && <a>[{maxContent || 0}]</a>}
        </p>
      </div>
    );
  };
  const schedulingText = (content: any) => {
    let text = '-';
    if (content && content === '正常') {
      text = '在岗';
    } else if ((content && content.indexOf('假') > -1 ) || (content && content.indexOf("调休")>-1)) {
      text = '请假';
    } else{
      text = content
    }
    return text;
  };
  const maxKeyList = [
    'maxDoubleValue',
    'maxIntValue',
    'totalCount',
    'price',
    'orderTotalCount',
    'yTotalCount',
    'reserveDetectNum',
    'avgTotalCount',
    'totalHourDay',
    'intTotalCount',
    'intTotalCount',
  ];
  const contentText = (record: any) => {
    let content = 0;
    maxKeyList.map((item: any) => {
      if (record[item]) {
        if (Number.isInteger(record[item])) {
          content = record[item];
        } else {
          content = record[item].toFixed(1);
        }
      }
    });
    return content;
  };
  const screenData = (res:any) => {
    let regionList: any[] = ['所有大区'];
    let districtList: any[] = ['所有城市'];
    let bdNameList: any[] = ['所有人员'];
    let marketList: any[] = ['所有市场'];
    res.map((item: any) => {
      if (item.region) {
        regionList.push(item.region);
      }
      if (item.regionName) {
        regionList.push(item.regionName);
      }
      if (item.areaName) {
        regionList.push(item.areaName);
      }
      if (item.regionalName) {
        regionList.push(item.regionalName);
      }
      if (item.city) {
        districtList.push(item.city);
      }
      if (item.cityName) {
        districtList.push(item.cityName);
      }
      if (item.bdName) {
        bdNameList.push(item.bdName);
      }
      if (item.name) {
        bdNameList.push(item.name);
      }
      if (item.nickName) {
        bdNameList.push(item.nickName);
      }
      if (item.market) {
        item.market.split(",").map((child:any)=>{
          marketList.push(child)
        })
      }
      if (item.stationName) {
        item.stationName.split(",").map((child:any)=>{
          marketList.push(child)
        })
      }
      if (item.marketName) {
        item.marketName.split(",").map((child:any)=>{
          marketList.push(child)
        })
      }
      if (item.memberMarketName) {
        item.memberMarketName.split(",").map((child:any)=>{
          marketList.push(child)
        })
      }
    });
    let list = [];
    for (let i = 0; i < regionList.length; i++) {
      if (list.indexOf(regionList[i]) === -1) {
        list.push(regionList[i]);
      }
    }
    let list2 = [];
    for (let i = 0; i < districtList.length; i++) {
      if (list2.indexOf(districtList[i]) === -1) {
        list2.push(districtList[i]);
      }
    }
    let list3 = [];
    for (let i = 0; i < bdNameList.length; i++) {
      if (list3.indexOf(bdNameList[i]) === -1) {
        list3.push(bdNameList[i]);
      }
    }
    let list4:any = [];
    for (let i = 0; i < marketList.length; i++) {
      if (list4.indexOf(marketList[i]) === -1) {
        list4.push(marketList[i]);
      }
    }
    setregionData(list);
    setdistrictData(list2);
    setBdData(list3);
    setmarketData(list4);
  }
  const screenValue = (value:any,name:any,type:number) => {
    let list1:any  =[]
    let list2:any  =[]
    let list3:any  =[]
    let list4:any  =[]
    if(type == 1 && name != "所有大区"){
      value.map((item:any)=>{
        if((item.regionName ||
          item.region ||
          item.areaName ||
          item.regionalName)=== name){
            list1.push(item)
        }
      })
    }else if(type == 1 && name == "所有大区"){
      list1 = value
    }else if(regionName != "所有大区"){
      value.map((item:any)=>{
        if((item.regionName ||
          item.region ||
          item.areaName ||
          item.regionalName)=== regionName){
            list1.push(item)
        }
      })
    }else{
      list1 = value
    }
    if(type == 2 && name != "所有城市"){
      list1.map((item:any)=>{
        if((item.district || item.cityNames)==name){
          list2.push(item)
        }
      })
    }else if(type == 2 && name == "所有城市"){
      list2 = list1
    }else if(districtName != "所有城市"){
      list1.map((item:any)=>{
        if((item.district || item.cityNames)==districtName){
          list2.push(item)
        }
      })
    }else{
      list2 = list1
    }
    if(type == 3 && name != "所有人员"){
      list2.map((item:any)=>{
        if(item.bdName === name || item.nickName === name || item.name === name ){
          list3.push(item)
        }
      })
    }else if(type == 3 && name == "所有人员"){
      list3 = list2
    }else if(bdDataName != "所有人员"){
      list2.map((item:any)=>{
        if(item.bdName === bdDataName || item.nickName === bdDataName || item.name === bdDataName ){
          list3.push(item)
        }
      })
    }else{
      list3 = list2
    }
    if(type == 4 && name != "所有市场"){
      list3.map((item:any)=>{
        if((item.marketName && item.marketName.indexOf(name) >-1) || (item.market && item.market.indexOf(name) >-1) || (item.stationName && item.stationName.indexOf(name) >-1) || (item.memberMarketName && item.memberMarketName.indexOf(name) >-1)){
          list4.push(item)
        }
      })
    }else if(type == 4 && name == "所有市场"){
      list4 = list3
    }else if(marketName != "所有市场"){
      list3.map((item:any)=>{
        if((item.marketName && item.marketName.indexOf(marketName) >-1) || (item.market && item.market.indexOf(marketName) >-1) || (item.stationName && item.stationName.indexOf(marketName) >-1) || (item.memberMarketName && item.memberMarketName.indexOf(marketName) >-1)){
          list4.push(item)
        }
      })
    }else{
      list4 = list3
    }
    summation(list4);
    ref.current?.close();
  }
  const onSearch = (v:any) => {
    setSearchValue(v)
  }
  const initValue = (v: string, time: any) => {
    setregionName('所有大区');
    setdistrictName('所有城市');
    setBdDataName('所有人员');
    setmarketName('所有市场');
    if (v === '0') {
      leaderboardList({ startTime: time[0], endTime: time[1] })
        .then((res) => {
          if (res.retCode === 0) {
            setDatas(res.data[0]);
            setExtra(res.extra);
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.bdName);
            summation(init);
            setDataList(init);

            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].totalPlans) {
                arr = res.data[i].totalPlans;
              }
            }
            screenData(res.data)
            setVisitNum(arr);
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    if (v === '1') {
      statementList({ startTime: time[0], endTime: time[1] })
        .then((res) => {
          if (res.retCode === 0) {
            setDatas(res.data[0]);
            setExtra(res.extra);
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.bdName);
            // setData(initData);
            summation(init);
            setDataList(init);
            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].complianceRate) {
                arr = res.data[i].complianceRate;
              }
            }
            screenData(res.data)
            setNoteNum(arr);
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    // 车源拜访
    if (v === '2') {
      bookingLeaderboardList({ startTime: time[0], endTime: time[1] })
        .then((res) => {
          if (res.retCode === 0) {
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.bdName);
            let initData = [];
            if (regionName != '所有大区' || districtName != '所有城市') {
              initData = init.filter(
                (item: any) =>
                  (item.regionName || item.region) === regionName ||
                  item.district === districtName,
              );
            } else {
              initData = init;
            }
            setDatas(res.data[0]);
            // setData(num);
            summation(init);
            setDataList(init);
            setExtra(res.extra);
            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].orderTotalCount) {
                arr = res.data[i].orderTotalCount;
              }
            }
            setSensingNum(arr);
            screenData(res.data)
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    if (v === '3') {
      detectionStatisticList({ startTime: time[0], endTime: time[1] })
        .then((res) => {
          if (res.retCode === 0) {
            let num: any = [];
            if (res.data) {
              res.data.map((item: any) => {
                if (item.nickName && item.nickName != '' && item.regionName) {
                  num.push(item);
                }
              });
            }
            res.data.sort((a: any, b: any) => {
              if (!b.nickName) {
                return 0;
              }
              return b.totalHourDay - a.totalHourDay;
            });
            setDatas(res.data[0]);
            setExtra(res.extra);
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.nickName);
            // setData(initData);
            summation(init);

            setDataList(init);
            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].orderTotalCount) {
                arr = res.data[i].orderTotalCount;
              }
            }
            setSensingNum(arr);
            screenData(res.data)
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    if (v === '4') {
      getUserCheckByCountCircle({ startTime: mon, endTime: sun })
        .then((res) => {
          if (res.retCode === 0) {
            let num: any = [];
            if (res.data) {
              res.data.map((item: any) => {
                if (item.nickName && item.nickName != '' && item.regionName) {
                  num.push(item);
                }
              });
            }
            res.data.sort((a: any, b: any) => {
              if (!b.nickName) {
                return 0;
              }
              return b.totalHourDay - a.totalHourDay;
            });
            setDatas(res.data[0]);
            setExtra(res.extra);
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.nickName);
            let initData = [];
            if (regionName != '所有大区' || districtName != '所有城市') {
              initData = init.filter(
                (item: any) =>
                  item.areaName === regionName ||
                  item.areaName === districtName,
              );
            } else {
              initData = init;
            }
            // setData(initData);
            summation(init);

            setDataList(init);
            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].orderTotalCount) {
                arr = res.data[i].orderTotalCount;
              }
            }
            setSensingNum(arr);
            screenData(res.data)
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    if (v === '5') {
      getMonthlyStatistics({})
        .then((res) => {
          if (res.retCode === 0) {
            let num: any = [];
            if (res.data) {
              res.data.map((item: any) => {
                if (item.regionName) {
                  num.push(item);
                }
              });
            }
            res.data.sort((a: any, b: any) => {
              if (!b.nickName) {
                return 0;
              }
              return b.totalHourDay - a.totalHourDay;
            });
            setDatas(res.data[0]);
            setExtra(res.extra);
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.regionalName);
            let initData = [];
            if (regionName != '所有大区' || districtName != '所有城市') {
              initData = init.filter(
                (item: any) =>
                  item.regionalName === regionName ||
                  item.cityName === districtName,
              );
            } else {
              initData = init;
            }
            // setData(initData);
            init.map((item:any,index:number)=>{
              if(!item.curr){
                init[index].curr = 0
              }
              if(!item.last){
                init[index].last = 0
              }
              if(!item.prev){
                init[index].prev = 0
              }
            })
            summation(init);

            setDataList(init);
            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].orderTotalCount) {
                arr = res.data[i].orderTotalCount;
              }
            }
            setSensingNum(arr);
            screenData(res.data)
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    if (v === '6') {
      auditData({})
        .then((res) => {
          if (res.retCode === 0) {
            let list: any = [];
            if (res.data && res.data[0]) {
              let keyList:any = [];
              for(const key in res.data[0]){
                let sortValue = 0
                if(key === "时间"){
                  sortValue = 1
                } else if(key === "总计"){
                  sortValue = 2
                }else{
                  sortValue = 99
                }
                let nameKey = "";
                let widthKey = 120;
                if(key === "王国强" || key === "朱伟"){
                  nameKey = `${key}(42%)`
                }else if(key === "吴盛林"){
                  nameKey = `${key}(5%)`
                }else if(key === "谢斌"){
                  nameKey = `${key}(11%)`
                }else{
                  nameKey = key
                }
                if(key == "时间"){
                  widthKey = 100
                }else if(key == "总计"){
                  widthKey = 80
                }
                keyList.push({
                  code: key,
                  name: nameKey,
                  width: widthKey,
                  align: 'center',
                  lock: key=== "时间" ? true : false,
                  features: { sortable: true },
                  sortValue: sortValue,
                  render: (_: any, record: any) => {
                    return auditProportion(record, key);
                  },
                })
              }
              keyList.sort((a:any,b:any)=>{
                return a.sortValue - b.sortValue
              })
              console.log(keyList);
              setKeyListColumns(keyList)
              res.data.forEach((item:any)=>{
                let keyList:any = {};
                for(const key in item){
                  if(key != "时间"){
                    keyList[key] = parseInt(item[key])
                  }else{
                    keyList[key] = item[key]
                  }
                }
                
                list.push(keyList)
              })
              
            }
            summation(list);
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    if (v === '7') {
      saveRepurchase({ startTime: time[0], endTime: time[1] })
        .then((res) => {
          if (res.retCode === 0) {
            let num: any = [];
            if (res.data) {
              res.data.map((item: any) => {
                if (item.regionName) {
                  num.push(item);
                }
              });
            }
            res.data.sort((a: any, b: any) => {
              if (!b.nickName) {
                return 0;
              }
              return b.totalHourDay - a.totalHourDay;
            });
            setDatas(res.data[0]);
            setExtra(res.extra);
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.name);
            summation(init);
            setDataList(init);
            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].orderTotalCount) {
                arr = res.data[i].orderTotalCount;
              }
            }
            setSensingNum(arr);
            screenData(res.data)
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
    if (v === '8') {
      reportList({ startTime: time[0], endTime: time[1] })
        .then((res) => {
          if (res.retCode === 0) {
            let num: any = [];
            if (res.data) {
              res.data.map((item: any) => {
                if (item.regionName) {
                  num.push(item);
                }
              });
            }
            res.data.sort((a: any, b: any) => {
              if (!b.nickName) {
                return 0;
              }
              return b.totalHourDay - a.totalHourDay;
            });
            setDatas(res.data[0]);
            setExtra(res.extra);
            let dataAll = res.data;
            let init = dataAll.filter((item: any) => item.name);
            summation(init);
            setDataList(init);
            let arr = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (arr < res.data[i].orderTotalCount) {
                arr = res.data[i].orderTotalCount;
              }
            }
            setSensingNum(arr);
            screenData(res.data)
          }
          setLoading(false);
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '数据查询失败',
          });
          setLoading(false);
        });
    }
  };
  const onChange = (v: string) => {
    setCheckState(v);
    setLoading(true);
    let start = dayjs(new Date()).format('YYYY-MM-DD');
    setTimeType({ index: 0, time: [start, start] });
    initValue(v, [start, start]);
    // if (v != 4) {
    //   setCheckState({ check: false, index: 0 });
    // }
  };
  const init = () => {
    getRegionRelationColor({}).then((res) => {
      if (res.retCode === 0) {
        setColor(res.data);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    initValue('0', timeType.time);
    init();
  }, []);
  const stateEnums = [
    { name: '拜访计划', value: 0 },
    { name: '拜访备注', value: 1 },
    { name: '车源拜访', value: 2 },
    { name: '检测人效', value: 3 },
    { name: '周检测量', value: 4 },
    { name: '同比环比', value: 5 },
    { name: '审核量', value: 6 },
    // { name: '业务报表', value: 7 },
    // { name: '检测报表', value: 8 },
  ];
  const tryncateDecimal = (value:number,decimalPlaces:any) => {
    let factor = Math.pow(10,decimalPlaces)
    return Math.floor(value * factor)/factor
  }
  const summation = (list: any) => {
    
    let lists = list.filter((index: number) => index != 0);
    let listFirst:any = {};
    lists.forEach((item:any)=>{
      for (const key in item) {
        if(typeof item[key] === "number"){
          listFirst[key] = tryncateDecimal(((listFirst[key] || 0) + (item[key] || 0)),2)
        }
      }
    })
    console.log(listFirst);
    
    lists.unshift(listFirst)
    setData(lists);
  };
  const columns_0: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    {
      code: 'bdName',
      name: '姓名',
      width: 100,
      lock: true,
      render: (_: any, record: any) => {
        return (
          <div>
            {/* <div>{record.bdName}</div> */}
            <div>
              {record.bdName}
              {record.status && (
                <span>{`（${record.status || ""}/${
                  schedulingText(record.scheduleStatus) || '-'
                }）`}</span>
              )}
            </div>
            <div>{record.posts}</div>
          </div>
        );
      },
    },
    {
      code: 'market',
      name: '市场',
      width: 140,
      align: 'right',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.market &&
              record.market.split(/,|、| /g).map((child: any) => {
                return <div>{child}</div>;
              })}
          </div>
        );
      },
    },
    {
      code: 'totalPlans',
      name: '总拜访计划数',
      width: 100,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalPlans', color, 1);
      },
    },
    {
      code: 'noSee',
      name: '陌拜计划数',
      width: 100,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'noSee', color, 1);
      },
    },
    {
      code: 'register',
      name: '注册拜访计划数',
      width: 100,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'register', color, 1);
      },
    },
    {
      code: 'newPurchase',
      name: '新购拜访计划数',
      width: 100,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'newPurchase', color, 1);
      },
    },
    {
      code: 'rePurchase',
      name: '复购拜访计划数',
      width: 100,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'rePurchase', color, 1);
      },
    },
    {
      code: 'newStore',
      name: '形象店拜访计划数',
      width: 100,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'newStore', color, 1);
      },
    },
    {
      code: 'carVisit',
      name: '车源拜访计划数',
      width: 100,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'carVisit', color, 1);
      },
    },
  ];
  const columns_1: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    {
      code: 'bdName',
      name: '姓名',
      width: 100,
      lock: true,
      render: (_: any, record: any) => {
        return (
          <div>
            {/* <div>{record.bdName}</div> */}
            <div>
              {record.bdName}
              {record.status && (
                <span>{`（${record.status || ""}/${
                  schedulingText(record.scheduleStatus) || '-'
                }）`}</span>
              )}
            </div>
            <div>{record.posts}</div>
          </div>
        );
      },
    },
    {
      code: 'market',
      name: '市场',
      width: 140,
      align: 'right',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.market &&
              record.market.split(/,|、| /g).map((child: any) => {
                return <div>{child}</div>;
              })}
          </div>
        );
      },
    },
    {
      code: 'totalCount',
      name: '拜访总商户数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalCount', color, 1);
      },
    },
    {
      code: 'outerMerchantCount',
      name: '拜访外围商户数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'outerMerchantCount', color, 1);
      },
    },
    {
      code: 'remakCount',
      name: '拜访备注数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'remakCount', color, 1);
      },
    },
    {
      code: 'remark8Count',
      name: '拜访备注达标数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'remark8Count', color, 1);
      },
    },
    {
      code: 'annotationCount',
      name: '总结批注',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'annotationCount', color, 1);
      },
    },
    {
      code: 'complianceRate',
      name: '备注达标完成率',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'complianceRate', color, 1);
      },
    },
    {
      code: 'commentOrAppraise',
      name: '拜访价值点校准数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'commentOrAppraise', color, 1);
      },
    },
    {
      code: 'commentCount',
      name: '拜访价值点批注数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'commentCount', color, 1);
      },
    },
    {
      code: 'matchCount',
      name: '价值点批注达标数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'matchCount', color, 1);
      },
    },
    {
      code: 'totalClinchRate',
      name: '拜访成交率',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalClinchRate', color, 1);
      },
    },
    {
      code: 'valuePointsCompletedNum',
      name: '已完成价值点',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'valuePointsCompletedNum', color, 1);
      },
    },
    {
      code: 'newPurClinchRate',
      name: '实际新购成交率',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'newPurClinchRate', color, 1);
      },
    },
    {
      code: 'repClinchRate',
      name: '实际复购成交率',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'repClinchRate', color, 1);
      },
    },
  ];
  const columns_2: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    {
      code: 'bdName',
      name: '姓名',
      width: 100,
      lock: true,
      render: (_: any, record: any) => {
        return (
          <div>
            <div>
              {record.bdName}
              {record.status && (
                <span>{`（${record.status || ""}/${
                  schedulingText(record.scheduleStatus) || '-'
                }）`}</span>
              )}
            </div>
            {/* <div>{record.bdName}</div> */}
            <div>{record.position}</div>
          </div>
        );
      },
    },
    // { code: 'position', name: '职位', width: 100, lock: true , align: 'right' },
    // {
    //   code: 'scheduleStatus',
    //   name: '排班状态',
    //   width: 80,
    //   align: 'center',
    //   render: (_: any, record: any) => {
    //     return <span>{schedulingText(record.scheduleStatus)}</span>;
    //   },
    // },
    {
      code: 'market',
      name: '市场',
      width: 140,
      align: 'right',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.market &&
              record.market.split(/,|、| /g).map((child: any) => {
                return <div>{child}</div>;
              })}
          </div>
        );
      },
    },
    // {
    //   code: 'orderTotalCount',
    //   name: '预约检测台次',
    //   width: 140,
    //   align: 'right',
    //   features: { sortable: true },
    //   render: (_: any, record: any) => {
    //     return colorThisValue(record, 'orderTotalCount', color, 1);
    //   },
    // },
    // {
    //   code: 'reserveTotalCount',
    //   name: '计划应检台次',
    //   width: 140,
    //   align: 'right',
    //   features: { sortable: true },
    //   render: (_: any, record: any) => {
    //     return colorThisValue(record, 'reserveTotalCount', color, 1);
    //   },
    // },
    {
      code: 'yTotalCount',
      name: '预约实际检测台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'yTotalCount', color, 1);
      },
    },
    // {
    //   code: 'rate',
    //   name: '预约完成率',
    //   width: 140,
    //   align: 'right',
    //   features: { sortable: true },
    //   render: (_: any, record: any) => {
    //     return colorThisValue(record, 'rate', color, 1);
    //   },
    // },
    // {
    //   code: 'totalCount',
    //   name: '实际总检测台次',
    //   width: 140,
    //   align: 'right',
    //   features: { sortable: true },
    //   render: (_: any, record: any) => {
    //     return colorThisValue(record, 'totalCount', color, 1);
    //   },
    // },
    // {
    //   code: 'nTotalCount',
    //   name: '实际无预约台次',
    //   width: 140,
    //   align: 'right',
    //   features: { sortable: true },
    //   render: (_: any, record: any) => {
    //     return colorThisValue(record, 'nTotalCount', color, 1);
    //   },
    // },
    // {
    //   code: 'cTotalCount',
    //   name: '检测取消台次',
    //   width: 140,
    //   align: 'right',
    //   features: { sortable: true },
    //   render: (_: any, record: any) => {
    //     return colorThisValue(record, 'cTotalCount', color, 1);
    //   },
    // },
    {
      code: 'carVisitCount',
      name: '车源拜访量',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'carVisitCount', color, 1);
      },
    },
    {
      code: 'totalCarSourceNum',
      name: '车源录入数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalCarSourceNum', color, 1);
      },
    },
  ];
  const columns_3: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    {
      code: 'nickName',
      name: '姓名',
      width: 100,
      lock: true,
      render: (_: any, record: any) => {
        return (
          <div>
            <div>
              {record.nickName}
              {record.nickName && (
                <span>{`（${record.status || ""}/${
                  schedulingText(record.scheduleStatus) || '-'
                }）`}</span>
              )}
            </div>
            <div style={{ color: '#666666' }}>{record.position}</div>
          </div>
        );
      },
    },
    // { code: 'position', name: '职位', lock: true , width: 100, align: 'right' },
    // { code: 'scheduleStatus', name: '排班状态', width: 80, align: 'center' },
    {
      code: 'stationName',
      name: '检测中心',
      width: 140,
      align: 'right',
      features: { sortable: true },
    },
    {
      code: 'yAuditCount',
      name: '检测台次(不含初检)',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'yAuditCount', color, 1);
      },
    },
    {
      code: 'marketCount',
      name: '市场端初检台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'marketCount', color, 1);
      },
    },
    {
      code: 'kaTotalCount',
      name: '大客户检测台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'kaTotalCount', color, 1);
      },
    },
    {
      code: 'avgHour',
      name: '日均检测工时',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'avgHour', color, 2);
      },
    },
    {
      code: 'avgCount',
      name: '日均检测台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'avgCount', color, 2);
      },
    },
    {
      code: 'totalHourDay',
      name: '当日检测工时',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalHourDay', color, 1);
      },
    },
    {
      code: 'totalCountDay',
      name: '当日检测台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalCountDay', color, 1);
      },
    },
    {
      code: 'nCheckTotalCount',
      name: '当日线下台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'nCheckTotalCount', color, 1);
      },
    },
    {
      code: 'yCheckTotalCount',
      name: '当日线上台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'yCheckTotalCount', color, 1);
      },
    },
    {
      code: 'totalCount',
      name: '月总检测台次',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalCount', color, 2);
      },
    },
    {
      code: 'totalHour',
      name: '月总检测工时',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'totalHour', color, 2);
      },
    },
    {
      code: 'userCheckDay',
      name: '月累计出勤天数',
      width: 140,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'userCheckDay', color, 2);
      },
    },
  ];
  const columns_4: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    {
      code: 'nickName',
      name: '姓名',
      width: 100,
      lock: true,
      render: (_: any, record: any) => {
        return (
          <div>
            {/* <div>
              {record.nickName}
              {record.status && <>{`（${record.status}）`}</>}
            </div> */}
            <div>
              {record.nickName}
              {record.nickName && (
                <span>{`（${record.status || ""}/${
                  schedulingText(record.scheduleStatus) || '-'
                }）`}</span>
              )}
            </div>
            <div>{record.position}</div>
          </div>
        );
      },
    },
    // { code: 'position', name: '职位', width: 100, lock: true , align: 'right' },
    // {
    //   code: 'scheduleStatus',
    //   name: '排班状态',
    //   width: 80,
    //   align: 'center',
    //   render: (_: any, record: any) => {
    //     return <span>{schedulingText(record.scheduleStatus)}</span>;
    //   },
    // },
    {
      code: 'marketName',
      name: '检测中心',
      width: 140,
      align: 'right',
      features: { sortable: true },
    },
    {
      code: 'monday',
      name: `周一(${mon})`,
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorDayValue(record, 'monday', color, 1);
      },
    },
    {
      code: 'tuesday',
      name: `周二(${tues})`,
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorDayValue(record, 'tuesday', color, 1);
      },
    },
    {
      code: 'wednesday',
      name: `周三(${wednes})`,
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorDayValue(record, 'wednesday', color, 1);
      },
    },
    {
      code: 'thursday',
      name: `周四(${thurs})`,
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorDayValue(record, 'thursday', color, 2);
      },
    },
    {
      code: 'friday',
      name: `周五(${fri})`,
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorDayValue(record, 'friday', color, 2);
      },
    },
    {
      code: 'saturday',
      name: `周六(${satur})`,
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorDayValue(record, 'saturday', color, 1);
      },
    },
    {
      code: 'sunday',
      name: `周日(${sun})`,
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorDayValue(record, 'sunday', color, 1);
      },
    },
  ];
  const columns_5: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    { code: 'cityName', name: '城市', lock: true, width: 100, align: 'right' },
    // {
    //   code: 'marketName',
    //   name: '检测中心',
    //   width: 140,
    //   align: 'right',
    //   features: { sortable: true },
    // },
    {
      code: 'curr',
      name: '当月',
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'curr', color, 1);
      },
    },
    {
      code: 'last',
      name: '环比（一月）',
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'last', color, 1);
      },
    },
    {
      code: 'prev',
      name: '同比（去年）',
      width: 150,
      align: 'right',
      features: { sortable: true },
      render: (_: any, record: any) => {
        return colorThisValue(record, 'prev', color, 1);
      },
    },
  ];
  const columns_7: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    {
      code: 'name',
      name: '姓名',
      width: 100,
      lock: true,
      render: (_: any, record: any) => {
        return (
          <div>
            <div>
              {record.name}
            </div>
            <div style={{ color: '#666666' }}>{record.post}</div>
          </div>
        );
      },
    },
    // { code: 'cityName', name: '城市', lock: true, width: 100, align: 'right' },
    { code: 'region', name: '大区' , lock: true,width: 100, align: 'center' },
    {
      code: 'memberMarketName',
      name: '市场',
      width: 140,
      align: 'right',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.memberMarketName &&
              record.memberMarketName.split(/,|、| /g).map((child: any) => {
                return <div>{child}</div>;
              })}
          </div>
        );
      },
    },
    { code: 'status', name: '状态' ,width: 100, align: 'center' },
    // { code: 'entryTime', name: '入职时间' ,width: 100, align: 'center' },
    // { code: 'resignationTime', name: '离职时间' ,width: 100, align: 'center' },
    { code: 'dayPrice', name: '日成交金额' ,width: 100, align: 'center' },
    { code: 'totalClinchRate', name: '拜访成交率' ,width: 100, align: 'center' },
    { code: 'monthTotalVisitHour', name: '月总拜访工时' ,width: 100, align: 'center' },
    { code: 'monthTotalVisitMerchantNum', name: '月总拜访商户数' ,width: 120, align: 'center' },
    { code: 'monthTotalPrice', name: '月总成交金额' ,width: 100, align: 'center' },
    { code: 'monthTotalClinchRate', name: '月均拜访成交率' ,width: 120, align: 'center' },
    { code: 'transactionMerchant', name: '成功计划数' ,width: 100, align: 'center' },
    { code: 'tomorrowKeyMerchantNum', name: '明日重点商户拜访计划数' ,width: 180, align: 'center' },
    { code: 'dayKeyMerchantNum', name: '今日重点商户拜访计划数' ,width: 180, align: 'center' },
  ];
  const columns_8: any = [
    {
      name: '排名',
      width: 50,
      align: 'center',
      lock: true,
      getValue(_: any, rowIndex: number) {
        return String(rowIndex + 1);
      },
    },
    {
      code: 'name',
      name: '姓名',
      width: 100,
      lock: true,
      render: (_: any, record: any) => {
        return (
          <div>
            <div>
              {record.name}
            </div>
            <div style={{ color: '#666666' }}>{record.post}</div>
          </div>
        );
      },
    },
    // { code: 'cityName', name: '城市', lock: true, width: 100, align: 'right' },
    { code: 'region', name: '大区', lock: true ,width: 100, align: 'center' },
    {
      code: 'memberMarketName',
      name: '市场',
      width: 140,
      align: 'right',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.memberMarketName &&
              record.memberMarketName.split(/,|、| /g).map((child: any) => {
                return <div>{child}</div>;
              })}
          </div>
        );
      },
    },
    { code: 'status', name: '状态' ,width: 100, align: 'center' },
    // { code: 'entryTime', name: '入职时间' ,width: 100, align: 'center' },
    // { code: 'resignationTime', name: '离职时间' ,width: 100, align: 'center' },
    { code: 'avgDayCount', name: '日均检测台次' ,width: 100, align: 'center' },
    { code: 'dayAvgDetectionTarget', name: '日均检测目标' ,width: 100, align: 'center' },
    { code: 'totalMonthHour', name: '月总检测工时' ,width: 100, align: 'center' },
    { code: 'monthlyCompletionRate', name: '月完成率' ,width: 100, align: 'center' },
    { code: 'monthlyTarget', name: '月目标' ,width: 100, align: 'center' },
    { code: 'monthRecheckWorkingHour', name: '月总复检工时' ,width: 100, align: 'center' },
    { code: 'monthInitialWorkingHour', name: '月总初检工时' ,width: 100, align: 'center' },
    { code: 'monthCarVisitHour', name: '月总拜访工时' ,width: 100, align: 'center' },
    { code: 'monthRenRate', name: '当月驳回率' ,width: 100, align: 'center' },
  ];
  const firstlyColumns: any = [
    // { code: 'firstPlaceRegion', name: '城市', align: 'center' },
    // {
    //   code: 'bdName',
    //   name: '当月',
    //   align: 'center',
    //   // render: (_: any, record: any) => {
    //   //   return colorThisValue(record, 'curr', color,1);
    //   // },
    // },
    {
      code: 'maximumValueName',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <span>
            {record.maximumValueName}:{contentText(record)}
          </span>
        );
      },
    },
    {
      code: 'regionName',
      align: 'center',
      render: (_: any, record: any) => {
        return <span>{record.regionName || record.region}</span>;
      },
    },
  ];

  const columns: any = {
    '0': columns_0,
    '1': columns_1,
    '2': columns_2,
    '3': columns_3,
    '4': columns_4,
    '5': columns_5,
    '7': columns_7,
    '8': columns_8
  };
  const pipeline = useTablePipeline({ components: fusion })
    .input({ dataSource: data, columns: checkState === "6" ? KeyListColumns : columns[checkState] })
    .use(
      features.sort({
        mode: 'single',
        highlightColumnWhenActive: true,
      }),
    );
  // return
  return (
    <div className={styles.DataCentres}>
      <Navbar name={'数据中心'}></Navbar>
      <div className={styles.DataCentres_content}>
        {loading && <Login></Login>}
        <div className={styles.ranking}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tabs
              style={{
                '--title-font-size': '14px',
              }}
              onChange={onChange}
              defaultActiveKey={'0'}
            >
              {stateEnums.map((item, index) => (
                <Tabs.Tab
                  className={`${
                    checkState === `${index}` ? styles.tabs : styles.tabs_0
                  }`}
                  title={item.name}
                  key={item.value}
                />
              ))}
            </Tabs>
            <div
              style={{ display: 'block', marginRight: '10px' }}
              onClick={() => {
                Modal.alert({
                  title: '数据中心说明',
                  content: (
                    <div style={{ width: '95%', margin: 'auto' }}>
                      <div>
                        <div className={styles.modal_title}>BD人员</div>
                        <div className={styles.modal_text}>
                          所有大区BD姓名展示，排序时按所属大区进行颜色区分
                        </div>
                        <div className={styles.model_color}>
                          <div className={styles.model_color_0}>
                            <div style={{ backgroundColor: '#8cdd9e' }}></div>
                            <span>北方大区</span>
                          </div>
                          <div className={styles.model_color_0}>
                            <div style={{ backgroundColor: '#6298ef' }}></div>
                            <span>华东大区</span>
                          </div>
                          <div className={styles.model_color_0}>
                            <div style={{ backgroundColor: '#d6abfd' }}></div>
                            <span>中南大区</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={styles.modal_title}>拜访计划</div>
                        <div className={styles.modal_text}>
                          按各BD创建拜访计划数量统计，详细包括总拜访计划数、陌拜计划数、注册拜访计划数、新购拜访计划数、复购拜访计划数、形象店拜访计划数、车源拜访计划数
                        </div>
                      </div>
                      <div>
                        <div className={styles.modal_title}>拜访备注</div>
                        <div className={styles.modal_text}>
                          按各BD拜访计划完成备注商户统计，详细包括拜访总商户数、拜访备注商户数、拜访备注达标商户数、备注达标完成率
                        </div>
                      </div>
                    </div>
                  ),
                  closeOnMaskClick: true,
                });
              }}
            >
              <img src={error} alt="" />
            </div>
          </div>
          {checkState != "6" && (
            // <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Dropdown style={{ width: '48%' }} ref={ref}>
                <Dropdown.Item key="sorter" title={regionName}>
                  <SearchBar placeholder='请输入内容' onSearch={onSearch} onClear={()=>{
                    setSearchValue("")
                  }}/>
                  <div className={styles.dropdown_scroll}>
                    {regionData &&
                      regionData.length > 0 &&
                      regionData.map((name: string) => {
                        if(name && name.indexOf(searchValue)> -1 ){
                          return (
                            <div
                              onClick={() => {
                                setregionName(name);
                                screenValue(dataList,name,1)
                              }}
                            >
                              {name}
                            </div>
                          );

                        }
                      })}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item key="bizop" title={districtName}>
                  <SearchBar placeholder='请输入内容' onSearch={onSearch} onClear={()=>{
                    setSearchValue("")
                  }}/>
                  <div className={styles.dropdown_scroll}>
                    {districtData &&
                      districtData.length > 0 &&
                      districtData.map((name: string) => {
                        if(name && name.indexOf(searchValue)> -1 ){
                          return (
                            <div
                              onClick={() => {
                                setdistrictName(name);
                                screenValue(dataList,name,2)
                                
                              }}
                            >
                              {name}
                            </div>
                          );
                        }
                      })}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item key="market" title={marketName}>
                  <SearchBar placeholder='请输入内容' onSearch={onSearch} onClear={()=>{
                    setSearchValue("")
                  }}/>
                  <div className={styles.dropdown_scroll}>
                    {marketData &&
                      marketData.length > 0 &&
                      marketData.map((name: string) => {
                        if(name && name.indexOf(searchValue)> -1 ){
                          return (
                            <div
                              onClick={() => {
                                setmarketName(name);
                                screenValue(dataList,name,4)
                              }}
                            >
                              {name}
                            </div>
                          );
                        }
                      })}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item key="bdName" title={bdDataName}>
                  <SearchBar placeholder='请输入内容' onSearch={onSearch} onClear={()=>{
                    setSearchValue("")
                  }}/>
                  <div className={styles.dropdown_scroll}>
                    {bdData &&
                      bdData.length > 0 &&
                      bdData.map((name: string) => {
                        if(name && name.indexOf(searchValue)> -1 ){
                          return (
                            <div
                              onClick={() => {
                                setBdDataName(name);
                                screenValue(dataList,name,3)
                              }}
                            >
                              {name}
                            </div>
                          );
                        }
                      })}
                  </div>
                </Dropdown.Item>
              </Dropdown>
              {checkState != '5' && (
                <Space wrap align="center">
                  {checkState === '0' && (
                    <Button
                      size="mini"
                      color={`${timeType.index === 4 ? 'primary' : 'default'}`}
                      onClick={() => {
                        const year = dayjs().year();
                        const month = dayjs().month() + 1;
                        const date = dayjs().date() + 1;
                        let start = dayjs(`${year}-${month}-${date}`).format(
                          'YYYY-MM-DD',
                        );
                        setLoading(true);
                        // Toast.show({
                        //   icon:"fail",
                        //   content:"数据查询失败"
                        // })
                        setTimeType({ index: 4, time: [start, start] });
                        initValue(checkState, [start, start]);
                      }}
                    >
                      明日
                    </Button>
                  )}
                  {checkState != '4' && (
                    <Button
                      size="mini"
                      color={`${timeType.index === 0 ? 'primary' : 'default'}`}
                      onClick={() => {
                        let start = dayjs(new Date()).format('YYYY-MM-DD');
                        setTimeType({ index: 0, time: [start, start] });
                        setLoading(true);
                        initValue(checkState, [start, start]);
                      }}
                    >
                      当日
                    </Button>
                  )}
                  {checkState != '3' && (
                    <Button
                      size="mini"
                      color={`${
                        timeType.index === 1 || checkState === '4'
                          ? 'primary'
                          : 'default'
                      }`}
                      onClick={() => {
                        let date = new Date();
                        //本周第一天
                        let start = dayjs(
                          new Date(
                            date.getTime() -
                              3600 *
                                1000 *
                                24 *
                                (date.getDay() == 0 ? 6 : date.getDay() - 1),
                          ),
                        ).format('YYYY-MM-DD');
                        //本周最后一天
                        let end = dayjs(
                          new Date(
                            date.getTime() +
                              3600 *
                                1000 *
                                24 *
                                (date.getDay() == 0 ? 0 : 7 - date.getDay()),
                          ),
                        ).format('YYYY-MM-DD');
                        setTimeType({ index: 1, time: [start, end] });
                        setLoading(true);
                        initValue(checkState, [start, end]);
                      }}
                    >
                      当周
                    </Button>
                  )}
                  {checkState != '3' && checkState != '4' && (
                    <Button
                      size="mini"
                      color={`${timeType.index === 2 ? 'primary' : 'default'}`}
                      onClick={() => {
                        const date = new Date();
                        const start = dayjs(new Date(date.setDate(1))).format(
                          'YYYY-MM-DD',
                        ); //本月第一天
                        const end = dayjs(
                          new Date(
                            new Date(date.getFullYear(), date.getMonth() + 1, 0),
                          ),
                        ).format('YYYY-MM-DD');
                        setTimeType({ index: 2, time: [start, end] });
                        setLoading(true);
                        initValue(checkState, [start, end]);
                      }}
                    >
                      当月
                    </Button>
                  )}
                </Space>
              )}
            </div>
          )}
          <BaseTable
            useOuterBorder
            style={{ maxHeight: '85%', overflow: 'auto' }}
            {...pipeline.getProps()}
          />
          {localStorage.getItem('isLeaderInDept') === '1' && (
            <div className={styles.firstly_tag}>
              <Tag
                round
                color="primary"
                onClick={() => {
                  setFirstlyVisible(true);
                }}
              >
                第一名
              </Tag>
            </div>
          )}
        </div>
        {localStorage.getItem('isLeaderInDept') === '1' && (
          <Popup
            visible={firstlyVisible}
            showCloseButton
            onClose={() => {
              setFirstlyVisible(false);
            }}
            mask={false}
            bodyStyle={{
              maxHeight: '40vh',
              borderTop: '1px solid rgba(128, 128, 128, 1)',
              borderRight: '1px solid rgba(128, 128, 128, 1)',
              width: '90vw',
            }}
          >
            <div className={styles.firstly}>
              <div className={styles.firstly_title}>第一名</div>
              <BaseTable
                useOuterBorder
                dataSource={extra}
                columns={firstlyColumns}
                hasHeader={false}
              />
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
}
