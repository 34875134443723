import { FC, useEffect, useRef, useState } from 'react';
import styles from './index.less';
import { Helmet, history, useLocation } from 'umi';
import { Button, CheckList, InfiniteScroll, Popup, SearchBar, Tabs, Toast, TreeSelect } from 'antd-mobile';
import { userList } from '../merchant/service';
import { DownFill } from 'antd-mobile-icons';
import { getList } from '../collect/service';
import datas from '../utils/省市区线数据.json';
import Item from 'antd-mobile/es/components/dropdown/item';
import { clear } from 'antd-mobile/es/components/dialog/clear';
type NameLists = {
  contactCityName: string,
  contactCityCode: number,
  nameVisible: boolean,
  checkFlag: boolean,
  modelFlag:boolean,
  onCancel: () => void;
  onSubmit: (values: any,data:any) => void;
};
const NameList: FC<NameLists> = (props) => {
  const {nameVisible,onCancel,onSubmit,contactCityCode,checkFlag,contactCityName,modelFlag} = props;
  const [nameList, setNameList] = useState<any[]>([]);
  const [pageNoName, setpageNoName] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true)
  const [nameValue, setNameValue] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false)
  const [options, setoptions] = useState<any>([]);
  const [cityCode, setCityCode] = useState<any>([]);
  const infoRef = useRef();
  const initValue = async(value:any)=>{
    let params:any = {}
    params.couponGroupName = value.couponGroupName
    params.cityCode = contactCityCode
    const msg = await getList(params)
    if(msg.retCode === 0){
      if(msg.data && msg.data.datas && msg.data.datas.length >0) {
        let list = msg.data.datas;
        if(modelFlag){
          list.push({
            imgUrl: "https://cst-b2b.obs.cn-north-4.myhuaweicloud.com/base/f1703569679481.png",
            couponGroupId: 296481860878337,
            couponGroupName:"事故排查检",
            useDesc:"二手车价70万以下",
            price: 198,
            type:1
          })
        }
        setNameList(list)
      }
    }
  }
  const onChange = (v: any)=>{
    console.log(v);
    setNameValue(v)
  }
  const onSearch = async (value: string) => {
    console.log(111);
    let params:any = {}
    params.couponGroupName = value
    initValue(params)
  };
  const init = () => {
    let list: any[] = [];
    let list2: any[] = [];
    let list3: any[] = [];
    datas.RECORDS.map((item: any) => {
      if (item.deep === 0) {
        list.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
      if (item.deep === 1) {
        list2.push({
          label: item.name,
          value: item.row_id,
          pid: item.pid,
        });
      }
    });
    let city: any[] = [];
    let city2: any[] = [];
    list2.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list3.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city.push(data);
    });
    list.map((item: any) => {
      const data = item;
      let children: any[] = [];
      list2.map((child: any) => {
        if (item.value === child.pid) {
          children.push(child);
        }
      });
      data.children = children;
      city2.push(data);
    });
    setoptions(city2);
    console.log(city2);
  };
  const stateEnum = [
    { name: '常规检测', value: 1 },
    {
      name: '豪车检测',
      value: 2,
    },
    {
      name: '查询服务',
      value: 99,
    },
    {
      name: '动力三包检+万高延保',
      value: 3,
    },
    // { name: '新能源检测', value: 3 },
  ];
  useEffect(()=>{
    initValue({})
    init()
    setNameValue([])
  },[contactCityCode])
  return(
    <div className={styles.market_0}>
      <Popup position="right" visible={nameVisible} className="123123" bodyClassName={styles.bodyClass}>
        <div className={styles.collect}>
          <div className={styles.title}>
            <div className={styles.search}>
              <SearchBar
                onSearch={onSearch}
                // style={{ '--background': '#E9ECF0'}}
                placeholder="搜索产品"
              />
            </div>
          </div>
          
          <div className={styles.content}>
            <CheckList multiple={checkFlag} value={nameValue} onChange={onChange}>
                <Tabs defaultActiveKey={"1"}>
                  {stateEnum.map((tab:any,tabIndex:number)=>{
                    return (
                      <Tabs.Tab title={tab.name} key={tab.value}>
                        {nameList && nameList.length > 0 && nameList.map((item:any)=>{
                          if(item.type === tab.value){
                            if(item.type === 99){
                              return (
                                <CheckList.Item value={`${item.couponGroupId}++${item.couponGroupName}++${item.price}`}>
                                  <div className={styles.test_0}>
                                    <div className={styles.test_order}>
                                      <img src={item.imgUrl && item.imgUrl} alt="" />
                                      <div className={styles.test_order_0}>
                                        <div className={styles.test_order_title}>{item.couponGroupName}</div>
                                        <div className={styles.test_order_price}>
                                          <div>￥<span>{item.price ? item.price : "--"}</span> {item.originPrice && (<del>原价{item.originPrice}</del>)}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CheckList.Item>
                              )
                            }else{
                              return (
                                <CheckList.Item value={`${item.couponGroupId}++${item.couponGroupName}++${modelFlag ? (item.goodsOrigin && item.goodsOrigin.batchBuyPrice) : item.price}`}>
                                  <div className={styles.test}>
                                    <div className={styles.test_order}>
                                      <img src={item.imgUrl && item.imgUrl} alt="" />
                                      <div className={styles.test_order_0}>
                                        <div className={styles.test_order_title}>{item.couponGroupName}</div>
                                        <div className={styles.test_order_tabs}>
                                          <span>终身回购</span>
                                          <span>检测报告</span>
                                        </div>
                                        <div className={styles.test_order_result}>
                                          使用范围：{item.useDesc ? item.useDesc : "--"}
                                        </div>
                                        <div className={styles.test_order_price}>
                                          <div>￥<span>{item.price ? item.price : "--"}</span> {item.originPrice && (<del>原价{item.originPrice}</del>)} {item.goodsOrigin && item.goodsOrigin.batchBuyPrice && (<a>换卷单价：{item.goodsOrigin.batchBuyPrice}</a>)}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CheckList.Item>
                              )
                            }
                          }
                        })}
                      </Tabs.Tab>
                    )
                  })}
                </Tabs>
              {/* </Tabs> */}
            </CheckList>
          </div>
          </div>
        <div className={styles.footer}>
          <div>
            已选：{nameValue.length} 个
          </div>
          <div className={styles.button} >
            <Button color='primary' fill='outline' onClick={()=>{
              onCancel()
            }}>
              取消
            </Button>
            <Button size='middle' color='primary' onClick={()=>{
              onSubmit(nameValue,nameList)
            }}>
              确定
            </Button>
          </div>
        </div>
        <Popup
          visible={visible}
          onMaskClick={() => {
            setVisible(false);
          }}
          onClose={() => {
            setVisible(false);
          }}
          showCloseButton
          position="right"
          bodyStyle={{ width: '100%' }}
        >
          <div style={{ width: 'inherit' }}>
            <TreeSelect
              options={options}
              onChange={(value, nodes) => {
                console.log(value, nodes);
                setCityCode(nodes.options);
              }}
            />
            <div className={styles.time_button}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  setVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                size="middle"
                color="primary"
                onClick={() => {
                  console.log(cityCode);
                  if (cityCode.length === 1) {
                    Toast.show({
                      content: '请选择城市',
                    });
                    return;
                  }else{
                    if (cityCode && cityCode.length > 1 && cityCode[1].value){
                      let params:any = {}
                      params.cityCode = cityCode[1].value
                      initValue(params)
                    }
                  }
                  setVisible(false);
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </Popup>
      </Popup>
    </div>
  )
}

export default NameList;