import { FC } from 'react';
import styles from './index.less';
import { Helmet, history, useLocation } from 'umi';
type Navbars = {
  name: string,
};

const Navbar: FC<Navbars> = (props) => {
  const {name} = props;
  return(
    <div>
      <Helmet>
        <title>{name}</title>
      </Helmet>
    </div>
  )
}

export default Navbar;