// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function Lists(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  delete params.flag
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/order/report/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0 && msg.data) {
    return msg.data
  } else {
    return []
  }
}
export async function detectlist(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/order/booking/detect/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0 && msg.data.datas) {
    return msg.data.datas
  } else {
    return []
  }
}
export async function getVisitTemplate(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/order/report/getVisitTemplate', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0 && msg.data) {
    return msg.data
  } else {
    return []
  }
}

export async function noauth(data: any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/test/noauth/data', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}

export async function openCarModel(data:any,options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/test/open/carModel', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}