// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function login(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/login', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getStatistics(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/kanban/todayData', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getWxAccessToken(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/wx/jsapi/ticket/getJsapiTicket', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}