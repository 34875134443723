// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
import { sleep } from 'antd-mobile/es/utils/sleep'


export async function getMCarDealerVisitVo(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/merchant/details/getMCarDealerVisitVo', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getHistoryVisitImgVinList(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/visit/img/getHistoryVisitImgVinList', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getCompanyRecord(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/merchant/details/getCompanyRecord', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function getCouponUsedStatAls(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/coupon/getCouponUsedStatAls', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function detectionRecords(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/merchant/details/detectionRecords', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
export async function fatherOrSonCompany(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/father/son/company/fatherOrSonCompany', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}
