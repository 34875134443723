// @ts-ignore
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie, RingProgress } from '@ant-design/plots';
import styles from '../index.less';
import { Tabs } from 'antd-mobile';

type point = {
  current: any,
  companyId: any,
  onPointChange: (values: any) => void;
};
const myPie: React.FC<point> = (props) => {
  const { current,onPointChange,companyId } = props;
  const [configList, setConfigList] = useState<any>([]);
  const [digitalKey, setDigitalKey] = useState<string>('专业人设');
  const data = [
    { type: '极好', value: 27 },
    { type: '好', value: 30 },
    { type: '中', value: 20 },
    { type: '差', value: 20 },
    { type: '未批注', value: 10 },
  ];

  const stateEnum = [
    { name: '专业人设', value: 0 },
    { name: '检测人设', value: 1 },
    { name: '数据人设', value: 2 },
    {
      name: '报价成交',
      value: 3,
    },
    { name: '预约实检', value: 4 },
  ];
  useEffect(() => {
    if (current && current.length > 0) {
      let list: any = [];
      current.map((item: any) => {
        list[item.labelName] = {
          labelName: item.labelName,
          appendPadding: 10,
          tooltip: false,
          legend: false,
          series: false,
          data: [
            { type: '极好', value: item.superb || 0 },
            { type: '好', value: item.good || 0 },
            { type: '中', value: item.medium || 0 },
            { type: '差', value: item.difference || 0 },
            { type: '未批注', value: item.notCommented || 0 },
          ],
          angleField: 'value',
          colorField: 'type',
          radius: 1,
          innerRadius: 0.65,
          autoFit: true,
          renderer: 'svg',
          label: false,
          statistic: false,
          color: [
            'rgba(21, 209, 65, 1)',
            'rgba(12, 125, 253, 1)',
            'rgba(255, 142, 32, 1)',
            'rgba(255, 47, 32, 1)',
            'rgba(182, 182, 182, 1)',
          ],
          height: 100,
        };
      });
      setConfigList(list);
    }
  }, [current]);
  const onChange = (v: any) => {
    const data:any = {companyId:companyId}
    current.map((item:any)=>{
      if(item.labelName === v){
        data.parentId = item.labelId
      }
    })
    onPointChange(data)
    setDigitalKey(v);
  };
  return (
    <div>
      <Tabs
        style={{
          '--title-font-size': '14px',
        }}
        className={styles.tab_appraise}
        onChange={onChange}
        defaultActiveKey={'专业人设'}
      >
        {stateEnum.map((item, index) => (
          <Tabs.Tab title={item.name} key={item.name} />
        ))}
      </Tabs>
      <div className={styles.pieClass}>
        {stateEnum &&
          stateEnum.length > 0 &&
          stateEnum.map((item: any, index: number) => {
            if (configList[item.name]) {
              return (
                <>
                  <div className={styles.pieClass_content}>
                    <Pie {...configList[item.name]} />
                    {!item.medium &&
                    !item.difference &&
                    !item.notCommented &&
                    item.superb && 
                    item.good ? (
                      <div>已完成</div>
                    ) : (
                      <div>未完成</div>
                    )}
                  </div>
                  {index + 1 != current.length && <span></span>}
                </>
              );
            }
          })}
      </div>
      
      {current &&
        current.length > 0 &&
        digitalKey &&
        current.map((item: any) => {
          if (item.labelName === digitalKey) {
            return (
              <div className={styles.contentClass}>
                <div>
                  <span style={{ background: 'rgba(21, 209, 65, 1)' }}></span>{' '}
                  极好：{item.superb || 0}
                </div>
                <div>
                  <span style={{ background: 'rgba(12, 125, 253, 1)' }}></span>{' '}
                  好：{item.good || 0}
                </div>
                <div>
                  <span style={{ background: 'rgba(255, 142, 32, 1)' }}></span>{' '}
                  中：{item.medium || 0}
                </div>
                <div>
                  <span style={{ background: 'rgba(255, 47, 32, 1)' }}></span>{' '}
                  差：{item.difference || 0}
                </div>
                <div>
                  <span style={{ background: 'rgba(182, 182, 182, 1)' }}></span>{' '}
                  未批注：{item.notCommented || 0}
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};
export default myPie;
